import { useField } from 'formik'
import React, { useRef } from 'react'
import styled from 'styled-components'
import { FormFieldWrap, FormFieldWrapProps } from '.'
import { Btn } from '..'
import { useDocTypesQuery } from '../../../gql_generated/graphql'
import { useState } from '../../../utils'

export const DocTypeFieldView = styled.div`
	.dropdown {
		overflow: hidden;
		transition: all ${props => props.theme.times.tranS};
		position: absolute;
		bottom: -0.3em;
		left: 0;
		z-index: 5;
		width: 100%;
		background: ${props => props.theme.colors.white.val};
		box-shadow: 1px 1px 2px ${props => props.theme.colors.grey.light().val};
		transform: translateY(100%);
		button {
			padding: 0.3em 0.5em;
			width: 100%;
			text-align: left;
		}
		.group {
			padding: 0.5em;
			button.__active {
				background: ${props => props.theme.colors.secondary.light(5).val};
			}
		}
	}
`

export type DocTypeFieldProps = FormFieldWrapProps & {}
export const DocTypeField: React.FC<DocTypeFieldProps> = ({ name, label, isReq, helperTxt }) => {
	const [isActive, setActive] = useState(false, 'isActive')
	const groupRef = useRef<HTMLDivElement>(null)
	const [{ data }] = useDocTypesQuery()
	const height = groupRef.current?.offsetHeight

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [{ value }, _, { setValue }] = useField({ name })

	const [activeIdx, setActiveIdx] = useState<number | null>(null, 'activeIdx')

	const searchResults = data?.docTypes?.filter(
		({ name }) => (value?.length || 0) > 2 && name.toLowerCase().includes(value?.toLowerCase())
	)

	const formFieldProps = {
		name,
		label,
		isReq,
		helperTxt,
	}

	return (
		<FormFieldWrap {...formFieldProps} type='select-rec-doc-type'>
			<DocTypeFieldView
				onKeyDown={e => {
					if (e.key === 'ArrowDown') {
						e.preventDefault()
						setActiveIdx(state =>
							state === null ? 0 : state + 1 === searchResults?.length ? state : state + 1
						)
					} else if (e.key === 'ArrowUp') {
						e.preventDefault()
						setActiveIdx(state => (!state ? null : state - 1))
					} else if (e.key === 'Enter') {
						e.preventDefault()
						if (typeof activeIdx === 'number' && searchResults) {
							const val = searchResults[activeIdx]?.name
							if (val) {
								setValue(val)
								setActive(false)
							}
						}
					}
				}}
			>
				<input
					onChange={e => setValue(e.target.value)}
					value={value}
					onFocus={() => (!isActive ? setActive(true) : null)}
					onBlur={() => {
						setTimeout(() => {
							setActive(false)
						}, 100)
					}}
				/>
				<div
					className='dropdown'
					style={{ maxHeight: isActive && searchResults?.length ? `${height || 0}px` : 0 }}
				>
					<div className='group' ref={groupRef}>
						<ul>
							{searchResults?.length
								? searchResults.map(({ name }, idx) => (
										<li key={name}>
											<Btn
												onClick={() => setValue(name)}
												className={idx === activeIdx ? '__active' : ''}
											>
												{name}
											</Btn>
										</li>
								  ))
								: null}
						</ul>
					</div>
				</div>
			</DocTypeFieldView>
		</FormFieldWrap>
	)
}
