import React from 'react'
import styled, { css } from 'styled-components/macro'
import { AgreementDetail } from '../../../Components/common/Agreement/Agreement.detail'

export const relatedOrgAgreementModalStyles = css``

const RelatedOrgAgreementModalView = styled.div`
	${relatedOrgAgreementModalStyles}
`

export type RelatedOrgAgreementModalProps = {
	agreementId: number
}
export const RelatedOrgAgreementModal: React.FC<RelatedOrgAgreementModalProps> = ({
	agreementId,
}) => {
	return (
		<RelatedOrgAgreementModalView>
			<AgreementDetail agreementId={agreementId} />
		</RelatedOrgAgreementModalView>
	)
}
