import React from 'react'
import styled, { css } from 'styled-components'
import { GenRoyaltyModal } from '../../Components/common/Royalty/Royalty.gen.modal'
import { royaltiesPageLeasesColsConfig } from '../../Components/UI/Table.resources/Table.resource.col.configs/Royalty.col.configs'
import { ResourcesTable } from '../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../Components/UI/Table.resources/usePaginationParams'
import { RoyaltyParent } from '../../gql_generated/document_types'
import { usePaginatedRoyaltiesQuery } from '../../gql_generated/graphql'

export const royaltiesPageStyles = css``

const RoyaltiesPageLeasesView = styled.div`
	${royaltiesPageStyles}
`

type RoyaltiesPageLeasesProps = {}
const RoyaltiesPageLeases: React.FC<RoyaltiesPageLeasesProps> = () => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedRoyaltiesQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: RoyaltyParent.Lease,
					},
				],
			},
		},
	})

	const dataset = fetchResults.data?.page?.royalties

	return (
		<RoyaltiesPageLeasesView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={royaltiesPageLeasesColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={data => <GenRoyaltyModal royaltyId={data.id} />}
				includeSearch
				resourceType='royalty'
				suppressGoTo
			/>
		</RoyaltiesPageLeasesView>
	)
}
export default RoyaltiesPageLeases
