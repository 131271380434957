import styled, { css } from 'styled-components'
import { SaleFilter } from '../../../../gql_generated/document_types'
import { useSalesForOpenSalesDashWidgetQuery } from '../../../../gql_generated/graphql'
import { Card } from '../../../UI'
import { openSalesWidgetConfig } from '../../../UI/Table.resources/Table.resource.col.configs/Sale.col.configs'
import { ResourcesTable } from '../../../UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../UI/Table.resources/usePaginationParams'
import { GenSaleModal } from '../../../common/Sale/Sale.gen.modal'

const OpenSalesDashWidgetStyles = css``

const OpenSalesDashWidgetView = styled.div`
	${OpenSalesDashWidgetStyles}
`

type OpenSalesDashWidgetProps = {
	className?: string
}
export const OpenSalesDashWidget = ({ className }: OpenSalesDashWidgetProps): JSX.Element => {
	const paginationStore = usePaginationParams()
	const params = {
		...paginationStore.params,
		filterOptions: [
			{
				filter: SaleFilter.Status,
				vals: ['Open Escrow'],
			},
		],
	}
	const [results] = useSalesForOpenSalesDashWidgetQuery({
		variables: {
			params,
		},
	})
	const { data } = results || {}

	const sales = data?.page?.sales
	return (
		<OpenSalesDashWidgetView className={`open-sales-dash-widget${className ? ` className` : ''}`}>
			<Card noShadow titleText='OPEN SALES' headingSize='lg' icon='sales'>
				<ResourcesTable
					colsConfig={openSalesWidgetConfig}
					fetchResults={results}
					paginationStore={paginationStore}
					dataset={sales}
					modalRenderFunc={sale => <GenSaleModal saleId={sale.id} />}
					limitSelectConfig={{ hideLimitSelect: true }}
					resourceType='sale'
					displayOptions={{
						hidePagination: true,
						hideTotalQty: true,
					}}
				/>
			</Card>
		</OpenSalesDashWidgetView>
	)
}
