import { useState } from 'react'

export type TableState = {
	lastActiveIdx: number | null
}
export type TableUpdateState = {
	setLastActiveIdx: React.Dispatch<React.SetStateAction<number | null>>
}
export type TableStore = {
	tableState: TableState
	updateTableState: TableUpdateState
}

export const useTableState = (): TableStore => {
	const [lastActiveIdx, setLastActiveIdx] = useState<number | null>(null)

	return {
		tableState: {
			lastActiveIdx,
		},
		updateTableState: {
			setLastActiveIdx,
		},
	}
}
