import capitalize from 'lodash/capitalize'
import includes from 'lodash/includes'
import React from 'react'
import styled from 'styled-components'
import { Estate } from '../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../Types'
import { estateTypes as types, useState } from '../../../utils'
import { Btn, Icon, Modal } from '../../UI'
import { IconType } from '../../UI/Icon/fa.defaults'
import { GenEstateModal } from './Estate.gen.modal'

const View = styled.div`
	align-items: center;
	display: flex;
	&.vert-layout {
		display: block;
		width: auto;
		.estate-type {
			margin: 0.5em 0;
			display: flex;
			align-items: center;
			&:last-child {
				margin-bottom: 0;
			}
			& > .icon {
				margin-right: 1em;
				height: auto;
				width: 1.7em;
			}
			.status {
				.icon {
					height: auto;
					width: 1.5em;
					margin-right: 0.2em;
				}
			}
		}
	}
	.estate-type {
		color: ${props => props.theme.colors.btn.val};
		margin: 0 0.5em;
		&:last-child {
			margin-right: 0;
		}
		&.selected {
			color: ${props => props.theme.colors.aux2.val};
		}
		.icon {
			height: 1.5em;
		}
	}
`

export type EstateTypesProps = {
	selected?: string[]
	onSelect?: (type: string | number) => void
	isStatic?: boolean
	hideUnselected?: boolean
	estates?: Estate[] | null
	isVert?: boolean
	showFullNames?: boolean
	inModal?: boolean
}
export const EstateTypes: React.FC<EstateTypesProps> = ({
	selected,
	onSelect,
	isStatic,
	isVert,
	hideUnselected,
	estates,
	showFullNames,
	inModal,
}) => {
	const [selectedTypes, setSelected] = useState<Array<string | null>>([], 'selectedTypes')

	const [currentEstate, setCurrentEstate] = useState<Estate | null>(null, 'currentEstate')

	const selectionHandler = (type: string) =>
		setSelected(state => (includes(state, type) ? state.filter(t => t !== type) : [...state, type]))

	let estateTypes: any[] = hideUnselected
		? types.filter(({ type }) => includes(selected, type))
		: types

	if (estates) {
		estateTypes = estates
	}

	return (
		<View className={`estate-types${isVert ? ' vert-layout' : ''}`}>
			{estateTypes.map((estateForRender: Estate) => {
				const { type, typeFull, id, status } = estateForRender
				const isSelected = selected ? includes(selected, type) : includes(selectedTypes, type)
				return isStatic ? (
					<div
						key={type}
						className={`estate-type estate-type-static${isSelected ? ' selected' : ''}`}
					>
						<Icon type={type as IconType} />
						{showFullNames ? <span className='full-name'>{typeFull}</span> : null}
						{isVert ? <span className='status'>{status}</span> : null}
					</div>
				) : (
					<Btn
						key={type}
						onClick={() => {
							if (inModal) {
								setCurrentEstate(estateForRender)
							} else if (onSelect) onSelect(id || (type as EstateTypeEnum))
							else selectionHandler(type)
						}}
						className={`estate-type estate-type-btn${isSelected ? ' selected' : ''}`}
					>
						<Icon type={type as IconType} />
						{showFullNames ? <span className='full-name'>{typeFull}</span> : null}
						{isVert ? <span className='status'>{status}</span> : null}
					</Btn>
				)
			})}
			{inModal ? (
				<Modal
					show={!!currentEstate}
					closeHandler={() => setCurrentEstate(null)}
					size='med'
					titleText={`${capitalize(currentEstate?.typeFull)} Estate`}
				>
					{currentEstate?.id ? <GenEstateModal estateId={currentEstate.id} /> : null}
				</Modal>
			) : null}
		</View>
	)
}

export default EstateTypes
