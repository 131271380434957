import React from 'react'
import styled from 'styled-components'
import {
	DocRelInputs,
	DocUpdateInputs,
	useDocForFormQuery,
	useUpdateDocMutation,
} from '../../../gql_generated/graphql'
import { ParentType } from '../../../Types'
import { defaultDocVals, sanitizeDataForForm } from '../../../utils'
import { Async, ErrorBlock, Form, SubmitBtn } from '../../UI'
import { docYupSchema, OpsDocFields } from './Ops.Doc.fields'

export const OpsDocUpdateView = styled.div`
	.create-doc-fields {
		max-width: 600px;
		margin: 0 auto;
		padding: 2em 0;
	}
`

export type OpsDocUpdateProps = {
	id: number
	cb?: (docId: number) => void
	parentId?: number
	parentType?: ParentType
	submitBtnTxt?: string
}
export const OpsDocUpdate: React.FC<OpsDocUpdateProps> = ({
	id,
	cb,
	parentId,
	parentType,
	submitBtnTxt,
}) => {
	const [results] = useDocForFormQuery({ variables: { id } })

	const initialVals: DocUpdateInputs = sanitizeDataForForm(results.data?.doc, {
		stripKeys: ['og', 'docRel'],
	})

	const [{ error }, updateDoc] = useUpdateDocMutation()
	const updateDocHandler = async (vals: DocUpdateInputs) => {
		const data = {
			...vals,
		}

		if (!data.book) data.book = null
		if (!data.page) data.page = null
		if (!data.mediaId) data.mediaId = null
		if (!data.dateRecorded) data.dateRecorded = data.dateEffective

		if (parentId && parentType) {
			const relData: DocRelInputs = {}
			if (parentType === 'sale') relData.saleId = parentId
			else if (parentType === 'acquisition') relData.acquisitionId = parentId
			else if (parentType === 'lease') relData.leaseId = parentId
			else if (parentType === 'agreement') relData.agreementId = parentId
			else if (parentType === 'easement') relData.easementId = parentId

			data.relatedResources = [relData]
		}

		if (!data.isRecDoc) {
			data.dateRecorded = null
			data.recDocId = null
			data.book = null
			data.county = null
			data.page = null
			data.state = null
		}

		try {
			const results = await updateDoc({
				data,
			})

			if (cb && results?.data) cb(results.data.updateDoc.id)
		} catch (err) {}
	}

	return (
		<OpsDocUpdateView>
			<ErrorBlock error={error} />
			<Async fetchResults={results}>
				{initialVals ? (
					<Form
						legend='Update Doc'
						initialValues={{
							...defaultDocVals,
							...initialVals,
							dateRecorded:
								initialVals.dateRecorded ||
								initialVals.dateEffective ||
								defaultDocVals.dateRecorded,
							dateEffective:
								initialVals.dateEffective ||
								initialVals.dateRecorded ||
								defaultDocVals.dateEffective,
						}}
						onSubmit={updateDocHandler}
						validationSchema={docYupSchema}
					>
						{() => (
							<div className='create-doc-fields'>
								<OpsDocFields />

								<SubmitBtn>{submitBtnTxt || 'Save Doc'}</SubmitBtn>
							</div>
						)}
					</Form>
				) : null}
			</Async>
		</OpsDocUpdateView>
	)
}
