import { useField } from 'formik'
import toNumber from 'lodash/toNumber'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Btn, ErrorBlock, Modal, Spinner } from '..'
import { useGetLeaseTypeQuery } from '../../../gql_generated/graphql'
import LeaseTypeSelect from '../../Ops/Ops.Leases/Ops.LeaseType.select'
import { useModalCtrl } from '../Modal'
import FormFieldWrap, { FormFieldWrapProps } from './Form.field.wrap'
import { InputProps } from './Input'

const View = styled.div`
	.select-lease-type-btn {
		position: relative;
	}
`

type AddLeaseTypeFieldProps = InputProps & FormFieldWrapProps & {}
const AddLeaseTypeField: React.FC<AddLeaseTypeFieldProps> = ({
	name,
	label,
	isReq,
	helperTxt,
	...props
}) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [{ value }, { touched }, { setValue, setTouched }] = useField({ name, ...props })
	const [{ data, error, fetching }, getLeaseType] = useGetLeaseTypeQuery({
		variables: { id: toNumber(value) },
		pause: !value,
	})

	const { getLeaseTypeById: leaseType } = data || {}
	const [[showModal, openModal, closeModal, modalContent]] = useModalCtrl()

	const isMounted = useRef(true)

	useEffect(() => {
		if (isMounted.current && !fetching && value && value !== leaseType?.id)
			getLeaseType({ variables: { id: toNumber(value) } })

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, fetching])

	useEffect(() => {
		if (leaseType?.id && leaseType.id !== value) setValue(leaseType.id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leaseType])

	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [])

	return (
		<FormFieldWrap
			name={name}
			label={label}
			isReq={isReq}
			type='select-lease-type'
			helperTxt={helperTxt}
		>
			<View>
				{error ? <ErrorBlock error={error} /> : null}
				<Btn className='select-lease-type-btn' onClick={() => openModal(true)} disabled={fetching}>
					{fetching ? <Spinner /> : leaseType?.name || 'Add Lease Type...'}
				</Btn>
				<Modal
					show={showModal}
					closeHandler={() => {
						if (!touched) setTouched(true)
						closeModal()
					}}
					titleText='Add lease type'
				>
					{modalContent ? (
						<LeaseTypeSelect
							cb={leaseType => {
								if (!touched) setTouched(true)
								setValue(leaseType.id)
								setTimeout(closeModal, 100)
							}}
						/>
					) : null}
				</Modal>
			</View>
		</FormFieldWrap>
	)
}
export default AddLeaseTypeField
