import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import {
	Lease,
	LeaseUpdateStatusInput,
	useUpdateLeaseStatusMutation,
} from '../../../gql_generated/graphql'
import { formatDate, parseDateForServer } from '../../../utils'
import { Form, SubmitBtn, Text } from '../../UI'

const OpTerminateLeaseView = styled.div``

const schema = Yup.object().shape({
	dateTerminated: Yup.string().required('Please enter a date for termination'),
})

type OpTerminateLeaseProps = {
	lease: Lease
	cb?: () => void
}
const OpTerminateLease: React.FC<OpTerminateLeaseProps> = ({ lease, cb }) => {
	const { id } = lease
	const [_, updateStatus] = useUpdateLeaseStatusMutation()

	return (
		<OpTerminateLeaseView>
			<Form
				legend='Terminate Lease'
				initialValues={{
					dateTerminated: formatDate(new Date(), 'input'),
				}}
				onSubmit={async (data: LeaseUpdateStatusInput) => {
					try {
						await updateStatus({
							data: {
								id,
								dateTerminated: parseDateForServer(data.dateTerminated),
							},
						})
						if (cb) cb()
					} catch (err) {}
				}}
				validationSchema={schema}
			>
				{() => (
					<>
						<Text type='date' name='dateTerminated' label='Date Terminated' isReq />
						<SubmitBtn>Terminate Lease</SubmitBtn>
					</>
				)}
			</Form>
		</OpTerminateLeaseView>
	)
}
export default OpTerminateLease
