import isFunction from 'lodash/isFunction'
import React, { useRef } from 'react'
import { useCreateAgreementMutation } from '../../../gql_generated/graphql'
import { defaultAgreementVals, parseDateForServer } from '../../../utils'
import { Form, SubmitBtn } from '../../UI'
import AgreementFields, { agreementSchema } from './Ops.Agreement.fields'

type CreateAgreementProps = {
	cb?: (data: any) => void
}
const CreateAgreement: React.FC<CreateAgreementProps> = ({ cb }) => {
	const [_, createAgreement] = useCreateAgreementMutation()
	const stopRender = useRef(false) // component should no longer render markup

	return stopRender.current ? null : (
		<Form
			legend='Create New Agreement'
			initialValues={defaultAgreementVals}
			onSubmit={async (vals: any) => {
				try {
					const { ...data } = vals
					// console.log('creating agreement with data: ', data)
					data.orgDbaId = data.orgDbaId || null
					data.dateEffective = parseDateForServer(vals.dateEffective)
					data.dateShouldExpire = parseDateForServer(vals.dateShouldExpire)
					const results = await createAgreement({ data })
					stopRender.current = true
					if (isFunction(cb)) cb(results)
				} catch (err) {
					console.error(err)
				}
			}}
			validationSchema={agreementSchema}
		>
			{() => (
				<>
					<AgreementFields />
					<SubmitBtn />
				</>
			)}
		</Form>
	)
}
export default CreateAgreement
