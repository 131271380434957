import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import {
	Acquisition,
	AcquisitionUpdateStatusInput,
	useUpdateAcquisitionsStatusMutation,
} from '../../../gql_generated/graphql'
import { formatDate, parseDateForServer } from '../../../utils'
import { Form, SubmitBtn, Text, Textarea } from '../../UI'

const OpForecloseAcquisitionView = styled.div``

const schema = Yup.object().shape({
	dateForeclosed: Yup.string().required('Please enter a date for foreclosure'),
	foreclosureReason: Yup.string().required('Please enter a reason for foreclosure'),
})

type OpForecloseAcquisitionProps = {
	acquisition: Acquisition
	cb?: () => void
}
const OpForecloseAcquisition: React.FC<OpForecloseAcquisitionProps> = ({ acquisition, cb }) => {
	const { id } = acquisition
	const [_, updateStatus] = useUpdateAcquisitionsStatusMutation()

	return (
		<OpForecloseAcquisitionView>
			<Form
				legend='Foreclose Acquisition'
				initialValues={{
					dateForeclosed: formatDate(new Date(), 'input'),
					foreclosureReason: '',
				}}
				onSubmit={async (data: AcquisitionUpdateStatusInput) => {
					try {
						await updateStatus({
							data: {
								...data,
								id,
								dateForeclosed: parseDateForServer(data.dateForeclosed),
							},
						})
						if (cb) cb()
					} catch (err) {}
				}}
				validationSchema={schema}
			>
				{() => (
					<>
						<Text type='date' name='dateForeclosed' label='Foreclosure Date' isReq />
						<Textarea name='foreclosureReason' label='Foreclosure Reason' isReq />
						<SubmitBtn>Foreclose Acquisition</SubmitBtn>
					</>
				)}
			</Form>
		</OpForecloseAcquisitionView>
	)
}
export default OpForecloseAcquisition
