/* eslint-disable @typescript-eslint/no-unused-vars */
import { useField } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { ContactInput } from '../../../gql_generated/graphql'
import { defaultContactVals } from '../../../utils'
import { ArrayField, Btn, Card } from '../../UI'
import { ContactFields } from './Ops.Org.fields'

const OrgContactsFormView = styled.div`
	.array-field {
		border: none;
		padding: 0;
	}
	.card {
		margin-bottom: 1em;
		&:last-child {
			margin: 0;
		}
	}
`

type OrgContactsFormProps = {
	orgId?: number
}
const OrgContactsForm: React.FC<OrgContactsFormProps> = ({ orgId }) => {
	const [{ value: contacts }, _, { setValue }] = useField<ContactInput[]>({ name: 'contacts' })

	const addContact = () => {
		setValue([
			...(contacts || []),
			{ ...(defaultContactVals as ContactInput), orgId: orgId as number },
		])
	}
	return (
		<OrgContactsFormView>
			{contacts?.length ? (
				<div className='contacts-cards'>
					<ArrayField
						name='contacts'
						render={() =>
							contacts.map((contact, idx) => (
								<Card
									key={idx}
									noShadow
									titleText={
										contact.firstName
											? `${contact.firstName}${contact.lastName ? ` ${contact.lastName}` : ''}`
											: 'New Contact'
									}
								>
									<ContactFields namePrefix={`contacts.${idx}.`} isSimple />
								</Card>
							))
						}
					/>
				</div>
			) : null}
			<Btn onClick={addContact}>Add Contact</Btn>
		</OrgContactsFormView>
	)
}
export default OrgContactsForm
