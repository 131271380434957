import { useEasementForGranteeQuery } from '../../../../gql_generated/graphql'
import { TableBodyCellComponent } from '../../Table/Table.types'
import { ColConfig } from './Col.config.types'
import { ResourceTableCellAsync } from './Table.resource.cell.Async'

const Grantee: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useEasementForGranteeQuery({
		variables: { id },
	})
	const { grantee } = fetchResults.data?.easement || {}
	// console.log('grantee sale: ', fetchResults.data?.sale)
	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{grantee?.name || 'Unknown'}
		</ResourceTableCellAsync>
	)
}

export const easementDateEffectiveColConfig: ColConfig = {
	field: 'dateEffective',
	format: 'date',
	label: 'Effective',
	sortField: 'dateEffective',
}

export const easementEscrowIdColConfig: ColConfig = {
	field: 'escrowId',
	label: 'Escrow ID',
	sortField: 'escrowId',
	hideMobile: true,
}

export const easementGranteeColConfig: ColConfig = {
	label: 'Grantee',
	sortField: 'grantee.name',
	Component: Grantee,
}

export const easementTypeColConfig: ColConfig = {
	field: 'easementType',
	label: 'Type',
	hideMobile: true,
}

export const easementStatusColConfig: ColConfig = {
	field: 'status',
	format: 'status',
	label: 'Status',
	hideMobile: true,
}

export const easementsPageColsConfig: ColConfig[] = [
	easementDateEffectiveColConfig,
	easementEscrowIdColConfig,
	easementGranteeColConfig,
	easementTypeColConfig,
	easementStatusColConfig,
]

export const parcelsRelatedEasementsColsConfig: ColConfig[] = [
	easementDateEffectiveColConfig,
	easementEscrowIdColConfig,
	easementGranteeColConfig,
	easementTypeColConfig,
	easementStatusColConfig,
]

export const saleRelatedEasementsColsConfig: ColConfig[] = [
	easementDateEffectiveColConfig,
	easementEscrowIdColConfig,
	easementGranteeColConfig,
	easementTypeColConfig,
	easementStatusColConfig,
]

export const docRelatedEasementsColsConfig: ColConfig[] = [
	easementDateEffectiveColConfig,
	easementEscrowIdColConfig,
	easementGranteeColConfig,
	easementTypeColConfig,
	easementStatusColConfig,
]

export const orgRelatedEasementsColsConfig: ColConfig[] = [
	easementDateEffectiveColConfig,
	easementEscrowIdColConfig,
	easementGranteeColConfig,
	easementTypeColConfig,
	easementStatusColConfig,
]

export const researchRelatedEasementsColsConfig: ColConfig[] = [
	easementDateEffectiveColConfig,
	easementEscrowIdColConfig,
	easementGranteeColConfig,
	easementTypeColConfig,
	easementStatusColConfig,
]
