import { RouteComponentProps, Router } from '@reach/router'
import React from 'react'
import Protect from '../Components/common/Protect'
import { UserRole } from '../gql_generated/document_types'
import Page404 from '../Pages/404.page'
import Doc from '../Pages/Doc'
import Docs from '../Pages/Docs'

const DocRoutes: React.FC<RouteComponentProps> = () => {
	return (
		<Protect allowed={[UserRole.Landman, UserRole.Accountant]}>
			<Router>
				<Docs path='/' />
				<Doc path='/:id' />
				<Page404 default />
			</Router>
		</Protect>
	)
}
export default DocRoutes
