import React from 'react'
import styled from 'styled-components'
import { EstateTypeEnum, ExhibitLetter } from '../../../Types'
import { Parcel, useParcelsForExhibitPdfQuery } from '../../../gql_generated/graphql'
import { TablePrint } from '../../UI'
import { ExhibitFormResource } from '../Exhibit/Exhibit.form'
import ExhibitPdfTitles from './Exhibit.pdf.titles'
import ExhibitPdfTotals from './Exhibit.pdf.totals'
import {
	ExhibitPdfPrintRowsAndAcres,
	genExhibitPdfPrintRowsFromActivity,
	genExhibitPdfPrintRowsFromParcels,
} from './Exhibit.pdf.utils'

const ExhibitPDFView = styled.div`
	font-size: 12px;
	.pdf-titles {
		margin-bottom: 0.3in;
	}

	.section-footer {
		border-top: 1px solid;
		display: inline;
		width: auto;
		em {
			margin-right: 2em;
		}
		.section-total {
			text-align: right;
			display: inline-flex;
			width: 100%;
			padding-top: 4px;
		}
	}

	.totals {
		display: flex;
		align-items: center;
		padding: 10px 0;
		font-weight: 700;
		.val {
			margin-left: auto;
		}
	}
`

export type ExhibitPdfConfig = {
	letter: ExhibitLetter
	showApns: boolean
	includeAssigned?: boolean
	includeTerminated?: boolean
	title: string
	subTitle?: string
	hideDate: boolean
	date?: string
}

type ExhibitPDFProps = {
	config: ExhibitPdfConfig
	estateType: EstateTypeEnum
	resource?: ExhibitFormResource
	parcelIds: number[]
}
const ExhibitPDF: React.FC<ExhibitPDFProps> = ({ config, estateType, parcelIds, resource }) => {
	const { lease, agreement, sale, acquisition, easement } = resource || {}
	const [results] = useParcelsForExhibitPdfQuery({ variables: { parcelIds } })
	const parcels = results.data?.parcels

	const activity =
		lease?.activity ||
		agreement?.activity ||
		sale?.activity ||
		acquisition?.activity ||
		easement?.activity

	const { printRows, acres } = parcels?.length
		? genExhibitPdfPrintRowsFromParcels(parcels as Parcel[], estateType, config)
		: activity?.length
		? genExhibitPdfPrintRowsFromActivity(activity, estateType, config)
		: ({} as ExhibitPdfPrintRowsAndAcres)

	return (
		<ExhibitPDFView>
			<ExhibitPdfTitles config={config} />
			<TablePrint
				body={{
					rows: printRows,
				}}
			/>
			<ExhibitPdfTotals totalAcres={acres} />
		</ExhibitPDFView>
	)
}
export default ExhibitPDF
