import React from 'react'
import styled from 'styled-components'
import { Btn, Icon } from '..'
import Protect, { ProtectProps } from '../../common/Protect'

export const EditBtnView = styled.div`
	display: inline-flex;
`

export type EditBtnProps = {
	allowed?: ProtectProps['allowed']
	onClick: Function
	className?: string
}
export const EditBtn: React.FC<EditBtnProps> = ({ allowed, onClick, className }) => {
	return (
		<Protect allowed={allowed} showDenied={false}>
			<EditBtnView className={`edit-btn${className ? ` ${className}` : ''}`}>
				<Btn onClick={onClick}>
					<Icon type='edit' />
				</Btn>
			</EditBtnView>
		</Protect>
	)
}
