import React from 'react'
import styled, { css } from 'styled-components'
import { GenDocModal } from '../../Components/common/Doc/Doc.gen.modal'
import { DateRangeField } from '../../Components/UI/Form.Fields/Daterange.field'
import { docsPageRecordedDocsColsConfig } from '../../Components/UI/Table.resources/Table.resource.col.configs/Doc.col.configs'
import { ResourcesTable } from '../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../Components/UI/Table.resources/usePaginationParams'
import { DocFilter } from '../../gql_generated/document_types'
import { usePaginatedDocsQuery } from '../../gql_generated/graphql'
import { useAdvancedSearch } from '../../utils'

const docsPageStyles = css``

const DocsPageAcquisitionsView = styled.div`
	${docsPageStyles}
`

type DocsPageAcquisitionsProps = {}
const DocsPageAcquisitions: React.FC<DocsPageAcquisitionsProps> = () => {
	const [advancedSearch, { daterange }] = useAdvancedSearch<{
		daterange: [startDate: string, endDate: string]
	}>({
		fields: [
			{
				name: 'daterange',
				label: 'Rec Date Range',
				Component: DateRangeField,
				defaultVal: ['', ''],
			},
		],
	})
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedDocsQuery({
		variables: {
			params: {
				...paginationStore.params,
				filterOptions: [
					{
						filter: DocFilter.Recorded,
					},
					{
						filter: DocFilter.Dates,
						vals: daterange[0] ? daterange : null,
					},
				],
			},
		},
	})
	const { data } = fetchResults || {}

	const docs = data?.page?.docs
	return (
		<DocsPageAcquisitionsView>
			<ResourcesTable
				dataset={docs}
				colsConfig={docsPageRecordedDocsColsConfig}
				paginationStore={paginationStore}
				fetchResults={fetchResults}
				includeSearch
				modalRenderFunc={data => <GenDocModal docId={data.id} />}
				resourceType='doc'
				advancedSearch={advancedSearch}
			/>
		</DocsPageAcquisitionsView>
	)
}
export default DocsPageAcquisitions
