import React from 'react'
import styled from 'styled-components'
import { useStoreState } from '../../store'
import { H1, Link, Spinner } from '../UI'
import { IconType } from '../UI/Icon/fa.defaults'
import { HeaderPageIcon } from './Header.pageIcon'

export const MgrHeaderPageInfoView = styled.div`
	display: flex;
	align-items: center;
	overflow: hidden;
	height: 100%;
	padding-left: ${props => props.theme.sizes.gutter.mobile.val};

	${props => props.theme.media.tablet} {
		padding-left: 40px;
	}

	.page-icon {
		padding-right: 1em;
		display: none;

		${props => props.theme.media.tablet} {
			padding-right: 0;
			position: absolute;
			height: 300px;
			width: 300px;
			top: 50%;
			left: 0;
			transform: translate(-60%, -50%);
			border-radius: 400px;
			background: ${props => props.theme.colors.grey.tint(30).val};
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding-right: 30px;
		}

		${props => props.theme.media.sdesk} {
			transform: translate(-50%, -50%);
		}

		.icon {
			height: 30px;
			width: auto;

			${props => props.theme.media.tablet} {
				height: 40px;
				width: auto;
			}
		}
	}
	.info {
		display: flex;
		height: 100%;
		align-items: center;
		${props => props.theme.media.tablet} {
			padding-left: 60px;
		}
		${props => props.theme.media.sdesk} {
			padding-left: 90px;
		}
	}
	.titles {
		${props => props.theme.media.sdesk} {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex-grow: 1;
			padding: 0.5em 0;
		}

		h1 {
			font-size: 1.2rem;
			font-weight: 400;
			color: ${props => props.theme.colors.grey.light().val};

			${props => props.theme.media.tablet} {
				font-size: 1.5rem;
			}

			${props => props.theme.media.sdesk} {
				text-transform: uppercase;
				color: ${props => props.theme.colors.white.val};
				font-weight: 700;
				&.loading {
					min-width: 100px;
					height: 1em;
				}
			}
		}
	}
`

export type MgrHeaderPageInfoProps = {
	className?: string
}
export const MgrHeaderPageInfo: React.FC<MgrHeaderPageInfoProps> = ({ className }) => {
	const { pageTitle, icon } = useStoreState(state => state.page)

	return (
		<MgrHeaderPageInfoView className={`page-info${className ? ` ${className}` : ''}`}>
			<div className='page-icon'>
				<HeaderPageIcon icon={icon as IconType} isLoading={!icon} />
			</div>
			<Link to='/mgr' className='info'>
				<div className='titles'>
					<H1 className={pageTitle ? '' : 'loading'}>
						{pageTitle ? pageTitle : <Spinner noBg size={10} />}
					</H1>
				</div>
			</Link>
		</MgrHeaderPageInfoView>
	)
}
