import React from 'react'
import styled from 'styled-components'

const View = styled.label``

type FormLabelProps = React.HtmlHTMLAttributes<HTMLLabelElement> & {
	isReq?: boolean
	htmlFor?: string
}
const FormLabel: React.FC<FormLabelProps> = ({ isReq, htmlFor, children }) => {
	return (
		<View htmlFor={htmlFor}>
			{isReq ? <span className='asterix'>*</span> : null}
			{children}
		</View>
	)
}
export default FormLabel
