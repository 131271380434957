import React from 'react'
import styled, { css } from 'styled-components/macro'
import {
	DocRelOptionsInput,
	useEstateActivityByParentQuery,
} from '../../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../../Types'
import { reduce, uniq } from '../../../../utils/lodash.utils'
import { Async, Checkbox, Form, H4, Icon, SubmitBtn, ToggleField } from '../../../UI'
import { IconType } from '../../../UI/Icon/fa.defaults'
import { AddDocToResourceConfirmProps } from './Ops.AddDocToResource.confirm'
import { AddDocToResourceConfirmParcelsConfigPreview } from './Ops.AddDocToResource.confirm.parcelsConfig.preview'

export const addDocToResourceConfirmConfigStyles = css`
	.estates,
	.counties {
		padding-left: 1em;
	}
`

const AddDocToResourceConfirmParcelsConfigView = styled.div`
	${addDocToResourceConfirmConfigStyles}
`

function formValReducer<T>(obj: any, key: T): { [Property in keyof T]: boolean } {
	const types = obj as any
	types[key] = false
	return types
}

function submitValReducer(arry: Array<string>, includeType: boolean, type: string): string[] {
	if (includeType) return [...arry, type]
	else return arry
}

export type AddDocToResourceConfirmParcelsConfigProps = Pick<
	AddDocToResourceConfirmProps,
	'parentType' | 'submitHandler' | 'parentId'
> & {}
export const AddDocToResourceConfirmParcelsConfig: React.FC<AddDocToResourceConfirmParcelsConfigProps> = ({
	parentType,
	parentId,
	submitHandler,
}) => {
	const [activityResults] = useEstateActivityByParentQuery({ variables: { parentId, parentType } })

	let estateTypes: EstateTypeEnum[] = []
	let counties: string[] = []

	activityResults.data?.activity?.forEach(({ estateType, parcel }) => {
		estateTypes.push(estateType as EstateTypeEnum)
		counties.push(parcel.county)
	})

	estateTypes = uniq(estateTypes)
	counties = uniq(counties)

	return (
		<AddDocToResourceConfirmParcelsConfigView>
			<H4>Configure Doc parcels</H4>
			<Async fetchResults={activityResults}>
				<Form
					initialValues={{
						autoAddParcels: false,
						configureEstates: false,
						configureCounties: false,
						estateTypes: estateTypes.reduce(formValReducer, {}),
						counties: counties.reduce(formValReducer, {}),
					}}
					onSubmit={(vals: any) => {
						const opts: DocRelOptionsInput = {
							estateTypes: vals.configureEstates
								? reduce(vals.estateTypes, submitValReducer, [])
								: estateTypes,
							counties: vals.configureCounties
								? reduce(vals.counties, submitValReducer, [])
								: counties,
						}

						submitHandler(vals.autoAddParcels ? opts : {})
					}}
				>
					{({ values, setFieldValue }) => {
						/**
						 *  Limit estate type options based on counties selected
						 * */
						const estateTypesForRender =
							values.configureCounties && Object.values(values.counties).some(isUsed => isUsed)
								? estateTypes.filter(type => {
										// If there are counties configured we want to make sure that at least one of them has this estate type available
										return !!activityResults.data?.activity?.some(
											({ parcel, estateType }) =>
												values.counties[parcel.county] && estateType === type
										)
								  })
								: estateTypes
						if (values.configureEstates && estateTypesForRender.length === 1)
							setFieldValue('configureEstates', false)

						/**
						 *  Limit county options based on estate types selected
						 * */
						const countiesForRender =
							values.configureEstates && Object.values(values.estateTypes).some(isUsed => isUsed)
								? counties.filter(county => {
										// If there are estate types configured we want to make sure that at least one of them is this county activity
										return !!activityResults.data?.activity?.some(
											({ parcel, estateType }) =>
												values.estateTypes[estateType] && parcel.county === county
										)
								  })
								: counties
						if (values.configureCounties && countiesForRender.length === 1)
							setFieldValue('configureCounties', false)

						return (
							<>
								<ToggleField
									name='autoAddParcels'
									label={`Auto-add parcels to doc?`}
									helperTxt={`Automatically add parcels on this ${parentType} to doc`}
								/>
								{values.autoAddParcels ? (
									<section>
										{estateTypesForRender?.length > 1 ? (
											<div className='estate-selectors'>
												<ToggleField
													name='configureEstates'
													label='Add specific estates?'
													helperTxt={`Default will add: ${estateTypes.join(', ')} estates`}
												/>
												{values.configureEstates ? (
													<div className='estates'>
														{estateTypesForRender.map(estateType => (
															<Checkbox
																key={estateType}
																name={`estateTypes.${estateType}`}
																label={
																	<>
																		<Icon type={estateType as IconType} padRight />{' '}
																		{`Add only ${estateType} estates on each parcel`}
																	</>
																}
															/>
														))}
													</div>
												) : null}
											</div>
										) : null}
										{countiesForRender?.length > 1 ? (
											<div className='county-selectors'>
												<ToggleField
													name='configureCounties'
													label='Add specific counties?'
													helperTxt={`Default will add parcels in: ${counties.join(', ')} counties`}
												/>
												{values.configureCounties ? (
													<div className='counties'>
														{countiesForRender.map(county => (
															<Checkbox
																key={county}
																name={`counties.${county}`}
																label={`Add all parcels in ${county} county`}
															/>
														))}
													</div>
												) : null}
											</div>
										) : null}
										<AddDocToResourceConfirmParcelsConfigPreview
											parentType={parentType}
											values={values as any}
											counties={countiesForRender}
											estateTypes={estateTypesForRender}
										/>
									</section>
								) : null}
								<SubmitBtn>Add doc to {parentType}</SubmitBtn>
							</>
						)
					}}
				</Form>
			</Async>
		</AddDocToResourceConfirmParcelsConfigView>
	)
}
