import { Redirect, RouteComponentProps } from '@reach/router'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useCreatePass } from '../../../auth'
import {
	Board,
	EmailField,
	ErrorBlock,
	Form,
	Link,
	P,
	PasswordField,
	SubmitBtn,
	Text,
} from '../../../Components/UI'
import { useStoreState } from '../../../store'
import { parseQueryParams, useState } from '../../../utils'
import { loginStyles } from '../Login/Login'

const ResetPassView = styled.div`
	${loginStyles}
`

const schema = Yup.object({
	password: Yup.string().required('Enter a new password'),
	code: Yup.string().required('Enter the verification code from email'),
	email: Yup.string().required('Enter the email address for your account'),
})

type ResetPassFormVals = {
	password: string
	code: string
	email: string
}

const ResetPass: React.FC<RouteComponentProps> = ({ location }) => {
	const { isAuthed } = useStoreState(state => state.user)

	const { u, c } = parseQueryParams(location?.search || '') || {}
	const email = u && decodeURIComponent(u)
	const code = c && decodeURIComponent(c)

	const [errCode, setErrCode] = useState<string | null>(null, 'errCode')

	const createPass = useCreatePass()
	const submitHandler = async (vals: ResetPassFormVals) => {
		const { password, code, email } = vals
		try {
			await createPass(password, {
				email,
				verification: code,
			})
		} catch (err: any) {
			if (err.code === 'ExpiredCodeException') setErrCode('invalidCode')
			throw err
		}
	}

	return isAuthed ? (
		<Redirect to={'/login'} noThrow />
	) : (
		<ResetPassView>
			<Board titleText='Reset password'>
				{errCode === 'invalidCode' ? (
					<>
						<ErrorBlock>Unable to verify code</ErrorBlock>
						<Link className='cta' to='/forgot-pass'>
							Resend code.
						</Link>
					</>
				) : null}
				{code && email ? null : (
					<Form
						initialValues={{
							email: email || '',
							code: code || '',
							password: '',
						}}
						onSubmit={async vals => {
							try {
								await submitHandler(vals as ResetPassFormVals)
							} catch (err) {}
						}}
						validationSchema={schema}
					>
						{({ values }) => (
							<>
								<div className='helper-text'>
									<P>An email with a verification code has been sent to {values.email}.</P>
									<Link to='/forgot-pass'>Resend code.</Link>
								</div>
								{!email && <EmailField name='email' label='Enter your email' isReq />}
								{!code && <Text name='code' label='Enter your reset code' isReq />}
								<PasswordField name='password' label='Enter new password' isReq />
								<SubmitBtn isInverted>Create Password</SubmitBtn>
							</>
						)}
					</Form>
				)}
			</Board>
		</ResetPassView>
	)
}
export default ResetPass
