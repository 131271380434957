// import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useStoreState } from '../../store'
import { hasAccess } from '../../utils'
import { Icon, Link } from '../UI'
import { IconType } from '../UI/Icon/fa.defaults'

const View = styled.div`
	nav {
		li {
			list-style: none;
		}
		.link {
			display: flex;
			align-items: center;
			color: ${props => props.theme.colors.white.val};
			overflow: hidden;
			position: relative;
			white-space: nowrap;
		}
	}

	&._display-normal {
		.nav-group {
			margin-bottom: 3em;
		}
		nav {
			.link {
				padding: 0.6em 1em;

				&:hover,
				&.active {
					color: ${props => props.theme.colors.btn.light(0).val};
				}
				&:hover {
					background: ${props => props.theme.colors.black.tint(34).val};
				}
				&.active {
					&:before {
						transform: translate3d(0, 0, 0);
					}
				}
				${props => props.theme.media.sdesk} {
					font-size: 1rem;
					padding: 0.5em 1em;
				}
				.icon {
					margin-right: 0.5em;
					${props => props.theme.media.sdesk} {
						margin-right: 0.7em;
						height: auto;
						width: 100px;
						max-height: 25px;
						max-width: 25px;
						display: flex;
					}
				}
				&:before {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 2px;
					background: ${props => props.theme.colors.btn.light(0).val};
					transition: transform ${props => props.theme.times.tranS};
					transform: translate3d(0, 105%, 0);
				}
			}
		}
	}

	&._display-in-dash {
		.nav-group {
			margin-bottom: 3em;
			background: ${props => props.theme.colors.white.tint(5).val};
			padding: 1rem;
		}

		nav {
			li {
				margin-bottom: 1rem;
				&:last-child {
					margin-bottom: 0;
				}
			}
			.link {
				padding: 1rem 2rem;
				background: ${props => props.theme.colors.white.tint(10).val};
				border-radius: 3rem;
			}
		}
	}
`

const navData = [
	{
		title: 'Primary',
		items: [
			{
				txt: 'Parcels',
				icon: 'parcel',
				to: '/mgr/parcels',
				access: ['landman', 'officer', 'accountant', 'shareholder'],
			},
			{
				txt: 'Sales',
				icon: 'sale',
				to: '/mgr/sales',
				access: ['landman', 'officer', 'accountant', 'shareholder'],
			},
			{
				txt: 'Leases',
				icon: 'lease',
				to: '/mgr/leases',
				access: ['landman', 'officer', 'accountant', 'shareholder'],
			},
			{
				txt: 'Agreements',
				icon: 'agreements',
				to: '/mgr/agreements',
				access: ['landman', 'officer', 'accountant', 'shareholder'],
			},
			{
				txt: 'Easements',
				icon: 'easement',
				to: '/mgr/easements',
				access: ['landman', 'officer', 'accountant', 'shareholder'],
			},
			{
				txt: 'Acquisitions',
				icon: 'acquisition',
				to: '/mgr/acquisitions',
				access: ['landman', 'officer', 'accountant', 'shareholder'],
			},
		],
	},
	{
		title: 'Secondary',
		items: [
			{
				txt: 'Royalties',
				icon: 'royalties',
				to: '/mgr/royalties',
				access: ['landman', 'officer', 'accountant', 'shareholder'],
			},
			{
				txt: 'Docs',
				icon: 'doc',
				to: '/mgr/docs',
				access: ['landman', 'officer', 'accountant', 'shareholder'],
			},
		],
	},
	{
		title: 'Misc',
		items: [
			{
				txt: 'Activity Logs',
				icon: 'logs',
				to: '/mgr/logs',
				access: ['landman', 'accountant', 'officer'],
			},
			{
				txt: 'Research',
				icon: 'research',
				to: '/mgr/research',
				access: ['landman', 'officer', 'accountant', 'shareholder'],
			},
			{
				txt: 'Orgs',
				icon: 'org',
				to: '/mgr/orgs',
				access: ['landman', 'officer', 'accountant', 'shareholder'],
			},
			{
				txt: 'Reports',
				icon: 'report',
				to: '/mgr/reports',
				access: ['landman', 'officer', 'accountant'],
			},
			{
				txt: 'Users',
				icon: 'users',
				to: '/mgr/users',
				access: ['landman', 'officer'],
			},
		],
	},
]

type NavMgrProps = {
	className?: string
	displayType?: 'normal' | 'in-dash'
}
const NavMgr = ({ className, displayType = 'normal' }: NavMgrProps): JSX.Element => {
	const { role, isAdmin } = useStoreState(state => state.user)

	return (
		<View className={`resources${className ? ` ${className}` : ''} _display-${displayType}`}>
			{navData.map(({ items, title }) => (
				<div key={title} className='nav-group'>
					<nav>
						{items
							.filter(({ access }) => isAdmin || hasAccess(role, access))
							.map(({ txt, to, icon }) => (
								<li key={txt}>
									<Link to={to}>
										<Icon type={icon as IconType} />
										<span className='txt'>{txt}</span>
									</Link>
								</li>
							))}
					</nav>
				</div>
			))}
		</View>
	)
}
export default NavMgr
