import isFunction from 'lodash/isFunction'
import isPlainObject from 'lodash/isPlainObject'
import isString from 'lodash/isString'
import round from 'lodash/round'
import startCase from 'lodash/startCase'
import toUpper from 'lodash/toUpper'
import { ReactNode } from 'react'
import styled from 'styled-components'
import OrgLink from '../Components/common/Org.link'
import { CopyBtn, Icon, MailTo, Tel, Trunc } from '../Components/UI'
import { IconType } from '../Components/UI/Icon/fa.defaults'
import { Org } from '../gql_generated/graphql'
import { asAcres, formatCurrency, formatDate, parseStatus } from './utils'

const TextCopyValue = styled.div`
	display: flex;
	align-items: center;
`

export type FormatValueOptions =
	| 'upper'
	| 'proper'
	| 'date'
	| 'acres'
	| 'currency'
	| 'status'
	| 'icon'
	| 'org'
	| 'phone'
	| 'email'
	| 'boolean'
	| 'trunc'
	| 'round'
	| 'percentage'

export type FormatValueObj = {
	date?: string
	format?: FormatValueOptions
	withCopyPaste?: boolean
	emptyVal?: ReactNode
}
const formatValue = (val: any, format?: FormatValueOptions | FormatValueObj): ReactNode | null => {
	let value: any = val
	let options = format as FormatValueObj
	const emptyVal = options?.emptyVal || 'N/A'

	if (isFunction(format)) value = format(val)
	else if (isPlainObject(format)) format = options.format as FormatValueOptions

	if (isString(format)) {
		if (format === 'upper' && typeof val === 'string') value = toUpper(val)
		else if (format === 'proper' && typeof val === 'string') value = startCase(val)
		else if (format === 'date') value = formatDate(val, options?.date) || emptyVal
		else if (format === 'acres') value = asAcres(val)
		else if (format === 'currency') value = formatCurrency(val)
		else if (format === 'round') value = round(val, 2)
		else if (format === 'status') value = parseStatus(val).txt
		else if (format === 'icon' && typeof val === 'string') value = <Icon type={val as IconType} />
		else if (format === 'org') value = <OrgLink org={val as Org} />
		else if (format === 'phone')
			value = val ? (
				<TextCopyValue className='val-copy'>
					<Tel>{val}</Tel>
					{options?.withCopyPaste ? <CopyBtn content={val} /> : null}
				</TextCopyValue>
			) : (
				emptyVal
			)
		else if (format === 'email')
			value = val ? (
				<TextCopyValue className='val-copy'>
					<MailTo>{val}</MailTo>
					{options?.withCopyPaste ? <CopyBtn content={val} /> : null}
				</TextCopyValue>
			) : (
				emptyVal
			)
		else if (format === 'boolean') value = val === true || val === 'true' ? 'Yes' : 'No'
		else if (format === 'trunc') value = <Trunc maxChars={30}>{val}</Trunc>
		else if (format === 'percentage') value = <span className='percentage'>{val}%</span>
	} else if (options?.withCopyPaste && (typeof val === 'string' || typeof val === 'number'))
		value = (
			<div className='val-copy'>
				{val}
				<CopyBtn content={val} />
			</div>
		)

	return value
}

export default formatValue
