import { action, Action } from 'easy-peasy'
import { UserRole } from '../gql_generated/document_types'
import { User } from '../gql_generated/graphql'
import { CognitoUser } from '../Types'

export type UserStoreModel = {
	cognito: CognitoUser | null
	isAuthed: boolean
	me: User | null
	role: UserRole
	dashPath: string | null
	isAdmin: boolean
	isMgr: boolean

	setCognitoUser: Action<UserStoreModel, CognitoUser>
	setUser: Action<UserStoreModel, User>
	setDashPath: Action<UserStoreModel, string>
	setIsAuthed: Action<UserStoreModel>
	clearUser: Action<UserStoreModel>
}

export const userModel: UserStoreModel = {
	// current user state
	cognito: null,
	isAuthed: false,
	me: null,
	role: UserRole.Anon,
	dashPath: null,
	isAdmin: false,
	isMgr: false,

	//set current user state
	setCognitoUser: action((state, user) => {
		if (!user?.username) return
		state.cognito = user
	}),

	setUser: action((state, user) => {
		if (!user?.email) return
		state.me = user
		state.role = user?.role?.name || UserRole.Anon
		state.dashPath = '/mgr/dash'
		state.isAdmin = user?.role?.name === 'sys_admin'
		state.isMgr =
			user?.role?.name === 'sys_admin' ||
			user?.role?.name === 'officer' ||
			user?.role?.name === 'landman'
	}),

	setDashPath: action((state, path) => {
		if (!path) return
		state.dashPath = path
	}),

	setIsAuthed: action(state => {
		state.isAuthed = true
	}),

	clearUser: action(state => {
		state.isAuthed = false
		state.role = UserRole.Anon
		state.cognito = null
		state.dashPath = null
		state.me = null
		state.isAdmin = false
	}),
}
