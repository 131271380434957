import styled, { css } from 'styled-components'
import ParcelEstates from '../../../../Components/common/Parcel/Parcel.estates'
import ParcelInfo from '../../../../Components/common/Parcel/Parcel.info'
import { Card } from '../../../../Components/UI'

export const relatedResearchModalParcelStyles = css`
	.two-cols {
		& > * {
			&:first-child {
				margin-bottom: 1rem;
			}
		}

		${props => props.theme.media.tablet} {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 1rem;

			& > * {
				margin: 0;
			}
		}
	}
`

const RelatedResearchModalParcelView = styled.div`
	${relatedResearchModalParcelStyles}
`

export type RelatedResearchModalParcelProps = {
	parcelId: number
}
export const RelatedResearchModalParcel = ({
	parcelId,
}: RelatedResearchModalParcelProps): JSX.Element => {
	return (
		<RelatedResearchModalParcelView>
			<Card titleText='Parcel Info' mBot>
				<div className='two-cols'>
					<ParcelInfo parcelId={parcelId} />
					<ParcelEstates parcelId={parcelId} />
				</div>
			</Card>
		</RelatedResearchModalParcelView>
	)
}
