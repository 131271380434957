import isFunction from 'lodash/isFunction'
import unset from 'lodash/unset'
import React, { useCallback } from 'react'
import { useCreateSaleMutation } from '../../../gql_generated/graphql'
import { defaultSaleVals, formatDate, parseDateForServer, priceToNumber } from '../../../utils'
import { Form, SubmitBtn, toastit } from '../../UI'
import SaleFields, { newSaleFieldsSchema } from './Ops.Sale.fields'

type CreateSaleProps = {
	cb?: (data: any) => void
}
const CreateSale: React.FC<CreateSaleProps> = ({ cb }) => {
	const [mutRes, createSale] = useCreateSaleMutation()

	const submitHandler = useCallback(async vals => {
		try {
			const data = {
				...vals,
			}
			if (!vals.brokerId) unset(data, 'brokerId')
			if (!vals.titleId) unset(data, 'titleId')
			if (!vals.granteeDbaId) unset(data, 'granteeDbaId')
			if (!vals.brokerDbaId) unset(data, 'brokerDbaId')
			if (!vals.titleDbaId) unset(data, 'titleDbaId')

			data.dateOpened = parseDateForServer(vals.dateOpened)
			data.dateExpected = parseDateForServer(vals.dateExpected)
			data.cbNote = priceToNumber(vals.cbNote)
			data.price = priceToNumber(vals.price)
			data.deposit = priceToNumber(vals.deposit)

			const results = await createSale({ data })

			if (results.error) throw results.error
			if (isFunction(cb)) cb(results)
		} catch (err) {
			toastit.err('Error saving sale')
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Form
			legend='Create New Sale'
			initialValues={{
				...defaultSaleVals,
				dateOpened: formatDate(new Date(), 'input'),
			}}
			onSubmit={submitHandler}
			validationSchema={newSaleFieldsSchema}
			submitErr={mutRes.error}
		>
			{() => (
				<>
					<SaleFields isNew />
					<SubmitBtn />
				</>
			)}
		</Form>
	)
}
export default CreateSale
