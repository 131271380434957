import React from 'react'
import styled, { css } from 'styled-components/macro'
import { ParcelDetail } from '../../../Components/common/Parcel/Parcel.detail'

export const relatedOrgParcelModalStyles = css``

const RelatedOrgParcelModalView = styled.div`
	${relatedOrgParcelModalStyles}
`

export type RelatedOrgParcelModalProps = {
	parcelId: number
}
export const RelatedOrgParcelModal: React.FC<RelatedOrgParcelModalProps> = ({ parcelId }) => {
	return (
		<RelatedOrgParcelModalView>
			<ParcelDetail parcelId={parcelId} />
		</RelatedOrgParcelModalView>
	)
}
