import React from 'react'
import styled, { css } from 'styled-components/macro'
import { AddBtn, Modal, useModalCtrl } from '../../../UI'
import { AddEditRoyaltyOnResource, AddEditRoyaltyOnResourceProps } from './Ops.Royalty.add.edit'

export const addRoyaltyToResoureBtnStyles = css``

const AddRoyaltyToResourceBtnView = styled.div`
	${addRoyaltyToResoureBtnStyles}
`

export type AddRoyaltyToResourceBtnProps = Omit<AddEditRoyaltyOnResourceProps, 'cb'> & {
	children?: string
}

export const AddRoyaltyToResourceBtn: React.FC<AddRoyaltyToResourceBtnProps> = ({
	children = 'Add Royalty',
	...props
}) => {
	const [[isOpen, openModal, closeModal]] = useModalCtrl()
	return (
		<AddRoyaltyToResourceBtnView>
			<AddBtn onClick={openModal}>{children}</AddBtn>
			<Modal show={isOpen} closeHandler={closeModal} titleText='Create Royalty'>
				<AddEditRoyaltyOnResource {...props} cb={closeModal} />
			</Modal>
		</AddRoyaltyToResourceBtnView>
	)
}
