import { Field } from 'formik'
import React from 'react'
import FormFieldWrap, { FormFieldWrapProps } from './Form.field.wrap'

export type InputProps = Pick<
	React.InputHTMLAttributes<HTMLInputElement>,
	'onChange' | 'type' | 'id' | 'className' | 'title' | 'disabled' | 'width' | 'readOnly'
> & {
	placeholder?: string
}

const Input: React.FC<InputProps & FormFieldWrapProps> = ({
	label,
	type = 'text',
	name,
	id,
	isReq,
	helperTxt,
	...props
}) => {
	return (
		<FormFieldWrap type={type} label={label} name={name} isReq={isReq} helperTxt={helperTxt}>
			<Field name={name} id={id || name} type={type} {...props} />
		</FormFieldWrap>
	)
}
export default Input
