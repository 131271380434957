import { navigate, RouteComponentProps } from '@reach/router'
import toNumber from 'lodash/toNumber'
import React, { useEffect } from 'react'
import Cards from '../../Components/common/CardsColumns'
import CardsSection from '../../Components/common/CardsSection'
import { PageOperation } from '../../Components/common/Operations'
import OrgDbas from '../../Components/common/Org/Org.dbas'
import OrgInfo from '../../Components/common/Org/Org.info'
import Page from '../../Components/common/Resources/Resource.page'
import OpsOrgDbasEdit from '../../Components/Ops/Ops.Orgs/Ops.Org.dbas.edit'
import EditOrg from '../../Components/Ops/Ops.Orgs/Ops.Org.edit'
import { Address, Alert, Async, Card, Colorize, Modal, P, useToaster } from '../../Components/UI'
import { useModalCtrl } from '../../Components/UI/Modal'
import { UserRole } from '../../gql_generated/document_types'
import { useDeleteOrgMutation, useOrgFullQuery } from '../../gql_generated/graphql'
import { AddressType } from '../../Types'
import { nnrMgrs, usePageData, useState } from '../../utils'
import OrgRelatedResources from './Related/Org.related'
import { RelatedOrgContacts } from './Related/Org.related.contacts'

// import { useStoreState } from '../../store'

interface orgPageProps extends RouteComponentProps {
	id?: string
}

const OrgPage: React.FC<orgPageProps> = props => {
	// const user = useStoreState(state => state.user.me)
	const id = toNumber(props.id)
	const [_, deleteOrg] = useDeleteOrgMutation()
	const [results] = useOrgFullQuery({ variables: { id } })
	const { data, fetching } = results

	const [Op, setOp] = useState<string | null>(null, 'Op')

	const toast = useToaster()

	const { org } = data || {}

	const { name, address, notes } = org || {}

	const [updatePageInfo] = usePageData({ pageType: 'org' })
	const [[showModal, openModal, closeModal]] = useModalCtrl()

	useEffect(() => {
		updatePageInfo({ pageTitle: name })
	}, [id, name, updatePageInfo])

	const operations: PageOperation[] = [
		{
			txt: 'Edit Org',
			icon: 'edit',
			op: () => setOp('edit'),
			allowed: [UserRole.Accountant, UserRole.Officer],
		},
		{
			txt: 'Delete',
			icon: 'x',
			op: () => setOp('delete'),
		},
	]

	const deleteOp = async (shouldDelete: boolean) => {
		if (shouldDelete) {
			try {
				await deleteOrg({ id })
				navigate('/mgr/orgs')
			} catch (err) {
				toast('Error deleting org', { type: 'err' })
			}
		} else setOp(null)
	}

	return (
		<Page isLoaded={!fetching} operations={operations}>
			<Async fetchResults={results}>
				<CardsSection isCollapsable>
					<Cards className='col left-col'>
						<Card titleText='Organization Info'>
							<OrgInfo orgId={id} />
						</Card>
						<Card titleText='Address'>
							<Address address={address as AddressType} format='table' />
						</Card>
						<Card titleText='DBAs' editHandler={openModal} editAllowed={nnrMgrs}>
							<OrgDbas orgId={id} />
						</Card>
					</Cards>
					<Cards className='col right-col'>
						<Card titleText='Notes'>
							<P>{notes}</P>
						</Card>
					</Cards>
					<Card titleText='Contacts'>
						<RelatedOrgContacts orgId={id} />
					</Card>
				</CardsSection>
			</Async>

			<OrgRelatedResources orgId={id} />
			<Modal show={!!Op && Op !== 'delete'} closeHandler={() => setOp(null)}>
				{Op === 'edit' ? (
					<EditOrg
						isOnOrgPage
						id={id}
						cb={() => {
							// refetch()
							setOp(null)
						}}
					/>
				) : null}
			</Modal>
			<Modal show={showModal} closeHandler={closeModal} titleText='Edit DBAs'>
				<OpsOrgDbasEdit orgId={id as number} cb={closeModal} />
			</Modal>
			{Op === 'delete' ? (
				<Alert
					msg='Are you sure you want to delete this org? (Cannot be undone)'
					cb={deleteOp}
					okBtn='Cancel'
					cancelBtn={
						<Colorize color='err' asBlock>
							Delete Permanently
						</Colorize>
					}
					useCancelAsOp
				/>
			) : null}
		</Page>
	)
}
export default OrgPage
