import { useFormikContext } from 'formik'
import styled, { css } from 'styled-components'
import { Estate, EstateStatus } from '../../../gql_generated/document_types'
import { Number, Select, Text, Textarea } from '../../UI'

export const estateFieldsStyles = css``

const EstateFieldsView = styled.div`
	${estateFieldsStyles}
`

export type EstateFieldsProps = {}
export const EstateFields = (_: EstateFieldsProps): JSX.Element => {
	const { values } = useFormikContext<Estate>()
	const { status, ownerId } = values
	const isNN = ownerId && ownerId <= 3
	return (
		<EstateFieldsView>
			{isNN ? (
				<Select
					name='ownerId'
					label='Owner'
					options={[
						{ val: 1, txt: 'NN' },
						{ val: 2, txt: 'NNR' },
						{ val: 3, txt: 'NNL' },
					]}
				/>
			) : null}
			{status === 'Conveyed' ? (
				<Text name={`status`} label='Status' disabled />
			) : (
				<Select
					name={`status`}
					label='Status'
					options={Object.values(EstateStatus)
						.filter(es => es !== EstateStatus.Conveyed)
						.map(val => ({ val }))}
				/>
			)}
			<Number name={`acres`} label='Acres' />
			<Textarea name={`aliquot`} label='Aliquot' />
		</EstateFieldsView>
	)
}
