import React from 'react'
import styled from 'styled-components'
import { Sale, useUpdateSalesStatusMutation } from '../../../gql_generated/graphql'
import { Form, P, SubmitBtn } from '../../UI'

const OpReopenSaleView = styled.div``

type OpReopenSaleProps = {
	sale: Sale
	cb?: () => void
}
const OpReopenSale: React.FC<OpReopenSaleProps> = ({ sale, cb }) => {
	const { id } = sale
	const [_, updateStatus] = useUpdateSalesStatusMutation()

	return (
		<OpReopenSaleView>
			<Form
				legend='Reopen Sale'
				initialValues={{
					dateOpened: '',
				}}
				onSubmit={async () => {
					try {
						await updateStatus({
							data: {
								dateCancelled: null,
								cancelReason: null,
								dateClosed: null,
								dateForeclosed: null,
								foreclosureReason: null,
								id,
							},
						})
						if (cb) cb()
					} catch (err) {}
				}}
			>
				{() => (
					<>
						<P>Are you sure you want to reopen this sale?</P>
						<SubmitBtn>Reopen</SubmitBtn>
					</>
				)}
			</Form>
		</OpReopenSaleView>
	)
}
export default OpReopenSale
