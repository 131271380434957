import React from 'react'
import styled, { css } from 'styled-components/macro'
import Protect from '../../../../Components/common/Protect'
import { AddParcelsToResourceBtn } from '../../../../Components/Ops/Ops.Parcels.addToResource'
import { LeftBar } from '../../../../Components/UI'
import { AssignRelatedParcelsOp } from '../../../../Components/UI/Table.resources/Table.global.ops/AssignRelatedParcels.op'
import { RemoveRelatedParcelsOp } from '../../../../Components/UI/Table.resources/Table.global.ops/RemoveParcelsFromRelated.op'
import { TerminateRelatedParcelsOp } from '../../../../Components/UI/Table.resources/Table.global.ops/TerminateRelatedParcels.op'
import { leaseAgreementRelatedParcelColsConfig } from '../../../../Components/UI/Table.resources/Table.resource.col.configs/Parcel.col.configs'
import {
	ResourcesTable,
	ResourceTableGlobalOpConfig,
} from '../../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../../Components/UI/Table.resources/usePaginationParams'
import { ParcelFilter, ParcelParent } from '../../../../gql_generated/document_types'
import { useAgreementFullQuery, usePaginatedParcelsQuery } from '../../../../gql_generated/graphql'
import { EstateTypeEnum, PrimaryResourceType } from '../../../../Types'
import { nnrMgrs } from '../../../../utils'
import { RelatedAgreementParcelModal } from '../../Related.Modals/Agreement.related.parcel.modal'

export const relatedAgreementActiveParcelStyles = css``

const RelatedAgreementActiveParcelsView = styled.div`
	${relatedAgreementActiveParcelStyles}
`

export type RelatedAgreementActiveParcelsProps = {
	agreementId: number
}
export const RelatedAgreementActiveParcels: React.FC<RelatedAgreementActiveParcelsProps> = ({
	agreementId,
}) => {
	const paginationStore = usePaginationParams()

	const [agreementResults] = useAgreementFullQuery({ variables: { id: agreementId } })
	const estateType = agreementResults.data?.agreement?.estateType as EstateTypeEnum

	const [results] = usePaginatedParcelsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: ParcelParent.Agreement,
						parentIds: [agreementId],
					},
				],
				filterOptions: [
					{
						filter: ParcelFilter.TerminatedActivity,
						options: {
							isNot: true,
						},
					},
					{
						filter: ParcelFilter.AssignedActivity,
						options: {
							isNot: true,
						},
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.parcels

	const parentProps = {
		parentId: agreementId,
		parentType: 'agreement' as PrimaryResourceType,
	}
	const tableOps: ResourceTableGlobalOpConfig[] = [
		{
			txt: 'Terminate',
			op: props => <TerminateRelatedParcelsOp {...props} {...parentProps} />,
		},
		{
			txt: 'Assign',
			op: props => <AssignRelatedParcelsOp {...props} {...parentProps} />,
		},
		{
			txt: 'Remove',
			op: props => <RemoveRelatedParcelsOp {...props} {...parentProps} />,
		},
	]
	return (
		<RelatedAgreementActiveParcelsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={leaseAgreementRelatedParcelColsConfig(agreementId, 'agreement', estateType)}
				fetchResults={results}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedAgreementParcelModal parcelId={data.id} />}
				resourceType='parcel'
				tableOps={tableOps}
				includeSearch
			>
				<Protect allowed={nnrMgrs}>
					{estateType ? (
						<LeftBar>
							<AddParcelsToResourceBtn
								config={{
									parentId: agreementId,
									parentType: 'agreement',
									estateType,
									useSingleEstateType: true,
								}}
							/>
						</LeftBar>
					) : null}
				</Protect>
			</ResourcesTable>
		</RelatedAgreementActiveParcelsView>
	)
}
