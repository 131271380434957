import React from 'react'
import styled, { css } from 'styled-components'
import { GenDocModal } from '../../Components/common/Doc/Doc.gen.modal'
import { docsPageAllDocsColsConfig } from '../../Components/UI/Table.resources/Table.resource.col.configs/Doc.col.configs'
import { ResourcesTable } from '../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../Components/UI/Table.resources/usePaginationParams'
import { usePaginatedDocsQuery } from '../../gql_generated/graphql'

const docsPageStyles = css``

const DocsPageAcquisitionsView = styled.div`
	${docsPageStyles}
`

type DocsPageAcquisitionsProps = {}
const DocsPageAcquisitions: React.FC<DocsPageAcquisitionsProps> = () => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedDocsQuery({
		variables: {
			params: {
				...paginationStore.params,
			},
		},
	})
	const { data } = fetchResults || {}

	const docs = data?.page?.docs
	return (
		<DocsPageAcquisitionsView>
			<ResourcesTable
				dataset={docs}
				colsConfig={docsPageAllDocsColsConfig}
				paginationStore={paginationStore}
				fetchResults={fetchResults}
				includeSearch
				modalRenderFunc={data => <GenDocModal docId={data.id} />}
				resourceType='doc'
			/>
		</DocsPageAcquisitionsView>
	)
}
export default DocsPageAcquisitions
