import { RouteComponentProps } from '@reach/router'
import React from 'react'
import styled from 'styled-components'
import Cards from '../../Components/common/CardsSection'
import { PageOperation } from '../../Components/common/Operations'
import Page from '../../Components/common/Resources/Resource.page'
import CreateUserForm from '../../Components/Ops/Ops.Users/Ops.User.create'
import { Card, ErrorBlock, Link, Modal, Skeleton } from '../../Components/UI'
import { UserRole } from '../../gql_generated/document_types'
import { useUsersQuery } from '../../gql_generated/graphql'
import { useCalcAccess, usePageData, useState } from '../../utils'

const UserPageView = styled.div`
	.users-list {
		max-width: 500px;
		margin: 0 auto;
		border: solid 2px ${props => props.theme.colors.secondary.light(6).desat().val};
		border-radius: 4px;
		min-height: 60vh;
		padding: 1em;
		${props => props.theme.media.tablet} {
			padding: 2em;
		}
		.link {
			display: flex;
			padding: 1em 2em;
			&:hover {
				background: ${props => props.theme.colors.grey.light(45).val};
			}
			em {
				margin-left: 2em;
			}
		}
	}
`

type UsersPageProps = RouteComponentProps & {}

const UsersPage: React.FC<UsersPageProps> = () => {
	const [Op, setOp] = useState<null | string>(null, 'Op')

	usePageData({
		pageTitle: 'Users',
		icon: 'users',
	})

	const calcAccess = useCalcAccess()

	const [{ data, fetching, error }, refetch] = useUsersQuery()

	const { allUsers } = data || {}

	const operations: PageOperation[] = [
		{
			txt: 'Invite User',
			icon: 'userAdd',
			op: () => setOp('invite'),
		},
	]

	return (
		<UserPageView>
			<Page isLoaded operations={operations}>
				<Cards>
					<Card>
						{fetching ? (
							<>
								<Skeleton />
								<Skeleton />
								<Skeleton />
							</>
						) : error ? (
							<ErrorBlock error={error} />
						) : (
							allUsers && (
								<ul className='users-list'>
									{allUsers
										.filter(({ role }) =>
											role?.name === UserRole.SysAdmin ? calcAccess([UserRole.SysAdmin]) : true
										)
										.map(({ id, firstName, email }) => (
											<li key={id}>
												<Link to={`/mgr/users/${id}`} className='user'>
													{firstName} <em>{email}</em>
												</Link>
											</li>
										))}
								</ul>
							)
						)}
					</Card>
				</Cards>
			</Page>
			<Modal show={!!Op} closeHandler={() => setOp(null)}>
				{Op === 'invite' ? (
					<CreateUserForm
						cb={() => {
							refetch()
						}}
					/>
				) : null}
			</Modal>
		</UserPageView>
	)
}
export default UsersPage
