import styled, { css } from 'styled-components/macro'
import { useDeleteFileMutation } from '../../../gql_generated/graphql'
import { nnrMgrs, parseBytes } from '../../../utils'
import { useS3, UseS3Options } from '../../../utils/useS3'
import Protect from '../../common/Protect'
import Alert from '../Alert'
import { Async } from '../Async'
import { Icon } from '../Icon'
import KeyVals, { KeyVal } from '../KeyVals'
import { Spinner } from '../Loaders'
import { useModalCtrl } from '../Modal'
import { Btn } from '../Typography'

export const pdfPreviewStyles = css`
	height: 100%;
	display: flex;
	flex-direction: column;

	& > header {
		display: flex;
		width: 100%;
		border-bottom: solid 1px ${props => props.theme.colors.grey.val};

		& > * {
			flex-grow: 1;
			padding: 1em;
			text-align: center;

			&:hover {
				background: ${props => props.theme.colors.grey.tint(8).val};
			}

			&:first-child {
				border-right: solid 1px ${props => props.theme.colors.grey.tint(20).val};
			}
		}

		.delete-btn {
			color: ${props => props.theme.colors.err.val};
			flex-grow: 0;
			margin-left: auto;
		}
	}

	& > main {
		flex-grow: 1;
		display: flex;
		height: 100%;
		width: 100%;
		justify-content: center;
		align-items: center;
		background-color: ${props => props.theme.colors.slate.val};
		padding: 1em 0;

		.preview-window {
			z-index: 1;
			position: relative;
			display: flex;
			align-items: center;
			flex-direction: column;

			embed {
				max-height: 250px;
				height: 100%;
				z-index: 1;

				body {
					background: white;
				}
			}

			& > aside {
				margin-top: 1em;
				padding: 1em;
				background: ${props => props.theme.colors.white.tint(80).val};
				border-radius: 10px;
			}
		}

		& > .icon {
			position: absolute;
			height: 95%;
			width: auto;
			color: ${props => props.theme.colors.grey.val};
			opacity: 0.1;
			z-index: 0;
		}

		& > button {
			position: absolute;
			z-index: 2;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
`

const PDFPreviewView = styled.div`
	${pdfPreviewStyles}
`

export type PDFPreviewProps = Pick<UseS3Options, 'defaultDownloadFilename'> & {
	fileId: number
	onClick?: () => void
	className?: string
}

export const PDFPreview = ({ className, fileId, onClick, ...s3Options }: PDFPreviewProps) => {
	const results = useS3(fileId, s3Options)
	const { blob, downloadFile, openFile, fileSize, name, description, uploadedFileName } = results
	const [[showConfirm, openConfirm]] = useModalCtrl()
	const [deleteRes, deleteFile] = useDeleteFileMutation()
	return (
		<PDFPreviewView className={`pdf-preview${className ? ` ${className}` : ''}`}>
			<Async fetchResults={results}>
				<header>
					<Btn className='download-btn' onClick={downloadFile}>
						<Icon padRight type='pdf' /> Download
					</Btn>
					<Btn className='open-btn' onClick={openFile}>
						<Icon padRight type='browsers' /> Open
					</Btn>
					<Protect allowed={nnrMgrs}>
						<Btn className='delete-btn' onClick={openConfirm}>
							<Icon type='x' />
						</Btn>
					</Protect>
				</header>
				<main>
					{deleteRes.fetching ? <Spinner /> : null}
					<Btn className='select-file-btn' onClick={onClick || openFile} />
					{blob?.type === 'application/pdf' ? (
						<div className='preview-window'>
							<embed src={URL.createObjectURL(blob)} type={blob.type} />
							<aside>
								<KeyVals
									items={[
										['Name', name],
										['Uploaded file name', uploadedFileName],
										['File size', parseBytes(fileSize)],
									]}
								/>
								{description ? <KeyVal label='Description'>{description}</KeyVal> : null}
							</aside>
						</div>
					) : null}

					<Icon type='pdf' />
				</main>
			</Async>
			{showConfirm ? (
				<Alert
					cb={async choice => {
						if (choice) await deleteFile({ fileId })
					}}
					msg='Are you sure you want to delete this file?'
				/>
			) : null}
		</PDFPreviewView>
	)
}
