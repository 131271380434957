import React from 'react'
import styled, { css } from 'styled-components/macro'
import { AgreementDetail } from '../../../Components/common/Agreement/Agreement.detail'

export const relatedParcelAgreementModalStyles = css``

const RelatedParcelAgreementModalView = styled.div`
	${relatedParcelAgreementModalStyles}
`

export type RelatedParcelAgreementModalProps = {
	agreementId: number
}
export const RelatedParcelAgreementModal: React.FC<RelatedParcelAgreementModalProps> = ({
	agreementId,
}) => {
	return (
		<RelatedParcelAgreementModalView>
			<AgreementDetail agreementId={agreementId} />
		</RelatedParcelAgreementModalView>
	)
}
