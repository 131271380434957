import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import {
	Agreement,
	AgreementUpdateStatusInput,
	useUpdateAgreementStatusMutation,
} from '../../../gql_generated/graphql'
import { formatDate, parseDateForServer } from '../../../utils'
import { Form, SubmitBtn, Text } from '../../UI'

const OpExpireAgreementView = styled.div``

const schema = Yup.object().shape({
	dateExpired: Yup.string().required('Please enter a date of expiration'),
})

type OpExpireAgreementProps = {
	agreement: Agreement
	cb?: () => void
}
const OpExpireAgreement: React.FC<OpExpireAgreementProps> = ({ agreement, cb }) => {
	const { id } = agreement
	const [_, updateStatus] = useUpdateAgreementStatusMutation()

	return (
		<OpExpireAgreementView>
			<Form
				legend='Expire Agreement'
				initialValues={{
					dateExpired: formatDate(new Date(), 'input'),
					id,
				}}
				onSubmit={async (data: AgreementUpdateStatusInput) => {
					try {
						await updateStatus({
							data: {
								id: data.id,
								dateExpired: parseDateForServer(data.dateExpired),
								dateTerminated: parseDateForServer(data.dateTerminated),
							},
						})
						if (cb) cb()
					} catch (err) {}
				}}
				validationSchema={schema}
			>
				{() => (
					<>
						<Text type='date' name='dateExpired' label='Expiration Date' isReq />
						<SubmitBtn>Expire Agreement</SubmitBtn>
					</>
				)}
			</Form>
		</OpExpireAgreementView>
	)
}
export default OpExpireAgreement
