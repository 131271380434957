import React from 'react'
import styled, { css } from 'styled-components/macro'
import { P } from '../Typography'

export const parcelSelectorNoEstateTypesStyles = css`
	min-height: 500px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`

const ParcelSelectorNoEstateTypesView = styled.div`
	${parcelSelectorNoEstateTypesStyles}
`

export type ParcelSelectorNoEstateTypesProps = {}
export const ParcelSelectorNoEstateTypes: React.FC<ParcelSelectorNoEstateTypesProps> = () => {
	return (
		<ParcelSelectorNoEstateTypesView>
			<P>Select an estate type to get started...</P>
		</ParcelSelectorNoEstateTypesView>
	)
}
