import { RouteComponentProps, Router } from '@reach/router'
import React from 'react'
import Protect from '../Components/common/Protect'
import Page404 from '../Pages/404.page'
import UserPage from '../Pages/User'
import UsersPage from '../Pages/Users'
import { nnrMgrs } from '../utils'

const UserRoutes: React.FC<RouteComponentProps> = () => {
	return (
		<Protect allowed={nnrMgrs}>
			<Router>
				<UsersPage path='/' />
				<UserPage path='/:id' />
				<Page404 default />
			</Router>
		</Protect>
	)
}
export default UserRoutes
