import React from 'react'
import styled, { css } from 'styled-components/macro'
import { parcelsPageColsConfig } from '../../../../Components/UI/Table.resources/Table.resource.col.configs/Parcel.col.configs'
import { ResourcesTable } from '../../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../../Components/UI/Table.resources/usePaginationParams'
import { ParcelFilter, ParcelParent } from '../../../../gql_generated/document_types'
import { usePaginatedParcelsQuery } from '../../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../../Types'
import { RelatedParcelEasements } from '../../../Parcel/Related/Parcel.related.easements'
import { RelatedResearchModal } from '../Related.Modals/Research.related.modal'

export const relatedResearchEasementsStyles = css``

const RelatedResearchEasementsView = styled.div`
	${relatedResearchEasementsStyles}
`

export type RelatedResearchEasementsProps = {
	parcelIds: number[]
	estateTypes: EstateTypeEnum[]
}

export const RelatedResearchEasements: React.FC<RelatedResearchEasementsProps> = ({
	parcelIds,
}) => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedParcelsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: ParcelParent.Easement,
					},
				],
				filterOptions: [
					{
						filter: ParcelFilter.ParcelIds,
						vals: parcelIds,
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.parcels
	return (
		<RelatedResearchEasementsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={parcelsPageColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={({ id }) => (
					<RelatedResearchModal parcelId={id} parcelParent={ParcelParent.Easement}>
						<RelatedParcelEasements parcelId={id} />
					</RelatedResearchModal>
				)}
				resourceType='easement'
				suppressGoTo
			/>
		</RelatedResearchEasementsView>
	)
}
