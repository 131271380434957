import { action, Action } from 'easy-peasy'
import { IconType } from '../Components/UI/Icon/fa.defaults'

export type PageStoreModel = {
	pageType: string | null
	pageTitle: string | null
	status: string | null
	icon: IconType | null

	setPageType: Action<PageStoreModel, string>
	setPageTitle: Action<PageStoreModel, string>
	setStatus: Action<PageStoreModel, string>
	setIcon: Action<PageStoreModel, IconType>
	resetPageData: Action<PageStoreModel>
}

export const pageModel: PageStoreModel = {
	pageType: null,
	pageTitle: null,
	status: null,

	icon: null,

	setPageType: action((state, pageType) => {
		if (pageType && pageType !== state.pageType) state.pageType = pageType
		else if (state.pageType && !pageType) state.pageType = null
	}),

	setPageTitle: action((state, title) => {
		if (title && title !== state.pageTitle) state.pageTitle = title
		else if (state.pageTitle && !title) state.pageTitle = null
	}),

	setStatus: action((state, status) => {
		if (status) state.status = status
		else if (state.status && !status) state.status = null
	}),

	setIcon: action((state, icon) => {
		if (icon && icon !== state.icon) state.icon = icon
		else if (state.icon && !icon) state.icon = null
	}),

	resetPageData: action(state => {
		if (state.pageType) state.pageType = null
		if (state.pageTitle) state.pageTitle = null
		if (state.status) state.status = null
		if (state.icon) state.icon = null
	}),
}
