import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Acquisition, Agreement, Easement } from '../../../gql_generated/document_types'
import { Lease, Sale } from '../../../gql_generated/graphql'
import { EstateTypeEnum, ExhibitLetter } from '../../../Types'
import { formatDate, useState } from '../../../utils'
import { Card, Form, PrintWindow, SelectEstateType, SubmitBtn, Text, ToggleField } from '../../UI'
import ExhibitPDF, { ExhibitPdfConfig } from '../Exhibit.pdf'

const schema = Yup.object().shape({
	title: Yup.string().required('Please enter a title'),
	estateType: Yup.string().required('Please select an estate type.'),
})

const ExhibitFormView = styled.div`
	min-height: 200px;
	min-width: 200px;

	${props => props.theme.media.tablet} {
		min-width: 400px;
	}

	${props => props.theme.media.sdesk} {
		min-width: 600px;
		padding: 0 50px;
	}
`

const parseTitle = ({ lease, agreement, sale, acquisition, easement }: ExhibitFormResource) => {
	return lease
		? `${lease.leaseType?.name || lease.og?.leaseType} #${lease.leaseId}`
		: agreement
		? `#${agreement.agreementId}`
		: sale
		? `${sale.granteeDba?.name || sale.grantee?.name || sale.og?.grantee}`
		: acquisition
		? `${acquisition.grantorDba?.name || acquisition.grantor?.name || ''}`
		: easement
		? `${easement.granteeDba?.name || easement.grantee?.name || ''}`
		: ''
}

const parseSubTitle = ({ lease, agreement }: ExhibitFormResource) => {
	return lease
		? lease.lessee?.name || lease.og?.lessee
		: agreement
		? agreement.org?.name || agreement.og?.org
		: ''
}

const parseFileName = ({
	config,
	lease,
	agreement,
	sale,
	acquisition,
	easement,
	estateType,
}: ExhibitFormResource & {
	config: ExhibitPdfConfig
	estateType?: EstateTypeEnum
}) => {
	const { letter } = config
	return lease
		? `NN.lease.${lease.leaseId}.exhibit_${letter}`
		: agreement
		? `NN.agreement.${agreement.agreementId}.exhibit_${letter}`
		: sale
		? `NN.sale.${sale.escrowId || sale.id}.${estateType}.exhibit_${letter}`
		: acquisition
		? `NN.acquisition.${acquisition.escrowId || acquisition.id}.${estateType}.exhibit_${letter}`
		: easement
		? `NN.easement.${easement.escrowId || easement.id}.exhibit_${letter}`
		: `NN.exhibit_${letter}`
}

export type ExhibitFormResource = {
	lease?: Pick<Lease, 'leaseId' | 'lessee' | 'lesseeDba' | 'og' | 'leaseType' | 'activity'>
	sale?: Pick<Sale, 'escrowId' | 'grantee' | 'granteeDba' | 'og' | 'id' | 'activity'>
	agreement?: Pick<Agreement, 'agreementId' | 'org' | 'og' | 'activity'>
	acquisition?: Pick<Acquisition, 'escrowId' | 'grantor' | 'grantorDba' | 'id' | 'activity'>
	easement?: Pick<Easement, 'escrowId' | 'grantee' | 'granteeDba' | 'id' | 'activity'>
}

export type ExhibitFormProps = {
	showApns?: boolean
	defaultLetter?: ExhibitLetter
	resource?: ExhibitFormResource

	parcelIds?: number[]

	estateType?: EstateTypeEnum
	cb?: () => void
}
const ExhibitForm: React.FC<ExhibitFormProps> = ({
	showApns = false,
	defaultLetter,
	resource = {},

	parcelIds,

	estateType: incomingEstateType,
	cb,
}) => {
	const { lease, agreement } = resource || {}
	const [pdfConfig, setConfig] = useState<ExhibitPdfConfig | null>(null, 'pdfConfig')
	const [estateType, setEstateType] = useState(incomingEstateType)

	const submitHandler = ({ estateType, ...vals }: any) => {
		setConfig(vals)
		setEstateType(estateType)
	}

	return (
		<ExhibitFormView>
			<Form
				validationSchema={schema}
				initialValues={{
					letter: defaultLetter || 'A',
					showApns,
					includeAssigned: false,
					includeTerminated: false,
					title: parseTitle(resource),
					subTitle: parseSubTitle(resource),
					hideDate: false,
					date: formatDate(new Date(), 'input'),
					estateType: estateType || 'srf',
				}}
				onSubmit={submitHandler}
			>
				{() => (
					<>
						{pdfConfig ? (
							// <PDFDownloadBtn
							// 	PDF={<ExhibitPDF config={pdfConfig} sale={sale} lease={lease} />}
							// 	fileName={parseFileName({ config: pdfConfig, lease, sale, estateType })}
							// 	cb={cb}
							// >
							// 	Download PDF
							// </PDFDownloadBtn>
							<PrintWindow
								margin='0.5in'
								PDF={
									<ExhibitPDF
										config={pdfConfig}
										resource={resource}
										parcelIds={parcelIds || []}
										estateType={estateType as EstateTypeEnum}
									/>
								}
								fileName={parseFileName({ config: pdfConfig, estateType, ...resource })}
								cb={cb}
							/>
						) : (
							<>
								<Card noShadow mBot>
									<Text name='letter' label='Exhibit Letter' isReq />
								</Card>
								<Card noShadow mBot>
									<Text name='title' label='Title Text' isReq />
									<Text name='subTitle' label='Sub-Title Text' />
								</Card>
								<Card noShadow mBot canOverflow>
									<ToggleField name='showApns' label='Include APNs?' />
									{lease || agreement ? (
										<ToggleField name='includeAssigned' label='Include assigned estates?' />
									) : null}
									{lease || agreement ? (
										<ToggleField name='includeTerminated' label='Include terminated estates?' />
									) : null}
									{!incomingEstateType ? (
										<SelectEstateType name='estateType' label='Estate Type' />
									) : null}
								</Card>
								<Card noShadow mBot>
									<Text name='date' label='Date' type='date' />
									<ToggleField name='hideDate' label='Hide Date?' />
								</Card>

								<SubmitBtn>Generate PDF</SubmitBtn>
							</>
						)}
					</>
				)}
			</Form>
		</ExhibitFormView>
	)
}
export default ExhibitForm
