import { RouteComponentProps, Router } from '@reach/router'
import React from 'react'

import Protect from '../Components/common/Protect'
import { UserRole } from '../gql_generated/document_types'
import Page404 from '../Pages/404.page'

import LogsPage from '../Pages/Logs'
import { nnrMgrs } from '../utils'

const ActivityLogRoutes: React.FC<RouteComponentProps> = () => {
	return (
		<Protect allowed={[...nnrMgrs, UserRole.Accountant, UserRole.Officer]}>
			<Router>
				<LogsPage path='/' />
				<Page404 default />
			</Router>
		</Protect>
	)
}
ActivityLogRoutes.propTypes = {}
export default ActivityLogRoutes
