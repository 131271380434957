import React from 'react'
import { Org, useOrgForInfoQuery } from '../../../gql_generated/graphql'
import { useStoreState } from '../../../store'
import { addressString, getPrimaryContact } from '../../../utils'
import { KeyVals } from '../../UI'

type OrgInfoProps = {
	orgId: number
}
const OrgInfo: React.FC<OrgInfoProps> = ({ orgId }) => {
	const user = useStoreState(store => store.user.me)
	const [results] = useOrgForInfoQuery({ variables: { id: orgId } })
	const org = results.data?.org

	const { id, name, phone, address } = org || {}
	const { phoneMobile, phoneWork, email } = getPrimaryContact({ org: org as Org, user }) || {}

	return (
		<KeyVals
			items={[
				['ID', id],
				['Name', name],
				['Email', email || org?.email, { withCopyPaste: true, format: 'email' }],
				['Phone', phoneMobile || phoneWork || phone, { withCopyPaste: true, format: 'phone' }],
				address ? ['Address', addressString(address), { withCopyPaste: true }] : null,
			]}
		/>
	)
}
export default OrgInfo
