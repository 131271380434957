import isNil from 'lodash/isNil'
import React, { useCallback } from 'react'
import styled, { css } from 'styled-components/macro'
import { Btn } from '../../Typography'
import {
	TableBodyCellConfig,
	TableCellStyleProps,
	TableRowConfig,
	TableRowHandlers,
	TableRowOpConfig,
} from '../Table.types'
import { TableRowOps, TableRowOpsProps } from './Table.body.rowOps'
import { TableSelectRow, TableSelectRowProps } from './Table.body.rowSelect'

export const tableBodyCellStyles = css<TableCellStyleProps>`
	font-size: 0.8rem;
	font-weight: 700;
	color: ${props => props.theme.colors.text.val};
	display: ${props => (props.hideMobile ? 'none' : 'table-cell')};

	${props => props.theme.media.tablet} {
		display: ${props => (props.hideTablet ? 'none' : 'table-cell')};
	}
	${props => props.theme.media.sdesk} {
		display: table-cell;
	}

	& > button,
	& > .raw-text {
		padding-top: 0.7em;
		padding-bottom: 0.7em;
	}

	& > button {
		white-space: nowrap;
		color: inherit;
		display: inline-flex;
		align-items: center;
		width: 100%;
		text-align: left;

		&:hover,
		&:active,
		&:focus {
			color: inherit;
		}
	}
	& > input {
		display: block;
	}

	&._row-ops-cell {
		z-index: 10;
	}
	.estate-types {
		max-height: 1em;

		.estate-type {
			margin: 0;
			margin-right: 0.6em;

			&:last-child {
				margin: 0;
			}

			.icon {
				margin: 0;
				height: 1.6em;
			}
		}
	}
`

const TableBodyCellView = styled.td`
	${tableBodyCellStyles}
`

export type TableBodyCellProps<T> = TableBodyCellConfig &
	Omit<TableRowConfig<T>, 'cells'> &
	TableRowHandlers<T> & {
		ops?: TableRowOpConfig<T>[]
	}

export const TableBodyCell: React.FC<TableBodyCellProps<any>> = ({
	txt,
	hideMobile,
	hideTablet,
	colSpan,
	ops,
	selectHandler,
	clickHandler,
	setLastActiveIdx,
	isSelected,
	id,
	val,
	rowIdx,
	isDisabled,
	isRowTrigger = true,
	Component,
	selectType,
}) => {
	const className = ops?.length
		? '_row-ops-cell'
		: selectHandler
		? '_checkbox-cell'
		: clickHandler
		? '_click-cell'
		: '_static-cell'

	const selectRowProps: Omit<TableSelectRowProps<any>, 'selectRow'> = {
		id,
		val,
		isDisabled,
		isSelected: !!isSelected,
		selectType,
	}
	const rowOpsConfig: Omit<TableRowOpsProps<any>, 'ops'> = {
		id,
		val,
		isDisabled,
	}
	const renderComponent = useCallback(
		() => (Component ? <Component id={id} /> : null),
		[id, Component]
	)

	const text = isNil(txt) ? 'N/A' : txt

	return (
		<TableBodyCellView
			className={`${className}${hideMobile ? ' __hide-mobile' : ''}${
				hideTablet ? ' __hide-tablet' : ''
			}`}
			colSpan={colSpan || 1}
		>
			{selectHandler ? (
				<TableSelectRow {...selectRowProps} selectRow={selectHandler} />
			) : ops?.length ? (
				<TableRowOps {...rowOpsConfig} ops={ops} />
			) : clickHandler && isRowTrigger ? (
				<Btn
					onClick={() => {
						clickHandler(val || id, rowIdx)
						setLastActiveIdx && setLastActiveIdx(rowIdx)
					}}
					isDisabled={isDisabled}
				>
					{Component && val ? renderComponent() : text}
				</Btn>
			) : Component && val ? (
				renderComponent()
			) : (
				<div className='raw-text'>{text}</div>
			)}
		</TableBodyCellView>
	)
}
