import isFunction from 'lodash/isFunction'
import styled from 'styled-components'
import { CTAStyleProps, ctaStyles } from './CTA.styles'
import { BtnLinkProps } from './Typo.types'

type ButtonProps = CTAStyleProps & React.HTMLProps<HTMLButtonElement> & BtnLinkProps

const Button = styled.button<ButtonProps>`
	border: none;
	outline: none;
	color: ${props => props.theme.colors.btn.val};
	&:active,
	&:focus,
	&:hover {
		border: none;
		color: ${props => props.theme.colors.btn.light(1).val};
	}
	.icon {
		margin-right: 0.5em;
	}

	&.cta {
		${ctaStyles}
	}
`

export type BtnProps = ButtonProps & {
	prevDef?: boolean
} & any

const Btn: React.FC<BtnProps> = ({
	onClick,
	prevDef = true,
	isCta,
	isLight,
	className,
	children,
	...props
}) => (
	<Button
		{...props}
		onClick={(e: React.MouseEvent<HTMLElement>) => {
			if (prevDef && isFunction(e.preventDefault)) e.preventDefault()
			if (isFunction(onClick)) onClick(e)
		}}
		className={`btn${className ? ` ${className}` : ''}${isCta ? ' cta' : ''}${
			isLight ? ' light' : ''
		}`}
	>
		{children}
	</Button>
)
export default Btn
