import React from 'react'
import { RouteComponentProps, Router } from '@reach/router'

import Protect from '../Components/common/Protect'
import Page404 from '../Pages/404.page'

import ParcelsPage from '../Pages/Parcels'
import ParcelPage from '../Pages/Parcel'

const ParcelRoutes: React.FC<RouteComponentProps> = () => {
	return (
		<Protect>
			<Router>
				<ParcelsPage path='/' />
				<ParcelPage path='/:id' />
				<Page404 default />
			</Router>
		</Protect>
	)
}
ParcelRoutes.propTypes = {}
export default ParcelRoutes
