import React, { useRef } from 'react'
import styled from 'styled-components'
import { Contact, Dba, OrgInput, useCreateOrgMutation } from '../../../gql_generated/graphql'
import { defaultOrgVals, useState } from '../../../utils'
import { ErrorBlock, Form, SubmitBtn, Tab, Tabs } from '../../UI'
import OrgContactsForm from './Ops.Org.contacts'
import { OrgFields, orgSchema } from './Ops.Org.fields'

const CreateOrgView = styled.div``

type CreateOrgProps = {
	cb?: (org: any) => void
}
const CreateOrg: React.FC<CreateOrgProps> = ({ cb }) => {
	const [error, setErr] = useState<any | null>(null, 'error')
	const isDone = useRef(false)
	const [_, createOrg] = useCreateOrgMutation()
	return isDone.current ? null : (
		<CreateOrgView>
			<Form<OrgInput>
				legend='Create Org'
				initialValues={defaultOrgVals}
				onSubmit={async (data: any) => {
					try {
						const res = await createOrg({
							data: {
								...data,
								email: data.email?.length ? data.email : null,
								phone: data.phone?.length ? data.phone : null,
								fax: data.fax?.length ? data.fax : null,
								dbas: data.dbas?.filter(({ name }: Dba) => name).length ? data.dbas : null,
								contacts: data.contacts?.length
									? data.contacts.map((contact: Contact) => ({
											...contact,
											orgId: 0,
									  }))
									: null,
							},
						})
						if (cb) {
							isDone.current = true
							cb(res)
						}
					} catch (err: any) {
						setErr(err)
						throw err
					}
				}}
				validationSchema={orgSchema}
			>
				{() => (
					<>
						<ErrorBlock error={error} />
						<Tabs>
							<Tab nav='Org' icon='org'>
								<OrgFields />
							</Tab>
							<Tab nav='Contacts' icon='contacts'>
								<OrgContactsForm />
							</Tab>
						</Tabs>
						<SubmitBtn />
					</>
				)}
			</Form>
		</CreateOrgView>
	)
}

export default CreateOrg
