import React from 'react'
import styled from 'styled-components'

const TruncView = styled.span``

type TruncProps = {
	children?: string
	maxChars?: number
	className?: string
}
export const Trunc: React.FC<TruncProps> = ({ children, className, maxChars = 80 }) => {
	const str =
		children && children.length > maxChars ? `${children.slice(0, maxChars - 1)}...` : children

	return children ? (
		<TruncView className={`trunc${className ? ` ${className}` : ''}`}>{str}</TruncView>
	) : null
}
