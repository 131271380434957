import React from 'react'
import styled from 'styled-components'
import { Spinner, Tab, Tabs } from '../../../Components/UI'
import { RelatedResourcesCard } from '../../../Components/common/RelatedResource.card'
import { RelatedLeaseDocs } from './Lease.related.docs'
import { RelatedLeaseLogs } from './Lease.related.logs'
import { RelatedLeaseOrgs } from './Lease.related.orgs'
import { RelatedLeaseParcels } from './Lease.related.parcels'
import { RelatedLeaseRoyalties } from './Lease.related.royalties'
import { RelatedLeaseTerms } from './Lease.related.terms'

const Section = styled.section``

type LeaseRelatedResourcesProps = {
	leaseId: number
}
const LeaseRelatedResources: React.FC<LeaseRelatedResourcesProps> = ({ leaseId }) => {
	return (
		<Section>
			<RelatedResourcesCard>
				{leaseId ? (
					<Tabs renderOnSelect>
						<Tab nav='Parcels' icon='parcel'>
							<RelatedLeaseParcels leaseId={leaseId} />
						</Tab>

						<Tab nav='Royalties' icon='royalty'>
							<RelatedLeaseRoyalties leaseId={leaseId} />
						</Tab>

						<Tab nav='Orgs' icon='org'>
							<RelatedLeaseOrgs leaseId={leaseId} />
						</Tab>

						<Tab nav='Docs' icon='doc'>
							<RelatedLeaseDocs leaseId={leaseId} />
						</Tab>

						<Tab nav='Terms' icon='term'>
							<RelatedLeaseTerms leaseId={leaseId} />
						</Tab>
						<Tab nav='Logs' icon='log'>
							<RelatedLeaseLogs leaseId={leaseId} />
						</Tab>
					</Tabs>
				) : (
					<Spinner />
				)}
			</RelatedResourcesCard>
		</Section>
	)
}
export default LeaseRelatedResources
