import includes from 'lodash/includes'
import React from 'react'
import styled, { css } from 'styled-components/macro'
import { SortDir } from '../../../../gql_generated/document_types'
import { Icon } from '../../Icon'
import { Btn } from '../../Typography'
import { UseTableSort } from '../useTableSort'

export const tableColSortStyles = css`
	position: relative;
	.ctrl {
		display: flex;
		align-items: center;
		padding: 0;
		color: inherit;
		.icon {
			color: ${props => props.theme.colors.grey.light(2).val};
			margin: 0;
			margin-left: 1em;
		}
	}
	.ctrl-group {
		position: absolute;
		bottom: -3px;
		right: -4px;
		transform: translateY(100%);
		padding: 1em;
		background: ${props => props.theme.colors.white.val};
		box-shadow: 0 1px 4px ${props => props.theme.colors.black.tint(70).val};
		z-index: 4;
		li {
			.btn {
				display: flex;
				align-items: center;
				padding: 0.3em 1em;
				color: ${props => props.theme.colors.slate.val};
				white-space: nowrap;
			}
		}
	}
`

const TableColSortView = styled.div`
	${tableColSortStyles}
`

// const parseSortFields = (sortField: string) =>
// 	sortField?.includes(',') ? sortField.split(',') : [sortField]

export type TableColSortProps = UseTableSort & {
	sortField: string
}
export const TableColSort: React.FC<TableColSortProps> = ({
	sortHandler,
	sortState,
	sortField,
	children,
}) => {
	const prevSortOption = sortState.find(sort => includes(sortField, sort.sortField))

	const handler = () => {
		const sortDir = prevSortOption?.sortDir === SortDir.Asc ? SortDir.Desc : SortDir.Asc

		sortHandler(sortField, { sortDir })
	}
	return (
		<TableColSortView>
			<Btn onClick={() => handler()} className='ctrl'>
				{children}
				{prevSortOption?.sortDir === SortDir.Asc ? (
					<Icon type='caretUp' />
				) : (
					<Icon type='caretDown' />
				)}
			</Btn>
		</TableColSortView>
	)
}
