import React from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import styled from 'styled-components'
import { DocRelOptionsInput } from '../../../../gql_generated/graphql'
import { Btn, Icon } from '../../../UI'
import { AddDocToResourceOpProps } from '../Ops.AddDocToResource'
import { AddDocToResourcePreview } from '../Ops.AddDocToResource.Preview'
import { AddDocToResourceConfirmParcelsConfig } from './Ops.AddDocToResource.confirm.parcelsConfig'

export const AddDocToResourceConfirmView = styled.div`
	display: flex;
	flex-direction: column;

	& > header {
		padding-bottom: 1em;
	}

	.columns {
		${props => props.theme.media.tablet} {
			display: flex;
			& > * {
				flex-grow: 1;
				width: 50%;
				margin-right: 3em;
				&:last-child {
					margin: 0;
				}
			}
		}
	}
`

export type AddDocToResourceConfirmProps = Pick<
	AddDocToResourceOpProps,
	'parentType' | 'parentId'
> & {
	docId: number | null
	submitHandler: (options: DocRelOptionsInput) => void
}

export const AddDocToResourceConfirm: React.FC<AddDocToResourceConfirmProps> = ({
	docId,
	submitHandler,
	parentType,
	parentId,
	...stepProps
}) => {
	const { previousStep } = stepProps as StepWizardChildProps

	return (
		<AddDocToResourceConfirmView>
			<header className='add-doc-confirm-header'>
				<Btn className='cta back-btn' onClick={previousStep}>
					<Icon type='arrowLeft' />
					Back
				</Btn>
			</header>
			<section className='columns'>
				{docId ? <AddDocToResourcePreview docId={docId} /> : null}
				{docId ? (
					<AddDocToResourceConfirmParcelsConfig
						parentType={parentType}
						parentId={parentId}
						submitHandler={submitHandler}
					/>
				) : null}
			</section>
		</AddDocToResourceConfirmView>
	)
}
