import React from 'react'
import styled from 'styled-components'
import { GenAcquisitionModal } from '../../Components/common/Acquisition/Acquisition.gen.modal'
import { Modal, useModalCtrl } from '../../Components/UI'
import { CsvDownload } from '../../Components/UI/CSV.download'
import { acquisitionsPageForeclosedColsConfig } from '../../Components/UI/Table.resources/Table.resource.col.configs/Acquisition.col.configs'
import { ResourcesTable } from '../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../Components/UI/Table.resources/usePaginationParams'
import { AcquisitionFilter } from '../../gql_generated/document_types'
import {
	usePaginatedAcquisitionsCsvMutation,
	usePaginatedAcquisitionsQuery,
} from '../../gql_generated/graphql'

const AcquisitionsPageForeclosedView = styled.div``

type AcquisitionsPageForeclosedProps = {}
const AcquisitionsPageForeclosed: React.FC<AcquisitionsPageForeclosedProps> = () => {
	const paginationStore = usePaginationParams()

	const params = {
		...paginationStore.params,
		filterOptions: [
			{
				filter: AcquisitionFilter.Status,
				vals: ['Foreclosed'],
			},
		],
	}
	const [fetchResults] = usePaginatedAcquisitionsQuery({
		variables: {
			params,
		},
	})
	const { data } = fetchResults || {}

	const [[showCsv, openCsv, closeCsv]] = useModalCtrl()
	const [csvMutResults, csvMut] = usePaginatedAcquisitionsCsvMutation()

	const acquisitions = data?.page?.acquisitions
	return (
		<AcquisitionsPageForeclosedView>
			<ResourcesTable
				dataset={acquisitions}
				colsConfig={acquisitionsPageForeclosedColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				includeSearch
				modalRenderFunc={data => <GenAcquisitionModal acquisitionId={data.id} />}
				resourceType='acquisition'
				miscOps={[
					{
						icon: 'download',
						title: 'CSV',
						op: openCsv,
					},
				]}
			/>
			<Modal show={showCsv} closeHandler={closeCsv}>
				<CsvDownload
					fetchData={() =>
						csvMut({
							params,
						})
					}
					fetchState={csvMutResults}
					csvData={csvMutResults.data?.csv}
					onAfterDownload={closeCsv}
					options={{
						filename: 'acquisitions',
					}}
				/>
			</Modal>
		</AcquisitionsPageForeclosedView>
	)
}
export default AcquisitionsPageForeclosed
