import capitalizeL from 'lodash/capitalize'
import compactL from 'lodash/compact'
import debounceL from 'lodash/debounce'
import differenceL from 'lodash/difference'
import differenceByL from 'lodash/differenceBy'
import eachL from 'lodash/each'
import isEmptyL from 'lodash/isEmpty'
import isNumberL from 'lodash/isNumber'
import kebabCaseL from 'lodash/kebabCase'
import randomL from 'lodash/random'
import reduceL from 'lodash/reduce'
import roundL from 'lodash/round'
import sortByL from 'lodash/sortBy'
import startCaseL from 'lodash/startCase'
import sumL from 'lodash/sum'
import sumByL from 'lodash/sumBy'
import toNumberL from 'lodash/toNumber'
import trimL from 'lodash/trim'
import uniqL from 'lodash/uniq'
import uniqByL from 'lodash/uniqBy'
import unsetL from 'lodash/unset'

export const sum = sumL
export const sumBy = sumByL

export const compact = compactL

export const capitalize = capitalizeL

export const round = roundL

export const uniq = uniqL

export const uniqBy = uniqByL

export const difference = differenceL

export const differenceBy = differenceByL

export const reduce = reduceL

export const trim = trimL

export const unset = unsetL

export const each = eachL

export const isEmpty = isEmptyL

export const startCase = startCaseL

export const sortBy = sortByL

export const kebabCase = kebabCaseL

export const isNumber = isNumberL

export const random = randomL

export const toNumber = toNumberL

export const debounce = debounceL
