import React from 'react'
import styled, { css } from 'styled-components/macro'
import { OrgDetail } from './Org.detail'

export const genOrgModalStyles = css``

const GenOrgModalView = styled.div`
	${genOrgModalStyles}
`

export type GenOrgModalProps = {
	orgId: number
}

export const GenOrgModal: React.FC<GenOrgModalProps> = ({ orgId }) => {
	return (
		<GenOrgModalView>
			<OrgDetail orgId={orgId} />
		</GenOrgModalView>
	)
}
