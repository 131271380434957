import isFunction from 'lodash/isFunction'
import unset from 'lodash/unset'
import React, { useRef } from 'react'
import { useCreateAcquisitionMutation } from '../../../gql_generated/graphql'
import {
	defaultAcquisitionVals,
	formatDate,
	parseDateForServer,
	priceToNumber,
} from '../../../utils'
import { Form, SubmitBtn } from '../../UI'
import AcquisitionFields, { newAcquisitionFieldsSchema } from './Ops.Acquisition.fields'

type CreateAcquisitionProps = {
	cb?: (data: any) => void
}
const CreateAcquisition: React.FC<CreateAcquisitionProps> = ({ cb }) => {
	const [_, createAcquisition] = useCreateAcquisitionMutation()
	const stopRender = useRef(false) // component should no longer render markup

	return stopRender.current ? null : (
		<Form
			legend='Create New Acquisition'
			initialValues={{
				...defaultAcquisitionVals,
				dateOpened: formatDate(new Date(), 'input'),
			}}
			onSubmit={async (vals: any) => {
				try {
					const data = {
						...vals,
					}
					if (!vals.brokerId) unset(data, 'brokerId')
					if (!vals.titleId) unset(data, 'titleId')
					if (!vals.grantorDbaId) unset(data, 'grantorDbaId')
					if (!vals.brokerDbaId) unset(data, 'brokerDbaId')
					if (!vals.titleDbaId) unset(data, 'titleDbaId')

					data.dateOpened = parseDateForServer(vals.dateOpened)
					data.dateExpected = parseDateForServer(vals.dateExpected)
					data.price = priceToNumber(vals.price)

					const results = await createAcquisition({ data })
					stopRender.current = true
					if (isFunction(cb)) cb(results)
				} catch (err) {
					console.error(err)
				}
			}}
			validationSchema={newAcquisitionFieldsSchema}
		>
			{() => (
				<>
					<AcquisitionFields isNew />
					<SubmitBtn />
				</>
			)}
		</Form>
	)
}
export default CreateAcquisition
