import React from 'react'
import styled from 'styled-components'
import Logo from '../../images/NNR-Logo'
import { Link } from '../UI'

const View = styled.header`
	height: ${props => props.theme.sizes.header.mobile.num}px;
	background: ${props => props.theme.colors.secondary.desat(2).val};
	color: ${props => props.theme.colors.white.val};
	${props => props.theme.media.tablet} {
		height: ${props => props.theme.sizes.header.tablet.num}px;
	}
	${props => props.theme.media.sdesk} {
		height: ${props => props.theme.sizes.header.sdesk.num}px;
	}
	.link {
		color: ${props => props.theme.colors.white.val};
	}
	.nnr-logo {
		max-height: 100%;
	}
`

type HeaderPublicProps = {}
const HeaderPublic: React.FC<HeaderPublicProps> = () => {
	return (
		<View className='site-header flex'>
			<Link to='/login'>
				<Logo />
			</Link>
		</View>
	)
}
export default HeaderPublic
