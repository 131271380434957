import { Org } from '../../../../gql_generated/document_types'
import {
	useOrgForEmailPhoneColsQuery,
	useOrgForPrimaryContactColQuery,
} from '../../../../gql_generated/graphql'
import { useStoreState } from '../../../../store'
import { contactNameTitle, getPrimaryContact } from '../../../../utils'
import { CopyBtn } from '../../CopyBtn'
import { TableBodyCellComponent } from '../../Table/Table.types'
import { ColConfig } from './Col.config.types'
import { ResourceTableCellAsync } from './Table.resource.cell.Async'

const PrimaryContact: TableBodyCellComponent = ({ id }) => {
	const user = useStoreState(state => state.user.me)
	const [fetchResults] = useOrgForPrimaryContactColQuery({
		variables: { id },
	})
	const org = fetchResults.data?.org || {}

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{contactNameTitle(getPrimaryContact({ user, org }))}
		</ResourceTableCellAsync>
	)
}

const Email: TableBodyCellComponent = ({ id }) => {
	const user = useStoreState(state => state.user.me)
	const [fetchResults] = useOrgForEmailPhoneColsQuery({
		variables: { id },
	})
	const org = fetchResults.data?.org

	const { email } = getPrimaryContact({ user, org: org as Org }) || {}

	const emailAddress = email || org?.email || ''

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{emailAddress ? (
				<CopyBtn content={emailAddress} showMsg>
					{emailAddress}
				</CopyBtn>
			) : null}
		</ResourceTableCellAsync>
	)
}

const Phone: TableBodyCellComponent = ({ id }) => {
	const user = useStoreState(state => state.user.me)
	const [fetchResults] = useOrgForEmailPhoneColsQuery({
		variables: { id },
	})
	const org = fetchResults.data?.org

	const { phoneMobile, phoneWork } = getPrimaryContact({ user, org: org as Org }) || {}

	const phone = phoneWork || phoneMobile || org?.phone || ''

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{phone ? (
				<CopyBtn content={phone} showMsg>
					{phone}
				</CopyBtn>
			) : null}
		</ResourceTableCellAsync>
	)
}

export const orgNameColConfig: ColConfig = {
	field: 'name',
	label: 'Org',
}

export const orgPrimaryContactNameColConfig: ColConfig = {
	label: 'Primary Contact',
	Component: PrimaryContact,
}

export const orgPrimaryContactEmailColConfig: ColConfig = {
	label: 'Email',
	isRowTrigger: false,
	Component: Email,
}

export const orgPrimaryContactPhoneColConfig: ColConfig = {
	label: 'Phone',
	Component: Phone,
	isRowTrigger: false,
}

export const orgsPageColsConfig: ColConfig[] = [
	orgNameColConfig,
	orgPrimaryContactNameColConfig,
	orgPrimaryContactEmailColConfig,
	orgPrimaryContactPhoneColConfig,
]

export const orgsSimpleColsConfig: ColConfig[] = [
	{
		field: 'name',
		label: ' ',
	},
	orgPrimaryContactNameColConfig,
]

export const relatedOrgColsConfig: ColConfig[] = [
	orgNameColConfig,
	orgPrimaryContactNameColConfig,
	orgPrimaryContactEmailColConfig,
	orgPrimaryContactPhoneColConfig,
]
