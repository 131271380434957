import { Redirect, RouteComponentProps } from '@reach/router'
import React from 'react'
import CardsSection from '../../Components/common/CardsSection'
import { PageOperation } from '../../Components/common/Operations'
import Page from '../../Components/common/Resources/Resource.page'
import CreateAcquisition from '../../Components/Ops/Ops.Acquisitions/Ops.Acquisition.create'
import { Card, Modal, Tab, Tabs } from '../../Components/UI'
import { usePageData, useState } from '../../utils'
import AcquisitionsPageCancelled from './Acquisitions.page.cancelled'
import AcquisitionsPageClosed from './Acquisitions.page.closed'
import AcquisitionsPageForeclosed from './Acquisitions.page.foreclosed'
import AcquisitionsPageOpen from './Acquisitions.page.open'

const AcquisitionsPage: React.FC<RouteComponentProps> = () => {
	const [Op, setOp] = useState<string | null>(null, 'Op')

	const [redirect, setRedirect] = useState<string | null>(null, 'redirect')

	usePageData({ pageType: 'acquisitions', pageTitle: 'Acquisitions' })

	const operations: PageOperation[] = [
		{
			txt: 'New Acquisition',
			icon: 'acquisition',
			op: () => setOp('create'),
		},
	]

	return redirect ? (
		<Redirect to={redirect} noThrow />
	) : (
		<Page isLoaded operations={operations}>
			<CardsSection>
				<Card>
					<Tabs renderOnSelect>
						<Tab nav='Closed'>
							<AcquisitionsPageClosed />
						</Tab>
						<Tab nav='Open'>
							<AcquisitionsPageOpen />
						</Tab>
						<Tab nav='Cancelled'>
							<AcquisitionsPageCancelled />
						</Tab>
						<Tab nav='Foreclosed'>
							<AcquisitionsPageForeclosed />
						</Tab>
					</Tabs>
				</Card>
			</CardsSection>
			<Modal show={!!Op} closeHandler={() => setOp(null)}>
				{Op === 'create' ? (
					<CreateAcquisition
						cb={(acquisition: any) => {
							const id = acquisition?.data?.createAcquisition.id
							if (id) setRedirect(`/mgr/acquisitions/${id}`)
							else {
								// window.location.reload()
							}
						}}
					/>
				) : null}
			</Modal>
		</Page>
	)
}
export default AcquisitionsPage
