import React from 'react'
import styled from 'styled-components'
import { Icon, Spinner } from '../UI'
import { IconType } from '../UI/Icon/fa.defaults'

export const HeaderPageIconView = styled.div`
	${props => props.theme.media.sdesk} {
	}

	.icon {
		height: 40px;
		width: 40px;
		color: ${props => props.theme.colors.ivory.tint(50).val};

		${props => props.theme.media.sdesk} {
			height: 50px;
			width: 50px;
		}

		svg {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}
`

export type HeaderPageIconProps = {
	icon?: IconType
	isLoading?: boolean
}
export const HeaderPageIcon: React.FC<HeaderPageIconProps> = ({ icon, isLoading }) => {
	return (
		<HeaderPageIconView>
			{isLoading ? <Spinner noBg size={5} /> : null}
			{icon ? <Icon type={icon} /> : null}
		</HeaderPageIconView>
	)
}
