import { RouteComponentProps, Router } from '@reach/router'
import React from 'react'

import Protect from '../Components/common/Protect'
import Page404 from '../Pages/404.page'
import ResearchPage from '../Pages/Research'
import { ResearchLeasePayments } from '../Pages/Research/Research.leasePayments'
import { ParcelResearchByRelatedPage } from '../Pages/Research/Research.parcels/Research.parcels.related'

const ResearchRoutes: React.FC<RouteComponentProps> = () => {
	return (
		<Protect>
			<Router>
				<ResearchPage path='/' />
				<ParcelResearchByRelatedPage path='/parcels-related' />
				<ResearchLeasePayments path='/lease-payments' />
				<Page404 default />
			</Router>
		</Protect>
	)
}
export default ResearchRoutes
