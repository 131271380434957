import styled, { css } from 'styled-components'
import { User } from '../../gql_generated/document_types'
import { useStoreState } from '../../store'
import { Trunc } from '../UI'

export const userCardStyles = css`
	margin-bottom: 1rem;

	&.__has-border {
		padding: 1rem;
		border: solid 1px ${props => props.theme.colors.lightGrey.val};
		border-radius: 5px;
	}

	& > * {
		color: ${props => props.theme.colors.grey.val};
		display: block;
		white-space: nowrap;
	}
	.user-name {
		margin-bottom: 0.5em;
	}
	.user-email {
		font-size: 0.7rem;
	}
`

const UserCardView = styled.div`
	${userCardStyles}
`

export type UserCardProps = {
	user?: User
	includeBorder?: boolean
}
export const UserCard = ({ user, includeBorder }: UserCardProps): JSX.Element => {
	const { me } = useStoreState(state => state.user)
	const u = user || me
	return (
		<UserCardView className={`user-card${includeBorder ? ' __has-border' : ''}`}>
			{u?.firstName ? <div className='user-name'>{u.firstName}</div> : null}
			{u?.email ? (
				<Trunc className='user-email' maxChars={30}>
					{u.email}
				</Trunc>
			) : null}
		</UserCardView>
	)
}
