import React from 'react'
import styled, { css } from 'styled-components/macro'
import { SaleDetail } from '../../../Components/common/Sale/Sale.detail'

export const relatedDocSaleModalStyles = css``

const RelatedDocSaleModalView = styled.div`
	${relatedDocSaleModalStyles}
`

export type RelatedDocSaleModalProps = {
	saleId: number
}
export const RelatedDocSaleModal: React.FC<RelatedDocSaleModalProps> = ({ saleId }) => (
	<RelatedDocSaleModalView>
		<SaleDetail saleId={saleId} />
	</RelatedDocSaleModalView>
)
