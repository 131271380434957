import React from 'react'
import styled, { css } from 'styled-components/macro'
import { LogDetail } from '../../../Components/common/Log/Log.detail'

export const relatedOrgLogModalStyles = css``

const RelatedOrgLogModalView = styled.div`
	${relatedOrgLogModalStyles}
`

export type RelatedOrgLogModalProps = {
	logId: number
}
export const RelatedOrgLogModal: React.FC<RelatedOrgLogModalProps> = ({ logId }) => {
	return (
		<RelatedOrgLogModalView>
			<LogDetail logId={logId} />
		</RelatedOrgLogModalView>
	)
}
