import { useFormikContext } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { royaltyStatuses } from '../../../utils'
import {
	Btn,
	Icon,
	PercentageField,
	PriceField,
	Select,
	SelectEstateType,
	Textarea,
} from '../../UI'
import { IconType } from '../../UI/Icon/fa.defaults'

export const royaltyfieldsSchema = Yup.object().shape({
	estateType: Yup.string().required('Estate type is required'),
	rateType: Yup.string().required('Rate type is required'),
	rate: Yup.number().required('Please enter a rate for this royalty'),
	description: Yup.string().required('Please enter a description'),
})

type RoyaltyFieldsProps = {
	estateType?: string
	estateTypes?: string[]
	isUpdate?: boolean
}
const RoyaltyFields: React.FC<RoyaltyFieldsProps> = ({ estateType, estateTypes, isUpdate }) => {
	const { values } = useFormikContext<{
		rateType: string
		estateType: string
	}>()
	const { rateType } = values
	return (
		<>
			{isUpdate ? (
				<Select
					name='status'
					label='Status'
					options={royaltyStatuses.map(status => ({ val: status }))}
				/>
			) : null}
			{estateType ? (
				<div className='preselected-estate-type'>
					<label>Estate Type</label>
					<Btn disabled>
						<Icon type={(estateType as IconType) || (values.estateType as IconType)} />
					</Btn>
				</div>
			) : (
				<SelectEstateType
					name='estateType'
					label='Select Estate Type'
					isReq
					estateTypes={estateTypes}
				/>
			)}
			<Select
				name='rateType'
				label='Rate Type'
				options={[
					{ txt: 'Percentage', val: 'percentage' },
					{ txt: 'Fixed', val: 'amount' },
				]}
				isReq
			/>
			{rateType === 'percentage' ? (
				<PercentageField name='rate' label='Rate' isReq />
			) : (
				<PriceField name='rate' label='Rate' decimals={6} isReq />
			)}
			<Textarea name='description' label='Description' isReq />
		</>
	)
}
export default RoyaltyFields
