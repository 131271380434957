import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Tab, Tabs } from '../../../../Components/UI'
import { RelatedSaleActivityAgreements } from './Sale.related.activity.agreements'
import { RelatedSaleActivityLeases } from './Sale.related.activity.leases'

export const relatedSaleParcelStyles = css``

const RelatedSaleActivityView = styled.div`
	${relatedSaleParcelStyles}
`

/**
 *  Leases and agreements on parcel estates on the sale
 * */

export type RelatedSaleActivityProps = {
	saleId: number
}
export const RelatedSaleActivity: React.FC<RelatedSaleActivityProps> = ({ saleId }) => {
	return (
		<RelatedSaleActivityView>
			<Tabs renderOnSelect>
				<Tab nav='Leases' icon='lease'>
					<RelatedSaleActivityLeases saleId={saleId} />
				</Tab>
				<Tab nav='Agreements' icon='agreement'>
					<RelatedSaleActivityAgreements saleId={saleId} />
				</Tab>
			</Tabs>
		</RelatedSaleActivityView>
	)
}
