import { Redirect, RouteComponentProps } from '@reach/router'
import React, { useEffect, useRef } from 'react'
import CardsSection from '../../Components/common/CardsSection'
import { PageOperation } from '../../Components/common/Operations'
import Page from '../../Components/common/Resources/Resource.page'
import CreateSale from '../../Components/Ops/Ops.Sales/Ops.Sale.create'
import { Card, Modal, Tab, Tabs } from '../../Components/UI'
import {} from '../../gql_generated/graphql'
import { usePageData, useState } from '../../utils'
import SalesPageCancelled from './Sales.page.cancelled'
import SalesPageClosed from './Sales.page.closed'
import SalesPageForeclosed from './Sales.page.foreclosed'
import SalesPageOpen from './Sales.page.open'

const SalesPage: React.FC<RouteComponentProps> = () => {
	const isDone = useRef(false)
	const [Op, setOp] = useState<string | null>(null, 'Op')

	const [redirect, setRedirect] = useState<string | null>(null, 'redirect')

	usePageData({ pageType: 'sales', pageTitle: 'Sales' })

	const operations: PageOperation[] = [
		{
			txt: 'New Sale',
			icon: 'sale',
			op: () => setOp('create'),
		},
	]

	useEffect(() => {
		return () => {
			isDone.current = true
		}
	})

	return redirect ? (
		<Redirect to={redirect} noThrow />
	) : (
		<Page isLoaded operations={operations}>
			<CardsSection>
				<Card>
					<Tabs renderOnSelect>
						<Tab nav='Closed'>
							<SalesPageClosed />
						</Tab>
						<Tab nav='Open'>
							<SalesPageOpen />
						</Tab>
						<Tab nav='Cancelled'>
							<SalesPageCancelled />
						</Tab>
						<Tab nav='Foreclosed'>
							<SalesPageForeclosed />
						</Tab>
					</Tabs>
				</Card>
			</CardsSection>
			<Modal show={!!Op} closeHandler={() => setOp(null)}>
				{Op === 'create' ? (
					<CreateSale
						cb={sale => {
							const id = sale?.data?.createSale.id
							if (id) setRedirect(`/mgr/sales/${id}`)
							else {
								// window.location.reload()
							}
						}}
					/>
				) : null}
			</Modal>
		</Page>
	)
}
SalesPage.propTypes = {}
export default SalesPage
