import {
	useAcquisitionForAcresColQuery,
	useAcquisitionForDatesColQuery,
	useAcquisitionForEstateIconsColQuery,
	useAcquisitionForGrantorColQuery,
} from '../../../../gql_generated/graphql'
import { asAcres, formatDate } from '../../../../utils'
import { sumBy } from '../../../../utils/lodash.utils'
import EstateTypes from '../../../common/Estate/Estates.Icons'
import { TableBodyCellComponent } from '../../Table/Table.types'
import { ColConfig } from './Col.config.types'
import { ResourceTableCellAsync } from './Table.resource.cell.Async'

const Dates: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useAcquisitionForDatesColQuery({
		variables: { id },
	})
	const { dateOpened, dateCancelled, dateExpected, dateClosed, dateForeclosed } =
		fetchResults.data?.acquisition || {}

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{formatDate(dateForeclosed || dateClosed || dateCancelled || dateExpected || dateOpened)}
		</ResourceTableCellAsync>
	)
}

const AcquisitionSrfAcres: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useAcquisitionForAcresColQuery({
		variables: { id },
	})
	const { activity } = fetchResults.data?.acquisition || {}

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{asAcres(
				sumBy(activity, ({ acres, estate, estateType }) =>
					estateType === 'srf' ? acres || estate.acres : 0
				)
			)}
		</ResourceTableCellAsync>
	)
}

const Grantor: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useAcquisitionForGrantorColQuery({
		variables: { id },
	})
	const { grantor } = fetchResults.data?.acquisition || {}
	// console.log('grantee sale: ', fetchResults.data?.sale)
	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{grantor?.name || 'Unknown'}
		</ResourceTableCellAsync>
	)
}

const genEstateTypeIcons =
	(parcelId?: number): TableBodyCellComponent =>
	({ id }) => {
		const [fetchResults] = useAcquisitionForEstateIconsColQuery({
			variables: { id },
		})
		const { activity } = fetchResults.data?.acquisition || {}

		return (
			<ResourceTableCellAsync fetchResults={fetchResults}>
				{activity?.length ? (
					<EstateTypes
						isStatic
						selected={activity
							.filter(ea => (parcelId ? ea.parcelId === parcelId : true))
							.map(({ estateType }) => estateType)}
						hideUnselected
					/>
				) : null}
			</ResourceTableCellAsync>
		)
	}

export const acquisitionDateColConfig: ColConfig = {
	format: 'date',
	label: 'Date',
	Component: Dates,
}

export const acquisitionDateOpenedColConfig: ColConfig = {
	field: 'dateOpened',
	format: 'date',
	label: 'Opened',
	sortField: 'dateOpened',
}

export const acquisitionDateClosedColConfig: ColConfig = {
	field: 'dateClosed',
	format: 'date',
	label: 'Closed',
	sortField: 'dateClosed',
}

export const acquisitionDateCancelledColConfig: ColConfig = {
	field: 'dateCancelled',
	format: 'date',
	label: 'Cancelled',
	sortField: 'dateCancelled',
}

export const acquisitionDateExpectedColConfig: ColConfig = {
	field: 'dateExpected',
	format: 'date',
	label: 'Expected Close',
	sortField: 'dateExpected',
}

export const acquisitionDateForeclosedColConfig: ColConfig = {
	field: 'dateForeclosed',
	format: 'date',
	label: 'Foreclosed',
	sortField: 'dateForeclosed',
}

export const acquisitionEscrowIdColConfig: ColConfig = {
	field: 'escrowId',
	label: 'Escrow ID',
	sortField: 'escrowId',
	hideMobile: true,
}
export const acquisitionGrantorColConfig: ColConfig = {
	label: 'Grantor',
	sortField: 'grantor.name',
	Component: Grantor,
}
export const acquisitionSrfAcresColConfig: ColConfig = {
	format: 'acres',
	label: 'Surface',
	hideMobile: true,
	Component: AcquisitionSrfAcres,
}
export const acquisitionPriceColConfig: ColConfig = {
	field: 'price',
	format: 'currency',
	label: 'Price',
	hideMobile: true,
}

export const acquisitionEstatesColConfig: ColConfig = {
	label: 'Estates',
	hideMobile: true,
	Component: genEstateTypeIcons(),
}

export const acquisitionEstatesOnParcelColConfig = (parcelId: number): ColConfig => ({
	label: 'Estates',
	hideMobile: true,
	Component: genEstateTypeIcons(parcelId),
})

export const acquisitionStatusColConfig: ColConfig = {
	field: 'status',
	format: 'status',
	label: 'Status',
	hideMobile: true,
}

export const acquisitionsPageClosedColsConfig: ColConfig[] = [
	acquisitionDateOpenedColConfig,
	acquisitionDateClosedColConfig,
	acquisitionEscrowIdColConfig,
	acquisitionGrantorColConfig,
	acquisitionSrfAcresColConfig,
	acquisitionPriceColConfig,
	acquisitionEstatesColConfig,
]

export const acquisitionsPageOpenColsConfig: ColConfig[] = [
	acquisitionDateOpenedColConfig,
	acquisitionDateExpectedColConfig,
	acquisitionEscrowIdColConfig,
	acquisitionGrantorColConfig,
	acquisitionSrfAcresColConfig,
	acquisitionPriceColConfig,
	acquisitionEstatesColConfig,
]

export const acquisitionsPageCancelledColsConfig: ColConfig[] = [
	acquisitionDateOpenedColConfig,
	acquisitionDateCancelledColConfig,
	acquisitionEscrowIdColConfig,
	acquisitionGrantorColConfig,
	acquisitionSrfAcresColConfig,
	acquisitionPriceColConfig,
	acquisitionEstatesColConfig,
]

export const acquisitionsPageForeclosedColsConfig: ColConfig[] = [
	acquisitionDateOpenedColConfig,
	acquisitionDateClosedColConfig,
	acquisitionDateForeclosedColConfig,
	acquisitionEscrowIdColConfig,
	acquisitionGrantorColConfig,
	acquisitionSrfAcresColConfig,
	acquisitionPriceColConfig,
	acquisitionEstatesColConfig,
]

export const parcelRelatedAcquisitionsColsConfig = (parcelId: number): ColConfig[] => [
	acquisitionDateColConfig,
	acquisitionEscrowIdColConfig,
	acquisitionGrantorColConfig,
	acquisitionSrfAcresColConfig,
	acquisitionPriceColConfig,
	acquisitionEstatesOnParcelColConfig(parcelId),
]

export const docRelatedAcquisitionsColsConfig: ColConfig[] = [
	acquisitionDateColConfig,
	acquisitionEscrowIdColConfig,
	acquisitionGrantorColConfig,
	acquisitionSrfAcresColConfig,
	acquisitionPriceColConfig,
	acquisitionEstatesColConfig,
]

export const orgRelatedAcquisitionsColsConfig: ColConfig[] = [
	acquisitionDateColConfig,
	acquisitionEscrowIdColConfig,
	acquisitionGrantorColConfig,
	acquisitionSrfAcresColConfig,
	acquisitionPriceColConfig,
	acquisitionEstatesColConfig,
]

export const researchRelatedAcquisitionsColsConfig: ColConfig[] = [
	acquisitionDateColConfig,
	acquisitionEscrowIdColConfig,
	acquisitionGrantorColConfig,
	acquisitionSrfAcresColConfig,
	acquisitionPriceColConfig,
	acquisitionEstatesColConfig,
]
