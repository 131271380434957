import React from 'react'
import styled from 'styled-components'
import { EstateDetail } from '../Estate/Estate.detail'

const ParcelEstateModalView = styled.div`
	& > header {
		display: flex;
		width: 100%;

		.edit-estate-btn {
			margin-left: auto;
		}
	}
	.cols {
		min-height: 350px;
		grid-template-columns: 350px 1fr;
	}
	.estate-ico {
		position: absolute;
		height: 100%;
		width: 100%;
		color: ${props => props.theme.colors.grey.light(43).val};
		object-fit: cover;
		object-position: center;
	}
`

type ParcelEstateModalProps = {
	estateId: number
}

export const ParcelEstateModal: React.FC<ParcelEstateModalProps> = ({ estateId }) => {
	return (
		<ParcelEstateModalView>
			<EstateDetail estateId={estateId} />
		</ParcelEstateModalView>
	)
}
