import isFunction from 'lodash/isFunction'
import React, { useRef } from 'react'
import { AgreementStatus } from '../../../gql_generated/document_types'
import {
	AgreementInput,
	useAgreementFullQuery,
	useUpdateAgreementMutation,
} from '../../../gql_generated/graphql'
import { parseDateForServer, sanitizeDataForForm } from '../../../utils'
import { Block, Form, Spinner, SubmitBtn } from '../../UI'
import AgreementFields, { agreementSchema } from './Ops.Agreement.fields'

type EditAgreementProps = {
	id: number
	cb?: (data: any) => void
}

const EditAgreement: React.FC<EditAgreementProps> = ({ id, cb }) => {
	const [{ data, fetching, error }] = useAgreementFullQuery({
		variables: { id },
	})
	const [_, updateAgreement] = useUpdateAgreementMutation()
	const stopRender = useRef(false) // component should no longer render markup

	const { agreement } = data || {}

	console.log('agreement: ', agreement)

	return stopRender.current ? null : fetching ? (
		<Spinner />
	) : error ? (
		<Block type='err'>{error.message}</Block>
	) : (
		<Form
			legend='Edit Agreement'
			initialValues={sanitizeDataForForm<AgreementInput>(agreement, {
				stripKeys: ['status', 'orgDba'],
			})}
			onSubmit={async (vals: any) => {
				try {
					const data = {
						id,
						...vals,
						orgDbaId: vals.orgDbaId || null,
						dateEffective: vals.dateEffective?.length
							? parseDateForServer(vals.dateEffective)
							: null,
						dateShouldExpire: vals.dateShouldExpire?.length
							? parseDateForServer(vals.dateShouldExpire)
							: null,
						dateExpired: vals.dateExpired?.length ? parseDateForServer(vals.dateExpired) : null,
						dateTerminated: vals.dateTerminated?.length
							? parseDateForServer(vals.dateTerminated)
							: null,
					}

					if (
						(agreement?.status === AgreementStatus.Expired && !vals.dateExpired) ||
						(agreement?.status === AgreementStatus.Terminated && !vals.dateTerminated)
					)
						data.status = 'Active'

					let results
					if (id)
						results = await updateAgreement({
							data,
							// update: cache => {
							// 	cache.evict({ fieldName: 'parcel:{}' })
							// },
						})
					stopRender.current = true
					if (isFunction(cb)) cb(results)
				} catch (err) {
					console.error(err)
				}
			}}
			validationSchema={agreementSchema}
		>
			{() => (
				<>
					<AgreementFields />
					<SubmitBtn />
				</>
			)}
		</Form>
	)
}
export default EditAgreement
