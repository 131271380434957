import styled, { css } from 'styled-components'
import { useParcelCountiesQuery } from '../../../gql_generated/graphql'
import { startCase } from '../../../utils/lodash.utils'
import { Async } from '../Async'
import { FormFieldWrapProps } from './Form.field.wrap'
import SelectRaw, { SelectRawProps } from './Select.raw'

export const selectCountyRawStyles = css``

const SelectCountyRawView = styled.div`
	${selectCountyRawStyles}
`

export type SelectCountyRawProps = FormFieldWrapProps &
	SelectRawProps & {
		defaultTxt?: string
	}
export const SelectCountyRaw = ({
	defaultTxt = 'Select...',
	...props
}: SelectCountyRawProps): JSX.Element => {
	const [results] = useParcelCountiesQuery()
	const options = results.data?.counties.map(county => ({
		val: county,
		txt: startCase(county),
	}))
	return (
		<SelectCountyRawView>
			<Async fetchResults={results}>
				{options ? (
					<SelectRaw {...props} options={[{ val: '', txt: defaultTxt }, ...options]} />
				) : null}
			</Async>
		</SelectCountyRawView>
	)
}
