import React from 'react'
import styled, { css } from 'styled-components/macro'
import { relatedLogsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Log.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { LogParent } from '../../../gql_generated/document_types'
import { usePaginatedLogsQuery } from '../../../gql_generated/graphql'
import { RelatedDocLogModal } from '../Related.Modals/Doc.related.log.modal'

export const relatedDocLogsStyles = css``

const RelatedDocLogsView = styled.div`
	${relatedDocLogsStyles}
`

export type RelatedDocLogsProps = {
	docId: number
}

export const RelatedDocLogs: React.FC<RelatedDocLogsProps> = ({ docId }) => {
	const paginationStore = usePaginationParams()

	const [fetchResults] = usePaginatedLogsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: LogParent.Doc,
						parentIds: [docId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.logs
	return (
		<RelatedDocLogsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedLogsColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedDocLogModal logId={data.id} />}
				resourceType='log'
				suppressGoTo
			/>
		</RelatedDocLogsView>
	)
}
