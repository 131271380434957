import React from 'react'
import styled, { css } from 'styled-components/macro'
import { orgRelatedAgreementsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Agreement.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { AgreementParent } from '../../../gql_generated/document_types'
import { usePaginatedAgreementsQuery } from '../../../gql_generated/graphql'
import { RelatedOrgAgreementModal } from '../Related.Modals/Org.related.agreement.modal'

export const relatedOrgAgreementsStyles = css``

const RelatedOrgAgreementsView = styled.div`
	${relatedOrgAgreementsStyles}
`

export type RelatedOrgAgreementsProps = {
	orgId: number
}

export const RelatedOrgAgreements: React.FC<RelatedOrgAgreementsProps> = ({ orgId }) => {
	const paginationStore = usePaginationParams()

	const [fetchResults] = usePaginatedAgreementsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: AgreementParent.Org,
						parentIds: [orgId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.agreements
	return (
		<RelatedOrgAgreementsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={orgRelatedAgreementsColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={agreement => <RelatedOrgAgreementModal agreementId={agreement.id} />}
				resourceType='agreement'
			/>
		</RelatedOrgAgreementsView>
	)
}
