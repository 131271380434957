import { useFormikContext } from 'formik'
import React from 'react'
import styled, { css } from 'styled-components/macro'
import { RoyaltyActivity } from '../../../../gql_generated/document_types'
import { EstateActivity, useParcelForInfoQuery } from '../../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../../Types'
import { Accordion, Card, Icon, Number, Textarea, ToggleField } from '../../../UI'
import { IconType } from '../../../UI/Icon/fa.defaults'

export const royaltyAddEditActivityParcelCardStyles = css``

const RoyaltyAddEditActivityParcelCardView = styled.div`
	${royaltyAddEditActivityParcelCardStyles}
`

export type RoyaltyAddEditActivityParcelCardProps = {
	estateType: EstateTypeEnum
	parcelId: number
	idx: number
	estateActivity: EstateActivity
	replaceHandler: (index: number, value: any) => void
}

export const RoyaltyAddEditActivityParcelCard: React.FC<RoyaltyAddEditActivityParcelCardProps> = ({
	estateType,
	parcelId,
	idx,
	estateActivity,
	replaceHandler,
}) => {
	const { values } = useFormikContext<{
		royaltyActivity: RoyaltyActivity[]
	}>()
	const existingVals = values.royaltyActivity ? values.royaltyActivity[idx] : null

	const [parcelResults] = useParcelForInfoQuery({ variables: { id: parcelId } })

	const parcel = parcelResults.data?.parcel

	const estate = estateActivity?.estate

	const aliquot = estateActivity?.aliquot?.length
		? estateActivity.aliquot
		: estate?.aliquot?.length
		? estate.aliquot
		: ''

	return (
		<RoyaltyAddEditActivityParcelCardView>
			<Card noShadow className='activity-detail'>
				<div className='info'>
					<Icon type={estateType as IconType} />
					<div className='parcel-identifiers'>
						<span className='county'>{parcel?.county}</span>
						<span className='trs'>{parcel?.trs}</span>
						<span className='apn'>{parcel?.apn}</span>
					</div>
					<ToggleField
						name={`royaltyActivity.${idx}.isTerminated`}
						label='Terminate royalty on this parcel estate'
					/>
				</div>
				<Accordion
					startsOpen={!!existingVals?.acres || !!existingVals?.aliquot}
					ctrl={{
						open: 'Use default aliquot/acreage',
						closed: 'Create custom aliquot/acreage - NOTE: This will override defaults',
					}}
					afterOpen={() => {
						if (!existingVals?.acres && !existingVals?.aliquot)
							replaceHandler(idx, {
								id: existingVals?.id,
								estateActivityId: estateActivity?.id,
								acres: estateActivity?.acres || estate?.acres || 0,
								aliquot,
							})
					}}
					afterClose={() => {
						replaceHandler(idx, {
							id: existingVals?.id,
							estateActivityId: existingVals?.estateActivityId || estateActivity?.id,
							acres: 0,
							aliquot: '',
						})
					}}
				>
					<div className='fields'>
						<Textarea name={`royaltyActivity.${idx}.aliquot`} label='aliquot' />
						<Number name={`royaltyActivity.${idx}.acres`} label='acres' />
					</div>
				</Accordion>
			</Card>
		</RoyaltyAddEditActivityParcelCardView>
	)
}
