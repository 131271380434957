import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Icon } from '../../Icon'
import { IconType } from '../../Icon/fa.defaults'
import { Btn } from '../../Typography'
import { TableRowConfig, TableRowOpConfig } from '../Table.types'

export const tableRowOpStyles = css`
	button {
		background: none;
		.icon {
			background: none;
		}
	}
`

const TableRowOpView = styled.div`
	${tableRowOpStyles}
`

export type TableRowOpProps<T> = Pick<TableRowConfig<T>, 'id' | 'isDisabled' | 'val'> &
	TableRowOpConfig<T> & {}
export const TableRowOp: React.FC<TableRowOpProps<any>> = ({
	id,
	val,
	isDisabled,
	icon,
	type,
	txt,
	op,
}) => {
	const iconType: IconType | null = icon || (type === 'remove' ? 'x' : type || null)

	return (
		<TableRowOpView>
			<Btn
				className={`op${type ? ` op-${type}` : ''}`}
				onClick={() => op(val || id)}
				disabled={isDisabled}
			>
				{iconType ? <Icon type={iconType} /> : null}
				{txt}
			</Btn>
		</TableRowOpView>
	)
}
