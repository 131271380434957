import { ReactNode } from 'react'
import styled from 'styled-components'
import { EditBtn, EditBtnProps } from '..'
import { Icon } from '../Icon'
import { IconType } from '../Icon/fa.defaults'
import { H4, P } from '../Typography'

type CardViewProps = {
	corners?: boolean | string
	borderColorName?: string
	mBot?: boolean
	mTop?: boolean
	mLeft?: boolean
	mRight?: boolean
	headerBgColor?: 'light' | 'normal' | 'dark'
}
const CardView = styled.div<CardViewProps>`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	overflow: hidden;
	border: solid 2px
		${props => (props.borderColorName ? props.theme.colors[props.borderColorName].val : 'none')};
	border-radius: ${props =>
		props.corners && props.corners === true ? '5px' : props.corners ? props.corners : 0};
	width: 100%;
	height: auto;
	box-shadow: 0 2px 4px ${props => props.theme.colors.black.tint().val};
	background: ${props => props.theme.colors.white.val};
	margin-top: ${p => (p.mTop ? '1.3em' : 0)};
	margin-bottom: ${p => (p.mBot ? '1.3em' : 0)};
	margin-left: ${p => (p.mLeft ? '1.3em' : 0)};
	margin-right: ${p => (p.mRight ? '1.3em' : 0)};

	&.__no-shadow {
		box-shadow: none;
		border: solid 1px ${props => props.theme.colors.grey.light().val};
	}
	& > header {
		min-height: 2em;
		align-items: center;
		padding: 0.3em 0.5em;
		padding-left: 1em;
		padding-top: 0.4em;
		background: ${p =>
			p.theme.colors.bg.light(p.headerBgColor === 'light' ? 50 : p.headerBgColor === 'dark' ? 0 : 2)
				.val};
		border-bottom: 1px solid ${props => props.theme.colors.grey.light(40).val};

		display: block;

		${props => props.theme.media.tablet} {
			display: flex;
		}

		.titles {
			display: flex;
			flex-direction: column;
		}
		h4,
		p {
			z-index: 1;
			color: ${p =>
				p.headerBgColor === 'light' ? p.theme.colors.text.val : p.theme.colors.white.val};
		}
	}

	&.__heading-size-sm {
		& > header {
			h4 {
				font-weight: 500;
				margin-bottom: 0.3em;
				font-size: 1rem;
				color: ${p =>
					p.headerBgColor === 'light' ? p.theme.colors.text.val : p.theme.colors.white.val};
			}
		}
	}

	&.__heading-size-lg {
		& > header {
			padding-top: 1em;

			h4 {
				font-weight: 500;
				margin-bottom: 0.3em;
				font-size: 2.3rem;
				color: ${props => props.theme.colors.white.tint(50).val};
				line-height: 2.5rem;
			}
		}
	}

	&.__overflow-enabled {
		overflow: visible;
	}

	.card-content {
		padding: 1em;
	}
	.card-cta {
		position: absolute;
		top: 50%;
		right: 0.5em;
		z-index: 2;
		transform: translateY(-50%);
		button {
			color: ${props => props.theme.colors.white.val};
		}
	}
	.edit-btn-wrap {
		margin-left: auto;
	}
`

type CardProps = CardViewProps & {
	titleText?: ReactNode
	subTitle?: ReactNode
	icon?: IconType
	noShadow?: boolean
	className?: string | number
	cta?: ReactNode
	editHandler?: () => void
	editAllowed?: EditBtnProps['allowed']
	headingSize?: 'sm' | 'lg'
	canOverflow?: boolean
	children?: ReactNode
}

const Card = ({
	children,
	titleText,
	subTitle,
	icon,
	corners = true,
	borderColorName,
	noShadow,
	className,
	cta,
	editHandler,
	editAllowed,
	mBot,
	mTop,
	mLeft,
	mRight,
	headingSize = 'sm',
	canOverflow,
	headerBgColor,
}: CardProps) => {
	const cardViewProps: CardViewProps = {
		corners,
		borderColorName,
		mBot,
		mTop,
		mLeft,
		mRight,
		headerBgColor,
	}

	return (
		<CardView
			className={`card ${className ? ` ${className}` : ''}${
				noShadow ? ' __no-shadow' : ''
			} __heading-size-${headingSize}${canOverflow ? ' __overflow-enabled' : ''}`}
			{...cardViewProps}
		>
			{titleText ? (
				<header className='flex'>
					<div className='titles'>
						{titleText && (
							<H4>
								{icon ? <Icon type={icon} padRight /> : null}
								{titleText}
							</H4>
						)}
						{subTitle && <P>{subTitle}</P>}
					</div>
					{cta ? <div className='card-cta'>{cta}</div> : null}
					{editHandler ? (
						<div className='edit-btn-wrap'>
							<EditBtn allowed={editAllowed} onClick={editHandler} />
						</div>
					) : null}
				</header>
			) : null}
			<main className='card-content'>{children}</main>
		</CardView>
	)
}
export default Card
