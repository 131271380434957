import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Btn, Icon } from '..'
import { useState } from '../../../utils'

export const EditFieldView = styled.div`
	.preset {
		display: flex;
		align-items: center;
		padding: 1em 0;
	}
`

export type EditFieldProps = {
	label: ReactNode
}
export const EditField: React.FC<EditFieldProps> = ({ children, label }) => {
	const [showField, setShowField] = useState(false, 'showField')
	return (
		<EditFieldView>
			{showField ? null : (
				<div className='preset'>
					<div className='label'>{label}</div>
					<Btn onClick={() => setShowField(true)}>
						<Icon type='edit' />
					</Btn>
				</div>
			)}

			{showField ? children : null}
		</EditFieldView>
	)
}
