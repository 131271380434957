import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Icon } from '../Icon'
import Btn, { BtnProps } from '../Typography/Btn'

export const addBtnStyles = css`
	color: ${props => props.theme.colors.white.val};
	button {
		width: 40px;
		height: 40px;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: inherit;
		background: ${props => props.theme.colors.teal.val};
		box-shadow: 0 1px 3px ${props => props.theme.colors.black.tint(40).val};
		transition: all ${props => props.theme.times.tranS};

		.icon {
			transition: all ${props => props.theme.times.tranL} 200ms;
			padding: 0;
			margin: 0;
		}

		&:focus,
		&:active {
			color: inherit;
		}

		&:hover {
			color: inherit;
			background: ${props => props.theme.colors.teal.sat(0).val};
			.icon {
				transform: rotate3d(0, 0, 1, 90deg);
			}
		}
	}

	.text {
		display: none;
	}
`

const AddBtnView = styled.div`
	${addBtnStyles}
`

export type AddBtnProps = {
	onClick?: BtnProps['onClick']
	title?: BtnProps['title']
	className?: string
	children?: string
}
export const AddBtn: React.FC<AddBtnProps> = ({ onClick, title, className, children }) => {
	return (
		<AddBtnView className={`add-btn${className ? ` ${className}` : ''}`}>
			<Btn onClick={onClick} title={title || children}>
				<Icon type='plus' />
				<span className='text'>{children}</span>
			</Btn>
		</AddBtnView>
	)
}
