import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Card } from '../../../../Components/UI'
import { ParcelParent } from '../../../../gql_generated/document_types'
import { parseResourceType } from '../../../../utils'
import { startCase } from '../../../../utils/lodash.utils'
import { RelatedResearchModalParcel } from './Research.related.modal.parcel'

export const relatedResearchModalStyles = css``

const RelatedResearchModalView = styled.div`
	${relatedResearchModalStyles}
`

export type RelatedResearchModalProps = {
	parcelId: number
	parcelParent: ParcelParent
	children: ReactNode
}
export const RelatedResearchModal = ({
	parcelId,
	parcelParent,
	children,
}: RelatedResearchModalProps): JSX.Element => {
	const { plural } = parseResourceType(parcelParent)
	return (
		<RelatedResearchModalView>
			<RelatedResearchModalParcel parcelId={parcelId} />

			<Card titleText={startCase(plural || '')}>{children}</Card>
		</RelatedResearchModalView>
	)
}
