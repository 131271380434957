import React from 'react'
import styled, { css } from 'styled-components'
import {
	useDeleteEstateActivitiesMutation,
	useRemoveParcelsFromDocMutation,
} from '../../../../gql_generated/graphql'
import { useState } from '../../../../utils'
import ErrorBlock from '../../Error.block'
import { Btn, P } from '../../Typography'
import { ResourceTableGlobalOpComponentOptions } from '../Table.resources'

const removeRelatedParcelsOpStyles = css`
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.msg {
		padding: 2em 0;
	}
	.btns {
		width: 100%;
		justify-content: center;
		margin-top: 3em;
		.btn {
			&:last-child {
				margin-left: 1em;
			}
		}
	}
`

const RemoveRelatedParcelsOpView = styled.div`
	${removeRelatedParcelsOpStyles}
`

type RemoveRelatedParcelsOpProps = ResourceTableGlobalOpComponentOptions

export const RemoveRelatedParcelsOp: React.FC<RemoveRelatedParcelsOpProps> = ({
	parentId,
	parentType,
	selectedRowIds,
	deselectedRowIds,
	isTotalQtySelected,
	closeModal,
}) => {
	const [error, setError] = useState<any | null>(null, 'error')
	const [_, removeEstateActivity] = useDeleteEstateActivitiesMutation()
	const [__, removeDocRel] = useRemoveParcelsFromDocMutation()

	const removeHandler = async () => {
		try {
			if (parentType === 'doc')
				await removeDocRel({
					docId: parentId,
					selectedIds: selectedRowIds,
					deselectedIds: deselectedRowIds,
					isTotalQtySelected,
				})
			else
				await removeEstateActivity({
					parentId,
					parentType,
					parcelIds: selectedRowIds,
					deselectedParcelIds: deselectedRowIds,
					isTotalQtySelected,
				})

			closeModal()
		} catch (err: any) {
			setError(err)
		}
	}

	return (
		<RemoveRelatedParcelsOpView>
			<div className='msg'>
				<P>
					Are you sure you want to remove {isTotalQtySelected ? 'all' : selectedRowIds.length}{' '}
					parcel
					{selectedRowIds.length > 1 ? 's' : ''} from this {parentType}?
				</P>
			</div>
			{error ? <ErrorBlock error={error} /> : null}
			<div className='flex btns'>
				<Btn className='cta' onClick={removeHandler}>
					Remove Parcels
				</Btn>
				<Btn onClick={closeModal}>Cancel</Btn>
			</div>
		</RemoveRelatedParcelsOpView>
	)
}
