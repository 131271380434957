import React from 'react'
import styled, { css } from 'styled-components/macro'
import {
	salesEscrowIdColConfig,
	salesGranteeColConfig,
	salesStatusColConfig,
} from '../../../Components/UI/Table.resources/Table.resource.col.configs/Sale.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { SaleParent } from '../../../gql_generated/document_types'
import { usePaginatedSalesQuery } from '../../../gql_generated/graphql'
import { RelatedOrgSaleModal } from '../Related.Modals/Org.related.sale.modal'

export const relatedOrgSalesStyles = css``

const RelatedOrgSalesView = styled.div`
	${relatedOrgSalesStyles}
`

export type RelatedOrgSalesProps = {
	orgId: number
}

export const RelatedOrgSales: React.FC<RelatedOrgSalesProps> = ({ orgId }) => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedSalesQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: SaleParent.Org,
						parentIds: [orgId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const sales = data?.page?.sales
	return (
		<RelatedOrgSalesView>
			<ResourcesTable
				dataset={sales}
				colsConfig={[salesEscrowIdColConfig, salesGranteeColConfig, salesStatusColConfig]}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={sale => <RelatedOrgSaleModal saleId={sale.id} />}
				resourceType='sale'
			/>
		</RelatedOrgSalesView>
	)
}
