import { navigate, RouteComponentProps } from '@reach/router'
import toNumber from 'lodash/toNumber'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import Cards from '../../Components/common/CardsSection'
import { PageOperation } from '../../Components/common/Operations'
import Page from '../../Components/common/Resources/Resource.page'
import EditUserForm from '../../Components/Ops/Ops.Users/Ops.User.edit'
import { Alert, Card, Colorize, ErrorBlock, Spinner } from '../../Components/UI'
import { useDeleteUserMutation, User, useSingleUserQuery } from '../../gql_generated/graphql'
import { useStoreState } from '../../store'
import { usePageData, useState, useToaster } from '../../utils'

const UserPageView = styled.div`
	.form-card {
		& > * {
			width: 100%;
			max-width: 400px;
			margin: 0 auto;
		}
	}
`

type UserPageProps = RouteComponentProps & {
	id?: string
}
const UserPage: React.FC<UserPageProps> = ({ id }) => {
	const [Op, setOp] = useState<string | null>(null, 'Op')
	const [updatePage] = usePageData({
		pageType: 'user',
	})

	const [_, deleteUser] = useDeleteUserMutation()

	const toast = useToaster()

	const { me } = useStoreState(state => state.user)

	const [{ data, fetching, error }] = useSingleUserQuery({ variables: { id: toNumber(id) } })

	const { user } = data || {}

	useEffect(() => {
		if (user) updatePage({ pageTitle: user.firstName || 'User' })
	}, [user, updatePage])

	const operations: PageOperation[] = [
		{
			txt: 'Delete',
			icon: 'x',
			op: () => setOp('delete'),
		},
	]

	const deleteOp = async (shouldDelete: boolean) => {
		if (shouldDelete) {
			try {
				await deleteUser({ id: toNumber(id) })
				navigate('/mgr/users')
			} catch (err) {
				toast('Error deleting parcel', { type: 'err' })
			}
		} else setOp(null)
	}

	return (
		<UserPageView>
			<Page isLoaded operations={operations}>
				<Cards>
					<Card className='form-card'>
						{fetching ? (
							<Spinner />
						) : error ? (
							<ErrorBlock error={error} />
						) : (
							<EditUserForm user={(user as User) || me} />
						)}
					</Card>
				</Cards>
			</Page>
			{Op === 'delete' ? (
				<Alert
					msg='Are you sure you want to delete this user? (Cannot be undone)'
					cb={deleteOp}
					okBtn='Cancel'
					cancelBtn={
						<Colorize color='err' asBlock>
							Delete Permanently
						</Colorize>
					}
					useCancelAsOp
				/>
			) : null}
		</UserPageView>
	)
}
export default UserPage
