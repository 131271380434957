import isFunction from 'lodash/isFunction'
import React, { useRef } from 'react'
import { useCreateEasementMutation } from '../../../gql_generated/graphql'
import { defaultEasementVals, parseDateForServer, priceToNumber } from '../../../utils'
import { Form, SubmitBtn } from '../../UI'
import EasementFields, { easementSchema } from './Ops.Easement.fields'

type CreateEasementProps = {
	cb?: (data: any) => void
}
const CreateEasement: React.FC<CreateEasementProps> = ({ cb }) => {
	const [_, createEasement] = useCreateEasementMutation()
	const stopRender = useRef(false) // component should no longer render markup

	return stopRender.current ? null : (
		<Form
			legend='Create New Easement'
			initialValues={defaultEasementVals}
			onSubmit={async (vals: any) => {
				try {
					const { ...data } = vals
					// console.log('creating sale with data: ', data)
					data.granteeDbaId = data.granteeDbaId || null
					data.dateEffective = parseDateForServer(vals.dateEffective)
					data.dateTerminated = parseDateForServer(vals.dateTerminated)
					const results = await createEasement({ data })
					stopRender.current = true
					data.price = priceToNumber(vals.price)
					if (isFunction(cb)) cb(results)
				} catch (err) {
					console.error(err)
				}
			}}
			validationSchema={easementSchema}
		>
			{() => (
				<>
					<EasementFields />
					<SubmitBtn />
				</>
			)}
		</Form>
	)
}
export default CreateEasement
