import React, { ReactNode } from 'react'
import styled from 'styled-components'
import isNil from 'lodash/isNil'

const Wrap = styled.div`
	align-items: flex-start;
	padding: 0.5em 0;
	.label {
		margin-right: 0.3em;
		color: ${props => props.theme.colors.grey.val};
		text-transform: capitalize;
		white-space: nowrap;
		&:after {
			content: ':';
			display: inline;
			margin-left: 0.3em;
		}
	}
	.val {
	}
`

type KeyValProps = {
	label: ReactNode
	val?: ReactNode
	className?: string
}

const KeyVal: React.FC<KeyValProps> = ({ label, val = null, className = null, children }) => {
	return (
		<Wrap className={`key-val flex${className ? ` ${className}` : ''}`}>
			<span className='label'>{label}</span>
			<span className='val'>{isNil(children) ? val : children}</span>
		</Wrap>
	)
}
export default KeyVal
