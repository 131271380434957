import React, { ReactNode } from 'react'
import styled from 'styled-components'

import isNil from 'lodash/isNil'
import reduce from 'lodash/reduce'
import replace from 'lodash/replace'

import { formatValue } from '../../../utils'
import { FormatValueOptions } from '../../../utils/formatValue'
import { Skeleton } from '../Loaders'

const Table = styled.table`
	tr.br {
		td {
			padding: 0.5em;
		}
	}
	td {
		padding: 0.2em 0;
	}
	.label {
		color: ${props => props.theme.colors.grey.val};
		text-align: right;
		padding-right: 0.5em;
		text-transform: capitalize;
		position: relative;
		&:after {
			content: ':';
			display: block;
			position: absolute;
			right: 0;
			bottom: 0.2em;
		}
		${props => props.theme.media.tablet} {
			white-space: nowrap;
		}
	}
	.val {
		padding-left: 1em;
	}
`
const parseLabel = (key: string) => {
	return replace(key, '_', ' ')
}

type KeyVal = {
	id?: number
	isBr?: boolean
	label?: ReactNode | null
	val?: ReactNode | null
}

const createKeyVals = ({
	items,
	noVal,
}: {
	items?: Array<KeyValItem>
	noVal?: ReactNode
}): KeyVal[] =>
	reduce(
		items,
		(arry: any, value: any) => {
			if (!value) return arry

			const label = value?.length > 1 ? value[0] : parseLabel(value[0])
			const val = value === 'br' ? null : value?.length > 1 ? value[1] : value[0]
			const format = value[2]

			return [
				...arry,
				{
					isBr: value === 'br',
					label,
					val: isNil(val) ? noVal : formatValue(val, format),
				},
			]
		},
		[]
	)

export type KeyValItem =
	| [
			label: string,
			value: ReactNode,
			options?: FormatValueOptions | { format?: FormatValueOptions; withCopyPaste?: boolean }
	  ]
	| null
	| 'br'

type KeyValsProps = {
	items?: Array<KeyValItem>
	err?: ReactNode
	Empty?: ReactNode
	noVal?: ReactNode
}

const KeyVals: React.FC<KeyValsProps> = ({ items, err, Empty = 'No items...', noVal = 'N/A' }) => {
	const keyVals = createKeyVals({ items, noVal })

	return (
		<Table>
			<tbody>
				{err ? (
					<tr>
						<td className='error'>{err}</td>
					</tr>
				) : keyVals && keyVals.length ? (
					keyVals.map((item, idx: number) => {
						const { id, label, val, isBr } = item
						// console.log('label: ', label, 'val: ', val)
						return (
							<tr key={id || idx} className={isBr ? 'br' : ''}>
								{isBr ? (
									<td />
								) : (
									<>
										<td className='label'>
											<span>{label}</span>
										</td>
										<td className='val'>{val}</td>
									</>
								)}
							</tr>
						)
					})
				) : keyVals ? (
					<tr>
						<td className='empty'>{Empty}</td>
					</tr>
				) : (
					<tr>
						<td>
							<Skeleton />
						</td>
					</tr>
				)}
			</tbody>
		</Table>
	)
}
export default KeyVals
