import { ReactNode } from 'react'
import styled from 'styled-components'
import { UseQueryState } from 'urql'
import { PrimaryResourceType } from '../../../Types'
import { parseResourceType } from '../../../utils'
import { FormatValueObj, FormatValueOptions } from '../../../utils/formatValue'
import { Dropdown } from '../Dropdown'
import { Icon } from '../Icon'
import { IconType } from '../Icon/fa.defaults'
import { Modal, useModalCtrl } from '../Modal'
import Pagination from '../Pagination'
import { SearchBar } from '../Search'
import {
	Table,
	TableDisplayOptions,
	TableGlobalOpsConfigOptions,
	TableProps,
	TableSelectRowsConfigOptions,
} from '../Table/Table'
import {
	TableBodyCellConfig,
	TableClickRowHandler,
	TableDisableRow,
	TableGlobalOpConfig,
	TableHeadCellConfig,
	TableRowOpConfig,
} from '../Table/Table.types'
import { TableSelectRowsState, useTableSelectRows } from '../Table/useTableSelectRows'
import { useTableState } from '../Table/usetTableState'
import { Btn } from '../Typography'
import {
	ResourcesTableLimitDropdown,
	ResourcesTableLimitDropdownProps,
} from './Table.resources.limitDropdown'
import { resourcesTableStyles } from './Table.resources.styles'
import { ResourcesTableTotal } from './Table.resources.total'
import { createColsFromColsConfig, createTableRowsFromDataSet } from './Table.resources.utils'
import { PaginationStore } from './usePaginationParams'

export const ResourcesTableView = styled.div`
	${resourcesTableStyles}
`

/**
 *  Resource table selection handler definitions
 * */
export type ResourceTableSelectState<T> = {
	selectedIds: TableSelectRowsState['selectedRowIds']
	selectedVals: T[]
	isTotalQtySelected: TableSelectRowsState['isTotalQtySelected']
}

type FieldKey = string
type Label = string
type Format = FormatValueOptions | FormatValueObj | null
type SortField = string
export type ResourceTableColConfigArry = [Label, FieldKey, Format?, SortField?]

export type ResourceTableGlobalOpComponentOptions = Pick<
	TableSelectRowsState,
	'isTotalQtySelected' | 'selectedRowIds' | 'deselectedRowIds'
> & {
	closeModal: () => void
	parentType: PrimaryResourceType
	parentId: number
}

type ResourceTableGlobalOpArgs = Omit<
	ResourceTableGlobalOpComponentOptions,
	'parentType' | 'parentId'
>

type ResourceTableGlobalOpFunc = (args: ResourceTableGlobalOpArgs) => ReactNode

type ResourceTableMiscOp = {
	icon?: IconType
	title?: string
	op: () => void
}

export type ResourceTableGlobalOpConfig = Pick<
	TableGlobalOpConfig,
	'icon' | 'fireOnSelect' | 'txt'
> & {
	op: ResourceTableGlobalOpFunc
}

export type ResourceTableColConfig<T> =
	| string
	| ResourceTableColConfigArry
	| (Partial<TableHeadCellConfig> & {
			field?: string | ((data: T) => ReactNode)
			Component?: TableBodyCellConfig['Component']
			format?: Format
			isRowTrigger?: boolean
	  })

type ResourceTableDisplayOptions = TableDisplayOptions & {
	hideTotalQty?: boolean
	hidePagination?: boolean
}

/**
 *  RESOURCE TABLE PROP TYPES
 * */
export type ResourcesTableProps<T> = {
	colsConfig: ResourceTableColConfig<T>[]

	// NOTE: fetchMore is required for pagination
	// NOTE: refetch is required for sorting
	fetchResults: Pick<UseQueryState, 'data' | 'fetching' | 'error'>
	dataset?: T[]

	paginationStore?: PaginationStore

	modalRenderFunc?: (itemData: T) => ReactNode

	resourceType?: PrimaryResourceType | 'contact' | 'leaseTerms'
	includeSearch?: boolean
	advancedSearch?: ReactNode
	children?: ReactNode

	miscOps?: ResourceTableMiscOp[]

	tableOps?: ResourceTableGlobalOpConfig[]

	onClickRow?: TableClickRowHandler<T>

	selectRowConfig?: TableSelectRowsConfigOptions<T>

	rowOps?: TableRowOpConfig<T>[]
	disableRow?: TableDisableRow

	contentOverride?: TableProps<T>['contentOverride']

	// We allow the table to be configured directly if necessary
	// NOTE: direct settings above should override settings in this config
	tableConfig?: TableProps<T>

	displayOptions?: ResourceTableDisplayOptions

	suppressGoTo?: boolean

	limitSelectConfig?: {
		hideLimitSelect?: boolean
		maxAllowed?: ResourcesTableLimitDropdownProps['maxAllowed']
	}
}

/**
 *  RESOURCE TABLE COMPONENT
 * */
export function ResourcesTable<T>({
	colsConfig,
	fetchResults,
	dataset,
	paginationStore,

	modalRenderFunc,
	resourceType,
	includeSearch,

	advancedSearch,
	children,

	miscOps,

	tableOps,
	rowOps,
	disableRow,

	onClickRow,

	selectRowConfig,

	contentOverride,

	displayOptions: resourceTableDisplayOptions,
	tableConfig,

	suppressGoTo,
	limitSelectConfig,
}: ResourcesTableProps<T>) {
	const { hideTotalQty, hidePagination, ...displayOptions } = resourceTableDisplayOptions || {}
	const clickRowHandler = onClickRow || tableConfig?.clickRowHandler

	const { data, fetching, error } = fetchResults
	const page = data?.page || {}
	const { totalQty } = page || {}

	const { hideLimitSelect, ...limitSelectProps } = limitSelectConfig || {}

	/**
	 *  Resource Table State
	 * */
	const { params, updateParams } = paginationStore || {}
	const { limit, offset, sortOptions } = params || {}
	const { sortHandler, setSearch, setOffset } = updateParams || {}

	/**
	 *  Table State
	 * */
	const tableStore = useTableState()

	/**
	 *  Resource Modal
	 * */
	const [[showResourceModal, openResourceModal, closeResourceModal, resourceModalData]] =
		useModalCtrl<T>()
	const modalContent =
		modalRenderFunc && resourceModalData ? modalRenderFunc(resourceModalData) : null

	/**
	 *  Global Ops
	 * */
	const selectRowsStore = useTableSelectRows()
	const [[showOpModal, openOpModal, closeOpModal, renderedOp]] = useModalCtrl<ReactNode>()

	const paginationEnabled =
		!hidePagination &&
		!contentOverride &&
		typeof limit === 'number' &&
		typeof offset === 'number' &&
		setOffset

	const globalOpsConfig: TableGlobalOpsConfigOptions | undefined = tableOps && {
		selectRowsStore,
		tableOps: tableOps.map(({ op, ...config }) => ({
			...config,
			op: () => {
				openOpModal(
					op({
						isTotalQtySelected: selectRowsStore.isTotalQtySelected,
						selectedRowIds: selectRowsStore.selectedRowIds,
						deselectedRowIds: selectRowsStore.deselectedRowIds,
						closeModal: () => {
							selectRowsStore.updateSelectRowState(false)
							closeOpModal()
						},
					})
				)
			},
		})),
		totalQty,
	}

	/**
	 *  Table Props
	 * */
	const tableProps: TableProps<T> = {
		cols: createColsFromColsConfig(colsConfig),
		rows: dataset ? createTableRowsFromDataSet(dataset, colsConfig) : [],
		rowsQty: limit,

		fetching,
		error,
		rowOps,

		tableStore,
		contentOverride,
		sortConfig:
			sortOptions && sortHandler
				? {
						sortState: sortOptions,
						sortHandler,
				  }
				: undefined,
		clickRowHandler: modalRenderFunc
			? val => {
					openResourceModal(val)
			  }
			: clickRowHandler,

		globalOpsConfig,
		selectRowConfig,

		disableRow,

		displayOptions,
	}

	/**
	 *  Go To CTA
	 * */
	const resource = resourceModalData as any

	return (
		<ResourcesTableView>
			<header className='resource-table-header'>
				<div className='resource-table-header-col'>
					{includeSearch && setSearch ? (
						<SearchBar
							hasBorder
							searchHandler={val => (val.length > 2 ? setSearch(val) : null)}
							clearHandler={() => setSearch('')}
						/>
					) : null}

					{advancedSearch ? <div className='advanced-search-options'>{advancedSearch}</div> : null}
				</div>
				<div className='resource-table-header-col'>
					{miscOps?.length ? (
						<div className='misc-ops'>
							<Dropdown
								zIndex={5}
								ctrl={
									<div className='download-options-ctrl'>
										<span>Export</span> <Icon type='circlesV' />
									</div>
								}
							>
								{miscOps.map(({ icon, title, op }, idx) => (
									<Btn className='misc-op-btn' onClick={op} key={idx}>
										{icon ? <Icon type={icon} /> : null}
										{title ? title : null}
									</Btn>
								))}
							</Dropdown>
						</div>
					) : null}
					{hideTotalQty ? null : <ResourcesTableTotal fetchResults={fetchResults} />}
				</div>
			</header>

			{children ? <section className='custom-content-section'>{children}</section> : null}

			<section className='table-section'>
				<Table {...(tableConfig || {})} {...tableProps} />
			</section>

			{paginationEnabled ? (
				<section className='pagination-section'>
					{hideLimitSelect || !paginationStore ? null : (
						<ResourcesTableLimitDropdown
							paginationStore={paginationStore}
							totalQty={totalQty}
							{...limitSelectProps}
						/>
					)}
					<Pagination
						limit={limit}
						offset={offset}
						handler={setOffset}
						totalCount={page.totalQty}
					/>
				</section>
			) : null}

			<Modal
				show={showResourceModal}
				closeHandler={closeResourceModal}
				size='med'
				link={
					!suppressGoTo && resourceType && resource?.id
						? `/mgr/${parseResourceType(resourceType).plural}/${resource.id}`
						: undefined
				}
				linkTxt={`Go to ${resourceType}`}
			>
				{modalContent}
			</Modal>

			<Modal show={showOpModal} closeHandler={closeOpModal} size='med'>
				{renderedOp}
			</Modal>
		</ResourcesTableView>
	)
}
