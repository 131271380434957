import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Skeleton } from '../../Loaders'

export const tableBodyLoaderRowStyles = css`
	td {
		padding: 0.8em 0.5em;
		min-width: 70px;
	}
`

const TableBodyLoaderRowView = styled.tr`
	${tableBodyLoaderRowStyles}
`

export type TableBodyLoaderRowProps = {
	colsQty: number
}
export const TableBodyLoaderRow: React.FC<TableBodyLoaderRowProps> = ({ colsQty }) => {
	const cells: string[] = Array(colsQty).fill('')
	return (
		<TableBodyLoaderRowView>
			{cells.map((_, i) => (
				<td key={`key-${i}`}>
					<Skeleton />
				</td>
			))}
		</TableBodyLoaderRowView>
	)
}
