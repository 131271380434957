import styled from 'styled-components'

const CardsColumnsView = styled.section`
	${props => props.theme.media.sdesk} {
		display: grid;
		grid-gap: 16px;
		padding-bottom: 5px;
		.card {
		}
	}

	&.cols {
		grid-template-columns: initial;
		display: grid;

		& > * {
			margin-bottom: 1em;

			&:last-child {
				margin-bottom: 0;
			}
		}
		${props => props.theme.media.sdesk} {
			grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
		}
	}
`
export default CardsColumnsView
