import React from 'react'
import Cleave from 'cleave.js/react'

import FormFieldWrap, { FormFieldWrapProps } from './Form.field.wrap'
import { InputProps } from './Input'
import { useField } from 'formik'
import styled from 'styled-components'

const View = styled.div``

const YearField: React.FC<InputProps & FormFieldWrapProps> = ({
	label,
	name,
	helperTxt,
	isReq,
	...props
}) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [{ value }, __, { setValue }] = useField({ name, ...props })
	return (
		<FormFieldWrap type='year' label={label} name={name} helperTxt={helperTxt} isReq={isReq}>
			<View>
				<Cleave
					name={name}
					options={{ date: true, datePattern: ['Y'] }}
					value={value}
					onChange={e => setValue(e.target.value)}
				/>
			</View>
		</FormFieldWrap>
	)
}
export default YearField
