import React from 'react'
import styled from 'styled-components'

export const TabContainerView = styled.div`
	.__pre-rendered {
		display: flex;
		max-width: 100%;
		overflow: hidden;
	}
`

export type TabContainerProps = {
	isActive?: boolean
	preRender?: boolean
}
export const TabContainer: React.FC<TabContainerProps> = ({ isActive, preRender, ...props }) => (
	<TabContainerView
		className={`tab-wrap ${isActive ? ' __active' : ''}${preRender ? ' __pre-rendered' : ''}`}
		style={preRender ? { display: isActive ? 'block' : 'none' } : {}}
		{...props}
	/>
)
