import React from 'react'
import styled, { css } from 'styled-components/macro'
import { AddBtn, Modal, useModalCtrl } from '../../UI'
import { CreateContact } from './Ops.Org.contact.create'

export const addContactToOrgBtnStyles = css``

const AddContactToOrgBtnView = styled.div`
	${addContactToOrgBtnStyles}
`

export type AddContactToOrgBtnProps = {
	orgId: number
}
export const AddContactToOrgBtn: React.FC<AddContactToOrgBtnProps> = ({ orgId }) => {
	const [[showModal, openModal, closeModal]] = useModalCtrl()
	return (
		<AddContactToOrgBtnView>
			<AddBtn onClick={openModal}>Add Contact</AddBtn>
			<Modal show={showModal} closeHandler={closeModal}>
				<CreateContact orgId={orgId} cb={closeModal} />
			</Modal>
		</AddContactToOrgBtnView>
	)
}
