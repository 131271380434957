import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useGTable } from './GTable.Container'

const GTableCellView = styled.div`
	white-space: nowrap;

	.g-table-ref-cell {
		padding: 0.3em 0.5em;
		width: auto;
		display: inline-block;
	}
`

export type GTableCellContainerProps = {
	colIdx: number
}
export const GTableCellContainer: React.FC<GTableCellContainerProps> = ({ colIdx, children }) => {
	const cellRef = useRef<HTMLDivElement>(null)
	const { setColWidth, cols } = useGTable()

	const col = cols.find((_, idx) => idx === colIdx)

	useEffect(() => {
		const setWidth = () => {
			setColWidth({
				cellRef,
				colIdx,
			})
		}

		setWidth()

		window.addEventListener('resize', setWidth)
		return () => {
			window.removeEventListener('resize', setWidth)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const width = typeof col?.width === 'number' ? `${col?.width}px` : col?.width

	// useEffect(() => {}, [colSetCount])

	return (
		<GTableCellView
			className='g-table-cell'
			style={{
				width,
			}}
		>
			<div className='g-table-ref-cell' ref={cellRef}>
				{children}
			</div>
		</GTableCellView>
	)
}
