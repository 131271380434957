import isFunction from 'lodash/isFunction'
import pick from 'lodash/pick'
import toNumber from 'lodash/toNumber'
import React, { useRef } from 'react'
import * as Yup from 'yup'
import {
	ParcelInput,
	useParcelFullQuery,
	useUpdateParcelMutation,
} from '../../../gql_generated/graphql'
import { sanitizeDataForForm } from '../../../utils'
import { Block, Form, Spinner, SubmitBtn, Tab, Tabs, toastit } from '../../UI'
import ParcelEstateFields from './Ops.Parcel.estateFields'
import ParcelFields from './Ops.Parcel.fields'

const schema = Yup.object({
	apn: Yup.string().required(),
})

type EditParcelProps = {
	id: number
	cb?: (data: any) => void
}

const EditParcel: React.FC<EditParcelProps> = ({ id, cb }) => {
	const [{ data, fetching, error }] = useParcelFullQuery({ variables: { id: toNumber(id) } })
	const [_, updateParcel] = useUpdateParcelMutation()
	const stopRender = useRef(false) // component should no longer render markup

	const { parcel } = data || {}

	return stopRender.current ? null : fetching ? (
		<Spinner />
	) : error ? (
		<Block type='err'>{error.message}</Block>
	) : (
		<Form
			legend='Edit Parcel'
			initialValues={sanitizeDataForForm<ParcelInput>(parcel)}
			onSubmit={async (values: any) => {
				try {
					let results

					const data = pick(values, [
						'country',
						'meridian',
						'state',
						'county',
						'apn',
						'town',
						'range',
						'section',
						'notes',
						'aliquotNotes',
					]) as unknown as ParcelInput

					data.estates = [values.srf, values.ind, values.min, values.oil, values.geo].map(
						estate => ({
							...sanitizeDataForForm(estate, {
								stripKeys: ['totals', 'owner', 'ownerId'],
							}),
						})
					)

					if (id)
						results = await updateParcel({
							id: toNumber(id),
							data,
						})
					stopRender.current = true
					if (results?.error) {
						toastit.err('Error saving parcel')
					} else if (isFunction(cb)) cb(results)
				} catch (err) {
					console.error(err)
				}
			}}
			validationSchema={schema}
		>
			{() => (
				<>
					<Tabs>
						<Tab nav='Parcel Info'>
							<ParcelFields />
						</Tab>
						<Tab nav='Estates'>
							<ParcelEstateFields show isUpdate />
						</Tab>
					</Tabs>
					<SubmitBtn>Save</SubmitBtn>
				</>
			)}
		</Form>
	)
}
export default EditParcel
