import React from 'react'
import styled from 'styled-components'
import { RelatedResourcesCard } from '../../../Components/common/RelatedResource.card'
import { Tab, Tabs } from '../../../Components/UI'
import { RelatedParcelAcquisitions } from './Parcel.related.acquisitions'
import { RelatedParcelAgreements } from './Parcel.related.agreements'
import { RelatedParcelDocs } from './Parcel.related.docs'
import { RelatedParcelEasements } from './Parcel.related.easements'
import { RelatedParcelLeases } from './Parcel.related.leases'
import { RelatedParcelLogs } from './Parcel.related.logs'
import { RelatedParcelOrgs } from './Parcel.related.orgs'
import { RelatedParcelRoyalties } from './Parcel.related.royalties'
import { RelatedParcelSales } from './Parcel.related.sales'
import { RelatedParcelTaxes } from './Parcel.related.taxes'

const Section = styled.section``

type ParcelRelatedResourcesProps = {
	parcelId: number
}
export const ParcelRelatedResources: React.FC<ParcelRelatedResourcesProps> = ({ parcelId }) => {
	return (
		<Section>
			<RelatedResourcesCard>
				<Tabs renderOnSelect>
					<Tab nav='Sales' icon='sale'>
						<RelatedParcelSales parcelId={parcelId} />
					</Tab>
					<Tab nav='Leases' icon='lease'>
						<RelatedParcelLeases parcelId={parcelId} />
					</Tab>
					<Tab nav='Easements' icon='easement' navVerbose='Easements'>
						<RelatedParcelEasements parcelId={parcelId} />
					</Tab>
					<Tab nav='Agreements' icon='agreement' navVerbose='Agreements'>
						<RelatedParcelAgreements parcelId={parcelId} />
					</Tab>
					<Tab nav='Docs' icon='doc'>
						<RelatedParcelDocs parcelId={parcelId} />
					</Tab>
					<Tab nav='Orgs' icon='org'>
						<RelatedParcelOrgs parcelId={parcelId} />
					</Tab>
					<Tab nav='Logs' icon='log'>
						<RelatedParcelLogs parcelId={parcelId} />
					</Tab>
					<Tab nav='Royalties' icon='royalties'>
						<RelatedParcelRoyalties parcelId={parcelId} />
					</Tab>
					<Tab nav='Acquisitions' icon='acquisitions'>
						<RelatedParcelAcquisitions parcelId={parcelId} />
					</Tab>
					<Tab nav='Taxes'>
						<RelatedParcelTaxes parcelId={parcelId} />
					</Tab>
				</Tabs>
			</RelatedResourcesCard>
		</Section>
	)
}
