import React from 'react'
import styled from 'styled-components'
import {
	ParcelFilter,
	ParcelFilterOption,
	ParcelParent,
} from '../../../gql_generated/document_types'
import { usePaginatedParcelsQuery } from '../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../Types'
import { GenParcelModal } from '../../common/Parcel/Parcel.gen.modal'
import { parcelSelectorColsConfig } from '../Table.resources/Table.resource.col.configs/Parcel.col.configs'
import { ResourcesTable } from '../Table.resources/Table.resources'
import { usePaginationParams } from '../Table.resources/usePaginationParams'
import { TableDisableRow } from '../Table/Table.types'
import { ParcelSelectorNoEstateTypes } from './ParcelSelector.NoEstateTypes'

const ParcelSelectorSelectView = styled.div``

export type ParcelSelectorSelectProps = {
	requireEstateTypes?: boolean

	selectedIds: number[]
	setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>

	parentId?: number
	parentType?: ParcelParent
	filterByParent?: boolean

	estateType?: EstateTypeEnum
	estateTypes?: EstateTypeEnum[]

	excludeConveyed?: boolean
	excludeTerminated?: boolean
	excludeAssigned?: boolean

	disableRow?: TableDisableRow
}
const ParcelSelectorSelect: React.FC<ParcelSelectorSelectProps> = ({
	requireEstateTypes,
	selectedIds,
	setSelectedIds,
	parentId,
	parentType,
	filterByParent,
	estateType,
	estateTypes,
	excludeConveyed,
	excludeTerminated,
	excludeAssigned,
	disableRow,
}) => {
	const paginationStore = usePaginationParams()

	const filterOptions: ParcelFilterOption[] = []

	if (excludeConveyed)
		filterOptions.push({
			filter: ParcelFilter.EstatesConveyed,
			vals: estateTypes,
			options: {
				isNot: true,
			},
		})

	if (excludeTerminated)
		filterOptions.push({
			filter: ParcelFilter.TerminatedActivity,
			options: {
				isNot: true,
			},
		})

	if (excludeAssigned)
		filterOptions.push({
			filter: ParcelFilter.AssignedActivity,
			options: {
				isNot: true,
			},
		})

	const [results] = usePaginatedParcelsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions:
					parentType && parentId && filterByParent
						? [
								{
									parentType,
									parentIds: [parentId],
								},
						  ]
						: null,
				filterOptions: filterOptions.length ? filterOptions : null,
			},
		},
	})

	const showOverride = !!requireEstateTypes && !estateTypes?.length

	const dataset = results.data?.page?.parcels

	return (
		<ParcelSelectorSelectView className='select-parcels'>
			<ResourcesTable
				dataset={dataset}
				fetchResults={results}
				colsConfig={parcelSelectorColsConfig({
					parentId,
					parentType,
					estateType,
				})}
				paginationStore={paginationStore}
				includeSearch
				selectRowConfig={{
					selectedRowIds: selectedIds,

					selectRowHandler: parcel =>
						setSelectedIds(state => {
							const newState = [...state]
							const idx = state.findIndex(id => id === parcel.id)
							if (idx < 0) newState.push(parcel.id)
							else newState.splice(idx, 1)

							return newState
						}),

					selectType: 'checkcircle',
				}}
				disableRow={disableRow}
				modalRenderFunc={data => <GenParcelModal parcelId={data.id} />}
				resourceType='parcel'
				contentOverride={showOverride ? <ParcelSelectorNoEstateTypes /> : undefined}
			/>
		</ParcelSelectorSelectView>
	)
}
export default ParcelSelectorSelect
