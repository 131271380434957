import React from 'react'
import styled, { css } from 'styled-components/macro'
import { EM } from '../../Typography'
import { TableGlobalOpsConfig } from '../Table.types'
import { TableHeadOps, TableHeadOpsProps } from './Table.head.Ops'

export const tableHeadCheckboxStyles = css`
	.select-all {
	}
	.select-all-options {
		display: flex;
		align-items: center;
		& > em {
			white-space: nowrap;
			padding-left: 5px;
			color: ${props => props.theme.colors.grey.light(2).val};
			/* border-left: solid 2px ${props => props.theme.colors.grey.light().val}; */
		}
	}
`

const TableGlobalOpsView = styled.div`
	${tableHeadCheckboxStyles}
`

export type TableGlobalOpsProps = Required<TableGlobalOpsConfig> & {}
export const TableGlobalOps: React.FC<TableGlobalOpsProps> = ({
	selectAllHandler,
	isTotalQtySelected,
	selectedRowIds,
	deselectedRowIds,
	totalQty,
	// rowsQty,
	tableOps,
}) => {
	// const [alert] = useAlert()

	const qtySelected = isTotalQtySelected ? totalQty : selectedRowIds.length

	const tableHeadOpsProps: TableHeadOpsProps = {
		ops: tableOps,
		hasSelectedRows: !!qtySelected,
	}

	const isChecked = isTotalQtySelected || !!selectedRowIds?.length
	return (
		<TableGlobalOpsView className='table-select-options'>
			<div className='select-all'>
				<input
					type='checkbox'
					value='all'
					checked={isChecked}
					onChange={() => {
						console.log('clicked')
						if (isChecked) {
							selectAllHandler({
								selectTotalQty: false,
								isSelected: false,
							})
							// TODO: Enable this option again once we can reconcile doing totalQtySelect when dataset is filtered
							// } else if (totalQty && totalQty > rowsQty) {
							// 	alert({
							// 		msg: `Select all ${totalQty} or ${rowsQty} showing?`,
							// 		okBtn: `Select ${totalQty}`,
							// 		cancelBtn: `Select ${rowsQty}`,
							// 		cb: selectTotalQty => {
							// 			selectAllHandler({
							// 				selectTotalQty,
							// 				isSelected: true,
							// 			})
							// 		},
							// 	})
						} else {
							selectAllHandler({
								selectTotalQty: false,
								isSelected: true,
							})
						}
					}}
				/>
			</div>
			{qtySelected ? (
				<div className='select-all-options'>
					<TableHeadOps {...tableHeadOpsProps} />-
					<EM>{`${
						isTotalQtySelected ? totalQty - deselectedRowIds.length : qtySelected
					} Selected`}</EM>
				</div>
			) : null}
		</TableGlobalOpsView>
	)
}
