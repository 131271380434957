import React from 'react'
import { RouteComponentProps, Router } from '@reach/router'

import Protect from '../Components/common/Protect'
import Page404 from '../Pages/404.page'

import OrgsPage from '../Pages/Orgs'
import OrgPage from '../Pages/Org'

const OrgRoutes: React.FC<RouteComponentProps> = () => {
	return (
		<Protect>
			<Router>
				<OrgsPage path='/' />
				<OrgPage path='/:id' />
				<Page404 default />
			</Router>
		</Protect>
	)
}
OrgRoutes.propTypes = {}
export default OrgRoutes
