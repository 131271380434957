import React from 'react'
import styled from 'styled-components'
import { RelatedResourcesCard } from '../../../Components/common/RelatedResource.card'
import { Spinner, Tab, Tabs } from '../../../Components/UI'
import { RelatedDocAcquisitions } from './Doc.related.acquisitions'
import { RelatedDocAgreements } from './Doc.related.agreements'
import { RelatedDocEasements } from './Doc.related.easements'
import { RelatedDocLeases } from './Doc.related.leases'
import { RelatedDocLogs } from './Doc.related.logs'
import { RelatedDocParcels } from './Doc.related.parcels'
import { RelatedDocSales } from './Doc.related.sales'
const Section = styled.section``

type DocRelatedResourcesProps = {
	docId: number
}
const DocRelatedResources: React.FC<DocRelatedResourcesProps> = ({ docId }) => {
	return (
		<Section>
			<RelatedResourcesCard>
				{docId ? (
					<Tabs renderOnSelect>
						<Tab nav='Parcels' icon='parcel'>
							<RelatedDocParcels docId={docId} />
						</Tab>
						<Tab nav='Sales' icon='sale'>
							<RelatedDocSales docId={docId} />
						</Tab>
						<Tab nav='Leases' icon='lease'>
							<RelatedDocLeases docId={docId} />
						</Tab>
						<Tab nav='Agreements' icon='agreement'>
							<RelatedDocAgreements docId={docId} />
						</Tab>
						<Tab nav='Easements' icon='easement'>
							<RelatedDocEasements docId={docId} />
						</Tab>
						<Tab nav='Acquisitions' icon='acquisitions'>
							<RelatedDocAcquisitions docId={docId} />
						</Tab>
						<Tab nav='Logs' icon='logs'>
							<RelatedDocLogs docId={docId} />
						</Tab>
					</Tabs>
				) : (
					<Spinner />
				)}
			</RelatedResourcesCard>
		</Section>
	)
}
export default DocRelatedResources
