import React, { ReactElement, useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import NewWindow from 'react-new-window'
import { useReactToPrint } from 'react-to-print'
import styled from 'styled-components'
import { isDev, useState } from '../../../utils'

type PrintWindowViewProps = {
	margin?: string
	size?: 'A4' | string
	orientation?: 'portrait' | 'landscape'
}
const PrintWindowView = styled.div<PrintWindowViewProps>`
	@page {
		margin: default;
		${props => (props.margin ? `margin: ${props.margin} !important;` : '')}
		size: ${props => `${props.size || 'A4'} ${props.orientation || 'portrait'}`};
	}
`

type PrintWindowProps = PrintWindowViewProps & {
	PDF: ReactElement
	errHandler?: (errLoc: string) => void
	cb?: () => void
	fileName?: string
}
const PrintWindow: React.FC<PrintWindowProps> = ({
	PDF,
	errHandler,
	cb,
	fileName,
	...styleProps
}) => {
	const pdfRef = useRef(null)
	const windowRef = useRef(null)
	const [isRendered, setRendered] = useState(false, 'isRendered')
	const onAfterPrint = useCallback(() => {
		if (cb) cb()
	}, [cb])

	const print = useReactToPrint({
		content: () => pdfRef.current,
		onPrintError: errLoc => {
			if (isDev()) console.warn('print error: ', errLoc)
			if (errHandler) errHandler(errLoc)
		},
		onAfterPrint,
		removeAfterPrint: true,
		suppressErrors: !isDev(),
		documentTitle: fileName || 'NN.download.pdf',
	})

	useEffect(() => {
		if (isRendered && pdfRef.current && print) print()
	}, [print, isRendered])

	useLayoutEffect(() => {
		setRendered(true)
	}, [])

	return (
		// @ts-ignore  TODO: remove this once we upgrade all packages
		<NewWindow ref={windowRef}>
			<div ref={pdfRef}>
				<PrintWindowView {...styleProps}>{PDF}</PrintWindowView>
			</div>
		</NewWindow>
	)
}
export default PrintWindow
