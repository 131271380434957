import { ColConfig } from './Col.config.types'

export const docDateEffectiveColConfig: ColConfig = {
	label: 'Effective',
	field: 'dateEffective',
	format: {
		format: 'date',
		date: 'MM/dd/yyyy',
	},
	sortField: 'dateEffective',
}

export const docDateRecordedColConfig: ColConfig = {
	label: 'Date Recorded',
	field: 'dateRecorded',
	format: {
		format: 'date',
		date: 'MM/dd/yyyy',
	},
	sortField: 'dateRecorded',
}

export const docIsRecDocColConfig: ColConfig = {
	field: 'isRecDoc',
	label: 'Rec Doc?',
	format: 'boolean',
}

export const docRecIdColConfig: ColConfig = {
	field: 'recDocId',
	label: 'Rec Doc Num',
	sortField: 'recDocId',
}

export const docTypeColConfig: ColConfig = {
	field: 'docType',
	label: 'Doc Type',
	format: 'proper',
	hideMobile: true,
}

export const docStateColConfig: ColConfig = {
	field: 'state',
	format: 'upper',
	hideMobile: true,
}

export const docCountyColConfig: ColConfig = {
	field: 'county',
	format: 'proper',
	hideMobile: true,
}

export const docBookColConfig: ColConfig = {
	field: 'book',
	format: 'proper',
	hideMobile: true,
}

export const docPageColConfig: ColConfig = {
	field: 'page',
	format: 'proper',
	hideMobile: true,
}

export const docsPageRecordedDocsColsConfig: ColConfig[] = [
	docDateEffectiveColConfig,
	docDateRecordedColConfig,
	docRecIdColConfig,
	docTypeColConfig,
	docCountyColConfig,
]

export const docsPageAllDocsColsConfig: ColConfig[] = [
	docIsRecDocColConfig,
	docDateEffectiveColConfig,
	docTypeColConfig,
	docRecIdColConfig,
	docCountyColConfig,
]

export const relatedDocsColsConfig: ColConfig[] = [
	docDateRecordedColConfig,
	docDateEffectiveColConfig,
	docRecIdColConfig,
	docTypeColConfig,
	docCountyColConfig,
]

export const docSelectorColsConfig: ColConfig[] = [
	docDateEffectiveColConfig,
	docTypeColConfig,
	docRecIdColConfig,
	docCountyColConfig,
]
