import styled from 'styled-components'
import ExhibitForm from '../../Components/common/Exhibit/Exhibit.form'
import { EstateTypeEnum } from '../../Types'

export const ReportsExhibitPageModalView = styled.div``

export type ReportsExhibitPageModalProps = {
	parcelIds: number[]
	estateType: EstateTypeEnum
	cb: () => void
}
export const ReportsExhibitPageModal: React.FC<ReportsExhibitPageModalProps> = ({
	parcelIds,
	estateType,
	cb,
}) => {
	return (
		<ReportsExhibitPageModalView>
			<ExhibitForm parcelIds={parcelIds} estateType={estateType} cb={cb} />
		</ReportsExhibitPageModalView>
	)
}
