import React from 'react'
import { useContactForInfoQuery } from '../../../gql_generated/graphql'
import { KeyVals } from '../../UI'

type ContactInfoProps = {
	contactId: number
}
const ContactInfo: React.FC<ContactInfoProps> = ({ contactId }) => {
	const [results] = useContactForInfoQuery({ variables: { id: contactId } })
	const contact = results.data?.contact

	const { fullName, email, title, phoneWork, phoneExt, phoneMobile } = contact || {}
	return (
		<KeyVals
			items={[
				['Name', fullName],
				title ? ['Title', title] : null,
				['Email', email, { format: 'email', withCopyPaste: true }],
				phoneMobile
					? ['Phone (mobile)', phoneMobile, { format: 'phone', withCopyPaste: true }]
					: null,
				phoneWork ? ['Phone (work)', phoneWork, { format: 'phone', withCopyPaste: true }] : null,
				phoneExt ? ['Work ext.', phoneExt] : null,
			]}
		/>
	)
}
export default ContactInfo
