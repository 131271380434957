import { Auth } from 'aws-amplify'
import fetch, { AxiosRequestConfig } from 'axios'
import each from 'lodash/each'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'

const MAIN_API_URI = process.env.REACT_APP_API_URL
const PRINT_TRACE = false
const ALLOW_MOCKS = true
const DISABLE_API = false

type AxiosMethod =
	| 'get'
	| 'GET'
	| 'delete'
	| 'DELETE'
	| 'head'
	| 'HEAD'
	| 'options'
	| 'OPTIONS'
	| 'post'
	| 'POST'
	| 'put'
	| 'PUT'
	| 'patch'
	| 'PATCH'
	| 'purge'
	| 'PURGE'
	| 'link'
	| 'LINK'
	| 'unlink'
	| 'UNLINK'
	| undefined

export type APIOptions = {
	params?: {
		[x: string]: string | number
	}
	body?: any
	apiUri?: string
	transData?: any
	noCreds?: boolean
	noPrint?: boolean
	mock?: any
	file?: any
	onProgress?: any
}

console.log('INITIALIZING API AT: ', MAIN_API_URI)
export const api = async <T>(
	method: AxiosMethod,
	path: string,
	options?: APIOptions
): Promise<T> => {
	let res = {}
	const { params, body, apiUri, transData, noCreds, noPrint, mock, file, onProgress } =
		options || {}
	const apiPath = apiUri || MAIN_API_URI

	let data = body

	// if (!noPrint)
	// 	console[PRINT_TRACE ? 'error' : 'log'](
	// 		`%cAPI ${method} to [ ${path} ] beginning with options: `,
	// 		`color:#FFF; background-color:#758B9A; padding: 4px;`,
	// 		options
	// 	)

	try {
		let token
		if (!noCreds) {
			const session = await Auth.currentSession()
			token = session.getIdToken().getJwtToken()
		}

		if (file) {
			data = new FormData()

			data.append('file', file)
		}

		if (params)
			each(params, (val, param) => {
				if (isArray(val))
					params[param] = val.reduce((str, a) => (isEmpty(str) ? a : `${str},${a}`), '')
			})

		const url = `${apiPath}${path}`

		const req: AxiosRequestConfig = {
			method,
			url,
			// withCredentials: !noCreds,
			// credentials: !noCreds && 'include',
			params,
			data,
			headers: {
				'Authorization': !noCreds && `Bearer ${token}`,
				// 'accesstoken': !noCreds && token,
				'Accept': 'application/json',
				'Content-Type': file ? '' : 'application/json',
			},
		}

		if (onProgress) req.onUploadProgress = onProgress

		if (!noPrint)
			console[PRINT_TRACE ? 'error' : 'log'](
				`%cAPI ${method} to [ ${path} ] req: `,
				`color:#FFF; background-color:#758B9A; padding: 4px;`,
				req
			)

		if (DISABLE_API) return {} as T

		res =
			mock && !isFunction(mock) && ALLOW_MOCKS
				? {
						data: {
							data: mock,
						},
				  }
				: await fetch(req)

		// if (!noPrint) console.log('API', method, ' | ', url, ' |  res:', res)
		res = get(res, 'data.data', res)

		if (isFunction(transData)) res = transData(res)

		if (isFunction(mock) && ALLOW_MOCKS) res = mock(res)

		if (!noPrint)
			console[PRINT_TRACE ? 'error' : 'log'](
				`%cAPI ${method} to [ ${path} ] returned with results: `,
				`color:#FFF; background-color:#89a989; padding: 4px;`,
				res
			)

		return res as T
	} catch (err) {
		console.dir(err)

		if (!noPrint)
			if (get(err, 'response.data.error'))
				console.error(
					`%cAPI ${method} to [ ${path} ] error response: `,
					`color:#FFF; background-color:#dc3300; padding: 4px;`,
					get(err, 'response.data.error')
				)
			else
				console.error(
					'API error (without message):',
					new Error(get(err, 'response.data.error.msg', 'no error message returned from api'))
				)

		throw err
	}
}

/**
 *  CURRENT USER
 * */

export const me = {
	get: <T>(options?: APIOptions) => api<T>('get', '/me', options),
	update: <T>(body: any, options = {}) =>
		api<T>('put', '/me', {
			body,
			...options,
		}),
	forgotPass: <T>(email: string, options = {}) =>
		api<T>('put', '/me/forgot-pass', {
			body: {
				email,
			},
			noCreds: true,
			...options,
		}),
}
