import React from 'react'
import styled, { css } from 'styled-components/macro'
import Protect from '../../../../Components/common/Protect'
import { AddParcelsToResourceBtn } from '../../../../Components/Ops/Ops.Parcels.addToResource'
import { LeftBar } from '../../../../Components/UI'
import { RemoveRelatedParcelsOp } from '../../../../Components/UI/Table.resources/Table.global.ops/RemoveParcelsFromRelated.op'
import { TerminateRelatedParcelsOp } from '../../../../Components/UI/Table.resources/Table.global.ops/TerminateRelatedParcels.op'
import { UnassignRelatedParcelsOp } from '../../../../Components/UI/Table.resources/Table.global.ops/UnassignRelatedParcels.op'
import { leaseAgreementRelatedParcelColsConfig } from '../../../../Components/UI/Table.resources/Table.resource.col.configs/Parcel.col.configs'
import {
	ResourcesTable,
	ResourceTableGlobalOpConfig,
} from '../../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../../Components/UI/Table.resources/usePaginationParams'
import { ParcelFilter, ParcelParent } from '../../../../gql_generated/document_types'
import { useLeaseFullQuery, usePaginatedParcelsQuery } from '../../../../gql_generated/graphql'
import { EstateTypeEnum, PrimaryResourceType } from '../../../../Types'
import { nnrMgrs } from '../../../../utils'
import { RelatedLeaseParcelModal } from '../../Related.Modals/Lease.related.parcel.modal'

export const relatedLeaseAssignedParcelStyles = css``

const RelatedLeaseAssignedParcelsView = styled.div`
	${relatedLeaseAssignedParcelStyles}
`

export type RelatedLeaseAssignedParcelsProps = {
	leaseId: number
}
export const RelatedLeaseAssignedParcels: React.FC<RelatedLeaseAssignedParcelsProps> = ({
	leaseId,
}) => {
	const paginationStore = usePaginationParams()

	const [leaseResults] = useLeaseFullQuery({ variables: { id: leaseId } })
	const estateType = leaseResults.data?.lease?.estateType as EstateTypeEnum

	const [results] = usePaginatedParcelsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: ParcelParent.Lease,
						parentIds: [leaseId],
					},
				],
				filterOptions: [
					{
						filter: ParcelFilter.AssignedActivity,
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.parcels

	const parentProps = {
		parentId: leaseId,
		parentType: 'lease' as PrimaryResourceType,
	}
	const tableOps: ResourceTableGlobalOpConfig[] = [
		{
			txt: 'Unassign',
			op: props => <UnassignRelatedParcelsOp {...props} {...parentProps} />,
		},
		{
			txt: 'Terminate',
			op: props => <TerminateRelatedParcelsOp {...props} {...parentProps} />,
		},
		{
			txt: 'Remove',
			op: props => <RemoveRelatedParcelsOp {...props} {...parentProps} />,
		},
	]
	return (
		<RelatedLeaseAssignedParcelsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={leaseAgreementRelatedParcelColsConfig(leaseId, 'lease', estateType)}
				fetchResults={results}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedLeaseParcelModal parcelId={data.id} />}
				resourceType='parcel'
				tableOps={tableOps}
				includeSearch
				limitSelectConfig={{
					maxAllowed: false,
				}}
			>
				<Protect allowed={nnrMgrs}>
					{estateType ? (
						<LeftBar>
							<AddParcelsToResourceBtn
								config={{
									parentId: leaseId,
									parentType: 'lease',
									estateType,
									useSingleEstateType: true,
								}}
							/>
						</LeftBar>
					) : null}
				</Protect>
			</ResourcesTable>
		</RelatedLeaseAssignedParcelsView>
	)
}
