import { Redirect, RouteComponentProps, useLocation } from '@reach/router'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useCreatePass } from '../../../auth'
import { Board, Form, P, PasswordField, SubmitBtn, UL } from '../../../Components/UI'
import { useStoreState } from '../../../store'
import { parseQueryParams, useState } from '../../../utils'
import { loginStyles } from '../Login/Login'

const CreatePassView = styled.div`
	${loginStyles}
`

const schema = Yup.object().shape({
	password: Yup.string()
		.min(5, 'Must have at least 8 characters')
		.required('Please a new password for your account.')
		.matches(/(?=.*[a-z])/, 'Must contain at least one lower case character')
		.matches(/(?=.*[A-Z])/, 'Must contain at least one upper case character')
		.matches(/(?=.*[0-9])/, 'Must contain at least one number')
		.matches(/(?=.*[!@#$%^()&*])/, 'Must contain at least one special character'),
})

type CreatePassFormVals = {
	password: string
}

const CreatePass: React.FC<RouteComponentProps> = () => {
	const { search } = useLocation()
	const { verification, email } = parseQueryParams(search)
	const [redirect, setRedirect] = useState(false, 'redirect')
	const isAuthed = useStoreState(state => state.user.isAuthed)

	const createPW = useCreatePass()

	const submitHandler = async ({ password }: CreatePassFormVals) => {
		try {
			await createPW(password, {
				email: decodeURIComponent(email),
				verification: decodeURIComponent(verification),
			})

			setRedirect(true)
		} catch (err) {
			return Promise.reject(err)
		}
	}

	const shouldRedirect = isAuthed || !verification || !email || redirect

	return shouldRedirect ? (
		<Redirect to='/login' noThrow />
	) : (
		<CreatePassView>
			<Board titleText='Create password'>
				<Form
					initialValues={{
						password: '',
					}}
					onSubmit={async vals => {
						try {
							await submitHandler(vals as CreatePassFormVals)
						} catch (err) {}
					}}
					validationSchema={schema}
				>
					{() => (
						<>
							<P>Passwords must have:</P>
							<UL>
								<li>At least 8 characters total</li>
								<li>At least one lower case character</li>
								<li>At least one upper case character</li>
								<li>At least one number</li>
								<li>At least one special character (e.g., {`%,$,!`})</li>
							</UL>
							<PasswordField name='password' placeholder='Create your password' />
							<SubmitBtn isInverted>Create Password</SubmitBtn>
						</>
					)}
				</Form>
			</Board>
		</CreatePassView>
	)
}
export default CreatePass
