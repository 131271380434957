import React from 'react'
import styled, { css } from 'styled-components/macro'
import { EstateActivity } from '../../../gql_generated/document_types'
import {
	EstateActivityUpdateInput,
	useEditEstateActivityMutation,
	useParcelForInfoQuery,
} from '../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../Types'
import { parseResourceType, sanitizeDataForForm } from '../../../utils'
import { capitalize, reduce, trim, unset } from '../../../utils/lodash.utils'
import {
	Async,
	Block,
	Card,
	EditField,
	EM,
	ErrorBlock,
	Form,
	Icon,
	KeyVals,
	SelectOrgField,
	SubmitBtn,
	Textarea,
	toastit,
	ToggleField,
} from '../../UI'
import { IconType } from '../../UI/Icon/fa.defaults'
import CardsSection from '../CardsSection'
import { EditEstateActivityProps } from './Activity.edit'
import { CustomEstateActivityAcresAliquotFields } from './Activity.edit.customAcresAliquot'

export const editEstateActivityFormStyles = css``

const EditEstateActivityFormView = styled.div`
	${editEstateActivityFormStyles}
`

export type EditEstateActivityFormProps = Pick<
	EditEstateActivityProps,
	'cb' | 'parcelId' | 'parentType'
> & {
	initialData: EstateActivity
}
export const EditEstateActivityForm: React.FC<EditEstateActivityFormProps> = ({
	parentType,
	parcelId,
	initialData,
	cb,
}) => {
	const [parcelResults] = useParcelForInfoQuery({ variables: { id: parcelId } })
	const { county, apn, trs } = parcelResults.data?.parcel || {}
	const { estate, estateType, id, activityType, assignee } = initialData

	const [mutResults, updateEstateActivity] = useEditEstateActivityMutation()

	const { titleFull, single } = parseResourceType(estateType)

	const submitHandler = async (vals: EstateActivityUpdateInput) => {
		try {
			const data = reduce(
				vals,
				(obj, val, key) => {
					if (typeof val !== 'boolean' && !val) return obj
					obj[key] = val
					return obj
				},
				{} as any
			)

			if (!data.acres || !data.aliquot) {
				data.acres = null
				data.aliquot = null
			}

			if (!data.useCustom) {
				data.acres = null
				data.aliquot = null
			}

			if (!data.assigneeId) data.assigneeId = null

			if (data.hasEasement) {
				data.reservedEasement = trim(data.reservedEasement || '')
				if (!data.reservedEasement) data.reservedEasement = null
			} else if (parentType === 'sale') data.reservedEasement = null

			unset(data, 'useCustom')
			unset(data, 'hasEasement')

			const res = await updateEstateActivity({
				data,
			})

			if (res.error) toastit.err('Error updating connection')
			else if (cb) cb()
		} catch (err) {
			toastit.err('Error updating connection')
		}
	}

	const initialVals: any = sanitizeDataForForm(initialData, {
		stripKeys: ['estate', 'assignee', 'isTerminated'],
	})

	initialVals.useCustom = initialVals.aliquot || initialVals.acres ? true : false

	initialVals.aliquot = initialVals.aliquot || estate.aliquot
	initialVals.acres = initialVals.acres || estate.acres

	if (parentType === 'sale') {
		initialVals.hasEasement = !!initialVals.reservedEasement
		initialVals.reservedEasement = initialVals.reservedEasement || ''
	}

	return (
		<EditEstateActivityFormView>
			<Async fetchResults={parcelResults}>
				{mutResults.error ? <ErrorBlock error={mutResults.error} /> : null}
				<Form
					legend={`${capitalize(county)} ${apn} ${trs}`}
					initialValues={initialVals}
					onSubmit={submitHandler}
				>
					{({ values }) => (
						<CardsSection>
							<Card noShadow>
								<div className='estate'>
									<Icon type={estateType as IconType} padRight />
									<span>{titleFull} Estate</span>
								</div>
								<KeyVals
									items={[
										['Acres', estate.acres, 'acres'],
										['Aliquot', estate.aliquot],
									]}
								/>
								<Block>
									<EM>
										NOTE: You are editing the connection between this {activityType} and the{' '}
										{single} estate on parcel:
										<br />
										{capitalize(county)} {apn} {trs}
									</EM>
								</Block>
								<KeyVals items={[['ID', id]]} />
							</Card>
							{parentType === 'lease' || parentType === 'agreement' ? (
								<Card noShadow titleText='Assignment'>
									{assignee ? (
										<EditField label={`Assigned to: ${assignee?.name}`}>
											<SelectOrgField
												name='assigneeId'
												label={`Reassign to:`}
												allowDba={false}
												allowRemove
											/>
										</EditField>
									) : (
										<SelectOrgField
											name='assigneeId'
											label={`Assign ${activityType} on this estate to`}
											allowDba={false}
											allowRemove
										/>
									)}
								</Card>
							) : null}
							<Card noShadow titleText='Custom Aliquot/Acres'>
								<CustomEstateActivityAcresAliquotFields
									estateAcres={estate.acres}
									estateAliquot={estate.aliquot}
									useCustom={values.useCustom}
									estateType={single as EstateTypeEnum}
								/>
							</Card>
							{parentType === 'sale' ? (
								<Card noShadow titleText='Reserve Easement'>
									<ToggleField name='hasEasement' label='Add Reserved Easement?' />
									{values.hasEasement ? (
										<Textarea name='reservedEasement' label='Easement description' isReq />
									) : null}
								</Card>
							) : null}

							<SubmitBtn />
						</CardsSection>
					)}
				</Form>
			</Async>
		</EditEstateActivityFormView>
	)
}
