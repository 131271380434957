import React from 'react'
import styled, { css } from 'styled-components/macro'
import { SaleDetail } from './Sale.detail'

export const genSaleModalStyles = css``

const GenSaleModalView = styled.div`
	${genSaleModalStyles}
`

export type GenSaleModalProps = {
	saleId: number
}
export const GenSaleModal: React.FC<GenSaleModalProps> = ({ saleId }) => (
	<GenSaleModalView>
		<SaleDetail saleId={saleId} />
	</GenSaleModalView>
)
