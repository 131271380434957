import React from 'react'
import { useAcquisitionForInfoQuery } from '../../../gql_generated/graphql'
import { KeyVals } from '../../UI'

type AcquisitionInfoProps = {
	acquisitionId: number
}
const AcquisitionInfo: React.FC<AcquisitionInfoProps> = ({ acquisitionId }) => {
	const [results] = useAcquisitionForInfoQuery({ variables: { id: acquisitionId } })
	const acquisition = results.data?.acquisition

	const {
		id,
		escrowId,
		grantor,
		grantorDba,
		broker,
		brokerDba,
		titleCompany,
		titleCompanyDba,
		status,
	} = acquisition || {}

	return (
		<KeyVals
			items={[
				['DB ID', id],
				['Escrow ID', escrowId],
				['Grantor', grantorDba || grantor, 'org'],
				['Broker', brokerDba || broker, 'org'],
				['Title Company', titleCompanyDba || titleCompany, 'org'],
				['Status', status],
			]}
		/>
	)
}
export default AcquisitionInfo
