import React from 'react'
import styled from 'styled-components'
import { Icon } from '.'
import { IconType } from './Icon/fa.defaults'

const IconWithTextView = styled.div`
	display: flex;
	align-items: center;
	.txt {
		padding-left: 0.5em;
	}
`

type IconWithTextProps = {
	icon?: IconType | null
}
const IconWithText: React.FC<IconWithTextProps> = ({ icon, children }) => {
	return (
		<IconWithTextView>
			{icon ? <Icon type={icon} /> : null}
			<span className='txt'>{children}</span>
		</IconWithTextView>
	)
}
export default IconWithText
