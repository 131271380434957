import { useCallback, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { UseMutationState } from 'urql'
import { CsvPayload } from '../../../gql_generated/document_types'
import { formatDate, useState } from '../../../utils'
import ErrorBlock from '../Error.block'
import { Heading } from '../Heading'
import { ProgressBar } from '../Loaders'
import { Btn } from '../Typography'

export const csvDownloadStyles = css``

const CsvDownloadView = styled.div`
	${csvDownloadStyles}
`

type CsvDownloadOptions = {
	filename?: string
}

export type CsvDownloadProps = {
	csvData?: CsvPayload
	fetchState: Pick<UseMutationState<any>, 'fetching' | 'error'>
	fetchData: () => void
	onAfterDownload?: () => void
	options?: CsvDownloadOptions
}
export const CsvDownload = ({
	csvData,
	fetchState,
	fetchData,
	onAfterDownload,
	options,
}: CsvDownloadProps): JSX.Element => {
	const { filename = 'nnr-app-data' } = options || {}
	const { fetching, error } = fetchState
	const [canDownload, setCanDownload] = useState(false, 'canDownload')
	const [isDone, setDone] = useState(false, 'isDone')

	const downloadFile = useCallback(() => {
		if (csvData) {
			const blob = new Blob([csvData.headerStr, csvData.dataStr], {
				type: 'text/csv;charset=utf-8;',
			})
			const url = URL.createObjectURL(blob)

			const a = document.createElement('a')
			a.href = url
			a.download = `nnr-${filename}-${formatDate(new Date(), 'yyyy-MM-dd.H-mm-ss')}.csv`
			a.click()

			URL.revokeObjectURL(url)

			setDone(true)
			setCanDownload(false)

			// Give the progress bar time to finish animating
			setTimeout(() => {
				onAfterDownload && onAfterDownload()
			}, 600)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [csvData, onAfterDownload])

	useEffect(() => {
		if (csvData && canDownload) {
			downloadFile()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [csvData, canDownload])

	return (
		<CsvDownloadView>
			<ProgressBar startBar={fetching} isDone={isDone || !!error} />
			{error ? <ErrorBlock error={error} /> : null}
			{fetching || isDone ? (
				<div className='fetching'>
					<Heading mTop>Generating CSV file...</Heading>
				</div>
			) : (
				<div className='inputs'>
					<Heading mBot>Generate CSV?</Heading>
					<Btn
						isCta
						onClick={() => {
							setCanDownload(true)
							setDone(false)
							fetchData()
						}}
					>
						Generate
					</Btn>
				</div>
			)}
		</CsvDownloadView>
	)
}
