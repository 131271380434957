import styled, { css } from 'styled-components'
import { EditEstateOp } from '../../Ops/Ops.Estates/Ops.Estate.edit'
import { Btn, Icon, Modal, useModalCtrl } from '../../UI'

export const editEstateBtnStyles = css`
	&.__size-sm {
	}

	&.__size-lg {
		.icon {
			height: 1.5em;
		}
	}
`

const EditEstateBtnView = styled.div`
	${editEstateBtnStyles}
`

export type EditEstateBtnProps = {
	estateId: number
	className?: string
	size?: 'sm' | 'lg'
}
export const EditEstateBtn = ({
	estateId,
	className,
	size = 'sm',
}: EditEstateBtnProps): JSX.Element => {
	const [[show, open, close]] = useModalCtrl()
	return (
		<EditEstateBtnView
			className={`edit-estate-btn${className ? ` ${className}` : ''} __size-${size}`}
		>
			<Btn onClick={() => open()}>
				<Icon type='edit' />
			</Btn>
			<Modal show={show} closeHandler={close}>
				<EditEstateOp estateId={estateId} cb={close} />
			</Modal>
		</EditEstateBtnView>
	)
}
