import React from 'react'
import styled from 'styled-components'

const Footer = styled.footer`
	.copyright {
		padding: 1em;
		font-size: 0.8em;
		text-align: center;
		color: ${props => props.theme.colors.white.val};
		box-shadow: inset 0 5px 10px ${props => props.theme.colors.black.tint().val};
		background: ${props => props.theme.colors.grey.val};
	}
`

const MainFooter: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = props => {
	return (
		<Footer {...props}>
			<div className='copyright'>
				copyright © {new Date().getFullYear()} New Nevada Resources LLC
			</div>
		</Footer>
	)
}
export default MainFooter
