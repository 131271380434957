import React from 'react'
import styled, { css } from 'styled-components/macro'
import { SaleDetail } from '../../../Components/common/Sale/Sale.detail'

export const relatedOrgSaleModalStyles = css``

const RelatedOrgSaleModalView = styled.div`
	${relatedOrgSaleModalStyles}
`

export type RelatedOrgSaleModalProps = {
	saleId: number
}
export const RelatedOrgSaleModal: React.FC<RelatedOrgSaleModalProps> = ({ saleId }) => (
	<RelatedOrgSaleModalView>
		<SaleDetail saleId={saleId} />
	</RelatedOrgSaleModalView>
)
