import React from 'react'
import styled, { css } from 'styled-components/macro'
import { relatedDocsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Doc.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { DocParent } from '../../../gql_generated/document_types'
import { usePaginatedDocsQuery } from '../../../gql_generated/graphql'
import { RelatedAgreementDocModal } from '../Related.Modals/Agreement.related.doc.modal'

export const relatedAgreementDocStyles = css``

const RelatedAgreementDocsView = styled.div`
	${relatedAgreementDocStyles}
`

export type RelatedAgreementDocsProps = {
	agreementId: number
}
export const RelatedAgreementDocs: React.FC<RelatedAgreementDocsProps> = ({ agreementId }) => {
	const paginationStore = usePaginationParams()

	const [results] = usePaginatedDocsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: DocParent.Agreement,
						parentIds: [agreementId],
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.docs

	return (
		<RelatedAgreementDocsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedDocsColsConfig}
				fetchResults={results}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedAgreementDocModal docId={data.id} />}
				resourceType='doc'
			/>
		</RelatedAgreementDocsView>
	)
}
