import get from 'lodash/get'
import styled from 'styled-components'

const Main = styled.main`
	min-height: 90vh;
	flex-grow: 1;
	z-index: 1;
	background: ${props => props.theme.colors.grey.light(42).val};
	background: ${props => props.theme.colors.bg.val};

	.content-wrap {
		${props => props.theme.media.sdesk} {
			padding-left: ${props => get(props.theme.sizes, 'alertbar.val')};
		}
	}
	.cards {
		${props => props.theme.media.sdesk} {
			&:hover {
				.resource-ctrl-bar {
					.btn {
						opacity: 1;
					}
				}
			}
		}
	}
`
export default Main
