import React from 'react'
import styled from 'styled-components'
import { Icon } from '../Icon'
import { Btn } from '../Typography'

const PaginationView = styled.div`
	align-items: center;
	.ctrls {
		display: flex;
		width: auto;
		margin-left: auto;
	}
	.btn {
		color: ${props => props.theme.colors.btn.val};
		display: flex;
		align-items: center;
		justify-content: center;
		&:disabled {
			color: ${props => props.theme.colors.grey.light().val};
		}

		&.page-status {
			padding: 0 0.5em;
			color: ${props => props.theme.colors.grey.val};
			span {
				display: block;
				margin: 0 0.3em;
			}
			.current-page {
				font-weight: 700;
			}
		}

		.icon {
			margin: 0;
		}
	}
`
export type PaginationHandler = (offset: number) => void

export type PaginationProps = {
	totalCount?: number
	limit: number
	offset: number
	handler: PaginationHandler
}
const Pagination: React.FC<PaginationProps> = ({ totalCount, limit, offset, handler }) => {
	if (!totalCount) return null

	const currentPage = offset / limit + 1

	const prevPage = offset - limit
	const nextPage = offset + limit

	const totalPages = Math.ceil(totalCount / limit)

	return (
		<PaginationView className='pagination'>
			<div className='ctrls'>
				<Btn disabled={prevPage < limit} onClick={() => handler(0)}>
					<Icon type='fastBack' />
				</Btn>

				<Btn disabled={prevPage < 0} onClick={() => handler(prevPage)}>
					<Icon type='caretLeftEmpty' />
				</Btn>
				<Btn className='page-status' disabled>
					<span className='current-page'>{currentPage}</span>
					of
					<span className='all-pages'>{totalPages}</span>
					pages
				</Btn>
				<Btn disabled={nextPage >= totalCount} onClick={() => handler(nextPage)}>
					<Icon type='caretRightEmpty' />
				</Btn>
			</div>
		</PaginationView>
	)
}
export default Pagination
