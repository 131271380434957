import React from 'react'
import styled, { css } from 'styled-components/macro'

export const tableHeadStyles = css`
	.sort-column {
		display: flex;
		.sort-options {
		}
	}
`

const TableHeadView = styled.thead`
	${tableHeadStyles}
`

type TableHeadProps = {}
export const TableHead: React.FC<TableHeadProps> = ({ children }) => {
	return <TableHeadView>{children}</TableHeadView>
}
