import { Field } from 'formik'
import React from 'react'
import styled from 'styled-components'
import FormFieldWrap, { FormFieldWrapProps } from './Form.field.wrap'

const T = styled.textarea``

type TextareaProps = React.InputHTMLAttributes<HTMLTextAreaElement> &
	FormFieldWrapProps & {
		cols?: number
		rows?: number
	}
const Textarea: React.FC<TextareaProps> = ({
	name,
	label,
	isReq,
	cols,
	rows,
	helperTxt,
	...props
}) => {
	return (
		<FormFieldWrap name={name} label={label} type='textarea' isReq={isReq} helperTxt={helperTxt}>
			<Field as={T} name={name} cols={cols || 20} rows={rows || 3} {...props} />
		</FormFieldWrap>
	)
}
export default Textarea
