import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import { TableCellStyleProps } from '../Table.types'
import { TableColSort, TableColSortProps } from './Table.head.ColSort'

export const tableHeadCellStyles = css<TableCellStyleProps>`
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	color: ${props => props.theme.colors.grey.val};
	font-size: 0.9rem;
	border-bottom: solid 1px ${props => props.theme.colors.lightGrey.val};
	display: ${props => (props.hideMobile ? 'none' : 'table-cell')};

	${props => props.theme.media.tablet} {
		display: ${props => (props.hideTablet ? 'none' : 'table-cell')};
	}
	${props => props.theme.media.sdesk} {
		display: table-cell;
	}
`

const TableHeadCellView = styled.th`
	${tableHeadCellStyles}
`

export type TableHeadCellProps = TableCellStyleProps & {
	className?: string
	children: ReactNode
	sortProps?: TableColSortProps
}
export const TableHeadCell: React.FC<TableHeadCellProps> = ({
	children,
	className,
	sortProps,
	...styleProps
}) => {
	return (
		<TableHeadCellView
			className={`table-header-col${className ? ` ${className}` : ''}`}
			{...styleProps}
		>
			{sortProps ? <TableColSort {...sortProps}>{children}</TableColSort> : children}
		</TableHeadCellView>
	)
}
