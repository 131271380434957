import React from 'react'
import styled, { css } from 'styled-components/macro'
import { DocDetail } from '../../../Components/common/Doc/Doc.detail'

export const relatedAgreementDocModalStyles = css``

const RelatedAgreementDocModalView = styled.div`
	${relatedAgreementDocModalStyles}
`

export type RelatedAgreementDocModalProps = {
	docId: number
}
export const RelatedAgreementDocModal: React.FC<RelatedAgreementDocModalProps> = ({ docId }) => {
	return (
		<RelatedAgreementDocModalView>
			<DocDetail docId={docId} />
		</RelatedAgreementDocModalView>
	)
}
