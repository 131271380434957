import React from 'react'
import styled, { css } from 'styled-components/macro'
import { ParcelDetail } from '../../../Components/common/Parcel/Parcel.detail'

export const relatedAgreementParcelModalStyles = css``

const RelatedAgreementParcelModalView = styled.div`
	${relatedAgreementParcelModalStyles}
`

export type RelatedAgreementParcelModalProps = {
	parcelId: number
}
export const RelatedAgreementParcelModal: React.FC<RelatedAgreementParcelModalProps> = ({
	parcelId,
}) => {
	return (
		<RelatedAgreementParcelModalView>
			<ParcelDetail parcelId={parcelId} />
		</RelatedAgreementParcelModalView>
	)
}
