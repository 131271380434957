import { useFormikContext } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { Block, Btn, P, UL } from '../../../UI'

const AddEditRoyaltyOnResourceActivitySelectView = styled.div`
	.ctrl-bar {
		padding-top: 1em 0;
		button {
			margin-left: auto;
		}
	}
`

type AddEditRoyaltyOnResourceActivitySelectProps = {
	parentType: string
	ids: number[]
	nextStep?: () => void
}

const AddEditRoyaltyOnResourceActivitySelect: React.FC<
	AddEditRoyaltyOnResourceActivitySelectProps
> = ({ parentType, nextStep, ids, children }) => {
	const { values } = useFormikContext<{ estateType: string }>()
	return (
		<AddEditRoyaltyOnResourceActivitySelectView>
			<Block>
				<P>
					You can add parcels below based on the '{values.estateType}' estate from individual
					parcels on this {parentType}.
				</P>
				<P>If you are unable to find the parcels you're looking for:</P>
				<UL>
					<li>You may have the wrong estate type selected in the "Info" tab</li>
					<li>There may not yet be any parcels added to this {parentType}.</li>
				</UL>
			</Block>
			<div className='ctrl-bar flex'>
				<Btn className='cta' disabled={ids.length === 0} onClick={() => nextStep && nextStep()}>
					Continue
				</Btn>
			</div>

			{children}
		</AddEditRoyaltyOnResourceActivitySelectView>
	)
}
export default AddEditRoyaltyOnResourceActivitySelect
