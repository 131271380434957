import React from 'react'
import Page from '../../Components/common/Resources/Resource.page'
import CardsSection from '../../Components/common/CardsSection'

import { Card, Tab, Tabs } from '../../Components/UI'

import { usePageData } from '../../utils'
import { RouteComponentProps } from '@reach/router'
import RoyaltiesPageSales from './Royalties.page.sales'
import RoyaltiesPageLeases from './Royalties.page.leases'

const RoyaltiesPage: React.FC<RouteComponentProps> = () => {
	usePageData({ pageType: 'royalties', pageTitle: 'Royalties' })

	return (
		<Page isLoaded>
			<CardsSection>
				<Card>
					<Tabs renderOnSelect>
						<Tab nav='Sales' icon='sales'>
							<RoyaltiesPageSales />
						</Tab>
						<Tab nav='Leases' icon='leases'>
							<RoyaltiesPageLeases />
						</Tab>
					</Tabs>
				</Card>
			</CardsSection>
		</Page>
	)
}
export default RoyaltiesPage
