import { ResponsiveBar } from '@nivo/bar'
import { sum } from 'lodash'
import styled, { css, useTheme } from 'styled-components'
import { useSalesForSalesHistoryDashWidgetQuery } from '../../../../gql_generated/graphql'
import { formatDate, formatNumberToKM } from '../../../../utils'
import { Async, Card, genNivoBar } from '../../../UI'

const SalesHistoryDashWidgetStyles = css`
	.visualization {
		height: 200px;
	}
`

const SalesHistoryDashWidgetView = styled.div`
	${SalesHistoryDashWidgetStyles}
`

type SalesHistoryDashWidgetProps = {
	className?: string
}
export const SalesHistoryDashWidget = ({ className }: SalesHistoryDashWidgetProps): JSX.Element => {
	const [results] = useSalesForSalesHistoryDashWidgetQuery({
		variables: {
			startDate: `${new Date().getFullYear() - 5}-01-01`,
			endDate: formatDate(new Date(), 'yyyy-MM-dd'),
		},
	})

	const treeByYear = results.data?.sales?.reduce((obj, { activity, price, dateClosed }) => {
		const year = formatDate(dateClosed, 'yyyy')
		const Acres = sum(
			activity.map(({ acres, estate }) => (estate.type === 'srf' ? acres || estate?.acres || 0 : 0))
		)
		const Sales = price || 0

		const agg = { ...obj } as any

		if (!agg[year]) {
			agg[year] = {
				Acres,
				Sales,
			}
		} else {
			agg[year].Acres += Acres
			agg[year].Sales += Sales
		}

		return agg
	}, {}) as any

	const data = treeByYear
		? Object.keys(treeByYear).map(year => ({
				Year: year,
				Acres: treeByYear[year].Acres,
				Sales: treeByYear[year].Sales,
		  }))
		: []

	const { colors } = useTheme()

	console.log('data', data)

	return (
		<SalesHistoryDashWidgetView
			className={`sales-history-dash-widget${className ? ` className` : ''}`}
		>
			<Card noShadow titleText='LAND SALES' headingSize='lg' icon='sales'>
				<Async fetchResults={results}>
					{data?.length ? (
						<div className='visualizations'>
							<div className='visualization'>
								<ResponsiveBar
									indexBy={'Year'}
									keys={['Sales']}
									data={data}
									margin={{ top: 10, right: 10, bottom: 40, left: 10 }}
									axisTop={null}
									axisRight={null}
									axisBottom={{
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										legend: 'Sales',
										legendPosition: 'middle',
										legendOffset: 32,
									}}
									axisLeft={null}
									valueFormat={val => formatNumberToKM(val, { currencySymbol: '$' })}
									barComponent={genNivoBar('vertical')}
									enableGridY={false}
									labelTextColor='#fff'
									labelSkipWidth={15}
									role='application'
									padding={0.1}
									colors={[colors.aux2.val]}
								/>
							</div>
							<div className='visualization'>
								<ResponsiveBar
									indexBy={'Year'}
									keys={['Acres']}
									data={data}
									margin={{ top: 10, right: 10, bottom: 40, left: 10 }}
									axisTop={null}
									axisRight={null}
									axisBottom={{
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										legend: 'Acres',
										legendPosition: 'middle',
										legendOffset: 32,
									}}
									axisLeft={null}
									valueFormat={formatNumberToKM}
									barComponent={genNivoBar('vertical')}
									enableGridY={false}
									labelTextColor='#fff'
									labelSkipWidth={15}
									role='application'
									padding={0.1}
									colors={[colors.secondary.val]}
								/>
							</div>
						</div>
					) : null}
				</Async>
			</Card>
		</SalesHistoryDashWidgetView>
	)
}
