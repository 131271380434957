import React from 'react'
import styled from 'styled-components'
import { Btn, ErrorBlock, P, toastit } from '../..'
import { useDeleteRoyaltiesMutation } from '../../../../gql_generated/graphql'
import { useState } from '../../../../utils'
import { ResourceTableGlobalOpComponentOptions } from '../Table.resources'

const RemoveRoyaltiesFromResourceOpView = styled.div``

type RemoveRoyaltiesFromResourceOpProps = ResourceTableGlobalOpComponentOptions

export const RemoveRoyaltiesFromResourceOp: React.FC<RemoveRoyaltiesFromResourceOpProps> = ({
	parentId,
	parentType,
	isTotalQtySelected,
	deselectedRowIds,
	selectedRowIds,
	closeModal,
}) => {
	const [error, setError] = useState<any | null>(null, 'error')
	const [_, deleteRoyalties] = useDeleteRoyaltiesMutation()

	const removeHandler = async () => {
		try {
			const res = await deleteRoyalties({
				parentId,
				parentType,
				selectedIds: selectedRowIds,
				deselectedIds: deselectedRowIds,
				isTotalQtySelected,
			})

			if (res.error) toastit.err('Error deleting royalties')
			else closeModal()
		} catch (err) {
			setError(err)
		}
	}

	return (
		<RemoveRoyaltiesFromResourceOpView>
			<div className='msg'>
				<P>
					Are you sure you want to remove {isTotalQtySelected ? 'all' : selectedRowIds.length}{' '}
					{selectedRowIds.length > 1 ? 'royalties' : 'royalty'} from this {parentType}?
				</P>
				<P>NOTE: Removed royalties will also be permanently deleted.</P>
			</div>
			{error ? <ErrorBlock error={error} /> : null}
			<div className='flex btns'>
				<Btn className='cta' onClick={removeHandler}>
					Remove Royalties
				</Btn>
				<Btn onClick={closeModal}>Cancel</Btn>
			</div>
		</RemoveRoyaltiesFromResourceOpView>
	)
}
