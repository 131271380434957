import get from 'lodash/get'
import React, { ReactNode } from 'react'
import ReactModal from 'react-modal'
import Status from '../../common/Status'
import { Icon } from '../Icon'
import { Btn, EM, H3, Link } from '../Typography'
import ModalWrap from './Modal.view'

ReactModal.setAppElement('#root')

type ModalProps = {
	children?: ReactNode
	show: boolean
	closeHandler: () => void
	afterCloseHandler?: () => void
	noPad?: boolean
	titleText?: string | ReactNode
	status?: string
	size?: 'small' | 'med' | 'large'
	canBgClose?: boolean
	confirm?: () => void
	type?: string
	file?: {
		description: string
		full: {
			url: string
		}
	}
	footerCta?: ReactNode
	link?: string | null
	linkTxt?: string | null
	includeCloseIco?: boolean
}
export const Modal: React.FC<ModalProps> = ({
	children = null,
	closeHandler,
	afterCloseHandler,
	noPad,
	titleText,
	status,
	size,
	canBgClose,
	confirm,
	type,
	file,
	show,
	footerCta,
	link,
	linkTxt,
	includeCloseIco = true,
}) => {
	return (
		<ReactModal
			shouldCloseOnOverlayClick={canBgClose}
			onRequestClose={closeHandler}
			onAfterClose={afterCloseHandler}
			isOpen={show}
			closeTimeoutMS={400}
			className={`modal ${type || ''}`}
			overlayClassName={`flex overlay modal-overlay ${type || ''}`}
		>
			<ModalWrap className={`modal-wrap ${size || 'small'}`} noPad={noPad}>
				<header className='flex modal-header'>
					{titleText && <H3 className='modal-title'>{titleText}</H3>}
					<Status status={status} />
					{includeCloseIco ? (
						<Btn
							className='modal-close-btn'
							onClick={(e: Event) => {
								e.preventDefault()
								closeHandler()
							}}
						>
							<Icon type='x' />
						</Btn>
					) : null}
				</header>
				<div className='modal-inner-wrap'>
					{type === 'pic' && file ? (
						<figure>
							<img src={get(file, 'full.url')} alt={get(file, 'description')} />
							<figcaption>
								<span className='name'>{get(file, 'name')}</span>
								{get(file, 'description') && <EM>{file.description}</EM>}
							</figcaption>
						</figure>
					) : (
						children && <div className='content'>{children}</div>
					)}
				</div>
				<footer className='modal-footer'>
					{type === 'alert' ? (
						<div className='flex alert-btns'>
							<Btn
								className='alert-confirm-btn'
								onClick={(e: Event) => {
									e.preventDefault()
									if (confirm) confirm()
								}}
								isCta
							>
								Okay
							</Btn>
							<Btn
								className='alert-cancel-btn'
								onClick={(e: Event) => {
									e.preventDefault()
									closeHandler()
								}}
							>
								Cancel
							</Btn>
						</div>
					) : footerCta ? (
						footerCta
					) : link ? (
						<div className='link-wrap flex'>
							<Link to={link}>
								{linkTxt || link}
								<Icon type='arrowRight' />
							</Link>
							<Link to={link} isBlankTab className='in-new-tab'>
								(In new tab)
							</Link>
						</div>
					) : null}
				</footer>
			</ModalWrap>
		</ReactModal>
	)
}
