import capitalize from 'lodash/capitalize'
import React from 'react'
import styled from 'styled-components'
import { Estate, useParcelForEstatesQuery } from '../../../gql_generated/graphql'
import { nnrMgrs } from '../../../utils'
import { Btn, Icon, Modal } from '../../UI'
import { IconType } from '../../UI/Icon/fa.defaults'
import { useModalCtrl } from '../../UI/Modal'
import { EditEstateBtn } from '../Estate/Estate.edit.btn'
import Protect from '../Protect'
import { ParcelEstateModal } from './Parcel.estate.modal'

const ParcelEstatesView = styled.div`
	.estate-item {
		align-items: center;
		width: 100%;
		.edit-estate-btn {
			margin-left: auto;
		}
	}
	.estate-btn {
		margin: 0.5em 0;
		align-items: center;
		grid-gap: 1em;
		color: ${props => props.theme.colors.btn.val};
		&:hover {
			color: ${props => props.theme.colors.aux2.val};
		}

		&:last-child {
			margin-bottom: 0;
		}
		& > .icon {
			height: auto;
			width: 1.7em;
		}
	}
`

type ParcelEstateProps = {
	estateId: number
	parcelId: number
	clickHandler: () => void
	allowEdit?: boolean
}
const ParcelEstate: React.FC<ParcelEstateProps> = ({
	estateId,
	parcelId,
	clickHandler,
	allowEdit,
}) => {
	const [results] = useParcelForEstatesQuery({ variables: { id: parcelId } })
	const estate = results.data?.parcel?.estates?.find(({ id }) => id === estateId)
	const { type, status, acres } = estate || {}
	return estate ? (
		<div className='estate-item flex'>
			<Btn className='estate-btn flex' onClick={clickHandler}>
				<Icon type={type as IconType} />
				<span className='status'>{status}</span>
				<span className='acres'>{acres} acres</span>
			</Btn>
			{allowEdit ? (
				<Protect allowed={nnrMgrs}>
					<EditEstateBtn estateId={estateId} />
				</Protect>
			) : null}
		</div>
	) : null
}

type ParcelEstatesProps = Pick<ParcelEstateProps, 'allowEdit'> & {
	parcelId: number
}
const ParcelEstates: React.FC<ParcelEstatesProps> = ({ parcelId, allowEdit }) => {
	const [results] = useParcelForEstatesQuery({ variables: { id: parcelId } })
	const parcel = results.data?.parcel

	const [[showModal, openModal, closeModal, modalData]] =
		useModalCtrl<Pick<Estate, 'id' | 'typeFull'>>()

	const { estates } = parcel || {}
	return (
		<ParcelEstatesView>
			{estates?.length
				? estates.map(estate =>
						estate ? (
							<ParcelEstate
								key={estate.type}
								parcelId={parcelId}
								estateId={estate.id}
								allowEdit={allowEdit}
								clickHandler={() =>
									openModal({
										id: estate.id,
										typeFull: estate.typeFull,
									})
								}
							/>
						) : null
				  )
				: null}
			<Modal
				show={!!showModal}
				closeHandler={closeModal}
				size='med'
				titleText={`${capitalize(modalData?.typeFull)} Estate`}
			>
				{modalData ? <ParcelEstateModal estateId={modalData.id} /> : null}
			</Modal>
		</ParcelEstatesView>
	)
}
export default ParcelEstates
