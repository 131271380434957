import React from 'react'
import styled from 'styled-components'
import { DocInfo } from '../../common/Doc/Doc.info'
import { DocRecInfo } from '../../common/Doc/Doc.recInfo'
import { Card, Icon } from '../../UI'

export const AddDocToResourcePreviewView = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: ${props => props.theme.colors.grey.light(0).val};
	border-radius: 10px;
	max-width: 450px;

	.preview-header {
		padding: 1em;
		display: flex;
		justify-content: center;
		width: 100%;

		.icon {
			height: 80px;
			color: ${props => props.theme.colors.aux1.val};
		}
	}
	.preview-card {
		border: none;
		background: none;
		.card-content {
			padding-top: 0;
		}
	}

	.placeholder {
		background: ${props => props.theme.colors.white.val};
		color: ${props => props.theme.colors.grey.light(6).val};
		padding: 3vh;
		width: 100%;
		.icon {
			width: auto;
			height: 20vw;
		}
	}

	& > footer {
		margin-top: auto;
		border-top: solid 1px ${props => props.theme.colors.grey.light().val};
		padding-top: 1em;
		.cta {
			width: 100%;
		}
	}
`

export type AddDocToResourcePreviewProps = {
	docId: number | null
}
export const AddDocToResourcePreview: React.FC<AddDocToResourcePreviewProps> = ({ docId }) => {
	return (
		<AddDocToResourcePreviewView>
			{docId ? (
				<>
					<header className='preview-header'>
						<Icon type='doc' padRight />
					</header>
					<Card noShadow className='preview-card'>
						<Card noShadow titleText='Doc Info' mBot>
							<DocInfo docId={docId} />
						</Card>
						<Card noShadow titleText='Recorded Doc Info'>
							<DocRecInfo docId={docId} />
						</Card>
					</Card>
				</>
			) : (
				<div className='placeholder'>
					<Icon type='doc' />
				</div>
			)}
		</AddDocToResourcePreviewView>
	)
}
