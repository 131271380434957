import isFunction from 'lodash/isFunction'
import React, { useRef } from 'react'
import styled from 'styled-components'
import { useLeaseForEditFormQuery, useUpdateLeaseMutation } from '../../../gql_generated/graphql'
import { parseDateForServer, priceToNumber, sanitizeDataForForm } from '../../../utils'
import { Form, SubmitBtn } from '../../UI'
import LeaseFields, { leaseSchema } from './Ops.Lease.fields'

const View = styled.div`
	min-height: 300px;

	.estates-section {
		margin-bottom: 2em;
	}
	.estates-title {
		margin-bottom: 1em;
	}
`

type EditLeaseProps = {
	leaseId: number
	cb?: (data: any) => void
}
const EditLease: React.FC<EditLeaseProps> = ({ leaseId, cb }) => {
	const [_, updateLease] = useUpdateLeaseMutation()
	const stopRender = useRef(false) // component should no longer render markup

	const [results] = useLeaseForEditFormQuery({ variables: { leaseId } })

	const initialVals = {
		...(results.data?.lease ?? {}),
	}

	return stopRender.current ? null : (
		<Form
			initialValues={sanitizeDataForForm(initialVals)}
			onSubmit={async (vals: typeof initialVals) => {
				try {
					const data = {
						...vals,
						leaseId: `${vals.leaseId}`,
						lesseeDbaId: vals.lesseeDbaId || null,
						dateEffective: vals.dateEffective?.length
							? parseDateForServer(vals.dateEffective)
							: null,
						dateShouldExpire: vals.dateShouldExpire?.length
							? parseDateForServer(vals.dateShouldExpire)
							: null,
						dateExpired: vals.dateExpired?.length ? parseDateForServer(vals.dateExpired) : null,
						dateTerminated: vals.dateTerminated?.length
							? parseDateForServer(vals.dateTerminated)
							: null,
						dateInsurExpires: vals.dateInsurExpires?.length
							? parseDateForServer(vals.dateInsurExpires)
							: null,
						initialFee: vals.initialFee ? priceToNumber(vals.initialFee as unknown as string) : 0,
					} as any

					const results = await updateLease({ data })

					if (isFunction(cb)) {
						stopRender.current = true
						cb(results)
					}
				} catch (err) {
					console.error(err)
				}
			}}
			validationSchema={leaseSchema}
		>
			{() => (
				<View>
					<LeaseFields />
					<SubmitBtn />
				</View>
			)}
		</Form>
	)
}
export default EditLease
