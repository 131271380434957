import { devtoolsExchange } from '@urql/devtools'
import { cacheExchange } from '@urql/exchange-graphcache'
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch'
import { ClientOptions, dedupExchange } from 'urql'
import { GQL_API_URI } from '../app.config'
import { urqlAuthExchange } from './Urq.config.auth'
import { urqlCacheConfig } from './Urql.config.cache'

export const urqlClientOptions: ClientOptions = {
	url: GQL_API_URI,

	exchanges: [
		devtoolsExchange,

		// urqlLogExchange, // Logging is taken care of using event subscriptions - see App.tsx
		dedupExchange,
		cacheExchange(urqlCacheConfig),
		urqlAuthExchange,
		multipartFetchExchange,
	],
}
