import React from 'react'
import styled, { css } from 'styled-components/macro'
import { DocDetail } from '../../../Components/common/Doc/Doc.detail'

export const relatedOrgDocModalStyles = css``

const RelatedOrgDocModalView = styled.div`
	${relatedOrgDocModalStyles}
`

export type RelatedOrgDocModalProps = {
	docId: number
}
export const RelatedOrgDocModal: React.FC<RelatedOrgDocModalProps> = ({ docId }) => {
	return (
		<RelatedOrgDocModalView>
			<DocDetail docId={docId} />
		</RelatedOrgDocModalView>
	)
}
