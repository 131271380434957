import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { ColorOption, useColor } from '../../../utils/useColor'

type HeadingStyleProps = {
	textColor: string
	mBot?: boolean
	mTop?: boolean
}

export const pageHeadingStyles = css`
	font-size: 4rem;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	font-weight: 400;
`
export const sectionHeadingStyles = css`
	font-size: 2rem;
	text-transform: uppercase;
	letter-spacing: 2px;

	${props => props.theme.media.tablet} {
		font-size: 2.5rem;
		letter-spacing: 4px;
	}
	${props => props.theme.media.sdesk} {
		font-size: 3rem;
		letter-spacing: 4px;
	}
`
export const contentHeadingStyles = css`
	font-size: 1.5rem;
	line-height: 1.7rem;
	font-weight: 600;
	${props => props.theme.media.tablet} {
		font-size: 1.8rem;
		line-height: 2rem;
		font-weight: 600;
	}
	${props => props.theme.media.sdesk} {
		font-size: 2rem;
		line-height: 2.2rem;
		font-weight: 600;
	}
`

export const subHeadingStyles = css`
	font-size: 1.3rem;
	line-height: 1.5rem;
	font-weight: 600;
	letter-spacing: 1px;
`

export const headingStyles = css<HeadingStyleProps>`
	color: ${props => props.textColor};

	margin-top: ${p => (p.mTop ? '1em' : 'none')};
	margin-bottom: ${p => (p.mBot ? '1em' : 'none')};

	&._display-type-page-heading {
		${pageHeadingStyles}
	}
	&._display-type-section-heading {
		${sectionHeadingStyles}
	}
	&._display-type-block-heading {
		${contentHeadingStyles}
	}
	&._display-type-p-heading {
		${subHeadingStyles}
	}
`

const H1 = styled.h1<HeadingStyleProps>`
	${headingStyles}
`
const H2 = styled.h2<HeadingStyleProps>`
	${headingStyles}
`
const H3 = styled.h3<HeadingStyleProps>`
	${headingStyles}
`
const H4 = styled.h4<HeadingStyleProps>`
	${headingStyles}
`
const H5 = styled.h5<HeadingStyleProps>`
	${headingStyles}
`
const H6 = styled.h6<HeadingStyleProps>`
	${headingStyles}
`

type DynamicHeaderProps = HeadingStyleProps & {
	tag: HTag
	children?: ReactNode
	className?: string
}

const DynamicHeader = ({ tag, ...hProps }: DynamicHeaderProps): JSX.Element => {
	const props = {
		...hProps,
	}
	return tag === 'h1' ? (
		<H1 {...props} />
	) : tag === 'h2' ? (
		<H2 {...props} />
	) : tag === 'h3' ? (
		<H3 {...props} />
	) : tag === 'h4' ? (
		<H4 {...props} />
	) : tag === 'h5' ? (
		<H5 {...props} />
	) : tag === 'h6' ? (
		<H6 {...props} />
	) : (
		<H2 {...props} />
	)
}

type HTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

type DisplayType =
	| 'page-heading'
	| 'section-heading'
	| 'dialog-heading'
	| 'block-heading'
	| 'p-heading'
export type HeadingProps = Pick<HeadingStyleProps, 'mBot' | 'mTop'> & {
	color?: ColorOption
	hTag?: HTag
	displayType?: DisplayType
	children?: DynamicHeaderProps['children']
	className?: string
}

export const Heading = ({
	color,
	hTag,
	displayType = 'p-heading',
	children,
	className,
	mBot,
	mTop,
}: HeadingProps): JSX.Element => {
	const colorVal = useColor(color || 'text')

	const tag =
		hTag ||
		(displayType === 'page-heading'
			? 'h1'
			: displayType === 'section-heading'
			? 'h2'
			: displayType === 'dialog-heading'
			? 'h3'
			: displayType === 'block-heading'
			? 'h4'
			: 'h5')

	return (
		<DynamicHeader
			className={`heading${className ? ` ${className}` : ''} _display-type-${displayType}`}
			textColor={colorVal}
			tag={tag}
			mBot={mBot}
			mTop={mTop}
		>
			{children}
		</DynamicHeader>
	)
}
