/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react'
import styled from 'styled-components'
import Bar from './Bar'
import { useProgress, UseProgressOptions } from './useProgress'

type BarContainerViewProps = {
	height?: string
}

const BarContainerView = styled.div<BarContainerViewProps>`
	width: 100%;
	height: ${props => (props.height ? props.height : '4px')};
	background-color: ${props =>
		props.theme.colors.loader
			? props.theme.colors.loader.light('6').val
			: props.theme.colors.grey.light('5').val};
	box-shadow: none;
	overflow: hidden;
	.bar {
		width: 10%;
		max-width: 100%;
		min-width: 10%;
		height: 100%;
		transition: width ${props => `${props.theme.times.med}ms ${props.theme.times.ease}`};
		* {
			height: 100%;
		}
	}
`

export type BarContainerProps = BarContainerViewProps &
	UseProgressOptions & {
		isDone?: boolean
		className?: string
	}

const BarContainer: React.FC<BarContainerProps> = ({
	isDone,
	height,
	className,
	...useProgressOptions
}) => {
	const percent = useProgress(useProgressOptions)

	return (
		<BarContainerView className={`progress-bar${className ? ` ${className}` : ''}`} height={height}>
			<div className='bar' style={{ width: isDone ? '100%' : `${percent}%` }}>
				<Bar />
			</div>
		</BarContainerView>
	)
}

export default memo(BarContainer)
