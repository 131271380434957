/*
 =================================================
  Swf-Theme Config and Init
  See: https://github.com/swiftforge/swf-theme
 =================================================
* */

import { createGlobalStyle } from 'styled-components'
import { createTheme, createThemeGlobalStyles } from '@swfsoft/swf-theme'

import colors from './colors'

export const themeConfig = {
	fonts: {
		textFamily: `'Lato', sans-serif`,
		titleFamily: `'Lato', sans-serif`,
	},

	sizes: {
		gutter: {
			mobile: {
				num: 8,
				val: '8px',
			},
			sdesk: {
				num: 180,
				val: '180px',
			},
		},
		header: {
			mobile: {
				num: 40,
				val: '40px',
			},
			tablet: {
				num: 50,
				val: '50px',
			},
			sdesk: {
				num: 102,
				val: '102px',
			},
		},
		deskNav: {
			num: 200,
			val: '200px',
		},
		alertbar: {
			num: 30,
			val: '30px',
		},
	},

	colors,
}

export const theme = createTheme(themeConfig)

export const ThemeGlobalStyles = createGlobalStyle`
  ${createThemeGlobalStyles(theme, {
		include: {
			cssReset: true,
			fluidHeadings: true,
		},
	})}
`
