import { Redirect, RouteComponentProps, Router } from '@reach/router'
import React from 'react'
import Protect from '../Components/common/Protect'
import MgrLayout from '../Layouts/Layout.mgr'
/**
 *  IMPORT ROUTES
 * */
import Page404 from '../Pages/404.page'
import Dash from '../Pages/Dash'
import { PropertyTaxBatchCreatePage } from '../Pages/PropertyTax.batch.create.page'
import { nnrTeam } from '../utils'
import AcquisitionRoutes from './Acquisition.routes'
import AgreementRoutes from './Agreement.routes'
import DocRoutes from './Doc.routes'
import EasementRoutes from './Easement.routes'
import LeaseRoutes from './Lease.routes'
import ActivityLogRoutes from './Log.routes'
import OrgRoutes from './Org.routes'
import ParcelRoutes from './Parcel.routes'
import ReportsRoutes from './Reports.routes'
import ResearchRoutes from './Research.routes'
import RoyaltyRoutes from './Royalty.routes'
import SaleRoutes from './Sale.routes'
import UserRoutes from './User.routes'

const MgrRoutes: React.FC<RouteComponentProps> = () => {
	return (
		<Protect allowed={nnrTeam} redirect='/login'>
			<MgrLayout>
				<Router>
					<Redirect from='/' to='dash' noThrow />
					<Dash path='dash' />
					<ParcelRoutes path='parcels/*' />
					<SaleRoutes path='sales/*' />
					<AcquisitionRoutes path='acquisitions/*' />
					<LeaseRoutes path='leases/*' />
					<EasementRoutes path='easements/*' />
					<AgreementRoutes path='agreements/*' />
					<OrgRoutes path='orgs/*' />
					<UserRoutes path='users/*' />
					<ActivityLogRoutes path='logs' />
					<ResearchRoutes path='research/*' />
					<RoyaltyRoutes path='royalties' />
					<DocRoutes path='docs/*' />
					<ReportsRoutes path='reports/*' />
					<PropertyTaxBatchCreatePage path='parcels/batch-tax-create' />
					<Page404 default />
				</Router>
			</MgrLayout>
		</Protect>
	)
}
export default MgrRoutes
