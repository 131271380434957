import React from 'react'
import styled, { css } from 'styled-components/macro'
import { RoyaltyDetail } from '../../../Components/common/Royalty/Royalty.detail'
import RoyaltyParcels from '../../../Components/common/Royalty/Royalty.parcels'
import { Card } from '../../../Components/UI'

export const relatedSaleRoyaltyModalStyles = css``

const RelatedSaleRoyaltyModalView = styled.div`
	${relatedSaleRoyaltyModalStyles}
`

export type RelatedSaleRoyaltyModalProps = {
	royaltyId: number
}
export const RelatedSaleRoyaltyModal: React.FC<RelatedSaleRoyaltyModalProps> = ({ royaltyId }) => {
	return (
		<RelatedSaleRoyaltyModalView>
			<RoyaltyDetail royaltyId={royaltyId} />
			<Card noShadow mTop titleText='Parcels'>
				<RoyaltyParcels royaltyId={royaltyId} />
			</Card>
		</RelatedSaleRoyaltyModalView>
	)
}
