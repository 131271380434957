import React from 'react'
import styled, { css } from 'styled-components/macro'
import { ParcelDetail } from './Parcel.detail'

export const genParcelModalStyles = css``

const GenParcelModalView = styled.div`
	${genParcelModalStyles}
`

export type GenParcelModalProps = {
	parcelId: number
}

export const GenParcelModal: React.FC<GenParcelModalProps> = ({ parcelId }) => {
	return (
		<GenParcelModalView>
			<ParcelDetail parcelId={parcelId} />
		</GenParcelModalView>
	)
}
