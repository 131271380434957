import React from 'react'
import styled, { css } from 'styled-components/macro'
import { ResourcesTableProps } from './Table.resources'

export const resourcesTableTotalStyles = css`
	color: ${props => props.theme.colors.grey.val};
`

const ResourcesTableTotalView = styled.div`
	${resourcesTableTotalStyles}
`

export type ResourcesTableTotalProps = {
	fetchResults: ResourcesTableProps<any>['fetchResults']
}
export const ResourcesTableTotal: React.FC<ResourcesTableTotalProps> = ({ fetchResults }) => {
	const { fetching, data } = fetchResults
	const total = data?.page?.totalQty

	return (
		<ResourcesTableTotalView className='resource-table-total'>
			<div className='total'>Total Results: {fetching ? '...' : total}</div>
		</ResourcesTableTotalView>
	)
}
