import React from 'react'
import CurrencyInput from 'react-currency-input-field'
import toNumber from 'lodash/toNumber'

import FormFieldWrap, { FormFieldWrapProps } from './Form.field.wrap'
import { InputProps } from './Input'
import { useField } from 'formik'
import styled from 'styled-components'

const View = styled.div``

const IntegerField: React.FC<InputProps & FormFieldWrapProps> = ({
	label,
	name,
	isReq,
	helperTxt,
	...props
}) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [{ value }, __, { setValue }] = useField({ name, ...props })
	return (
		<FormFieldWrap type='integer' label={label} name={name} isReq={isReq} helperTxt={helperTxt}>
			<View>
				<CurrencyInput
					name={name}
					allowDecimals={false}
					decimalsLimit={0}
					value={`${value}`}
					onValueChange={value => setValue(toNumber(value))}
				/>
			</View>
		</FormFieldWrap>
	)
}
export default IntegerField
