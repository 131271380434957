import React from 'react'
import styled, { css } from 'styled-components/macro'
import ErrorBlock from '../../Error.block'
import { ErrorBlockErr } from '../../Error.block/Error.block'

export const tableBodyErrorRowStyles = css``

const TableBodyErrorRowView = styled.tr`
	${tableBodyErrorRowStyles}
`

export type TableBodyErrorRowProps = {
	error: ErrorBlockErr
	colsQty: number
}
export const TableBodyErrorRow: React.FC<TableBodyErrorRowProps> = ({ error, colsQty }) => {
	return (
		<TableBodyErrorRowView>
			<td colSpan={colsQty}>
				<ErrorBlock error={error} />
			</td>
		</TableBodyErrorRowView>
	)
}
