import React, { HtmlHTMLAttributes, useEffect } from 'react'
import styled from 'styled-components'
import { useStoreActions, useStoreState } from '../../store'
import {} from '../UI'
import LoggedInNav from './Nav.loggedin'
import MgrNav from './Nav.mgr'

const NavWrap = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	width: 80%;
	z-index: 20;
	transition: all ${props => props.theme.times.tranS};
	transform: translate3d(0, 0, 0);
	${props => props.theme.media.tablet} {
		width: 50%;
	}
	${props => props.theme.media.sdesk} {
		width: 400px;
	}
	.inner-wrap {
		overflow-y: auto;
		height: 100%;
		width: 100%;
		background: ${props => props.theme.colors.slate.val};
		padding: 2em 0;

		.nav-group {
			margin-bottom: 0.5em;
		}
	}
	nav {
		padding: 1em 0;
		.link {
			color: ${props => props.theme.colors.white.val};
			font-weight: 400;
			padding: 1em 2em;
			&.active,
			&:hover {
				color: ${props => props.theme.colors.white.val};
				background: ${props => props.theme.colors.aux1.tint(30).val};
			}
		}
	}
`

const Overlay = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	transition: opacity ${props => props.theme.times.tranM};
	background: ${props => props.theme.colors.black.tint().val};
	width: 100vw;
	height: 100vh;
	z-index: 19;
`

const MobileNav: React.FC<HtmlHTMLAttributes<HTMLDivElement>> = ({ className }) => {
	const { isMobileNavOpen } = useStoreState(state => state.view)
	const { toggleMobileNav } = useStoreActions(actions => actions.view)

	useEffect(() => {
		if (isMobileNavOpen) document.body.classList.add('__no-scroll')
		else document.body.classList.remove('__no-scroll')
	}, [isMobileNavOpen])

	return (
		<>
			<NavWrap
				className={`mobile-nav${className ? ` ${className}` : ''}`}
				onClick={() => toggleMobileNav()}
				style={{ transform: `translate3d(${isMobileNavOpen ? 0 : '100%'}, 0, 0)` }}
			>
				<div className='inner-wrap'>
					<MgrNav className='mgr-nav' />
					<LoggedInNav className='logged-in-nav' />
				</div>
			</NavWrap>
			<Overlay
				onClick={() => toggleMobileNav()}
				style={{
					pointerEvents: isMobileNavOpen ? 'initial' : 'none',
					opacity: isMobileNavOpen ? 1 : 0,
				}}
			/>
		</>
	)
}
MobileNav.propTypes = {}
export default MobileNav
