import toUpper from 'lodash/toUpper'
import styled from 'styled-components'
import { Address as AddressType } from '../../../gql_generated/graphql'
import KeyVals from '../KeyVals'

const StyledAddress = styled.div`
	line-height: 1.1em;
	.city-state {
		display: flex;
		span {
			margin-right: 0.5em;
			&:last-child {
				margin-right: 0;
			}
		}
	}
`

type AddressProps = {
	address: AddressType
	className?: string
	format?: string
}

const Address: React.FC<AddressProps> = ({ address, className = null, format = 'default' }) => {
	const { street, street2, city, state, country, zip } = address || {}
	if (!street && !city) return null
	return format === 'table' ? (
		<KeyVals
			items={[
				['Country', country],
				['Street', street],
				street2 ? ['Street 2', street2] : null,
				['City', city],
				['State/Province', state],
				['Zip/Postal Code', zip],
			]}
		/>
	) : (
		<StyledAddress className={`address${className ? ` ${className}` : ''}`}>
			{street ? <div className='street'>{street}</div> : null}
			{street2 ? <div className='street'>{street2}</div> : null}
			<div className='city-state'>
				{city ? <span className='city'>{city},</span> : null}
				{state ? <span className='state'>{toUpper(state)}</span> : null}
				{zip ? <div className='zip'>{` ${zip}`}</div> : null}
			</div>
			{country && <div className='country'>{toUpper(country)}</div>}
		</StyledAddress>
	)
}

export default Address
