import { useState } from 'react'
import styled, { css } from 'styled-components'
import { LeaseTermsExpirationFilterPeriodOption } from '../../../../Types'
import { Card, P, Tab, Tabs } from '../../../UI'
import { ExpiredLeaseDashWidgetFilters } from './ExpiredLease.dash.widget.filters'
import { ExpiredLeaseDashWidgetNoContClause } from './ExpiredLease.dash.widget.noCont'
import { ExpiredLeaseDashWidgetWithContClause } from './ExpiredLease.dash.widget.withCont'

export const expiredLeaseDashWidgetStyles = css``

const ExpiredLeaseDashWidgetView = styled.div`
	${expiredLeaseDashWidgetStyles}
`

export type ExpiredLeaseDashWidgetProps = {}
export const ExpiredLeaseDashWidget = (): JSX.Element => {
	const [period, setPeriod] = useState<LeaseTermsExpirationFilterPeriodOption>('NOW')

	return (
		<ExpiredLeaseDashWidgetView>
			<Card
				corners
				titleText='Leases Past Expiration'
				headingSize='lg'
				icon='lease'
				cta={<ExpiredLeaseDashWidgetFilters onChange={setPeriod} value={period} />}
			>
				<P>Shows all leases with terms that are past expiration by selected time period.</P>
				<Tabs>
					<Tab nav='No Continuation Clause'>
						<ExpiredLeaseDashWidgetNoContClause period={period} />
					</Tab>
					<Tab nav='With Continuation Clause'>
						<ExpiredLeaseDashWidgetWithContClause period={period} />
					</Tab>
				</Tabs>
			</Card>
		</ExpiredLeaseDashWidgetView>
	)
}
