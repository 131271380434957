import React from 'react'
import styled from 'styled-components'
import { useCreateLeaseTermMutation, useLeaseFullQuery } from '../../../gql_generated/graphql'
import { defaultLeaseTermVals, parseDateForServer } from '../../../utils'
import { Async, Card, Form, P, SubmitBtn, toastit, UL } from '../../UI'
import { Heading } from '../../UI/Heading'
import { LeaseTermsProps } from './Ops.Lease.terms'
import LeaseTermFields, { leaseTermsYupSchema } from './Ops.Lease.terms.fields'

const View = styled.div``

const CreateLeaseTerms: React.FC<LeaseTermsProps> = ({ leaseId, cb }) => {
	const [results] = useLeaseFullQuery({ variables: { id: leaseId } })
	const lease = results.data?.lease

	const [{ error }, createLeaseTerm] = useCreateLeaseTermMutation()

	return (
		<View>
			<Async fetchResults={results}>
				{lease ? (
					<Form
						legend='Create new terms'
						validationSchema={leaseTermsYupSchema}
						submitErr={error}
						initialValues={{
							...defaultLeaseTermVals,
							termNotes: lease.currentTerm?.termNotes || '',
						}}
						onSubmit={async (vals: any) => {
							try {
								const data = { ...vals }
								data.leaseId = lease.id

								data.dateFrom = parseDateForServer(vals.dateFrom)
								data.dateTo = parseDateForServer(vals.dateTo)

								const res: any = await createLeaseTerm({ data })

								if (!res.error) {
									toastit.ok('Saved!')
									cb && cb()
								}
							} catch (err: any) {}
						}}
					>
						{() => (
							<>
								<Card noShadow mBot>
									<Heading mBot>WARNING</Heading>
									<P>Creating new terms is for when:</P>
									<UL>
										<li>Terms have been renegotiated</li>
										<li>They have expired and need to be updated</li>
										<li>Renewal option has taken effect</li>
									</UL>
								</Card>
								<LeaseTermFields isTermNotesRequired />
								<SubmitBtn />
							</>
						)}
					</Form>
				) : null}
			</Async>
		</View>
	)
}
export default CreateLeaseTerms
