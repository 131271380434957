import capitalize from 'lodash/capitalize'
import { formatValue } from '../../../utils'
import { TableBodyCellConfig, TableHeadCellConfig, TableRowConfig } from '../Table/Table.types'
import { ResourceTableColConfig } from './Table.resources'

export const createColsFromColsConfig = (
	colsConfig: ResourceTableColConfig<any>[]
): TableHeadCellConfig[] =>
	colsConfig.map(col => {
		// Col is a string (we just make the first char upper case and call it good)
		if (typeof col === 'string')
			return {
				label: capitalize(col),
			}
		// Col is an array config
		else if (Array.isArray(col))
			return {
				label: col[0], // first item is the label
				sortField: col[3], // fourth item is an optional sort field key
			}

		const { label, field, sortField, hideMobile, hideTablet } = col
		return {
			label: label ? label : typeof field === 'string' ? capitalize(field) : '[add label]',
			sortField,
			hideMobile,
			hideTablet,
		}
	})

export function createTableRowsFromDataSet<T>(
	dataSet: any[],
	colsConfig: ResourceTableColConfig<T>[]
): TableRowConfig<T>[] {
	const rows: TableRowConfig<T>[] = dataSet.map((data, idx) => {
		const cells: TableBodyCellConfig[] = colsConfig.map(col => {
			// This col is the field key
			if (typeof col === 'string')
				return {
					colSpan: 1,
					txt: data[col],
				}
			// This col is an array config
			else if (Array.isArray(col)) {
				// second item is the field key
				const val = data[col[1]]
				// third item is an optional format
				const format = col[2]
				return {
					colSpan: 1,
					txt: format ? formatValue(val, format) : val,
				}
			}

			// This col is a regular col object
			const { field, Component, format, hideMobile, hideTablet, isRowTrigger } = col
			const val =
				typeof field === 'string' ? data[field] : typeof field === 'function' ? field(data) : null

			return {
				colSpan: 1,
				hideMobile,
				hideTablet,
				txt: format ? formatValue(val, format) : val,
				Component,
				isRowTrigger,
			}
		})

		return {
			id: data.id || idx,
			rowIdx: idx,
			val: data,
			cells,
		}
	})

	return rows
}
