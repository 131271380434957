import React from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import { useTheme } from 'styled-components'
import Skeleton from '../Skeleton'

const Bar: React.FC = () => {
	const { colors } = useTheme()
	return (
		<SkeletonTheme
			baseColor={colors.loader ? colors.loader.val : colors.aux2.val}
			highlightColor={colors.loader ? colors.loader.light().val : colors.aux2.light().val}
		>
			<Skeleton />
		</SkeletonTheme>
	)
}
Bar.propTypes = {}
export default React.memo(Bar)
