import React from 'react'
import styled from 'styled-components'
import { Lease } from '../../../gql_generated/document_types'
import { formatDate } from '../../../utils'
import { H1, H4 } from '../../UI'
import { LeaseTaxReimbrsPdfConfig } from './Lease.TaxReimbrs.pdf'

const LeaseTaxReimbrsPdfTitlesView = styled.div`
	display: grid;
	grid-template-columns: 15% 1fr 15%;
	.page-titles {
		text-align: center;
		& > * {
			margin-bottom: 0.5em;
		}
		h1 {
			font-size: 18px;
		}
		h4 {
			font-size: 14px;
		}
		p {
			font-size: 12px;
			font-weight: 700;
		}
	}
	.date {
		text-align: right;
	}
`

type LeaseTaxReimbrsPdfTitlesProps = {
	config: LeaseTaxReimbrsPdfConfig
	lease: Lease
}
const LeaseTaxReimbrsPdfTitles: React.FC<LeaseTaxReimbrsPdfTitlesProps> = ({ lease, config }) => {
	const { hideDate } = config
	return (
		<LeaseTaxReimbrsPdfTitlesView className='pdf-titles'>
			<div className='left-col'></div>
			<div className='page-titles'>
				<H1>{lease.lesseeDba?.name ?? lease.lessee?.name ?? lease.og?.lessee}</H1>
				<H4>{lease.leaseId}</H4>
			</div>
			{hideDate ? null : (
				<div className='right-col'>
					<div className='date'>{formatDate(new Date())}</div>
				</div>
			)}
		</LeaseTaxReimbrsPdfTitlesView>
	)
}
export default LeaseTaxReimbrsPdfTitles
