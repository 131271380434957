import React from 'react'
import styled, { css } from 'styled-components/macro'
import Protect from '../../../Components/common/Protect'
import { CreatePropertyTaxOp } from '../../../Components/Ops/Ops.Parcels/Ops.Parcel.create.propertyTax'
import { EditPropertyTaxOp } from '../../../Components/Ops/Ops.Parcels/Ops.Parcel.edit.propertyTax'
import { AddBtn, Async, LeftBar, Modal, useModalCtrl } from '../../../Components/UI'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { UserRole } from '../../../gql_generated/document_types'
import { useParcelForPropertyTaxesQuery } from '../../../gql_generated/graphql'
import { nnrMgrs } from '../../../utils'
import { sortBy } from '../../../utils/lodash.utils'

export const relatedParcelTaxesStyles = css``

const RelatedParcelTaxesView = styled.div`
	${relatedParcelTaxesStyles}
`

export type RelatedParcelTaxesProps = {
	parcelId: number
}

export const RelatedParcelTaxes: React.FC<RelatedParcelTaxesProps> = ({ parcelId }) => {
	const [fetchResults] = useParcelForPropertyTaxesQuery({ variables: { id: parcelId } })

	const { propertyTaxes } = fetchResults.data?.parcel || {}

	const sortedTaxes = sortBy(propertyTaxes, 'year').reverse()

	const [[showEdit, openEdit, closeEdit, editTaxId]] = useModalCtrl<number>()
	const [[showAdd, openAdd, closeAdd]] = useModalCtrl()
	return (
		<RelatedParcelTaxesView>
			<Async fetchResults={fetchResults}>
				<ResourcesTable
					colsConfig={[
						{ label: 'Year', field: 'year' },
						{ label: 'Amount', field: 'amount', format: 'currency' },
					]}
					fetchResults={fetchResults}
					dataset={sortedTaxes}
					rowOps={[
						{
							icon: 'edit',
							allowed: [...nnrMgrs, UserRole.Accountant],
							op: tax => openEdit(tax.id),
						},
					]}
				>
					<Protect allowed={[...nnrMgrs, UserRole.Accountant]}>
						<LeftBar>
							<AddBtn onClick={() => openAdd()} title='Add property tax' />
						</LeftBar>
					</Protect>
				</ResourcesTable>
				{/* {sortedTaxes ? (
					<Table
						cols={[{ label: 'Year' }, { label: 'Amount' }]}
						rows={sortedTaxes.map(({ id, amount, year }, rowIdx) => ({
							id,
							rowIdx,
							cells: [{ txt: year }, { txt: amount }],
						}))}
					/>
				) : null} */}
			</Async>
			<Modal show={showAdd} closeHandler={closeAdd} titleText='Add Property Tax'>
				<CreatePropertyTaxOp parcelId={parcelId} cb={closeAdd} />
			</Modal>
			<Modal show={showEdit} closeHandler={closeEdit} titleText='Edit Property Tax'>
				{editTaxId ? <EditPropertyTaxOp propertyTaxId={editTaxId} cb={closeEdit} /> : null}
			</Modal>
		</RelatedParcelTaxesView>
	)
}
