import styled from 'styled-components'

export type ModalWrapProps = {
	noPad?: boolean
}
export default styled.div<ModalWrapProps>`
	width: auto;
	height: 100%;
	border-radius: 4px;
	outline: none;
	max-height: 90vh;
	background: ${props => props.theme.colors.white.val};
	transition: all ${props => props.theme.times.tranS};
	opacity: 0;
	transform: translate3d(0, 50px, 0);
	overflow: hidden;
	${props => props.theme.media.tablet} {
		/* max-width: 80vw; */
	}
	&.med {
		${props => props.theme.media.tablet} {
			min-width: 65vw;
		}
	}
	&.large {
		${props => props.theme.media.tablet} {
			width: 90vw;
		}
		.modal-inner-wrap {
			height: 100%;
			overflow-y: auto;
			${props => props.theme.media.tablet} {
				padding: ${props => (props.noPad ? 0 : '3em')};
			}
		}
	}

	&.pic {
		figure {
			img {
				display: block;
				max-width: 100%;
				max-height: 60vh;
			}
			figcaption {
				width: 100%;
				padding: 0.5em 1em;
				background: ${props => props.theme.colors.black.val};
				color: ${props => props.theme.colors.white.val};
				box-sizing: border-box;
				span,
				em {
					display: block;
					${props => props.theme.media.tablet} {
						display: initial;
					}
				}
				em {
					margin-left: 1.5em;
				}
			}
		}
	}
	& > header {
		display: grid;
		grid-template-columns: 1em 1fr 0 30px;
		border-bottom: solid 1px ${props => props.theme.colors.grey.light().val};
		background: ${props => props.theme.colors.secondary.val};
		color: ${props => props.theme.colors.white.val};
		${props => props.theme.media.tablet} {
			grid-template-columns: 30px 1fr 120px 30px;
		}
		.modal-title {
			padding: 8px 0;
			grid-column-start: 2;
			grid-column-end: 2;
			font-size: 1.1rem;
			color: inherit;
			${props => props.theme.media.tablet} {
				font-size: 1.5rem;
			}
			${props => props.theme.media.sdesk} {
				font-size: 1.7rem;
			}
		}
		.status {
			display: none;
			${props => props.theme.media.tablet} {
				grid-column-start: 3;
				grid-column-end: 3;
				color: inherit;
			}
		}
		.modal-close-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 30px;
			padding: 0;
			grid-column-start: 4;
			grid-column-end: 4;
			.icon {
				margin: 0;
				color: ${props => props.theme.colors.white.val};
			}
		}
	}
	.modal-inner-wrap {
		padding: ${props => (props.noPad ? 0 : '1em')};
		overflow-y: auto;
		max-height: 70vh;
		${props => props.theme.media.tablet} {
			padding: ${props => (props.noPad ? 0 : '1em 2em')};
		}
		${props => props.theme.media.sdesk} {
			padding: ${props => (props.noPad ? 0 : '1em 2.5em')};
		}
	}
	.modal-footer {
		width: 100%;
		border-top: solid 1px ${props => props.theme.colors.grey.light(5).val};
		.alert-btns {
			justify-content: center;
			width: 100%;
		}
		.link-wrap {
			width: 100%;
			justify-content: flex-end;
			padding: 1em 0.5em;

			${props => props.theme.media.tablet} {
				padding: 1em;
			}

			.link {
				display: flex;
				align-items: center;
				text-transform: uppercase;
				margin-right: 1em;

				&:last-child {
					margin: 0;
				}

				&.in-new-tab {
					display: none;

					${props => props.theme.media.sdesk} {
						display: flex;
					}
				}

				.icon {
					margin-left: 0.5em;
					margin-top: -2px;
					height: 1.1em;
				}
			}
		}
	}
`
