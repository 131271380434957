import React from 'react'
import styled, { css } from 'styled-components/macro'
import { relatedOrgColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Org.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { OrgParent } from '../../../gql_generated/document_types'
import { usePaginatedOrgsQuery } from '../../../gql_generated/graphql'
import { RelatedAgreementOrgModal } from '../Related.Modals/Agreement.related.org.modal'

export const relatedAgreementOrgStyles = css``

const RelatedAgreementOrgsView = styled.div`
	${relatedAgreementOrgStyles}
`

export type RelatedAgreementOrgsProps = {
	agreementId: number
}
export const RelatedAgreementOrgs: React.FC<RelatedAgreementOrgsProps> = ({ agreementId }) => {
	const paginationStore = usePaginationParams()

	const [results] = usePaginatedOrgsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: OrgParent.Agreement,
						parentIds: [agreementId],
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.orgs

	return (
		<RelatedAgreementOrgsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedOrgColsConfig}
				fetchResults={results}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedAgreementOrgModal orgId={data.id} />}
				resourceType='org'
			/>
		</RelatedAgreementOrgsView>
	)
}
