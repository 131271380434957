import styled, { css } from 'styled-components'
import { Btn, P, toastit } from '../..'
import { useRemoveDocsFromRelMutation } from '../../../../gql_generated/graphql'
import ErrorBlock from '../../Error.block'
import { Heading } from '../../Heading'
import { Spinner } from '../../Loaders'
import { ResourceTableGlobalOpComponentOptions } from '../Table.resources'

export const removeDocsFromResourceOpStyles = css`
	max-width: 500px;
	margin: 0 auto;

	.cta-btns {
		display: flex;
		align-items: center;
		& > * :first-child {
			margin-right: 1em;
		}
	}
`

const RemoveDocsFromResourceOpView = styled.div`
	${removeDocsFromResourceOpStyles}
`

export type RemoveDocsFromResourceOpProps = ResourceTableGlobalOpComponentOptions & {}
export const RemoveDocsFromResourceOp = ({
	parentId,
	parentType,
	selectedRowIds,
	deselectedRowIds,
	closeModal,
	isTotalQtySelected,
}: RemoveDocsFromResourceOpProps): JSX.Element => {
	const [mutRes, removeDocMut] = useRemoveDocsFromRelMutation()

	const { fetching, error } = mutRes
	const removeHandler = async () => {
		try {
			const res = await removeDocMut({
				parentId,
				parentType,
				selectedIds: selectedRowIds,
				deselectedIds: deselectedRowIds,
				isTotalQtySelected,
			})

			if (res.error) throw res.error

			closeModal()
		} catch (err) {
			toastit.err('Error removing docs')
		}
	}
	return (
		<RemoveDocsFromResourceOpView>
			<Heading mBot>Remove Doc?</Heading>
			<P>
				Are you sure you want to remove {isTotalQtySelected ? 'All' : selectedRowIds.length} docs
				from {parentType}?
			</P>
			<div className='cta-btns'>
				{fetching ? <Spinner /> : null}
				{error ? <ErrorBlock error={error} /> : null}
				<Btn isCta onClick={removeHandler}>
					Okay
				</Btn>
				<Btn onClick={closeModal}>Cancel</Btn>
			</div>
		</RemoveDocsFromResourceOpView>
	)
}
