/**
 *  Provides state and handlers for managing sort table by column functionality
 * */

import { useState } from 'react'
import { SortDir, SortOption } from '../../../gql_generated/document_types'

const parseSortFields = (sortField: string) =>
	sortField?.includes(',') ? sortField.split(',') : [sortField]

type TableSortHandlerOptions = {
	sortDir?: SortDir
}
export type TableSortHandler = (sortField: string, options?: TableSortHandlerOptions) => void

export type UseTableSort = {
	sortState: SortOption[]
	sortHandler: TableSortHandler
}

export const useTableSort = (defaultSortOptions?: SortOption[]): UseTableSort => {
	const [sortOptions, setSortOptions] = useState(defaultSortOptions || [])

	const sortHandler: TableSortHandler = (sortField, options) => {
		const { sortDir = SortDir.Asc } = options || {}

		const sortFields = parseSortFields(sortField)

		setSortOptions(sortFields.map(sortField => ({ sortField, sortDir })).reverse())

		// setSortOptions(state => {
		// 	const sortFields = parseSortFields(sortField)
		// 	const sortOpts: SortOption[] = sortFields.map(sortField => ({
		// 		sortField,
		// 		sortDir,
		// 	}))

		// 	let newState = state

		// 	state.forEach(({ sortField }, idx) => {
		// 		if (sortFields.includes(sortField || '')) newState.splice(idx, 1)
		// 	})

		// 	newState = [...newState, ...sortOpts].reverse()

		// 	// console.log('state: ', state, ' || sortFields: ', sortFields, ' || new state: ', newState)

		// 	return newState
		// })
	}

	return {
		sortState: sortOptions,
		sortHandler,
	}
}
