import { Redirect } from '@reach/router'
import React from 'react'
import styled, { css } from 'styled-components'
import * as Yup from 'yup'
import { useLogin } from '../../../auth'
import {
	Board,
	EmailField,
	Form,
	Icon,
	Link,
	PasswordField,
	SubmitBtn,
	useToaster,
} from '../../../Components/UI'
import { useStoreState } from '../../../store'
import { useState } from '../../../utils'
import useErrHandler from '../../../utils/useErrorHandler'

export const loginStyles = css`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	min-height: 90vh;

	.board {
		background: ${props => props.theme.colors.white.val};
		box-shadow: 0 2px 10px ${props => props.theme.colors.black.tint(40).val};
	}
`

const LoginView = styled.div`
	${loginStyles}
`

const schema = Yup.object({
	email: Yup.string().required(),
	password: Yup.string().required(),
})

type FormVals = {
	email: string
	password: string
}

const Login: React.FC<any> = () => {
	const dashPath = useStoreState(state => state.user.dashPath)
	const auth = useLogin()
	const [handleErr] = useErrHandler({ context: 'login page' })
	const toast = useToaster()

	const [redirect, setRedirect] = useState(dashPath, 'redirect')

	const submitHandler = async (vals: FormVals) => {
		try {
			const res = await auth(vals)

			if (res.redirect) return setRedirect(res.redirect)

			toast(`Welcome${res?.dateLastLogin ? ' back' : ''} ${res?.firstName}`, { type: 'ok' })

			setRedirect('/mgr/dash')
		} catch (err: any) {
			handleErr(err)
			return Promise.reject(err)
		}
	}

	if (redirect) console.log('Login Page - redirecting to:', redirect)
	return redirect ? (
		<Redirect to={redirect} noThrow />
	) : (
		<LoginView>
			<Board titleText='Login'>
				<Form
					initialValues={{
						email: '',
						password: '',
					}}
					onSubmit={async (vals, { setSubmitting }) => {
						try {
							await submitHandler(vals as FormVals)
						} catch (err) {
							setSubmitting(false)
						}
					}}
					validationSchema={schema}
				>
					{({ values }) => (
						<>
							<EmailField name='email' placeholder='Email...' />
							<PasswordField name='password' placeholder='Password...' />
							<Link
								to={`/forgot-pass${
									values.email ? `?email=${encodeURIComponent(values.email)}` : ''
								}`}
							>
								Forgot password?
							</Link>
							<SubmitBtn isInverted>
								<Icon type='login' />
								Login
							</SubmitBtn>
							{/* <P margin='1em'>
							<EM>
								<Link to='/forgot-pass'>Forgot password?</Link>
							</EM>
						</P> */}
						</>
					)}
				</Form>
			</Board>
		</LoginView>
	)
}
export default Login
