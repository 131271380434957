import { RouteComponentProps, Router } from '@reach/router'
import React from 'react'
import Protect from '../Components/common/Protect'
import Page404 from '../Pages/404.page'
import AcquisitionPage from '../Pages/Acquisition'
import AcquisitionsPage from '../Pages/Acquisitions'

const AcquisitionRoutes: React.FC<RouteComponentProps> = () => {
	return (
		<Protect>
			<Router>
				<AcquisitionsPage path='/' />
				<AcquisitionPage path='/:id' />
				<Page404 default />
			</Router>
		</Protect>
	)
}
export default AcquisitionRoutes
