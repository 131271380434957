import React from 'react'
import styled, { css } from 'styled-components/macro'
import { relatedParcelsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Parcel.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { ParcelParent } from '../../../gql_generated/document_types'
import { usePaginatedParcelsQuery } from '../../../gql_generated/graphql'
import { RelatedOrgParcelModal } from '../Related.Modals/Org.related.parcel.modal'

export const relatedOrgParcelsStyles = css``

const RelatedOrgParcelsView = styled.div`
	${relatedOrgParcelsStyles}
`

export type RelatedOrgParcelsProps = {
	orgId: number
}
export const RelatedOrgParcels: React.FC<RelatedOrgParcelsProps> = ({ orgId }) => {
	const paginationStore = usePaginationParams()

	const [results] = usePaginatedParcelsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: ParcelParent.Org,
						parentIds: [orgId],
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.parcels

	return (
		<RelatedOrgParcelsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedParcelsColsConfig}
				fetchResults={results}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedOrgParcelModal parcelId={data.id} />}
				includeSearch
				resourceType='parcel'
			/>
		</RelatedOrgParcelsView>
	)
}
