import includes from 'lodash/includes'
import { UserRole } from '../gql_generated/document_types'
import { useStoreState } from '../store'

const useCalcAccess = () => {
	const { role } = useStoreState(state => state.user)

	return (allowed: UserRole[]) => (allowed?.length ? includes(allowed, role || 'noaccess') : true)
}

export default useCalcAccess
