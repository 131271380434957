import toNumber from 'lodash/toNumber'
import React from 'react'
import styled from 'styled-components'
import { useInviteUserMutation } from '../../../gql_generated/graphql'
import { useState } from '../../../utils'
import { Block, Form, P, SubmitBtn, toastit, useToaster } from '../../UI'
import UserFields, { userSchema } from './Ops.User.fields'

const CreateUserView = styled.div``

type CreateUserFormProps = {
	cb?: () => void
}
const CreateUserForm: React.FC<CreateUserFormProps> = ({ cb }) => {
	const toast = useToaster()
	const [_, inviteUser] = useInviteUserMutation()
	const [successContent, setSuccess] = useState<any>(null)
	return (
		<CreateUserView>
			<Form
				legend='Invite New User'
				legendSize='sm'
				initialValues={{
					email: '',
					firstName: '',
					lastName: '',
					roleId: 1,
				}}
				onSubmit={async (vals: any) => {
					const { roleId, ...data } = vals
					try {
						const results = await inviteUser({
							data: {
								...data,
								roleId: toNumber(roleId),
							},
						})

						if (results.error) toastit.err('Error creating user')
						else {
							const tempPw = results.data?.inviteUser?.tempPw

							console.log('tempPw: ', tempPw)
							setSuccess({
								user: vals,
								tempPw,
							})
							toast(`Invitation sent to ${vals.email}`, { type: 'ok' })
							if (cb) cb()
						}
					} catch (err) {
						console.log(err)
					}
				}}
				validationSchema={userSchema}
			>
				{() =>
					successContent ? (
						<Block type='ok'>
							<P>
								An invitation email has been successfully sent to {successContent.user.email} with a
								link to finish setting up their account.
							</P>
							<P>
								Alternatively, account setup can be initiated by logging in with the following
								temporary password:
							</P>
							<pre className='center'>{successContent.tempPw}</pre>
						</Block>
					) : (
						<>
							<UserFields />
							<SubmitBtn>Send Invite</SubmitBtn>
						</>
					)
				}
			</Form>
		</CreateUserView>
	)
}
export default CreateUserForm
