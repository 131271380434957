import React from 'react'
import styled, { css } from 'styled-components/macro'
import { DocDetail } from '../../../Components/common/Doc/Doc.detail'

export const relatedAcquisitionDocModalStyles = css``

const RelatedAcquisitionDocModalView = styled.div`
	${relatedAcquisitionDocModalStyles}
`

export type RelatedAcquisitionDocModalProps = {
	docId: number
}
export const RelatedAcquisitionDocModal: React.FC<RelatedAcquisitionDocModalProps> = ({
	docId,
}) => {
	return (
		<RelatedAcquisitionDocModalView>
			<DocDetail docId={docId} />
		</RelatedAcquisitionDocModalView>
	)
}
