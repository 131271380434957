import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Icon } from '../Icon'
import { IconType } from '../Icon/fa.defaults'
import Badge from './Block.badge'
import Banner from './Block.banner'
import Overlay, { BlockOverlayProps } from './Block.overlay'
import Simple from './Block.simple'

type BlockViewProps = {
	hasHeader?: boolean
	hasIcon?: boolean
	hasBorder?: boolean
}
const BlockView = styled.div<BlockViewProps>`
	text-align: left;
	display: ${props => (!props.hasHeader && props.hasIcon ? 'flex' : 'block')};
	align-items: center;
	border: ${props => (props.hasBorder ? `solid 1px` : 'none')};
	margin-bottom: 1em;
	header {
		display: flex;
		align-items: center;
		padding: 1em;
		/* border-bottom: solid 1px ${props => props.theme.colors.grey.val}; */
		h3 {
			font-size: 1.5em;
			font-weight: 400;
		}
	}
	&.__block-type-err,
	&.__block-type-ok,
	&.__block-type-warn,
	&.__block-type-info {
		& > .icon {
			height: 2em;
			width: auto;
			margin-right: 1em;
			padding-left: ${props => (props.hasBorder ? '0.5em' : '0')};
		}
		p,
		ul,
		h5,
		h6,
		ol {
			color: inherit;
		}
	}

	&.__block-type-err {
		border-color: ${props => props.theme.colors.err.val};
		.icon {
			color: ${props => props.theme.colors.err.val};
		}
		header {
			color: ${props =>
				props.hasHeader ? props.theme.colors.white.val : props.theme.colors.err.val};
			border: none;
			background: ${props => props.theme.colors.err.val};
			.icon {
				color: inherit;
			}
		}
	}
	&.__block-type-ok {
		align-items: flex-start;
		& > .icon {
			color: ${props => props.theme.colors.ok.val};
			height: 4em;
		}
		border-color: ${props => props.theme.colors.ok.val};
		.content-wrap {
			p {
				font-weight: 700;
			}
			.center {
				text-align: center;
			}
		}
	}
	&.__block-type-warn {
		color: ${props => props.theme.colors.warn.val};
		border-color: ${props => props.theme.colors.warn.val};
	}
	&.helper,
	&.__block-type-info {
		color: ${props => props.theme.colors.grey.val};
	}
	.content-wrap {
		${props =>
			props.hasHeader &&
			`
			padding: 1em;
		`}
	}
`

const B: React.FC<
	BlockProps &
		BlockOverlayProps & {
			usage: UsageType
		}
> = ({ usage, bgClose, close, ...props }) => (
	<React.Fragment>
		{usage === 'banner' ? (
			<Banner {...props} />
		) : usage === 'badge' ? (
			<Badge {...props} />
		) : usage === 'overlay' ? (
			<Overlay bgClose={bgClose} close={close} {...props} />
		) : usage === 'simple' ? (
			<Simple {...props} />
		) : null}
	</React.Fragment>
)

type UsageType = 'simple' | 'banner' | 'badge' | 'overlay'

export type BlockOptions = BlockOverlayProps & {
	usage?: UsageType
	type?: 'err' | 'warn' | 'info' | 'ok'
	icon?: IconType
	hasBorder?: boolean
	title?: ReactNode
}

export type BlockProps = {
	hasIcon?: boolean
}

const Block: React.FC<BlockOptions> = ({
	usage = 'simple',
	type,
	icon,
	children,
	hasBorder,
	title,
	...blockProps
}) => {
	const iconType = icon || (type as IconType)
	return (
		<B usage={usage} hasIcon={!!iconType} {...blockProps}>
			<BlockView
				hasIcon={!!iconType}
				hasHeader={!!title}
				className={`block-content __block-type-${type || ''} ${usage}`}
				hasBorder={hasBorder}
			>
				{title ? (
					<header>
						{iconType ? <Icon type={iconType} /> : null}
						<h3>{title}</h3>
					</header>
				) : iconType ? (
					<Icon type={iconType} />
				) : null}
				<div className='content-wrap'>{children}</div>
			</BlockView>
		</B>
	)
}
export default Block
