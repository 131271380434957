import React from 'react'
import styled, { css, DefaultTheme, useTheme } from 'styled-components'

type CSSProps = {
	color: string
}
const CSS = css<CSSProps>``

const Span = styled.span`
	${CSS}
	color: ${props => props.color};
`
const Div = styled.div`
	${CSS}
	color: ${props => props.theme.colors.white.val};
	background-color: ${props => props.color};
	padding: 0.3em 0.5em;
	font-weight: 700;
`

type ColorizeProps = CSSProps & {
	asBlock?: boolean
}

const Colorize: React.FC<ColorizeProps> = ({ color, asBlock, children }) => {
	const { colors }: DefaultTheme = useTheme()

	const swfColor = colors[color]
	const colorVal = swfColor ? swfColor.val : color

	return asBlock ? <Div color={colorVal}>{children}</Div> : <Span color={colorVal}>{children}</Span>
}
export default Colorize
