import React from 'react'
import styled, { css } from 'styled-components/macro'
import { LeaseDetail } from '../../../Components/common/Lease/Lease.detail'

export const relatedParcelLeaseModalStyles = css``

const RelatedParcelLeaseModalView = styled.div`
	${relatedParcelLeaseModalStyles}
`

export type RelatedParcelLeaseModalProps = {
	leaseId: number
}
export const RelatedParcelLeaseModal: React.FC<RelatedParcelLeaseModalProps> = ({ leaseId }) => {
	return (
		<RelatedParcelLeaseModalView>
			<LeaseDetail leaseId={leaseId} />
		</RelatedParcelLeaseModalView>
	)
}
