import React from 'react'
import styled, { css } from 'styled-components/macro'
import { parcelRelatedRoyaltiesColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Royalty.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { RoyaltyParent } from '../../../gql_generated/document_types'
import { usePaginatedRoyaltiesQuery } from '../../../gql_generated/graphql'
import { RelatedParcelRoyaltyModal } from '../RelatedModals/Parcel.related.royalty.modal'

export const relatedParcelRoyaltiesStyles = css``

const RelatedParcelRoyaltiesView = styled.div`
	${relatedParcelRoyaltiesStyles}
`

export type RelatedParcelRoyaltiesProps = {
	parcelId: number
}

export const RelatedParcelRoyalties: React.FC<RelatedParcelRoyaltiesProps> = ({ parcelId }) => {
	const paginationStore = usePaginationParams()

	const [fetchResults] = usePaginatedRoyaltiesQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: RoyaltyParent.Parcel,
						parentIds: [parcelId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.royalties
	return (
		<RelatedParcelRoyaltiesView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={parcelRelatedRoyaltiesColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedParcelRoyaltyModal royaltyId={data.id} />}
				resourceType='royalty'
			/>
		</RelatedParcelRoyaltiesView>
	)
}
