import React from 'react'
import styled, { css } from 'styled-components/macro'
import { LogDetail } from '../../../Components/common/Log/Log.detail'

export const relatedSaleLogModalStyles = css``

const RelatedSaleLogModalView = styled.div`
	${relatedSaleLogModalStyles}
`

export type RelatedSaleLogModalProps = {
	logId: number
}
export const RelatedSaleLogModal: React.FC<RelatedSaleLogModalProps> = ({ logId }) => {
	return (
		<RelatedSaleLogModalView>
			<LogDetail logId={logId} />
		</RelatedSaleLogModalView>
	)
}
