import React from 'react'
import styled, { css } from 'styled-components/macro'
import { relatedLogsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Log.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { LogParent } from '../../../gql_generated/document_types'
import { usePaginatedLogsQuery } from '../../../gql_generated/graphql'
import { RelatedEasementLogModal } from '../Related.Modals/Easement.related.log.modal'

export const relatedEasementLogsStyles = css``

const RelatedEasementLogsView = styled.div`
	${relatedEasementLogsStyles}
`

export type RelatedEasementLogsProps = {
	easementId: number
}

export const RelatedEasementLogs: React.FC<RelatedEasementLogsProps> = ({ easementId }) => {
	const paginationStore = usePaginationParams()

	const [fetchResults] = usePaginatedLogsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: LogParent.Easement,
						parentIds: [easementId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.logs
	return (
		<RelatedEasementLogsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedLogsColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedEasementLogModal logId={data.id} />}
				resourceType='log'
				suppressGoTo
			/>
		</RelatedEasementLogsView>
	)
}
