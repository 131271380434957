import React from 'react'
import styled, { css } from 'styled-components/macro'
import { parcelRelatedAcquisitionsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Acquisition.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { AcquisitionParent } from '../../../gql_generated/document_types'
import { usePaginatedAcquisitionsQuery } from '../../../gql_generated/graphql'
import { RelatedParcelAcquisitionModal } from '../RelatedModals/Parcel.related.acquisition.modal'

export const relatedParcelAcquisitionsStyles = css``

const RelatedParcelAcquisitionsView = styled.div`
	${relatedParcelAcquisitionsStyles}
`

export type RelatedParcelAcquisitionsProps = {
	parcelId: number
}

export const RelatedParcelAcquisitions: React.FC<RelatedParcelAcquisitionsProps> = ({
	parcelId,
}) => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedAcquisitionsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: AcquisitionParent.Parcel,
						parentIds: [parcelId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.acquisitions
	return (
		<RelatedParcelAcquisitionsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={parcelRelatedAcquisitionsColsConfig(parcelId)}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedParcelAcquisitionModal acquisitionId={data.id} />}
				resourceType='acquisition'
			/>
		</RelatedParcelAcquisitionsView>
	)
}
