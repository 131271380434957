import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import Logo from '../../images/NNR-Logo'
import { Link } from '../UI'

const Branding = styled(Link)`
	color: ${props => props.theme.colors.white.val};
	height: 90%;
	svg {
		height: 100%;
		width: auto;
	}
`

const HeaderBranding: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = ({ className }) => {
	return (
		<Branding className={`branding${className ? ` ${className}` : ''}`} to='/mgr/dash'>
			<Logo />
		</Branding>
	)
}

export default HeaderBranding
