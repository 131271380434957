import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Async, Card, KeyVals, P } from '../../../Components/UI'
import { useLeaseTermByIdQuery } from '../../../gql_generated/graphql'

export const leaseTermDetailStyles = css``

const LeaseTermDetailView = styled.div`
	${leaseTermDetailStyles}
`

export type LeaseTermDetailProps = {
	termId: number
}
export const LeaseTermDetail: React.FC<LeaseTermDetailProps> = ({ termId }) => {
	const [results] = useLeaseTermByIdQuery({ variables: { id: termId } })
	const term = results.data?.term
	return (
		<LeaseTermDetailView>
			<Async fetchResults={results}>
				{term ? (
					<>
						<Card mBot noShadow titleText='Lease Terms'>
							<KeyVals
								items={[
									['DB ID', term.id],
									['Effective', term.dateFrom, 'date'],
									['Expiration', term.dateTo, 'date'],
									['Payment Schedule', term.schedForDisplay],
									['Rate Increase', term.rate, 'percentage'],
									['Has Purchase Option?', term.hasPurchaseOption, 'boolean'],
									['Has Continuation Clause?', term.hasContClause, 'boolean'],
								]}
							/>
						</Card>
						<Card noShadow titleText='Term Notes'>
							<P>{term.termNotes}</P>
						</Card>
					</>
				) : null}
			</Async>
		</LeaseTermDetailView>
	)
}
