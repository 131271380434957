import React from 'react'
import styled from 'styled-components'
import { useParcelForOgQuery } from '../../../gql_generated/graphql'
import { KeyVals } from '../../UI'

const ParcelOGDataView = styled.div``

type ParcelOGDataProps = {
	parcelId: number
}
const ParcelOGData: React.FC<ParcelOGDataProps> = ({ parcelId }) => {
	const [results] = useParcelForOgQuery({ variables: { id: parcelId } })
	const parcel = results.data?.parcel
	const { og } = parcel || {}
	const { history, currStat, availStat } = og || {}

	return (
		<ParcelOGDataView>
			<KeyVals
				items={[
					['OG History', history],
					['OG availStat', availStat],
					['OG Current Status', currStat],
				]}
			/>
		</ParcelOGDataView>
	)
}
export default ParcelOGData
