import React from 'react'
import styled, { css } from 'styled-components/macro'
import { docRelatedLeasesColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Lease.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { LeaseParent } from '../../../gql_generated/document_types'
import { usePaginatedLeasesQuery } from '../../../gql_generated/graphql'
import { RelatedDocLeaseModal } from '../Related.Modals/Doc.related.lease.modal'

export const relatedDocLeasesStyles = css``

const RelatedDocLeasesView = styled.div`
	${relatedDocLeasesStyles}
`

export type RelatedDocLeasesProps = {
	docId: number
}

export const RelatedDocLeases: React.FC<RelatedDocLeasesProps> = ({ docId }) => {
	const paginationStore = usePaginationParams()

	const [fetchResults] = usePaginatedLeasesQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: LeaseParent.Doc,
						parentIds: [docId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const leases = data?.page?.leases
	return (
		<RelatedDocLeasesView>
			<ResourcesTable
				dataset={leases}
				colsConfig={docRelatedLeasesColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={lease => <RelatedDocLeaseModal leaseId={lease.id} />}
				resourceType='lease'
			/>
		</RelatedDocLeasesView>
	)
}
