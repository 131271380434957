import styled, { css } from 'styled-components'
import { useOwnedAcresForOwnedAcresPdfQuery } from '../../../gql_generated/graphql'
import { formatDate, formatNumberWithCommas } from '../../../utils'
import { sortBy } from '../../../utils/lodash.utils'
import { Async, P, TablePrint, TablePrintRow } from '../../UI'
import { Heading } from '../../UI/Heading'

const OwnedAcresPdfStyles = css``

const OwnedAcresPdfView = styled.div`
	${OwnedAcresPdfStyles}
`

type OwnedAcresPdfProps = {
	className?: string
}
export const OwnedAcresPdf = ({ className }: OwnedAcresPdfProps): JSX.Element => {
	const [results] = useOwnedAcresForOwnedAcresPdfQuery()
	const counties = sortBy(results.data?.counties, 'county')

	const rows: TablePrintRow[] | undefined = counties
		? [
				{
					rowType: 'header',
					cells: [
						{ val: '' },
						...counties.map(({ county }) => ({ val: county })),
						{ val: 'Total' },
					],
				},
				{
					cells: [
						{ val: 'Land & Minerals' },
						...counties.map(({ land }) => ({ val: formatNumberWithCommas(land) })),
						{
							val: formatNumberWithCommas(counties.reduce((acc, { land }) => acc + land, 0)),
							align: 'right',
							bold: true,
						},
					],
				},
				{
					cells: [
						{ val: 'Mineral Only' },
						...counties.map(({ min }) => ({ val: formatNumberWithCommas(min) })),
						{
							val: formatNumberWithCommas(counties.reduce((acc, { min }) => acc + min, 0)),
							align: 'right',
							bold: true,
						},
					],
				},
				{
					cells: [
						{ val: 'Royalty' },
						...counties.map(({ royalty }) => ({ val: formatNumberWithCommas(royalty) })),
						{
							align: 'right',
							bold: true,
							val: formatNumberWithCommas(counties.reduce((acc, { royalty }) => acc + royalty, 0)),
						},
					],
				},
				{
					rowType: 'footer',
					cells: [
						{ val: 'Totals', bold: true, align: 'right' },
						...counties.map(({ land, min, royalty }) => ({
							bold: true,
							val: formatNumberWithCommas(land + min + royalty),
						})),
						{
							bold: true,
							val: formatNumberWithCommas(
								counties.reduce((acc, { land, min, royalty }) => acc + land + min + royalty, 0)
							),
						},
					],
				},
		  ]
		: undefined

	return (
		<OwnedAcresPdfView className={`owned-acres-pdf${className ? ` className` : ''}`}>
			<Heading>NNR Owned Acres Snapshot</Heading>
			<P>{formatDate(new Date())}</P>
			<Async fetchResults={results}>{rows?.length ? <TablePrint body={{ rows }} /> : null}</Async>
		</OwnedAcresPdfView>
	)
}
