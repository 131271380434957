import React from 'react'
import styled, { css } from 'styled-components/macro'
import { AcquisitionDetail } from '../../../Components/common/Acquisition/Acquisition.detail'

export const relatedOrgAcquisitionModalStyles = css``

const RelatedOrgAcquisitionModalView = styled.div`
	${relatedOrgAcquisitionModalStyles}
`

export type RelatedOrgAcquisitionModalProps = {
	acquisitionId: number
}
export const RelatedOrgAcquisitionModal: React.FC<RelatedOrgAcquisitionModalProps> = ({
	acquisitionId,
}) => {
	return (
		<RelatedOrgAcquisitionModalView>
			<AcquisitionDetail acquisitionId={acquisitionId} />
		</RelatedOrgAcquisitionModalView>
	)
}
