import styled, { css } from 'styled-components'
import NavMgr from '../../Nav/Nav.mgr'
import { Card } from '../../UI'
import { Heading } from '../../UI/Heading'
import { AcresByLeaseTypeDashWidget } from '../DashWidgets/AcresByLeaseType.dash.widget'
import { AcresOwnedByEstateTypeDashWidget } from '../DashWidgets/AcresOwnedByEstateType.dash.widget'
import { ExpiredLeaseDashWidget } from '../DashWidgets/ExpiredLease.dash.widget'
import { OpenSalesDashWidget } from '../DashWidgets/OpenSales.dash.widget'
import { SalesHistoryDashWidget } from '../DashWidgets/SalesHistory.dash.widget'
import { UpcomingLeasesDashWidget } from '../DashWidgets/UpcomingLeases.dash.widget'

const MgrsDashStyles = css`
	padding: 1rem;
	max-width: 100vw;
	display: flex;
	flex-direction: column;
	row-gap: 1rem;

	${props => props.theme.media.tablet} {
		max-width: calc(100vw - 200px);
		flex-direction: row;
		align-items: stretch;
		column-gap: 1rem;
	}

	& > aside {
		display: flex;
		flex-direction: column;
		row-gap: 1rem;

		${props => props.theme.media.tablet} {
			padding-right: 1rem;
			border-right: solid 3px ${props => props.theme.colors.secondary.val};
		}
	}

	& > main {
		display: flex;
		flex-direction: column;
		row-gap: 1rem;

		${props => props.theme.media.tablet} {
			flex-grow: 1;
			max-width: calc(100vw - 650px);
		}
	}

	.sales-widgets {
		& > .widgets {
			display: flex;
			flex-direction: column;
			row-gap: 1rem;

			${props => props.theme.media.tablet} {
				flex-direction: row;
				column-gap: 1rem;
				padding-right: 1rem;

				& > * {
					flex-grow: 1;
					min-width: 50%;
					max-width: 50%;
				}
			}
		}
	}

	.widget {
		width: 100%;
		max-width: 100%;
		min-width: 0;
	}

	.desk-only {
		display: none;
		${props => props.theme.media.sdesk} {
			display: block;
		}
	}
	.mobile-only {
		${props => props.theme.media.sdesk} {
			display: none;
		}
	}
`

const MgrsDashView = styled.div`
	${MgrsDashStyles}
`

type MgrsDashProps = {
	className?: string
}
export const MgrsDash = ({ className }: MgrsDashProps): JSX.Element => {
	return (
		<MgrsDashView className={`mgr-dash${className ? ` className` : ''}`}>
			<aside className='small-widgets'>
				<AcresOwnedByEstateTypeDashWidget />
				<AcresByLeaseTypeDashWidget />
			</aside>
			<main className='big-widgets'>
				<Card>
					<div className='sales-widgets'>
						<div className='widgets'>
							<SalesHistoryDashWidget />
							<OpenSalesDashWidget />
						</div>
					</div>
				</Card>
				<UpcomingLeasesDashWidget />
				<ExpiredLeaseDashWidget />
			</main>
			<div className='mobile-only'>
				<Heading mBot color='white'>
					Where would you like to go?
				</Heading>
				<NavMgr displayType='in-dash' />
			</div>
		</MgrsDashView>
	)
}
