import React from 'react'
import styled from 'styled-components'

export const GTableHeaderRowView = styled.div``

export type GTableHeaderRowProps = {}
export const GTableHeaderRow: React.FC<GTableHeaderRowProps> = ({ children }) => {
	return (
		<GTableHeaderRowView className='g-table-row g-table-header-row'>{children}</GTableHeaderRowView>
	)
}
