import React from 'react'
import styled from 'styled-components'
import { Lease } from '../../../gql_generated/document_types'
import { useLeaseForFinancialInfoQuery } from '../../../gql_generated/graphql'
import {
	calcIsSinglePayment,
	calcLeasePaymentDate,
	getCurrentLeaseFee,
	getCurrentLeaseTerms,
} from '../../../utils'
import { Btn, Card, KeyVals, Modal, P, PrintWindow, useModalCtrl } from '../../UI'
import { KeyValItem } from '../../UI/KeyVals/KeyVals'
import { LeaseTaxReimbrsPDF } from '../Lease.TaxReimbrs.pdf'

const LeaseFinancialView = styled.div`
	.tax-reimburse {
		display: flex;

		.label {
			color: ${props => props.theme.colors.grey.val};
			margin-right: 0.5rem;
			white-space: nowrap;
		}

		.tax-report-btn {
			padding: 0;
			display: inline;
			margin: 0;
			line-height: 1rem;
		}
	}
`

type LeaseFinancialProps = {
	leaseId: number
}

const LeaseFinancial: React.FC<LeaseFinancialProps> = ({ leaseId }) => {
	const [results] = useLeaseForFinancialInfoQuery({ variables: { id: leaseId } })
	const lease = results.data?.lease

	const term = getCurrentLeaseTerms(lease)
	const fee = getCurrentLeaseFee(lease)

	const {
		schedAmount,
		schedInterval,
		hasContClause,
		hasPurchaseOption,
		rate,
		termNotes,
		hasTaxReimbursements,
	} = term || {}

	const isSinglePayment = calcIsSinglePayment(lease)

	const [[showTaxReport, openTaxReport, closeTaxReport]] = useModalCtrl()

	const items: KeyValItem[] = [
		[
			'Payment Schedule',
			isSinglePayment ? 'Single Payment' : `Every ${schedAmount} ${schedInterval}`,
		],
		[
			'Next Payment Date',
			term && !isSinglePayment ? calcLeasePaymentDate({ term, hasRenewal: lease?.canRenew }) : null,
		],
		['Next Payment Fee', fee.amount, 'currency'],
		['Rate Increase', `${rate}%`],
		['Option to Purchase?', hasPurchaseOption, 'boolean'],
		['Has Continuation Clause?', hasContClause, 'boolean'],
		// ['Notify on Payment Date', 'TODO'],
	]

	if (!hasTaxReimbursements) items.push(['Tax Reimbursements', 'None'])

	return (
		<LeaseFinancialView>
			<KeyVals items={items} />

			{hasTaxReimbursements ? (
				<div className='tax-reimburse'>
					<span className='label'>Tax Reimbursements:</span>
					<Btn className='tax-report-btn' onClick={openTaxReport}>
						Generate Report
					</Btn>
				</div>
			) : null}

			<Card noShadow titleText='Current Term Notes' mTop headerBgColor='light' corners={false}>
				<P asRT>{termNotes}</P>
			</Card>
			<Modal show={showTaxReport} closeHandler={closeTaxReport}>
				{lease ? (
					<PrintWindow
						margin='0.5in'
						PDF={<LeaseTaxReimbrsPDF config={{}} lease={lease as Lease} />}
						fileName={`${lease?.lessee?.name}.tax-reimbursements.${new Date().getFullYear()}`}
						cb={closeTaxReport}
					/>
				) : null}
			</Modal>
		</LeaseFinancialView>
	)
}
export default LeaseFinancial
