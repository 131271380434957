import replace from 'lodash/replace'
import { Icon } from '../Icon'
import { ExternalLink } from './Link'

const parsePhoneNumber = (phone: string) => replace(phone, /\D/g, '')

type TelProps = {
	className?: string
	withIcon?: boolean
	children: string
}

const Tel: React.FC<TelProps> = ({ children: phone, className = null, withIcon = false }) => {
	return (
		<ExternalLink
			className={`link phone-link${className ? ` ${className}` : ''}`}
			href={`tel:${parsePhoneNumber(phone)}`}
		>
			{withIcon ? <Icon type='phone' /> : null}
			{phone}
		</ExternalLink>
	)
}
export default Tel
