import { RouteComponentProps } from '@reach/router'
import React from 'react'
import CardsSection from '../../Components/common/CardsSection'
import { GenLogModal } from '../../Components/common/Log/Log.gen.modal'
import Page from '../../Components/common/Resources/Resource.page'
import { Card } from '../../Components/UI'
import { logsPageColsConfig } from '../../Components/UI/Table.resources/Table.resource.col.configs/Log.col.configs'
import { ResourcesTable } from '../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../Components/UI/Table.resources/usePaginationParams'
import { usePaginatedLogsQuery } from '../../gql_generated/graphql'
import { usePageData } from '../../utils'

type LogsPageProps = RouteComponentProps & {}
const LogsPage: React.FC<LogsPageProps> = () => {
	usePageData({ icon: 'logs', pageTitle: 'Activity Logs' })

	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedLogsQuery({
		variables: {
			params: {
				...paginationStore.params,
			},
		},
	})
	const logs = fetchResults.data?.page.logs

	return (
		<Page isLoaded>
			<CardsSection>
				<Card>
					<ResourcesTable
						dataset={logs}
						colsConfig={logsPageColsConfig}
						fetchResults={fetchResults}
						paginationStore={paginationStore}
						includeSearch
						modalRenderFunc={({ id }) => <GenLogModal logId={id} />}
						suppressGoTo
					/>
				</Card>
			</CardsSection>
		</Page>
	)
}
export default LogsPage
