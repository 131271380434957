import React from 'react'
import styled, { css } from 'styled-components/macro'
import { EasementDetail } from '../../../Components/common/Easement/Easement.detail'

export const relatedParcelEasementModalStyles = css``

const RelatedParcelEasementModalView = styled.div`
	${relatedParcelEasementModalStyles}
`

export type RelatedParcelEasementModalProps = {
	easementId: number
}
export const RelatedParcelEasementModal: React.FC<RelatedParcelEasementModalProps> = ({
	easementId,
}) => {
	return (
		<RelatedParcelEasementModalView>
			<EasementDetail easementId={easementId} />
		</RelatedParcelEasementModalView>
	)
}
