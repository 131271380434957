import startCase from 'lodash/startCase'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { LeaseType, useCreateLeaseTypeMutation } from '../../../gql_generated/graphql'
import { useState } from '../../../utils'
import { ErrorBlock, Form, SubmitBtn, Text } from '../../UI'

const CreateLeaseTypeView = styled.div``

const schema = Yup.object().shape({
	name: Yup.string().required('Please enter a new type'),
})

type CreateLeaseTypeProps = {
	initialName?: string
	cb?: (leaseType: Pick<LeaseType, 'id' | 'name'>) => void
}
const CreateLeaseType: React.FC<CreateLeaseTypeProps> = ({ cb, initialName }) => {
	const [error, setErr] = useState<any | null>(null, 'error')
	const [_, createLeaseType] = useCreateLeaseTypeMutation()

	const handler = async ({ name }: any) => {
		try {
			const res = await createLeaseType({ name: startCase(name) })
			console.log('finished with: ', res)
			if (cb) cb(res.data?.createLeaseType as any)
		} catch (err) {
			setErr(err as any)
		}
	}
	return (
		<CreateLeaseTypeView>
			<Form
				legend='Create lease Type'
				legendSize='sm'
				initialValues={{
					name: initialName || '',
				}}
				onSubmit={handler}
				validationSchema={schema}
			>
				{() => (
					<>
						{error ? <ErrorBlock error={error} /> : null}
						<Text name='name' label='New lease type' isReq />
						<SubmitBtn />
					</>
				)}
			</Form>
		</CreateLeaseTypeView>
	)
}
export default CreateLeaseType
