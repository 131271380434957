import React from 'react'
import CurrencyInput from 'react-currency-input-field'
import toNumber from 'lodash/toNumber'
import endsWith from 'lodash/endsWith'

import FormFieldWrap, { FormFieldWrapProps } from './Form.field.wrap'
import { InputProps } from './Input'
import { useField } from 'formik'
import styled from 'styled-components'

const View = styled.div``

const PercentageField: React.FC<InputProps & FormFieldWrapProps> = ({
	label,
	name,
	helperTxt,
	...props
}) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [{ value }, __, { setValue }] = useField({ name, ...props })
	return (
		<FormFieldWrap type='percentage' label={label} name={name} helperTxt={helperTxt}>
			<View>
				<CurrencyInput
					name={name}
					suffix='%'
					value={`${value}`}
					allowDecimals
					decimalsLimit={6}
					onValueChange={value => setValue(endsWith(value, '.') ? value : toNumber(value))}
					max={100}
				/>
			</View>
		</FormFieldWrap>
	)
}
export default PercentageField
