import React from 'react'
import styled from 'styled-components'
import { Org, usePaginatedOrgsQuery } from '../../../gql_generated/graphql'
import { useState } from '../../../utils'
import { Btn, HR, Modal } from '../../UI'
import { useModalCtrl } from '../../UI/Modal'
import { orgsSimpleColsConfig } from '../../UI/Table.resources/Table.resource.col.configs/Org.col.configs'
import { ResourcesTable } from '../../UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../UI/Table.resources/usePaginationParams'
import CreateOrg from './Ops.Org.create'
import EditOrg from './Ops.Org.edit'

const OrgModalView = styled.div`
	margin-bottom: 3em;
	${props => props.theme.media.tablet} {
		min-width: 70vw;
		padding: 0 5vw;
	}
	${props => props.theme.media.sdesk} {
		padding: 0 15vw;
	}
`

type SelectOrgTableProps = {
	cb: (org: Org) => void
}

const SelectOrgTable: React.FC<SelectOrgTableProps> = ({ cb }) => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedOrgsQuery({
		variables: { params: paginationStore.params },
	})

	const [[showModal, openModal, closeModal, selectedOrgId]] = useModalCtrl<number>()

	const dataset = fetchResults.data?.page?.orgs

	return (
		<>
			<ResourcesTable
				dataset={dataset}
				colsConfig={orgsSimpleColsConfig}
				paginationStore={paginationStore}
				fetchResults={fetchResults}
				includeSearch
				onClickRow={org => cb(org as Org)}
				rowOps={[
					{
						icon: 'edit',
						op: org => {
							openModal(org.id)
						},
					},
				]}
			/>
			{/* <ResourceTable
				cols={[{ field: 'name' }]}
				data={data}
				error={error}
				loading={fetching}
				limit={limit}
				search={setSearch}
				type='orgs'
				clickHandler={cb}
				rowOps={[
					{
						icon: 'edit',
						op: (id: number) => {
							setEditOrgId(id)
						},
					},
				]}
			/> */}
			<Modal show={showModal} closeHandler={closeModal} titleText='Edit Org'>
				{selectedOrgId ? (
					<OrgModalView>
						<EditOrg id={selectedOrgId} cb={closeModal} />
					</OrgModalView>
				) : null}
			</Modal>
		</>
	)
}

type SelectOrgModalProps = SelectOrgTableProps & {
	show?: boolean
	closeHandler?: () => void
}

export const SelectOrgModal: React.FC<SelectOrgModalProps> = ({ cb, show, closeHandler }) => {
	const [showModal, setShow] = useState(true, 'showModal')
	const [showCreateModal, setCreateModal] = useState(false, 'showCreateModal')

	const selectHandler = (org: Org) => {
		if (showModal) setShow(false)
		if (showCreateModal) setCreateModal(false)
		cb(org)
	}

	return (
		<>
			<Modal
				titleText='Select Org...'
				show={typeof show === 'boolean' ? show : showModal}
				closeHandler={() => (typeof closeHandler === 'function' ? closeHandler() : setShow(false))}
			>
				<SelectOrgTable cb={selectHandler} />
				<HR />
				<Btn onClick={() => setCreateModal(true)}>Create New Org</Btn>
			</Modal>
			<Modal
				titleText='Create Org'
				show={showCreateModal}
				closeHandler={() => setCreateModal(false)}
			>
				<OrgModalView>
					<CreateOrg cb={(res: any) => selectHandler(res.data?.createOrg as Org)} />
				</OrgModalView>
			</Modal>
		</>
	)
}

export default SelectOrgModal
