import React from 'react'
import styled, { css } from 'styled-components/macro'
import EasementInfo from '../../../Components/common/Easement/Easement.info'
import { Async, Card, P } from '../../../Components/UI'
import { useEasementFullQuery } from '../../../gql_generated/graphql'

export const easementDetailStyles = css``

const EasementDetailView = styled.div`
	${easementDetailStyles}
`

export type EasementDetailProps = {
	easementId: number
}
export const EasementDetail: React.FC<EasementDetailProps> = ({ easementId }) => {
	const [results] = useEasementFullQuery({ variables: { id: easementId } })
	const easement = results.data?.easement
	const { notes } = easement || {}

	return (
		<EasementDetailView>
			<Async fetchResults={results}>
				{easement ? (
					<>
						<Card mBot noShadow>
							<EasementInfo easementId={easementId} />
						</Card>
						<Card noShadow titleText='Notes'>
							<P asRT>{notes}</P>
						</Card>
					</>
				) : null}
			</Async>
		</EasementDetailView>
	)
}
