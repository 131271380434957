import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import {
	Agreement,
	AgreementUpdateStatusInput,
	useUpdateAgreementStatusMutation,
} from '../../../gql_generated/graphql'
import { formatDate, parseDateForServer } from '../../../utils'
import { Form, SubmitBtn, Text } from '../../UI'

const OpTerminateAgreementView = styled.div``

const schema = Yup.object().shape({
	dateTerminated: Yup.string().required('Please enter a date for termination'),
})

type OpTerminateAgreementProps = {
	agreement: Agreement
	cb?: () => void
}
const OpTerminateAgreement: React.FC<OpTerminateAgreementProps> = ({ agreement, cb }) => {
	const { id } = agreement
	const [_, updateStatus] = useUpdateAgreementStatusMutation()

	return (
		<OpTerminateAgreementView>
			<Form
				legend='Terminate Agreement'
				initialValues={{
					dateTerminated: formatDate(new Date(), 'input'),
				}}
				onSubmit={async (data: AgreementUpdateStatusInput) => {
					try {
						await updateStatus({
							data: {
								id,
								dateTerminated: parseDateForServer(data.dateTerminated),
							},
						})
						if (cb) cb()
					} catch (err) {}
				}}
				validationSchema={schema}
			>
				{() => (
					<>
						<Text type='date' name='dateTerminated' label='Date Terminated' isReq />
						<SubmitBtn>Terminate Agreement</SubmitBtn>
					</>
				)}
			</Form>
		</OpTerminateAgreementView>
	)
}
export default OpTerminateAgreement
