import React from 'react'
import styled, { css } from 'styled-components/macro'
import Protect from '../../../Components/common/Protect'
import { AddParcelsToResourceBtn } from '../../../Components/Ops/Ops.Parcels.addToResource'
import { LeftBar } from '../../../Components/UI'
import { AddParcelEstatesToAcquisitionOp } from '../../../Components/UI/Table.resources/Table.global.ops/AddParcelEstateTypesToAcquisition.op'
import { RemoveParcelEstatesFromSaleAcquisitionOp } from '../../../Components/UI/Table.resources/Table.global.ops/RemoveParcelEstateTypesFromSaleAcquisition.op'
import { RemoveRelatedParcelsOp } from '../../../Components/UI/Table.resources/Table.global.ops/RemoveParcelsFromRelated.op'

import { acquisitionRelatedParcelsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Parcel.col.configs'
import {
	ResourcesTable,
	ResourceTableGlobalOpConfig,
} from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { ParcelParent } from '../../../gql_generated/document_types'
import {
	SaleStatus,
	useAcquisitionForStatusQuery,
	usePaginatedParcelsQuery,
} from '../../../gql_generated/graphql'
import { PrimaryResourceType } from '../../../Types'
import { nnrMgrs } from '../../../utils'
import { RelatedAcquisitionParcelModal } from '../Related.Modals/Acquisition.related.parcel.modal'

export const relatedAcquisitionParcelStyles = css``

const RelatedAcquisitionParcelsView = styled.div`
	${relatedAcquisitionParcelStyles}
`

export type RelatedAcquisitionParcelsProps = {
	acquisitionId: number
}
export const RelatedAcquisitionParcels: React.FC<RelatedAcquisitionParcelsProps> = ({
	acquisitionId,
}) => {
	const [statusResults] = useAcquisitionForStatusQuery({ variables: { id: acquisitionId } })

	const status = statusResults.data?.acquisition?.status
	const canEdit = !!status && status !== SaleStatus.Closed

	const paginationStore = usePaginationParams()

	const [results] = usePaginatedParcelsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: ParcelParent.Acquisition,
						parentIds: [acquisitionId],
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.parcels

	const parentProps = {
		parentId: acquisitionId,
		parentType: 'acquisition' as PrimaryResourceType,
	}
	const tableOps: ResourceTableGlobalOpConfig[] = [
		{
			txt: 'Remove',
			op: props => <RemoveRelatedParcelsOp {...props} {...parentProps} />,
		},
		{
			txt: 'Add Estate Types',
			op: props => <AddParcelEstatesToAcquisitionOp {...props} {...parentProps} />,
		},
		{
			txt: 'Remove Estate Types',
			op: props => <RemoveParcelEstatesFromSaleAcquisitionOp {...props} {...parentProps} />,
		},
	]
	return (
		<RelatedAcquisitionParcelsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={acquisitionRelatedParcelsColsConfig(acquisitionId)}
				fetchResults={results}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedAcquisitionParcelModal parcelId={data.id} />}
				resourceType='parcel'
				tableOps={canEdit ? tableOps : undefined}
			>
				<Protect allowed={nnrMgrs}>
					{canEdit ? (
						<LeftBar>
							<AddParcelsToResourceBtn
								config={{
									parentId: acquisitionId,
									parentType: 'acquisition',
								}}
							/>
						</LeftBar>
					) : null}
				</Protect>
			</ResourcesTable>
		</RelatedAcquisitionParcelsView>
	)
}
