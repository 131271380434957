import React from 'react'
import styled, { css } from 'styled-components/macro'
import { relatedLogsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Log.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { LogParent } from '../../../gql_generated/document_types'
import { usePaginatedLogsQuery } from '../../../gql_generated/graphql'
import { RelatedOrgLogModal } from '../Related.Modals/Org.related.log.modal'

export const relatedOrgLogsStyles = css``

const RelatedOrgLogsView = styled.div`
	${relatedOrgLogsStyles}
`

export type RelatedOrgLogsProps = {
	orgId: number
}

export const RelatedOrgLogs: React.FC<RelatedOrgLogsProps> = ({ orgId }) => {
	const paginationStore = usePaginationParams()

	const [fetchResults] = usePaginatedLogsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: LogParent.Org,
						parentIds: [orgId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.logs
	return (
		<RelatedOrgLogsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedLogsColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedOrgLogModal logId={data.id} />}
				resourceType='log'
			/>
		</RelatedOrgLogsView>
	)
}
