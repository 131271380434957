import React from 'react'
import styled, { css } from 'styled-components/macro'
import { EstateTypeEnum } from '../../../Types'
import { P } from '../../UI'
import EstateTypeBtn from '../Estate/Estate.type.btn'

export const editEstateActivitySelectEstateTypeStyles = css`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 200px;
	.icons {
		display: flex;
		align-items: center;
		padding-top: 1em;
	}
	.icon {
		height: 40px;
	}
`

const EditEstateActivitySelectEstateTypeView = styled.div`
	${editEstateActivitySelectEstateTypeStyles}
`

export type EditEstateActivitySelectEstateTypeProps = {
	estateTypes: EstateTypeEnum[]
	onSelect: React.Dispatch<React.SetStateAction<EstateTypeEnum | null>>
}
export const EditEstateActivitySelectEstateType: React.FC<EditEstateActivitySelectEstateTypeProps> = ({
	estateTypes,
	onSelect,
}) => {
	return (
		<EditEstateActivitySelectEstateTypeView>
			<P>Which estate would you like to edit?</P>
			<div className='icons'>
				{estateTypes.map(type => (
					<EstateTypeBtn key={type} type={type} onClick={() => onSelect(type)} />
				))}
			</div>
		</EditEstateActivitySelectEstateTypeView>
	)
}
