import styled, { css } from 'styled-components'
import { APP_VERSION } from '../../app.config'

const AppVersionStyles = css`
	color: ${props => props.theme.colors.white.dark().val};
	padding: 0.5rem 1rem;
	font-size: 0.7rem;
	width: 100%;
`

const AppVersionView = styled.div`
	${AppVersionStyles}
`

type AppVersionProps = {
	className?: string
}
export const AppVersion = ({ className }: AppVersionProps): JSX.Element => {
	return (
		<AppVersionView className={`app-version${className ? ` className` : ''}`}>
			Version {APP_VERSION}
		</AppVersionView>
	)
}
