import { createContext, ReactNode, Reducer, useContext, useEffect, useReducer } from 'react'

type WindowState = {
	width: number | undefined
	height: number | undefined

	isMobile: boolean
	isTablet: boolean
	isSdesk: boolean
	isLdesk: boolean
}

const calcScreenBools = (): Pick<WindowState, 'isMobile' | 'isTablet' | 'isSdesk' | 'isLdesk'> => {
	const width = window.innerWidth

	// Corresponds to breaks in eclo mode
	const tabletWidth = 767
	const sdeskWidth = 1112
	const ldeskWidth = 1480

	return {
		isMobile: width < tabletWidth,
		isTablet: width >= tabletWidth && width < sdeskWidth,
		isSdesk: width >= sdeskWidth,
		isLdesk: width >= ldeskWidth,
	}
}

const initialCtx: WindowState = {
	width: 0,
	height: 0,

	isMobile: false,
	isTablet: false,
	isSdesk: false,
	isLdesk: false,
}

const context = createContext(initialCtx)

const Provider = context.Provider

export const useWindow = () => useContext(context)

type DimensionsReducerState = WindowState
const dimensionsReducer: Reducer<DimensionsReducerState, boolean> = (state, shouldUpdate) =>
	shouldUpdate
		? {
				width: window.innerWidth,
				height: window.innerHeight,
				...calcScreenBools(),
		  }
		: state

type WindowProviderProps = {
	children: ReactNode
}
export const WindowProvider = ({ children }: WindowProviderProps): JSX.Element => {
	const [dimensions, dispatch] = useReducer(dimensionsReducer, initialCtx)

	useEffect(() => {
		const updateDimensions = () => dispatch(true)

		updateDimensions() // set initial dimensions
		window.addEventListener('resize', updateDimensions)

		return () => {
			window.removeEventListener('resize', updateDimensions)
		}
	}, [])

	return (
		<Provider
			value={{
				...dimensions,
			}}
		>
			{children}
		</Provider>
	)
}
