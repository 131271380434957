import styled from 'styled-components'
import { BlockProps } from './Block'

export default styled.div<BlockProps>`
	background: ${props => props.color || props.theme.colors.grey.val};
	color: ${props => props.theme.colors.white.val};
	display: ${props => (props.hasIcon ? 'inline-flex' : 'inline-block')};
	width: auto;
	&.err {
		background: ${props => props.theme.colors.err.val};
	}
	&.ok {
		background: ${props => props.theme.colors.ok.val};
	}
	&.warn {
		background: ${props => props.theme.colors.warn.val};
	}
`
