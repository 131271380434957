import React from 'react'
import styled from 'styled-components'
import { DocInputs, DocRelInputs, useCreateDocMutation } from '../../../gql_generated/graphql'
import { ParentType } from '../../../Types'
import { defaultDocVals } from '../../../utils'
import { Form, SubmitBtn } from '../../UI'
import { docYupSchema, OpsDocFields } from './Ops.Doc.fields'

export const OpsDocCreateView = styled.div`
	.create-doc-fields {
		max-width: 600px;
		margin: 0 auto;
		padding: 2em 0;
	}
`

export type OpsDocCreateProps = {
	cb?: (docId: number) => void
	parentId?: number
	parentType?: ParentType
	submitBtnTxt?: string
}
export const OpsDocCreate: React.FC<OpsDocCreateProps> = ({
	cb,
	parentId,
	parentType,
	submitBtnTxt,
}) => {
	const [{ error }, createDoc] = useCreateDocMutation()
	const createDocHandler = async (vals: DocInputs) => {
		const data = {
			...vals,
		}

		if (!data.book) data.book = null
		if (!data.page) data.page = null
		if (!data.mediaId) data.mediaId = null
		if (!data.dateRecorded) data.dateRecorded = null

		if (parentId && parentType) {
			const relData: DocRelInputs = {}
			if (parentType === 'sale') relData.saleId = parentId
			else if (parentType === 'acquisition') relData.acquisitionId = parentId
			else if (parentType === 'lease') relData.leaseId = parentId
			else if (parentType === 'agreement') relData.agreementId = parentId
			else if (parentType === 'easement') relData.easementId = parentId

			data.relatedResources = [relData]
		}

		if (!data.isRecDoc) {
			data.dateRecorded = null
			data.recDocId = null
			data.book = null
			data.county = null
			data.page = null
			data.state = null
		}

		try {
			const results = await createDoc({
				data,
			})

			if (cb && results?.data) cb(results.data.createDoc.id)
		} catch (err) {}
	}

	return (
		<OpsDocCreateView>
			<Form
				submitErr={error}
				legend='Create Doc'
				initialValues={defaultDocVals}
				onSubmit={createDocHandler}
				validationSchema={docYupSchema}
			>
				{() => (
					<div className='create-doc-fields'>
						<OpsDocFields isCreate />

						<SubmitBtn>{submitBtnTxt || 'Save Doc'}</SubmitBtn>
					</div>
				)}
			</Form>
		</OpsDocCreateView>
	)
}
