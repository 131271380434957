import React from 'react'
import styled, { css } from 'styled-components/macro'
import { ContactDetail } from '../../../Components/common/Org/Contact.detail'

export const relatedOrgContactModalStyles = css``

const RelatedOrgContactModalView = styled.div`
	${relatedOrgContactModalStyles}
`

export type RelatedOrgContactModalProps = {
	contactId: number
}
export const RelatedOrgContactModal: React.FC<RelatedOrgContactModalProps> = ({ contactId }) => {
	return (
		<RelatedOrgContactModalView>
			<ContactDetail contactId={contactId} />
		</RelatedOrgContactModalView>
	)
}
