import React from 'react'
import { TablePrintRow } from './Table.row.print'

export type TablePrintCell = {
	val: string | number
	isMultiLine?: boolean
	bold?: boolean
	align?: 'left' | 'right' | 'center'
	italic?: boolean
}

type TableCellPrintProps = {
	rowType: TablePrintRow['rowType']
	cell: TablePrintCell
}
const TableCellPrint: React.FC<TableCellPrintProps> = ({ cell, rowType }) => {
	const { val, isMultiLine, bold, align, italic } = cell
	const styles =
		bold || align || italic
			? {
					fontWeight: bold ? 700 : undefined,
					textAlign: align,
					fontStyle: italic ? 'italic' : undefined,
			  }
			: {}
	return (
		<td
			style={styles}
			className={`${isMultiLine ? `__multi-line` : ''} __row-type-${rowType}`}
		>{`${val}`}</td>
	)
}
export default TableCellPrint
