import React from 'react'
import styled from 'styled-components'
import { Lease, useCreateLeaseFeeMutation } from '../../../gql_generated/graphql'
import { formatCurrency, getCurrentLeaseFee } from '../../../utils'
import { Form, P, PriceField, SubmitBtn } from '../../UI'

const LeaseFeeView = styled.div``

type LeaseFeeProps = {
	lease: Lease
	cb: () => void
}
const LeaseFee: React.FC<LeaseFeeProps> = ({ lease, cb }) => {
	const [_, createFee] = useCreateLeaseFeeMutation()
	const { amount } = getCurrentLeaseFee(lease)

	const submitHandler = async (data: any) => {
		try {
			await createFee({ data })
			if (cb) cb()
		} catch (err) {
			// sdfkj
		}
	}
	return (
		<LeaseFeeView>
			<Form
				legend='Create New Fee'
				initialValues={{
					amount: 0,
					leaseId: lease.id,
				}}
				onSubmit={submitHandler}
			>
				{() => (
					<>
						{amount ? <P>Current Fee: {formatCurrency(amount)}</P> : null}
						<PriceField name='amount' decimals={3} label='Fee Amount' isReq />
						<SubmitBtn>Save</SubmitBtn>
					</>
				)}
			</Form>
		</LeaseFeeView>
	)
}
export default LeaseFee
