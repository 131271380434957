import React, { HtmlHTMLAttributes } from 'react'
import styled from 'styled-components'
import { UserCard } from '../common/User.card'

import { Icon, Link } from '../UI'

const Menu = styled.div`
	border-top: solid 1px ${props => props.theme.colors.grey.light().val};
	display: flex;
	align-items: stretch;
	padding: 2em 0;
	background: ${props => props.theme.colors.white.val};
	.icon.user {
		color: ${props => props.theme.colors.grey.light().val};
		height: 75px;
		width: 75px;
		padding: 0 0.5em;
	}
	nav {
		list-style: none;
	}
	.links {
		padding: 0;
		flex-direction: column;
		justify-content: center;
		li {
			margin-bottom: 1em;
			&:last-child {
				margin: 0;
			}
		}
		.link {
			padding: 0;
			display: flex;
			align-items: center;
			color: ${props => props.theme.colors.btn.val};
		}
	}
`

const LoggedInNav: React.FC<HtmlHTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
	return (
		<Menu className={`logged-in-menu flex${className ? ` ${className}` : ''}`} {...props}>
			<Icon type='user' />

			<div className='right'>
				<UserCard />
				<nav className='links flex'>
					{/* <li>
					<Link to='/account'>
						<Icon type='edit' />
						Account Settings
					</Link>
				</li> */}
					<li>
						<Link to='/logout'>
							<Icon type='logout' padRight />
							Logout
						</Link>
					</li>
				</nav>
			</div>
		</Menu>
	)
}
export default LoggedInNav
