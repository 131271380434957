import styled, { css } from 'styled-components'
import { useParcelsForPropertyTaxReportQuery } from '../../../gql_generated/graphql'
import { formatNumberWithCommas } from '../../../utils'
import { TablePrint } from '../../UI'
import { Heading } from '../../UI/Heading'
import {
	generatePropertyTaxReportPrintRows,
	PropertyTaxesReportPrintRows,
} from './PropertyTaxes.pdf.utils'

export const PropertyTaxesPDFStyles = css`
	width: 100%;
	max-width: 600px;
	margin: 0 auto;

	.titles {
		text-align: center;
	}

	table {
		max-width: 300px;
		margin: 0 auto;
	}

	.footer-totals {
		display: flex;
		align-items: baseline;
		justify-content: flex-end;
		padding: 1em;
		margin-top: 1em;
		border-top: solid 1px ${props => props.theme.colors.slate.val};

		.parcel-total {
			padding: 0 1em;
		}

		.amount-total {
			padding: 0.5em 1em;
			border: solid 1px ${props => props.theme.colors.grey.val};
		}
	}
`

const PropertyTaxesPDFView = styled.div`
	${PropertyTaxesPDFStyles}
`

export type PropertyTaxesPDFProps = {
	county: string
	includeUnowned: boolean
}
export const PropertyTaxesPDF = ({
	county,
	includeUnowned,
}: PropertyTaxesPDFProps): JSX.Element => {
	const [res] = useParcelsForPropertyTaxReportQuery({
		variables: { county, onlyOwnedSrf: !includeUnowned },
		pause: !county,
	})
	const year = new Date().getFullYear()

	const parcels = !includeUnowned
		? res.data?.parcels
		: res.data?.parcels?.filter(({ currentPropertyTax }) => !!currentPropertyTax?.amount)
	const { rows, total = 0 } = parcels
		? generatePropertyTaxReportPrintRows({ parcels })
		: ({} as PropertyTaxesReportPrintRows)

	return (
		<PropertyTaxesPDFView>
			<div className='titles'>
				<Heading>{county} County</Heading>
				<p>
					{year}-{year + 1} Property Taxes
				</p>
			</div>
			{rows ? (
				<>
					<TablePrint
						body={{
							rows,
						}}
					/>
					<div className='footer-totals'>
						<div className='parcel-total'>
							Total {county} County Parcels: {parcels?.length ?? 0}
						</div>
						<div className='amount-total'>Total ${formatNumberWithCommas(total, 2)}</div>
					</div>
				</>
			) : null}
		</PropertyTaxesPDFView>
	)
}
