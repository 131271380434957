import { css } from 'styled-components'
/* @ts-ignore */
import font900 from './Lato-Black.ttf'
/* @ts-ignore */
import font700 from './Lato-Bold.ttf'
/* @ts-ignore */
import font300 from './Lato-Light.ttf'
/* @ts-ignore */
import font400 from './Lato-Regular.ttf'
/* @ts-ignore */
import font100 from './Lato-Thin.ttf'

const fonts = css`
	@font-face {
		font-family: 'Lato';
		src: url(${font100}) format('truetype');
		font-weight: 100;
		font-style: normal;
	}
	@font-face {
		font-family: 'Lato';
		src: url(${font300}) format('truetype');
		font-weight: 300;
		font-style: normal;
	}
	@font-face {
		font-family: 'Lato';
		src: url(${font400}) format('truetype');
		font-weight: 400;
		font-style: normal;
	}
	@font-face {
		font-family: 'Lato';
		src: url(${font700}) format('truetype');
		font-weight: 700;
		font-style: normal;
	}
	@font-face {
		font-family: 'Lato';
		src: url(${font900}) format('truetype');
		font-weight: 900;
		font-style: normal;
	}
`

export default fonts
