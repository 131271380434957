import styled, { css } from 'styled-components'
import { relatedOrgColsConfig } from '../../../../Components/UI/Table.resources/Table.resource.col.configs/Org.col.configs'
import { ResourcesTable } from '../../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../../Components/UI/Table.resources/usePaginationParams'
import { OrgParcelSubParent, OrgParent } from '../../../../gql_generated/document_types'
import { usePaginatedOrgsQuery } from '../../../../gql_generated/graphql'
import { RelatedParcelOrgModal } from '../../RelatedModals/Parcel.related.org.modal'

const ParcelRelatedOrgsTableStyles = css``

const ParcelRelatedOrgsTableView = styled.div`
	${ParcelRelatedOrgsTableStyles}
`

type ParcelRelatedOrgsTableProps = {
	className?: string
	parcelId: number
	subType: OrgParcelSubParent
}
export const ParcelRelatedOrgsTable = ({
	className,
	parcelId,
	subType,
}: ParcelRelatedOrgsTableProps): JSX.Element => {
	const paginationStore = usePaginationParams()

	const [fetchResults] = usePaginatedOrgsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: OrgParent.Parcel,
						parentIds: [parcelId],
						parcelSubParentType: subType,
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.orgs
	return (
		<ParcelRelatedOrgsTableView className={`${className ? ` className` : ''}`}>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedOrgColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={org => <RelatedParcelOrgModal orgId={org.id} />}
				resourceType='org'
			/>
		</ParcelRelatedOrgsTableView>
	)
}
