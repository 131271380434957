import React from 'react'
import styled, { css } from 'styled-components'
import {
	Dba,
	useDeleteDbaMutation,
	useOrgFullQuery,
	useUpdateOrgMutation,
} from '../../../gql_generated/graphql'
import { sanitizeDataForForm, useState } from '../../../utils'
import { ArrayField, Async, Btn, Form, Icon, Spinner, SubmitBtn, Text } from '../../UI'

export const opsOrgDbasEditStyles = css`
	.dba-fields {
		.dba-field {
			display: flex;
			align-items: center;
		}
	}
`

const OpsOrgDbasEditView = styled.div`
	${opsOrgDbasEditStyles}
`

// const dbaInUseErr = (errMsg: string) => new RegExp('violates foreign key constraint').test(errMsg)

type OpsOrgDbasEditProps = {
	orgId: number
	cb?: () => void
}
const OpsOrgDbasEdit: React.FC<OpsOrgDbasEditProps> = ({ orgId, cb }) => {
	const [isRemoving, setRemoving] = useState(false, 'isRemoving')
	const [unRemovable] = useState<number[]>([], 'unRemovable')
	const [{ data, fetching, error }] = useOrgFullQuery({
		variables: { id: orgId },
	})

	const [_, updateOrg] = useUpdateOrgMutation()
	const [__, deleteDba] = useDeleteDbaMutation()

	const initialVals = data?.org ? sanitizeDataForForm(data.org) : null

	const onSubmit = async ({ id, name, dbas }: any) => {
		try {
			const res = await updateOrg({
				data: {
					id,
					name,
					dbas: dbas.map(({ id, name }: Dba) => {
						const val = { name } as Dba
						if (id) val.id = id
						return val
					}),
				},
			})

			if (cb && !res.error) cb()
		} catch (err) {}
	}

	return (
		<OpsOrgDbasEditView>
			<Async fetchResults={{ fetching, error }}>
				{data?.org ? (
					<Form legend='DBAs' initialValues={initialVals} onSubmit={onSubmit}>
						{({ values }) => {
							const dbas = values.dbas as Dba[]
							return (
								<>
									<ArrayField
										name='dbas'
										render={({ remove, push }) => (
											<div className='dba-fields'>
												{isRemoving ? (
													<Spinner />
												) : (
													dbas.map(({ id }, idx) => (
														<div className='dba-field' key={idx}>
															<Text name={`dbas.${idx}.name`} />
															<Btn
																disabled={unRemovable.includes(id)}
																onClick={async () => {
																	if (!id) remove(idx)
																	else {
																		try {
																			setRemoving(true)
																			const res = await deleteDba({ dbaId: id })
																			// 	context: {
																			// 		errMsgHandler: ({ errMsg }: ErrMsgHandlerOptions) => {
																			// 			if (dbaInUseErr(errMsg)) {
																			// 				setUnremovable(state => [...state, id])
																			// 				return `Cannot remove '${name}' because it's being used on another resource.`
																			// 			} else return errMsg
																			// 		},
																			// 	},
																			// })

																			if (!res.error) remove(idx)
																		} finally {
																			setRemoving(false)
																		}
																	}
																}}
															>
																<Icon type='x' />
															</Btn>
														</div>
													))
												)}
												<Btn onClick={() => push({ name: '' })}>Add DBA</Btn>
											</div>
										)}
									/>

									<SubmitBtn />
								</>
							)
						}}
					</Form>
				) : null}
			</Async>
		</OpsOrgDbasEditView>
	)
}
export default OpsOrgDbasEdit
