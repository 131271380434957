import { Auth } from 'aws-amplify'
import get from 'lodash/get'
import toLower from 'lodash/toLower'
import { me } from '../api'
import { User } from '../gql_generated/graphql'
import { useStoreActions, useStoreState } from '../store'

/**
 *  Authenticates current user and stores the cognito user in the global cache
 * */
export const useAuth = () => {
	const { isAuthed } = useStoreState(state => state.user)
	const { setCognitoUser, setUser, setIsAuthed } = useStoreActions(actions => actions.user)
	return async () => {
		try {
			// we don't autheticate if we already are
			if (isAuthed) {
				console.log('Auth.useAuth - already authenticated!')
				return
			}
			// console.log('Auth.useAuth - authenticating with cognito...')
			const cognitoUser = await Auth.currentAuthenticatedUser()

			setCognitoUser(cognitoUser)

			if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
				return {
					redirect: '/create-pass',
				}
			}

			// console.log('Auth.useAuth - fetching user from db...')
			const dbUser: User = await me.get<User>()

			setUser(dbUser)

			// console.log('Auth.useAuth - setting app to authenticated')
			setIsAuthed()
			const dashPath = '/mgr/dash'
			return {
				redirect: get(dashPath, 'pref'),
			}
		} catch (err) {
			throw err
		}
	}
}

const useClearAuth = () => {
	const { clearUser } = useStoreActions(actions => actions.user)
	return () => {
		clearUser()
	}
}

export const useLogin = () => {
	const { setCognitoUser, setIsAuthed, setUser } = useStoreActions(actions => actions.user)
	return async ({ email, password }: { email: string; password: string }) => {
		try {
			const cognitoUser = await Auth.signIn(toLower(email), password)
			console.log('Auth.login cognito user: ', cognitoUser)

			if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
				setCognitoUser(cognitoUser)
				return {
					redirect: '/create-pass',
				}
			}

			if (cognitoUser.challengeName) {
				console.warn('Auth.login - unknown user challenge: ', cognitoUser.challengeName)
				// handleErr(user.challengeName)
				setCognitoUser(cognitoUser)
				return cognitoUser.challengeName
			}

			setCognitoUser(cognitoUser)

			const dbUser = await me.get<User>()

			setUser(dbUser)

			setIsAuthed()

			// const dashPath = get(dbUser, 'preferences', []).find(({ type }) => type === 'dash_path')

			return dbUser
		} catch (err) {
			console.error(err)
			throw err
		}
	}
}

export const useLogout = () => {
	const clearAuth = useClearAuth()
	return async () => {
		try {
			await Auth.signOut()

			console.log('Signed out from cognito')

			clearAuth()
		} catch (err) {
			console.error(err)
			throw err
		}
	}
}

type UseCreatePassOptions = {
	email: string
	verification: string
}

export const useCreatePass = () => {
	const { cognito } = useStoreState(state => state.user)
	const authUser = useAuth()
	return async (pw: string, options?: UseCreatePassOptions) => {
		try {
			const { email, verification } = options || {}

			let cognitoUser = cognito

			if (!cognitoUser?.Session) {
				try {
					cognitoUser = await Auth.currentAuthenticatedUser()
				} catch (err) {
					// we proceed anyways
				}
			}

			if (!cognitoUser?.Session && email && verification) {
				if (!!cognitoUser) await Auth.signOut()
				console.log('authenticating user with email: ', email, ' and pw: ', verification)
				cognitoUser = await Auth.signIn(toLower(email), verification)
			}

			try {
				console.log('Auth - create new pw - cognito user: ', cognitoUser)
				await Auth.completeNewPassword(cognitoUser, pw)
			} catch (err) {
				console.log('Auth - create new pw err: ', err)
				throw err
			}

			try {
				await Auth.verifyUserAttribute
			} catch (err) {
				console.log('Auth - verify email err: ', err)
				throw err
			}

			console.log('new password successfully set')

			await authUser()

			return {
				ok: true,
			}
		} catch (err) {
			console.error(err)
			throw err
		}
	}
}

// Forgot password flow is handled via the server and the createPassword client service

// export const useRequestPWReset = () => {
// 	return async (email: string) => {
// 		try {
// 			const res = await Auth.forgotPassword(email)

// 			return res
// 		} catch (err) {
// 			console.error(err)
// 			throw err
// 		}
// 	}
// }

// export const useResetPass = () => {
// 	return async (email: string, code: string, pw: string) => {
// 		try {
// 			const res = await Auth.completeNewPassword(email, code, pw)

// 			return res
// 		} catch (err) {
// 			console.error(err)
// 			throw err
// 		}
// 	}
// }
