import React from 'react'
import styled, { css } from 'styled-components/macro'
import { LeaseTermDetail } from '../../../Components/common/Lease/Lease.term.detail'

export const relatedLeaseTermModalStyles = css``

const RelatedLeaseTermModalView = styled.div`
	${relatedLeaseTermModalStyles}
`

export type RelatedLeaseTermModalProps = {
	termId: number
}
export const RelatedLeaseTermModal: React.FC<RelatedLeaseTermModalProps> = ({ termId }) => {
	return (
		<RelatedLeaseTermModalView>
			<LeaseTermDetail termId={termId} />
		</RelatedLeaseTermModalView>
	)
}
