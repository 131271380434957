import React from 'react'
import styled, { css } from 'styled-components/macro'
import { OrgDetail } from '../../../Components/common/Org/Org.detail'

export const relatedParcelOrgModalStyles = css``

const RelatedParcelOrgModalView = styled.div`
	${relatedParcelOrgModalStyles}
`

export type RelatedParcelOrgModalProps = {
	orgId: number
}
export const RelatedParcelOrgModal: React.FC<RelatedParcelOrgModalProps> = ({ orgId }) => {
	return (
		<RelatedParcelOrgModalView>
			<OrgDetail orgId={orgId} />
		</RelatedParcelOrgModalView>
	)
}
