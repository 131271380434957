import { action, Action } from 'easy-peasy'
import isBoolean from 'lodash/isBoolean'

export type SearchStoreModel = {
	fetching: boolean
	err: Error | null

	parcelsStr: string | null
	salesStr: string | null
	leasesStr: string | null
	easementsStr: string | null
	orgsStr: string | null
	docsStr: string | null

	setFetching: Action<SearchStoreModel, string>
	setErr: Action<SearchStoreModel, Error>
	setSearchStr: Action<SearchStoreModel, SearchStrOptions>
}

export type SearchStrOptions = {
	str: string
	type: string
}

type SearchStrKey = 'parcelsStr' | 'salesStr' | 'leasesStr' | 'easementsStr' | 'orgsStr' | 'docsStr'

export const searchModel: SearchStoreModel = {
	fetching: true,
	err: null,

	parcelsStr: null,
	salesStr: null,
	leasesStr: null,
	easementsStr: null,
	orgsStr: null,
	docsStr: null,

	setFetching: action((state, status) => {
		if (isBoolean(status) && status !== state.fetching) state.fetching = status
	}),

	setErr: action((state, error) => {
		if (error) state.err = error
		else if (state.err) state.err = null
	}),

	// setIndex: action((state, resources) => {
	// 	const { parcels, sales, leases, easements, orgs, docs } = resources || {}

	// 	if (parcels && !state.allParcels) state.allParcels = parcels
	// 	else if (!parcels && state.allParcels) state.allParcels = null

	// 	if (sales && !state.allSales) state.allSales = sales
	// 	else if (!sales && state.allSales) state.allSales = null

	// 	if (leases && !state.allLeases) state.allLeases = leases
	// 	else if (!leases && state.allLeases) state.allLeases = null

	// 	if (easements && !state.allEasements) state.allEasements = easements
	// 	else if (!easements && state.allEasements) state.allEasements = null

	// 	if (orgs && !state.allOrgs) state.allOrgs = orgs
	// 	else if (!orgs && state.allOrgs) state.allOrgs = null

	// 	if (docs && !state.allDocs) state.allDocs = docs
	// 	else if (!docs && state.allDocs) state.allDocs = null
	// }),

	setSearchStr: action((state, opts) => {
		const { str, type } = opts || {}
		if (str && str.length > 0 && str.length < 3) return

		const stateKey: SearchStrKey = `${type}Str` as any

		const stateStr = state[stateKey]

		if (str && stateStr !== str) state[stateKey] = str

		if (!str && stateStr) {
			state[stateKey] = null
		}
	}),
}
