import React from 'react'
import styled from 'styled-components'
import { useLogForInfoQuery } from '../../../gql_generated/graphql'
import { IconWithText, KeyVals } from '../../UI'
import { IconType } from '../../UI/Icon/fa.defaults'

const LogInfoView = styled.div``

type LogInfoProps = {
	logId: number
}
const LogInfo: React.FC<LogInfoProps> = ({ logId }) => {
	const [results] = useLogForInfoQuery({ variables: { id: logId } })
	const log = results.data?.log
	const { createdAt, createdBy, logType, action, status, resourceType } = log || {}

	return (
		<LogInfoView>
			<KeyVals
				items={[
					['Created At', createdAt, 'date'],
					['User', createdBy?.firstName, 'proper'],
					['Type', logType, 'proper'],
					['Action', action, 'proper'],
					['Status', status, 'proper'],
					['Resource', <IconWithText icon={resourceType as IconType}>{resourceType}</IconWithText>],
				]}
			/>
		</LogInfoView>
	)
}
export default LogInfo
