import React from 'react'
import styled from 'styled-components'
import { Lease, ParcelParent } from '../../../gql_generated/document_types'
import { Parcel, useParcelsByParentQuery } from '../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../Types'
import { TablePrint } from '../../UI'
import LeaseTaxReimbrsPdfTitles from './Lease.TaxReimbrs.pdf.titles'
import LeaseTaxReimbrsPdfTotals from './Lease.TaxReimbrs.pdf.totals'
import {
	genLeaseTaxReimbrsPdfPrintRowsFromParcels,
	LeaseTaxReimbrsPdfPrintRowsAndTaxes,
} from './Lease.TaxReimbrs.pdf.utils'

const LeaseTaxReimbrsPDFView = styled.div`
	font-size: 12px;
	.pdf-titles {
		margin-bottom: 0.3in;
	}

	.section-footer {
		border-top: 1px solid;
		display: inline;
		width: auto;
		em {
			margin-right: 2em;
		}
		.section-total {
			text-align: right;
			display: inline-flex;
			width: 100%;
			padding-top: 4px;
		}
	}

	.totals {
		display: flex;
		align-items: center;
		padding: 10px 0;
		font-weight: 700;
		.val {
			margin-left: auto;
		}
	}
`

export type LeaseTaxReimbrsPdfConfig = {
	// includeAssigned?: boolean
	// includeTerminated?: boolean
	// title: string
	// subTitle?: string
	hideDate?: boolean
	// date?: string
}

type LeaseTaxReimbrsPDFProps = {
	config: LeaseTaxReimbrsPdfConfig
	lease: Lease
}
export const LeaseTaxReimbrsPDF: React.FC<LeaseTaxReimbrsPDFProps> = ({ lease, config }) => {
	const [results] = useParcelsByParentQuery({
		variables: { parentId: lease.id, parentType: ParcelParent.Lease },
	})
	const parcels = results.data?.parcels

	console.log('lease tax reimbrs parcels: ', parcels)

	const { printRows = [], taxes = 0 } = parcels?.length
		? genLeaseTaxReimbrsPdfPrintRowsFromParcels(
				parcels as Parcel[],
				lease.estateType as EstateTypeEnum
		  )
		: ({} as LeaseTaxReimbrsPdfPrintRowsAndTaxes)

	console.log('printRows: ', printRows)

	return (
		<LeaseTaxReimbrsPDFView>
			<LeaseTaxReimbrsPdfTitles lease={lease} config={config} />
			<TablePrint
				body={{
					rows: printRows,
				}}
			/>
			<LeaseTaxReimbrsPdfTotals totalTaxes={taxes} />
		</LeaseTaxReimbrsPDFView>
	)
}
