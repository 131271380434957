import { RouteComponentProps } from '@reach/router'
import React from 'react'
import styled from 'styled-components'
import { Card, Icon, Link } from '../../Components/UI'
import CardsSection from '../../Components/common/CardsSection'
import Page from '../../Components/common/Resources/Resource.page'
import { usePageData } from '../../utils'

const ReportsPageView = styled.div`
	.options-card {
		min-height: 500px;
	}

	.options-nav {
		display: grid;
		grid-gap: 1rem;
		grid-template-rows: 100px;

		${props => props.theme.media.tablet} {
			grid-template-columns: 1fr 1fr;
		}
		${props => props.theme.media.sdesk} {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}

		.link {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 1rem 2rem;
			font-size: 1.5rem;
			border: solid 2px;
			border-radius: 4px;
			width: 100%;
			height: 100%;
		}
	}
`

export const ReportsPage: React.FC<RouteComponentProps> = () => {
	usePageData({ pageTitle: 'Reports', icon: 'report' })

	return (
		<ReportsPageView>
			<Page isLoaded>
				<CardsSection>
					<Card className='options-card'>
						<nav className='options-nav'>
							<li>
								<Link to='exhibit'>
									<Icon type='exhibit' padRight /> Exhibits
								</Link>
							</li>
							<li>
								<Link to='property-taxes'>
									<Icon type='receipt' padRight /> Property Taxes
								</Link>
							</li>
							<li>
								<Link to='county-snapshots'>
									<Icon type='parcel' padRight /> County Snapshots
								</Link>
							</li>
						</nav>
					</Card>
				</CardsSection>
			</Page>
		</ReportsPageView>
	)
}
