import React from 'react'
import styled, { css } from 'styled-components/macro'
import { EasementDetail } from '../../../Components/common/Easement/Easement.detail'

export const relatedDocEasementModalStyles = css``

const RelatedDocEasementModalView = styled.div`
	${relatedDocEasementModalStyles}
`

export type RelatedDocEasementModalProps = {
	easementId: number
}
export const RelatedDocEasementModal: React.FC<RelatedDocEasementModalProps> = ({ easementId }) => {
	return (
		<RelatedDocEasementModalView>
			<EasementDetail easementId={easementId} />
		</RelatedDocEasementModalView>
	)
}
