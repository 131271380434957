import React from 'react'
import styled, { css } from 'styled-components/macro'
import { docRelatedAgreementsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Agreement.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { AgreementParent } from '../../../gql_generated/document_types'
import { usePaginatedAgreementsQuery } from '../../../gql_generated/graphql'
import { RelatedDocAgreementModal } from '../Related.Modals/Doc.related.agreement.modal'

export const relatedDocAgreementsStyles = css``

const RelatedDocAgreementsView = styled.div`
	${relatedDocAgreementsStyles}
`

export type RelatedDocAgreementsProps = {
	docId: number
}

export const RelatedDocAgreements: React.FC<RelatedDocAgreementsProps> = ({ docId }) => {
	const paginationStore = usePaginationParams()

	const [fetchResults] = usePaginatedAgreementsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: AgreementParent.Doc,
						parentIds: [docId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.agreements
	return (
		<RelatedDocAgreementsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={docRelatedAgreementsColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={agreement => <RelatedDocAgreementModal agreementId={agreement.id} />}
				resourceType='agreement'
			/>
		</RelatedDocAgreementsView>
	)
}
