import React from 'react'
import styled from 'styled-components'
import { RelatedResourcesCard } from '../../../Components/common/RelatedResource.card'
import { Spinner, Tab, Tabs } from '../../../Components/UI'
import { RelatedOrgAcquisitions } from './Org.related.acquisitions'
import { RelatedOrgAgreements } from './Org.related.agreements'
import { RelatedOrgDocs } from './Org.related.docs'
import { RelatedOrgEasements } from './Org.related.easements'
import { RelatedOrgLeases } from './Org.related.leases'
import { RelatedOrgLogs } from './Org.related.logs'
import { RelatedOrgParcels } from './Org.related.parcels'
import { RelatedOrgSales } from './Org.related.sales'
const Section = styled.section``

type OrgRelatedResourcesProps = {
	orgId: number
}
const OrgRelatedResources: React.FC<OrgRelatedResourcesProps> = ({ orgId }) => {
	return (
		<Section>
			<RelatedResourcesCard>
				{orgId ? (
					<Tabs renderOnSelect>
						<Tab nav='Parcels' icon='parcel'>
							<RelatedOrgParcels orgId={orgId} />
						</Tab>

						<Tab nav='Sales' icon='sale'>
							<RelatedOrgSales orgId={orgId} />
						</Tab>

						<Tab nav='Leases' icon='lease'>
							<RelatedOrgLeases orgId={orgId} />
						</Tab>

						<Tab nav='Agreements' icon='agreement'>
							<RelatedOrgAgreements orgId={orgId} />
						</Tab>
						<Tab nav='Easements' icon='easement'>
							<RelatedOrgEasements orgId={orgId} />
						</Tab>
						<Tab nav='Acquisitions' icon='acquisitions'>
							<RelatedOrgAcquisitions orgId={orgId} />
						</Tab>
						<Tab nav='Docs' icon='doc'>
							<RelatedOrgDocs orgId={orgId} />
						</Tab>
						<Tab nav='Logs' icon='log'>
							<RelatedOrgLogs orgId={orgId} />
						</Tab>
					</Tabs>
				) : (
					<Spinner />
				)}
			</RelatedResourcesCard>
		</Section>
	)
}
export default OrgRelatedResources
