import React, { useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { useState } from '../../../../utils'
import { Icon } from '../../Icon'
import { Btn } from '../../Typography'
import { TableRowConfig, TableRowOpConfig } from '../Table.types'
import { TableRowOp, TableRowOpProps } from './Table.body.rowOp'

export const tableRowOpsStyles = css`
	.ctrl-group {
		box-shadow: 1px 1px 3px ${props => props.theme.colors.grey.tint().val};
		position: absolute;
		bottom: -4px;
		right: 0;
		transform: translateY(100%);
		padding: 1em;
		background: ${props => props.theme.colors.white.val};
		box-shadow: 1px 1px 3px ${props => props.theme.colors.grey.val};
		z-index: 2;
	}
`

const TableRowOpsView = styled.div`
	${tableRowOpsStyles}
`

export type TableRowOpsProps<T> = Pick<TableRowConfig<T>, 'id' | 'val' | 'isDisabled'> & {
	ops: TableRowOpConfig<T>[]
}
export const TableRowOps: React.FC<TableRowOpsProps<any>> = ({ ops, ...rowConfig }) => {
	const [showGroup, setShowGroup] = useState(false, 'showGroup')

	useEffect(() => {
		const closeGroup = () => {
			setShowGroup(false)
			document.removeEventListener('click', closeGroup)
		}
		if (showGroup) document.addEventListener('click', closeGroup)

		return () => {
			document.removeEventListener('click', closeGroup)
		}
	}, [showGroup])

	const singleOp = ops.length === 1 ? ops[0] : null
	const singleOpProps = singleOp
		? ({
				...rowConfig,
				...singleOp,
		  } as TableRowOpProps<any>)
		: null

	return (
		<TableRowOpsView className={singleOp ? 'single-row-op' : 'row-ops'}>
			{singleOpProps ? (
				<TableRowOp {...singleOpProps} />
			) : (
				<>
					<Btn className='ctrl' onClick={() => setShowGroup(true)}>
						<Icon type='dotsH' />
					</Btn>
					<div className='ctrl-group'>
						{ops.map((op, idx) => (
							<TableRowOp key={idx} {...rowConfig} {...op} />
						))}
					</div>
				</>
			)}
		</TableRowOpsView>
	)
}
