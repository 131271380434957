import React from 'react'
import styled from 'styled-components'
import { GTableCell } from './GTable'
import { GTableCellContainer, GTableCellContainerProps } from './GTable.cell.Container'

export const GTableBodyCellView = styled.div``

export type GTableBodyCellProps = GTableCellContainerProps & {
	className?: string
	cell: GTableCell
}

export const GTableBodyCell: React.FC<GTableBodyCellProps> = ({ className, cell, ...props }) => {
	return (
		<GTableCellContainer {...props}>
			<GTableBodyCellView className={`g-table-body-cell${className ? ` ${className}` : ''}`}>
				{cell.val}
			</GTableBodyCellView>
		</GTableCellContainer>
	)
}
