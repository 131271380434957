import { useLocation } from '@reach/router'
import capitalize from 'lodash/capitalize'
import includes from 'lodash/includes'
import { resourceStatuses, StatusObj } from './resource.defaults'

export const parseSingle = (type?: string | null) =>
	includes(type, 'parcel')
		? 'parcel'
		: includes(type, 'lease')
		? 'lease'
		: includes(type, 'easement')
		? 'easement'
		: includes(type, 'agreement')
		? 'agreement'
		: includes(type, 'doc')
		? 'doc'
		: includes(type, 'sale')
		? 'sale'
		: includes(type, 'acquisition')
		? 'acquisition'
		: includes(type, 'log')
		? 'log'
		: includes(type, 'org')
		? 'org'
		: includes(type, 'contact')
		? 'contact'
		: includes(type, 'report')
		? 'report'
		: includes(type, 'royalt')
		? 'royalty'
		: includes(type, 'srf')
		? 'surface'
		: includes(type, 'min')
		? 'mineral'
		: includes(type, 'ind')
		? 'industrial mineral'
		: includes(type, 'oil')
		? 'oil & gas'
		: includes(type, 'geo')
		? 'geothermal'
		: null

export const parsePlural = (type?: string | null) =>
	includes(type, 'parcel')
		? 'parcels'
		: includes(type, 'lease')
		? 'leases'
		: includes(type, 'easement')
		? 'easements'
		: includes(type, 'agreement')
		? 'agreements'
		: includes(type, 'doc')
		? 'docs'
		: includes(type, 'sale')
		? 'sales'
		: includes(type, 'acquisition')
		? 'acquisitions'
		: includes(type, 'log')
		? 'logs'
		: includes(type, 'org')
		? 'orgs'
		: includes(type, 'contact')
		? 'contacts'
		: includes(type, 'report')
		? 'reports'
		: includes(type, 'royalt')
		? 'royalties'
		: includes(type, 'srf')
		? 'surfaces'
		: includes(type, 'min')
		? 'minerals'
		: includes(type, 'ind')
		? 'industrial minerals'
		: includes(type, 'oil')
		? 'oil & gases'
		: includes(type, 'geo')
		? 'geothermals'
		: null

export const parseResourceType = (type?: string | null) => ({
	icon: parseSingle(type),
	title: capitalize(type || undefined),
	titleFull: capitalize(parseSingle(type) || undefined),
	single: parseSingle(type),
	plural: parsePlural(type),
})

export const useResourceType = () => {
	const { pathname } = useLocation() || {}

	return parseResourceType(parsePlural(pathname as string))
}

export const parseResourceStatus = (status?: string | null) => {
	const obj = resourceStatuses.find(s => status === s.statusName) as StatusObj
	return (
		obj || {
			statusName: undefined,
			icon: undefined,
		}
	)
}
