import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Tab, Tabs } from '../../../../Components/UI'
import { RelatedLeaseActiveParcels } from './Lease.related.parcels.active'
import { RelatedLeaseAssignedParcels } from './Lease.related.parcels.assigned'
import { RelatedLeaseTerminatedParcels } from './Lease.related.parcels.terminated'

export const relatedLeaseParcelStyles = css``

const RelatedLeaseParcelsView = styled.div`
	${relatedLeaseParcelStyles}
`

export type RelatedLeaseParcelsProps = {
	leaseId: number
}
export const RelatedLeaseParcels: React.FC<RelatedLeaseParcelsProps> = ({ leaseId }) => {
	return (
		<RelatedLeaseParcelsView>
			<Tabs navSize='sm'>
				<Tab nav='Active'>
					<RelatedLeaseActiveParcels leaseId={leaseId} />
				</Tab>
				<Tab nav='Terminated'>
					<RelatedLeaseTerminatedParcels leaseId={leaseId} />
				</Tab>
				<Tab nav='Assigned'>
					<RelatedLeaseAssignedParcels leaseId={leaseId} />
				</Tab>
			</Tabs>
		</RelatedLeaseParcelsView>
	)
}
