import { navigate, RouteComponentProps } from '@reach/router'
import toNumber from 'lodash/toNumber'
import React, { useEffect } from 'react'
import AgreementInfo from '../../Components/common/Agreement/Agreement.info'
import AgreementOg from '../../Components/common/Agreement/Agreement.og'
import Cards from '../../Components/common/CardsColumns'
import CardsSection from '../../Components/common/CardsSection'
import ExhibitForm from '../../Components/common/Exhibit'
import { PageOperation } from '../../Components/common/Operations'
import Page from '../../Components/common/Resources/Resource.page'
import { AddDocToResourceOp } from '../../Components/Ops/Ops.AddDocToResource'
import EditAgreement from '../../Components/Ops/Ops.Agreements/Ops.Agreement.edit'
import OpExpireAgreement from '../../Components/Ops/Ops.Agreements/Ops.Agreement.expire'
import OpTerminateAgreement from '../../Components/Ops/Ops.Agreements/Ops.Agreement.terminate'
import { Alert, Async, Card, Colorize, Modal, P, useToaster } from '../../Components/UI'
import { UserRole } from '../../gql_generated/document_types'
import {
	Agreement,
	useAgreementFullQuery,
	useDeleteAgreementMutation,
} from '../../gql_generated/graphql'
import { EstateTypeEnum } from '../../Types'
import { isPastDate, usePageData, useState } from '../../utils'
import AgreementRelatedResources from './Related/Agreement.related'

type AgreementPageProps = RouteComponentProps & {
	id?: number
}
const AgreementPage: React.FC<AgreementPageProps> = props => {
	const id = toNumber(props.id)
	const [Op, setOp] = useState<string | null>(null, 'Op')
	const [results, refetch] = useAgreementFullQuery({ variables: { id } })
	const [_, deleteAgreement] = useDeleteAgreementMutation()
	const toast = useToaster()

	const { data, fetching } = results

	const { agreement } = data || {}

	const { status, agreementId, notes, dateShouldExpire } = agreement || {}

	const [updatePageInfo] = usePageData({ pageType: 'agreement' })

	const isPastExpiration = isPastDate(dateShouldExpire)

	useEffect(() => {
		updatePageInfo({
			pageTitle: `Agreement ID: ${agreementId}`,
			status: isPastExpiration ? 'Past Expiration' : status,
		})
	}, [agreementId, updatePageInfo, status, isPastExpiration])

	const operations: PageOperation[] = [
		{
			txt: 'Edit',
			icon: 'edit',
			op: () => setOp('edit'),
		},
		{
			txt: 'Expire Agreement',
			icon: 'expire',
			hide: status !== 'Active' || !isPastExpiration,
			op: () => setOp('expire'),
		},
		{
			txt: 'Generate Exhibit',
			icon: 'exhibit',
			op: () => setOp('exhibit'),
			hide: !agreement?.activity?.length,
			allowed: [UserRole.Accountant, UserRole.Officer],
		},
		{
			txt: 'Terminate Agreement',
			icon: 'terminate',
			hide: status !== 'Active',
			op: () => setOp('terminate'),
		},
		{
			txt: 'Add Doc',
			icon: 'doc',
			op: () => setOp('doc'),
		},

		{
			txt: 'Delete',
			icon: 'x',
			op: () => setOp('delete'),
		},
	]

	const deleteOp = async (shouldDelete: boolean) => {
		if (shouldDelete) {
			try {
				await deleteAgreement({ id })
				navigate('/mgr/agreements')
			} catch (err) {
				toast('Error deleting agreement', { type: 'err' })
			}
		} else setOp(null)
	}

	const opCb = () => {
		refetch()
		setOp(null)
	}

	return (
		<Page isLoaded={!fetching} operations={operations}>
			<Async fetchResults={results}>
				<CardsSection isCollapsable>
					<Cards className='col left-col'>
						<Card titleText='Agreement Info'>
							<AgreementInfo agreementId={id} />
						</Card>
						<Card titleText='Notes'>
							<P asRT>{notes}</P>
						</Card>
						<Card titleText='Original (OG) data from Xogenous'>
							<AgreementOg agreementId={id} />
						</Card>
					</Cards>
				</CardsSection>
				<AgreementRelatedResources agreementId={id} />
			</Async>

			<Modal show={!!Op && Op !== 'delete'} closeHandler={() => setOp(null)}>
				{Op === 'edit' ? (
					<EditAgreement id={id} cb={opCb} />
				) : Op === 'expire' ? (
					<OpExpireAgreement agreement={agreement as Agreement} cb={opCb} />
				) : Op === 'exhibit' ? (
					<ExhibitForm
						resource={{ agreement: agreement as Agreement }}
						cb={() => setOp(null)}
						estateType={agreement?.estateType as EstateTypeEnum}
					/>
				) : Op === 'terminate' ? (
					<OpTerminateAgreement agreement={agreement as Agreement} cb={opCb} />
				) : Op === 'doc' ? (
					<AddDocToResourceOp parentId={id} parentType='agreement' cb={opCb} />
				) : null}
			</Modal>
			{Op === 'delete' ? (
				<Alert
					msg='Are you sure you want to delete this agreement? (Cannot be undone)'
					cb={deleteOp}
					okBtn='Cancel'
					cancelBtn={
						<Colorize color='err' asBlock>
							Delete Permanently
						</Colorize>
					}
					useCancelAsOp
				/>
			) : null}
		</Page>
	)
}
export default AgreementPage
