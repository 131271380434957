import { FieldConfig, useField } from 'formik'
import React, { ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { SelectDba } from '.'
import { useOrgFullQuery } from '../../../gql_generated/graphql'
import { useState } from '../../../utils'
import { SelectOrgModal } from '../../Ops/Ops.Orgs/Ops.Org.select'
import { Icon } from '../Icon'
import { useModalCtrl } from '../Modal'
import { Btn } from '../Typography'
import FormFieldWrap from './Form.field.wrap'

const SelectOrgFieldView = styled.div`
	.interactive {
		display: flex;
		align-items: center;
		.remove-val-btn {
			margin-left: auto;
			color: ${props => props.theme.colors.err.val};
		}
	}
`

type SelectOrgFieldProps = FieldConfig & {
	label?: ReactNode
	isReq?: boolean
	helperTxt?: ReactNode
	placeholder?: ReactNode
	allowDba?: boolean
	allowRemove?: boolean
}
const SelectOrgField: React.FC<SelectOrgFieldProps> = ({
	label,
	isReq,
	placeholder,
	name,
	helperTxt,
	allowDba = true,
	allowRemove,
	...props
}) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [{ value }, { touched }, { setValue, setTouched }] = useField({ name, ...props })
	const [{ data }] = useOrgFullQuery({ variables: { id: value || 1 } })
	const defaultTxt = placeholder || 'Select Org...'
	const [orgName, setOrgName] = useState(defaultTxt, 'orgName')
	const [[showModal, openModal, closeModal]] = useModalCtrl()

	const dbaFieldName = `${name.substring(0, name.length - 2)}DbaId`

	useEffect(() => {
		if (data?.org && data.org.id > 1) setOrgName(data.org.name)
	}, [data])

	return (
		<FormFieldWrap name={name} label={label} type='org-select' isReq={isReq} helperTxt={helperTxt}>
			<SelectOrgFieldView>
				<div className='interactive'>
					<Btn
						onClick={() => {
							openModal()
						}}
						className='field'
					>
						<Icon type='org' />
						{orgName}
					</Btn>
					{allowRemove && value ? (
						<Btn
							className='remove-val-btn'
							onClick={() => {
								setValue('')
								setOrgName(defaultTxt)
							}}
						>
							<Icon type='x' />
						</Btn>
					) : null}
				</div>
				<SelectOrgModal
					show={showModal}
					closeHandler={() => {
						if (!touched) setTouched(true)
						closeModal()
					}}
					cb={org => {
						if (!touched) setTouched(true)
						closeModal()
						setValue(org.id)
						setOrgName(org.name)
					}}
				/>
			</SelectOrgFieldView>
			{value && allowDba ? <SelectDba orgId={value} label='DBA' name={dbaFieldName} /> : null}
		</FormFieldWrap>
	)
}
export default SelectOrgField
