import { RouteComponentProps } from '@reach/router'
import styled, { css } from 'styled-components'
import CardsSection from '../../../../Components/common/CardsSection'
import EstateTypes from '../../../../Components/common/Estate/Estates.Icons'
import Page from '../../../../Components/common/Resources/Resource.page'
import { Card, H5, P, ParcelSelector, Tab, Tabs } from '../../../../Components/UI'
import { EstateTypeEnum } from '../../../../Types'
import { usePageData, useState } from '../../../../utils'
import { RelatedResearchAcquisitions } from '../Related/Research.related.acquisitions'
import { RelatedResearchAgreements } from '../Related/Research.related.agreements'
import { RelatedResearchEasements } from '../Related/Research.related.easements'
import { RelatedResearchLeases } from '../Related/Research.related.leases'
import { RelatedResearchSales } from '../Related/Research.related.sales'

export const parcelResearchByRelatedStyles = css`
	.action-bar {
		padding: 2em 0;
		h5 {
		}
	}
	.tab-wrap {
		.cols {
			grid-template-columns: 1fr 1fr;
		}
	}
	.parcel-select {
		/* flex-grow: 1; */
	}
`

const ParcelResearchByRelatedPageView = styled.div`
	${parcelResearchByRelatedStyles}
`

const SelectParcelsNote = () => {
	return (
		<P padBot='2em' padTop='2em'>
			Please select at least one parcel to see related resources
		</P>
	)
}

export const ParcelResearchByRelatedPage = (_: RouteComponentProps): JSX.Element => {
	usePageData({ pageTitle: 'Parcel Research', icon: 'research' })

	const [selectedParcelIds, setSelectedIds] = useState<number[]>([], 'selectedParcelIds')

	const [estateTypes, setEstateTypes] = useState<EstateTypeEnum[]>([], 'estateType')

	return (
		<ParcelResearchByRelatedPageView>
			<Page isLoaded>
				<CardsSection>
					<Card>
						<Tabs renderOnSelect className='parcel-research-tabs'>
							<Tab nav='Select Parcels' icon='parcels' className='select-parcels-col'>
								<div className='action-bar'>
									<H5 mBot>Select Estate Type</H5>
									<EstateTypes
										onSelect={type =>
											setEstateTypes((state: any) => {
												if (state.includes(type as EstateTypeEnum))
													return state.filter((t: string) => t !== type)
												else return [...state, type]
											})
										}
										selected={estateTypes}
									/>
								</div>
								<ParcelSelector
									selectedIds={selectedParcelIds}
									setSelectedIds={setSelectedIds}
									estateTypes={estateTypes}
								/>
							</Tab>
							<Tab nav='Sales' icon='sales'>
								{selectedParcelIds?.length ? (
									<RelatedResearchSales parcelIds={selectedParcelIds} estateTypes={estateTypes} />
								) : (
									<SelectParcelsNote />
								)}
							</Tab>
							<Tab nav='Leases' icon='leases'>
								{selectedParcelIds?.length ? (
									<RelatedResearchLeases parcelIds={selectedParcelIds} estateTypes={estateTypes} />
								) : (
									<SelectParcelsNote />
								)}
							</Tab>
							<Tab nav='Easements' icon='easements'>
								{selectedParcelIds?.length ? (
									<RelatedResearchEasements
										parcelIds={selectedParcelIds}
										estateTypes={estateTypes}
									/>
								) : (
									<SelectParcelsNote />
								)}
							</Tab>
							<Tab nav='Agreements' icon='agreements'>
								{selectedParcelIds?.length ? (
									<RelatedResearchAgreements
										parcelIds={selectedParcelIds}
										estateTypes={estateTypes}
									/>
								) : (
									<SelectParcelsNote />
								)}
							</Tab>
							<Tab nav='Acquisitions' icon='acquisitions'>
								{selectedParcelIds?.length ? (
									<RelatedResearchAcquisitions
										parcelIds={selectedParcelIds}
										estateTypes={estateTypes}
									/>
								) : (
									<SelectParcelsNote />
								)}
							</Tab>
						</Tabs>
					</Card>
				</CardsSection>
			</Page>
		</ParcelResearchByRelatedPageView>
	)
}
