import React from 'react'
import styled from 'styled-components'
import EstateTypes from '../../../../../Components/common/Estate/Estates.Icons'
import { Btn, Card, H5, ParcelSelector, Spinner } from '../../../../../Components/UI'
import {
	useAddParcelsToDocMutation,
	useAllParcelsByDocIdQuery,
} from '../../../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../../../Types'
import { useState } from '../../../../../utils'

export const AddParcelsToDocView = styled.div`
	.action-bar {
		display: flex;
		align-items: center;
		padding-bottom: 2em;

		.add-parcels-btn {
			margin-left: auto;
		}
	}
`

export type AddParcelsToDocProps = {
	docId: number
	cb?: () => void
}
export const AddParcelsToDoc: React.FC<AddParcelsToDocProps> = ({ docId, cb }) => {
	const [results] = useAllParcelsByDocIdQuery({ variables: { docId } })
	const docParcels = results.data?.parcels
	const previouslySelectedParcelIds = docParcels?.map(({ id }) => id) || []

	const [{ fetching }, addParcels] = useAddParcelsToDocMutation()
	const [estateTypes, setEstateTypes] = useState<EstateTypeEnum[]>([], 'estateTypes')
	const [selectedParcelIds, setSelectedParcelIds] = useState<number[]>([], 'selectedParcelIds')

	const saveHandler = async () => {
		try {
			await addParcels({
				docId,
				estateTypes,
				parcelIds: selectedParcelIds,
			})
			if (cb) cb()
		} catch (err) {}
	}

	return (
		<AddParcelsToDocView>
			<Card>
				<div className='action-bar'>
					<div className='estate-types-selection'>
						<H5 mBot>Select Estate Type</H5>
						<EstateTypes
							onSelect={type =>
								setEstateTypes((state: any) => {
									if (state.includes(type as EstateTypeEnum))
										return state.filter((t: string) => t !== type)
									else return [...state, type]
								})
							}
							selected={estateTypes}
						/>
					</div>
					<Btn
						disabled={!selectedParcelIds.length || !estateTypes.length || fetching}
						onClick={saveHandler}
						className='cta add-parcels-btn'
					>
						{fetching ? <Spinner /> : null}
						Connect {selectedParcelIds.length * estateTypes.length} Parcel Estates To Doc
					</Btn>
				</div>
				<ParcelSelector
					selectedIds={selectedParcelIds}
					setSelectedIds={setSelectedParcelIds}
					estateTypes={estateTypes}
					requireEstateTypes
					disableRow={parcelId => {
						const hasEstateTypes = docParcels?.some(({ docs }) =>
							docs?.some(({ estate }) => estateTypes.includes(estate.type as EstateTypeEnum))
						)
						const disable = !!hasEstateTypes && previouslySelectedParcelIds.includes(parcelId)

						return disable
					}}
				/>
			</Card>
		</AddParcelsToDocView>
	)
}
