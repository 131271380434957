import React from 'react'
import styled, { css } from 'styled-components/macro'
import { RoyaltyDetail } from '../../../Components/common/Royalty/Royalty.detail'

export const relatedParcelRoyaltyModalStyles = css``

const RelatedParcelRoyaltyModalView = styled.div`
	${relatedParcelRoyaltyModalStyles}
`

export type RelatedParcelRoyaltyModalProps = {
	royaltyId: number
}
export const RelatedParcelRoyaltyModal: React.FC<RelatedParcelRoyaltyModalProps> = ({
	royaltyId,
}) => {
	return (
		<RelatedParcelRoyaltyModalView>
			<RoyaltyDetail royaltyId={royaltyId} />
		</RelatedParcelRoyaltyModalView>
	)
}
