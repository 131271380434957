import { RouteComponentProps } from '@reach/router'
import isValid from 'date-fns/isValid'
import { Reducer, useEffect, useMemo, useReducer } from 'react'
import styled, { css } from 'styled-components'
import CardsSection from '../../../Components/common/CardsSection'
import { GenLeaseModal } from '../../../Components/common/Lease/Lease.gen.modal'
import Page from '../../../Components/common/Resources/Resource.page'
import {
	Async,
	Btn,
	Card,
	FormFieldRawWrap,
	Modal,
	Spinner,
	useModalCtrl,
} from '../../../Components/UI'
import { Heading } from '../../../Components/UI/Heading'
import { Table } from '../../../Components/UI/Table/Table'
import { useLeasesForPaymentsDueReportQuery } from '../../../gql_generated/graphql'
import { formatDate, usePageData, useState } from '../../../utils'
import { debounce } from '../../../utils/lodash.utils'

export const ResearchLeasePaymentsStyles = css`
	.controls {
		display: flex;
		align-items: center;
		flex-direction: column;
		padding-bottom: 1rem;
	}
	.dates {
		max-width: 500px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 1rem;
	}
	.results {
		position: relative;
	}

	table {
		th {
			padding-right: 0.5em;
			white-space: nowrap;
		}
		td {
			& > * {
				padding-left: 0;
				padding-right: 0.5em;
				white-space: normal;
			}
		}
	}
`

const ResearchLeasePaymentsView = styled.div`
	${ResearchLeasePaymentsStyles}
`

const confirmDate = (dateStr: string): string => (isValid(new Date(dateStr)) ? dateStr : '')

const today = formatDate(new Date(), 'input')
const thisYear = new Date().getFullYear()

const dateReducer: Reducer<
	{ dateFrom: string; dateTo: string; fromErr: string; toErr: string },
	{ dateFrom: string; dateTo: string }
> = (state, incoming) => {
	const dateFrom = confirmDate(incoming.dateFrom)
	const dateTo = confirmDate(incoming.dateTo)

	const newState = {
		...state,
		fromErr: '',
		toErr: '',
	}

	if (dateFrom && dateFrom < today) {
		newState.fromErr = `Cannot be before today`
	} else if (dateFrom && dateTo && dateFrom >= dateTo) {
		newState.toErr = `Must be after 'From' date`
	} else if (dateFrom && dateTo) {
		newState.dateFrom = dateFrom
		newState.dateTo = dateTo
	}
	return newState
}

export const ResearchLeasePayments = (_: RouteComponentProps): JSX.Element => {
	usePageData({ pageTitle: 'Lease Payments', icon: 'research' })

	const [{ dateFrom, dateTo, fromErr, toErr }, dispatchDate] = useReducer(dateReducer, {
		dateFrom: '',
		dateTo: '',
		fromErr: '',
		toErr: '',
	})

	const [results] = useLeasesForPaymentsDueReportQuery({
		variables: { dateFrom, dateTo },
		pause: !dateFrom || !dateTo,
	})

	const [loading, setLoading] = useState(false, 'loading')
	const [dateFromInput, setDateFromInput] = useState('', 'dateFromInput')
	const [dateToInput, setDateToInput] = useState('', 'dateToInput')

	const [leases, setLeases] = useState(results.data?.terms ?? null, 'leases')

	const setDateRange = useMemo(() => debounce(dispatchDate, 1000), [])

	useEffect(() => {
		setDateRange({ dateFrom: dateFromInput, dateTo: dateToInput })
		if (dateFromInput && dateToInput) {
			setLoading(true)
			setLeases(null)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateFromInput, dateToInput])

	useEffect(() => {
		if (results.data?.terms) {
			setLeases(results.data.terms)
			setLoading(false)
		}
	}, [results.data?.terms])

	useEffect(() => {
		return () => {
			setDateRange.cancel()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const [[showModal, openModal, closeModal, leaseId]] = useModalCtrl<number>()

	return (
		<ResearchLeasePaymentsView>
			<Page isLoaded>
				<CardsSection>
					<Card>
						<header className='controls'>
							<Heading mBot>Future Lease Payments Tracker</Heading>
							<div className='dates'>
								<FormFieldRawWrap name='dateFrom' label='From' error={fromErr}>
									<input
										className='date-from'
										type='date'
										onChange={e => setDateFromInput(e.currentTarget.value)}
										value={dateFromInput}
									/>
								</FormFieldRawWrap>
								<FormFieldRawWrap name='dateTo' label='To' error={toErr}>
									<input
										className='date-to'
										type='date'
										onChange={e => setDateToInput(e.currentTarget.value)}
										value={dateToInput}
									/>
								</FormFieldRawWrap>
							</div>
							<div className='preselections'>
								<Btn
									onClick={() => {
										setDateFromInput(today)
										setDateToInput(`${thisYear}-12-31`)
									}}
								>
									Rest of This Year
								</Btn>
								<Btn
									onClick={() => {
										setDateFromInput(`${thisYear + 1}-01-01`)
										setDateToInput(`${thisYear + 1}-12-31`)
									}}
								>
									Next Year
								</Btn>
								<Btn
									onClick={() => {
										const year = today <= `${thisYear}-01-01` ? thisYear : thisYear + 1
										setDateFromInput(`${year}-01-01`)
										setDateToInput(`${year}-03-31`)
									}}
								>
									Q1
								</Btn>
								<Btn
									onClick={() => {
										const year = today <= `${thisYear}-04-01` ? thisYear : thisYear + 1
										setDateFromInput(`${year}-04-01`)
										setDateToInput(`${year}-06-30`)
									}}
								>
									Q2
								</Btn>
								<Btn
									onClick={() => {
										const year = today <= `${thisYear}-07-01` ? thisYear : thisYear + 1
										setDateFromInput(`${year}-07-01`)
										setDateToInput(`${year}-09-30`)
									}}
								>
									Q3
								</Btn>
								<Btn
									onClick={() => {
										const year = today <= `${thisYear}-10-01` ? thisYear : thisYear + 1
										setDateFromInput(`${year}-10-01`)
										setDateToInput(`${year}-12-31`)
									}}
								>
									Q4
								</Btn>
							</div>
						</header>
						<div className='results'>
							<Async fetchResults={results}>
								{loading && !fromErr && !toErr ? <Spinner /> : null}
								{leases?.length ? (
									<Table
										cols={[
											{ label: 'Due Date' },
											{ label: 'Lease #' },
											{ label: 'Lessee' },
											{ label: 'Schedule' },
											{ label: 'Term Notes' },
										]}
										rows={leases.map(({ id, term, dueDate }, rowIdx) => {
											const { lease, schedInterval, schedAmount, termNotes } = term

											const { lessee, lesseeDba, leaseId, ogLessee } = lease

											return {
												id,
												val: lease.id,
												rowIdx,
												cells: [
													{
														txt: formatDate(dueDate),
													},
													{ txt: leaseId },
													{ txt: lesseeDba?.name ?? lessee?.name ?? ogLessee },
													{ txt: `${schedAmount} ${schedInterval}` },
													{ txt: termNotes },
												],
											}
										})}
										clickRowHandler={(leaseId: number) => openModal(leaseId)}
									/>
								) : null}
							</Async>
						</div>
					</Card>
				</CardsSection>
			</Page>
			<Modal show={showModal} closeHandler={closeModal}>
				{leaseId ? <GenLeaseModal leaseId={leaseId} /> : null}
			</Modal>
		</ResearchLeasePaymentsView>
	)
}
