import React, { useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { useState } from '../../../../utils'
import { Icon } from '../../Icon'
import { Btn } from '../../Typography'
import { TableHeadOp } from '../Table.types'

export const tableHeadOptsStyles = css`
	position: relative;
	&.__hidden {
		visibility: hidden;
		pointer-events: none;
	}

	.btn {
		color: ${props => props.theme.colors.secondary.val};
		&.ctrl {
			display: flex;
			align-items: center;
			.icon {
				height: 1.7em;
			}
		}
	}
	.ctrl-group {
		display: block;
		position: absolute;
		bottom: -5px;
		left: 0;
		z-index: 5;
		background: ${props => props.theme.colors.white.val};
		padding: 1em;
		box-shadow: 1px 0 3px ${props => props.theme.colors.black.tint(30).val};
		transform: translateY(100%);
		.btn {
			white-space: nowrap;
			display: block;
		}
	}
`

const TableHeadOpsView = styled.div`
	${tableHeadOptsStyles}
`

export type TableHeadOpsProps = {
	hasSelectedRows?: boolean
	ops: TableHeadOp[]
}

export const TableHeadOps: React.FC<TableHeadOpsProps> = ({ hasSelectedRows, ops }) => {
	const [show, setShow] = useState(false, 'show')

	useEffect(() => {
		const close = () => {
			setShow(false)
			document.removeEventListener('click', close)
		}
		if (show) document.addEventListener('click', close)

		return () => {
			if (show) document.removeEventListener('click', close)
		}
	}, [show])

	return (
		<TableHeadOpsView className={`table-global-ops${hasSelectedRows ? '' : ' __hidden'}`}>
			<Btn className='ctrl' onClick={() => setShow(state => !state)}>
				<Icon type='dotsV' />
				Options
			</Btn>
			{show ? (
				<div className='ctrl-group'>
					{ops.map(({ icon, txt, fireOnSelect, op }) =>
						fireOnSelect ? null : (
							<Btn key={txt || icon} onClick={op}>
								{icon ? <Icon type={icon} /> : null}
								{txt ? txt : null}
							</Btn>
						)
					)}
				</div>
			) : null}
		</TableHeadOpsView>
	)
}
