import React from 'react'
import styled from 'styled-components'
import Main from '../Components/common/Main'
import Footer from '../Components/Footer'
import MgrPageHeader from '../Components/Header/Header.mgr'
import { DeskNav as DeskAside, MobileNav } from '../Components/Nav'
import { useStoreActions } from '../store'

const Layout = styled.div`
	${props => props.theme.media.sdesk} {
		min-height: 100vh;
		display: flex;
		flex-direction: column;
	}

	.page-wrap {
		min-width: 20px;

		& > .page {
			padding-top: ${props => props.theme.sizes.header.mobile.num + 8}px;

			${props => props.theme.media.tablet} {
				padding-top: ${props => props.theme.sizes.header.tablet.val};
			}

			${props => props.theme.media.sdesk} {
				padding-top: 0;
			}
		}
	}

	.page-outer {
		${props => props.theme.media.sdesk} {
			display: flex;
			max-width: 100vw;
			min-width: 50vw;
			flex-grow: 1;
		}
	}
	.desk-nav {
		display: none;
		${props => props.theme.media.sdesk} {
			display: flex;
		}
	}
	.page-content {
		max-width: 100%;
		min-width: 20px;
		& > .content-wrap {
			padding-bottom: 2em;

			${props => props.theme.media.sdesk} {
				padding-bottom: 0;
				max-width: 100%;
				min-width: 20px;
			}
			& > .content {
				flex-grow: 1;
				min-width: 20px;
			}
		}
		.content-ctrl-bar {
			& > div:last-child {
				width: ${props =>
					props.theme.sizes.gutter.sdesk.num + props.theme.sizes.gutter.mobile.num}px;
			}
		}
		.content-cards {
		}
		.operations-ctrl {
			display: none;
			${props => props.theme.media.sdesk} {
				display: flex;
			}
		}
		.operations {
			width: 100%;
			${props => props.theme.media.mobile} {
				max-width: 100% !important;
			}
			${props => props.theme.media.sdesk} {
				width: 13vw;
			}
		}
	}
`

const MgrLayout: React.FC = ({ children, ...props }) => {
	const { toggleMobileNav } = useStoreActions(actions => actions.view)

	return (
		<Layout className='layout-mgr' {...props}>
			<MobileNav />
			<div className='page-outer'>
				<DeskAside />
				<Main className='page-wrap'>
					<MgrPageHeader toggleNav={toggleMobileNav} />
					<div className='page'>{children}</div>
				</Main>
			</div>
			<Footer className='main-footer' />
		</Layout>
	)
}
export default MgrLayout
