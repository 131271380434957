import styled, { css } from 'styled-components'
import { useParcelCountiesQuery } from '../../../gql_generated/graphql'
import { startCase } from '../../../utils/lodash.utils'
import { Async } from '../Async'
import { FormFieldWrapProps } from './Form.field.wrap'
import Select from './Select'

export const selectCountyStyles = css``

const SelectCountyView = styled.div`
	${selectCountyStyles}
`

export type SelectCountyProps = FormFieldWrapProps & {
	defaultTxt?: string
}
export const SelectCounty = ({
	defaultTxt = 'Select...',
	...props
}: SelectCountyProps): JSX.Element => {
	const [results] = useParcelCountiesQuery()
	const options = results.data?.counties.map(county => ({
		val: county,
		txt: startCase(county),
	}))
	return (
		<SelectCountyView>
			<Async fetchResults={results}>
				{options ? (
					<Select {...props} options={[{ val: '', txt: defaultTxt }, ...options]} />
				) : null}
			</Async>
		</SelectCountyView>
	)
}
