import styled from 'styled-components'

const BlockSimpleView = styled.div``

const BlockSimple: React.FC<{
	className?: string
}> = ({ className, ...props }) => (
	<BlockSimpleView className={`block block-simple${className ? ` ${className}` : ''}`} {...props} />
)
export default BlockSimple
