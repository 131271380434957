import { css } from 'styled-components/macro'

export const tableBodyRowStyles = css`
	position: relative;

	&:hover {
		z-index: 10;
		&:after {
			background: ${props => props.theme.colors.btn.val};
		}
		cursor: pointer;
		.data-cell {
			&:before {
				transform: translate3d(0, 0, 0);
			}
		}
	}
	td:first-child {
		// to accommodate for last active state
		padding-left: 5px;
		border-left: solid 3px transparent;
		transition: all ${props => props.theme.times.tranS};
	}
	.data-cell {
		overflow: hidden;
		position: relative;
		button {
			color: ${props => props.theme.colors.text.val};
		}
		&:before {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			height: 2px;
			width: 100%;
			background: ${props => props.theme.colors.btn.val};
			transition: all ${props => props.theme.times.tranS};
			transform: translate3d(0, 100%, 0);
		}
	}
	.link-cell {
		.link {
			&:hover {
				color: ${props => props.theme.colors.btn.val};
			}
		}
	}

	.check-ico {
		display: flex;
		height: 1.5em;
		width: 1.5em;
		align-items: center;
		justify-content: center;
		border-radius: 2rem;
		border: solid 1px;
		color: ${props => props.theme.colors.grey.val};
		.icon {
			opacity: 0;
			height: 1em;
			margin: 0;
			svg {
				transform: translateY(-15%);
			}
		}
		&.__selected,
		&:hover {
			.icon {
				opacity: 1;
			}
		}
		&:hover {
			color: ${props => props.theme.colors.secondary.val};
		}
		&.__selected {
			color: ${props => props.theme.colors.aux1.val};
			&:hover {
				color: ${props => props.theme.colors.grey.light().val};
			}
		}
	}

	&.__selected {
		.op-ok {
			color: ${props => props.theme.colors.ok.val};
		}
	}
	&.__last-active {
		td:first-child {
			border-left: solid 3px ${props => props.theme.colors.btn.val};
		}
	}
	&.__disabled {
		cursor: default;
		z-index: 10;
		button.btn,
		input {
			color: ${props => props.theme.colors.grey.light().val} !important;
		}
		td {
			cursor: default;
			position: relative;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				height: 1px;
				width: 100%;
				background: ${props => props.theme.colors.grey.light().val};
				z-index: 1;
			}
		}
		._checkbox-cell {
			.icon {
				opacity: 0;
			}
		}
	}
`
