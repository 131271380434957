import React from 'react'
import styled, { css } from 'styled-components/macro'
import { relatedParcelsColsConfig } from '../../../../Components/UI/Table.resources/Table.resource.col.configs/Parcel.col.configs'
import { ResourcesTable } from '../../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../../Components/UI/Table.resources/usePaginationParams'
import { ParcelFilter, ParcelParent } from '../../../../gql_generated/document_types'
import { usePaginatedParcelsQuery } from '../../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../../Types'
import { RelatedParcelSales } from '../../../Parcel/Related/Parcel.related.sales'
import { RelatedResearchModal } from '../Related.Modals/Research.related.modal'

export const relatedResearchSalesStyles = css``

const RelatedResearchSalesView = styled.div`
	${relatedResearchSalesStyles}
`

export type RelatedResearchSalesProps = {
	parcelIds: number[]
	estateTypes: EstateTypeEnum[]
}

export const RelatedResearchSales: React.FC<RelatedResearchSalesProps> = ({ parcelIds }) => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedParcelsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: ParcelParent.Sale,
					},
				],
				filterOptions: [
					{
						filter: ParcelFilter.ParcelIds,
						vals: parcelIds,
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.parcels
	return (
		<RelatedResearchSalesView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedParcelsColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={({ id }) => (
					<RelatedResearchModal parcelId={id} parcelParent={ParcelParent.Sale}>
						<RelatedParcelSales parcelId={id} />
					</RelatedResearchModal>
				)}
				resourceType='sale'
				suppressGoTo
			/>
		</RelatedResearchSalesView>
	)
}
