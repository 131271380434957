import { css } from 'styled-components'

export const resourcesTableStyles = css`
	.advanced-search-options {
		max-width: 800px;
	}
	.resource-table-header {
		padding-top: 1em;
		padding-bottom: 0.5em;
		display: flex;
		align-items: stretch;
		width: 100%;
		flex-direction: column;
		${props => props.theme.media.tablet} {
			flex-direction: row;
		}
		.resource-table-header-col {
			flex-grow: 1;
			&:last-child {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;

				.misc-ops {
					margin-left: auto;
					margin-bottom: 4px;

					.dropdown-ctrl {
						.download-options-ctrl {
							display: flex;
							align-items: center;

							& > span {
								display: block;
							}

							.icon {
								margin-right: 0;
								margin-left: 4px;
								height: 18px;
							}
						}
					}

					.misc-op-btn {
						padding: 0.5em 1em;
						display: flex;
						align-items: center;
						white-space: nowrap;
					}
				}

				.resource-table-total {
					margin-left: auto;
				}
			}
		}
	}
	.custom-content-section {
	}
	.pagination-section {
		display: flex;
		width: 100%;
		padding-top: 0.5em;
		.pagination {
			margin-left: auto;
		}
	}
`
