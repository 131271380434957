import React from 'react'
import { useAgreementForOgQuery } from '../../../gql_generated/graphql'
import { KeyVals } from '../../UI'

type AgreementOgProps = {
	agreementId: number
}
const AgreementOg: React.FC<AgreementOgProps> = ({ agreementId }) => {
	const [results] = useAgreementForOgQuery({ variables: { id: agreementId } })
	const agreement = results.data?.agreement
	const { og } = agreement || {}
	const { org, renewDate, renewMonth } = og || {}
	return agreement ? (
		<KeyVals
			items={[
				['OG Org', org],
				['Renewal Date', renewDate],
				['Renewal Month', renewMonth],
			]}
		/>
	) : null
}
export default AgreementOg
