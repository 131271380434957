import React from 'react'
import styled, { css } from 'styled-components/macro'
import { relatedDocsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Doc.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { DocParent } from '../../../gql_generated/document_types'
import { usePaginatedDocsQuery } from '../../../gql_generated/graphql'
import { RelatedEasementDocModal } from '../Related.Modals/Easement.related.doc.modal'

export const relatedEasementDocStyles = css``

const RelatedEasementDocsView = styled.div`
	${relatedEasementDocStyles}
`

export type RelatedEasementDocsProps = {
	easementId: number
}
export const RelatedEasementDocs: React.FC<RelatedEasementDocsProps> = ({ easementId }) => {
	const paginationStore = usePaginationParams()

	const [results] = usePaginatedDocsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: DocParent.Easement,
						parentIds: [easementId],
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.docs

	return (
		<RelatedEasementDocsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedDocsColsConfig}
				fetchResults={results}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedEasementDocModal docId={data.id} />}
				resourceType='doc'
			/>
		</RelatedEasementDocsView>
	)
}
