import React from 'react'
import styled, { css } from 'styled-components/macro'
import { relatedDocsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Doc.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { DocParent } from '../../../gql_generated/document_types'
import { usePaginatedDocsQuery } from '../../../gql_generated/graphql'
import { RelatedOrgDocModal } from '../Related.Modals/Org.related.doc.modal'

export const relatedOrgDocStyles = css``

const RelatedOrgDocsView = styled.div`
	${relatedOrgDocStyles}
`

export type RelatedOrgDocsProps = {
	orgId: number
}
export const RelatedOrgDocs: React.FC<RelatedOrgDocsProps> = ({ orgId }) => {
	const paginationStore = usePaginationParams()

	const [results] = usePaginatedDocsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: DocParent.Org,
						parentIds: [orgId],
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.docs

	return (
		<RelatedOrgDocsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedDocsColsConfig}
				fetchResults={results}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedOrgDocModal docId={data.id} />}
				resourceType='doc'
			/>
		</RelatedOrgDocsView>
	)
}
