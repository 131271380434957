import { RouteComponentProps } from '@reach/router'
import styled, { css } from 'styled-components'
import { Async, Btn, Card, Modal, PrintWindow, useModalCtrl } from '../../../Components/UI'
import CardsSection from '../../../Components/common/CardsSection'
import { LeasedAcresPdfForm } from '../../../Components/common/LeasedAcres.pdf'
import { OwnedAcresPdf } from '../../../Components/common/OwnedAcres.pdf'
import Page from '../../../Components/common/Resources/Resource.page'
import { useOwnedAcresForOwnedAcresPdfQuery } from '../../../gql_generated/graphql'
import { formatDate, usePageData } from '../../../utils'

const CountySnapshotsReportPageStyles = css`
	.ctas {
		display: flex;
		column-gap: 1rem;
	}
`

const CountySnapshotsReportPageView = styled.div`
	${CountySnapshotsReportPageStyles}
`

type CountySnapshotsReportPageProps = RouteComponentProps & {
	className?: string
}
export const CountySnapshotsReportPage = ({
	className,
}: CountySnapshotsReportPageProps): JSX.Element => {
	usePageData({ pageTitle: 'County Snapshots', icon: 'report' })
	const [[showOwnershipModal, openOwnershipModal, closeOwnershipModal]] = useModalCtrl()
	const [[showLeasedModal, openLeasedModal, closeLeasedModal]] = useModalCtrl()
	const [results] = useOwnedAcresForOwnedAcresPdfQuery() // We want to do this network fetch here before we try and load the PDF window
	return (
		<CountySnapshotsReportPageView
			className={`county-snapshots-report-page${className ? ` className` : ''}`}
		>
			<Page isLoaded>
				<CardsSection>
					<Card>
						<div className='ctas'>
							<Btn className='cta' onClick={openOwnershipModal}>
								Owned Acres Snapshot
							</Btn>
							<Btn className='cta' onClick={openLeasedModal}>
								Leased Acres Snapshot
							</Btn>
						</div>
					</Card>
				</CardsSection>
			</Page>
			<Modal show={showOwnershipModal} closeHandler={closeOwnershipModal}>
				<Async fetchResults={results}>
					{results.data ? (
						<PrintWindow
							orientation='landscape'
							margin='0.5in'
							PDF={<OwnedAcresPdf />}
							fileName={`NNR-Owned-Acres_report.${formatDate(new Date(), 'mm-dd-yyyy')}`}
							cb={closeOwnershipModal}
						/>
					) : null}
				</Async>
			</Modal>
			<Modal show={showLeasedModal} closeHandler={closeLeasedModal}>
				<LeasedAcresPdfForm cb={closeLeasedModal} />
			</Modal>
		</CountySnapshotsReportPageView>
	)
}
