import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import {
	Easement,
	EasementUpdateStatusInput,
	useUpdateEasementStatusMutation,
} from '../../../gql_generated/graphql'
import { formatDate, parseDateForServer } from '../../../utils'
import { Form, SubmitBtn, Text } from '../../UI'

const OpTerminateEasementView = styled.div``

const schema = Yup.object().shape({
	dateTerminateled: Yup.string().required('Please enter a date for termination'),
})

type OpTerminateEasementProps = {
	easement: Easement
	cb?: () => void
}
const OpTerminateEasement: React.FC<OpTerminateEasementProps> = ({ easement, cb }) => {
	const { id } = easement
	const [_, updateStatus] = useUpdateEasementStatusMutation()

	return (
		<OpTerminateEasementView>
			<Form
				legend='Terminate Easement'
				initialValues={{
					dateTerminated: formatDate(new Date(), 'input'),
				}}
				onSubmit={async (data: EasementUpdateStatusInput) => {
					try {
						await updateStatus({
							data: {
								id,
								dateTerminated: parseDateForServer(data.dateTerminated),
							},
						})
						if (cb) cb()
					} catch (err) {}
				}}
				validationSchema={schema}
			>
				{() => (
					<>
						<Text type='date' name='dateTerminated' label='Date Terminated' isReq />
						<SubmitBtn>Terminate Easement</SubmitBtn>
					</>
				)}
			</Form>
		</OpTerminateEasementView>
	)
}
export default OpTerminateEasement
