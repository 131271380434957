import React from 'react'
import styled, { css } from 'styled-components/macro'
import { each } from '../../../../utils/lodash.utils'
import { P } from '../../../UI'
import { AddDocToResourceConfirmProps } from './Ops.AddDocToResource.confirm'

export const addDocToResourceConfirmParcelsConfigPreviewStyles = css``

const AddDocToResourceConfirmParcelsConfigPreviewView = styled.div`
	${addDocToResourceConfirmParcelsConfigPreviewStyles}
`

export type AddDocToResourceConfirmParcelsConfigPreviewProps = {
	parentType: AddDocToResourceConfirmProps['parentType']
	values: {
		autoAddParcels: boolean
		configureEstates: boolean
		configureCounties: boolean
		counties: any
		estateTypes: any
	}
	counties: string[]
	estateTypes: string[]
}
export const AddDocToResourceConfirmParcelsConfigPreview: React.FC<AddDocToResourceConfirmParcelsConfigPreviewProps> = ({
	parentType,
	values,
	counties,
	estateTypes,
}) => {
	const countiesUsed = [] as string[]
	const estateTypesUsed = [] as string[]
	const { configureCounties, configureEstates } = values

	each(values.counties, (isSelected, county) => {
		if (isSelected) countiesUsed.push(county)
	})

	each(values.estateTypes, (isSelected, estateType) => {
		if (isSelected) estateTypesUsed.push(estateType)
	})

	const estateTypesForRender =
		configureEstates && estateTypesUsed.length
			? estateTypesUsed.join(', ')
			: estateTypes?.length
			? estateTypes.join(', ')
			: ''

	const countiesForRender =
		configureCounties && countiesUsed.length
			? countiesUsed.join(', ')
			: counties?.length
			? counties.join(', ')
			: ''

	const isCountiesPlural = configureCounties ? countiesUsed.length > 1 : counties.length > 1
	return (
		<AddDocToResourceConfirmParcelsConfigPreviewView>
			<P>
				Auto-adding {estateTypesForRender ? ` the [${estateTypesForRender}]` : 'all'} parcel estates
				{countiesForRender
					? ` in [${countiesForRender}] ${isCountiesPlural ? 'counties' : 'county'}`
					: ''}{' '}
				on this {parentType} to the doc.
			</P>
		</AddDocToResourceConfirmParcelsConfigPreviewView>
	)
}
