import React from 'react'
import { useEasementForInfoQuery } from '../../../gql_generated/graphql'
import { sumBy } from '../../../utils/lodash.utils'
import { KeyVals } from '../../UI'

type EasementInfoProps = {
	easementId: number
}
const EasementInfo: React.FC<EasementInfoProps> = ({ easementId }) => {
	const [results] = useEasementForInfoQuery({ variables: { id: easementId } })
	const easement = results.data?.easement
	const { id, grantee, granteeDba, easementType, dateEffective, escrowId, activity, price } =
		easement || {}
	return easement ? (
		<KeyVals
			items={[
				['DB ID', id],
				escrowId ? ['Escrow ID', escrowId] : null,
				['Effective Date', dateEffective, 'date'],
				['Grantee', granteeDba || grantee, 'org'],
				['Easement Type', easementType, 'proper'],
				['Price', price, 'currency'],
				['Acres', sumBy(activity, ({ acres, estate }) => acres || estate.acres), 'acres'],
			]}
		/>
	) : null
}
export default EasementInfo
