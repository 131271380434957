import unset from 'lodash/unset'
import React, { useRef } from 'react'
import styled from 'styled-components'
import {
	OrgUpdateInput,
	useOrgFullQuery,
	useUpdateOrgMutation,
} from '../../../gql_generated/graphql'
import { sanitizeDataForForm } from '../../../utils'
import { ErrorBlock, Form, Spinner, SubmitBtn, Tab, Tabs } from '../../UI'
import OrgContactsForm from './Ops.Org.contacts'
import { OrgFields, orgSchema } from './Ops.Org.fields'

const EditOrgView = styled.div``

type EditOrgProps = {
	id: number
	cb?: (org: any) => void
	isOnOrgPage?: boolean
}
const EditOrg: React.FC<EditOrgProps> = ({ cb, id, isOnOrgPage }) => {
	const isDone = useRef(false)
	const [{ data, fetching, error }] = useOrgFullQuery({
		variables: { id },
	})
	const { org } = data || {}
	const [_, updateOrg] = useUpdateOrgMutation()

	const initialVals = sanitizeDataForForm<OrgUpdateInput>(org, {
		stripKeys: isOnOrgPage ? ['contacts'] : undefined,
	})

	if (initialVals)
		initialVals.dbas = initialVals?.dbas?.length
			? initialVals.dbas
			: [
					{
						name: '',
					},
			  ]

	return isDone.current ? null : fetching ? (
		<Spinner />
	) : error ? (
		<ErrorBlock error={error} />
	) : (
		<EditOrgView>
			<Form<OrgUpdateInput>
				legend={`Edit ${org ? org.name : 'Org'}`}
				initialValues={initialVals}
				onSubmit={async data => {
					try {
						const vals = { ...data }
						if (data.contacts?.length)
							vals.contacts = data.contacts.map(contact => {
								unset(contact, 'fullName')
								contact.primary = contact.primary?.length ? contact.primary : null
								return contact
							})

						vals.phone = vals.phone?.length ? vals.phone : null
						vals.email = vals.email?.length ? vals.email : null

						const dbas = vals.dbas
							?.filter(({ name }: any) => !!name)
							.map(({ id, name }) => ({ id, name }))

						vals.dbas = dbas?.length ? dbas : null

						const res = await updateOrg({
							data: vals,
						})
						if (!res.error && cb) {
							isDone.current = true
							cb(res)
						}
					} catch (err) {
						throw err
					}
				}}
				validationSchema={orgSchema}
			>
				{() => (
					<>
						{isOnOrgPage ? (
							<OrgFields />
						) : (
							<Tabs>
								<Tab nav='Org' icon='org'>
									<OrgFields />
								</Tab>
								<Tab nav='Contacts' icon='contacts'>
									<OrgContactsForm orgId={id} />
								</Tab>
							</Tabs>
						)}
						<SubmitBtn />
					</>
				)}
			</Form>
		</EditOrgView>
	)
}
export default EditOrg
