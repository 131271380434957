import React from 'react'
import styled from 'styled-components'
import { useSaleForOgQuery } from '../../../gql_generated/graphql'
import { KeyVals } from '../../UI'

const SaleOgView = styled.div``

type SaleOgProps = {
	saleId: number
}
const SaleOg: React.FC<SaleOgProps> = ({ saleId }) => {
	const [results] = useSaleForOgQuery({ variables: { id: saleId } })

	const og = results.data?.sale?.og

	const {
		acres,
		cashDownpayment,
		financeTerms,
		pricePerAcre,
		titleCompany,
		broker,
		grantee,
		brokerCommission,
	} = og || {}
	return (
		<SaleOgView>
			<KeyVals
				items={[
					['Acres', acres, 'acres'],
					['Cash Downpayment', cashDownpayment, 'currency'],
					['Finance Terms', financeTerms],
					['Price Per Acre', pricePerAcre, 'currency'],
					['OG Grantee', grantee],
					['OG Broker', broker],
					['OG Broker Commission', brokerCommission, 'currency'],
					['OG Title Company', titleCompany],
				]}
			/>
		</SaleOgView>
	)
}
export default SaleOg
