import { useEffect, useRef } from 'react'
import { useAuth } from '../auth'
import { UserRole } from '../gql_generated/document_types'
import { useStoreState } from '../store'
import useState from './useState'

type UseProtectOptions = {
	allowed?: UserRole[]
}

const useProtect = ({ allowed }: UseProtectOptions) => {
	const authenticate = useAuth()
	const { isAuthed, role, isAdmin, isMgr } = useStoreState(state => state.user)

	const [hasAuthed, setHasAuthed] = useState(isAuthed, 'hasAuthed')

	const isProcessing = useRef(false)

	// If specific roles are passed in we should enforce them
	// NOTE: If no roles are passed in then only admins and mgrs will be allowed by default.
	const isAllowed = allowed?.length ? !!allowed.find(okRole => okRole === role) : true

	// admins and managers always have access (for now at least)
	const hasPermissions = isAuthed && (isAdmin || isMgr || isAllowed)

	// if (isDev())
	// 	console.log(
	// 		'is authed:',
	// 		hasAuthed,
	// 		'evaluating role: ',
	// 		role,
	// 		' against: ',
	// 		allowed,
	// 		' as: ',
	// 		hasPermissions
	// 	)

	useEffect(() => {
		const auth = async () => {
			isProcessing.current = true
			try {
				await authenticate()
				// console.log('useProtect - auth success!')
				isProcessing.current = false
				setHasAuthed(true)
			} catch (err) {
				console.error('useProtect -  auth error: ', err)
				isProcessing.current = false
				setHasAuthed(true)
			}
		}
		if (!hasAuthed && !isProcessing.current) auth()
	}, [hasAuthed, authenticate])

	return [hasPermissions, hasAuthed]
}
export default useProtect
