import Skeleton, { SkeletonProps } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styled from 'styled-components'

const SkeletonWrap = styled.div`
	.react-loading-skeleton {
		border-radius: 0;
	}
`

const SkeletonLoader = (props: SkeletonProps): JSX.Element => {
	return (
		<SkeletonWrap className='skeleton-wrap'>
			<Skeleton {...props} />
		</SkeletonWrap>
	)
}
export default SkeletonLoader
