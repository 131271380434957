import React, { ReactChild } from 'react'
import { useRef } from 'react'
import styled from 'styled-components'
import { useState } from '../../../utils'
import { Btn } from '../Typography'

const AccordionView = styled.div`
	.ctrl {
		padding: 0.5em 1em;
		color: ${props => props.theme.colors.btn.val};
		margin-bottom: 0.5em;
		border-left: solid 3px;
		&:hover {
			background-color: ${props => props.theme.colors.grey.tint(10).val};
		}
	}
	.ctrl-group {
		overflow: hidden;
		transition: all ${props => props.theme.times.tranS};
		.inner-wrap {
		}
	}
`

type AccordionProps = {
	ctrl:
		| ReactChild
		| {
				open: ReactChild
				closed: ReactChild
		  }
	startsOpen?: boolean
	afterOpen?: () => void
	afterClose?: () => void
	override?: boolean
}
export const Accordion: React.FC<AccordionProps> = ({
	children,
	startsOpen = false,
	afterClose,
	afterOpen,
	ctrl,
	override,
}) => {
	const [isAccordionOpen, setOpen] = useState(startsOpen, 'isAccordionOpen')
	const inner = useRef<null | HTMLDivElement>(null)

	const height = inner.current?.offsetHeight

	const isOpen = typeof override === 'boolean' ? override : isAccordionOpen

	let ctrlElement: any = ctrl

	if (ctrlElement?.open && isOpen) ctrlElement = ctrlElement.open
	else if (ctrlElement?.closed && !isOpen) ctrlElement = ctrlElement.closed

	const handler = () => {
		let fireOpen = isOpen
		setOpen(state => {
			fireOpen = !state

			return !state
		})

		if (fireOpen && afterOpen) afterOpen()
		else if (!fireOpen && afterClose) afterClose()
	}

	return (
		<AccordionView className='accordion'>
			<Btn onClick={handler} className='ctrl'>
				{ctrlElement}
			</Btn>
			<div
				className='ctrl-group'
				style={{
					maxHeight: isOpen ? `${height}px` : '0',
				}}
			>
				<div className='inner-wrap' ref={inner}>
					{children}
				</div>
			</div>
		</AccordionView>
	)
}
