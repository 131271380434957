import { Duration } from 'date-fns'
import add from 'date-fns/add'
import sortBy from 'lodash/sortBy'
import { LeaseSchedInterval, LeaseTerms } from '../gql_generated/document_types'
import { formatDate, isPastDate } from './utils'

export const calcLeasePaymentDate = ({
	term,
	hasRenewal,
}: {
	term?: LeaseTerm
	hasRenewal?: boolean
}): string | null => {
	if (!term) return null
	const { schedInterval, schedAmount, dateFrom, dateTo, hasContClause } = term
	if (schedInterval === LeaseSchedInterval.Single || !dateFrom) return 'N/A'

	const now = new Date()

	// Create a duration object from term data
	const interval: Duration = {}
	interval[schedInterval || 'months'] = schedAmount

	// We want to calculate payment starting from the term from date
	let paymentDate = new Date(dateFrom)

	// We don't want to calculate any dates beyond the next one after today
	// TODO: add support to return all dates within a range (e.g. all due dates for the next 5 years)
	while (paymentDate < now) {
		paymentDate = add(paymentDate, interval)
	}

	// The above "add" calculation results in the day before the correct date
	paymentDate = add(paymentDate, { days: 1 })

	const expDate = (dateTo && new Date(dateTo)) as Date

	let shouldCalcDate = hasContClause || hasRenewal || (expDate && paymentDate < expDate)

	// const currentYear = now.getFullYear()
	// const expYear = expDate ? add(expDate, { years: 1 }).getFullYear() : currentYear

	return shouldCalcDate ? formatDate(paymentDate) : 'N/A'
}

export const getCurrentLeaseFee = (lease?: any) => {
	const { fees } = lease || {}

	if (!fees?.length)
		return {
			amount: 0,
		}

	const sortedFees = sortBy(fees, 'createdAt').reverse()

	return sortedFees[0]
}

type LeaseTerm = Pick<
	LeaseTerms,
	| 'dateTo'
	| 'dateFrom'
	| 'schedAmount'
	| 'schedInterval'
	| 'hasContClause'
	| 'rate'
	| 'hasPurchaseOption'
	| 'termNotes'
	| 'hasTaxReimbursements'
>
type GetCurrentLease = {
	terms: LeaseTerm[]
	[x: string]: any
}
export const getCurrentLeaseTerms = (lease?: GetCurrentLease | null) => {
	const { terms } = lease || {}

	if (!terms?.length) return null
	else if (terms.length === 1) return terms[0]

	const sortedTerms = sortBy(terms, ({ dateTo }) => (dateTo ? Date.parse(dateTo) : false)).reverse()

	return sortedTerms[0]
}

export const calcPastExpiration = (lease?: GetCurrentLease | null) => {
	const { dateTo } = getCurrentLeaseTerms(lease) || {}

	return dateTo ? isPastDate(dateTo) : false
}

export const calcIsSinglePayment = (
	lease?:
		| (GetCurrentLease & {
				terms: Array<LeaseTerm & Pick<LeaseTerms, 'schedInterval'>>
		  })
		| null
) => {
	const { schedInterval } = getCurrentLeaseTerms(lease) || {}
	return schedInterval === LeaseSchedInterval.Single
}
