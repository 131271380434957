import styled, { css } from 'styled-components'
import { FormFieldWrapProps } from './Form.field.wrap'
import Input, { InputProps } from './Input'

export const dateFieldStyles = css``

const DateFieldView = styled.div`
	${dateFieldStyles}
`

export type DateFieldProps = Omit<InputProps, 'type'> & FormFieldWrapProps & {}
export const DateField = (props: DateFieldProps): JSX.Element => {
	return (
		<DateFieldView>
			<Input {...props} type='date' />
		</DateFieldView>
	)
}
