import isPlainObject from 'lodash/isPlainObject'
import isArray from 'lodash/isArray'
import reduce from 'lodash/reduce'
import includes from 'lodash/includes'
import { formatDate } from './utils'

type SanitizeForFormOpts = {
	stripKeys?: string[]
	formatVal?: (val: any, key?: string) => any
	formatOverride?: (val: any, key?: string) => any
}
const sanitizeDataForForm = <T>(data: any, opts?: SanitizeForFormOpts): T => {
	const { stripKeys, formatVal, formatOverride } = opts || {}

	const sanitizer = (data: any, propName?: string): any => {
		if (formatOverride) {
			return formatOverride(data, propName)
		} else if (isPlainObject(data)) {
			return reduce(
				data,
				(obj: any, val, key) => {
					if (
						key === '__typename' ||
						key === 'og' ||
						key === 'activity' ||
						key === 'broker' ||
						key === 'grantee' ||
						key === 'titleCompany' ||
						key === 'lessee' ||
						key === 'org' ||
						key === 'createdAt' ||
						key === 'createdBy' ||
						includes(stripKeys, key)
					)
						return obj

					obj[key] = sanitizer(val, key)
					return obj
				},
				{}
			)
		} else if (isArray(data)) {
			return data.map(val => sanitizer(val))
		} else if (data === null) {
			return ''
		} else if (propName) {
			if (propName.slice(0, 4) === 'date') {
				return formatDate(data, 'input') || ''
			} else return formatVal ? formatVal(data, propName) : data
		} else return formatVal ? formatVal(data, propName) : data
	}

	const sanitized = isPlainObject(data) || isArray(data) ? sanitizer(data) : data
	// console.log('Finished sanitizing: ', sanitized)
	return sanitized
}

export default sanitizeDataForForm
