/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import styled from 'styled-components'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import FormFieldWrap, { FormFieldWrapProps } from './Form.field.wrap'
import { useField } from 'formik'
import { InputProps } from './Input'

const PhoneFieldView = styled.div`
	.react-tel-input {
		input.form-control,
		.flag-dropdown {
			background: none;
			border: none;
			height: auto;
			line-height: inherit;
			font-size: inherit;
			border-radius: 0;
		}
	}
`

type PhoneFieldProps = InputProps & FormFieldWrapProps & {}
const PhoneField: React.FC<PhoneFieldProps> = ({
	label,
	name,
	isReq,
	placeholder,
	helperTxt,
	...props
}) => {
	const [{ value }, _, { setValue }] = useField({ name, ...props })
	return (
		<FormFieldWrap type='phone-field' label={label} name={name} isReq={isReq} helperTxt={helperTxt}>
			<PhoneFieldView>
				<PhoneInput
					country='us'
					preferredCountries={['us']}
					regions={['north-america', 'europe']}
					copyNumbersOnly={false}
					value={value}
					onChange={(__, ___, ____, val) => setValue(val)}
					placeholder={placeholder}
				/>
			</PhoneFieldView>
		</FormFieldWrap>
	)
}
export default PhoneField
