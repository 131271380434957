import { css } from 'styled-components'

export type CTAStyleProps = {
	width?: string
}
export const ctaStyles = css<CTAStyleProps>`
	width: ${props => (props.width ? props.width : 'auto')};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5em 1em;
	text-transform: uppercase;
	font-size: 1.1em;
	white-space: nowrap;
	border: solid 2px;
	position: relative;
	color: ${props => props.theme.colors.btn.val};
	min-height: 3.5em;
	&:disabled {
		color: ${props => props.theme.colors.disabled.val} !important;
		border-color: ${props => props.theme.colors.disabled.val} !important;
		background: none !important;
	}

	&:hover {
		background: ${props => props.theme.colors.btn.val};
		color: ${props => props.theme.colors.white.val};
		border-color: ${props => props.theme.colors.btn.val};
		&:disabled {
			color: ${props => props.theme.colors.disabled.val} !important;
			border-color: ${props => props.theme.colors.disabled.val} !important;
			background: none !important;
		}
	}
	&:active {
		color: ${props => props.theme.colors.white.val};
		background: ${props => props.theme.colors.btn.light(1).val};
		border-color: ${props => props.theme.colors.btn.light(1).val};
	}
	&.light {
		color: ${props => props.theme.colors.white.val};
		&:hover {
			border-color: ${props => props.theme.colors.white.val};
		}
		&:active {
			border-color: ${props => props.theme.colors.white.val};
		}
	}

	${props => props.theme.media.tablet} {
		padding: 1em 2em;
	}
	${props => props.theme.media.sdesk} {
		font-size: 1.2em;
	}
`
