import React, { ReactElement } from 'react'

import styled from 'styled-components'
import { useState } from '../../../utils'

import { Btn } from '../Typography'
import PrintWindow from './Print.window'

const PrintBtnView = styled.div``

type PrintBtnProps = {
	PDF: ReactElement
}
export const PrintBtn: React.FC<PrintBtnProps> = ({ children, PDF }) => {
	const [print, setPrint] = useState(false)
	return (
		<>
			<PrintBtnView>
				<Btn onClick={() => setPrint(true)}>{children || 'Print PDF'}</Btn>
			</PrintBtnView>
			{print ? <PrintWindow PDF={PDF} cb={() => setPrint(false)} /> : null}
		</>
	)
}
