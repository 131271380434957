import { navigate, RouteComponentProps } from '@reach/router'
import toNumber from 'lodash/toNumber'
import React, { useEffect } from 'react'
import Cards from '../../Components/common/CardsColumns'
import CardsSection from '../../Components/common/CardsSection'
import ExhibitForm from '../../Components/common/Exhibit'
import LeaseDates from '../../Components/common/Lease/Lease.dates'
import LeaseFinancial from '../../Components/common/Lease/Lease.financial'
import LeaseInfo from '../../Components/common/Lease/Lease.info'
import LeaseOg from '../../Components/common/Lease/Lease.og'
import { PageOperation } from '../../Components/common/Operations'
import Page from '../../Components/common/Resources/Resource.page'
import { AddDocToResourceOp } from '../../Components/Ops/Ops.AddDocToResource'
import EditLease from '../../Components/Ops/Ops.Leases/Ops.Lease.edit'
import OpExpireLease from '../../Components/Ops/Ops.Leases/Ops.Lease.expire'
import LeaseFee from '../../Components/Ops/Ops.Leases/Ops.Lease.fee'
import { LeaseFinancialNotesOp } from '../../Components/Ops/Ops.Leases/Ops.Lease.financial.notes'
import OpTerminateLease from '../../Components/Ops/Ops.Leases/Ops.Lease.terminate'
import LeaseTerm from '../../Components/Ops/Ops.Leases/Ops.Lease.terms'
import { Alert, Async, Card, Colorize, Modal, P } from '../../Components/UI'
import { UserRole } from '../../gql_generated/document_types'
import { Lease, useDeleteLeaseMutation, useLeaseFullQuery } from '../../gql_generated/graphql'
import { EstateTypeEnum } from '../../Types'
import { calcPastExpiration, usePageData, useState, useToaster } from '../../utils'
import LeaseRelatedResources from './Related/Lease.related'

interface leasePageProps extends RouteComponentProps {
	id?: number
}
const LeasePage: React.FC<leasePageProps> = props => {
	const id = toNumber(props.id)
	const [Op, setOp] = useState<string | null>(null, 'Op')
	const [results] = useLeaseFullQuery({ variables: { id } })
	const { data, fetching } = results

	const [_, deleteLease] = useDeleteLeaseMutation()

	const { lease } = data || {}

	const { status, leaseId, notes, dateExpired, financialNotes } = lease || {}

	const toast = useToaster()

	const [updatePageInfo] = usePageData({ pageType: 'lease' })

	const shouldBeExpired = status === 'Active' && calcPastExpiration(lease as Lease)

	useEffect(() => {
		updatePageInfo({
			pageTitle: `Lease ID: ${leaseId}`,
			status: shouldBeExpired && !dateExpired ? 'Past Expiration' : status,
		})
	}, [leaseId, updatePageInfo, status, dateExpired, shouldBeExpired])

	const operations: PageOperation[] = [
		{
			txt: 'Edit Lease',
			icon: 'edit',
			op: () => setOp('edit'),
			allowed: [UserRole.Accountant],
		},
		// {
		// 	txt: 'Update Terms',
		// 	icon: 'calEdit',
		// 	hide: status !== 'Active',
		// 	op: () => setOp('term'),
		// 	allowed: [UserRole.Accountant],
		// },
		{
			txt: 'Update Fee',
			icon: 'coins',
			hide: status !== 'Active',
			op: () => setOp('fee'),
			allowed: [UserRole.Accountant],
		},
		{
			txt: 'Financial Notes',
			icon: 'financialNotes',
			op: () => setOp('financial-notes'),
			allowed: [UserRole.Accountant],
		},
		{
			txt: 'Expire Lease',
			icon: 'expire',
			hide: status !== 'Active' || !shouldBeExpired,
			op: () => setOp('expire'),
		},
		{
			txt: 'Generate Exhibit',
			icon: 'exhibit',
			op: () => setOp('exhibit'),
			hide: !lease?.activity?.length,
			allowed: [UserRole.Accountant, UserRole.Officer],
		},
		{
			txt: 'Terminate Lease',
			icon: 'terminate',
			hide: status !== 'Active' && status !== 'Expired',
			op: () => setOp('terminate'),
		},
		{
			txt: 'Add Doc',
			icon: 'doc',
			op: () => setOp('doc'),
		},
		{
			txt: 'Delete',
			icon: 'x',
			op: () => setOp('delete'),
		},
	]

	const deleteOp = async (shouldDelete: boolean) => {
		if (shouldDelete) {
			try {
				await deleteLease({ id: toNumber(id) })
				navigate('/mgr/leases')
			} catch (err) {
				toast('Error deleting lease', { type: 'err' })
			}
		} else setOp(null)
	}

	const opCb = () => {
		setOp(null)
	}

	return (
		<Page isLoaded={!fetching} operations={operations}>
			<Async fetchResults={results}>
				<CardsSection isCollapsable>
					<Cards className='cols'>
						<Card titleText='Lease Info'>
							<LeaseInfo leaseId={id} />
						</Card>
						<Card titleText='Dates'>
							<LeaseDates leaseId={id} />
						</Card>
						<Card titleText='Financial Terms'>
							<LeaseFinancial leaseId={id} />
						</Card>
					</Cards>
					<Cards className='cols'>
						<Card titleText='Notes'>
							<P asRT>{notes}</P>
						</Card>
						<Card titleText='Financial Notes'>
							<P asRT>{financialNotes}</P>
						</Card>
						<Card titleText='Original (OG) Data (from Xogenous)'>
							<LeaseOg leaseId={id} />
						</Card>
					</Cards>
				</CardsSection>
				<LeaseRelatedResources leaseId={id} />
			</Async>

			<Modal show={!!Op && Op !== 'delete'} closeHandler={() => setOp(null)}>
				{Op === 'edit' ? <EditLease leaseId={id} cb={opCb} /> : null}
				{Op === 'term' ? (
					<LeaseTerm leaseId={id as number} cb={opCb} />
				) : Op === 'expire' ? (
					<OpExpireLease lease={lease as Lease} cb={opCb} />
				) : Op === 'terminate' ? (
					<OpTerminateLease lease={lease as Lease} cb={opCb} />
				) : Op === 'fee' ? (
					<LeaseFee lease={lease as Lease} cb={opCb} />
				) : Op === 'financial-notes' ? (
					<LeaseFinancialNotesOp leaseId={id} cb={opCb} />
				) : Op === 'exhibit' ? (
					<ExhibitForm
						resource={{ lease: lease as Lease }}
						cb={() => setOp(null)}
						estateType={lease?.estateType as EstateTypeEnum}
					/>
				) : Op === 'doc' ? (
					<AddDocToResourceOp parentId={id} parentType='lease' cb={opCb} />
				) : null}
			</Modal>
			{Op === 'delete' ? (
				<Alert
					msg='Are you sure you want to delete this lease? (Cannot be undone)'
					cb={deleteOp}
					okBtn='Cancel'
					cancelBtn={
						<Colorize color='err' asBlock>
							Delete Permanently
						</Colorize>
					}
					useCancelAsOp
				/>
			) : null}
		</Page>
	)
}
export default LeasePage
