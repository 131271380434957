import React from 'react'
import styled, { css } from 'styled-components/macro'
import { parcelsPageColsConfig } from '../../../../Components/UI/Table.resources/Table.resource.col.configs/Parcel.col.configs'
import { ResourcesTable } from '../../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../../Components/UI/Table.resources/usePaginationParams'
import { ParcelFilter, ParcelParent } from '../../../../gql_generated/document_types'
import { usePaginatedParcelsQuery } from '../../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../../Types'
import { RelatedParcelAgreements } from '../../../Parcel/Related/Parcel.related.agreements'
import { RelatedResearchModal } from '../Related.Modals/Research.related.modal'

export const relatedResearchAgreementsStyles = css``

const RelatedResearchAgreementsView = styled.div`
	${relatedResearchAgreementsStyles}
`

export type RelatedResearchAgreementsProps = {
	parcelIds: number[]
	estateTypes: EstateTypeEnum[]
}

export const RelatedResearchAgreements: React.FC<RelatedResearchAgreementsProps> = ({
	parcelIds,
}) => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedParcelsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: ParcelParent.Agreement,
					},
				],
				filterOptions: [
					{
						filter: ParcelFilter.ParcelIds,
						vals: parcelIds,
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.parcels
	return (
		<RelatedResearchAgreementsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={parcelsPageColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={({ id }) => (
					<RelatedResearchModal parcelId={id} parcelParent={ParcelParent.Agreement}>
						<RelatedParcelAgreements parcelId={id} />
					</RelatedResearchModal>
				)}
				resourceType='agreement'
				suppressGoTo
			/>
		</RelatedResearchAgreementsView>
	)
}
