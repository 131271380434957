import { useField } from 'formik'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { EM } from '..'
import ErrorBlock from '../Error.block'
import FormLabel from './Form.label'

const FormFieldView = styled.div`
	padding: 0.5em 1em;
	border: solid 1px ${props => props.theme.colors.grey.val};
	border-radius: 4px;
	margin-bottom: 0.5em;
	background-color: ${props => props.theme.colors.paleGrey.val};

	input,
	select,
	textarea {
		border-radius: 4px;
		background: ${props => props.theme.colors.white.val};
		border: none;
		outline: none;
		box-shadow: none;
		border-style: none;
		font-size: 15px;
		font-weight: 700;
		padding: 0.5em;
		padding-left: 1em;
		width: 100%;
		&::placeholder {
			font-weight: 400;
		}
		${props => props.theme.media.tablet} {
			font-size: 1em;
		}
	}

	select {
		font-weight: 400;
	}

	label {
		margin-bottom: 0.5em;
		display: block;
		color: ${props => props.theme.colors.grey.val};
	}

	.input-wrap {
		.req {
			position: absolute;
			top: 0.2em;
			left: 0.2em;
		}
	}
	&.__simple {
		display: inline-flex;
		border: none;
		flex-direction: row-reverse;
		align-items: center;
		padding-left: 0;
		grid-gap: 1em;
		width: auto;
		max-width: initial;
		background: none;
		label {
			margin: 0;
		}
	}

	.helper-text {
		color: ${props => props.theme.colors.grey.light(1).val};
		margin-top: 3px;
	}
`

export const FormFieldRawWrap: React.FC<
	FormFieldWrapProps & {
		type?: string
		error?: string | false
		className?: string
	}
> = ({ label, name, type, isReq, error, helperTxt, isSimple, children, className }) => {
	return (
		<FormFieldView
			className={`form-field ${type || 'generic'}-field${isSimple ? ' __simple' : ''}${
				className ? ` ${className}` : ''
			}`}
		>
			{label ? (
				<FormLabel htmlFor={name}>
					{isReq ? <span className='req'>*</span> : null}
					{label}
				</FormLabel>
			) : null}
			<div className='input-wrap'>
				{!label && isReq ? <span className='req'>*</span> : null}
				{children}
			</div>
			{helperTxt ? <EM className='helper-text'>~{helperTxt}</EM> : null}
			{error ? <ErrorBlock>{error}</ErrorBlock> : null}
		</FormFieldView>
	)
}

export type FormFieldWrapProps = {
	name: string
	label?: ReactNode
	helperTxt?: ReactNode
	isReq?: boolean
	isSimple?: boolean
}
const FormFieldWrap: React.FC<
	FormFieldWrapProps & {
		type: string
	}
> = ({ name, ...props }) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, { error, touched }] = useField({ name })
	return <FormFieldRawWrap name={name} error={touched && error} {...props} />
}
export default FormFieldWrap
