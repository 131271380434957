import React from 'react'
import styled, { css } from 'styled-components'
import { useOrgForDbasQuery } from '../../../gql_generated/graphql'

export const orgDbasStyles = css`
	li {
		padding: 0.3em 0;
	}
`

const OrgDbasView = styled.ul`
	${orgDbasStyles}
`

type OrgDbasProps = {
	orgId: number
}
const OrgDbas: React.FC<OrgDbasProps> = ({ orgId }) => {
	const [results] = useOrgForDbasQuery({ variables: { id: orgId } })
	const org = results.data?.org
	const { dbas } = org || {}
	return dbas ? (
		<OrgDbasView>
			{dbas.map(({ name, id }) => (
				<li key={id}>{name}</li>
			))}
		</OrgDbasView>
	) : null
}
export default OrgDbas
