import { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useUploadFileMutation } from '../../../gql_generated/graphql'
import { parseBytes, useState } from '../../../utils'
import Colorize from '../Colorize'
import { FormFieldRawWrap } from '../Form.Fields'
import { Heading } from '../Heading'
import { Icon } from '../Icon'
import { IconType } from '../Icon/fa.defaults'
import { toastit } from '../Toaster'
import { Btn, P } from '../Typography'
import { UploaderProgress } from './Uploader.progress'

export const uploaderStyles = css`
	& > header {
		width: 100%;
		display: flex;
		justify-content: center;
		padding-bottom: 1.5em;

		.heading {
			text-align: center;
			color: ${props => props.theme.colors.grey.val};
		}
	}

	.inputs-wrap {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	.file-selector {
		margin-right: 1em;
		background: ${props => props.theme.colors.white.val};
		border: solid 2px;
		border-radius: 5px;
		color: ${props => props.theme.colors.lightGrey.val};
		transition: all ${props => props.theme.times.tranS};

		&:hover {
			color: ${props => props.theme.colors.primary.val};
			cursor: pointer;
			.select-file {
				.icon {
					transform: scale3d(1.05, 1.05, 1);
				}
			}
		}
	}
	.select-file {
		pointer-events: none;
		width: 100px;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;

		.icon {
			transition: transform ${props => props.theme.times.tranS};
			color: ${props => props.theme.colors.primary.val};
			height: 80%;
			width: auto;
		}
		.file-preview {
			object-fit: cover;
			display: block;
			height: 100%;
			width: 100%;
		}
	}
	.select-file-input {
		position: absolute;
		z-index: 0;
		opacity: 0;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
	}

	.file-details {
		& > span {
			display: block;
		}
	}
`

const UploaderView = styled.div`
	${uploaderStyles}
`

export type UploaderProps = {
	onUpload: (fileId: number) => void
	className?: string
	docId: number
	fileIdForDeletion?: number
	fileSelectOnOpen?: boolean
}
export const Uploader = ({
	className,
	onUpload,
	docId,
	fileIdForDeletion,
	fileSelectOnOpen,
}: UploaderProps): JSX.Element => {
	const [file, setFile] = useState<File | null>(null, 'file')
	const [name, setName] = useState('', 'name')
	const [description, setDescription] = useState('', 'description')

	const inputRef = useRef<HTMLInputElement>(null)

	const [uploadResults, upload] = useUploadFileMutation()
	const { fetching } = uploadResults
	const handleUpload = async () => {
		if (!file) return

		try {
			const res = await upload({
				file,
				data: { docId, name, description },
				idForDeletion: fileIdForDeletion,
			})

			const fileId = res.data?.uploadFile.id
			if (res.error) throw res.error
			else if (fileId) onUpload(fileId)
		} catch (err: any) {
			toastit.err(err.message)
		}
	}

	useEffect(() => {
		if (fileSelectOnOpen) inputRef.current?.click()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const iconType: IconType =
		file?.type === 'application/pdf'
			? 'pdf'
			: file?.type === 'image/jpeg' || file?.type === 'image/png'
			? 'image'
			: file
			? 'file'
			: 'fileUpload'

	return (
		<UploaderView className={`uploader${className ? ` ${className}` : ''}`}>
			<header>{fileSelectOnOpen ? null : <Heading>Select File...</Heading>}</header>
			{fetching ? (
				<UploaderProgress />
			) : (
				<div className='inputs-wrap'>
					<div className='file-selector'>
						<div className='select-file' role='button'>
							<Icon type={iconType as IconType} />
						</div>
						<input
							ref={inputRef}
							className='select-file-input'
							type='file'
							onChange={e => {
								const file = e.target.files?.length ? e.target.files[0] : null

								if (file) {
									setFile(file)
									setName(
										file.name.includes('.')
											? file.name.split('.').slice(0, -1).join('.')
											: file.name
									)
								}
							}}
						/>
					</div>
					{file ? (
						<div className='file-details'>
							<div className='info'>
								<div className='text'>
									<P>
										<Colorize color='grey'>Filename: {file?.name}</Colorize>
									</P>
									<P>
										<Colorize color='grey'>Size: {parseBytes(file?.size, 0)}</Colorize>
									</P>
								</div>
								<div className='inputs'>
									<FormFieldRawWrap name='file-name-input' label='Name'>
										<input
											name='file-name-input'
											onChange={e => setName(e.target.value)}
											value={name}
										/>
									</FormFieldRawWrap>
									<FormFieldRawWrap name='file-description-input' label='Description'>
										<textarea
											name='file-description-input'
											onChange={e => setDescription(e.target.value)}
											value={description}
										/>
									</FormFieldRawWrap>
								</div>
								<Btn className='cta' disabled={fetching || !file} onClick={handleUpload}>
									Save
								</Btn>
							</div>
						</div>
					) : null}
				</div>
			)}
		</UploaderView>
	)
}
