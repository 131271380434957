import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Text, PriceField, Textarea, SelectOrgField } from '../../UI'

const View = styled.div``

export const easementSchema = Yup.object({
	escrowId: Yup.string(),
	price: Yup.string().required('Please enter a price'),
	easementType: Yup.string().required('Please enter an easement type'),
	dateEffective: Yup.date().required('Please enter an effective date'),
	granteeId: Yup.number().required('Please select a Grantee'),
})

type EasementFieldsProps = {}
const EasementFields: React.FC<EasementFieldsProps> = () => {
	return (
		<View className='easement-fields'>
			<Text name='escrowId' label='Escrow ID' />
			<Text type='date' name='dateEffective' label='Effective Date' isReq />
			<PriceField name='price' label='Price' isReq />
			<Text name='easementType' label='Easement Type' isReq />
			<SelectOrgField name='granteeId' label='Grantee' isReq />
			<Textarea name='notes' label='Notes' />
		</View>
	)
}
export default EasementFields
