import React from 'react'
import styled from 'styled-components'
import {
	Acquisition,
	AcquisitionUpdateStatusInput,
	useUpdateAcquisitionsStatusMutation,
} from '../../../gql_generated/graphql'
import { formatDate, parseDateForServer } from '../../../utils'
import { Form, SubmitBtn, Text } from '../../UI'

const OpCloseAcquisitionView = styled.div``

type OpCloseAcquisitionProps = {
	acquisition: Acquisition
	cb?: () => void
}
const OpCloseAcquisition: React.FC<OpCloseAcquisitionProps> = ({ acquisition, cb }) => {
	const { id } = acquisition
	const [_, updateStatus] = useUpdateAcquisitionsStatusMutation()

	return (
		<OpCloseAcquisitionView>
			<Form
				legend='Close Acquisition'
				initialValues={{
					dateClosed: formatDate(new Date(), 'input'),
				}}
				onSubmit={async (data: AcquisitionUpdateStatusInput) => {
					try {
						await updateStatus({
							data: {
								...data,
								id,
								dateClosed: parseDateForServer(data.dateClosed),
							},
						})
						if (cb) cb()
					} catch (err) {}
				}}
			>
				{() => (
					<>
						<Text type='date' name='dateClosed' label='Date Closed' isReq />
						<SubmitBtn>Close Acquisition</SubmitBtn>
					</>
				)}
			</Form>
		</OpCloseAcquisitionView>
	)
}
export default OpCloseAcquisition
