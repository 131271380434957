import React from 'react'
import styled, { css } from 'styled-components/macro'
import { DocDetail } from '../../../Components/common/Doc/Doc.detail'

export const relatedLeaseDocModalStyles = css``

const RelatedLeaseDocModalView = styled.div`
	${relatedLeaseDocModalStyles}
`

export type RelatedLeaseDocModalProps = {
	docId: number
}
export const RelatedLeaseDocModal: React.FC<RelatedLeaseDocModalProps> = ({ docId }) => {
	return (
		<RelatedLeaseDocModalView>
			<DocDetail docId={docId} />
		</RelatedLeaseDocModalView>
	)
}
