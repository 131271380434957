import React from 'react'
import styled, { css } from 'styled-components/macro'
import { relatedLogsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Log.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { LogParent } from '../../../gql_generated/document_types'
import { usePaginatedLogsQuery } from '../../../gql_generated/graphql'
import { RelatedAcquisitionLogModal } from '../Related.Modals/Acquisition.related.log.modal'

export const relatedAcquisitionLogsStyles = css``

const RelatedAcquisitionLogsView = styled.div`
	${relatedAcquisitionLogsStyles}
`

export type RelatedAcquisitionLogsProps = {
	acquisitionId: number
}

export const RelatedAcquisitionLogs: React.FC<RelatedAcquisitionLogsProps> = ({
	acquisitionId,
}) => {
	const paginationStore = usePaginationParams()

	const [fetchResults] = usePaginatedLogsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: LogParent.Acquisition,
						parentIds: [acquisitionId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.logs
	return (
		<RelatedAcquisitionLogsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedLogsColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedAcquisitionLogModal logId={data.id} />}
				resourceType='log'
				suppressGoTo
			/>
		</RelatedAcquisitionLogsView>
	)
}
