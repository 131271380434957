import { navigate, RouteComponentProps } from '@reach/router'
import toNumber from 'lodash/toNumber'
import React, { useEffect } from 'react'
import Cards from '../../Components/common/CardsColumns'
import CardsSection from '../../Components/common/CardsSection'
import { PageOperation } from '../../Components/common/Operations'
import ParcelEstates from '../../Components/common/Parcel/Parcel.estates'
import ParcelFinances from '../../Components/common/Parcel/Parcel.finances'
import ParcelInfo from '../../Components/common/Parcel/Parcel.info'
import ParcelOGData from '../../Components/common/Parcel/Parcel.og'
import Page from '../../Components/common/Resources/Resource.page'
import { EditParcel } from '../../Components/Ops/Ops.Parcels'
import { Alert, Async, Card, Colorize, Modal, P, useToaster } from '../../Components/UI'
import {
	useArchiveParcelMutation,
	useDeleteParcelMutation,
	useParcelFullQuery,
	useUnArchiveParcelMutation,
} from '../../gql_generated/graphql'
import { usePageData, useState } from '../../utils'
import { ParcelRelatedResources } from './Related/Parcel.related'

interface parcelPageProps extends RouteComponentProps {
	id?: number
}

const ParcelPage: React.FC<parcelPageProps> = ({ id: parcelId }) => {
	const id = toNumber(parcelId)
	const [Op, setOp] = useState<string | null>(null, 'Op')

	const [fetchResults] = useParcelFullQuery({
		variables: { id },
		// context: useQueryDebug('results'),
	})
	const { data, fetching } = fetchResults

	const [_, deleteParcel] = useDeleteParcelMutation()
	const [__, archiveParcel] = useArchiveParcelMutation()
	const [___, unArchiveParcel] = useUnArchiveParcelMutation()
	const toast = useToaster()

	const parcel = data?.parcel

	const [updatePageInfo] = usePageData()

	const { apn, county, srf, aliquotNotes, archivedAt, notes } = parcel || {}

	const status = archivedAt ? 'Archived' : parcel?.status

	useEffect(() => {
		updatePageInfo({ pageTitle: `${county} ${apn}`, status })
	}, [apn, county, updatePageInfo, status])

	const operations: PageOperation[] = [
		{
			txt: 'Edit',
			icon: 'edit',
			op: () => setOp('edit'),
		},
		{
			txt: 'Archive',
			icon: 'archive',
			op: () => setOp('archive'),
			hide: !!archivedAt,
		},
		{
			txt: 'Un-Archive',
			icon: 'archive',
			op: () => setOp('unarchive'),
			hide: !archivedAt,
		},
		{
			txt: 'Delete',
			icon: 'x',
			op: () => setOp('delete'),
		},
	]

	const opCb = async () => {
		setOp(null)
	}

	const deleteOp = async (shouldDelete: boolean) => {
		if (shouldDelete) {
			try {
				await deleteParcel({ id })
				navigate('/mgr/parcels')
			} catch (err) {
				toast('Error deleting parcel', { type: 'err' })
			}
		} else setOp(null)
	}

	const archiveOp = async (shouldArchive: boolean) => {
		if (shouldArchive) {
			try {
				await archiveParcel({ id })
				// navigate('/mgr/parcels')
			} catch (err) {
				toast('Error archiving parcel', { type: 'err' })
			}
		} else setOp(null)
	}

	const unArchiveOp = async (shouldArchive: boolean) => {
		if (shouldArchive) {
			try {
				await unArchiveParcel({ id })
				// navigate('/mgr/parcels')
			} catch (err) {
				toast('Error un-archiving parcel', { type: 'err' })
			}
		} else setOp(null)
	}

	return (
		<Page isLoaded={!fetching} operations={operations}>
			<Async fetchResults={fetchResults}>
				<CardsSection isCollapsable>
					<Cards className='col left-col'>
						<Card titleText='Parcel Info'>
							<ParcelInfo parcelId={id} />
						</Card>
						<Card titleText='Estates'>
							<ParcelEstates parcelId={id} allowEdit />
						</Card>
						<Card titleText='Financial'>
							<ParcelFinances parcelId={id} />
						</Card>
					</Cards>
					<Cards className='col right-col'>
						<Card titleText='Surface Aliquot (Legal Description)'>
							<P asRT>{srf?.aliquot}</P>
						</Card>
						<Card titleText='Aliquot Notes'>
							<P asRT>{aliquotNotes}</P>
						</Card>
						<Card titleText='General Notes'>
							<P asRT>{notes}</P>
						</Card>
					</Cards>
					<Cards className='full-col'>
						<Card titleText='Original (OG) data from Xogenous'>
							<ParcelOGData parcelId={id} />
						</Card>
					</Cards>
				</CardsSection>
			</Async>

			<ParcelRelatedResources parcelId={id} />
			<Modal
				show={!!Op && Op !== 'delete' && Op !== 'archive' && Op !== 'unarchive'}
				closeHandler={() => setOp(null)}
				size='med'
			>
				{Op === 'edit' ? <EditParcel id={id} cb={opCb} /> : null}
			</Modal>
			{Op === 'delete' ? (
				<Alert
					msg='Are you sure you want to delete this parcel? (Cannot be undone)'
					cb={deleteOp}
					okBtn='Cancel'
					cancelBtn={
						<Colorize color='err' asBlock>
							Delete Permanently
						</Colorize>
					}
					useCancelAsOp
				/>
			) : null}
			{Op === 'archive' ? (
				<Alert
					msg='Are you sure you want to archive this parcel?'
					cb={archiveOp}
					okBtn='Cancel'
					cancelBtn={
						<Colorize color='err' asBlock>
							Archive
						</Colorize>
					}
					useCancelAsOp
				/>
			) : null}
			{Op === 'unarchive' ? (
				<Alert
					msg='Un-archiving this parcel will not restore related activity, docs, etc. Are you sure you want to continue?'
					cb={unArchiveOp}
					okBtn='Cancel'
					cancelBtn={
						<Colorize color='err' asBlock>
							Un-Archive
						</Colorize>
					}
					useCancelAsOp
				/>
			) : null}
		</Page>
	)
}
export default ParcelPage
