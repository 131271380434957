import React from 'react'
import styled, { css } from 'styled-components/macro'
import Protect from '../../../Components/common/Protect'
import { AddEditRoyaltyOnResource } from '../../../Components/Ops/Ops.Royalties/Ops.Royalty.add.edit'
import { AddRoyaltyToResourceBtn } from '../../../Components/Ops/Ops.Royalties/Ops.Royalty.add.edit/Ops.Royalty.addToResource.btn'
import { LeftBar, Modal, P, useModalCtrl } from '../../../Components/UI'
import { RemoveRoyaltiesFromResourceOp } from '../../../Components/UI/Table.resources/Table.global.ops/RemoveRoyaltiesFromResource.op'
import { leaseRelatedRoyaltiesColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Royalty.col.configs'
import {
	ResourcesTable,
	ResourceTableGlobalOpConfig,
} from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { RoyaltyParent } from '../../../gql_generated/document_types'
import { useLeaseFullQuery, usePaginatedRoyaltiesQuery } from '../../../gql_generated/graphql'
import { EstateTypeEnum, PrimaryResourceType } from '../../../Types'
import { nnrMgrs } from '../../../utils'
import { RelatedLeaseRoyaltyModal } from '../Related.Modals/Lease.related.royalty.modal'

export const relatedLeaseRoyaltyStyles = css``

const RelatedLeaseRoyaltiesView = styled.div`
	${relatedLeaseRoyaltyStyles}
`

export type RelatedLeaseRoyaltiesProps = {
	leaseId: number
}

export const RelatedLeaseRoyalties: React.FC<RelatedLeaseRoyaltiesProps> = ({ leaseId }) => {
	const paginationStore = usePaginationParams()

	const [leaseResults] = useLeaseFullQuery({ variables: { id: leaseId } })
	const estateType = leaseResults.data?.lease?.estateType as EstateTypeEnum

	const [results] = usePaginatedRoyaltiesQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: RoyaltyParent.Lease,
						parentIds: [leaseId],
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.royalties

	const parentProps = {
		parentId: leaseId,
		parentType: 'lease' as PrimaryResourceType,
	}
	const tableOps: ResourceTableGlobalOpConfig[] = [
		{
			txt: 'Remove',
			op: props => <RemoveRoyaltiesFromResourceOp {...props} {...parentProps} />,
		},
	]

	const [[showEditModal, openEditModal, closeEditModal, currentRoyaltyId]] = useModalCtrl<number>()

	const hasParcels = !!leaseResults.data?.lease?.activity?.length

	return (
		<RelatedLeaseRoyaltiesView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={leaseRelatedRoyaltiesColsConfig}
				fetchResults={results}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedLeaseRoyaltyModal royaltyId={data.id} />}
				tableOps={tableOps}
				resourceType='royalty'
				suppressGoTo
				rowOps={[
					{
						type: 'edit',
						op: royalty => openEditModal(royalty.id),
						allowed: nnrMgrs,
					},
				]}
			>
				<Protect allowed={nnrMgrs}>
					<LeftBar>
						{estateType ? (
							hasParcels ? (
								<AddRoyaltyToResourceBtn
									parentId={leaseId}
									parentType='lease'
									estateType={estateType}
								/>
							) : (
								<P>Please add parcels before adding royalties</P>
							)
						) : null}
					</LeftBar>
				</Protect>
			</ResourcesTable>
			<Modal
				show={showEditModal}
				closeHandler={closeEditModal}
				size='large'
				titleText='Edit Royalty'
			>
				{currentRoyaltyId ? (
					<AddEditRoyaltyOnResource
						parentId={leaseId}
						parentType='lease'
						royaltyId={currentRoyaltyId}
						cb={closeEditModal}
					/>
				) : null}
			</Modal>
		</RelatedLeaseRoyaltiesView>
	)
}
