import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { P, Link } from '../Typography'

const Pane = styled.div`
	background: ${props => props.theme.colors.white.val};
	padding: 1em;
	min-width: 100%;
	position: absolute;
	bottom: 0;
	transform: translateY(-100%);
	min-height: 3em;
	z-index: 5;
	box-shadow: 0 2px 5px ${props => props.theme.colors.black.tint().val};
	.no-results {
		font-style: italic;
		color: ${props => props.theme.colors.grey.val};
	}
`

type SearchPaneProps = {
	className?: string
	showPane?: boolean
	results?: Array<{
		txt: ReactNode
		to: string
	}>
	emptyTxt?: ReactNode
}

const SearchPane: React.FC<SearchPaneProps> = ({
	className,
	showPane,
	results,
	emptyTxt = 'No results found',
}) => {
	return showPane ? (
		<Pane className={`search-pane flex${className ? ` ${className}` : ''}`}>
			{results && results.length ? (
				<nav>
					{results.map(({ txt, to }) => (
						<Link key={`${txt}-${to}`} to={to}>
							{txt}
						</Link>
					))}
				</nav>
			) : (
				<P className='no-results'>
					<i>{emptyTxt}</i>
				</P>
			)}
		</Pane>
	) : null
}
export default SearchPane
