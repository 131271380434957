import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

export const leftBarStyles = css`
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	width: 100%;
`

const LeftBarView = styled.div`
	${leftBarStyles}
`

export type LeftBarProps = {
	children: ReactNode
}
export const LeftBar: React.FC<LeftBarProps> = ({ children }) => {
	return <LeftBarView>{children}</LeftBarView>
}
