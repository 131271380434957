import { css } from 'styled-components'

export const propertyTaxBatchCreatePageStyles = css`
	.operations-header {
		display: flex;
		align-items: stretch;

		& > * {
			margin-right: 1em;

			&:last-child {
				margin-right: 0;
			}
		}

		.total-results {
			display: flex;
			align-items: center;
			.note {
				margin-left: 1em;
				font-style: italic;
				display: none;
				${props => props.theme.media.sdesk} {
					display: inline-block;
				}
			}
		}

		.save-btn {
			margin-left: auto;
			padding: 0 2em;
			border: solid 2px;
		}
	}

	.content-table {
		${props => props.theme.media.sdesk} {
			height: calc(80vh - ${p => p.theme.sizes.header.sdesk.val});
			overflow-y: auto;
		}
	}
	input {
		border: none;
		box-shadow: none;
		outline: none !important;
	}

	.field {
		display: flex;
		align-items: center;
	}

	.disabled-field {
		color: ${props => props.theme.colors.lightGrey.val};
	}

	.input-field {
		input {
			border: 1px solid ${props => props.theme.colors.grey.light().val};
			padding: 4px;
			-webkit-appearance: none;
			appearance: none;

			&:focus {
				border-color: ${props => props.theme.colors.secondary.val};
			}
		}
	}

	table {
		position: relative;
		height: auto;

		thead {
			background: ${props => props.theme.colors.white.val};
		}
		thead,
		th {
			position: sticky;
			top: 0;
			z-index: 10;
		}
	}
`
