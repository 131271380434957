import React from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import styled, { css } from 'styled-components/macro'
import { EstateActivityInput } from '../../../gql_generated/document_types'
import {
	Parcel,
	useParcelsForAddParcelsToResourceActivityInfoQuery,
} from '../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../Types'
import { uniqBy } from '../../../utils/lodash.utils'
import { Accordion, ArrayField, Btn, Card, Form, Icon, Number, SubmitBtn, Textarea } from '../../UI'
import { IconType } from '../../UI/Icon/fa.defaults'
import { AddParcelsToResourceSelectParcelsProps } from './Ops.Parcels.addToResource.select'

export const addParcelsToResourceActivityInfoStyles = css`
	padding: 2rem;
	min-height: 80vh;
	${props => props.theme.media.sdesk} {
		min-width: 80vw;
	}

	.form-header {
		${props => props.theme.media.tablet} {
			display: flex;
			justify-content: space-between;
			padding-bottom: 1em;
			border-bottom: solid 1px ${props => props.theme.colors.grey.light().val};
			margin-bottom: 2em;
		}

		.previous {
			text-transform: uppercase;
			font-size: 1.2em;
		}

		.submit-btn {
			width: auto;
			margin: 0;
		}
	}

	.parcels {
		padding: 0 1em;

		${props => props.theme.media.tablet} {
			padding: 0;
			width: 50vw;
			max-width: 650px;
			margin: 0 auto;
		}
	}

	.activity-detail {
		padding-top: 1em;
		margin-bottom: 1em;
		border-left: 3px solid ${props => props.theme.colors.orange.val};
		.accordion {
			padding-top: 1em;
		}
		.parcel-identifiers,
		.info {
			display: flex;
			align-items: center;
			span {
				margin-right: 1em;
			}
		}
		.info {
			.icon {
				margin-right: 1em;
			}
		}
	}

	.array-field {
		border: none;
	}
`

const AddParcelsToResourceActivityInfoView = styled.div`
	${addParcelsToResourceActivityInfoStyles}
`

// NOTE: curries parentId and returns hash function
const estateActivityUniqHash = (parentId: number) => (activityData: EstateActivityInput) => {
	const { estateType, parcelId } = activityData

	return `${estateType}-${parcelId}-${parentId}`
}

type CreateActivityArgs = {
	estateTypes: EstateTypeEnum[]
	parentId: number
	parentType: string
}

const createActivityFromParcel = (
	parcel: Parcel,
	args: CreateActivityArgs
): EstateActivityInput[] => {
	const { estateTypes, parentType, parentId } = args
	const { id } = parcel
	return estateTypes.map(estateType => {
		const estate = parcel.estates.find(({ type }) => type === estateType)
		const newActivity = {
			parcelId: id,
			estateId: estate?.id,
			aliquot: '',
			estateType,
			acres: 0,
			activityType: parentType,
		} as EstateActivityInput

		newActivity[
			`${parentType}Id` as 'saleId' | 'leaseId' | 'easementId' | 'agreementId' | 'acquisitionId'
		] = parentId

		if (parentType === 'sale' || parentType === 'easement') newActivity.grantorId = estate?.ownerId

		return newActivity
	})
}

const createActivityFromParcels = (parcels: Parcel[], args: CreateActivityArgs) => {
	const newActivity = parcels.reduce(
		(arry, parcel) => [...arry, ...createActivityFromParcel(parcel, args)],
		[] as any
	)

	return uniqBy(newActivity, estateActivityUniqHash(args.parentId))
}

export type AddParcelsToResourceActivityInfoProps = Pick<
	AddParcelsToResourceSelectParcelsProps,
	'estateTypes' | 'selectedParcelIds' | 'parentId' | 'parentType'
> & {
	submitHandler: (vals: EstateActivityInput[]) => void
	isLazyMount: boolean
}

export const AddParcelsToResourceActivityInfo: React.FC<AddParcelsToResourceActivityInfoProps> = ({
	parentId,
	parentType,
	estateTypes,
	selectedParcelIds,
	submitHandler,
	isLazyMount: _,
	...wizardProps
}) => {
	const { isActive, previousStep } = wizardProps as StepWizardChildProps

	const [results] = useParcelsForAddParcelsToResourceActivityInfoQuery({
		variables: { parcelIds: selectedParcelIds },
	})

	const selectedParcels = results.data?.parcels

	const estateActivityData =
		selectedParcels &&
		createActivityFromParcels(selectedParcels as Parcel[], {
			estateTypes,
			parentId,
			parentType,
		})

	return (
		<AddParcelsToResourceActivityInfoView>
			{isActive && estateTypes?.length && estateActivityData && selectedParcels ? (
				<Form
					initialValues={{
						data: estateActivityData,
					}}
					onSubmit={({ data }: any) => submitHandler(data)}
				>
					{() => (
						<>
							<header className='form-header'>
								<Btn
									className='previous'
									onClick={(e: Event) => {
										e.preventDefault()
										if (previousStep) previousStep()
									}}
								>
									<Icon padRight type='arrowLeft' />
									Previous
								</Btn>
								<SubmitBtn>Add Parcels</SubmitBtn>
							</header>
							<div className='parcels'>
								<ArrayField
									name='data'
									render={({ replace, form }) =>
										estateActivityData.map(({ parcelId, estateType }, idx) => {
											const parcel = selectedParcels.find(({ id }) => id === parcelId)
											const estate = parcel?.estates.find(({ type }) => type === estateType)

											const { acres, aliquot } = estate || {}
											const { county, trs, apn } = parcel || {}

											const { values } = form
											const existingVals = values.data[idx]
											return (
												<Card noShadow className='activity-detail' key={idx}>
													<div className='info'>
														<Icon type={estateType as IconType} />
														<div className='parcel-identifiers'>
															<span className='county'>{county}</span>
															<span className='trs'>{trs}</span>
															<span className='apn'>{apn}</span>
														</div>
													</div>
													<div className='info-fields'>
														<Accordion
															ctrl={{
																open: 'Use estate aliquot',
																closed: 'Add custom aliquot',
															}}
															afterOpen={() => {
																replace(idx, {
																	...existingVals,
																	acres,
																	aliquot,
																})
															}}
															afterClose={() => {
																replace(idx, {
																	...existingVals,
																	acres: 0,
																	aliquot: '',
																})
															}}
														>
															<Number name={`data.${idx}.acres`} label='Acres' />
															<Textarea name={`data.${idx}.aliquot`} label='Aliquot' rows={3} />
														</Accordion>
													</div>
												</Card>
											)
										})
									}
								/>
							</div>
						</>
					)}
				</Form>
			) : null}
		</AddParcelsToResourceActivityInfoView>
	)
}
