import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { LeaseType, useSearchLeaseTypesQuery } from '../../../gql_generated/graphql'
import { useState } from '../../../utils'
import { Btn, EM, ErrorBlock, Modal, Spinner } from '../../UI'
import { useModalCtrl } from '../../UI/Modal'
import CreateLeaseType from './Ops.Lease.type.create'

const View = styled.div`
	input {
		border: 1px solid ${props => props.theme.colors.grey.val};
		border-radius: 4px;
		box-shadow: none;
		padding: 0.5em 1em;
		outline: none;
	}
	.search-results {
	}
	.lease-types {
		padding: 1em 0;
		display: flex;
		flex-direction: column;
		button {
			color: ${props => props.theme.colors.btn.val};
			text-align: left;
			padding: 0.4em;
			&:hover {
				background: ${props => props.theme.colors.grey.light(40).val};
			}
		}
	}
	.add-new-lease-type {
		border-top: solid 1px ${props => props.theme.colors.grey.light().val};
		padding-top: 1em;
		button {
			border-left: solid 3px;
			padding: 0.3em 0.5em;
			color: ${props => props.theme.colors.btn.val};
		}
	}
`
type LeaseTypeObj = Pick<LeaseType, 'id' | 'name'>
type LeaseTypeSelectProps = {
	cb: (leaseType: LeaseTypeObj) => void
}

const matching = (searchStr: string, leaseTypes?: LeaseTypeObj[] | null) =>
	leaseTypes?.find(({ name }) => name.toLowerCase() === searchStr.toLowerCase())

const LeaseTypeSelect: React.FC<LeaseTypeSelectProps> = ({ cb }) => {
	const isMounted = useRef(true)
	const [[showCreateModal, openCreateModal, closeCreateModal, modalContents]] = useModalCtrl()
	const [searchStr, setSearchStr] = useState('', 'searchStr')
	const [{ data, fetching, error }, searchLeaseTypes] = useSearchLeaseTypesQuery()

	const [leaseTypes, setLeaseTypes] = useState(data?.searchLeaseTypes, 'leaseTypes')

	useEffect(() => {
		if (isMounted.current && data?.searchLeaseTypes?.length) setLeaseTypes(data.searchLeaseTypes)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [])

	return !isMounted.current ? null : (
		<View>
			<section className='search-input'>
				<input
					autoFocus
					type='search'
					placeholder='Find Lease Type...'
					onChange={e => {
						setSearchStr(e.target.value)
						if (e.target.value.length > 1)
							searchLeaseTypes({ variables: { searchStr: e.target.value } })
						else if (!e.target.value) setLeaseTypes([])
					}}
					value={searchStr}
				/>
			</section>
			{error ? <ErrorBlock error={error} /> : null}
			<section className='search-results'>
				{fetching ? <Spinner /> : null}
				<div className='lease-types'>
					{leaseTypes?.length ? (
						leaseTypes.map(leaseType => (
							<Btn key={leaseType.id} onClick={() => cb(leaseType)}>
								{leaseType.name}
							</Btn>
						))
					) : searchStr?.length > 1 ? (
						<EM>None found...</EM>
					) : null}
				</div>
				{searchStr?.length > 1 && !matching(searchStr, leaseTypes) ? (
					<div className='add-new-lease-type'>
						<Btn onClick={() => openCreateModal(true)}>Add {searchStr}</Btn>
					</div>
				) : null}
			</section>
			<Modal show={showCreateModal} closeHandler={closeCreateModal}>
				{modalContents ? (
					<CreateLeaseType
						cb={leaseType => {
							closeCreateModal()
							cb(leaseType)
						}}
						initialName={searchStr}
					/>
				) : null}
			</Modal>
		</View>
	)
}
export default LeaseTypeSelect
