import styled from 'styled-components'

type HProps = {
	mBot?: string | boolean
	weight?: number | boolean
}

export const H1 = styled.h1<HProps>`
	${props =>
		props.mBot === true
			? 'margin-bottom: 0.5em;'
			: props.mBot
			? `margin-bottom: ${props.mBot};`
			: ''}

	font-weight: ${props => (props.weight === true ? 700 : props.weight || 400)};
`
export const H2 = styled.h2<HProps>`
	${props =>
		props.mBot === true
			? 'margin-bottom: 0.5em;'
			: props.mBot
			? `margin-bottom: ${props.mBot};`
			: ''}
	font-weight: ${props => (props.weight === true ? 700 : props.weight || 400)};
`
export const H3 = styled.h3<HProps>`
	${props =>
		props.mBot === true
			? 'margin-bottom: 0.5em;'
			: props.mBot
			? `margin-bottom: ${props.mBot};`
			: ''}
	font-weight: ${props => (props.weight === true ? 700 : props.weight || 400)};
`

export const H4 = styled.h4<HProps>`
	${props =>
		props.mBot === true
			? 'margin-bottom: 0.5em;'
			: props.mBot
			? `margin-bottom: ${props.mBot};`
			: ''}
	font-weight: ${props => (props.weight === true ? 700 : props.weight || 400)};
`

export const H5 = styled.h5<HProps>`
	${props =>
		props.mBot === true
			? 'margin-bottom: 0.5em;'
			: props.mBot
			? `margin-bottom: ${props.mBot};`
			: ''}
	font-weight: ${props => (props.weight === true ? 700 : props.weight || 400)};
	&.banner {
		color: ${props => props.theme.colors.white.val};
		padding: 0.3em 1em;
		background: ${props => props.theme.colors.grey.val};
		${props => props.theme.media.tablet} {
			padding: 0.5em 1em;
		}
	}
`
export const H6 = styled.h6<HProps>`
	color: ${props => props.theme.colors.grey.light(1).val};
	${props =>
		props.mBot === true
			? 'margin-bottom: 0.5em;'
			: props.mBot
			? `margin-bottom: ${props.mBot};`
			: ''}
	font-weight: ${props => (props.weight === true ? 700 : props.weight || 400)};
`
