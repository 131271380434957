import React from 'react'
import { useParcelForInfoQuery } from '../../../gql_generated/graphql'
import { useQueryDebug } from '../../../utils'
import { KeyVals } from '../../UI'

type ParcelInfoProps = {
	parcelId: number
}
const ParcelInfo: React.FC<ParcelInfoProps> = ({ parcelId }) => {
	const [results] = useParcelForInfoQuery({
		variables: { id: parcelId },
		context: useQueryDebug('results'),
	})
	const parcel = results.data?.parcel

	const { id, country, state, county, meridian, apn, town, range, section } = parcel || {}

	return (
		<KeyVals
			items={[
				['DB ID', id],
				['Country', country, 'upper'],
				['State', state, 'upper'],
				['Meridian', meridian],
				['County', county, 'proper'],
				['APN', apn],
				['Township', town],
				['Range', range],
				['Section', section],
			]}
		/>
	)
}
export default ParcelInfo
