import { useField } from 'formik'
import styled, { css } from 'styled-components'
import FormFieldWrap, { FormFieldWrapProps } from './Form.field.wrap'

export const dateRangeFieldStyles = css`
	display: flex;
	align-items: center;

	& > span {
		margin: 0 1em;
	}
	input {
		border: none;
		box-shadow: none;
		outline: none;
		padding: 0.5 1em;
		background: ${props => props.theme.colors.white.val};
	}
`

const DateRangeFieldView = styled.div`
	${dateRangeFieldStyles}
`

export type DateRangeFieldProps = FormFieldWrapProps & {}
export const DateRangeField = ({
	name,
	label,
	isReq,
	helperTxt,
}: DateRangeFieldProps): JSX.Element => {
	const [{ value }, _, { setValue }] = useField({ name })

	const formFieldWrapProps: FormFieldWrapProps = {
		name,
		label,
		isReq,
		helperTxt,
	}

	return (
		<FormFieldWrap {...formFieldWrapProps} type='daterange'>
			<DateRangeFieldView>
				<input
					type='date'
					onChange={e => setValue([e.currentTarget.value, value[1]])}
					value={value[0]}
					placeholder='Start Date...'
				/>
				<span>To</span>
				<input
					type='date'
					onChange={e => setValue([value[0], e.currentTarget.value])}
					value={value[1]}
					placeholder='End Date...'
				/>
			</DateRangeFieldView>
		</FormFieldWrap>
	)
}
