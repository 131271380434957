import React from 'react'
import { RouteComponentProps, Router } from '@reach/router'

import Protect from '../Components/common/Protect'
import Page404 from '../Pages/404.page'

import LeasesPage from '../Pages/Leases'
import LeasePage from '../Pages/Lease'

const LeaseRoutes: React.FC<RouteComponentProps> = () => {
	return (
		<Protect>
			<Router>
				<LeasesPage path='/' />
				<LeasePage path='/:id' />
				<Page404 default />
			</Router>
		</Protect>
	)
}
export default LeaseRoutes
