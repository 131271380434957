import React from 'react'
import styled, { css } from 'styled-components/macro'
import { ParcelDetail } from '../../../Components/common/Parcel/Parcel.detail'

export const relatedSaleParcelModalStyles = css``

const RelatedSaleParcelModalView = styled.div`
	${relatedSaleParcelModalStyles}
`

export type RelatedSaleParcelModalProps = {
	parcelId: number
}
export const RelatedSaleParcelModal: React.FC<RelatedSaleParcelModalProps> = ({ parcelId }) => {
	return (
		<RelatedSaleParcelModalView>
			<ParcelDetail parcelId={parcelId} />
		</RelatedSaleParcelModalView>
	)
}
