import React from 'react'

export type NoResultsSvgProps = {}
export const NoResultsSvg: React.FC<NoResultsSvgProps> = () => {
	return (
		<svg viewBox='0 0 4439 3285' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M647.096 512.904C778.212 452.052 853.07 319.307 846.547 183.31L266.624 452.458C366.275 545.232 515.981 573.756 647.096 512.904ZM142.063 510.268C114.029 477.73 89.9758 440.816 71 399.93L932.717 0C951.693 40.8866 964.356 83.0861 971.109 125.501C998.25 295.977 929.908 469.925 792.18 576.045L1399.26 1801.05L1720 1655C1882.83 1934.67 2057.1 2585.6 1765.5 2742C1473.9 2898.4 1062.5 2338 919.5 2019.5L1228.61 1878.75L624.148 659.035C447.471 706.322 259.717 646.828 142.063 510.268Z'
				fill='#A5A3A3'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2961.4 3012.32C2892.98 2693.33 3197.64 2007.08 3197.64 2007.08C3128.66 1976.12 3033.51 1851.92 3054.23 1757.16C3054.23 1757.16 3159.55 1724.92 3205.39 1728.3C3242.06 1730.99 3255.94 1665.65 3255.94 1665.65C3329 1617.14 3504.99 1685.32 3504.99 1685.32C3567.09 1717.33 3601 1757.91 3636.88 1861.57L3576.41 2138.03C3549.75 2259.95 3811.96 2830.18 3770.69 3018.89L2961.4 3012.32ZM3340.5 1775C3368.56 1736.41 3364.94 1696.14 3302.04 1731.01C3294.94 1742.42 3270.72 1758.22 3302.04 1783.5C3313.5 1792.75 3332.5 1786 3340.5 1775Z'
				fill='#A5A3A3'
			/>
			<path
				d='M3636.88 1861.57C3601 1757.91 3567.09 1717.33 3504.99 1685.32C3504.99 1685.32 3329 1617.14 3255.94 1665.65C3255.94 1665.65 3242.06 1730.99 3205.39 1728.3C3159.55 1724.92 3054.23 1757.16 3054.23 1757.16C3033.51 1851.92 3128.66 1976.12 3197.64 2007.08C3197.64 2007.08 2892.98 2693.33 2961.4 3012.32L3770.69 3018.89C3811.96 2830.18 3549.75 2259.95 3576.41 2138.03M3636.88 1861.57C3636.88 1861.57 3609.74 1985.64 3576.41 2138.03M3636.88 1861.57L3576.41 2138.03M3302.04 1731.01C3364.94 1696.14 3368.56 1736.41 3340.5 1775C3332.5 1786 3313.5 1792.75 3302.04 1783.5C3270.72 1758.22 3294.94 1742.42 3302.04 1731.01Z'
				stroke='black'
			/>
			<path
				d='M615.989 2464L388.989 2712.5L108.502 2939C-64.4991 3058 21.9989 3285 21.9989 3285H4438.49C4438.49 3285 4438.49 3047 4233.49 2939L3920.49 2863.5V2712.5L3714.99 2550.5C3671.82 2546.83 3574.69 2541.7 3531.49 2550.5C3488.29 2559.3 3477.49 2496.5 3477.49 2464C3437.99 2420.83 3350.29 2321.5 3315.49 2269.5C3280.69 2217.5 3092.32 2291.17 3002.49 2334.5L2872.99 2032L2591.99 1892L2354.49 1794.5L1976.49 2032L1792.99 1892H1555.49L1252.99 2032C1224.16 2096.83 1136.29 2248.1 1015.49 2334.5C894.69 2420.9 698.822 2456.83 615.989 2464Z'
				fill='#C4C4C4'
			/>
		</svg>
	)
}
