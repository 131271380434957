/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef } from 'react'
import { useTheme } from 'styled-components'
import { useState } from '../../../../utils'
import BarContainer, { BarContainerProps } from './Bar.container'

export type ProgressBarProps = BarContainerProps & {
	startBar?: boolean
}
const ProgressBar: React.FC<ProgressBarProps> = ({
	isDone,
	startBar,
	percentOverride,
	height,
	className,
	speed,
}) => {
	const [showBar, setShowBar] = useState(startBar || !!percentOverride, 'showBar')
	const { times } = useTheme()
	const isCancelled = useRef(false)

	useEffect(() => {
		if (startBar && !showBar && !isCancelled.current) setShowBar(true)
	}, [startBar])

	useEffect(() => {
		if (isDone)
			setTimeout(() => {
				if (!isCancelled.current) setShowBar(false)
			}, times.long || 600)
	}, [isDone])

	useEffect(() => {
		return () => {
			isCancelled.current = true
		}
	}, [])

	return showBar ? (
		<BarContainer
			isDone={isDone}
			height={height}
			className={className}
			speed={speed}
			percentOverride={percentOverride}
		/>
	) : null
}

export default React.memo(ProgressBar)
