import React from 'react'
import styled from 'styled-components/macro'
import { TableBodyCell, TableBodyCellProps } from '../Table.body.cell/Table.body.cell'
import { TableRowConfig, TableRowHandlers, TableRowOpConfig } from '../Table.types'
import { tableBodyRowStyles } from './Table.body.row.styles'

const TableBodyRowView = styled.tr`
	${tableBodyRowStyles}
`

export type TableBodyRowProps<T> = TableRowConfig<T> &
	TableRowHandlers<T> & {
		rowOps?: TableRowOpConfig<T>[]
		isLastActive?: boolean
	}
export const TableBodyRow: React.FC<TableBodyRowProps<any>> = ({
	cells,
	isSelected,
	rowOps,
	isLastActive,
	selectHandler,
	val,
	isDisabled,
	...rowConfig
}) => {
	const className = isSelected
		? '__selected'
		: isLastActive
		? '__last-active'
		: isDisabled
		? '__disabled'
		: ''

	const cellProps = {
		...rowConfig,
		isDisabled,
		val,
	}
	const checkboxCellProps: TableBodyCellProps<any> = {
		...cellProps,
		isSelected,
		txt: '',
		selectHandler,
	}
	const rowOpCellProps: TableBodyCellProps<any> = {
		...cellProps,
		ops: rowOps,
		txt: '',
		isDisabled,
	}
	return (
		<TableBodyRowView className={`${className}`}>
			{selectHandler ? <TableBodyCell {...checkboxCellProps} /> : null}
			{cells.map((cell, idx) => (
				<TableBodyCell {...cell} {...cellProps} key={idx} />
			))}
			{rowOps ? <TableBodyCell {...rowOpCellProps} /> : null}
		</TableBodyRowView>
	)
}
