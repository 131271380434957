import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Icon } from '../../Icon'
import { Btn } from '../../Typography'
import { TableRowConfig, TableSelectRowHandler } from '../Table.types'

export const tableSelectRowStyles = css``

const TableSelectRowView = styled.div`
	${tableSelectRowStyles}
`

export type TableSelectRowProps<T> = Pick<
	TableRowConfig<T>,
	'id' | 'val' | 'isDisabled' | 'isSelected' | 'selectType'
> & {
	selectRow: TableSelectRowHandler<T>
	isSelected: boolean
}
export const TableSelectRow: React.FC<TableSelectRowProps<any>> = ({
	id,
	val,
	isDisabled,
	isSelected,
	selectRow,
	selectType = 'checkbox',
}) => {
	return (
		<TableSelectRowView className='select-row'>
			{selectType === 'checkbox' ? (
				<input
					type='checkbox'
					checked={!!isSelected}
					disabled={isDisabled}
					value={id}
					onChange={() => (selectRow ? selectRow(val || id, !!isSelected) : undefined)}
				/>
			) : (
				<Btn
					className={`check-ico${isSelected ? ' __selected' : ''}`}
					onClick={() => selectRow(val || id, !!isSelected)}
					disabled={isDisabled}
				>
					<Icon type='check' />
				</Btn>
			)}
		</TableSelectRowView>
	)
}
