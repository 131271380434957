import React from 'react'
import styled, { css } from 'styled-components/macro'
import { parcelsRelatedEasementsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Easement.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { EasementParent } from '../../../gql_generated/document_types'
import { usePaginatedEasementsQuery } from '../../../gql_generated/graphql'
import { RelatedParcelEasementModal } from '../RelatedModals/Parcel.related.easement.modal'

export const relatedParcelSalesStyles = css``

const RelatedParcelEasementsView = styled.div`
	${relatedParcelSalesStyles}
`

export type RelatedParcelEasementsProps = {
	parcelId: number
}

export const RelatedParcelEasements: React.FC<RelatedParcelEasementsProps> = ({ parcelId }) => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedEasementsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: EasementParent.Parcel,
						parentIds: [parcelId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.easements
	return (
		<RelatedParcelEasementsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={parcelsRelatedEasementsColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={easement => <RelatedParcelEasementModal easementId={easement.id} />}
				resourceType='easement'
			/>
		</RelatedParcelEasementsView>
	)
}
