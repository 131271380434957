import { useState } from 'react'
import { SortOption } from '../../../gql_generated/document_types'
import { TableSortHandler, useTableSort } from '../Table/useTableSort'

export type PaginationParams = {
	search: string
	limit: number
	offset: number
	sortOptions: SortOption[]
}

export type UpdatePaginationParams = {
	setSearch: React.Dispatch<React.SetStateAction<string>>
	sortHandler: TableSortHandler
	setLimit: React.Dispatch<React.SetStateAction<number>>
	setOffset: React.Dispatch<React.SetStateAction<number>>
}

export type PaginationStore = {
	params: PaginationParams
	updateParams: UpdatePaginationParams
}

type UsePaginationOptions = {
	limit?: number
}
export const usePaginationParams = (options?: UsePaginationOptions): PaginationStore => {
	const { limit: initialLimit = 15 } = options || {}
	const [search, setSearch] = useState<string>('')
	const [limit, setLimit] = useState(initialLimit)
	const [offset, setOffset] = useState(0)
	const { sortState, sortHandler } = useTableSort()

	return {
		params: {
			search,
			limit,
			offset,
			sortOptions: sortState,
		},
		updateParams: {
			setSearch: searchStr => {
				setOffset(0)
				setSearch(searchStr)
			},
			setLimit,
			setOffset,
			sortHandler,
		},
	}
}
