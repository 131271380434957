import React from 'react'
import styled, { css } from 'styled-components/macro'
import { LogDetail } from '../../../Components/common/Log/Log.detail'

export const relatedEasementLogModalStyles = css``

const RelatedEasementLogModalView = styled.div`
	${relatedEasementLogModalStyles}
`

export type RelatedEasementLogModalProps = {
	logId: number
}
export const RelatedEasementLogModal: React.FC<RelatedEasementLogModalProps> = ({ logId }) => {
	return (
		<RelatedEasementLogModalView>
			<LogDetail logId={logId} />
		</RelatedEasementLogModalView>
	)
}
