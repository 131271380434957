import React from 'react'
import styled from 'styled-components'
import { useDocForInfoQuery } from '../../../gql_generated/graphql'
import { KeyVals } from '../../UI'

export const DocInfoView = styled.div``

export type DocInfoProps = {
	docId: number
}
export const DocInfo: React.FC<DocInfoProps> = ({ docId }) => {
	const [results] = useDocForInfoQuery({ variables: { id: docId } })
	const doc = results.data?.doc
	const { docType, docName, id, dateEffective } = doc || {}
	return (
		<DocInfoView>
			<KeyVals
				items={[
					['DB ID', id],
					['Doc Type', docType],
					['Doc Name', docName],
					['Effective Date', dateEffective, 'date'],
				]}
			/>
		</DocInfoView>
	)
}
