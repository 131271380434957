import React from 'react'
import styled, { css } from 'styled-components/macro'
import { RemoveDocsFromResourceOp } from '../../../Components/UI/Table.resources/Table.global.ops/RemoveDocFromResource.op'
import { relatedDocsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Doc.col.configs'
import {
	ResourcesTable,
	ResourceTableGlobalOpConfig,
} from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { DocParent } from '../../../gql_generated/document_types'
import { usePaginatedDocsQuery } from '../../../gql_generated/graphql'
import { PrimaryResourceType } from '../../../Types'
import { RelatedLeaseDocModal } from '../Related.Modals/Lease.related.doc.modal'

export const relatedLeaseDocStyles = css``

const RelatedLeaseDocsView = styled.div`
	${relatedLeaseDocStyles}
`

export type RelatedLeaseDocsProps = {
	leaseId: number
}
export const RelatedLeaseDocs: React.FC<RelatedLeaseDocsProps> = ({ leaseId }) => {
	const paginationStore = usePaginationParams()

	const [results] = usePaginatedDocsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: DocParent.Lease,
						parentIds: [leaseId],
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.docs

	const parentProps = {
		parentId: leaseId,
		parentType: 'lease' as PrimaryResourceType,
	}
	const tableOps: ResourceTableGlobalOpConfig[] = [
		{
			op: props => <RemoveDocsFromResourceOp {...props} {...parentProps} />,
			txt: 'Remove',
			icon: 'x',
		},
	]

	return (
		<RelatedLeaseDocsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedDocsColsConfig}
				fetchResults={results}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedLeaseDocModal docId={data.id} />}
				resourceType='doc'
				tableOps={tableOps}
			/>
		</RelatedLeaseDocsView>
	)
}
