import { Field } from 'formik'
import React from 'react'
import styled from 'styled-components'
import FormFieldWrap, { FormFieldWrapProps } from './Form.field.wrap'
import { InputProps } from './Input'

const CheckboxView = styled.div`
	.checkbox-input-wrap {
		display: inline-flex;
		align-items: center;

		input {
			height: 20px;
			width: 20px;
			margin: 0;
			margin-right: 1em;
		}
		label {
			white-space: nowrap;
			margin: 0;
		}
	}
`

const Checkbox: React.FC<InputProps & FormFieldWrapProps> = ({
	label,
	name,
	helperTxt,
	...props
}) => {
	return (
		<FormFieldWrap type='checkbox' name={name}>
			<CheckboxView>
				<div className='checkbox-input-wrap'>
					<Field as='input' name={name} id={name} {...props} type='checkbox' />
					<label htmlFor={name}>{label}</label>
				</div>
				{helperTxt ? <span className='helper-text'>{helperTxt}</span> : null}
			</CheckboxView>
		</FormFieldWrap>
	)
}
export default Checkbox
