import { useFormikContext } from 'formik'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import {
	AddLeaseType,
	PriceField,
	SelectEstateType,
	SelectOrgField,
	Text,
	Textarea,
	ToggleField,
} from '../../UI'

const View = styled.div``

export const leaseYupSchemaConfig = {
	leaseId: Yup.string().required('A lease ID is required'),
	leaseTypeId: Yup.number().required('A lease type is required'),
	estateType: Yup.string().required('Please select an estate type'),
	dateEffective: Yup.date().required('Please enter an effective date'),
	dateShouldExpire: Yup.date().required('Please enter an expiration date'),
	lesseeId: Yup.number().required('Please select a Lessee'),
	// initialFee: Yup.number().required('Pl'),
}

export const leaseSchema = Yup.object(leaseYupSchemaConfig)

type LeaseFieldsProps = {
	isNew?: boolean
}
const LeaseFields: React.FC<LeaseFieldsProps> = () => {
	const { values } = useFormikContext<{
		dateExpired?: string
		dateTerminated?: string
	}>()
	const { dateExpired, dateTerminated } = values
	return (
		<View className='lease-fields'>
			<Text name='leaseId' label='Lease ID' isReq />
			<AddLeaseType name='leaseTypeId' label='Lease Type' isReq />
			<SelectEstateType name='estateType' label='Estate Type' isReq />
			<div className='flex'>
				<Text name='dateEffective' type='date' label='Effective Date' isReq />
				<Text name='dateShouldExpire' type='date' label='Expected Expiration Date' isReq />
				<Text name='dateInsurExpires' type='date' label='Insurance Expiration Date' />
				{dateExpired ? <Text name='dateExpired' type='date' label='Expiration Date' /> : null}
				{dateTerminated ? (
					<Text name='dateTerminated' type='date' label='Termination Date' />
				) : null}
			</div>
			<SelectOrgField name='lesseeId' label='Select Lessee' isReq />
			<PriceField name='initialFee' label='Initial Fee' isReq />
			<ToggleField name='canRenew' label='Has renewal option?' />
			<Textarea name='notes' label='General Notes' />
		</View>
	)
}
export default LeaseFields
