import { useFormikContext } from 'formik'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { SelectEstateType, SelectOrgField, Text, Textarea } from '../../UI'

const View = styled.div``

export const agreementSchema = Yup.object({
	agreementType: Yup.string().required('Please enter an agreement type'),
	estateType: Yup.string().required('Please an estate type'),
	dateEffective: Yup.date().required('Please enter a from date'),
	orgId: Yup.number().required('Please select an org'),
})

type EasementFieldsProps = {}
const EasementFields: React.FC<EasementFieldsProps> = () => {
	const { values } = useFormikContext<any>()

	const { dateTerminated, dateExpired } = values

	return (
		<View className='agreement-fields'>
			<Text name='agreementId' label='Agreement ID' />
			<Text type='date' name='dateEffective' label='Effective Date' isReq />
			<Text type='date' name='dateShouldExpire' label='Expected Expiration Date' />
			{dateExpired ? <Text type='date' name='dateExpired' label='Actual Expiration Date' /> : null}
			{dateTerminated ? <Text type='date' name='dateTerminated' label='Termination Date' /> : null}
			<Text name='agreementType' label='Agreement Type' isReq />
			<SelectEstateType name='estateType' label='Estate Type' isReq />
			<SelectOrgField name='orgId' label='Org' isReq />
			<Textarea name='notes' label='Notes' />
		</View>
	)
}
export default EasementFields
