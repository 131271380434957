import React from 'react'
import { useAgreementForInfoQuery } from '../../../gql_generated/graphql'
import { sumBy } from '../../../utils/lodash.utils'
import { KeyVals } from '../../UI'

type AgreementInfoProps = {
	agreementId: number
}
const AgreementInfo: React.FC<AgreementInfoProps> = ({ agreementId }) => {
	const [results] = useAgreementForInfoQuery({ variables: { id: agreementId } })
	const agreement = results.data?.agreement
	const {
		id,
		dateEffective,
		dateShouldExpire,
		dateExpired,
		agreementType,
		status,
		org,
		orgDba,
		activity,
		estateType,
	} = agreement || {}
	return agreement ? (
		<KeyVals
			items={[
				['DB ID', id],
				['Org', orgDba || org, 'org'],
				['Estate Type', estateType, 'icon'],
				['Agreement Type', agreementType, 'proper'],
				['Effective Date', dateEffective, 'date'],
				['Expected Expiration', dateShouldExpire, 'date'],
				['Actual Expiration', dateExpired, 'date'],
				[
					'Total',
					sumBy(activity, ({ acres, estate, isTerminated, assigneeId }) =>
						assigneeId || isTerminated ? 0 : acres || estate.acres
					),
					'acres',
				],
				['Status', status],
			]}
		/>
	) : null
}
export default AgreementInfo
