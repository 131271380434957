import { DebugEvent, getOperationName } from 'urql'
import { isDev } from '../utils'

const DEBUG_OVERRIDE = 'results'

export type UrqlLoggerLevel = 'event' | 'all' | 'fetch' | 'results' | 'fetchAndResults'

export const urqlLogger = (event: DebugEvent<string>) => {
	if (!isDev()) return

	const debug: UrqlLoggerLevel = event.operation?.context?.debug || DEBUG_OVERRIDE

	if (!debug) return

	const opName = getOperationName(event.operation.query)

	if (debug === 'event') console.log('URQL LOG EVENT: ', event)

	if (event.type === 'fetchRequest' && (debug === 'fetch' || debug === 'fetchAndResults')) {
		// FETCH REQUEST
		// NOTE: This may fire multiple times for some reason (would have thought that the dedupe exhange would take care of that??)

		console.log(
			`%cGQL [ ${opName} ] QUERY | FETCHING: `,
			`color:#FFF; background-color:#138de4; padding: 4px;`,
			event.operation.variables
		)
	}

	if (
		event.type === 'fetchSuccess' &&
		(debug === 'all' || debug === 'results' || debug === 'fetchAndResults')
	) {
		// FETCH RESULTS
		// NOTE: These are results from the server not the cache

		const data = event.data?.value?.data
		console.log(
			`%cGQL [ ${opName} ] QUERY | FETCH SUCCESS: `,
			`color:#FFF; background-color:#5bbb12; padding: 4px;`,
			data
		)
	} else if (
		event.type === 'cacheHit' &&
		(debug === 'all' || debug === 'results' || debug === 'fetchAndResults')
	) {
		// FETCH RESULTS
		// NOTE: These are results from the cache not the server
		const data = event.data?.value?.data
		console.log(
			`%cGQL [ ${opName} ] QUERY | CACHE SUCCESS: `,
			`color:#FFF; background-color:#c705b5; padding: 4px;`,
			data
		)
	}
}
