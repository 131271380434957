import React from 'react'
import styled, { css } from 'styled-components/macro'
import Protect from '../../../Components/common/Protect'
import { LeftBar } from '../../../Components/UI'
import { RemoveRelatedParcelsOp } from '../../../Components/UI/Table.resources/Table.global.ops/RemoveParcelsFromRelated.op'
import { docRelatedParcelsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Parcel.col.configs'
import {
	ResourcesTable,
	ResourceTableGlobalOpConfig,
} from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { ParcelParent } from '../../../gql_generated/document_types'
import { usePaginatedParcelsByDocQuery } from '../../../gql_generated/graphql'
import { PrimaryResourceType } from '../../../Types'
import { nnrMgrs } from '../../../utils'
import { RelatedDocParcelModal } from '../Related.Modals/Doc.related.parcel.modal'
import { AddParcelsToDocBtn } from './Operations/Doc.add.parcels.btn/Doc.add.parcels.btn'

export const relatedDocParcelsStyles = css``

const RelatedDocParcelsView = styled.div`
	${relatedDocParcelsStyles}
`

export type RelatedDocParcelsProps = {
	docId: number
}
export const RelatedDocParcels: React.FC<RelatedDocParcelsProps> = ({ docId }) => {
	const paginationStore = usePaginationParams()

	const [results] = usePaginatedParcelsByDocQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: ParcelParent.Doc,
						parentIds: [docId],
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.parcels

	const parentProps = {
		parentId: docId,
		parentType: 'doc' as PrimaryResourceType,
	}
	const tableOps: ResourceTableGlobalOpConfig[] = [
		{
			txt: 'Remove',
			op: props => <RemoveRelatedParcelsOp {...props} {...parentProps} />,
		},
	]

	return (
		<RelatedDocParcelsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={docRelatedParcelsColsConfig(docId)}
				fetchResults={results}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedDocParcelModal parcelId={data.id} />}
				resourceType='parcel'
				tableOps={tableOps}
				includeSearch
			>
				<Protect allowed={nnrMgrs}>
					<LeftBar>
						<AddParcelsToDocBtn docId={docId} />
					</LeftBar>
				</Protect>
			</ResourcesTable>
		</RelatedDocParcelsView>
	)
}
