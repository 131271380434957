import React from 'react'
import { Dba, Org } from '../../gql_generated/graphql'
import { Link } from '../UI'

type OrgLinkProps = {
	org?: Org | Dba
	className?: string
}
const OrgLink: React.FC<OrgLinkProps> = ({ org: o, className }) => {
	const org = o as any
	const id = org?.orgId || org?.id
	const name = org?.name
	return id ? (
		<Link className={`org-link${className ? ` ${className}` : ''}`} to={`/mgr/orgs/${id}`}>
			{name}
		</Link>
	) : name ? (
		<span className='org'>{name}</span>
	) : null
}
export default OrgLink
