import { sortBy } from 'lodash'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import {
	useLeaseTypeListQuery,
	useLeasedAcresForLeasedAcresPdfQuery,
} from '../../../gql_generated/graphql'
import { formatDate, formatNumberWithCommas } from '../../../utils'
import { Async, Btn, Card, P, PrintWindow, TablePrint, TablePrintRow } from '../../UI'
import { ToggleRaw } from '../../UI/Form.Fields/Toggle.field'
import { Heading } from '../../UI/Heading'

const LeasedAcresPdfStyles = css``

const LeasedAcresPdfView = styled.div`
	${LeasedAcresPdfStyles}
`

type LeasedAcresPdfProps = {
	className?: string
	leaseTypeNames: string[]
}
const LeasedAcresPdf = ({ className, leaseTypeNames }: LeasedAcresPdfProps): JSX.Element => {
	const [results] = useLeasedAcresForLeasedAcresPdfQuery({
		variables: { leaseTypeNames },
		pause: !leaseTypeNames.length,
	})

	const counties = sortBy(results.data?.counties, 'county')

	const rows: TablePrintRow[] | undefined = counties
		? [
				{
					rowType: 'header',
					cells: [
						{ val: '' },
						...counties.map(({ county }) => ({ val: county })),
						{ val: 'Total' },
					],
				},
				...leaseTypeNames.map(
					leaseTypeName =>
						({
							cells: [
								{
									val: leaseTypeName,
									bold: true,
								},
								...counties.map(({ leaseTypes }) => ({
									val: formatNumberWithCommas(
										leaseTypes.reduce(
											(acc, { acres, leaseType }) =>
												leaseType === leaseTypeName ? acc + acres : acc,
											0
										)
									),
								})),
								{
									align: 'right',
									bold: true,
									val: formatNumberWithCommas(
										counties.reduce(
											(acc, { leaseTypes }) =>
												acc +
												leaseTypes.reduce(
													(sum, { acres, leaseType }) =>
														leaseType === leaseTypeName ? sum + acres : sum,
													0
												),
											0
										)
									),
								},
							],
						} as TablePrintRow)
				),
				{
					rowType: 'footer',
					cells: [
						{
							val: 'Totals',
							bold: true,
							align: 'right',
						},
						...counties.map(({ leaseTypes }) => ({
							val: formatNumberWithCommas(leaseTypes.reduce((acc, { acres }) => acc + acres, 0)),
						})),
						{
							bold: true,
							val: formatNumberWithCommas(
								counties.reduce(
									(acc, { leaseTypes }) =>
										acc + leaseTypes.reduce((sum, { acres }) => sum + acres, 0),
									0
								)
							),
						},
					],
				},
		  ]
		: undefined

	return (
		<LeasedAcresPdfView className={`lease-acres-pdf${className ? ` className` : ''}`}>
			<Heading>NNR Leased Acres Snapshot</Heading>
			<P>{formatDate(new Date())}</P>
			<Async fetchResults={results}>{rows?.length ? <TablePrint body={{ rows }} /> : null}</Async>
		</LeasedAcresPdfView>
	)
}

type LeasedAcresPdfFormProps = {
	cb?: () => void
}

export const LeasedAcresPdfForm = ({ cb }: LeasedAcresPdfFormProps) => {
	const [leaseTypeNames, setLeaseTypeNames] = useState<string[]>([])
	const [shouldFetch, setShouldFetch] = useState(false)
	const [results] = useLeasedAcresForLeasedAcresPdfQuery({
		variables: { leaseTypeNames },
		pause: !shouldFetch,
	})
	const [leaseTypeResults] = useLeaseTypeListQuery()

	const readyToPrint = !!results.data?.counties.length
	const leaseTypes = leaseTypeResults.data?.leaseTypes

	return (
		<Async fetchResults={results}>
			{readyToPrint ? (
				<PrintWindow
					orientation='landscape'
					margin='0.5in'
					PDF={<LeasedAcresPdf leaseTypeNames={shouldFetch ? leaseTypeNames : []} />}
					fileName={`NNR-Leased-Acres_report.${formatDate(new Date(), 'mm-dd-yyyy')}`}
					cb={cb}
				/>
			) : (
				<div className='form-inputs'>
					<Heading mBot>Select Lease Types</Heading>
					<Card className='inputs' mBot>
						{leaseTypes?.map(({ id, name }) => (
							<ToggleRaw
								key={id}
								name={`lease-type-input-${id}`}
								isOn={leaseTypeNames.includes(name)}
								isSimple
								label={name}
								onClick={val =>
									setLeaseTypeNames(state => {
										if (val) {
											return [...state, name]
										} else {
											return state.filter(n => n !== name)
										}
									})
								}
							/>
						))}
					</Card>
					<Btn
						className='cta'
						onClick={() => setShouldFetch(true)}
						disabled={!leaseTypeNames.length}
					>
						Generate PDF
					</Btn>
				</div>
			)}
		</Async>
	)
}
