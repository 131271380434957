import styled, { css } from 'styled-components'
import { useAcresOwnedByEstateTypeForWidgetQuery } from '../../../../gql_generated/graphql'
import { asAcres } from '../../../../utils'
import { Spinner } from '../../../UI'

const AcresOwnedByEstateTypeEstateStyles = css`
	display: flex;
	align-items: center;

	.label {
		width: 100px;
	}
	.val {
		height: 40px;
		width: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: solid 3px ${props => props.theme.colors.grey.light().val};
		border-radius: 5px;
		position: relative;
	}
`

const AcresOwnedByEstateTypeEstateView = styled.div`
	${AcresOwnedByEstateTypeEstateStyles}
`

type AcresOwnedByEstateTypeEstateProps = {
	className?: string
	estateType: string
}
export const AcresOwnedByEstateTypeEstate = ({
	className,
	estateType,
}: AcresOwnedByEstateTypeEstateProps): JSX.Element => {
	const [{ data, fetching }] = useAcresOwnedByEstateTypeForWidgetQuery({
		variables: { estateType },
	})

	return (
		<AcresOwnedByEstateTypeEstateView
			className={`acres-owned-by-estate-type-estate${className ? ` className` : ''}`}
		>
			<span className='label'>{estateType.toUpperCase()}</span>
			<span className='val'>{fetching ? <Spinner /> : asAcres(data?.acres || 0, 0)}</span>
		</AcresOwnedByEstateTypeEstateView>
	)
}
