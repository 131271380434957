import { Field } from 'formik'
import React from 'react'
import styled from 'styled-components'
import FormFieldWrap, { FormFieldWrapProps } from './Form.field.wrap'

const S = styled.select``

export type SelectOptions = {
	txt?: string
	val: string | number
}

export type SelectProps = Pick<
	React.InputHTMLAttributes<HTMLSelectElement>,
	'onChange' | 'title' | 'placeholder' | 'className' | 'value' | 'defaultValue'
> &
	FormFieldWrapProps

const Select: React.FC<
	SelectProps & {
		options: SelectOptions[]
	}
> = ({ options, name, label, helperTxt, isReq, ...props }) => {
	return (
		<FormFieldWrap type='select' name={name} label={label} isReq={isReq} helperTxt={helperTxt}>
			<Field as={S} name={name} {...props}>
				{options.map(({ txt, val }) => (
					<option key={val} value={val}>
						{txt ? txt : val}
					</option>
				))}
			</Field>
		</FormFieldWrap>
	)
}
export default Select
