import { navigate, RouteComponentProps } from '@reach/router'
import toNumber from 'lodash/toNumber'
import React, { useEffect } from 'react'
import Cards from '../../Components/common/CardsColumns'
import CardsSection from '../../Components/common/CardsSection'
import EasementInfo from '../../Components/common/Easement/Easement.info'
import ExhibitForm from '../../Components/common/Exhibit'
import { PageOperation } from '../../Components/common/Operations'
import Page from '../../Components/common/Resources/Resource.page'
import { AddDocToResourceOp } from '../../Components/Ops/Ops.AddDocToResource'
import EditEasement from '../../Components/Ops/Ops.Easements/Ops.Easement.edit'
import OpTerminateEasement from '../../Components/Ops/Ops.Easements/Ops.Easement.terminate'
import { Alert, Block, Card, Colorize, Modal, useToaster } from '../../Components/UI'
import { UserRole } from '../../gql_generated/document_types'
import {
	Easement,
	useDeleteEasementMutation,
	useEasementPageQuery,
} from '../../gql_generated/graphql'
import { usePageData, useState } from '../../utils'
import { EasementRelatedResources } from './Related/Easement.related'

type EasementPageProps = RouteComponentProps & {
	id?: number
}
const EasementPage: React.FC<EasementPageProps> = props => {
	const id = toNumber(props.id)
	const [Op, setOp] = useState<string | null>(null, 'Op')
	const [{ data, fetching, error }, refetch] = useEasementPageQuery({
		variables: { id },
	})

	const [_, deleteEasement] = useDeleteEasementMutation()
	const toast = useToaster()

	const { easement } = data || {}

	const { status, escrowId } = easement || {}

	const [updatePageInfo] = usePageData({ pageType: 'easement' })

	useEffect(() => {
		updatePageInfo({ pageTitle: `Escrow ID: ${escrowId}`, status })
	}, [id, escrowId, updatePageInfo, status])

	const operations: PageOperation[] = [
		{
			txt: 'Edit',
			icon: 'edit',
			op: () => setOp('edit'),
		},
		{
			txt: 'Cancel',
			icon: 'calX',
			op: () => setOp('cancel'),
		},
		{
			txt: 'Generate Exhibit',
			icon: 'exhibit',
			op: () => setOp('exhibit'),
			hide: !easement?.activity?.length,
			allowed: [UserRole.Accountant, UserRole.Officer],
		},
		{
			txt: 'Terminate',
			icon: 'terminate',
			op: () => setOp('terminate'),
		},
		{
			txt: 'Add Doc',
			icon: 'doc',
			op: () => setOp('doc'),
		},

		{
			txt: 'Delete',
			icon: 'x',
			op: () => setOp('delete'),
		},
	]

	const deleteOp = async (shouldDelete: boolean) => {
		if (shouldDelete) {
			try {
				await deleteEasement({ id })
				navigate('/mgr/easements')
			} catch (err) {
				toast('Error deleting easement', { type: 'err' })
			}
		} else setOp(null)
	}

	const opCb = () => {
		refetch()
		setOp(null)
	}

	return (
		<Page isLoaded={!fetching} operations={operations}>
			{error && (
				<section>
					<Block type='err'>{error.message}</Block>
				</section>
			)}
			<CardsSection isCollapsable>
				<Cards className='col left-col'>
					<Card titleText='Easement Info'>
						<EasementInfo easementId={id} />
					</Card>
				</Cards>
			</CardsSection>
			<EasementRelatedResources easementId={id} />
			<Modal show={!!Op && Op !== 'delete'} closeHandler={() => setOp(null)}>
				{Op === 'edit' ? (
					<EditEasement id={id} cb={opCb} />
				) : Op === 'terminate' ? (
					<OpTerminateEasement easement={easement as Easement} cb={opCb} />
				) : Op === 'exhibit' ? (
					<ExhibitForm
						resource={{ easement: easement as Easement }}
						cb={() => setOp(null)}
						estateType={'srf'}
					/>
				) : Op === 'doc' ? (
					<AddDocToResourceOp parentId={id} parentType='easement' cb={opCb} />
				) : null}
			</Modal>
			{Op === 'delete' ? (
				<Alert
					msg='Are you sure you want to delete this easement? (Cannot be undone)'
					cb={deleteOp}
					okBtn='Cancel'
					cancelBtn={
						<Colorize color='err' asBlock>
							Delete Permanently
						</Colorize>
					}
					useCancelAsOp
				/>
			) : null}
		</Page>
	)
}
export default EasementPage
