import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import React from 'react'
import styled from 'styled-components'
import { UserRole } from '../../gql_generated/document_types'
import { useStoreActions, useStoreState } from '../../store'
import { Btn, Card, Icon } from '../UI'
import { IconType } from '../UI/Icon/fa.defaults'

const Aside = styled.aside`
	${props => props.theme.media.sdesk} {
		margin-left: 8px;
		&:hover {
			.operations-ctrl {
				.btn {
					opacity: 1;
				}
			}
		}
	}
	.operations-ctrl {
		${props => props.theme.media.sdesk} {
			height: ${props => get(props.theme.sizes, 'alertbar.val')};
			justify-content: flex-end;
		}
		button {
			padding: 0;
			&.open {
				opacity: 0;
			}
			.icon {
				margin: 0;
			}
		}
	}
	.operations {
		transition: all ${props => props.theme.times.tranS};
		overflow: hidden;

		.card {
			border: solid 2px ${props => props.theme.colors.grey.val};
			transition: border-color ${props => props.theme.times.tranS};
			& > header {
				background: linear-gradient(
					to right,
					${props => props.theme.colors.teal.val} 0%,
					${props => props.theme.colors.teal.light(0).val} 100%
				);
				background: ${props => props.theme.colors.slate.val};
			}

			&:hover {
				border-color: ${props => props.theme.colors.teal.val};
			}
		}

		.card-content {
			padding: 0.5em 0;
		}
		.menu {
			button {
				display: flex;
				width: 100%;
				align-items: center;
				white-space: nowrap;
				padding: 0.5em 1em;
				&:hover {
					background: ${props => props.theme.colors.grey.light(6).val};
					color: ${props => props.theme.colors.btn.val};
				}
				&.x {
					margin-top: 3em;
					color: ${props => props.theme.colors.err.val};
				}
				.icon {
					height: auto;
					width: 15px;
					margin-right: 0.8em;
					svg {
						width: auto;
						height: 1.1rem;
					}
				}
			}
		}
	}
`
export type PageOperation = {
	txt: string
	icon?: IconType
	hide?: boolean
	op: () => void
	allowed?: UserRole[]
}

type OperationsProps = {
	className?: string
	items?: PageOperation[]
}

const Operations: React.FC<OperationsProps> = ({ className = null, items = null }) => {
	const toggleOpBar = useStoreActions(actions => actions.view.toggleOpBar)
	const isOpBarOpen = useStoreState(state => state.view.isOpBarOpen)

	const role = useStoreState(state => state.user.role)

	const opsForRender =
		role === UserRole.Landman || role === UserRole.SysAdmin
			? items
			: items?.filter(({ allowed = [UserRole.Landman] }) => allowed.includes(role))

	return opsForRender?.length ? (
		<Aside className={`operations-wrap${className ? ` ${className}` : ''}`}>
			<div className='operations-ctrl flex'>
				<Btn onClick={() => toggleOpBar(null)} className={isOpBarOpen ? 'open' : 'closed'}>
					<Icon type='caretBoxLeft' isFlipped={isOpBarOpen} />
				</Btn>
			</div>
			<div className='operations' style={{ maxWidth: isOpBarOpen ? '222px' : '0px' }}>
				<Card
					corners
					titleText={
						<>
							<Icon type='tools' padRight /> Operations
						</>
					}
				>
					<div className='menu'>
						{opsForRender
							.filter(({ hide }) => hide !== true)
							.map(({ txt, icon, op }) => (
								<Btn key={txt} onClick={() => (isFunction(op) ? op() : null)} className={icon}>
									{icon && <Icon type={icon as IconType} />}
									{txt}
								</Btn>
							))}
					</div>
				</Card>
			</div>
		</Aside>
	) : (
		<></>
	)
}
export default Operations
