import { RouteComponentProps } from '@reach/router'
import React from 'react'
import styled from 'styled-components'
import CardsSection from '../../Components/common/CardsSection'
import EstateTypes from '../../Components/common/Estate/Estates.Icons'
import Page from '../../Components/common/Resources/Resource.page'
import { Btn, Card, Modal, ParcelSelector } from '../../Components/UI'
import { useModalCtrl } from '../../Components/UI/Modal'
import { EstateTypeEnum } from '../../Types'
import { usePageData, useState } from '../../utils'
import { ReportsExhibitPageModal } from './Reports.exhibit.page.modal'

export const ReportsExhibitPageView = styled.div`
	.top {
		.card-content {
			display: flex;
			.exhibit-btn {
				margin-left: auto;
			}
		}
	}
`

export const ReportsExhibitPage: React.FC<RouteComponentProps> = () => {
	usePageData({ pageTitle: 'Exhibits', icon: 'exhibit' })

	const [estateType, setEstateType] = useState<EstateTypeEnum>('srf', 'estateType')

	const [selectedParcelIds, setSelectedIds] = useState<number[]>([], 'selectedParcelIds')

	const [[showExhibitModal, openExhibitModal, closeExhibitModal]] = useModalCtrl()

	return (
		<ReportsExhibitPageView>
			<Page isLoaded>
				<CardsSection>
					<Card titleText='Select Estate Type' className='top'>
						<EstateTypes
							selected={[estateType]}
							onSelect={e => setEstateType(e as EstateTypeEnum)}
						/>
						<Btn
							className='cta exhibit-btn'
							disabled={!selectedParcelIds.length}
							onClick={openExhibitModal}
						>
							Generate Exhibit
						</Btn>
					</Card>
					<Card>
						<ParcelSelector
							selectedIds={selectedParcelIds}
							setSelectedIds={setSelectedIds}
							estateTypes={[estateType]}
						/>
					</Card>
				</CardsSection>
			</Page>
			<Modal show={showExhibitModal} closeHandler={closeExhibitModal}>
				<ReportsExhibitPageModal
					estateType={estateType}
					parcelIds={selectedParcelIds}
					cb={closeExhibitModal}
				/>
			</Modal>
		</ReportsExhibitPageView>
	)
}
