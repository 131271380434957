import get from 'lodash/get'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { ProgressBar } from '../../UI'
import Operations, { PageOperation } from '../Operations'
import AlertBar from './Resource.alertBar'

const PageView = styled.div`
	&.__has-ops {
		.content-wrap {
			${props => props.theme.media.sdesk} {
				padding-right: 8px;
			}
		}
	}
	.progress-bar {
		position: absolute;
		z-index: 2;
	}
	.resource-alert-bar {
		z-index: 1;
	}
	.content-wrap {
		${props => props.theme.media.sdesk} {
			padding-right: ${props => get(props.theme.sizes, 'alertbar.val')};
		}
	}
	.content {
		padding-bottom: 2em;
	}
`

type PageProps = {
	children?: ReactNode
	isLoaded?: boolean
	operations?: PageOperation[]
}
const Page: React.FC<PageProps> = ({ children, isLoaded, operations }) => {
	return (
		<PageView className={`page-content${operations?.length ? ' __has-ops' : ''}`}>
			<ProgressBar isDone={isLoaded} startBar speed='short' />
			<AlertBar />
			<div className='content-wrap flex-desk'>
				<main className='content'>{children}</main>
				{operations?.length ? <Operations items={operations} /> : null}
			</div>
		</PageView>
	)
}
export default Page
