import styled, { css } from 'styled-components'
import { Card } from '../../../UI'
import { AcresByLeaseTypeWidgetLeaseType } from './AcresByLeaseType.dash.widget.leaseType'

const AcresByLeaseTypeDashWidgetStyles = css`
	.acres-items {
		display: grid;
		grid-gap: 0.5rem;
	}
`

const AcresByLeaseTypeDashWidgetView = styled.div`
	${AcresByLeaseTypeDashWidgetStyles}
`

const leaseTypes = ['Solar Energy', 'Mining', 'Geothermal', 'Wind']

type AcresByLeaseTypeDashWidgetProps = {
	className?: string
}
export const AcresByLeaseTypeDashWidget = ({
	className,
}: AcresByLeaseTypeDashWidgetProps): JSX.Element => {
	return (
		<AcresByLeaseTypeDashWidgetView
			className={`acres-by-lease-type-dash-widget${className ? ` className` : ''}`}
		>
			<Card noShadow titleText='LEASED'>
				<div className='acres-items'>
					{leaseTypes.map(leaseTypeName => (
						<AcresByLeaseTypeWidgetLeaseType
							key={leaseTypeName}
							leaseTypeName={leaseTypeName}
							className='acres-item'
						/>
					))}
				</div>
			</Card>
		</AcresByLeaseTypeDashWidgetView>
	)
}
