import includes from 'lodash/includes'
import pull from 'lodash/pull'
import React from 'react'
import styled from 'styled-components'
import {
	useActivityEstateTypesByParentQuery,
	useAddActivityEstateTypesMutation,
} from '../../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../../Types'
import { estateTypes, useState } from '../../../../utils'
import EstateTypeBtn from '../../../common/Estate/Estate.type.btn'
import { Btn, ErrorBlock, P, Spinner } from '../../../UI'
import { ResourceTableGlobalOpComponentOptions } from '../Table.resources'

const AddParcelEstatesToAcquisitionOpView = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.estate-type-btns {
		margin: 2em 0;
		justify-content: center;
		.icon {
			height: 1.5em;
		}
	}
`

type AddParcelEstatesToAcquisitionOpProps = ResourceTableGlobalOpComponentOptions

export const AddParcelEstatesToAcquisitionOp: React.FC<AddParcelEstatesToAcquisitionOpProps> = ({
	parentId,
	parentType,
	isTotalQtySelected,
	selectedRowIds,
	deselectedRowIds,
	closeModal,
}) => {
	const [error, setError] = useState<any | null>(null, 'error')
	const [selectedEstateTypes, setSelected] = useState<string[]>([], 'selectedEstateTypes')
	const [_, addActivity] = useAddActivityEstateTypesMutation()
	const [{ data, fetching }] = useActivityEstateTypesByParentQuery({
		variables: {
			parentId,
			parentType,
			parcelIds: isTotalQtySelected ? null : selectedRowIds,
			returnUsedEstateTypes: false,
		},
	})

	const { activityEstateTypes } = data || {}

	const typesForRender = estateTypes.filter(({ type }) => includes(activityEstateTypes || [], type))

	const addHandler = async () => {
		try {
			await addActivity({
				estateTypes: selectedEstateTypes,
				parentId,
				parentType,
				parcelIds: selectedRowIds,
				deselectedParcelIds: deselectedRowIds,
				isTotalQtySelected,
			})
			closeModal()
		} catch (err: any) {
			console.log(err)
			setError(err)
		}
	}

	const addType = (type: string) =>
		setSelected(state => {
			const results = [...state]
			if (includes(state, type)) {
				pull(results, type)
			} else {
				results.push(type as string)
			}
			return results
		})

	return (
		<AddParcelEstatesToAcquisitionOpView>
			<P>Select estate types to add to the selected parcels.</P>
			{fetching ? (
				<Spinner />
			) : (
				<div className='estate-type-btns flex'>
					{typesForRender.map(({ type }) => (
						<EstateTypeBtn
							key={type}
							type={type as EstateTypeEnum}
							onClick={addType}
							selected={selectedEstateTypes as EstateTypeEnum[]}
						/>
					))}
				</div>
			)}
			{error ? <ErrorBlock error={error} /> : null}
			<Btn className='cta' disabled={!selectedEstateTypes?.length} onClick={addHandler}>
				Add Estate Types
			</Btn>
		</AddParcelEstatesToAcquisitionOpView>
	)
}
