import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useState } from '../../../utils'
import { Btn } from '../Typography'
import { TabsNavItem, TabsNavItemProps } from './Tabs.nav.item'

const TabsNavView = styled.div`
	background: ${props => props.theme.colors.softSlate.light(0).val};
	border-bottom: solid 2px ${props => props.theme.colors.white.val};

	.tab-nav-outer {
		width: 100%;
		overflow-x: auto;
		position: relative;
		z-index: 1;

		@media only screen and (max-width: ${props => props.theme.breaks.sdesk.val}) {
			padding-bottom: 0;
			&::-webkit-scrollbar {
				display: none;
			}
		}
	}
	.tab-nav-inner {
		width: fit-content;
	}
	ul {
		position: relative;
		display: flex;
		width: fit-content;
		flex-wrap: nowrap;
		align-items: center;
		padding: 0.4em;
		${props => props.theme.media.sdesk} {
			flex-wrap: wrap;
			padding: 0.2em;
		}
	}

	button {
		padding: 0 0.2em;
		${props => props.theme.media.sdesk} {
			padding: 0.2em;
		}
	}

	.current-tab-title {
		/* border-left: solid 4px ${props => props.theme.colors.btn.light(0).val}; */
		padding: 0.5em 1em;
		color: ${props => props.theme.colors.white.val};
		text-transform: uppercase;
		background: ${props => props.theme.colors.white.tint(5).val};
		display: none;

		&.__has-icon {
			display: block;
			${props => props.theme.media.tablet} {
				display: none;
			}
		}
	}

	&.__has-more {
		.tab-nav-outer {
			${props => props.theme.media.sdesk} {
				padding-bottom: 15px;
			}
		}
		.current-tab-title {
			${props => props.theme.media.tablet} {
				&.__has-icon {
					display: block;
				}
			}
		}
		&:after {
			display: block;
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			right: 0;
			height: 100%;
			width: 60px;
			background: linear-gradient(
				to left,
				${props => props.theme.colors.white.tint(50).val} 0%,
				${props => props.theme.colors.white.tint(0).val} 100%
			);
		}
	}

	&.__size-sm {
		.tab-nav-item {
			font-size: 0.9rem;
			font-weight: 500;
			text-transform: none;
			height: 2em;
			padding: 0 1em;
		}
		.current-tab-title {
			font-size: 0.8em;
		}
	}
	&.__size-lg {
		.tab-nav-item {
			font-size: 1rem;
			font-weight: 700;
			text-transform: uppercase;
			height: 2.8em;
			padding: 0 2em;
		}
	}
`

export type TabsNavProps = {
	tabs: TabsNavItemProps[]
	currentIdx: number
	selectHandler: (idx: number) => void
	size?: 'sm' | 'lg'
}

export const TabsNav: React.FC<TabsNavProps> = ({
	tabs,
	currentIdx,
	selectHandler,
	size = 'lg',
}) => {
	const outerRef = useRef<HTMLDivElement>(null)
	const innerRef = useRef<HTMLDivElement>(null)

	const [hasMore, setHasMore] = useState(false, 'hasMore')

	useEffect(() => {
		const outerEl = outerRef.current
		const innerEl = innerRef.current

		const calcHasMore = () => {
			const outerWidth = outerEl?.offsetWidth || 0
			const innerWidth = innerEl?.offsetWidth || 0
			setHasMore(outerWidth < innerWidth)
		}

		calcHasMore()

		window?.addEventListener('resize', calcHasMore)

		return () => {
			window?.removeEventListener('resize', calcHasMore)
		}
	}, [])

	const currentTab = tabs[currentIdx]

	return tabs?.length ? (
		<TabsNavView className={`tabs-nav __size-${size}${hasMore ? ' __has-more' : ''}`}>
			<div className='tab-nav-outer' ref={outerRef}>
				<div className='tab-nav-inner' ref={innerRef}>
					<ul>
						{tabs.map(({ nav, icon, navVerbose }, idx) => (
							<li key={idx}>
								<Btn
									title={navVerbose || nav}
									disabled={currentIdx === idx}
									isBlank
									onClick={(e: any) => {
										e.preventDefault()
										e.stopPropagation()
										selectHandler(idx)
									}}
								>
									<TabsNavItem nav={nav} icon={icon} isActive={currentIdx === idx} />
								</Btn>
							</li>
						))}
					</ul>
				</div>
			</div>
			<div className={`current-tab-title${currentTab.icon ? ' __has-icon' : ''}`}>
				{currentTab.navVerbose || currentTab.nav}
			</div>
		</TabsNavView>
	) : null
}
