import React, { useRef } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useCreateLeaseMutation } from '../../../gql_generated/graphql'
import { defaultLeaseVals, parseDateForServer, priceToNumber } from '../../../utils'
import { Form, SubmitBtn } from '../../UI'
import LeaseFields, { leaseYupSchemaConfig } from './Ops.Lease.fields'
import LeaseTermFields from './Ops.Lease.terms.fields'

const View = styled.div`
	min-height: 300px;
	.estates-section {
		margin-bottom: 2em;
	}
	.estates-title {
		margin-bottom: 1em;
	}
`
const yupSchema = Yup.object().shape({
	...leaseYupSchemaConfig,
})

type CreateLeaseProps = {
	cb?: (id: number) => void
}
const CreateLease: React.FC<CreateLeaseProps> = ({ cb }) => {
	const [{ error }, createLease] = useCreateLeaseMutation()
	const stopRender = useRef(false) // component should no longer render markup

	return stopRender.current ? null : (
		<Form
			initialValues={defaultLeaseVals}
			submitErr={error}
			onSubmit={async (vals: any) => {
				try {
					const { canRenew, terms, ...data } = vals

					data.canRenew = canRenew === true

					data.lesseeDbaId = data.lesseeDbaId || null

					data.dateEffective = parseDateForServer(vals.dateEffective)
					data.dateShouldExpire = parseDateForServer(vals.dateShouldExpire)
					data.dateInsurExpires = parseDateForServer(vals.dateInsurExpires)
					data.terms = [
						{
							...terms,
							dateFrom: data.dateEffective,
							dateTo: data.dateShouldExpire,
						},
					]
					data.initialFee = vals.initialFee ? priceToNumber(vals.initialFee) : 0
					data.fees = [
						{
							amount: data.initialFee,
						},
					]

					const results = await createLease({ data })
					const leaseId = results.data?.createLease.id
					if (leaseId && cb) {
						stopRender.current = true
						cb(leaseId)
					}
				} catch (err) {
					console.error(err)
				}
			}}
			validationSchema={yupSchema}
		>
			{() => (
				<View>
					<LeaseFields isNew />
					<LeaseTermFields namePrefix='terms.' showDates={false} />
					<SubmitBtn />
				</View>
			)}
		</Form>
	)
}
export default CreateLease
