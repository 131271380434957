import { RouteComponentProps } from '@reach/router'
import React from 'react'
import styled from 'styled-components'
import CardsSection from '../../Components/common/CardsSection'
import Page from '../../Components/common/Resources/Resource.page'
import { Card, Link } from '../../Components/UI'
import { usePageData } from '../../utils'

const ResearchPageView = styled.div`
	.menu-card {
		margin: 0 auto;
		max-width: 500px;
	}

	li {
		.link-item {
			margin-bottom: 0.5em;
		}
	}

	.link-item {
		display: flex;
		padding: 2rem 4rem;
		border: solid 1px;
		border-radius: 5px;
		font-size: 1.5rem;
	}
`

const ResearchPage: React.FC<RouteComponentProps> = () => {
	usePageData({ pageTitle: 'Research', icon: 'research' })

	return (
		<ResearchPageView>
			<Page isLoaded>
				<CardsSection>
					<Card>
						<Card className='menu-card'>
							<ul>
								<li>
									<Link className='link-item' to='parcels-related'>
										Parcel Research
									</Link>
									<Link className='link-item' to='lease-payments'>
										Lease Payments
									</Link>
								</li>
							</ul>
						</Card>
					</Card>
				</CardsSection>
			</Page>
		</ResearchPageView>
	)
}
export default ResearchPage
