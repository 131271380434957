import styled from 'styled-components'

export default styled.div`
	padding-left: 0;
	position: relative;
	.content-wrap {
		font-size: 2rem;
	}
	.icon {
		width: auto;
		height: 2rem;
		max-width: initial;
	}
`
