import React from 'react'
import styled, { css } from 'styled-components/macro'
import { NoResultsSvg } from '../../../images/NNR-NoResults.svg'
import { H2 } from '../Typography'

export const tableNoResultsStyles = css`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2vw;
	opacity: 0.5;

	.inner-wrap {
		width: 100%;
		max-width: 400px;
	}
	h2 {
		position: absolute;
		z-index: 1;
		color: ${props => props.theme.colors.grey.light().val};
		left: 25%;
	}
	svg {
		width: 100%;
	}
`

const TableNoResultsView = styled.div`
	${tableNoResultsStyles}
`

export type TableNoResultsProps = {}
export const TableNoResults: React.FC<TableNoResultsProps> = () => {
	return (
		<TableNoResultsView className='table-no-results'>
			<div className='inner-wrap'>
				<H2>No Results Found...</H2>
				<NoResultsSvg />
			</div>
		</TableNoResultsView>
	)
}
