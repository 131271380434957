import React from 'react'
import styled from 'styled-components'
import { useDocForRecInfoQuery } from '../../../gql_generated/graphql'
import { KeyVals } from '../../UI'

export const DocRecInfoView = styled.div``

export type DocRecInfoProps = {
	docId: number
}
export const DocRecInfo: React.FC<DocRecInfoProps> = ({ docId }) => {
	const [results] = useDocForRecInfoQuery({ variables: { id: docId } })
	const doc = results.data?.doc
	const { recDocId, dateRecorded, book, page, state, county } = doc || {}
	return (
		<DocRecInfoView>
			<KeyVals
				items={[
					['Rec Doc Num', recDocId],
					['Date Recorded', dateRecorded, 'date'],
					['Book', book],
					['Page', page],
					['State', state, 'upper'],
					['County', county, 'proper'],
				]}
			/>
		</DocRecInfoView>
	)
}
