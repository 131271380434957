import React from 'react'
import { RouteComponentProps, Router } from '@reach/router'

import Protect from '../Components/common/Protect'
import Page404 from '../Pages/404.page'

import SalesPage from '../Pages/Sales'
import SalePage from '../Pages/Sale'

const SaleRoutes: React.FC<RouteComponentProps> = () => {
	return (
		<Protect>
			<Router>
				<SalesPage path='/' />
				<SalePage path='/:id' />
				<Page404 default />
			</Router>
		</Protect>
	)
}
SaleRoutes.propTypes = {}
export default SaleRoutes
