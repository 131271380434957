import styled, { css } from 'styled-components'
import { P } from '../../../../Components/UI'
import { researchRelatedAgreementsColsConfig } from '../../../../Components/UI/Table.resources/Table.resource.col.configs/Agreement.col.configs'
import { ResourcesTable } from '../../../../Components/UI/Table.resources/Table.resources'
import { useAgreementsForSaleRelatedActivityAgreementsQuery } from '../../../../gql_generated/graphql'
import { RelatedSaleActivityModal } from './Sale.related.activity.modal'

const RelatedSaleActivityAgreementsStyles = css``

const RelatedSaleActivityAgreementsView = styled.div`
	${RelatedSaleActivityAgreementsStyles}
`

type RelatedSaleActivityAgreementsProps = {
	className?: string
	saleId: number
}
export const RelatedSaleActivityAgreements = ({
	className,
	saleId,
}: RelatedSaleActivityAgreementsProps): JSX.Element => {
	const [fetchResults] = useAgreementsForSaleRelatedActivityAgreementsQuery({
		variables: {
			saleId,
		},
	})

	const agreements = fetchResults.data?.agreements

	return (
		<RelatedSaleActivityAgreementsView
			className={`related-sale-activity-agreements${className ? ` className` : ''}`}
		>
			<P>Active agreements that share parcel estates with this sale.</P>
			<ResourcesTable
				dataset={agreements}
				colsConfig={researchRelatedAgreementsColsConfig}
				fetchResults={fetchResults}
				displayOptions={{
					hideTotalQty: true,
				}}
				resourceType='agreement'
				suppressGoTo
				modalRenderFunc={agreement => (
					<RelatedSaleActivityModal agreementId={agreement.id} saleId={saleId} />
				)}
			/>
		</RelatedSaleActivityAgreementsView>
	)
}
