import React, { HtmlHTMLAttributes } from 'react'
import styled from 'styled-components'

import { Btn } from '../UI'

const IcoBtn = styled(Btn)`
	color: ${props => props.theme.colors.white.val};
	font-size: 13px;
	border: none;
	transition: none;
	${props => props.theme.media.tablet} {
		font-size: 15px;
	}
	.faux-icon {
		padding-top: 3px;
	}
	span {
		transition: all ${props => props.theme.times.tranS};
	}
	span.bar {
		display: block;
		height: 1px;
		width: 100%;
		background-color: ${props => props.theme.colors.white.val};
		margin: 5px 0;
		&:first-child {
			margin-top: 0;
		}
	}
	span.text {
		margin-top: -2px;
		display: block;
	}
	&:hover {
		color: ${props => props.theme.colors.btn.val};
		span.bar {
			background-color: ${props => props.theme.colors.btn.val};
		}
	}
`

const NavIco: React.FC<HtmlHTMLAttributes<HTMLButtonElement>> = props => {
	return (
		<IcoBtn {...props}>
			<div className='faux-icon'>
				<span className='bar'></span>
				<span className='bar'></span>
				<span className='text'>MENU</span>
			</div>
		</IcoBtn>
	)
}
export default NavIco
