import React from 'react'
import styled, { css } from 'styled-components/macro'
import { EasementDetail } from './Easement.detail'

export const genEasementModalStyles = css``

const GenEasementModalView = styled.div`
	${genEasementModalStyles}
`

export type GenEasementModalProps = {
	easementId: number
}
export const GenEasementModal: React.FC<GenEasementModalProps> = ({ easementId }) => {
	return (
		<GenEasementModalView>
			<EasementDetail easementId={easementId} />
		</GenEasementModalView>
	)
}
