import isFunction from 'lodash/isFunction'
import React from 'react'
import styled from 'styled-components'
import {
	SaleUpdateInput,
	useSaleFullQuery,
	useUpdateSaleMutation,
} from '../../../gql_generated/graphql'
import { parseDateForServer, priceToNumber, sanitizeDataForForm } from '../../../utils'
import { Async, Form, SubmitBtn, toastit } from '../../UI'
import SaleFields, { saleFieldsSchema } from './Ops.Sale.fields'

const EditSaleView = styled.div``

type EditSaleProps = {
	id: number
	cb?: (data: any) => void
}
const EditSale: React.FC<EditSaleProps> = ({ id, cb }) => {
	const [results] = useSaleFullQuery({ variables: { id } })
	const [_, updateSale] = useUpdateSaleMutation()

	const { sale } = results.data || {}

	const submitHandler = async (vals: any) => {
		try {
			const data = {
				...vals,
				id,
				brokerId: vals.brokerId > 0 ? vals.brokerId : null,
				brokerDbaId: vals.brokerDbaId ? vals.brokerDbaId : null,
				brokerRate: vals.brokerRate || 0,
				titleId: vals.titleId > 0 ? vals.titleId : null,
				titleDbaId: vals.titleDbaId ? vals.titleDbaId : null,
				granteeId: vals.granteeId > 0 ? vals.granteeId : null,
				granteeDbaId: vals.granteeDbaId ? vals.granteeDbaId : null,
				price: priceToNumber(vals.price),
				deposit: priceToNumber(vals.deposit),
				dateOpened: vals.dateOpened?.length ? parseDateForServer(vals.dateOpened) : null,
				dateExpected: vals.dateExpected?.length ? parseDateForServer(vals.dateExpected) : null,
			}
			let results = await updateSale({ data })

			if (results?.error) {
				toastit.err('Error saving sale...')
			} else if (isFunction(cb)) cb(results)
		} catch (err) {
			console.error(err)
		}
	}

	return (
		<EditSaleView>
			<Async fetchResults={results}>
				<Form
					legend='Edit Sale'
					initialValues={{
						brokerRate: 0,
						...sanitizeDataForForm<SaleUpdateInput>(sale, {
							stripKeys: [
								'status',
								'brokerDba',
								'titleCompanyDba',
								'granteeDba',
								'dateCancelled',
								'dateForeclosed',
								'dateClosed',
								'cancelReason',
								'foreclosureReason',
								'cbNote',
							],
						}),
					}}
					onSubmit={submitHandler}
					validationSchema={saleFieldsSchema}
				>
					{() => (
						<>
							<SaleFields />
							<SubmitBtn>Save</SubmitBtn>
						</>
					)}
				</Form>
			</Async>
		</EditSaleView>
	)
}
export default EditSale
