import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import { Amplify, Auth } from 'aws-amplify'
import { StoreProvider } from 'easy-peasy'

import React from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'

import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from 'styled-components'
import { createClient, Provider } from 'urql'
import { ErrorBlock } from './Components/UI'
import { AlertProvider } from './Components/UI/Alert'
import { WindowProvider } from './Components/Window.provider'
import Routes from './Routing'
import { store } from './store'
import ProjectStyles from './styles'
import { theme, ThemeGlobalStyles } from './theme'
import { urqlClientOptions } from './Urql.config/Urq.config'
import { urqlLogger } from './Urql.config/Urq.config.log'

const env = process.env.REACT_APP_ENV

// INITIAlIZE AWS
Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		authenticationFlowType: 'USER_SRP_AUTH',
		userPoolId: 'us-west-2_WtLfebEah',
		userPoolWebClientId: '37hklr24eccpqkq8np7t6ujj5t',
		region: 'us-west-2',
		identityPoolId: 'us-west-2:d5fa4fd1-e922-4bf3-8603-514c4e761a6e',
	},
	Storage: {
		AWSS3: {
			bucket: 'nnr-private',
			region: 'us-west-1',
		},
	},
})

Auth.currentSession()

// INITIALIZE ERROR TRACKING
// https://docs.bugsnag.com/platforms/javascript/react/

bugsnag.start({
	apiKey: 'e0fe9de8d9d16faba85afc1fbb6fefee',
	plugins: [new bugsnagReact()],
	onError: (event: any) => {
		// Disable for dev
		if (env === 'dev' || env === 'staging') {
			console.log(
				`%c BUGSNAG - report that would have sent:`,
				`color: ${theme.colors.white.val}; background-color: ${theme.colors.err.val}; padding: 4px;`,
				event
			)
			return false
		}

		// NOTE: You can mutate error report sent to bugsnag
		// see: https://docs.bugsnag.com/platforms/react-native/expo/customizing-error-reports/
	},
})

const ErrorBoundary = bugsnag.getPlugin('react').createErrorBoundary(React)

const gqlClient = createClient(urqlClientOptions)

// Add log debugging
if (gqlClient.subscribeToDebugTarget) gqlClient.subscribeToDebugTarget(urqlLogger)

const App = () => {
	return (
		<ErrorBoundary FallbackComponent={() => <ErrorBlock />}>
			<WindowProvider>
				<Provider value={gqlClient}>
					{/* @ts-ignore  TODO: remove this once we upgrade all packages */}
					<StoreProvider store={store}>
						<ThemeProvider theme={theme}>
							<ThemeGlobalStyles />
							<ProjectStyles />
							<SkeletonTheme
								baseColor={theme.colors.grey.light().val}
								highlightColor={theme.colors.grey.light(5).val}
							>
								<AlertProvider>
									<Routes />
									<ToastContainer
										position='bottom-left'
										autoClose={6000}
										closeOnClick
										pauseOnHover
									/>
								</AlertProvider>
							</SkeletonTheme>
						</ThemeProvider>
					</StoreProvider>
				</Provider>
			</WindowProvider>
		</ErrorBoundary>
	)
}

export default App
