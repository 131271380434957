import React from 'react'
import styled, { css } from 'styled-components/macro'
import { LogDetail } from '../../../Components/common/Log/Log.detail'

export const relatedDocLogModalStyles = css``

const RelatedDocLogModalView = styled.div`
	${relatedDocLogModalStyles}
`

export type RelatedDocLogModalProps = {
	logId: number
}
export const RelatedDocLogModal: React.FC<RelatedDocLogModalProps> = ({ logId }) => {
	return (
		<RelatedDocLogModalView>
			<LogDetail logId={logId} />
		</RelatedDocLogModalView>
	)
}
