import React from 'react'
import StepWizard from 'react-step-wizard'
import styled, { css } from 'styled-components/macro'
import {
	DocRelInputs,
	DocRelOptionsInput,
	useAddDocRelToDocMutation,
	useAddParcelsFromParentToDocMutation,
} from '../../../gql_generated/graphql'
import { ParentType } from '../../../Types'
import { useState } from '../../../utils'
import { isEmpty } from '../../../utils/lodash.utils'
import { ErrorBlock, H3, Spinner, toastit } from '../../UI'
import { AddDocToResourceConfirm } from './Ops.AddDocToResource.confirm/Ops.AddDocToResource.confirm'
import { AddDocToResourceSelectDoc } from './Ops.AddDocToResource.SelectDoc'

export const addDocToResourceOpStyles = css`
	min-width: 80vw;
	& > header {
		padding-bottom: 2em;
		margin-bottom: 2em;
		display: flex;
		border-bottom: solid 1px ${props => props.theme.colors.grey.light().val};

		h3 {
			color: ${props => props.theme.colors.secondary.val};
		}

		& > button {
			margin-left: auto;
		}
	}
`

const AddDocToResourceOpView = styled.div`
	${addDocToResourceOpStyles}
`

export type AddDocToResourceOpProps = {
	parentId: number
	parentType: ParentType
	cb?: () => void
}

export const AddDocToResourceOp: React.FC<AddDocToResourceOpProps> = ({
	parentId,
	parentType,
	cb,
}) => {
	const [docId, setDocId] = useState<number | null>(null, 'docId')

	const [noParcelsMutResults, addDocNoParcels] = useAddDocRelToDocMutation()
	const [withParcelsMutResults, addDocWithParcels] = useAddParcelsFromParentToDocMutation()

	const fetching = noParcelsMutResults.fetching || withParcelsMutResults.fetching
	const error = noParcelsMutResults.error || withParcelsMutResults.error

	const submitHandler = async (options: DocRelOptionsInput) => {
		if (!docId) return

		const data: DocRelInputs = {}

		if (parentType === 'sale') data.saleId = parentId
		else if (parentType === 'acquisition') data.acquisitionId = parentId
		else if (parentType === 'lease') data.leaseId = parentId
		else if (parentType === 'agreement') data.agreementId = parentId
		else if (parentType === 'easement') data.easementId = parentId

		try {
			let res: any
			if (isEmpty(options))
				res = await addDocNoParcels({
					docId,
					data: [data],
				})
			else
				res = await addDocWithParcels({
					docId,
					parentId,
					parentType,
					data: [data],
					options,
				})

			if (res.error) toastit.err(`Error adding doc to ${parentType}`)
			else if (cb) cb()
		} catch (err) {}
	}

	return (
		<AddDocToResourceOpView>
			<header>
				<H3>Add Doc to {parentType}</H3>
			</header>
			{fetching ? <Spinner /> : null}
			{error ? <ErrorBlock error={error} /> : null}
			<StepWizard>
				<AddDocToResourceSelectDoc
					parentId={parentId}
					parentType={parentType}
					docId={docId}
					setDocId={setDocId}
				/>
				<AddDocToResourceConfirm
					docId={docId}
					parentType={parentType}
					parentId={parentId}
					submitHandler={submitHandler}
				/>
			</StepWizard>
		</AddDocToResourceOpView>
	)
}
