import { useFormikContext } from 'formik'
import startCase from 'lodash/startCase'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Estate } from '../../../gql_generated/graphql'
import { estateTypes } from '../../../utils'
import { Card, H6, Icon, Number, Select, Text, Textarea } from '../../UI'
import { IconType } from '../../UI/Icon/fa.defaults'

const View = styled.div`
	.estates-wrap {
		${props => props.theme.media.tablet} {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 1em;
		}
		${props => props.theme.media.sdesk} {
			grid-template-columns: repeat(5, 1fr);
		}
	}
	.estate-info {
		padding: 1em;
		border: solid 1px ${props => props.theme.colors.grey.light().val};
		h6 {
			margin-bottom: 0.5em;
		}
	}
`

type ParcelEstateFieldsProps = {
	show: boolean
	isUpdate?: boolean
}
const ParcelEstateFields: React.FC<ParcelEstateFieldsProps> = ({ show, isUpdate }) => {
	const { values, setValues } = useFormikContext<any>()
	const { acres, aliquot } = values || {}
	const initialEstateVals = {
		acres: acres || 0,
		aliquot: aliquot || '',
	}
	useEffect(() => {
		if (!isUpdate && (acres || aliquot)) {
			setValues({
				...values,
				srf: {
					...values.srf,
					...initialEstateVals,
				},
				ind: {
					...values.ind,
					...initialEstateVals,
				},
				min: {
					...values.min,
					...initialEstateVals,
				},
				oil: {
					...values.oil,
					...initialEstateVals,
				},
				geo: {
					...values.geo,
					...initialEstateVals,
				},
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [acres, aliquot, isUpdate])
	return !show ? null : (
		<View className='estates'>
			{estateTypes.map(({ type, typeFull }) => {
				const { status } = values[type as 'srf'] as Estate
				return (
					<Card className='estate-fields' key={type}>
						<H6>
							<Icon type={type as IconType} padRight />
							{startCase(typeFull)}
						</H6>
						{status === 'Conveyed' ? (
							<Text name={`${type}.status`} label='Status' disabled />
						) : (
							<Select
								name={`${type}.status`}
								label='Status'
								options={[{ val: 'Available' }, { val: 'Unowned' }]}
							/>
						)}
						<Number name={`${type}.acres`} label='Acres' />
						<Textarea name={`${type}.aliquot`} label='Aliquot' />
					</Card>
				)
			})}
		</View>
	)
}
export default ParcelEstateFields
