import React from 'react'
import styled, { css } from 'styled-components/macro'
import { useOrgForContactsListQuery } from '../../../gql_generated/graphql'
import { Async, H5 } from '../../UI'
import { Table } from '../../UI/Table/Table'

export const orgContactsListStyles = css``

const OrgContactsListView = styled.div`
	${orgContactsListStyles}
`

export type OrgContactsListProps = {
	orgId: number
}

export const OrgContactsList: React.FC<OrgContactsListProps> = ({ orgId }) => {
	const [results] = useOrgForContactsListQuery({ variables: { id: orgId } })
	const contacts = results.data?.org?.contacts
	return (
		<OrgContactsListView>
			<H5 mBot='1em'>Contacts</H5>
			<Async fetchResults={results}>
				{contacts?.length ? (
					<Table
						cols={[{ label: 'Name' }, { label: 'Email' }, { label: 'Phone' }]}
						rows={contacts.map((contact, rowIdx) => ({
							id: contact.id,
							val: contact,
							rowIdx,
							cells: [
								{
									txt: contact.fullName || contact.firstName || '',
								},
								{
									txt: contact.email || '',
								},
								{
									txt: contact.phoneWork || contact.phoneWork || '',
								},
							],
						}))}
					/>
				) : (
					'No contacts...'
				)}
			</Async>
		</OrgContactsListView>
	)
}
