import styled, { css } from 'styled-components'
import { LeaseTermsExpirationFilterPeriodOption } from '../../../../Types'
import { SelectRaw } from '../../../UI'
import { SelectOptions } from '../../../UI/Form.Fields/Select'

const ExpiredLeaseDashWidgetFiltersStyles = css`
	background: ${props => props.theme.colors.white.val};
`

const ExpiredLeaseDashWidgetFiltersView = styled.div`
	${ExpiredLeaseDashWidgetFiltersStyles}
`

const options: SelectOptions[] = [
	{
		val: 'NOW',
		txt: 'By today',
	},
	{
		val: 'NEXT 60 DAYS',
		txt: 'By 60 days from now',
	},
	{
		val: 'END OF YEAR',
		txt: 'By the end of the current year',
	},
	{
		val: 'SIX MONTHS',
		txt: 'By six months from now',
	},
]

type ExpiredLeaseDashWidgetFiltersProps = {
	className?: string
	onChange: (period: LeaseTermsExpirationFilterPeriodOption) => void
	value: LeaseTermsExpirationFilterPeriodOption
}
export const ExpiredLeaseDashWidgetFilters = ({
	className,
	onChange,
	value,
}: ExpiredLeaseDashWidgetFiltersProps): JSX.Element => {
	return (
		<ExpiredLeaseDashWidgetFiltersView className={`${className ? ` className` : ''}`}>
			<SelectRaw
				name='periodFilter'
				onChange={e => onChange(e.target.value as LeaseTermsExpirationFilterPeriodOption)}
				value={value}
				defaultValue={'NOW'}
				options={options}
			/>
		</ExpiredLeaseDashWidgetFiltersView>
	)
}
