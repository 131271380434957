import { Resolver } from '@urql/exchange-graphcache'

export type MergeMode = 'before' | 'after'

export type PaginationParams = {
	debug?: boolean
}

export const urqlPagination = (params: PaginationParams = {}): Resolver => {
	const { debug } = params

	const resolver: Resolver = (parent, _fieldArgs, cache, info) => {
		const { parentKey: entityKey, fieldName, variables } = info

		debug && console.groupCollapsed('URQL PAGINATION')
		debug && console.log('fieldName: ', fieldName)
		debug && console.log('variables: ', variables)
		debug && console.log('parent: ', parent)
		debug && console.log('info: ', info)

		const dataSetLinks = cache.resolve(entityKey, fieldName)

		debug && console.log('dataSetLinks: ', dataSetLinks)
		debug && console.groupEnd()

		const result = dataSetLinks

		return result
	}

	return resolver
}
