import React from 'react'
import { RouteComponentProps, Router } from '@reach/router'

import RoyaltiesPage from '../Pages/Royalties'
import Protect from '../Components/common/Protect'
import Page404 from '../Pages/404.page'

const RoyaltyRoutes: React.FC<RouteComponentProps> = () => {
	return (
		<Protect>
			<Router>
				<RoyaltiesPage path='/' />
				<Page404 default />
			</Router>
		</Protect>
	)
}
export default RoyaltyRoutes
