import React from 'react'
import styled, { css } from 'styled-components/macro'
import { relatedDocsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Doc.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { DocParent } from '../../../gql_generated/document_types'
import { usePaginatedDocsQuery } from '../../../gql_generated/graphql'
import { RelatedParcelDocModal } from '../RelatedModals/Parcel.related.doc.modal'

export const relatedParcelDocsStyles = css``

const RelatedParcelDocsView = styled.div`
	${relatedParcelDocsStyles}
`

export type RelatedParcelDocsProps = {
	parcelId: number
}

export const RelatedParcelDocs: React.FC<RelatedParcelDocsProps> = ({ parcelId }) => {
	const paginationStore = usePaginationParams()

	const [fetchResults] = usePaginatedDocsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: DocParent.Parcel,
						parentIds: [parcelId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.docs
	return (
		<RelatedParcelDocsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedDocsColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedParcelDocModal docId={data.id} />}
				resourceType='doc'
			/>
		</RelatedParcelDocsView>
	)
}
