import React from 'react'
import styled, { css } from 'styled-components/macro'
import { orgRelatedLeasesColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Lease.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { LeaseParent } from '../../../gql_generated/document_types'
import { usePaginatedLeasesQuery } from '../../../gql_generated/graphql'
import { RelatedOrgLeaseModal } from '../Related.Modals/Org.related.lease.modal'

export const relatedOrgLeasesStyles = css``

const RelatedOrgLeasesView = styled.div`
	${relatedOrgLeasesStyles}
`

export type RelatedOrgLeasesProps = {
	orgId: number
}

export const RelatedOrgLeases: React.FC<RelatedOrgLeasesProps> = ({ orgId }) => {
	const paginationStore = usePaginationParams()

	const [fetchResults] = usePaginatedLeasesQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: LeaseParent.Org,
						parentIds: [orgId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const leases = data?.page?.leases
	return (
		<RelatedOrgLeasesView>
			<ResourcesTable
				dataset={leases}
				colsConfig={orgRelatedLeasesColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={lease => <RelatedOrgLeaseModal leaseId={lease.id} />}
				resourceType='lease'
			/>
		</RelatedOrgLeasesView>
	)
}
