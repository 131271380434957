import React from 'react'
import styled from 'styled-components'
import { parseApolloErrs } from '../../../utils'
import { Icon } from '../Icon'

const View = styled.div`
	color: ${props => props.theme?.colors?.err.val};
	display: flex;
	align-items: center;
	padding: 0;
	&.__multi-line {
		padding: 2em;
		display: block;
	}
	&.__border {
		border: solid 1px;
	}
	.icon {
		margin-right: 0.5em;
	}
	.error {
		border: solid 1px;
		display: flex;
		align-items: stretch;
		.error-icon {
			display: flex;
			align-items: center;
			padding: 0 1em;
			background: ${props => props.theme.colors.err.light(6).val};
			border-right: solid 3px ${props => props.theme.colors.err.val};
			.icon {
				margin: 0;
				height: 3em;
			}
		}
	}
	.error-content {
	}
	.error-msg {
		padding: 1.5em 0.5em;
	}
	.error-code {
		display: block;
		text-align: left;

		span {
			display: inline-block;
			padding: 0.5em;
			font-weight: 600;
			color: ${props => props.theme.colors.grey.val};
		}
	}
`
export type ErrorBlockErr = Error | any | null
type ErrorBlockProps = {
	error?: ErrorBlockErr
	isMultiLine?: boolean
	hasBorder?: boolean
}

const ErrorBlock: React.FC<ErrorBlockProps> = ({ error, isMultiLine, hasBorder, children }) => {
	if (error) console.dir(error)
	const errs = parseApolloErrs(error)
	return (
		<View
			className={`error-block${isMultiLine ? ' __multi-line' : ''}${hasBorder ? ' __border' : ''}`}
		>
			{children ||
				(errs?.length
					? errs.map((er, idx) => {
							const { code, origMsg, humanMsg } = er || {}
							return er ? (
								<div className='error' key={idx}>
									<div className='error-icon'>
										<Icon type='err' />
									</div>
									<div className='error-content'>
										<div className='error-msg'>{origMsg ? origMsg : humanMsg}</div>
										{code ? (
											<div className='error-code'>
												<span className='code'>ERR-CODE: {code}</span>
											</div>
										) : null}
									</div>
								</div>
							) : null
					  })
					: null)}
		</View>
	)
}
export default ErrorBlock
