import React from 'react'
import styled, { css } from 'styled-components/macro'
import { OrgDetail } from '../../../Components/common/Org/Org.detail'

export const relatedLeaseOrgModalStyles = css``

const RelatedLeaseOrgModalView = styled.div`
	${relatedLeaseOrgModalStyles}
`

export type RelatedLeaseOrgModalProps = {
	orgId: number
}
export const RelatedLeaseOrgModal: React.FC<RelatedLeaseOrgModalProps> = ({ orgId }) => {
	return (
		<RelatedLeaseOrgModalView>
			<OrgDetail orgId={orgId} />
		</RelatedLeaseOrgModalView>
	)
}
