import { Redirect, RouteComponentProps } from '@reach/router'
import React from 'react'
import CardsSection from '../../Components/common/CardsSection'
import { PageOperation } from '../../Components/common/Operations'
import { GenOrgModal } from '../../Components/common/Org/Org.gen.modal'
import Page from '../../Components/common/Resources/Resource.page'
import CreateOrg from '../../Components/Ops/Ops.Orgs/Ops.Org.create'
import { Card, Modal } from '../../Components/UI'
import { orgsPageColsConfig } from '../../Components/UI/Table.resources/Table.resource.col.configs/Org.col.configs'
import { ResourcesTable } from '../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../Components/UI/Table.resources/usePaginationParams'
import { usePaginatedOrgsQuery } from '../../gql_generated/graphql'
import { usePageData, useState } from '../../utils'

const OrgsPage: React.FC<RouteComponentProps> = () => {
	const [Op, setOp] = useState<string | null>(null, 'Op')
	const [redirect, setRedirect] = useState<string | null>(null, 'redirect')

	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedOrgsQuery({
		variables: {
			params: {
				...paginationStore.params,
			},
		},
	})
	const { data } = fetchResults
	const orgs = data?.page?.orgs

	usePageData({ pageType: 'orgs', pageTitle: 'Orgs' })

	const operations: PageOperation[] = [
		{
			txt: 'New Org',
			icon: 'org',
			op: () => setOp('create'),
		},
	]

	// const user = useStoreState(state => state.user.me)

	return redirect ? (
		<Redirect to={redirect} noThrow />
	) : (
		<Page isLoaded operations={operations}>
			<CardsSection>
				<Card>
					<ResourcesTable
						dataset={orgs}
						colsConfig={orgsPageColsConfig}
						fetchResults={fetchResults}
						paginationStore={paginationStore}
						includeSearch
						modalRenderFunc={data => <GenOrgModal orgId={data.id} />}
						resourceType='org'
					/>
				</Card>
			</CardsSection>
			<Modal show={!!Op} closeHandler={() => setOp(null)}>
				{Op === 'create' ? (
					<CreateOrg
						cb={org => {
							const id = org?.data?.createOrg.id
							if (id) setRedirect(`/mgr/orgs/${id}`)
							else {
								setOp(null)
							}
						}}
					/>
				) : null}
			</Modal>
		</Page>
	)
}
export default OrgsPage
