import { action, Action } from 'easy-peasy'
import isBoolean from 'lodash/isBoolean'

export type ViewStoreModel = {
	context: string
	isMobileNavOpen: boolean
	isDeskNavOpen: boolean
	isDeskMenuOpen: boolean
	isCardsOpen: boolean
	isOpBarOpen: boolean
	isAdvSearchOpen: boolean

	setContext: Action<ViewStoreModel, string | null>
	toggleMobileNav: Action<ViewStoreModel>
	toggleDeskNav: Action<ViewStoreModel, boolean | null>
	toggleDeskMenu: Action<ViewStoreModel, boolean | null>
	toggleCardsBar: Action<ViewStoreModel, boolean | null>
	toggleOpBar: Action<ViewStoreModel, boolean | null>
	toggleAdvSearch: Action<ViewStoreModel, boolean | null>
}

export const viewModel: ViewStoreModel = {
	context: 'public',

	isMobileNavOpen: false,
	isDeskNavOpen: false,
	isDeskMenuOpen: true,
	isCardsOpen: true,
	isOpBarOpen: true,
	isAdvSearchOpen: false,

	setContext: action((state, context) => {
		if (!context && state.context === 'public') return
		if (state.context !== context) state.context = context || 'public'
	}),

	toggleMobileNav: action(state => {
		state.isMobileNavOpen = !state.isMobileNavOpen
	}),

	toggleDeskNav: action((state, override) => {
		if (isBoolean(override)) state.isDeskNavOpen = override
		else state.isDeskNavOpen = !state.isDeskNavOpen
	}),
	toggleDeskMenu: action((state, override) => {
		if (isBoolean(override)) state.isDeskMenuOpen = override
		else state.isDeskMenuOpen = !state.isDeskMenuOpen
	}),
	toggleCardsBar: action((state, override) => {
		if (isBoolean(override)) state.isCardsOpen = override
		else state.isCardsOpen = !state.isCardsOpen
	}),
	toggleOpBar: action((state, override) => {
		if (isBoolean(override)) state.isOpBarOpen = override
		else state.isOpBarOpen = !state.isOpBarOpen
	}),
	toggleAdvSearch: action((state, override) => {
		if (isBoolean(override)) state.isAdvSearchOpen = override
		else state.isAdvSearchOpen = !state.isAdvSearchOpen
	}),
}
