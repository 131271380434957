import includes from 'lodash/includes'
import pull from 'lodash/pull'
import React from 'react'
import styled, { css } from 'styled-components'
import { Btn, ErrorBlock, P, Spinner } from '../..'
import {
	useActivityEstateTypesByParentQuery,
	useAddActivityEstateTypesMutation,
} from '../../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../../Types'
import { estateTypes, useState } from '../../../../utils'
import EstateTypeBtn from '../../../common/Estate/Estate.type.btn'
import { Async } from '../../Async'
import { toastit } from '../../Toaster'
import { ResourceTableGlobalOpComponentOptions } from '../Table.resources'

const addParcelEstatesToSaleOpStyles = css`
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.estate-type-btns {
		margin: 2em 0;
		justify-content: center;
		.icon {
			height: 1.5em;
		}
	}
`

const AddParcelEstatesToSaleOpView = styled.div`
	${addParcelEstatesToSaleOpStyles}
`

type AddParcelEstatesToSaleOpProps = ResourceTableGlobalOpComponentOptions
export const AddParcelEstatesToSaleOp: React.FC<AddParcelEstatesToSaleOpProps> = ({
	parentId,
	parentType,
	selectedRowIds,
	deselectedRowIds,
	isTotalQtySelected,
	closeModal,
}) => {
	const [selectedEstateTypes, setSelected] = useState<string[]>([], 'selectedEstateTypes')
	const [mutResults, addActivity] = useAddActivityEstateTypesMutation()
	const [activityResults] = useActivityEstateTypesByParentQuery({
		variables: {
			parentId,
			parentType,
			parcelIds: isTotalQtySelected ? null : selectedRowIds,
			returnUsedEstateTypes: false,
		},
	})

	const { activityEstateTypes } = activityResults.data || {}

	const typesForRender = estateTypes.filter(({ type }) => includes(activityEstateTypes || [], type))

	const addHandler = async () => {
		console.log('adding estate types: ', selectedEstateTypes)
		try {
			const res = await addActivity({
				estateTypes: selectedEstateTypes,
				parentId,
				parentType,
				parcelIds: selectedRowIds,
				deselectedParcelIds: deselectedRowIds,
				isTotalQtySelected,
			})
			if (res.error) toastit.err('Error adding estate types')
			else closeModal()
		} catch (err) {
			toastit.err('Error adding estate types')
		}
	}

	const addType = (type: string) =>
		setSelected(state => {
			const results = [...state]
			if (includes(state, type)) {
				pull(results, type)
			} else {
				results.push(type as string)
			}
			return results
		})

	return (
		<AddParcelEstatesToSaleOpView>
			<Async fetchResults={activityResults}>
				{mutResults.error ? <ErrorBlock error={mutResults.error} /> : null}
				{mutResults.fetching ? <Spinner /> : null}
				<P>Select estate types to add to the selected parcels.</P>

				<div className='estate-type-btns flex'>
					{typesForRender.map(({ type }) => (
						<EstateTypeBtn
							key={type}
							type={type as EstateTypeEnum}
							onClick={addType}
							selected={selectedEstateTypes as EstateTypeEnum[]}
						/>
					))}
				</div>
				<Btn className='cta' disabled={!selectedEstateTypes?.length} onClick={addHandler}>
					Add Estate Types
				</Btn>
			</Async>
		</AddParcelEstatesToSaleOpView>
	)
}
