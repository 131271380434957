import React from 'react'
import styled from 'styled-components'
import {
	Sale,
	SaleUpdateStatusInput,
	useUpdateSalesStatusMutation,
} from '../../../gql_generated/graphql'
import { formatDate, parseDateForServer } from '../../../utils'
import { Form, SubmitBtn, Text, ToggleField } from '../../UI'

const OpCloseSaleView = styled.div``

type OpCloseSaleProps = {
	sale: Sale
	cb?: () => void
}
const OpCloseSale: React.FC<OpCloseSaleProps> = ({ sale, cb }) => {
	const { id } = sale
	const [_, updateStatus] = useUpdateSalesStatusMutation()

	return (
		<OpCloseSaleView>
			<Form
				legend='Close Sale'
				initialValues={{
					dateClosed: formatDate(new Date(), 'input'),
					autoTerminateActivity: false,
					autoAssignActivity: false,
				}}
				onSubmit={async (data: SaleUpdateStatusInput) => {
					try {
						await updateStatus({
							data: {
								...data,
								id,
								dateClosed: parseDateForServer(data.dateClosed),
							},
						})
						if (cb) cb()
					} catch (err) {}
				}}
			>
				{() => (
					<>
						<Text type='date' name='dateClosed' label='Date Closed' isReq />
						<ToggleField
							name='autoTerminateActivity'
							label='Auto terminate lease/agreement estates?'
							helperTxt='Terminate any estates on leases/agreements that are also on this sale'
						/>
						<ToggleField
							name='autoAssignActivity'
							label='Auto assign lease/agreements to grantee?'
							helperTxt='Assign leases/agreements on any estate on this Sale over to the grantee.'
						/>
						<SubmitBtn>Close Sale</SubmitBtn>
					</>
				)}
			</Form>
		</OpCloseSaleView>
	)
}
export default OpCloseSale
