import React from 'react'
import styled, { css } from 'styled-components/macro'
import { ParcelDetail } from '../../../Components/common/Parcel/Parcel.detail'

export const relatedAcquisitionParcelModalStyles = css``

const RelatedAcquisitionParcelModalView = styled.div`
	${relatedAcquisitionParcelModalStyles}
`

export type RelatedAcquisitionParcelModalProps = {
	parcelId: number
}
export const RelatedAcquisitionParcelModal: React.FC<RelatedAcquisitionParcelModalProps> = ({
	parcelId,
}) => {
	return (
		<RelatedAcquisitionParcelModalView>
			<ParcelDetail parcelId={parcelId} />
		</RelatedAcquisitionParcelModalView>
	)
}
