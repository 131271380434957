import { ReactNode, useCallback } from 'react'
import styled from 'styled-components/macro'
import { UseQueryResponse } from 'urql'
import { useStoreState } from '../../../store'
import { TableBody, TableBodyProps } from './Table.body'
import { TableHead } from './Table.head/Table.head'
import { TableHeadRow, TableHeadRowProps } from './Table.head/Table.head.row'
import { TableNoResults } from './Table.noResults'
import { TableStyleProps, tableStyles } from './Table.styles'
import {
	TableClickRowHandler,
	TableDisableRow,
	TableGlobalOpsConfig,
	TableHeadCellConfig,
	TableRowConfig,
	TableRowOpConfig,
	TableSelectAllHandler,
	TableSelectRowHandler,
} from './Table.types'
import { TableSelectRowsState } from './useTableSelectRows'
import { UseTableSort } from './useTableSort'
import { TableStore } from './usetTableState'

const TableView = styled.div`
	${tableStyles}
`
export type TableDisplayOptions = TableStyleProps & {
	isCentered?: boolean
	suppressLoader?: boolean
	suppressLastSelected?: boolean
	suppressSorting?: boolean
}

/**
 *  Select rows configuration option types
 * */
export type TableGlobalOpsConfigOptions = {
	totalQty: TableGlobalOpsConfig['totalQty']
	tableOps: TableGlobalOpsConfig['tableOps']
	selectRowsStore: TableSelectRowsState
}

export type TableSelectRowsConfigOptions<T> = {
	selectedRowIds: number[]
	selectRowHandler: TableSelectRowHandler<T>
	selectType?: TableRowConfig<T>['selectType']
}

/**
 *  Table Prop types
 * */

export type TableProps<T> = Partial<Pick<UseQueryResponse[0], 'fetching' | 'error'>> & {
	className?: string
	cols: TableHeadCellConfig[]
	rows: TableRowConfig<T>[]

	colsQty?: number
	rowsQty?: number
	clickRowHandler?: TableClickRowHandler<T>

	displayOptions?: TableDisplayOptions

	globalOpsConfig?: TableGlobalOpsConfigOptions
	selectRowConfig?: TableSelectRowsConfigOptions<T>

	sortConfig?: UseTableSort

	tableStore?: TableStore

	rowOps?: TableRowOpConfig<T>[]

	disableRow?: TableDisableRow

	// Overrides normal table rendering with an injected component
	// Useful for conditionally rendering a friendly graphic in place of undesired table render
	contentOverride?: ReactNode
}

/**
 *  Table Component
 * */

export function Table<T>(props: TableProps<T>) {
	const {
		cols,
		rows,
		className,

		fetching,
		error,

		colsQty,
		rowsQty,

		tableStore,

		clickRowHandler,

		displayOptions,

		globalOpsConfig,
		selectRowConfig,

		sortConfig,

		rowOps: incomingRowOps,

		disableRow,

		contentOverride,
	} = props
	const { role, isAdmin, isMgr } = useStoreState(state => state.user)

	const { tableState, updateTableState } = tableStore || {}
	const { lastActiveIdx } = tableState || {}
	const { setLastActiveIdx } = updateTableState || {}
	const { isCentered, maxWidth, suppressLoader, suppressLastSelected, suppressSorting } =
		displayOptions || {}
	const { tableOps: incomingTableOps, totalQty, selectRowsStore } = globalOpsConfig || {}
	const { selectRowHandler, selectType } = selectRowConfig || {}

	const tableOps =
		isAdmin || isMgr
			? incomingTableOps
			: incomingTableOps?.filter(({ allowed }) => (allowed ? allowed.includes(role) : true))

	// NOTE: If a selectRowHandler is passed in then we shouldn't update the selected rows directly (this means that selectedRowIds are managed in a parent component)
	const { isTotalQtySelected, updateSelectRowState } = selectRowsStore || {}

	const selectedRowIds = selectRowConfig?.selectedRowIds || selectRowsStore?.selectedRowIds
	const deselectedRowIds = selectRowsStore?.deselectedRowIds || []

	const enableRowSelection = !!globalOpsConfig || !!selectRowConfig

	const rowOps =
		isAdmin || isMgr
			? incomingRowOps
			: incomingRowOps?.filter(({ allowed }) => (allowed ? allowed.includes(role) : true))
	/**
	 *  Select all rows handler
	 * */
	// NOTE: This should never get called if a selectRowsConfig is passed in
	const handleSelectAll: TableSelectAllHandler = useCallback(
		args => {
			const { isSelected, selectTotalQty } = args
			const val =
				isSelected === false && selectTotalQty === false // (Unselect all)
					? false
					: isSelected
					? selectTotalQty || rows.map(({ id }) => id)
					: []
			updateSelectRowState && updateSelectRowState(val)
		},
		[rows, updateSelectRowState]
	)

	/**
	 *  Select individual row
	 * */

	const handleSelectRow = useCallback(
		(val: any, isSelected) => {
			// NOTE: We should only directly set the selected ids if there is no selectRowHandler
			if (selectRowHandler) selectRowHandler(val, isSelected)
			else if (updateSelectRowState) {
				if (typeof val === 'number') updateSelectRowState([val])
				else if ('id' in val && typeof val.id === 'number') updateSelectRowState([val.id as number])
			}
		},
		[selectRowHandler, updateSelectRowState]
	)

	/**
	 *  Configure props for table head
	 * */
	const headProps: TableHeadRowProps = {
		cols,

		globalOps: tableOps &&
			selectedRowIds && {
				tableOps: tableOps,
				totalQty,
				rowsQty: rows.length,
				isTotalQtySelected: !!isTotalQtySelected,
				deselectedRowIds,
				selectAllHandler: handleSelectAll,
				selectedRowIds: selectedRowIds,
			},

		sortConfig: suppressSorting ? undefined : sortConfig,

		hasRowOps: !!rowOps?.length,
		hasSelectRows: enableRowSelection,
	}
	/**
	 *  Configure props for table body
	 * */
	const bodyProps: TableBodyProps<T> = {
		clickHandler: clickRowHandler,
		colsQty: colsQty || cols.length,

		error,
		loading: suppressLoader ? false : fetching,
		rowOps,
		rowsQty: rowsQty || rows.length || 4,
		lastActiveIdx:
			typeof lastActiveIdx === 'number' && !suppressLastSelected ? lastActiveIdx : undefined,
		setLastActiveIdx,

		selectHandler: enableRowSelection ? handleSelectRow : undefined,

		suppressRowRender: !!contentOverride,
		selectType,

		rows: rows.map(({ id, isDisabled, val, ...row }) => ({
			id,
			isSelected: isTotalQtySelected
				? !deselectedRowIds.includes(id)
				: selectedRowIds?.length
				? selectedRowIds.includes(id)
				: false,
			isDisabled:
				typeof isDisabled === 'boolean' ? isDisabled : disableRow && id ? disableRow(id) : false,
			val,
			...row,
		})),
	}

	return (
		<>
			<TableView
				className={`table${className ? ` ${className}` : ''}${isCentered ? ' __centered' : ''}${
					contentOverride ? ' __content-overriden' : ''
				}`}
				maxWidth={maxWidth}
			>
				<table>
					<TableHead>
						<TableHeadRow {...headProps} />
					</TableHead>
					<TableBody {...bodyProps} />
				</table>
				{contentOverride ? contentOverride : !fetching && !rows?.length ? <TableNoResults /> : null}
			</TableView>
		</>
	)
}
