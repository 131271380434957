import styled, { css } from 'styled-components'
import { P } from '../../../../Components/UI'
import { researchRelatedLeasesColsConfig } from '../../../../Components/UI/Table.resources/Table.resource.col.configs/Lease.col.configs'
import { ResourcesTable } from '../../../../Components/UI/Table.resources/Table.resources'
import { useLeasesForSaleRelatedActivityLeasesQuery } from '../../../../gql_generated/graphql'
import { RelatedSaleActivityModal } from './Sale.related.activity.modal'

const RelatedSaleActivityLeasesStyles = css``

const RelatedSaleActivityLeasesView = styled.div`
	${RelatedSaleActivityLeasesStyles}
`

type RelatedSaleActivityLeasesProps = {
	className?: string
	saleId: number
}
export const RelatedSaleActivityLeases = ({
	className,
	saleId,
}: RelatedSaleActivityLeasesProps): JSX.Element => {
	const [fetchResults] = useLeasesForSaleRelatedActivityLeasesQuery({
		variables: {
			saleId,
		},
	})

	const leases = fetchResults.data?.leases

	return (
		<RelatedSaleActivityLeasesView
			className={`related-sale-activity-leases${className ? ` className` : ''}`}
		>
			<P>Active leases that share parcel estates with this sale.</P>
			<ResourcesTable
				dataset={leases}
				colsConfig={researchRelatedLeasesColsConfig}
				fetchResults={fetchResults}
				resourceType='lease'
				suppressGoTo
				displayOptions={{
					hideTotalQty: true,
				}}
				modalRenderFunc={lease => <RelatedSaleActivityModal leaseId={lease.id} saleId={saleId} />}
			/>
		</RelatedSaleActivityLeasesView>
	)
}
