import isFunction from 'lodash/isFunction'
import React, { useRef } from 'react'
import {
	EasementInput,
	useEasementPageQuery,
	useUpdateEasementMutation,
} from '../../../gql_generated/graphql'
import { parseDateForServer, priceToNumber, sanitizeDataForForm } from '../../../utils'
import { Block, Form, Spinner, SubmitBtn } from '../../UI'
import EasementFields, { easementSchema } from './Ops.Easement.fields'

type EditEasementProps = {
	id: number
	cb?: (data: any) => void
}

const EditEasement: React.FC<EditEasementProps> = ({ id, cb }) => {
	const [{ data, fetching, error }] = useEasementPageQuery({ variables: { id } })
	const [_, updateEasement] = useUpdateEasementMutation()
	const stopRender = useRef(false) // component should no longer render markup

	const { easement } = data || {}

	return stopRender.current ? null : fetching ? (
		<Spinner />
	) : error ? (
		<Block type='err'>{error.message}</Block>
	) : (
		<Form
			legend='Edit Easement'
			initialValues={sanitizeDataForForm<EasementInput>(easement, { stripKeys: ['status'] })}
			onSubmit={async (vals: any) => {
				try {
					const data = {
						...vals,
						id,
						granteeDbaId: vals.granteeDbaId || null,
						dateEffective: vals.dateEffective?.length
							? parseDateForServer(vals.dateEffective)
							: null,
						dateCancelled: vals.dateCancelled?.length
							? parseDateForServer(vals.dateCancelled)
							: null,
						price: priceToNumber(vals.price),
					}
					let results
					if (id)
						results = await updateEasement({
							data,
						})
					stopRender.current = true
					if (isFunction(cb)) cb(results)
				} catch (err) {
					console.error(err)
				}
			}}
			validationSchema={easementSchema}
		>
			{() => (
				<>
					<EasementFields />
					<SubmitBtn />
				</>
			)}
		</Form>
	)
}
export default EditEasement
