/*
 =================================================
	Wraps formik and forwards functionality to parent
	Also adds a styled form element and optional legend
 =================================================
* */
import { Form as FormikForm, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik'
import React, { ReactNode } from 'react'
import ErrorBlock from '../../Error.block'
import Wrap from './Form.view'

// const isInitialValid = props => {
// 	if (!props.validationSchema) return null
// 	return props.validationSchema.isValidSync(props.initialValues)
// }

type FormProps<T> = {
	legend?: ReactNode
	maxWidth?: string | number
	center?: boolean
	leftBorder?: boolean
	legendSize?: 'sm' | 'med' | 'lg'
	className?: string
	noPad?: boolean
	submitErr?: any | Error | null
	onSubmit: (data: T, formikHelpers: FormikHelpers<FormikValues>) => void | Promise<any>
	initialValues: T | any
	validationSchema?: any
	children?: (renderProps: FormikProps<FormikValues>) => ReactNode
}

function Form<T>({
	legend,
	children,
	maxWidth,
	center,
	legendSize,
	leftBorder,
	noPad,
	submitErr,
	initialValues,
	validationSchema,
	onSubmit,
}: FormProps<T>) {
	const lSize =
		legendSize === 'sm'
			? '1.3em'
			: legendSize === 'med'
			? '2em'
			: legendSize === 'lg'
			? '3em'
			: null
	return (
		<Wrap
			hasLegend={!!legend}
			maxWidth={maxWidth}
			center={center}
			legendSize={lSize}
			leftBorder={leftBorder}
			noPad={noPad}
		>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(vals: FormikValues | T, opts) => onSubmit(vals as T, opts)}
			>
				{renderProps => (
					<FormikForm>
						{legend && <legend>{legend}</legend>}
						{submitErr ? <ErrorBlock error={submitErr} /> : null}
						<div className='form-contents'>{children && children(renderProps)}</div>
					</FormikForm>
				)}
			</Formik>
		</Wrap>
	)
}
export default Form
