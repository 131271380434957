import { Colors } from '@swfsoft/swf-theme/dist/lib/colors'
import replace from 'lodash/replace'
import startCase from 'lodash/startCase'
import React, { useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { useStoreState } from '../../../store'
import { parseResourceStatus, useState } from '../../../utils'
import { Icon } from '../../UI'
import { IconType } from '../../UI/Icon/fa.defaults'

type ResourceAlertBarViewProps = {
	color?: string
}
const ResourceAlertBarView = styled.section<ResourceAlertBarViewProps>`
	background: ${props => (props.color ? props.color : props.theme.colors.brown.val)};
	/* box-shadow: inset 0 -2px 5px ${props => props.theme.colors.black.tint().val}; */
	color: ${props => props.theme.colors.white.val};
	transition: all 100ms ease-in-out;
	height: 30px;
	overflow: hidden;
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		background: ${props => props.theme.colors.black.tint(30).val};
	}
	.inner-wrap {
		width: 100%;
		padding: 4px ${props => props.theme.sizes.gutter.mobile.val};
		z-index: 1;
	}
	.status {
		margin-left: auto;
		font-weight: 500;
		letter-spacing: 1px;
		align-items: center;
		.icon {
			height: 1.5em;
		}
	}
`

const getColor = (colors: Colors, status?: string) =>
	status === 'Closed'
		? colors.blue.val
		: status === 'Open'
		? colors.ok.val
		: status === 'Cancelled'
		? colors.grey.val
		: status === 'Foreclosed'
		? colors.grey.light(0).val
		: status === 'Terminated'
		? colors.slate.val
		: status === 'Conveyed' || status === 'Surface Conveyed'
		? colors.blue.val
		: status === 'Expired'
		? colors.grey.val
		: status === 'Active'
		? colors.blue.val
		: status === 'Past Expiration' || status === 'No_Parcels' || status === 'Archived'
		? colors.err.val
		: status === 'Available'
		? colors.ok.val
		: status === 'Unowned' || status === 'Surface Unowned'
		? colors.grey.light().val
		: null

type ResourceAlertBarProps = {
	className?: string
}

const ResourceAlertBar: React.FC<ResourceAlertBarProps> = ({ className }) => {
	const { status } = useStoreState(state => state.page)
	const [currentStatus, setStatus] = useState(status, 'currentStatus')
	const { colors } = useTheme()
	const { icon, statusName } = parseResourceStatus(currentStatus)
	const color = getColor(colors, statusName)

	useEffect(() => {
		setStatus(status)
	}, [status])

	return (
		<ResourceAlertBarView
			className={`resource-alert-bar${className ? ` ${className}` : ''}`}
			color={color || undefined}
			style={{ maxHeight: icon ? '30px' : '0' }}
		>
			{icon ? (
				<div className='inner-wrap flex'>
					<div className='status flex'>
						{status ? startCase(replace(status, '_', ' ')) : null}
						<Icon type={icon as IconType} padLeft />
					</div>
				</div>
			) : null}
		</ResourceAlertBarView>
	)
}
export default ResourceAlertBar
