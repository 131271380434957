import styled, { css } from 'styled-components'
import { UserRole } from '../../../gql_generated/document_types'
import { nnrMgrs } from '../../../utils'
import { Btn, Icon, Modal, useModalCtrl } from '../../UI'
import { Heading } from '../../UI/Heading'
import { PDFPreview, PDFPreviewProps } from '../../UI/PDF.preview'
import { Uploader } from '../../UI/Uploader'
import Protect from '../Protect'

export const docFileStyles = css`
	height: 100%;
	min-height: 250px;

	.no-file {
		color: ${props => props.theme.colors.grey.tint(30).val};
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.icon {
			height: 250px;
			width: auto;
		}
		.heading {
			font-size: 50px;
			line-height: 50px;
			color: inherit;
		}
	}
`

const DocFileView = styled.div`
	${docFileStyles}
`

const NoFile = ({ children }: { children: string }) => (
	<div className='no-file'>
		<Icon type='pdf' />
		<Heading>{children}</Heading>
	</div>
)

export type DocFileProps = Omit<PDFPreviewProps, 'fileId'> & {
	docId?: number
	fileId?: number
}
export const DocFile = ({ fileId, docId, ...pdfPreviewProps }: DocFileProps): JSX.Element => {
	const [[showModal, openModal, closeModal, idForDeletion]] = useModalCtrl<number>()
	return (
		<DocFileView>
			{fileId ? (
				<PDFPreview fileId={fileId} {...pdfPreviewProps} />
			) : (
				<Protect
					allowed={[...nnrMgrs, UserRole.Accountant]}
					Denied={<NoFile>No PDF</NoFile>}
					showDenied
				>
					<Btn className='no-file' disabled={!docId} onClick={() => openModal()}>
						<NoFile>Add File</NoFile>
					</Btn>
				</Protect>
			)}
			<Modal titleText='Upload File' show={showModal} closeHandler={closeModal}>
				{docId ? (
					<Uploader
						docId={docId}
						onUpload={closeModal}
						fileIdForDeletion={idForDeletion || undefined}
						fileSelectOnOpen
					/>
				) : null}
			</Modal>
		</DocFileView>
	)
}
