import { Storage } from 'aws-amplify'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useFileByIdQuery } from '../gql_generated/graphql'
import { kebabCase } from './lodash.utils'

export type UseS3Options = {
	defaultDownloadFilename?: string
}

const getExtensionFromType = (type: string) =>
	type === 'application/pdf'
		? 'pdf'
		: type === 'image/jpeg'
		? 'jpg'
		: type === 'image/png'
		? 'png'
		: 'txt'

export const useS3 = (fileId?: number, options?: UseS3Options) => {
	const { defaultDownloadFilename } = options || {}
	const [results] = useFileByIdQuery({ variables: { id: fileId as number }, pause: !fileId })
	const { name, awsFileName, awsDir, mimeType } = results?.data?.file || {}
	const [blob, setBlob] = useState<Blob | null>(null)
	const [error, setError] = useState<any>(null)
	const [loading, setLoading] = useState(false)

	const isMounted = useRef(true)

	const downloadFile = useCallback(() => {
		if (blob) {
			const newBlob = new Blob([blob], { type: blob.type || mimeType || 'application/pdf' })
			const url = URL.createObjectURL(newBlob)

			const a = document.createElement('a')
			a.href = url
			a.download = `${
				defaultDownloadFilename || kebabCase(name || '') || awsFileName || ''
			}.${getExtensionFromType(blob.type)}`
			a.click()

			URL.revokeObjectURL(url)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blob])

	const openFile = useCallback(() => {
		if (blob) {
			const newBlob = new Blob([blob], { type: blob.type || 'application/pdf' })
			const url = URL.createObjectURL(newBlob)

			const a = document.createElement('a')
			a.href = url
			a.target = '_blank'
			a.click()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blob])

	useEffect(() => {
		if (awsFileName && awsDir && mimeType && isMounted.current) {
			setLoading(true)
			Storage.get(`${awsDir}/${awsFileName}`, {
				download: true,
				contentType: mimeType || 'application/pdf',
			})
				.then(({ Body }) => {
					if (!isMounted.current) return

					if (Body) setBlob(Body as Blob)

					setLoading(false)
				})
				.catch(err => {
					if (!isMounted.current) return
					setError(err)
					setLoading(false)
				})
		}
	}, [awsFileName, awsDir, mimeType])

	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [])

	return {
		blob,
		error,
		loading: results.fetching || loading,
		downloadFile,
		openFile,
		name: results.data?.file?.name,
		description: results.data?.file?.description,
		uploadedFileName: results.data?.file?.uploadedFileName,
		fileSize: results.data?.file?.fileSize,
	}
}
