import React from 'react'
import styled, { css } from 'styled-components/macro'
import { TableHeadConfig } from '../Table.types'
import { TableHeadCell, TableHeadCellProps } from './Table.head.cell'
import { TableGlobalOps, TableGlobalOpsProps } from './Table.head.GlobalOps'

export const tableHeadRowStyles = css`
	padding: 0.5em;
	position: relative;

	&&:after {
		background: ${props => props.theme.colors.grey.light().val};
	}

	${props => props.theme.media.tablet} {
		padding: 0.5em 0.25em;
	}

	.global-ops-cell {
		max-width: 40px;
		width: 40px;
		.select-all-options {
			/* position: absolute;
			top: 0;
			left: 0;
			transform: translateY(-100%); */
		}
	}
`

const TableHeadRowView = styled.tr`
	${tableHeadRowStyles}
`

export type TableHeadRowProps = TableHeadConfig & {}
export const TableHeadRow: React.FC<TableHeadRowProps> = ({
	cols,
	globalOps,
	hasSelectRows,
	hasRowOps,
	sortConfig,
}) => {
	const tableCols: TableHeadCellProps[] = cols.map(
		({ label, hideMobile, hideTablet, sortField }) => ({
			children: label,
			hideMobile,
			hideTablet,
			sortProps:
				sortConfig && sortField
					? {
							...sortConfig,
							sortField,
					  }
					: undefined,
		})
	)

	if (globalOps) {
		// create defaults
		const {
			selectAllHandler,
			isTotalQtySelected = false,
			deselectedRowIds,
			rowsQty,
			tableOps,
			totalQty = 0,
			selectedRowIds = [],
		} = globalOps

		const globalOpProps = {
			selectAllHandler,
			isTotalQtySelected,
			deselectedRowIds,
			rowsQty,
			selectedRowIds,
			tableOps,
			totalQty,
		} as TableGlobalOpsProps

		tableCols.unshift({
			className: 'global-ops-cell',
			children: <TableGlobalOps {...globalOpProps} />,
		})
	} else if (hasSelectRows)
		tableCols.unshift({
			children: '',
		})

	if (hasRowOps)
		tableCols.push({
			children: '',
		})

	return (
		<TableHeadRowView>
			{tableCols.map((props, idx) => (
				<TableHeadCell {...props} key={idx} />
			))}
		</TableHeadRowView>
	)
}
