import React from 'react'
import styled, { css } from 'styled-components/macro'
import { relatedOrgColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Org.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { OrgParent } from '../../../gql_generated/document_types'
import { usePaginatedOrgsQuery } from '../../../gql_generated/graphql'
import { RelatedAcquisitionOrgModal } from '../Related.Modals/Acquisition.related.org.modal'

export const relatedAcquisitionOrgStyles = css``

const RelatedAcquisitionOrgsView = styled.div`
	${relatedAcquisitionOrgStyles}
`

export type RelatedAcquisitionOrgsProps = {
	acquisitionId: number
}
export const RelatedAcquisitionOrgs: React.FC<RelatedAcquisitionOrgsProps> = ({
	acquisitionId,
}) => {
	const paginationStore = usePaginationParams()

	const [results] = usePaginatedOrgsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: OrgParent.Acquisition,
						parentIds: [acquisitionId],
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.orgs

	return (
		<RelatedAcquisitionOrgsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedOrgColsConfig}
				fetchResults={results}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedAcquisitionOrgModal orgId={data.id} />}
				resourceType='org'
			/>
		</RelatedAcquisitionOrgsView>
	)
}
