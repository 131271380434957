import React from 'react'
import styled, { css } from 'styled-components/macro'
import { AcquisitionDetail } from '../../../Components/common/Acquisition/Acquisition.detail'

export const relatedDocAcquisitionModalStyles = css``

const RelatedDocAcquisitionModalView = styled.div`
	${relatedDocAcquisitionModalStyles}
`

export type RelatedDocAcquisitionModalProps = {
	acquisitionId: number
}
export const RelatedDocAcquisitionModal: React.FC<RelatedDocAcquisitionModalProps> = ({
	acquisitionId,
}) => {
	return (
		<RelatedDocAcquisitionModalView>
			<AcquisitionDetail acquisitionId={acquisitionId} />
		</RelatedDocAcquisitionModalView>
	)
}
