import React from 'react'
import styled from 'styled-components'
import { GTableRow } from './GTable'

export const GTableBodyRowView = styled.div``

export type GTableBodyRowProps = {
	row: GTableRow
}
export const GTableBodyRow: React.FC<GTableBodyRowProps> = ({ children }) => {
	return <GTableBodyRowView className='g-table-row g-table-body-row'>{children}</GTableBodyRowView>
}
