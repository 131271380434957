import React from 'react'
import styled, { css } from 'styled-components/macro'
import { OrgDetail } from '../../../Components/common/Org/Org.detail'

export const relatedEasementOrgModalStyles = css``

const RelatedEasementOrgModalView = styled.div`
	${relatedEasementOrgModalStyles}
`

export type RelatedEasementOrgModalProps = {
	orgId: number
}
export const RelatedEasementOrgModal: React.FC<RelatedEasementOrgModalProps> = ({ orgId }) => {
	return (
		<RelatedEasementOrgModalView>
			<OrgDetail orgId={orgId} />
		</RelatedEasementOrgModalView>
	)
}
