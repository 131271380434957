import styled, { css } from 'styled-components'
import { MgrsDash } from '../Dash.mgrs'

export const officerDashboardStyles = css``

const OfficerDashboardView = styled.div`
	${officerDashboardStyles}
`

export type OfficerDashboardProps = {}
export const OfficerDashboard = (): JSX.Element => {
	return (
		<OfficerDashboardView>
			<MgrsDash />
		</OfficerDashboardView>
	)
}
