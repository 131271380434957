import { RouteComponentProps, useNavigate } from '@reach/router'
import toNumber from 'lodash/toNumber'
import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import CardsSection from '../../Components/common/CardsSection'
import { DocFile } from '../../Components/common/Doc/Doc.file'
import { DocInfo } from '../../Components/common/Doc/Doc.info'
import { DocOg } from '../../Components/common/Doc/Doc.og'
import { DocRecInfo } from '../../Components/common/Doc/Doc.recInfo'
import { PageOperation } from '../../Components/common/Operations'
import Page from '../../Components/common/Resources/Resource.page'
import { OpsDocUpdate } from '../../Components/Ops/Ops.Docs/Ops.Doc.edit'
import { Alert, Async, Card, Colorize, Modal, P } from '../../Components/UI'
import { useDeleteDocMutation, useDocQuery } from '../../gql_generated/graphql'
import { usePageData, useState, useToaster } from '../../utils'
import DocRelatedResources from './Related/Doc.related'

export const docPageStyles = css`
	.file-card {
		.card-content {
			height: 100%;
		}
	}
`

const DocPageView = styled.div`
	${docPageStyles}
`

type DocPageProps = RouteComponentProps & {
	id?: string
}
const DocPage: React.FC<DocPageProps> = props => {
	const id = toNumber(props.id)
	const [results, refetch] = useDocQuery({
		variables: { id },
	})

	const { data } = results
	const { doc } = data || {}
	const { recDocId, files, isRecDoc } = doc || {}

	const [updatePageInfo] = usePageData({ pageType: 'doc' })
	useEffect(() => {
		updatePageInfo({
			pageTitle: recDocId ? `Rec Doc Num: ${recDocId}` : `Doc ID: ${id}`,
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [recDocId, id])

	const [op, setOp] = useState('', 'op')

	const operations: PageOperation[] = [
		{
			op: () => setOp('edit'),
			txt: 'Edit',
			icon: 'edit',
		},
		{
			op: () => setOp('delete'),
			txt: 'Delete',
			icon: 'x',
		},
	]

	const toast = useToaster()

	const navigate = useNavigate()
	const [_, deleteDoc] = useDeleteDocMutation()
	const deleteOp = async (shouldDelete: boolean) => {
		if (shouldDelete) {
			try {
				const res = await deleteDoc({ docId: toNumber(id) })

				if (res.error) throw res.error
				else navigate('/mgr/docs')
			} catch (err) {
				toast('Error deleting doc', { type: 'err' })
			}
		} else setOp('')
	}

	const opCb = () => {
		refetch()
		setOp('')
	}

	const file = files?.length ? files[0] : null

	return (
		<Page isLoaded={!results.fetching} operations={operations}>
			<DocPageView>
				<Async fetchResults={results}>
					<CardsSection>
						<div className='col'>
							<Card titleText='Info'>
								<DocInfo docId={id} />
							</Card>
							{isRecDoc ? (
								<Card titleText='Recorded Doc Info'>
									<DocRecInfo docId={id} />
								</Card>
							) : null}
							<Card titleText='Notes'>
								<P>{doc?.notes}</P>
							</Card>
							<Card titleText='Original (OG) data from Xogenous'>
								<DocOg docId={id} />
							</Card>
						</div>
						<div className='col'>
							<Card className='file-card'>
								<DocFile fileId={file?.id} docId={id} />
							</Card>
						</div>
					</CardsSection>

					<DocRelatedResources docId={id} />
				</Async>
			</DocPageView>

			<Modal show={!!op && op !== 'delete'} closeHandler={() => setOp('')} size='med'>
				{op === 'edit' ? <OpsDocUpdate id={id} cb={opCb} /> : null}
			</Modal>
			{op === 'delete' ? (
				<Alert
					msg='Are you sure you want to delete this doc? (Cannot be undone)'
					cb={deleteOp}
					okBtn='Cancel'
					cancelBtn={
						<Colorize color='err' asBlock>
							Delete Permanently
						</Colorize>
					}
					useCancelAsOp
				/>
			) : null}
		</Page>
	)
}
export default DocPage
