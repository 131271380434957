import React from 'react'
import { useLeaseForOgQuery } from '../../../gql_generated/graphql'
import { KeyVals } from '../../UI'

type LeaseOgProps = {
	leaseId: number
}
const LeaseOg: React.FC<LeaseOgProps> = ({ leaseId }) => {
	const [results] = useLeaseForOgQuery({ variables: { id: leaseId } })
	const lease = results.data?.lease
	const { og } = lease || {}
	const {
		lessee,
		leaseType,
		acres,
		paymentSched,
		isCurrentYearPayment,
		isTaxReimburse,
		qtr1Yn,
		qtr2Yn,
		qtr3Yn,
		qtr4Yn,
		dateRenewal,
		hasPurchaseOption,
		renewMonth,
	} = og || {}
	return lease ? (
		<KeyVals
			items={[
				['OG Lease Type: ', leaseType],
				['OG Lessee', lessee],
				['OG Acres', acres, 'acres'],
				['Has purchase option? ', hasPurchaseOption, 'boolean'],
				['Is Tax Reimbursable? ', isTaxReimburse, 'boolean'],
				['Is Current Year Payment? ', isCurrentYearPayment, 'boolean'],
				['Date of Renewal', dateRenewal],
				['Renewal Month', renewMonth],
				['Payment Schedule', paymentSched],
				['Qtr. 1 Yn', qtr1Yn],
				['Qtr. 2 Yn', qtr2Yn],
				['Qtr. 3 Yn', qtr3Yn],
				['Qtr. 4 Yn', qtr4Yn],
			]}
		/>
	) : null
}
export default LeaseOg
