import { useFormikContext } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { Spinner } from '../Loaders'
import { Btn } from '../Typography'
import { BtnProps } from '../Typography/Btn'

const B = styled(Btn)`
	position: relative;
`

type SubmitBtnProps = BtnProps

const SubmitBtn: React.FC<SubmitBtnProps> = ({
	className,
	onClick,
	children,
	disabled = false,
}) => {
	const { isSubmitting, isValid } = useFormikContext() || {}

	return (
		<B
			disabled={isSubmitting || !isValid || disabled}
			isCta
			className={`submit-btn${className ? ` ${className}` : ''}`}
			type='submit'
			title='Submit form'
			onClick={onClick}
			prevDef={false}
		>
			{isSubmitting ? <Spinner /> : children || 'Save'}
		</B>
	)
}
export default SubmitBtn
