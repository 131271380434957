import React from 'react'
import styled, { css } from 'styled-components/macro'
import { relatedLogsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Log.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { LogParent } from '../../../gql_generated/document_types'
import { usePaginatedLogsQuery } from '../../../gql_generated/graphql'
import { RelatedParcelLogModal } from '../RelatedModals/Parcel.related.log.modal'

export const relatedParcelLogsStyles = css``

const RelatedParcelLogsView = styled.div`
	${relatedParcelLogsStyles}
`

export type RelatedParcelLogsProps = {
	parcelId: number
}

export const RelatedParcelLogs: React.FC<RelatedParcelLogsProps> = ({ parcelId }) => {
	const paginationStore = usePaginationParams()

	const [fetchResults] = usePaginatedLogsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: LogParent.Parcel,
						parentIds: [parcelId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.logs
	return (
		<RelatedParcelLogsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedLogsColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedParcelLogModal logId={data.id} />}
				resourceType='log'
				suppressGoTo
			/>
		</RelatedParcelLogsView>
	)
}
