import React from 'react'
import styled, { css } from 'styled-components/macro'
import LeaseDates from '../../../Components/common/Lease/Lease.dates'
import LeaseFinancial from '../../../Components/common/Lease/Lease.financial'
import LeaseInfo from '../../../Components/common/Lease/Lease.info'
import LeaseOg from '../../../Components/common/Lease/Lease.og'
import { Async, Card, P } from '../../../Components/UI'
import { UserRole } from '../../../gql_generated/document_types'
import { useLeaseFullQuery } from '../../../gql_generated/graphql'
import CardsColumnsView from '../CardsColumns'
import Protect from '../Protect'

export const leaseDetailStyles = css`
	${props => props.theme.media.sdesk} {
		width: 1200px;
		max-width: 80vw;
	}
`

const LeaseDetailView = styled.div`
	${leaseDetailStyles}
`

export type LeaseDetailProps = {
	leaseId: number
}
export const LeaseDetail: React.FC<LeaseDetailProps> = ({ leaseId }) => {
	const [results] = useLeaseFullQuery({ variables: { id: leaseId } })
	const lease = results.data?.lease
	return (
		<LeaseDetailView>
			<Async fetchResults={results}>
				{lease ? (
					<>
						<CardsColumnsView className='cols'>
							<Card noShadow titleText='Lease Info'>
								<LeaseInfo leaseId={leaseId} />
							</Card>
							<Card noShadow titleText='Dates'>
								<LeaseDates leaseId={leaseId} />
							</Card>
							<Card noShadow titleText='Current Terms'>
								<LeaseFinancial leaseId={leaseId} />
							</Card>
						</CardsColumnsView>
						<CardsColumnsView className='cols'>
							<Card noShadow titleText='General Notes'>
								<P asRT>{lease?.notes}</P>
							</Card>
							<Protect allowed={[UserRole.Accountant]}>
								<Card noShadow titleText='Financial Notes'>
									{lease.financialNotes || ''}
								</Card>
							</Protect>
							<Card noShadow titleText='Original (OG) data from Xogenous'>
								<LeaseOg leaseId={leaseId} />
							</Card>
						</CardsColumnsView>
					</>
				) : null}
			</Async>
		</LeaseDetailView>
	)
}
