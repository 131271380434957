import React from 'react'
import styled, { css } from 'styled-components/macro'
import { LeaseDetail } from './Lease.detail'

export const genLeaseModalStyles = css``

const GenLeaseModalView = styled.div`
	${genLeaseModalStyles}
`

export type GenLeaseModalProps = {
	leaseId: number
}
export const GenLeaseModal: React.FC<GenLeaseModalProps> = ({ leaseId }) => {
	return (
		<GenLeaseModalView>
			<LeaseDetail leaseId={leaseId} />
		</GenLeaseModalView>
	)
}
