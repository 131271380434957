import React from 'react'
import styled, { css } from 'styled-components/macro'
import { docRelatedEasementsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Easement.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { EasementParent } from '../../../gql_generated/document_types'
import { usePaginatedEasementsQuery } from '../../../gql_generated/graphql'
import { RelatedDocEasementModal } from '../Related.Modals/Doc.related.easement.modal'

export const relatedDocEasementsStyles = css``

const RelatedDocEasementsView = styled.div`
	${relatedDocEasementsStyles}
`

export type RelatedDocEasementsProps = {
	docId: number
}

export const RelatedDocEasements: React.FC<RelatedDocEasementsProps> = ({ docId }) => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedEasementsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: EasementParent.Doc,
						parentIds: [docId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const easements = data?.page?.easements
	return (
		<RelatedDocEasementsView>
			<ResourcesTable
				dataset={easements}
				colsConfig={docRelatedEasementsColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={easement => <RelatedDocEasementModal easementId={easement.id} />}
				resourceType='easement'
			/>
		</RelatedDocEasementsView>
	)
}
