import isNil from 'lodash/isNil'
import startCase from 'lodash/startCase'
import React, { ReactChild } from 'react'
import styled from 'styled-components'
import { Btn, Card, Form, FormFieldWrapProps, H5, Icon, SubmitBtn } from '../Components/UI'
import { InputProps } from '../Components/UI/Form.Fields/Input'
import { useStoreActions, useStoreState } from '../store'
import useState from './useState'

const AdvancedSearchView = styled.div`
	.ctrl {
		padding: 0.5em;
		color: ${props => props.theme.colors.secondary.val};
		&:hover {
			color: ${props => props.theme.colors.secondary.sat().val};
		}
	}
	.ctrl-group {
		margin-top: 1em;
	}
	.hide-group-btn {
	}
	form {
		.form-field {
		}
		.buttons {
			align-items: center;
			margin-top: 1em;
		}
		.submit-btn {
			width: 140px;
			padding: 0.5em;
			text-transform: none;
			font-size: 1rem;
			margin: 0;
		}
	}
	.field-group {
		${props => props.theme.media.tablet} {
			min-width: 48%;
		}
		${props => props.theme.media.sdesk} {
			min-width: 30%;
		}
	}
	.advanced-search-fields {
		${props => props.theme.media.tablet} {
			display: flex;
			flex-wrap: wrap;
			grid-gap: 1em;
		}
	}
`

type AdvancedSearchHandler = (filterVals: any) => Promise<any>

export type AdvancedSearchField = {
	name: string
	label?: string
	Component?: React.FC<InputProps & FormFieldWrapProps>
	input?: ReactChild
	defaultVal?: any
	fields?: Array<Omit<AdvancedSearchField, 'fields'>>
}

type AdvancedSearchProps = UseAdvancedSearchOptions & {
	handler: AdvancedSearchHandler
	initialVals: any
}

const AdvancedSearch: React.FC<AdvancedSearchProps> = ({
	handler,
	fields,
	executeOnChange,
	initialVals,
}) => {
	const showGroup = useStoreState(state => state.view.isAdvSearchOpen)
	const setShowGroup = useStoreActions(actions => actions.view.toggleAdvSearch)

	return (
		<AdvancedSearchView>
			{showGroup ? null : (
				<Btn className='ctrl' onClick={() => setShowGroup(true)}>
					Advanced Search Options
				</Btn>
			)}
			{showGroup ? (
				<Card
					noShadow
					className='ctrl-group'
					titleText='Advanced Search Options'
					cta={
						<Btn className='hide-group-btn' onClick={() => setShowGroup(false)}>
							<Icon type='x' />
						</Btn>
					}
				>
					<Form onSubmit={handler} initialValues={initialVals}>
						{({ resetForm, submitForm, dirty }) => {
							if (executeOnChange) {
								// TODO: hook this up somehow...
							}
							return (
								<>
									<div className='advanced-search-fields'>
										{fields.map(({ name, label, Component, input, fields }) =>
											fields?.length ? (
												// We have nested fields inside of groupings
												<div className='field-group'>
													{label ? (
														<header>
															<H5>{label}</H5>
														</header>
													) : null}
													<div className='fields'>
														{fields.map(({ name, label, Component, input }) => (
															<div className='advanced-search-field' key={name}>
																{Component ? (
																	<Component name={name} label={label || startCase(name)} />
																) : (
																	input
																)}
															</div>
														))}
													</div>
												</div>
											) : (
												// we just have a list of fields
												<div className='advanced-search-field' key={name}>
													{Component ? (
														<Component name={name} label={label || startCase(name)} />
													) : (
														input
													)}
												</div>
											)
										)}
									</div>
									<div className='flex'>
										<div className='buttons flex'>
											<SubmitBtn disabled={!dirty}>Apply Filters</SubmitBtn>

											{dirty ? (
												<Btn
													onClick={() => {
														resetForm()
														submitForm()
													}}
												>
													Clear Filters
												</Btn>
											) : null}
										</div>
									</div>
								</>
							)
						}}
					</Form>
				</Card>
			) : null}
		</AdvancedSearchView>
	)
}

export type UseAdvancedSearchOptions = {
	fields: AdvancedSearchField[]
	executeOnChange?: boolean
}

const useAdvancedSearch = function <TVals>({
	fields,
	executeOnChange,
}: UseAdvancedSearchOptions): [ReactChild, TVals] {
	const initialVals: any = fields.reduce((obj, { name, defaultVal }) => {
		const val: any = { ...obj }
		val[name] = isNil(defaultVal) ? '' : defaultVal
		return val
	}, {})

	const [searchVals, setSearchVals] = useState(initialVals, 'searchVals')

	return [
		<AdvancedSearch
			fields={fields}
			executeOnChange={executeOnChange}
			handler={async vals => setSearchVals(vals)}
			initialVals={initialVals}
		/>,
		searchVals,
	]
}

export default useAdvancedSearch
