// import toLower from 'lodash/toLower'
import { Icon } from '../Icon'
import { ExternalLink } from './Link'

type MailToProps = {
	children: string
	className?: string
	withIcon?: boolean
}

const MailTo: React.FC<MailToProps> = ({ children: email, className = null, withIcon = false }) => (
	<ExternalLink
		className={`link email-link${className ? ` ${className}` : ''}`}
		// href={`mailto:${toLower(email)}`}
	>
		{withIcon ? <Icon type='email' /> : null}
		{email}
	</ExternalLink>
)
export default MailTo
