import React from 'react'
import TableRowPrint, { TablePrintRow } from './Table.row.print'

type TableBodyPrintProps = {
	children: TablePrintRow[]
}
const TableBodyPrint: React.FC<TableBodyPrintProps> = ({ children: rows }) => {
	return (
		<tbody>
			{rows.map((row, idx) => (
				<TableRowPrint row={row} key={idx} />
			))}
		</tbody>
	)
}
export default TableBodyPrint
