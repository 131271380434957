import { Link as ReachLink } from '@reach/router'
import styled, { css } from 'styled-components'
import { isExternalLink } from '../../../utils'
import { ctaStyles } from './CTA.styles'
import { BtnLinkProps } from './Typo.types'

const linkStyles = css`
	/* useGlobalStyles instead - this doesn't work */
	.icon {
		margin-right: 0.5em;
		vertical-align: middle;
	}
`

export const InternalLink = styled(ReachLink)`
	${linkStyles}
	&.cta {
		${ctaStyles}
	}
`
export const ExternalLink = styled.a`
	${linkStyles}
	&.cta {
		${ctaStyles}
	}
`

type LinkProps = React.HTMLProps<HTMLLinkElement> &
	BtnLinkProps & {
		to: string
		isBlankTab?: boolean
		activeStrict?: boolean
	}

const Link: React.FC<LinkProps> = ({
	to,
	isBlankTab,
	isCta,
	isLight,
	className,
	activeStrict = true,
	...props
}) =>
	isExternalLink(to) || isBlankTab ? (
		<ExternalLink
			href={to}
			target='_blank'
			rel='noopener noreferrer'
			className={`link external-link${className ? ` ${className}` : ''}${isCta ? ' cta' : ''}${
				isLight ? ' light' : ''
			}`}
		>
			{props.children}
		</ExternalLink>
	) : !to ? (
		<ExternalLink
			className={`link faux-link${className ? ` ${className}` : ''}${isCta ? ' cta' : ''}${
				isLight ? ' light' : ''
			}`}
		>
			{props.children}
		</ExternalLink>
	) : (
		<InternalLink
			to={to}
			target={isBlankTab ? '_blank' : ''}
			getProps={({ isCurrent, isPartiallyCurrent }) => {
				const isActive = activeStrict && isPartiallyCurrent ? isPartiallyCurrent : isCurrent
				return {
					className: `link internal-link${className ? ` ${className}` : ''}${isCta ? ' cta' : ''}${
						isLight ? ' light' : ''
					}${isActive ? ' active' : ''}`,
					...props,
				}
			}}
		/>
	)
export default Link
