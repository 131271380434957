import styled, { css } from 'styled-components'
import { useLeaseAcresByLeaseTypeWidgetQuery } from '../../../../gql_generated/graphql'
import { asAcres } from '../../../../utils'
import { Spinner } from '../../../UI'

const AcresByLeaseTypeWidgetLeaseTypeStyles = css`
	display: flex;
	align-items: center;

	.label {
		width: 100px;
	}
	.val {
		height: 40px;
		width: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: solid 3px ${props => props.theme.colors.grey.light().val};
		border-radius: 5px;
		position: relative;
	}
`

const AcresByLeaseTypeWidgetLeaseTypeView = styled.div`
	${AcresByLeaseTypeWidgetLeaseTypeStyles}
`

type AcresByLeaseTypeWidgetLeaseTypeProps = {
	className?: string
	leaseTypeName: string
}
export const AcresByLeaseTypeWidgetLeaseType = ({
	className,
	leaseTypeName,
}: AcresByLeaseTypeWidgetLeaseTypeProps): JSX.Element => {
	const [{ data, fetching }] = useLeaseAcresByLeaseTypeWidgetQuery({ variables: { leaseTypeName } })

	const acres = data?.acres || 0
	return (
		<AcresByLeaseTypeWidgetLeaseTypeView
			className={`acres-by-lease-type-lease-type${className ? ` className` : ''}`}
		>
			<span className='label'>{leaseTypeName}</span>
			<span className='val'>{fetching ? <Spinner /> : asAcres(acres, 0)}</span>
		</AcresByLeaseTypeWidgetLeaseTypeView>
	)
}
