import React from 'react'
import styled, { css } from 'styled-components/macro'
import { DocInfo } from '../../../Components/common/Doc/Doc.info'
import { DocOg } from '../../../Components/common/Doc/Doc.og'
import { DocRecInfo } from '../../../Components/common/Doc/Doc.recInfo'
import { Async, Card, P } from '../../../Components/UI'
import { useDocFullQuery } from '../../../gql_generated/graphql'

export const docDetailStyles = css``

const DocDetailView = styled.div`
	${docDetailStyles}
`

export type DocDetailProps = {
	docId: number
}
export const DocDetail: React.FC<DocDetailProps> = ({ docId }) => {
	const [results] = useDocFullQuery({ variables: { id: docId } })
	const { notes, isRecDoc } = results.data?.doc || {}
	return (
		<DocDetailView>
			<Async fetchResults={results}>
				{results.data ? (
					<>
						<Card titleText='Info' noShadow mBot>
							<DocInfo docId={docId} />
						</Card>
						{isRecDoc ? (
							<Card titleText='Recorded Doc Info' noShadow mBot>
								<DocRecInfo docId={docId} />
							</Card>
						) : null}
						<Card titleText='Notes' noShadow mBot>
							<P>{notes}</P>
						</Card>
						<Card titleText='Original (OG) data from Xogenous' noShadow>
							<DocOg docId={docId} />
						</Card>
					</>
				) : null}
			</Async>
		</DocDetailView>
	)
}
