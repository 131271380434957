import { ReactChild } from 'react'
import styled from 'styled-components'
import { Icon } from '../Icon'
import { ToastType } from './Toast'

export type ToastViewProps = {
	type?: ToastType
	children?: ReactChild
	className?: string | undefined
}

const ToastWrap = styled.div`
	align-items: center;
	padding-right: 0.5em;
	.toast-icon {
		align-items: center;
		padding-right: 0.5em;
	}
	.toast-content {
		align-items: center;
	}
	.icon {
		height: 20px;
		${props => props.theme.media.tablet} {
			height: 30px;
		}
	}
`

const Toast = ({ type, children, className }: ToastViewProps) => {
	return (
		<ToastWrap className={`toast-contents flex${className ? ` ${className}` : ''}`}>
			{type && (
				<div className='toast-icon flex'>
					<Icon type={type} />
				</div>
			)}
			<div className='toast-content flex'>{children}</div>
		</ToastWrap>
	)
}
export default Toast
