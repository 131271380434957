import styled from 'styled-components'

const EM = styled.em<{
	pad?: string
	padLeft?: string
	padRight?: string
	padBot?: string
}>`
	display: inline-block;
	line-height: 1.2em;
	padding: ${props => (props.pad ? props.pad : 0)};
	padding-left: ${props => (props.padLeft ? props.padLeft : 0)};
	padding-right: ${props => (props.padRight ? props.padRight : 0)};
	padding-bottom: ${props => (props.padBot ? props.padBot : 0)};
`

export default EM
