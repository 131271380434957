import React from 'react'
import { SaleUpdateInput, useUpdateSaleMutation } from '../../../gql_generated/graphql'
import { Btn, Form, IntegerField, PercentageField, PriceField, SubmitBtn } from '../../UI'

type OpSaleCBProps = {
	id: number
	cb: () => void
}
const OpSaleCB: React.FC<OpSaleCBProps> = ({ id, cb }) => {
	const [_, updateSale] = useUpdateSaleMutation()

	return (
		<Form
			initialValues={{
				cbNote: 0,
				cbInterest: 0,
				cbYears: 0,
			}}
			onSubmit={async (vals: any) => {
				try {
					const data = {
						...vals,
						id,
					} as SaleUpdateInput
					await updateSale({ data })
					cb()
				} catch (err) {
					console.error(err)
				}
			}}
			legend='Add Carry Back Note'
			legendSize='sm'
			leftBorder
		>
			{() => (
				<>
					<div className='form-fields'>
						<PriceField name='cbNote' label='Carryback Note' width='sm' />
						<PercentageField name='cbInterest' label='Carryback Interest' width='sm' />
						<IntegerField name='cbYears' label='Carryback Term (years)' width='sm' />
					</div>
					<div className='ok-cancel-btns flex'>
						<SubmitBtn>Save Note</SubmitBtn>
						<Btn onClick={cb}>Cancel</Btn>
					</div>
				</>
			)}
		</Form>
	)
}
export default OpSaleCB
