import React from 'react'
import styled, { css } from 'styled-components/macro'
import { useContactFullQuery } from '../../../gql_generated/graphql'
import { AddressType } from '../../../Types'
import { Address, Async, Card, P } from '../../UI'
import ContactInfo from './Contact.info'

export const contactDetailStyles = css`
	.cols {
		display: block;
		${props => props.theme.media.tablet} {
			display: flex;
			& > .col {
				flex-grow: 1;
				width: 50%;
			}
		}

		.notes {
			margin-top: 1em;
			${props => props.theme.media.tablet} {
				margin: 0;
			}
		}
	}
`

const ContactDetailView = styled.div`
	${contactDetailStyles}
`

export type ContactDetailProps = {
	contactId: number
}
export const ContactDetail: React.FC<ContactDetailProps> = ({ contactId }) => {
	const [results] = useContactFullQuery({ variables: { id: contactId } })
	const contact = results.data?.contact
	const { notes, address } = contact || {}

	return (
		<ContactDetailView>
			<Async fetchResults={results}>
				{contact ? (
					<div className='cols'>
						<section className='col'>
							<Card mBot noShadow titleText='Info'>
								<ContactInfo contactId={contactId} />
							</Card>
							<Card noShadow titleText='Address'>
								<Address address={address as AddressType} format='table' />
							</Card>
						</section>
						<section className='col notes'>
							{
								<Card noShadow titleText='Notes'>
									<P asRT>{notes}</P>
								</Card>
							}
						</section>
					</div>
				) : null}
			</Async>
		</ContactDetailView>
	)
}
