import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Tab, Tabs } from '../../../../Components/UI'
import { RelatedAgreementActiveParcels } from './Agreement.related.parcels.active'
import { RelatedAgreementAssignedParcels } from './Agreement.related.parcels.assigned'
import { RelatedAgreementTerminatedParcels } from './Agreement.related.parcels.terminated'

export const relatedAgreementParcelStyles = css``

const RelatedAgreementParcelsView = styled.div`
	${relatedAgreementParcelStyles}
`

export type RelatedAgreementParcelsProps = {
	agreementId: number
}
export const RelatedAgreementParcels: React.FC<RelatedAgreementParcelsProps> = ({
	agreementId,
}) => {
	return (
		<RelatedAgreementParcelsView>
			<Tabs navSize='sm'>
				<Tab nav='Active'>
					<RelatedAgreementActiveParcels agreementId={agreementId} />
				</Tab>
				<Tab nav='Terminated'>
					<RelatedAgreementTerminatedParcels agreementId={agreementId} />
				</Tab>
				<Tab nav='Assigned'>
					<RelatedAgreementAssignedParcels agreementId={agreementId} />
				</Tab>
			</Tabs>
		</RelatedAgreementParcelsView>
	)
}
