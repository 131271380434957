import { css } from 'styled-components/macro'

export type TableStyleProps = {
	maxWidth?: string
}
export const tableStyles = css<TableStyleProps>`
	max-width: ${props => (props.maxWidth ? props.maxWidth : 'initial')};
	width: 100%;
	overflow-x: auto;
	padding-bottom: 10px;

	.table-no-results {
	}

	&.__centered {
		margin-left: auto;
		margin-right: auto;
	}

	&.__content-overriden {
		min-height: 300px;
	}

	table {
		text-align: left;
		width: 100%;
		border-collapse: collapse;

		.check-box-cell {
			width: 25px;
		}
	}
	th,
	td {
		&:last-child {
		}
	}
	tr {
		&:after {
			content: '';
			background: ${props => props.theme.colors.grey.light(6).val};
			height: 1px;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			display: block;
			z-index: 0;
			transition: all ${props => props.theme.times.tranS};
		}

		&:nth-child(even) {
			background: ${props => props.theme.colors.secondary.light(43).val};
		}
	}
`
