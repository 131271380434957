import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { GTableBodyRow } from './GTable.body.row'
import { GTableBodyCell } from './GTable.cell.body'
import { GTableHeaderCell } from './GTable.cell.header'
import { GTableContainer } from './GTable.Container'
import { GTableHeaderRow } from './GTable.row.header'

export const GTableView = styled.div<{
	colQty: number
}>`
	.g-table-row {
		display: flex;
		/* grid-template-columns: repeat(${p => p.colQty}, 1fr); */
	}
	.g-table-header {
	}
	.g-table-body {
	}
`

export type GTableCell = {
	val: ReactNode
}

export type GTableRow = {
	id?: number
	cells: GTableCell[]
}

export type GTableCol = {
	val: ReactNode
}

export type GTableProps = {
	rows: GTableRow[]
	cols: GTableCol[]
}
const GTableComponent: React.FC<GTableProps> = ({ rows, cols }) => {
	return (
		<GTableView colQty={cols.length}>
			<header className='g-table-header'>
				<GTableHeaderRow>
					{cols.map((col, idx) => (
						<GTableHeaderCell col={col} colIdx={idx} key={idx} />
					))}
				</GTableHeaderRow>
			</header>
			<main className='g-table-body'>
				{rows.map(row => (
					<GTableBodyRow row={row} key={row.id}>
						{row.cells.map((cell, idx) => (
							<GTableBodyCell cell={cell} colIdx={idx} key={idx} />
						))}
					</GTableBodyRow>
				))}
			</main>
		</GTableView>
	)
}

export const GTable: React.FC<GTableProps> = props => (
	<GTableContainer cols={props.cols}>
		<GTableComponent {...props} />
	</GTableContainer>
)
