import { useFormikContext } from 'formik'
import React from 'react'
import styled from 'styled-components'
import {
	DocTypeField,
	H6,
	IntegerField,
	SelectStateField,
	Text,
	Textarea,
	ToggleField,
} from '../../UI'

import * as Yup from 'yup'

export const docYupSchema = Yup.object().shape({
	docType: Yup.string().required('Please enter a doc type'),
	dateEffective: Yup.date().required('Please enter an effective date'),
	recDocId: Yup.string().when('isRecDoc', {
		is: (val: boolean) => val,
		then: Yup.string().required('Please enter a rec doc number'),
		otherwise: Yup.string(),
	}),
	dateRecorded: Yup.date().when('isRecDoc', {
		is: (val: boolean) => val,
		then: Yup.date().required('Date recorded is required'),
		otherwise: Yup.date(),
	}),
})

const OpsDocFieldsView = styled.div`
	.recorded-doc-fields {
		padding: 1em 0;

		h6 {
			margin-bottom: 0.5rem;
		}

		.fields {
			padding-left: 1em;
		}
	}
	.columns {
		${props => props.theme.media.sdesk} {
			display: flex;
			& > div {
				flex-grow: 1;
				&:first-child {
					margin-right: 1em;
				}
			}
		}
	}
`

export type OpsDocFieldsProps = {
	isCreate?: boolean
}
export const OpsDocFields: React.FC<OpsDocFieldsProps> = () => {
	const { values } = useFormikContext<{
		isRecDoc: boolean
	}>()
	return (
		<OpsDocFieldsView>
			<DocTypeField name='docType' label='Doc Type' isReq />
			<Text name='dateEffective' label='Effective Date' isReq type='date' />
			<Text name='docName' label='Doc Name' />
			<ToggleField name='isRecDoc' label='Recorded Doc?' />
			{values.isRecDoc ? (
				<div className='recorded-doc-fields'>
					<H6>Recorded Doc Info</H6>
					<div className='fields'>
						<div className='columns'>
							<Text name='recDocId' label='Recorded Doc No.' isReq />
							<Text name='dateRecorded' label='Date Recorded' type='date' />
						</div>
						<div className='columns'>
							<IntegerField name='book' label='Book' />
							<IntegerField name='page' label='Page' />
						</div>
						<div className='columns'>
							<SelectStateField name='state' label='State' />
							<Text name='county' label='County' />
						</div>
					</div>
				</div>
			) : null}
			<Textarea name='notes' label='Notes' />
		</OpsDocFieldsView>
	)
}
