import React from 'react'
import { useSaleForDatesInfoQuery } from '../../../gql_generated/graphql'
import { KeyVal, KeyVals } from '../../UI'

type SaleDatesProps = {
	saleId: number
}
const SaleDates: React.FC<SaleDatesProps> = ({ saleId }) => {
	const [results] = useSaleForDatesInfoQuery({ variables: { id: saleId } })
	const sale = results.data?.sale
	const {
		dateOpened,
		dateExpected,
		dateClosed,
		dateForeclosed,
		dateCancelled,
		foreclosureReason,
		cancelReason,
	} = sale || {}
	const dateDone = dateForeclosed || dateCancelled
	const doneReason = foreclosureReason || cancelReason
	return (
		<>
			<KeyVals
				items={[
					['Date Opened', dateOpened, 'date'],
					['Expected Date to Close', dateExpected, 'date'],
					['Date Closed', dateClosed, 'date'],
					dateDone
						? [dateForeclosed ? 'Date of Foreclosure' : 'Date Cancelled', dateDone, 'date']
						: null,
				]}
			/>
			{dateDone ? (
				<KeyVal label={dateForeclosed ? 'Reason for Foreclosure' : 'Cancellation Reason'}>
					{doneReason}
				</KeyVal>
			) : null}
		</>
	)
}
export default SaleDates
