import React from 'react'
import { UseQueryResponse } from 'urql'
import Colorize from '../../Colorize'
import { Skeleton } from '../../Loaders'

export type ResourceTableCellAsyncProps = {
	showErr?: boolean
	fetchResults: UseQueryResponse[0]
}
export const ResourceTableCellAsync: React.FC<ResourceTableCellAsyncProps> = ({
	fetchResults,
	showErr,
	children,
}) => {
	const { fetching, error } = fetchResults
	return fetching ? (
		<Skeleton />
	) : error && showErr ? (
		<Colorize color='err'>Error loading data...</Colorize>
	) : (
		<>{children}</>
	)
}
