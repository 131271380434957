import React from 'react'
import styled, { css } from 'styled-components/macro'
import { OrgDetail } from '../../../Components/common/Org/Org.detail'

export const relatedAcquisitionOrgModalStyles = css``

const RelatedAcquisitionOrgModalView = styled.div`
	${relatedAcquisitionOrgModalStyles}
`

export type RelatedAcquisitionOrgModalProps = {
	orgId: number
}
export const RelatedAcquisitionOrgModal: React.FC<RelatedAcquisitionOrgModalProps> = ({
	orgId,
}) => {
	return (
		<RelatedAcquisitionOrgModalView>
			<OrgDetail orgId={orgId} />
		</RelatedAcquisitionOrgModalView>
	)
}
