import React from 'react'
import styled, { css } from 'styled-components/macro'
import { ParcelDetail } from '../../../Components/common/Parcel/Parcel.detail'

export const relatedLeaseParcelModalStyles = css``

const RelatedLeaseParcelModalView = styled.div`
	${relatedLeaseParcelModalStyles}
`

export type RelatedLeaseParcelModalProps = {
	parcelId: number
}
export const RelatedLeaseParcelModal: React.FC<RelatedLeaseParcelModalProps> = ({ parcelId }) => {
	return (
		<RelatedLeaseParcelModalView>
			<ParcelDetail parcelId={parcelId} />
		</RelatedLeaseParcelModalView>
	)
}
