import { useEffect, RefObject, useRef } from 'react'
import useState from './useState'

const useClickDetector = (ref: RefObject<Html>, isOpen: boolean, handler: () => void) => {
	useEffect(() => {
		const clickHandler = (e: any) => {
			const isInside = ref.current?.contains(e.target) || false

			if (!isInside) {
				handler()
				document.removeEventListener('click', clickHandler)
			}
		}

		if (isOpen) document.addEventListener('click', clickHandler)

		return () => {
			document.removeEventListener('click', clickHandler)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref, isOpen])
}

type Html = HTMLDivElement &
	HTMLSpanElement &
	HTMLHeadingElement &
	HTMLButtonElement &
	HTMLAnchorElement

const useDropdown = (): [boolean, (state: boolean) => void, RefObject<Html>] => {
	const [show, setShow] = useState(false, 'show')

	const ref = useRef<Html>(null)

	useClickDetector(ref, show, () => setShow(false))

	return [show, setShow, ref]
}

export default useDropdown
