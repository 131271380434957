import React from 'react'
import styled, { css } from 'styled-components/macro'
import { AddParcelsToResourceBtn } from '../../../../Components/Ops/Ops.Parcels.addToResource'
import { LeftBar, Modal, useModalCtrl } from '../../../../Components/UI'
import { AssignRelatedParcelsOp } from '../../../../Components/UI/Table.resources/Table.global.ops/AssignRelatedParcels.op'
import { RemoveRelatedParcelsOp } from '../../../../Components/UI/Table.resources/Table.global.ops/RemoveParcelsFromRelated.op'
import { TerminateRelatedParcelsOp } from '../../../../Components/UI/Table.resources/Table.global.ops/TerminateRelatedParcels.op'
import { leaseAgreementRelatedParcelColsConfig } from '../../../../Components/UI/Table.resources/Table.resource.col.configs/Parcel.col.configs'
import {
	ResourceTableGlobalOpConfig,
	ResourcesTable,
} from '../../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../../Components/UI/Table.resources/usePaginationParams'
import { EditEstateActivity } from '../../../../Components/common/Activity/Activity.edit'
import Protect from '../../../../Components/common/Protect'
import { EstateTypeEnum, PrimaryResourceType } from '../../../../Types'
import { ParcelFilter, ParcelParent } from '../../../../gql_generated/document_types'
import { useLeaseFullQuery, usePaginatedParcelsQuery } from '../../../../gql_generated/graphql'
import { nnrMgrs } from '../../../../utils'
import { RelatedLeaseParcelModal } from '../../Related.Modals/Lease.related.parcel.modal'

export const relatedLeaseActiveParcelStyles = css``

const RelatedLeaseActiveParcelsView = styled.div`
	${relatedLeaseActiveParcelStyles}
`

export type RelatedLeaseActiveParcelsProps = {
	leaseId: number
}
export const RelatedLeaseActiveParcels: React.FC<RelatedLeaseActiveParcelsProps> = ({
	leaseId,
}) => {
	const paginationStore = usePaginationParams()

	const [leaseResults] = useLeaseFullQuery({ variables: { id: leaseId } })
	const estateType = leaseResults.data?.lease?.estateType as EstateTypeEnum

	const [[showEdit, openEdit, closeEdit, parcelForEdit]] = useModalCtrl<number>()

	const [results] = usePaginatedParcelsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: ParcelParent.Lease,
						parentIds: [leaseId],
					},
				],
				filterOptions: [
					{
						filter: ParcelFilter.TerminatedActivity,
						options: {
							isNot: true,
						},
					},
					{
						filter: ParcelFilter.AssignedActivity,
						options: {
							isNot: true,
						},
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.parcels

	const parentProps = {
		parentId: leaseId,
		parentType: 'lease' as PrimaryResourceType,
	}
	const tableOps: ResourceTableGlobalOpConfig[] = [
		{
			txt: 'Terminate',
			op: props => <TerminateRelatedParcelsOp {...props} {...parentProps} />,
		},
		{
			txt: 'Assign',
			op: props => <AssignRelatedParcelsOp {...props} {...parentProps} />,
		},
		{
			txt: 'Remove',
			op: props => <RemoveRelatedParcelsOp {...props} {...parentProps} />,
		},
	]

	return (
		<RelatedLeaseActiveParcelsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={leaseAgreementRelatedParcelColsConfig(leaseId, 'lease', estateType)}
				fetchResults={results}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedLeaseParcelModal parcelId={data.id} />}
				resourceType='parcel'
				tableOps={tableOps}
				includeSearch
				limitSelectConfig={{
					maxAllowed: false,
				}}
				rowOps={[
					{
						type: 'edit',
						op: val => openEdit(val.id),
					},
				]}
			>
				<Protect allowed={nnrMgrs}>
					{estateType ? (
						<LeftBar>
							<AddParcelsToResourceBtn
								config={{
									parentId: leaseId,
									parentType: 'lease',
									estateType,
									useSingleEstateType: true,
									showConveyedToggle: true,
								}}
							/>
						</LeftBar>
					) : null}
				</Protect>
			</ResourcesTable>
			<Modal show={showEdit} closeHandler={closeEdit}>
				{parcelForEdit ? (
					<EditEstateActivity
						parcelId={parcelForEdit}
						parentId={leaseId}
						parentType='lease'
						cb={closeEdit}
					/>
				) : null}
			</Modal>
		</RelatedLeaseActiveParcelsView>
	)
}
