import React, { ReactNode } from 'react'
import styled from 'styled-components'
import ProgressBar from './ProgressBar'
import Spinner from './Spinner'

const LoaderWrap = styled.div`
	min-height: 20vh;
`

// const progressCSS = css`
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// `

type LoaderHOCProps = {
	isDone?: boolean
	Loader?: ReactNode
	useProgressBar?: boolean
	children?: ReactNode
}

const LoaderHOC = ({ isDone, Loader, useProgressBar, children }: LoaderHOCProps): JSX.Element => {
	return (
		<>
			{useProgressBar && <ProgressBar isDone={isDone} className='pageload-progress-bar' />}
			{isDone
				? children
				: !useProgressBar && (
						<LoaderWrap className='loader-wrap'>{Loader ? Loader : <Spinner />}</LoaderWrap>
				  )}
		</>
	)
}
export default React.memo(LoaderHOC)
