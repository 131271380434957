import styled, { css } from 'styled-components'
import { Heading } from '../../../../Components/UI/Heading'
import { relatedParcelsColsConfig } from '../../../../Components/UI/Table.resources/Table.resource.col.configs/Parcel.col.configs'
import { ResourcesTable } from '../../../../Components/UI/Table.resources/Table.resources'
import AgreementInfo from '../../../../Components/common/Agreement/Agreement.info'
import LeaseInfo from '../../../../Components/common/Lease/Lease.info'
import { GenParcelModal } from '../../../../Components/common/Parcel/Parcel.gen.modal'
import SaleInfo from '../../../../Components/common/Sale/Sale.info'
import {
	useParcelsForSaleRelatedActivityAgreementsModalQuery,
	useParcelsForSaleRelatedActivityLeasesModalQuery,
} from '../../../../gql_generated/graphql'

const RelatedSaleActivityModalStyles = css`
	${props => props.theme.media.ldesk} {
		display: flex;
		align-items: stretch;
		gap: 1rem;
		& > main {
			flex-grow: 1;
		}
	}

	& > aside {
		display: flex;
		gap: 1rem;
		margin-bottom: 1rem;

		${props => props.theme.media.ldesk} {
			flex-direction: column;
			margin: 0;
		}

		& > * {
			flex-grow: 1;
			width: 50%;
			padding: 1rem;
			border: solid 1px ${props => props.theme.colors.grey.light().val};
			border-radius: 10px;

			${props => props.theme.media.ldesk} {
				width: 100%;
			}
		}
	}

	& > main {
		border-radius: 10px;
		border: 1px solid ${props => props.theme.colors.grey.light().val};
		padding: 1rem;
	}
`

const RelatedSaleActivityModalView = styled.div`
	${RelatedSaleActivityModalStyles}
`

type RelatedSaleActivityModalProps = {
	className?: string
	leaseId?: number
	agreementId?: number
	saleId: number
}
export const RelatedSaleActivityModal = ({
	className,
	leaseId,
	agreementId,
	saleId,
}: RelatedSaleActivityModalProps): JSX.Element => {
	const [leaseParcelResults] = useParcelsForSaleRelatedActivityLeasesModalQuery({
		variables: { leaseId: leaseId as number, saleId },
		pause: !leaseId,
	})

	const [agreeementParcelResults] = useParcelsForSaleRelatedActivityAgreementsModalQuery({
		variables: { agreementId: agreementId as number, saleId },
		pause: !agreementId,
	})
	const parcels = leaseParcelResults.data?.parcels || agreeementParcelResults.data?.parcels

	return (
		<RelatedSaleActivityModalView
			className={`related-sale-activity-modal${className ? ` className` : ''}`}
		>
			<aside>
				<div className='sale'>
					<Heading>Sale</Heading>
					<SaleInfo saleId={saleId} />
				</div>
				{leaseId ? (
					<div className='lease'>
						<Heading>Lease</Heading>
						<LeaseInfo leaseId={leaseId} />
					</div>
				) : agreementId ? (
					<div className='agreement'>
						<Heading>Agreement</Heading>
						<AgreementInfo agreementId={agreementId} />
					</div>
				) : null}
			</aside>
			<main>
				<Heading>Parcels on Sale & Lease</Heading>
				<ResourcesTable
					dataset={parcels}
					fetchResults={leaseId ? leaseParcelResults : agreeementParcelResults}
					resourceType='parcel'
					colsConfig={relatedParcelsColsConfig}
					displayOptions={{
						hideTotalQty: true,
					}}
					modalRenderFunc={parcel => <GenParcelModal parcelId={parcel.id} />}
				/>
			</main>
		</RelatedSaleActivityModalView>
	)
}
