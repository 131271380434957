import React from 'react'
import styled, { css } from 'styled-components/macro'
import { LogDetail } from '../../../Components/common/Log/Log.detail'

export const relatedAgreementLogModalStyles = css``

const RelatedAgreementLogModalView = styled.div`
	${relatedAgreementLogModalStyles}
`

export type RelatedAgreementLogModalProps = {
	logId: number
}
export const RelatedAgreementLogModal: React.FC<RelatedAgreementLogModalProps> = ({ logId }) => {
	return (
		<RelatedAgreementLogModalView>
			<LogDetail logId={logId} />
		</RelatedAgreementLogModalView>
	)
}
