import { Redirect, RouteComponentProps } from '@reach/router'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { me } from '../../../api'
import { Board, EmailField, Form, SubmitBtn } from '../../../Components/UI'
import { useStoreState } from '../../../store'
import { parseQueryParams, useState } from '../../../utils'
import { loginStyles } from '../Login/Login'

const ForgotPassView = styled.div`
	${loginStyles}
`

const schema = Yup.object().shape({
	email: Yup.string().required('Please enter your email'),
})

type ForgotPassFormVals = {
	email: string
}

const ForgotPass: React.FC<RouteComponentProps> = ({ location }) => {
	const { email } = parseQueryParams(location?.search || '') || {}

	const { isAuthed, dashPath } = useStoreState(state => state.user)
	const [redirect, setRedirect] = useState<string | null>(null, 'redirect')

	const submitHandler = async ({ email }: ForgotPassFormVals) => {
		try {
			console.log('reseting pass with email: ', email)
			await me.forgotPass(email)

			setRedirect(`/reset-pass?u=${email}`)
		} catch (err) {
			console.dir(err)
		}
	}

	return isAuthed ? (
		<Redirect to={dashPath || '/mgr/dash'} noThrow />
	) : redirect ? (
		<Redirect to={redirect} noThrow />
	) : (
		<ForgotPassView>
			<Board titleText='Forgotten Password'>
				<Form
					initialValues={{
						email: email ? decodeURIComponent(email) : '',
					}}
					onSubmit={submitHandler}
					validationSchema={schema}
				>
					{() => (
						<>
							<EmailField name='email' label='Enter your email address' />
							<SubmitBtn isInverted>Reset Password</SubmitBtn>
						</>
					)}
				</Form>
			</Board>
		</ForgotPassView>
	)
}
export default ForgotPass
