import isFunction from 'lodash/isFunction'
import React, { useRef } from 'react'
import {
	AcquisitionUpdateInput,
	useAcquisitionFullQuery,
	useUpdateAcquisitionMutation,
} from '../../../gql_generated/graphql'
import { parseDateForServer, priceToNumber, sanitizeDataForForm } from '../../../utils'
import { Block, Form, Spinner, SubmitBtn } from '../../UI'
import AcquisitionFields, { acquisitionFieldsSchema } from './Ops.Acquisition.fields'

type EditAcquisitionProps = {
	id: number
	cb?: (data: any) => void
}
const EditAcquisition: React.FC<EditAcquisitionProps> = ({ id, cb }) => {
	const [{ data, fetching, error }] = useAcquisitionFullQuery({
		variables: { id },
	})
	const [_, updateAcquisition] = useUpdateAcquisitionMutation()
	const stopRender = useRef(false) // component should no longer render markup

	const { acquisition } = data || {}

	return stopRender.current ? null : fetching ? (
		<Spinner />
	) : error ? (
		<Block type='err'>{error.message}</Block>
	) : (
		<Form
			legend='Edit Acquisition'
			initialValues={{
				brokerRate: 0,
				...sanitizeDataForForm<AcquisitionUpdateInput>(acquisition, {
					stripKeys: [
						'status',
						'brokerDba',
						'titleCompanyDba',
						'grantorDba',
						'grantor',
						'dateCancelled',
						'dateClosed',
						'dateForeclosed',
						'foreclosureReason',
						'cancelReason',
					],
				}),
			}}
			onSubmit={async (vals: any) => {
				try {
					const data = {
						...vals,
						id,
						brokerId: vals.brokerId ? vals.brokerId : null,
						brokerDbaId: vals.brokerDbaId ? vals.brokerDbaId : null,
						brokerRate: vals.brokerRate || 0,
						titleId: vals.titleId ? vals.titleId : null,
						titleDbaId: vals.titleDbaId ? vals.titleDbaId : null,
						granteeId: vals.granteeId ? vals.granteeId : null,
						grantorId: vals.granteeId ? vals.grantorId : null,
						grantorDbaId: vals.grantorDbaId ? vals.grantorDbaId : null,
						price: priceToNumber(vals.price),
						dateOpened: vals.dateOpened?.length ? parseDateForServer(vals.dateOpened) : null,
						dateExpected: vals.dateExpected?.length ? parseDateForServer(vals.dateExpected) : null,
					}
					let results
					if (id) results = await updateAcquisition({ data })
					stopRender.current = true
					if (isFunction(cb)) cb(results)
				} catch (err) {
					console.error(err)
				}
			}}
			validationSchema={acquisitionFieldsSchema}
		>
			{() => (
				<>
					<AcquisitionFields />
					<SubmitBtn>Save</SubmitBtn>
				</>
			)}
		</Form>
	)
}
export default EditAcquisition
