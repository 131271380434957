import React from 'react'
import styled, { css } from 'styled-components/macro'
import { saleColsForParcelSales } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Sale.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { SaleParent } from '../../../gql_generated/document_types'
import { usePaginatedSalesQuery } from '../../../gql_generated/graphql'
import { RelatedParcelSaleModal } from '../RelatedModals/Parcel.related.sale.modal'

export const relatedParcelSalesStyles = css``

const RelatedParcelSalesView = styled.div`
	${relatedParcelSalesStyles}
`

export type RelatedParcelSalesProps = {
	parcelId: number
}

export const RelatedParcelSales: React.FC<RelatedParcelSalesProps> = ({ parcelId }) => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedSalesQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: SaleParent.Parcel,
						parentIds: [parcelId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const sales = data?.page?.sales
	return (
		<RelatedParcelSalesView>
			<ResourcesTable
				dataset={sales}
				colsConfig={saleColsForParcelSales(parcelId)}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={sale => <RelatedParcelSaleModal saleId={sale.id} />}
				resourceType='sale'
			/>
		</RelatedParcelSalesView>
	)
}
