import isFunction from 'lodash/isFunction'
import React from 'react'
import styled from 'styled-components'
import { Icon } from '../Icon'
import { BlockProps } from './Block'

const Wrap = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	.bg {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 0;
		background: ${props => props.theme.colors.white.tint().val};
	}
	.close-btn {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		color: ${props => props.theme.colors.grey.val};
		border: none;
		outline: none;
		z-index: 11;
		&:focus {
			outline: none;
		}
		&:hover {
			color: ${props => props.theme.colors.grey.light().val};
		}
		.icon {
			height: 20px;
			${props => props.theme.media.tablet} {
				height: 30px;
			}
		}
	}
	.block-content {
	}
`

export type BlockOverlayProps = BlockProps & {
	close?: () => void
	bgClose?: boolean
}

const BlockOverlay: React.FC<BlockOverlayProps> = ({ close, bgClose = true, children }) => {
	const closeHandler = () => (isFunction(close) ? close() : null)

	const bgProps: any = {}

	if (close && bgClose) bgProps.onClick = closeHandler

	return children ? (
		<Wrap>
			<div className='bg' aria-hidden={true} {...bgProps} />
			{!!close ? (
				<button className='close-btn' onClick={() => closeHandler()}>
					<Icon type='x' />
				</button>
			) : null}
			{children}
		</Wrap>
	) : null
}
export default React.memo(BlockOverlay)
