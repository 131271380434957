import React from 'react'
import styled, { css } from 'styled-components/macro'
import { relatedLogsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Log.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { LogParent } from '../../../gql_generated/document_types'
import { usePaginatedLogsQuery } from '../../../gql_generated/graphql'
import { RelatedSaleLogModal } from '../Related.Modals/Sale.related.log.modal'

export const relatedSaleLogsStyles = css``

const RelatedSaleLogsView = styled.div`
	${relatedSaleLogsStyles}
`

export type RelatedSaleLogsProps = {
	saleId: number
}

export const RelatedSaleLogs: React.FC<RelatedSaleLogsProps> = ({ saleId }) => {
	const paginationStore = usePaginationParams()

	const [fetchResults] = usePaginatedLogsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: LogParent.Sale,
						parentIds: [saleId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.logs
	return (
		<RelatedSaleLogsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedLogsColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedSaleLogModal logId={data.id} />}
				resourceType='log'
				suppressGoTo
			/>
		</RelatedSaleLogsView>
	)
}
