import React from 'react'
import { toast, ToastOptions } from 'react-toastify'
import { parseType, ToastOpts } from './Toast'

import ToastView from './Toast.view'

const useToaster = (settings?: ToastOptions) => {
	return (msg: React.ReactChild, options?: ToastOpts) => {
		const { type, ...opts } = options || {}
		toast(<ToastView type={type}>{msg}</ToastView>, {
			type: parseType(type),
			...(settings || {}),
			...opts,
		})
	}
}

export default useToaster
