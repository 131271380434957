import React from 'react'
import styled from 'styled-components'
import { AccountantDashboard } from '../../Components/Dashboard/Dash.Accountant'
import { LandmanDashboard } from '../../Components/Dashboard/Dash.Landman'
import { OfficerDashboard } from '../../Components/Dashboard/Dash.Officer/Dash.Officer'
import { H1 } from '../../Components/UI'
import { UserRole } from '../../gql_generated/document_types'
import { useStoreState } from '../../store'
import { usePageData } from '../../utils'

const View = styled.div`
	h1 {
		padding: 1rem;
		background: ${props => props.theme.colors.secondary.val};
		color: ${props => props.theme.colors.white.val};
	}
	.menu {
		height: 100vh;

		${props => props.theme.media.sdesk} {
			display: none;
		}

		p {
			padding: 1em;
		}

		nav {
			.link {
				color: ${props => props.theme.colors.btn.val};
				padding: 1em 2em;
				&:hover {
					background: ${props => props.theme.colors.black.tint(5).val};
					color: ${props => props.theme.colors.aux3.val};
				}
			}
		}
	}
`

const Dashboard: React.FC<any> = () => {
	const { me, role } = useStoreState(state => state.user)

	const { firstName } = me || {}

	usePageData({
		pageType: 'dash',
		pageTitle: `${firstName}'s Dash`,
		icon: 'dash',
	})
	return (
		<View>
			<H1>Hello {firstName}.</H1>
			{role === UserRole.Landman ? (
				<LandmanDashboard />
			) : role === UserRole.Officer ? (
				<OfficerDashboard />
			) : role === UserRole.Accountant ? (
				<AccountantDashboard />
			) : role === UserRole.Shareholder ? (
				<></>
			) : role === UserRole.SysAdmin ? (
				<LandmanDashboard />
			) : (
				<></>
			)}
		</View>
	)
}
export default Dashboard
