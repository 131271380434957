import { UpdateResolver } from '@urql/exchange-graphcache'

/**
 *  INVALIDATE PAGINATED RESOURCES
 * */
type InvalidationOptions = {
	debug?: boolean
}

type InvalidateQueryByFieldNameParams = InvalidationOptions & {
	fieldName: string
}
export const invalidateQueryByFieldName =
	(params?: InvalidateQueryByFieldNameParams): UpdateResolver =>
	(results, args, cache, info) => {
		const { debug, fieldName } = params || {}
		try {
			debug && console.groupCollapsed(`INVALIDATE ${fieldName}`)
			debug && console.log('Results: ', results)
			debug && console.log('Args: ', args)
			debug && console.log('Info: ', info)

			let allPaginatedQueries = cache
				.inspectFields('Query')
				?.filter(query => query.fieldName === fieldName)

			debug && console.log('All paginated queries found: ', allPaginatedQueries)

			debug && console.groupEnd()

			// If we have a lot of queries we should slim it down to just the ones that are most likely to need updating
			if (allPaginatedQueries?.length > 3)
				allPaginatedQueries = allPaginatedQueries.filter(query => {
					const { limit, offset } = query.arguments || {}

					if (limit) return offset === 0 && limit < 20
					else return true
				})

			allPaginatedQueries?.forEach(query =>
				cache.invalidate('Query', query.fieldName, query.arguments)
			)
		} catch (err) {
			console.log(`Error invalidating batch queries by field name: ${fieldName} | error: `, err)
		}
	}

/**
 *  INVALIDATE QUERIES BY FIELD NAMES
 * */
type InvalidateQueriesByFieldNamesParams = InvalidationOptions &
	Omit<InvalidateQueryByFieldNameParams, 'fieldName'> & {
		fieldNames: string[]
	}
export const invalidateQueriesByFieldNames =
	(params: InvalidateQueriesByFieldNamesParams): UpdateResolver =>
	(...args) => {
		try {
			const { fieldNames, ...options } = params
			fieldNames.forEach(fieldName => {
				invalidateQueryByFieldName({ fieldName, ...options })(...args)
			})
		} catch (err) {
			console.error('Error invalidating on estateactivity muts: ', err)
		}
	}

/**
 *  INVALIDATE PARCEL RELATED RESOURCE
 * */
export const invalidateParcelRelatedResource =
	(options?: InvalidationOptions): UpdateResolver =>
	(_, args, cache, __) => {
		try {
			const { debug } = options || {}

			debug && console.log('args: ', args)
			cache.invalidate('Query', args.parentType as string, { id: args.parentId })
		} catch (err) {
			console.error('Error invalidating parcel related resource: ', err)
		}
	}

/**
 *  INVALIDATE ON ESTATE ACTIVITY MUTS
 * */
export const invalidateOnEstateActivityMuts =
	(options?: InvalidationOptions): UpdateResolver =>
	(...args) => {
		try {
			invalidateQueryByFieldName({ ...options, fieldName: 'paginatedParcels' })(...args)
			invalidateParcelRelatedResource(options)(...args)
		} catch (err) {
			console.error('Error invalidating on estateactivity muts: ', err)
		}
	}

/**
 *  INVALIDATE ON ROYALTY MUTS
 * */
export const invalidateOnRoyaltyMuts =
	(options?: InvalidationOptions): UpdateResolver =>
	(...args) => {
		try {
			invalidateQueryByFieldName({
				...(options || {}),
				fieldName: 'paginatedRoyalties',
			})(...args)
		} catch (error) {
			console.error('Error invalidating on royalty muts: ', error)
		}
	}
