import React from 'react'
import styled from 'styled-components'
import { GTableCol } from './GTable'
import { GTableCellContainer, GTableCellContainerProps } from './GTable.cell.Container'

export const GTableHeaderCellView = styled.div``

export type GTableHeaderCellProps = GTableCellContainerProps & {
	className?: string
	col: GTableCol
}
export const GTableHeaderCell: React.FC<GTableHeaderCellProps> = ({ className, col, ...props }) => {
	return (
		<GTableCellContainer {...props}>
			<GTableHeaderCellView className={`g-table-header-cell${className ? ` ${className}` : ''}`}>
				{col.val}
			</GTableHeaderCellView>
		</GTableCellContainer>
	)
}
