import React from 'react'
import styled, { css } from 'styled-components/macro'
import { RoyaltyDetail } from './Royalty.detail'
import RoyaltyParcels from './Royalty.parcels'

export const genRoyaltyModalStyles = css``

const GenRoyaltyModalView = styled.div`
	${genRoyaltyModalStyles}
`

export type GenRoyaltyModalProps = {
	royaltyId: number
}
export const GenRoyaltyModal: React.FC<GenRoyaltyModalProps> = ({ royaltyId }) => {
	return (
		<GenRoyaltyModalView>
			<RoyaltyDetail royaltyId={royaltyId} showGoTo />
			<RoyaltyParcels royaltyId={royaltyId} />
		</GenRoyaltyModalView>
	)
}
