import React, { useCallback } from 'react'
import styled from 'styled-components'
import { LeaseTerms } from '../../../gql_generated/document_types'
import {
	useLeaseFullQuery,
	useLeaseTermByIdQuery,
	useUpdateLeaseTermMutation,
} from '../../../gql_generated/graphql'
import { defaultLeaseTermVals, parseDateForServer, sanitizeDataForForm } from '../../../utils'
import { Async, Card, Form, P, SubmitBtn, toastit, UL } from '../../UI'
import { Heading } from '../../UI/Heading'
import { LeaseTermsProps } from './Ops.Lease.terms'
import LeaseTermFields, { leaseTermsYupSchema } from './Ops.Lease.terms.fields'

const View = styled.div``

type UpdateLeaseTermProps = LeaseTermsProps & {
	termId?: number
}

const UpdateLeaseTerms: React.FC<UpdateLeaseTermProps> = ({ leaseId, termId, cb }) => {
	const [leaseResults] = useLeaseFullQuery({ variables: { id: leaseId } })

	const leaseTermId = termId || leaseResults.data?.lease?.currentTerm?.id

	const [termResults] = useLeaseTermByIdQuery({
		variables: { id: leaseTermId as number },
		pause: !leaseTermId,
	})

	const lease = leaseResults.data?.lease
	const term = termResults.data?.term

	const initialVals = sanitizeDataForForm(
		{
			...defaultLeaseTermVals,
			...(term || {}),
			// rate: rate || 0,
		},
		{ stripKeys: ['schedForDisplay'] }
	) as Pick<
		LeaseTerms,
		| 'id'
		| 'dateFrom'
		| 'dateTo'
		| 'hasContClause'
		| 'hasPurchaseOption'
		| 'hasTaxReimbursements'
		| 'rate'
		| 'schedAmount'
		| 'schedInterval'
		| 'termNotes'
		| 'leaseId'
	>

	const [{ error }, updateLeaseTerm] = useUpdateLeaseTermMutation()

	const submitHandler = useCallback(
		async ({ termNotes, ...vals }: typeof initialVals) => {
			try {
				const data = { ...vals } as any
				data.leaseId = lease?.id as number

				data.dateFrom = parseDateForServer(vals.dateFrom)
				data.dateTo = parseDateForServer(vals.dateTo)
				data.termNotes = termNotes || ''

				const res: any = await updateLeaseTerm({ data })

				if (!res.error) {
					toastit.ok('Saved!')
					cb && cb()
				}
			} catch (err) {}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[lease]
	)

	return (
		<View>
			<Async fetchResults={[leaseResults, termResults]}>
				{lease && term ? (
					<Form
						legend='Update existing terms'
						submitErr={error}
						initialValues={initialVals}
						onSubmit={submitHandler}
						validationSchema={leaseTermsYupSchema}
					>
						{() => (
							<>
								<Card noShadow mBot>
									<Heading mBot>WARNING</Heading>
									<P>Updating existing terms should be reserved for:</P>
									<UL>
										<li>Fixing incorrectly entered terms data</li>
										<li>Correcting typos</li>
									</UL>
								</Card>
								<LeaseTermFields isTermNotesRequired />
								<SubmitBtn />
							</>
						)}
					</Form>
				) : null}
			</Async>
		</View>
	)
}
export default UpdateLeaseTerms
