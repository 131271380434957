import { Redirect, RouteComponentProps } from '@reach/router'
import React from 'react'
import { GenAgreementModal } from '../../Components/common/Agreement/Agreement.gen.modal'
import CardsSection from '../../Components/common/CardsSection'
import { PageOperation } from '../../Components/common/Operations'
import Page from '../../Components/common/Resources/Resource.page'
import CreateAgreement from '../../Components/Ops/Ops.Agreements/Ops.Agreement.create'
import { Card, Modal, Text, ToggleField, useModalCtrl } from '../../Components/UI'
import { CsvDownload } from '../../Components/UI/CSV.download'
import { agreementsPageColsConfig } from '../../Components/UI/Table.resources/Table.resource.col.configs/Agreement.col.configs'
import { ResourcesTable } from '../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../Components/UI/Table.resources/usePaginationParams'
import { AgreementFilter, AgreementPaginationInputs } from '../../gql_generated/document_types'
import {
	usePaginatedAgreementsCsvMutation,
	usePaginatedAgreementsQuery,
} from '../../gql_generated/graphql'
import { useAdvancedSearch, usePageData, useState } from '../../utils'

const Agreements: React.FC<RouteComponentProps> = () => {
	const [advancedSearch, { showInactive, agreementIdStr, orgStr }] = useAdvancedSearch<{
		showInactive: boolean
		agreementIdStr: string
		orgStr: string
	}>({
		fields: [
			{
				label: 'By Agreement ID',
				name: 'agreementIdStr',
				Component: Text,
			},
			{
				label: 'By Org',
				name: 'orgStr',
				Component: Text,
			},
			{
				name: 'showInactive',
				defaultVal: false,
				input: <ToggleField name='showInactive' label='Show Inactive' isSimple />,
			},
		],
		executeOnChange: true,
	})
	usePageData({ pageType: 'agreements', pageTitle: 'Agreements' })

	const [Op, setOp] = useState<string | null>(null, 'Op')
	const [redirect, setRedirect] = useState<string | null>(null, 'redirect')

	const paginationStore = usePaginationParams()
	const params = {
		...paginationStore.params,
		filterOptions: [
			{
				filter: AgreementFilter.AgreementId,
				vals: [agreementIdStr],
			},
			{
				filter: AgreementFilter.OrgName,
				vals: [orgStr],
			},
			{
				filter: AgreementFilter.Statuses,
				vals: showInactive ? ['Terminated', 'Cancelled'] : ['Active'],
			},
		],
	} as AgreementPaginationInputs
	const [fetchResults] = usePaginatedAgreementsQuery({
		variables: {
			params,
		},
	})
	const { data } = fetchResults

	const agreements = data?.page?.agreements

	const operations: PageOperation[] = [
		{
			txt: 'New Agreement',
			icon: 'agreement',
			op: () => setOp('create'),
		},
	]

	const [[showCsv, openCsv, closeCsv]] = useModalCtrl()
	const [csvMutResults, csvMut] = usePaginatedAgreementsCsvMutation()

	return redirect ? (
		<Redirect to={redirect} noThrow />
	) : (
		<Page isLoaded operations={operations}>
			<CardsSection>
				<Card>
					<ResourcesTable
						dataset={agreements}
						colsConfig={agreementsPageColsConfig}
						fetchResults={fetchResults}
						paginationStore={paginationStore}
						includeSearch
						modalRenderFunc={data => <GenAgreementModal agreementId={data.id} />}
						resourceType='agreement'
						advancedSearch={advancedSearch}
						miscOps={[
							{
								icon: 'download',
								title: 'CSV',
								op: openCsv,
							},
						]}
					/>
				</Card>
			</CardsSection>
			<Modal show={!!Op} closeHandler={() => setOp(null)} titleText='Create new lease'>
				{Op === 'create' ? (
					<CreateAgreement
						cb={(agreement: any) => {
							const id = agreement?.data?.createAgreement.id
							if (id) setRedirect(`/mgr/agreements/${id}`)
							else {
								setOp(null)
							}
						}}
					/>
				) : null}
			</Modal>

			<Modal show={showCsv} closeHandler={closeCsv}>
				<CsvDownload
					fetchData={() =>
						csvMut({
							params,
						})
					}
					fetchState={csvMutResults}
					csvData={csvMutResults.data?.csv}
					onAfterDownload={closeCsv}
					options={{
						filename: 'agreements',
					}}
				/>
			</Modal>
		</Page>
	)
}
export default Agreements
