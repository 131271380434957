import {
	useAgreementForAcresColQuery,
	useAgreementForOrgColQuery,
} from '../../../../gql_generated/graphql'
import { asAcres } from '../../../../utils'
import { sumBy } from '../../../../utils/lodash.utils'
import { TableBodyCellComponent } from '../../Table/Table.types'
import { ColConfig } from './Col.config.types'
import { ResourceTableCellAsync } from './Table.resource.cell.Async'

const Acres: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useAgreementForAcresColQuery({
		variables: { id },
	})
	const { activity } = fetchResults.data?.agreement || {}

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{asAcres(
				sumBy(activity, ({ acres, estate, assigneeId, isTerminated }) =>
					assigneeId || isTerminated ? 0 : acres || estate.acres
				)
			)}
		</ResourceTableCellAsync>
	)
}

/**
 *  Acres for parcel related leases
 * */
const genAcresFromParcelComponent =
	(parentId: number): TableBodyCellComponent =>
	({ id }) => {
		const [results] = useAgreementForAcresColQuery({ variables: { id } })
		const { activity } = results.data?.agreement || {}

		return (
			<ResourceTableCellAsync fetchResults={results}>
				{asAcres(
					sumBy(activity, ({ acres, estate, assigneeId, isTerminated, parcelId }) =>
						assigneeId || isTerminated || parcelId !== parentId ? 0 : acres || estate.acres
					)
				)}
			</ResourceTableCellAsync>
		)
	}

const Org: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useAgreementForOrgColQuery({
		variables: { id },
	})
	const { org, og } = fetchResults.data?.agreement || {}

	const ogOrg = og?.org && `*${og.org}`

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{org?.name || ogOrg || ''}
		</ResourceTableCellAsync>
	)
}

export const agreementDateEffectiveColConfig: ColConfig = {
	field: 'dateEffective',
	format: 'date',
	label: 'Effective',
	sortField: 'dateEffective',
}

export const agreementAgreementIdColConfig: ColConfig = {
	field: 'agreementId',
	label: 'ID',
	sortField: 'agreementId',
}

export const agreementOrgNameColConfig: ColConfig = {
	label: 'Org',
	sortField: 'org.name',
	Component: Org,
}

export const agreementTypeColConfig: ColConfig = {
	field: 'agreementType',
	label: 'Type',
	hideMobile: true,
}

export const agreementEstateTypeIconColConfig: ColConfig = {
	field: 'estateType',
	label: 'Estate Type',
	format: 'icon',
	hideMobile: true,
}

export const agreementAcresColConfig: ColConfig = {
	label: 'Acres',
	format: 'acres',
	hideMobile: true,
	Component: Acres,
}

export const agreementAcresForParcelColConfig = (parcelId: number): ColConfig => ({
	label: 'Acres',
	hideMobile: true,
	Component: genAcresFromParcelComponent(parcelId),
})

export const agreementStatusColConfig: ColConfig = {
	field: 'status',
	format: 'status',
	label: 'Status',
	hideMobile: true,
}

export const agreementsPageColsConfig: ColConfig[] = [
	agreementDateEffectiveColConfig,
	agreementAgreementIdColConfig,
	agreementOrgNameColConfig,
	agreementTypeColConfig,
	agreementAcresColConfig,
	agreementStatusColConfig,
]

export const parcelRelatedAgreementsColsConfig = (parcelId: number): ColConfig[] => [
	agreementDateEffectiveColConfig,
	agreementAgreementIdColConfig,
	agreementOrgNameColConfig,
	agreementTypeColConfig,
	agreementAcresForParcelColConfig(parcelId),
	agreementStatusColConfig,
]

export const docRelatedAgreementsColsConfig: ColConfig[] = [
	agreementDateEffectiveColConfig,
	agreementAgreementIdColConfig,
	agreementOrgNameColConfig,
	agreementTypeColConfig,
	agreementAcresColConfig,
	agreementStatusColConfig,
]

export const orgRelatedAgreementsColsConfig: ColConfig[] = [
	agreementDateEffectiveColConfig,
	agreementAgreementIdColConfig,
	agreementOrgNameColConfig,
	agreementTypeColConfig,
	agreementAcresColConfig,
	agreementStatusColConfig,
]

export const researchRelatedAgreementsColsConfig: ColConfig[] = [
	agreementDateEffectiveColConfig,
	agreementAgreementIdColConfig,
	agreementOrgNameColConfig,
	agreementTypeColConfig,
	agreementAcresColConfig,
	agreementStatusColConfig,
]
