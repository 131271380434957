import React from 'react'
import StepWizard from 'react-step-wizard'
import styled, { css } from 'styled-components/macro'
import {
	EstateActivityInput,
	useCreateEstateActivitiesMutation,
} from '../../../gql_generated/graphql'
import { EstateTypeEnum, ParcelsParentType } from '../../../Types'
import { useState } from '../../../utils'
import { Async, toastit } from '../../UI'
import { ParcelSelectorSelectProps } from '../../UI/ParcelSelector/ParcelSelector.select'
import { AddParcelsToResourceActivityInfo } from './Ops.Parcels.addToResource.addInfo'
import { AddParcelsToResourceSelectParcels } from './Ops.Parcels.addToResource.select'

export const addParcelsToResourceOpStyles = css`
	min-height: 70vh;
`

const AddParcelsToResourceOpView = styled.div`
	${addParcelsToResourceOpStyles}
`

export type AddParcelsToResourceOpProps = Pick<
	ParcelSelectorSelectProps,
	'excludeConveyed' | 'excludeTerminated' | 'excludeAssigned'
> & {
	parentType: ParcelsParentType
	parentId: number

	estateType?: EstateTypeEnum
	useSingleEstateType?: boolean
	showConveyedToggle?: boolean

	cb?: () => void // For closing modal
}
export const AddParcelsToResourceOp: React.FC<AddParcelsToResourceOpProps> = ({
	cb,
	parentType,
	parentId,
	useSingleEstateType: useSingleEstate,
	estateType,
	...selectProps
}) => {
	/**
	 *  Set some defaults based on parent type
	 *  NOTE: These can be overridden by passing in explicit props
	 * */
	const defaultEstateType = estateType ?? (parentType === 'easement' ? 'srf' : undefined)
	const useSingleEstateType =
		useSingleEstate ??
		(parentType === 'lease' || parentType === 'agreement' || parentType === 'easement')

	/**
	 *  Internal state
	 * */
	const [estateTypes, setEstateTypes] = useState<EstateTypeEnum[]>(
		defaultEstateType ? [defaultEstateType] : [],
		'estateTypes'
	)
	const [selectedParcelIds, setSelectedIds] = useState<number[]>([], 'selectedParcelIds')

	/**
	 *  Handle mutation
	 * */
	const [mutResults, createEstateActivity] = useCreateEstateActivitiesMutation()
	const submitHandler = async (data: EstateActivityInput[]) => {
		try {
			const results = await createEstateActivity({
				parentId,
				parentType,
				estateTypes,
				data,
			})

			if (results.error) {
				toastit.err('Error adding parcels')
			} else {
				toastit.ok('Parcels added!')
				cb && cb()
			}
		} catch (err) {}
	}

	/**
	 *  Props common to all wizard step components below
	 * */
	const commonProps = {
		parentId,
		parentType,
		estateTypes,
		selectedParcelIds,
	}

	return (
		<AddParcelsToResourceOpView>
			<Async fetchResults={mutResults}>
				<StepWizard>
					<AddParcelsToResourceSelectParcels
						{...commonProps}
						{...selectProps}
						useSingleEstateType={useSingleEstateType}
						estateType={estateType}
						setSelectedParcelIds={setSelectedIds}
						selectEstateType={type =>
							setEstateTypes(state => {
								if (useSingleEstateType) return [type]

								const newState = [...state]
								const idx = state.findIndex(t => t === type)
								if (~idx) newState.splice(idx, 1)
								else newState.push(type)
								return newState
							})
						}
					/>

					<AddParcelsToResourceActivityInfo
						{...commonProps}
						submitHandler={submitHandler}
						isLazyMount
					/>
				</StepWizard>
			</Async>
		</AddParcelsToResourceOpView>
	)
}
