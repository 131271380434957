import React, { ReactNode } from 'react'
import styled from 'styled-components'
import Card from '../Card'
import ParcelSelectorSelect, { ParcelSelectorSelectProps } from './ParcelSelector.select'
import ParcelSelectorSelected from './ParcelSelector.selected'

const ParcelSelectorView = styled.div`
	& > header {
		padding: 1em 0;
		.btn {
			color: ${props => props.theme.colors.secondary.val};
			font-weight: 700;
			padding: 0.5em;
			&:hover {
				background: ${props => props.theme.colors.secondary.val};
				color: ${props => props.theme.colors.white.val};
			}
		}
	}
	.cols {
		grid-gap: 1em;
		${props => props.theme.media.tablet} {
			grid-template-columns: 1fr;
		}
		${props => props.theme.media.sdesk} {
			grid-template-columns: repeat(2, 1fr);
		}
	}
`

type ParcelSelectorProps = ParcelSelectorSelectProps & {
	children?: ReactNode
}
const ParcelSelector: React.FC<ParcelSelectorProps> = props => {
	const { selectedIds, setSelectedIds, parentId, parentType, estateTypes, children } = props

	const estateType = props.estateType || (estateTypes?.length === 1 ? estateTypes[0] : undefined)

	return (
		<ParcelSelectorView>
			<div className='cols cols-tablet'>
				<Card
					noShadow
					titleText={estateType ? `Select '${estateType}' estate by parcel...` : 'Select Parcels'}
				>
					{children}
					<ParcelSelectorSelect {...props} estateType={estateType} />
				</Card>
				<Card
					noShadow
					titleText={
						estateType ? `Selected '${estateType}' estate on parcels:` : `Selected Parcels`
					}
				>
					<ParcelSelectorSelected
						selectedIds={selectedIds}
						setSelectedIds={setSelectedIds}
						parentId={parentId}
						parentType={parentType}
						estateType={estateType}
					/>
				</Card>
			</div>
		</ParcelSelectorView>
	)
}
export default ParcelSelector
