import React from 'react'
import { useParcelForFinancialsQuery } from '../../../gql_generated/graphql'
import { KeyVals } from '../../UI'

type ParcelFinancesProps = {
	parcelId: number
}

const ParcelFinances: React.FC<ParcelFinancesProps> = ({ parcelId }) => {
	const [results] = useParcelForFinancialsQuery({ variables: { id: parcelId } })
	const { currentPropertyTax } = results.data?.parcel || {}
	return (
		<KeyVals
			items={[
				['Current property tax', currentPropertyTax?.amount, 'currency'],
				['Property tax expiration', currentPropertyTax?.year],
			]}
		/>
	)
}
export default ParcelFinances
