import React from 'react'
import styled, { css } from 'styled-components/macro'
import { useLogFullQuery } from '../../../gql_generated/graphql'
import { Async, Card, P } from '../../UI'
import { LogChangeLog } from './Log.changeLog'
import LogInfo from './Log.info'

export const logDetailStyles = css``

const LogDetailView = styled.div`
	${logDetailStyles}
`

export type LogDetailProps = {
	logId: number
}
export const LogDetail: React.FC<LogDetailProps> = ({ logId }) => {
	const [results] = useLogFullQuery({ variables: { id: logId } })
	const log = results.data?.log

	return (
		<LogDetailView>
			<Async fetchResults={results}>
				{log ? (
					<>
						<Card mBot noShadow>
							<LogInfo logId={logId} />
						</Card>
						<Card mBot noShadow titleText='Description'>
							<P asRT>{log.description}</P>
						</Card>
						<Card noShadow titleText='Change Log'>
							<LogChangeLog logId={logId} />
						</Card>
					</>
				) : null}
			</Async>
		</LogDetailView>
	)
}
