import { BarDatum, BarItemProps } from '@nivo/bar'
import { useTheme as useNivoTheme } from '@nivo/core'
import { useTooltip } from '@nivo/tooltip'
import { animated, to } from '@react-spring/web'
import { createElement, MouseEvent, useCallback, useMemo } from 'react'
import { useTheme } from 'styled-components'
import { useWindow } from '../../Window.provider'

/*
 =================================================
  This is the nivo bar item component code as of 01/26/2023 with just a couple of tweaks
  Original Component code: https://github.com/plouc/nivo/blob/master/packages/bar/src/BarItem.tsx

  Customizations:
   - If the label cannot be rendered because the height of the bar is below threshold, we render it above the bar. NOTE: labelSkipHeight might override this
   - We adjust the label styles to make the font bigger and heavier as per the designs
 =================================================
* */

type GenNivoBarOptions = {
	fontSize?: {
		mobile?: string
		desk?: string
	}
}

/**
 *  @description Renders bar labels according to design spec. NOTE: This overrides some built in nivo label formatting api functionality.
 *  @description This otherwise retains all built in nivo bar item functionality as of 01/26/2023
 * */
export const genNivoBar = (
	layout: 'horizontal' | 'vertical' = 'vertical',
	options?: GenNivoBarOptions
) => {
	const { fontSize: { mobile = '12px', desk = '13px' } = {} } = options ?? {}

	return function NivoBar<RawDatum extends BarDatum>({
		bar: { data, ...bar },

		style: {
			borderColor,
			color,
			height,
			labelColor,
			labelOpacity,
			labelX,
			labelY,
			transform,
			width,
		},

		borderRadius,
		borderWidth,

		label,
		shouldRenderLabel,

		isInteractive,
		onClick,
		onMouseEnter,
		onMouseLeave,

		tooltip,

		isFocusable,
		ariaLabel,
		ariaLabelledBy,
		ariaDescribedBy,
	}: BarItemProps<RawDatum>) {
		const theme = useNivoTheme()
		const { showTooltipFromEvent, showTooltipAt, hideTooltip } = useTooltip()

		const renderTooltip = useMemo(
			// eslint-disable-next-line react/display-name
			() => () => createElement(tooltip, { ...bar, ...data }),
			[tooltip, bar, data]
		)

		const handleClick = useCallback(
			(event: MouseEvent<SVGRectElement>) => {
				onClick?.({ color: bar.color, ...data }, event)
			},
			[bar, data, onClick]
		)
		const handleTooltip = useCallback(
			(event: MouseEvent<SVGRectElement>) => showTooltipFromEvent(renderTooltip(), event),
			[showTooltipFromEvent, renderTooltip]
		)
		const handleMouseEnter = useCallback(
			(event: MouseEvent<SVGRectElement>) => {
				onMouseEnter?.(data, event)
				showTooltipFromEvent(renderTooltip(), event)
			},
			[data, onMouseEnter, showTooltipFromEvent, renderTooltip]
		)
		const handleMouseLeave = useCallback(
			(event: MouseEvent<SVGRectElement>) => {
				onMouseLeave?.(data, event)
				hideTooltip()
			},
			[data, hideTooltip, onMouseLeave]
		)

		// extra handlers to allow keyboard navigation
		const handleFocus = useCallback(() => {
			showTooltipAt(renderTooltip(), [bar.absX + bar.width / 2, bar.absY])
		}, [showTooltipAt, renderTooltip, bar])
		const handleBlur = useCallback(() => {
			hideTooltip()
		}, [hideTooltip])

		const { isMobile } = useWindow()
		const { colors } = useTheme()
		const isVert = layout === 'vertical'
		const xMult = isMobile ? label.length * 7 : label.length * 12

		const canFitLabel = isVert ? bar.height > (isMobile ? 25 : 30) : bar.width > xMult + 20

		let y = labelY as any
		let x = labelX as any

		if (isVert) {
			y = canFitLabel ? (isMobile ? 20 : 25) : isMobile ? -15 : -20
		} else {
			x = bar.width
			x -= canFitLabel ? xMult : -xMult
		}

		return (
			<animated.g transform={transform}>
				<animated.rect
					width={to(width, value => Math.max(value, 0))}
					height={to(height, value => Math.max(value, 0))}
					rx={borderRadius}
					ry={borderRadius}
					fill={data.fill ?? color}
					strokeWidth={borderWidth}
					stroke={borderColor}
					focusable={isFocusable}
					tabIndex={isFocusable ? 0 : undefined}
					aria-label={ariaLabel ? ariaLabel(data) : undefined}
					aria-labelledby={ariaLabelledBy ? ariaLabelledBy(data) : undefined}
					aria-describedby={ariaDescribedBy ? ariaDescribedBy(data) : undefined}
					onMouseEnter={isInteractive ? handleMouseEnter : undefined}
					onMouseMove={isInteractive ? handleTooltip : undefined}
					onMouseLeave={isInteractive ? handleMouseLeave : undefined}
					onClick={isInteractive ? handleClick : undefined}
					onFocus={isInteractive && isFocusable ? handleFocus : undefined}
					onBlur={isInteractive && isFocusable ? handleBlur : undefined}
				/>

				{shouldRenderLabel ? (
					<animated.text
						x={x}
						y={y}
						textAnchor='middle'
						dominantBaseline='central'
						fillOpacity={labelOpacity}
						style={{
							...theme.labels.text,
							pointerEvents: 'none',
							fontSize: isMobile ? mobile : desk,
							fontWeight: 400,
							fill: canFitLabel ? labelColor : colors.black.val,
						}}
					>
						{label}
					</animated.text>
				) : null}
			</animated.g>
		)
	}
}
