import React from 'react'
import styled, { css } from 'styled-components/macro'
import { LogDetail } from './Log.detail'

export const genLogModalStyles = css``

const GenLogModalView = styled.div`
	${genLogModalStyles}
`

export type GenLogModalProps = {
	logId: number
}
export const GenLogModal: React.FC<GenLogModalProps> = ({ logId }) => {
	return (
		<GenLogModalView>
			<LogDetail logId={logId} />
		</GenLogModalView>
	)
}
