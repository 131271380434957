import { useFormikContext } from 'formik'
import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import {
	HR,
	IntegerField,
	PercentageField,
	PriceField,
	SelectOrgField,
	Text,
	Textarea,
	ToggleField,
} from '../../UI'

const View = styled.div``

export const saleFieldsSchema = Yup.object().shape({
	dateOpened: Yup.string().required('Date opened is required'),
})

export const newSaleFieldsSchema = Yup.object().shape({
	granteeId: Yup.number().required('Please select a grantee org'),
	dateOpened: Yup.string().required('Date opened is required'),
})

type SaleFieldsProps = {
	isNew?: boolean
}
const SaleFields: React.FC<SaleFieldsProps> = ({ isNew = false }) => {
	const { values } = useFormikContext<{
		deposit?: number
		cbNote?: number
		brokerId?: number
		status: string
	}>()

	return (
		<View className='sale-fields cols cols-2'>
			<div className='col'>
				<Text name='escrowId' label='Escrow ID' />
				<HR />
				<SelectOrgField name='granteeId' label='Grantee' isReq={isNew} />
				<SelectOrgField name='brokerId' label='Broker' />
				{values.brokerId ? (
					<PercentageField name='brokerRate' label='Broker Commission Rate' />
				) : null}
				<SelectOrgField name='titleId' label='Title Company' />
				<HR />
				<PriceField name='price' label='Price' />
				<PriceField name='deposit' label='Deposit' />
				{values.deposit ? (
					<ToggleField name='isDepositTowardPurchase' label='Deposit counts towards purchase?' />
				) : null}
				{values.cbNote ? <PriceField name='cbNote' label='CB (Carry Back) Note' /> : null}
				{values.cbNote ? (
					<>
						<PercentageField name='cbInterest' label='CB Interest' />
						<IntegerField name='cbYears' label='CB Years' />
					</>
				) : null}
			</div>
			<div className='col'>
				<Text type='date' name='dateOpened' label='Date Opened' isReq />
				<Text type='date' name='dateExpected' label='Date Expected' />
				<HR />
				<Textarea name='notes' label='General Notes' />
			</div>
		</View>
	)
}
export default SaleFields
