import React from 'react'
import styled, { css } from 'styled-components/macro'
import { AcquisitionDetail } from './Acquisition.detail'

export const genAcquisitionModalStyles = css``

const GenAcquisitionModalView = styled.div`
	${genAcquisitionModalStyles}
`

export type GenAcquisitionModalProps = {
	acquisitionId: number
}
export const GenAcquisitionModal: React.FC<GenAcquisitionModalProps> = ({ acquisitionId }) => {
	return (
		<GenAcquisitionModalView>
			<AcquisitionDetail acquisitionId={acquisitionId} />
		</GenAcquisitionModalView>
	)
}
