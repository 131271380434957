import React from 'react'
import styled from 'styled-components'
import { useDocForOgQuery } from '../../../gql_generated/graphql'
import { KeyVals } from '../../UI'

export const DocOgView = styled.div``

export type DocOgProps = {
	docId: number
}
export const DocOg: React.FC<DocOgProps> = ({ docId }) => {
	const [results] = useDocForOgQuery({ variables: { id: docId } })
	const doc = results.data?.doc
	const { og } = doc || {}
	const {
		grantee,
		grantor,
		vestingDescription,
		hasRoyalty,
		royaltyCap,
		royaltyDeedStatus,
		royaltyPercentage,
		royaltyType,
	} = og || {}
	return (
		<DocOgView>
			<KeyVals
				items={[
					['Grantee', grantee, 'proper'],
					['Grantor', grantor, 'proper'],
					['Vesting Description', vestingDescription],
					['Has Royalty?', hasRoyalty, 'boolean'],
					hasRoyalty ? ['Royalty Cap', royaltyCap, 'currency'] : null,
					hasRoyalty ? ['Royalty Deed Status', royaltyDeedStatus] : null,
					hasRoyalty ? ['Royalty Percentage', `${royaltyPercentage || 0}%`] : null,
					hasRoyalty ? ['Royalty Type', royaltyType] : null,
				]}
			/>
		</DocOgView>
	)
}
