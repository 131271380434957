import React from 'react'
import * as Yup from 'yup'
import { useStoreState } from '../../../store'
import { userRoles } from '../../../utils'
import { EmailField, Select, Text } from '../../UI'

export const userSchema = Yup.object().shape({
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required'),
	email: Yup.string().required('Required'),
	preferences: Yup.array().of(
		Yup.object().shape({
			id: Yup.number(),
			type: Yup.string(),
			pref: Yup.string(),
		})
	),
	roleId: Yup.number(),
})

let userRolesOpts = userRoles.map(({ description, id }) => ({ txt: description, val: id }))

const UserFields: React.FC<{ isUpdate?: boolean }> = ({ isUpdate }) => {
	const { isAdmin } = useStoreState(store => store.user)
	if (isAdmin !== true) userRolesOpts = userRolesOpts.filter(({ val }) => val > 2)
	return (
		<>
			<div className='grid name'>
				<Text name='firstName' label='First name' isReq />
				<Text name='lastName' label='Last name' isReq />
			</div>
			<div className='grid email-role'>
				<EmailField
					name='email'
					label={isUpdate ? 'Email...' : 'Email'}
					placeholder=''
					isReq={isUpdate !== true}
					readOnly={isUpdate}
				/>
				<Select name='roleId' label='User Role' options={userRolesOpts} className='role-field' />
			</div>
		</>
	)
}
export default UserFields
