import React from 'react'
import styled from 'styled-components'
import { useParcelsForParcelSelectorSelectedQuery } from '../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../Types'
import { parcelSelectorColsConfig } from '../Table.resources/Table.resource.col.configs/Parcel.col.configs'
import { ResourcesTable } from '../Table.resources/Table.resources'
import { usePaginationParams } from '../Table.resources/usePaginationParams'
import { ParcelSelectorSelectProps } from './ParcelSelector.select'

const ParcelSelectorSelectedView = styled.div``

export type ParcelSelectorSelectedProps = Pick<
	ParcelSelectorSelectProps,
	'selectedIds' | 'setSelectedIds' | 'parentId' | 'parentType'
> & {
	estateType?: EstateTypeEnum
}
const ParcelSelectorSelected: React.FC<ParcelSelectorSelectedProps> = ({
	selectedIds,
	setSelectedIds,
	parentId,
	parentType,
	estateType,
}) => {
	const paginationStore = usePaginationParams()
	const [results] = useParcelsForParcelSelectorSelectedQuery({
		variables: { parcelIds: selectedIds },
		pause: selectedIds?.length ? false : true,
	})

	const parcels = selectedIds?.length ? results.data?.parcels || [] : []

	const dataset = [...parcels].sort((a, b) => {
		const aApn = a.apn
		const bApn = b.apn

		if (aApn < bApn) return -1
		else if (aApn > bApn) return +1
		else return 0
	})

	return (
		<ParcelSelectorSelectedView className='selected-parcels'>
			<ResourcesTable
				dataset={dataset}
				colsConfig={parcelSelectorColsConfig({
					parentId,
					parentType,
					estateType,
				})}
				fetchResults={results}
				paginationStore={paginationStore}
				rowOps={[
					{
						type: 'remove',
						op: parcel =>
							setSelectedIds(state => {
								const newState = [...state]
								const idx = state.findIndex(id => id === parcel.id)
								if (idx >= 0) newState.splice(idx, 1)
								return newState
							}),
					},
				]}
				displayOptions={{
					suppressLoader: true,
					suppressSorting: true,
				}}
				limitSelectConfig={{
					hideLimitSelect: true,
				}}
				contentOverride={!selectedIds?.length ? <div /> : undefined}
			/>
		</ParcelSelectorSelectedView>
	)
}
export default ParcelSelectorSelected
