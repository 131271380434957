import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Modal } from '..'
import { useState } from '../../../utils'
import { Btn } from '../Typography'

const View = styled.div`
	.message {
		text-align: center;
		margin-bottom: 2em;
		min-height: 5em;
		align-items: center;
		justify-content: center;
	}
	.btns {
		align-items: center;
		justify-content: center;
		padding-bottom: 1em;
	}
`

export type AlertProps = {
	msg: ReactNode
	okBtn?: ReactNode
	cancelBtn?: ReactNode
	cb: (selection: boolean) => void
	useCancelAsOp?: boolean
	show?: boolean
}
const Alert: React.FC<AlertProps> = ({ msg, okBtn, cancelBtn, cb, show, useCancelAsOp }) => {
	const [showModal, setShow] = useState(typeof show === 'boolean' ? show : true, 'showModal')

	const okHandler = () => {
		setShow(false)
		cb(typeof useCancelAsOp === 'boolean' ? false : true)
	}

	const cancelHandler = () => {
		setShow(false)
		cb(typeof useCancelAsOp === 'boolean' ? true : false)
	}
	return (
		<Modal
			show={showModal}
			closeHandler={() => setShow(false)}
			canBgClose={false}
			includeCloseIco={false}
		>
			<View className='alert-modal'>
				<div className='message flex'>{msg}</div>
				<div className='btns flex'>
					<Btn isCta onClick={okHandler}>
						{okBtn || 'Okay'}
					</Btn>
					<Btn onClick={cancelHandler}>{cancelBtn || 'Cancel'}</Btn>
				</div>
			</View>
		</Modal>
	)
}
export default Alert
