/*
 =================================================
  Global store model
  This gets injected into a react context provider from easy-peasy - see gatsby-browser.js
 =================================================
* */
import { createStore, createTypedHooks } from 'easy-peasy'
import { pageModel, PageStoreModel } from './store.page'
import { searchModel, SearchStoreModel } from './store.search'
import { userModel, UserStoreModel } from './store.user'
import { viewModel, ViewStoreModel } from './store.view'

type StoreModel = {
	user: UserStoreModel
	view: ViewStoreModel
	page: PageStoreModel
	search: SearchStoreModel
}

const store = createStore({
	user: userModel,
	view: viewModel,
	page: pageModel,
	search: searchModel,
})

const typedHooks = createTypedHooks<StoreModel>()

export const useStoreActions = typedHooks.useStoreActions
export const useStoreState = typedHooks.useStoreState
export const useStoreDispatch = typedHooks.useStoreDispatch

export default store
