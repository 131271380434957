import { RouteComponentProps, useNavigate } from '@reach/router'
import React from 'react'
import CardsSection from '../../Components/common/CardsSection'
import { PageOperation } from '../../Components/common/Operations'
import Page from '../../Components/common/Resources/Resource.page'
import { OpsDocCreate } from '../../Components/Ops/Ops.Docs/Ops.Doc.create'
import { Card, Modal, Tab, Tabs } from '../../Components/UI'
import { useModalCtrl } from '../../Components/UI/Modal'
import { usePageData } from '../../utils'
import DocsPageAll from './Docs.page.all'
import DocsPageRecorded from './Docs.page.recorded'

const DocsPage: React.FC<RouteComponentProps> = () => {
	usePageData({ pageType: 'docs', pageTitle: 'Docs' })
	const navTo = useNavigate()

	const [[showOpModal, openOpModal, closeOpModal, opModal]] = useModalCtrl<'create'>()

	const operations: PageOperation[] = [
		{
			op: () => openOpModal('create'),
			txt: 'New Doc',
			icon: 'doc',
		},
	]

	return (
		<Page isLoaded operations={operations}>
			<CardsSection>
				<Card>
					<Tabs renderOnSelect>
						<Tab nav='Recorded' icon='docs'>
							<DocsPageRecorded />
						</Tab>
						<Tab nav='All' icon='docs'>
							<DocsPageAll />
						</Tab>
					</Tabs>
				</Card>
			</CardsSection>
			<Modal size='med' show={showOpModal} closeHandler={closeOpModal}>
				{opModal === 'create' ? (
					<OpsDocCreate
						cb={id => {
							navTo(`/mgr/docs/${id}`)
						}}
					/>
				) : null}
			</Modal>
		</Page>
	)
}
export default DocsPage
