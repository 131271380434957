import React from 'react'
import styled, { css } from 'styled-components/macro'
import { AgreementDetail } from './Agreement.detail'

export const genAgreementModalStyles = css``

const GenAgreementModalView = styled.div`
	${genAgreementModalStyles}
`

export type GenAgreementModalProps = {
	agreementId: number
}
export const GenAgreementModal: React.FC<GenAgreementModalProps> = ({ agreementId }) => {
	return (
		<GenAgreementModalView>
			<AgreementDetail agreementId={agreementId} />
		</GenAgreementModalView>
	)
}
