import unset from 'lodash/unset'
import React, { useEffect } from 'react'
import StepWizard from 'react-step-wizard'
import { ParcelParent } from '../../../../gql_generated/document_types'
import {
	RoyaltyInput,
	useActivityEstateTypesByParentQuery,
	useCreateRoyaltyMutation,
	useSingleRoyaltyQuery,
	useUpdateRoyaltyMutation,
} from '../../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../../Types'
import { priceToNumber, sanitizeDataForForm, useState } from '../../../../utils'
import RoyaltyFields, { royaltyfieldsSchema } from '../../../common/Royalty/Royalty.fields'
import {
	Card,
	ErrorBlock,
	Form,
	ParcelSelector,
	Spinner,
	SubmitBtn,
	Tab,
	Tabs,
	toastit,
	ToggleField,
} from '../../../UI'
import AddEditRoyaltyOnResourceActivityDetails from './Ops.Royalty.add.edit.activity.details'
import AddEditRoyaltyOnResourceActivitySelect from './Ops.Royalty.add.edit.activity.select'
import AddEditRoyaltyOnResourceView from './Ops.Royalty.add.edit.view'

export type AddEditRoyaltyOnResourceProps = {
	royaltyId?: number
	parentId: number
	parentType: 'sale' | 'lease'
	estateType?: EstateTypeEnum
	cb?: () => void
}

export const AddEditRoyaltyOnResource: React.FC<AddEditRoyaltyOnResourceProps> = ({
	royaltyId,
	parentId,
	parentType,
	estateType,
	cb,
}) => {
	const [error, setErr] = useState<any | null>(null, 'error')
	const [_, createRoyalty] = useCreateRoyaltyMutation()
	const [__, updateRoyalty] = useUpdateRoyaltyMutation()

	const [{ data: estateTypesData, fetching: estateTypesLoading }, fetchEstateTypes] =
		useActivityEstateTypesByParentQuery({
			variables: { parentId, parentType, returnUsedEstateTypes: true },
			pause: !estateType,
		})

	const { activityEstateTypes } = estateTypesData || {}

	const [{ data: existingRoyaltyData, fetching: existingRoyaltyLoading }, fetchRoyalty] =
		useSingleRoyaltyQuery({ variables: { id: royaltyId as number }, pause: !royaltyId })

	const [selectedParcelIds, setSelectedParcelIds] = useState<number[]>([], 'selectedParcelIds')

	useEffect(() => {
		if (!estateType && !estateTypesLoading && !estateTypesData)
			fetchEstateTypes({ variables: { parentId, parentType, returnUsedEstateTypes: true } })

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [estateType, estateTypesLoading, estateTypesData])

	useEffect(() => {
		if (royaltyId && !existingRoyaltyLoading && !existingRoyaltyData) {
			fetchRoyalty({ variables: { id: royaltyId } })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [royaltyId, existingRoyaltyLoading, existingRoyaltyData])

	useEffect(() => {
		if (existingRoyaltyData?.royalty?.activity) {
			setSelectedParcelIds(existingRoyaltyData.royalty.activity.map(({ parcelId }) => parcelId))
		}
	}, [existingRoyaltyData])

	const submitHandler = async (vals: RoyaltyInput) => {
		try {
			const data = { ...vals, parentType }
			data.saleId = null
			data.leaseId = null
			const parentIdKey = `${parentType}Id` as 'saleId' | 'leaseId'
			data[parentIdKey] = parentId
			data.rate = priceToNumber(vals.rate as unknown as string)
			data.royaltyActivity = vals.usesAllParcels
				? null
				: data?.royaltyActivity?.map(({ acres, aliquot, ...ra }) => {
						const raData = {
							...ra,
							acres: acres ? acres : null,
							aliquot: aliquot ? aliquot : null,
						}
						if (royaltyId) raData.royaltyId = royaltyId

						unset(raData, 'estateActivity')
						unset(raData, 'status')
						return raData
				  })

			if (royaltyId) {
				const res = await updateRoyalty({
					data: {
						...data,
						id: royaltyId,
					},
				})
				if (res.error) toastit.err('Unable to update royalty')
				else if (cb) cb()
			} else {
				const res = await createRoyalty({ data })
				if (res.error) toastit.err('Unable to create royalty')
				else if (cb) cb()
			}
		} catch (err: any) {
			setErr(err)
		}
	}

	const initialVals = existingRoyaltyData?.royalty || {
		estateType: estateType || 'srf',
		rateType: 'percentage',
		rate: 0,
		description: '',
		usesAllParcels: true,
		royaltyActivity: [],
	}

	return (
		<AddEditRoyaltyOnResourceView>
			{royaltyId && existingRoyaltyLoading ? (
				<Spinner />
			) : royaltyId && !existingRoyaltyData ? null : (
				<Form
					initialValues={sanitizeDataForForm(initialVals, {
						stripKeys: ['sale', 'lease', 'estateActivity'],
					})}
					onSubmit={submitHandler}
					validationSchema={royaltyfieldsSchema}
				>
					{({ values }) => (
						<>
							<Tabs renderOnSelect>
								<Tab nav='Info'>
									<ErrorBlock error={error} />
									<Card noShadow>
										<RoyaltyFields
											estateType={estateType}
											estateTypes={activityEstateTypes as string[]}
											isUpdate={!!royaltyId}
										/>
									</Card>
								</Tab>
								<Tab nav='Configure Parcels'>
									<ToggleField
										name='usesAllParcels'
										label={`Use all parcels on ${parentType} based on the '${values.estateType}' estate.`}
									/>
									{!values.usesAllParcels ? (
										<StepWizard isLazyMount>
											<AddEditRoyaltyOnResourceActivitySelect
												parentType={parentType}
												ids={selectedParcelIds}
											>
												<ParcelSelector
													parentId={parentId}
													parentType={parentType as ParcelParent}
													filterByParent
													estateTypes={values.estateType ? [values.estateType] : undefined}
													selectedIds={selectedParcelIds}
													setSelectedIds={setSelectedParcelIds}
													excludeConveyed
												/>
											</AddEditRoyaltyOnResourceActivitySelect>
											<AddEditRoyaltyOnResourceActivityDetails
												parcelIds={selectedParcelIds}
												estateType={values.estateType}
												parentId={parentId}
												parentType={parentType}
											/>
										</StepWizard>
									) : null}
								</Tab>
							</Tabs>
							<SubmitBtn />
						</>
					)}
				</Form>
			)}
		</AddEditRoyaltyOnResourceView>
	)
}
