import React from 'react'
import styled, { css } from 'styled-components/macro'
import { SaleDetail } from '../../../Components/common/Sale/Sale.detail'

export const relatedParcelSaleModalStyles = css``

const RelatedParcelSaleModalView = styled.div`
	${relatedParcelSaleModalStyles}
`

export type RelatedParcelSaleModalProps = {
	saleId: number
}
export const RelatedParcelSaleModal: React.FC<RelatedParcelSaleModalProps> = ({ saleId }) => (
	<RelatedParcelSaleModalView>
		<SaleDetail saleId={saleId} />
	</RelatedParcelSaleModalView>
)
