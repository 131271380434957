import React from 'react'
import styled, { css } from 'styled-components/macro'
import { AddBtn, Modal, useModalCtrl } from '../../UI'
import { AddParcelsToResourceOp, AddParcelsToResourceOpProps } from './Ops.Parcels.addToResource'

export const addParcelsToResourceBtnStyles = css``

const AddParcelsToResourceBtnView = styled.div`
	${addParcelsToResourceBtnStyles}
`

export type AddParcelsToResourceBtnProps = {
	children?: string
	config: Omit<AddParcelsToResourceOpProps, 'cb'>
}
export const AddParcelsToResourceBtn: React.FC<AddParcelsToResourceBtnProps> = ({
	children = 'Add Parcels',
	config,
}) => {
	const [[showModal, openModal, closeModal]] = useModalCtrl()
	return (
		<AddParcelsToResourceBtnView>
			<AddBtn onClick={openModal}>{children}</AddBtn>

			<Modal show={showModal} closeHandler={closeModal} size='large'>
				<AddParcelsToResourceOp {...config} cb={closeModal} />
			</Modal>
		</AddParcelsToResourceBtnView>
	)
}
