import React from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import styled, { css } from 'styled-components/macro'
import { useParcelsByParentQuery } from '../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../Types'
import { useState } from '../../../utils'
import EstateTypes from '../../common/Estate/Estates.Icons'
import { Async, Btn, H6, Icon, ParcelSelector } from '../../UI'
import { ToggleRaw } from '../../UI/Form.Fields/Toggle.field'
import { AddParcelsToResourceOpProps } from './Ops.Parcels.addToResource'

export const addParcelsToResourceSelectParcelsStyles = css`
	padding: 2rem;
	min-height: 80vh;
	min-width: 75vw;
	width: 100%;
	& > header {
		display: flex;
		justify-content: space-between;
		padding-bottom: 1em;
		margin-bottom: 2em;
		border-bottom: solid 1px ${props => props.theme.colors.grey.light().val};

		.preselected-estate-type {
			display: flex;
			align-items: center;

			.icon {
				height: 50px;
				margin-left: 10px;
				color: ${props => props.theme.colors.secondary.tint(40).val};
			}
		}

		.selected-estate-types {
			text-align: center;
		}

		.nav-btns {
		}
	}
	& > .cols {
		${props => props.theme.media.sdesk} {
			display: flex;
		}
		& > .col {
			& > header {
				margin-bottom: 2rem;
			}
			h3 {
				padding: 0;
			}
		}
	}
	.parcel-select {
		${props => props.theme.media.sdesk} {
			min-width: 700px;
			max-width: 70%;
		}
		.estate-type-btns {
			margin-bottom: 1em;
		}
	}
	.selected-parcels {
		${props => props.theme.media.sdesk} {
			flex-grow: 1;
		}
		& > main {
			${props => props.theme.media.sdesk} {
				/* spacer to match tables */
				padding-top: 4.8em;
			}
		}
		.totals {
			margin-bottom: 1em;
			.total-acres {
				margin-left: auto;
			}
		}
	}
`

const AddParcelsToResourceSelectParcelsView = styled.div`
	${addParcelsToResourceSelectParcelsStyles}
`

export type AddParcelsToResourceSelectParcelsProps = Pick<
	AddParcelsToResourceOpProps,
	| 'estateType'
	| 'parentType'
	| 'parentId'
	| 'useSingleEstateType'
	| 'excludeConveyed'
	| 'excludeTerminated'
	| 'excludeAssigned'
	| 'showConveyedToggle'
> & {
	estateTypes: EstateTypeEnum[]
	selectEstateType: (estateType: EstateTypeEnum) => void
	selectedParcelIds: number[]
	setSelectedParcelIds: React.Dispatch<React.SetStateAction<number[]>>
}
export const AddParcelsToResourceSelectParcels: React.FC<
	AddParcelsToResourceSelectParcelsProps
> = ({
	parentId,
	parentType,
	estateType,
	estateTypes,
	selectEstateType,
	selectedParcelIds,
	setSelectedParcelIds,
	useSingleEstateType,
	excludeTerminated,
	excludeAssigned,
	excludeConveyed,
	showConveyedToggle,
	...wizardProps
}) => {
	const { nextStep } = wizardProps as StepWizardChildProps
	const [results] = useParcelsByParentQuery({
		variables: { parentId, parentType },
	})

	const existingParcels = results.data?.parcels

	const [showConveyed, setInactive] = useState(false, 'showConveyed')

	return (
		<Async fetchResults={results}>
			<AddParcelsToResourceSelectParcelsView className='select-parcel'>
				<header>
					<div className='spacer' />
					{estateType ? (
						<div className='preselected-estate-type selected-estate-types'>
							<H6 mBot>Estate Type</H6>
							<Icon type={estateType} />
						</div>
					) : (
						<div className='select-estate-type selected-estate-types'>
							<H6 mBot>Select Estate Type{useSingleEstateType ? '' : 's'}</H6>
							<EstateTypes
								onSelect={val => selectEstateType(val as EstateTypeEnum)}
								selected={estateTypes as string[]}
							/>
						</div>
					)}
					<div className='nav-btns'>
						<Btn disabled={!selectedParcelIds?.length} onClick={() => nextStep && nextStep()} isCta>
							Next
							<Icon type='arrowRight' padLeft />
						</Btn>
					</div>
				</header>

				{estateTypes?.length ? (
					<ParcelSelector
						selectedIds={selectedParcelIds}
						setSelectedIds={setSelectedParcelIds}
						estateTypes={estateTypes}
						excludeConveyed={
							showConveyedToggle ? !showConveyed : excludeConveyed || parentType === 'sale'
						}
						excludeAssigned={excludeAssigned}
						excludeTerminated={excludeTerminated}
						disableRow={parcelId =>
							!!existingParcels?.length &&
							existingParcels.some(
								({ activity, id }) =>
									!!activity?.length &&
									parcelId === id &&
									activity.some(ea => {
										const eaParentId = ea[`${parentType}Id` as keyof typeof ea]
										return (
											eaParentId === parentId &&
											estateTypes.includes(ea.estateType as EstateTypeEnum)
										)
									})
							)
						}
					>
						{showConveyedToggle ? (
							<div className='toggle-conveyed-parcels'>
								<ToggleRaw
									onClick={setInactive}
									isOn={showConveyed}
									name='show-conveyed-parcels-toggle'
									label='Include Conveyed Estates?'
								/>
							</div>
						) : null}
					</ParcelSelector>
				) : null}
			</AddParcelsToResourceSelectParcelsView>
		</Async>
	)
}
