import { useLogForUserColQuery } from '../../../../gql_generated/graphql'
import { TableBodyCellComponent } from '../../Table/Table.types'
import { ColConfig } from './Col.config.types'
import { ResourceTableCellAsync } from './Table.resource.cell.Async'

const User: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useLogForUserColQuery({
		variables: { id },
	})
	const { createdBy } = fetchResults.data?.log || {}

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{createdBy?.firstName}
		</ResourceTableCellAsync>
	)
}

export const logDateCreatedColConfig: ColConfig = {
	label: 'Date/time',
	field: 'createdAt',
	format: {
		format: 'date',
		date: 'MM/dd/yyyy h:mm:ss a',
	},
	sortField: 'createdAt',
}

export const logTypeColConfig: ColConfig = {
	field: 'logType',
	label: 'Type',
	format: 'proper',
	sortField: 'logType',
}

export const logActionColConfig: ColConfig = {
	field: 'action',
	format: 'proper',
	sortField: 'action',
}

export const logDescriptionColConfig: ColConfig = {
	field: 'description',
}

export const logCreatedByColConfig: ColConfig = {
	label: 'User',
	format: 'proper',
	Component: User,
}

export const logResourceTypeColConfig: ColConfig = {
	field: 'resourceType',
	label: 'Resource',
	format: 'proper',
	hideMobile: true,
}

export const logsPageColsConfig: ColConfig[] = [
	logDateCreatedColConfig,
	logTypeColConfig,
	logActionColConfig,
	logDescriptionColConfig,
	logCreatedByColConfig,
]

export const relatedLogsColsConfig: ColConfig[] = [
	logDateCreatedColConfig,
	logTypeColConfig,
	logDescriptionColConfig,
	logCreatedByColConfig,
]

export const logWidgetColsConfig: ColConfig[] = [
	logDateCreatedColConfig,
	logDescriptionColConfig,
	logCreatedByColConfig,
]
