import React from 'react'
import styled, { css } from 'styled-components/macro'
import { LeaseDetail } from '../../../Components/common/Lease/Lease.detail'

export const relatedOrgLeaseModalStyles = css``

const RelatedOrgLeaseModalView = styled.div`
	${relatedOrgLeaseModalStyles}
`

export type RelatedOrgLeaseModalProps = {
	leaseId: number
}
export const RelatedOrgLeaseModal: React.FC<RelatedOrgLeaseModalProps> = ({ leaseId }) => {
	return (
		<RelatedOrgLeaseModalView>
			<LeaseDetail leaseId={leaseId} />
		</RelatedOrgLeaseModalView>
	)
}
