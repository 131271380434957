import { ColConfig } from './Col.config.types'

export const leaseTermDateFromColConfig: ColConfig = {
	field: 'dateFrom',
	label: 'Effective',
	format: 'date',
	sortField: 'dateFrom',
}

export const leaseTermDateToColConfig: ColConfig = {
	field: 'dateTo',
	label: 'Expiration',
	format: 'date',
	sortField: 'date',
}

export const leaseTermScheduleColConfig: ColConfig = {
	field: 'schedForDisplay',
	label: 'Payment Schedule',
}

export const leaseTermNotesColConfig: ColConfig = {
	field: 'termNotes',
	label: 'Term Notes',
	hideMobile: true,
}

export const leaseTermRateColConfig: ColConfig = {
	field: 'rate',
	label: 'Rate Increase',
	format: 'percentage',
	sortField: 'date',
}

export const leaseReleatedTermsColsConfig = [
	leaseTermDateFromColConfig,
	leaseTermDateToColConfig,
	leaseTermScheduleColConfig,
	leaseTermNotesColConfig,
]
