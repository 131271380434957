import React from 'react'
import styled from 'styled-components'
import { usePaginatedDocsQuery } from '../../../../gql_generated/graphql'
import { GenDocModal } from '../../../common/Doc/Doc.gen.modal'
import { docSelectorColsConfig } from '../../../UI/Table.resources/Table.resource.col.configs/Doc.col.configs'
import { ResourcesTable } from '../../../UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../UI/Table.resources/usePaginationParams'

export const AddDocToResourceSelectDocSelectView = styled.div`
	& > header {
		display: flex;
		align-items: center;
		padding-bottom: 2em;

		button {
			margin-left: auto;
		}
	}
`

export type AddDocToResourceSelectDocSelectProps = {
	docId: number | null
	setDocId: React.Dispatch<React.SetStateAction<number | null>>
	showPreview: () => void
}
export const AddDocToResourceSelectDocSelect: React.FC<AddDocToResourceSelectDocSelectProps> = ({
	docId,
	setDocId,
	showPreview,
}) => {
	const paginationStore = usePaginationParams()
	const [results] = usePaginatedDocsQuery({
		variables: {
			params: paginationStore.params,
		},
	})

	const dataset = results.data?.page?.docs
	return (
		<AddDocToResourceSelectDocSelectView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={docSelectorColsConfig}
				fetchResults={results}
				paginationStore={paginationStore}
				includeSearch
				selectRowConfig={{
					selectRowHandler: doc => {
						if (!docId) showPreview()
						setDocId(state => (state === doc.id ? null : doc.id))
					},
					selectedRowIds: docId ? [docId] : [],
					selectType: 'checkcircle',
				}}
				resourceType='doc'
				modalRenderFunc={doc => <GenDocModal docId={doc.id} />}
				displayOptions={{
					suppressLastSelected: true,
				}}
			/>
		</AddDocToResourceSelectDocSelectView>
	)
}
