import { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { LeaseFinancialNotesInput } from '../../../gql_generated/document_types'
import {
	useLeaseFinancialNotesMutation,
	useLeaseForFinancialInfoQuery,
} from '../../../gql_generated/graphql'
import { sanitizeDataForForm } from '../../../utils'
import { Async, Form, SubmitBtn, Textarea, toastit } from '../../UI'

export const leaseFinancialNotesOpStyles = css``

const LeaseFinancialNotesOpView = styled.div`
	${leaseFinancialNotesOpStyles}
`

export type LeaseFinancialNotesOpProps = {
	leaseId: number
	cb?: () => void
}
export const LeaseFinancialNotesOp = ({ leaseId, cb }: LeaseFinancialNotesOpProps): JSX.Element => {
	const [results] = useLeaseForFinancialInfoQuery({ variables: { id: leaseId } })

	const lease = results.data?.lease

	const [{ error }, updateMut] = useLeaseFinancialNotesMutation()

	const initialVals = {
		leaseId,
		financialNotes: lease?.financialNotes || '',
	} as LeaseFinancialNotesInput

	const submitHandler = useCallback(
		async (vals: typeof initialVals) => {
			try {
				const res = await updateMut({ data: vals })

				if (!res.error) {
					toastit.ok('Saved!')
					cb && cb()
				}
			} catch (err) {}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[initialVals]
	)
	return (
		<LeaseFinancialNotesOpView>
			<Async fetchResults={results}>
				<Form
					initialValues={sanitizeDataForForm(initialVals, { stripKeys: ['schedForDisplay'] })}
					onSubmit={submitHandler}
					legend='Update Financial Notes'
					submitErr={error}
				>
					{() => (
						<>
							<Textarea name='financialNotes' label='Financial Notes' />
							<SubmitBtn />
						</>
					)}
				</Form>
			</Async>
		</LeaseFinancialNotesOpView>
	)
}
