import React from 'react'
import styled, { css } from 'styled-components/macro'
import { ParcelDetail } from '../../../Components/common/Parcel/Parcel.detail'

export const relatedDocParcelModalStyles = css``

const RelatedDocParcelModalView = styled.div`
	${relatedDocParcelModalStyles}
`

export type RelatedDocParcelModalProps = {
	parcelId: number
}
export const RelatedDocParcelModal: React.FC<RelatedDocParcelModalProps> = ({ parcelId }) => {
	return (
		<RelatedDocParcelModalView>
			<ParcelDetail parcelId={parcelId} />
		</RelatedDocParcelModalView>
	)
}
