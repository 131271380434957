import React from 'react'
import styled from 'styled-components'
import { Icon } from '..'
import { IconType } from '../Icon/fa.defaults'

export const TabsNavItemView = styled.div`
	background: ${props => props.theme.colors.white.tint(10).val};
	display: flex;
	align-items: center;
	border-radius: 5px;
	color: ${props => props.theme.colors.white.val};
	transition: all ${props => props.theme.times.tranS};
	overflow: hidden;
	position: relative;

	${props => props.theme.media.sdesk} {
		&:hover {
			background: ${props => props.theme.colors.white.tint(20).val};
		}
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 4px;
		background: ${props => props.theme.colors.btn.light(12).sat().val};
		transition: all ${props => props.theme.times.tranS};
		max-height: 0;
	}

	.icon {
		margin: 0;
	}

	span {
		white-space: nowrap;
	}

	&.__has-icon {
		.icon {
			${props => props.theme.media.tablet} {
				margin-right: 0.5em;
			}
		}
		span {
			display: none;
			${props => props.theme.media.tablet} {
				display: block;
			}
		}
	}

	&.__active {
		color: ${props => props.theme.colors.white.val};
		color: ${props => props.theme.colors.btn.bright(3).sat(4).val};
		background: ${props => props.theme.colors.white.tint(20).val};
		&:after {
			max-height: 4px;
		}
	}
`

export type TabsNavItemProps = {
	nav: string | number
	navVerbose?: string
	icon?: IconType
}
export const TabsNavItem: React.FC<
	TabsNavItemProps & {
		isActive: boolean
	}
> = ({ nav, icon, isActive }) => {
	return (
		<TabsNavItemView
			className={`tab-nav-item${isActive ? ' __active' : ''}${icon ? ' __has-icon' : ''}`}
		>
			{icon ? <Icon type={icon} /> : null}
			<span>{nav}</span>
		</TabsNavItemView>
	)
}
