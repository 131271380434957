import React from 'react'
import styled from 'styled-components'
import { useLogForChangeLogQuery } from '../../../gql_generated/graphql'
import { Card } from '../../UI'

export const LogChangeLogView = styled.div`
	${props => props.theme.media.sdesk} {
		max-height: 50vh;
		overflow: auto;
	}
	.change-item {
		margin: 1em 0;
		border-left: none;
		border-right: none;

		& > header {
			background: none;
			border: none;
			padding-left: 6px;
		}
	}
	.val {
		padding: 0.5em;
		color: ${props => props.theme.colors.grey.val};
	}
`

export type LogChangeLogProps = {
	logId: number
}
export const LogChangeLog: React.FC<LogChangeLogProps> = ({ logId }) => {
	const [results] = useLogForChangeLogQuery({ variables: { id: logId } })
	const log = results.data?.log
	const { dataChangeLog } = log || {}
	return (
		<LogChangeLogView>
			{dataChangeLog?.length
				? dataChangeLog.map(({ propertyName, prevVal, newVal }) => (
						<Card
							className='change-item'
							noShadow
							titleText={`'${propertyName}' changed`}
							key={propertyName}
						>
							<div className='from change'>
								<span className='label'>From:</span>
								<blockquote className='val'>{prevVal}</blockquote>
							</div>
							<div className='to change'>
								<span className='label'>To:</span>
								<blockquote className='val'>{newVal}</blockquote>
							</div>
						</Card>
				  ))
				: null}
		</LogChangeLogView>
	)
}
