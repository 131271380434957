const colors = {
	primary: '#FFDB7A',
	secondary: '#bb9d5f',
	aux1: '#a0c9b1',
	aux2: '#3AAB67',
	aux3: '#2CB0B0',

	em: '#7A5403',
	link: '#967d48',
	btn: '#ca9c19',

	err: '#ca2c1b',
	ok: '#84ce0b',
	disabled: '#ccd2ce',

	ivory: '#FFF2D0',
	yellow: '#F1C33E',
	brown: '#9D7521',

	black: '#171a1a',
	midnight: '#353938',
	slate: '#353938',
	bg: '#5c5e5c',
	softSlate: '#68655D',
	grey: '#808782',
	lightGrey: '#e9eae9',
	paleGrey: '#f6f6f6',
	teal: '#0fb788',
}
export default colors

export type ThemeColors = keyof typeof colors
