import React from 'react'
import { useSaleForAcresInfoQuery } from '../../../gql_generated/graphql'
import { KeyVals } from '../../UI'

type SaleAcresProps = {
	saleId: number
}
const SaleAcres: React.FC<SaleAcresProps> = ({ saleId }) => {
	const [results] = useSaleForAcresInfoQuery({ variables: { id: saleId } })
	const activity = results.data?.sale?.activity

	const { srf, ind, min, oil, geo } =
		activity?.reduce(
			(obj, { estateType, acres, estate }) => {
				obj[estateType as 'srf' | 'ind' | 'min' | 'oil' | 'geo'] += acres || estate?.acres || 0
				return obj
			},
			{
				srf: 0,
				ind: 0,
				min: 0,
				oil: 0,
				geo: 0,
			}
		) || {}

	return (
		<KeyVals
			items={[
				activity ? ['Surface', srf, 'acres'] : null,
				activity ? ['Industrial Min.', ind, 'acres'] : null,
				activity ? ['Mineral', min, 'acres'] : null,
				activity ? ['Oil & Gas', oil, 'acres'] : null,
				activity ? ['Geothermal', geo, 'acres'] : null,
			]}
		/>
	)
}
export default SaleAcres
