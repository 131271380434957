import React from 'react'
import styled, { css } from 'styled-components/macro'
import { EstateTypeEnum } from '../../../Types'
import { Block, EM, Number, Textarea, ToggleField, UL } from '../../UI'

/**
*  NOTE: This must be used inside of a form with fields for:
*  {
  customForm: boolean,
  acres: number,
  aliquot: string
}
* */

export const customEstateActivityAcresAliquotFieldsStyles = css``

const CustomEstateActivityAcresAliquotFieldsView = styled.div`
	${customEstateActivityAcresAliquotFieldsStyles}
`

export type CustomEstateActivityAcresAliquotFieldsProps = {
	estateAcres: number
	estateAliquot: string
	useCustom: boolean
	estateType: EstateTypeEnum
}
export const CustomEstateActivityAcresAliquotFields: React.FC<CustomEstateActivityAcresAliquotFieldsProps> = ({
	estateAcres,
	estateAliquot,
	useCustom,
	estateType,
}) => {
	return (
		<CustomEstateActivityAcresAliquotFieldsView>
			<ToggleField name='useCustom' label='Use custom acres/aliquot' />
			{useCustom ? (
				<>
					<EM padBot='0.5em'>Enter custom acres/aliquot:</EM>
					<Number name='acres' label='Acres' />
					<Textarea name='aliquot' label='Aliquot' />
				</>
			) : (
				<Block type='info'>
					Acres and aliquot for this parcel connection will use the acres/aliquot from the{' '}
					{estateType} estate on the parcel:
					<UL>
						<li>Acres: {estateAcres}</li>
						<li>Aliquot: {estateAliquot}</li>
					</UL>
				</Block>
			)}
		</CustomEstateActivityAcresAliquotFieldsView>
	)
}
