import { FieldArray, FieldArrayConfig } from 'formik'
import React from 'react'
import FormFieldWrap, { FormFieldWrapProps } from './Form.field.wrap'

type ArrayFieldProps = FormFieldWrapProps & FieldArrayConfig

const ArrayField: React.FC<ArrayFieldProps> = ({ name, label, ...props }) => {
	return (
		<FormFieldWrap name={name} label={label} type='array'>
			{/* @ts-ignore  TODO: remove this once we upgrade all packages */}
			<FieldArray name={name} {...props} />
		</FormFieldWrap>
	)
}
export default ArrayField
