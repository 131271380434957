import React from 'react'
import { ParcelFilter, ParcelParent } from '../../../gql_generated/document_types'
import { usePaginatedParcelsQuery } from '../../../gql_generated/graphql'
import { Tab, Tabs } from '../../UI'
import { royaltyRelatedParcelColsConfig } from '../../UI/Table.resources/Table.resource.col.configs/Parcel.col.configs'
import { ResourcesTable } from '../../UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../UI/Table.resources/usePaginationParams'
import { GenParcelModal } from '../Parcel/Parcel.gen.modal'

const RoyaltyParcelsTable: React.FC<{
	royaltyId: number
	isTerminated?: boolean
}> = ({ royaltyId, isTerminated }) => {
	const paginationStore = usePaginationParams()

	const [results] = usePaginatedParcelsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: ParcelParent.Royalty,
						parentIds: [royaltyId],
					},
				],
				filterOptions: [
					{
						filter: ParcelFilter.TerminatedRoyaltyActivity,
						vals: [],
						options: {
							isNot: !isTerminated,
						},
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.parcels
	return (
		<ResourcesTable
			dataset={dataset}
			colsConfig={royaltyRelatedParcelColsConfig(royaltyId)}
			paginationStore={paginationStore}
			fetchResults={results}
			modalRenderFunc={data => <GenParcelModal parcelId={data.id} />}
			resourceType='parcel'
		/>
	)
}

type RoyaltyParcelsProps = {
	royaltyId: number
}
const RoyaltyParcels: React.FC<RoyaltyParcelsProps> = ({ royaltyId }) => {
	return (
		<Tabs navSize='sm'>
			<Tab nav='Active'>
				<RoyaltyParcelsTable royaltyId={royaltyId} />
			</Tab>
			<Tab nav='Terminated'>
				<RoyaltyParcelsTable royaltyId={royaltyId} isTerminated />
			</Tab>
		</Tabs>
	)
}
export default React.memo(RoyaltyParcels)
