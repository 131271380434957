import { navigate, RouteComponentProps } from '@reach/router'
// import PropTypes from 'prop-types'
import toNumber from 'lodash/toNumber'
import React, { useEffect } from 'react'
import AcquisitionAcres from '../../Components/common/Acquisition/Acquisition.acres'
import AcquisitionDates from '../../Components/common/Acquisition/Acquisition.dates'
import AcquisitionFinancialInfo from '../../Components/common/Acquisition/Acquisition.finances'
import AcquisitionInfo from '../../Components/common/Acquisition/Acquisition.info'
import Cards from '../../Components/common/CardsColumns'
import CardsSection from '../../Components/common/CardsSection'
import ExhibitForm from '../../Components/common/Exhibit'
import { PageOperation } from '../../Components/common/Operations'
import Page from '../../Components/common/Resources/Resource.page'
import OpCancelAcquisition from '../../Components/Ops/Ops.Acquisitions/Ops.Acquisition.cancel'
import OpAcquisitionCB from '../../Components/Ops/Ops.Acquisitions/Ops.Acquisition.cb'
import OpCloseAcquisition from '../../Components/Ops/Ops.Acquisitions/Ops.Acquisition.close'
import OpsAcquisitionEdit from '../../Components/Ops/Ops.Acquisitions/Ops.Acquisition.edit'
import OpForecloseAcquisition from '../../Components/Ops/Ops.Acquisitions/Ops.Acquisition.foreclose'
import OpReopenAcquisition from '../../Components/Ops/Ops.Acquisitions/Ops.Acquisition.reopen'
import { AddDocToResourceOp } from '../../Components/Ops/Ops.AddDocToResource'
import { Alert, Async, Card, Colorize, H5, Modal, P, useToaster } from '../../Components/UI'
import { SaleStatus, UserRole } from '../../gql_generated/document_types'
import {
	Acquisition,
	useAcquisitionFullQuery,
	useDeleteAcquisitionMutation,
	useUpdateEstateConveyenceMutation,
} from '../../gql_generated/graphql'
import { usePageData, useState } from '../../utils'
import AcquisitionRelatedResources from './Related/Acquisition.related'

interface acquisitionPageProps extends RouteComponentProps {
	id?: number
}

const AcquisitionPage: React.FC<acquisitionPageProps> = props => {
	const id = toNumber(props.id)
	const [results, refetch] = useAcquisitionFullQuery({ variables: { id } })

	const { data } = results

	const [_, deleteAcquisition] = useDeleteAcquisitionMutation()
	const [__, updateEstateConveyence] = useUpdateEstateConveyenceMutation()

	const toast = useToaster()

	const [Op, setOp] = useState<string | null>(null, 'Op')

	const { acquisition } = data || {}

	const { status, escrowId, notes } = acquisition || {}

	const [updatePageInfo] = usePageData({ pageType: 'acquisition' })

	useEffect(() => {
		updatePageInfo({ pageTitle: `Escrow ID: ${escrowId}`, status })
	}, [id, escrowId, updatePageInfo, status])

	const opCb = async () => {
		await refetch()
		setOp(null)
	}

	const canEdit = acquisition?.status !== SaleStatus.Closed

	const operations: PageOperation[] = [
		{
			txt: 'Close Acq.',
			icon: 'convey',
			op: () => setOp('close'),
			hide: acquisition?.status === SaleStatus.Closed || (acquisition?.activity?.length || 0) === 0,
		},
		{
			txt: 'Edit',
			icon: 'edit',
			op: () => setOp('edit'),
			hide: !canEdit,
		},
		{
			txt: 'Carryback',
			icon: 'cb',
			op: () => setOp('cb'),
			hide: !canEdit,
		},
		{
			txt: 'Cancel Acq.',
			icon: 'cancel',
			op: () => setOp('cancel'),
			hide: acquisition?.status !== SaleStatus.Open,
		},
		{
			txt: 'Foreclose',
			icon: 'foreclose',
			op: () => setOp('foreclose'),
			hide: acquisition?.status !== SaleStatus.Closed,
		},
		{
			txt: 'Reopen Acq.',
			icon: 'open',
			op: () => setOp('open'),
			hide: acquisition?.status === SaleStatus.Open || !acquisition?.activity?.length,
		},
		{
			txt: 'Generate Exhibit',
			icon: 'exhibit',
			op: () => setOp('exhibit'),
			hide: !acquisition?.activity?.length,
			allowed: [UserRole.Accountant, UserRole.Officer],
		},
		{
			txt: 'Add Doc',
			icon: 'doc',
			op: () => setOp('doc'),
		},
		{
			txt: 'Delete',
			icon: 'x',
			op: () => setOp('delete'),
		},
	]

	const deleteOp = async (shouldDelete: boolean) => {
		if (shouldDelete) {
			try {
				await deleteAcquisition({ id: toNumber(id) })
				navigate('/mgr/acquisitions')
			} catch (err) {
				toast('Error deleting acquisition', { type: 'err' })
			}
		} else setOp(null)
	}

	const updateEstates = async () => {
		try {
			await updateEstateConveyence({
				parentId: toNumber(id),
				parentType: 'acquisition',
			})
			toast('Estates updated', { type: 'ok' })
		} catch (err) {
			toast('Error updating estates', { type: 'err' })
		}
	}

	return (
		<Page isLoaded={!results.fetching} operations={operations}>
			<Async fetchResults={results}>
				<CardsSection isCollapsable>
					<Cards className='col'>
						<Card>
							<AcquisitionInfo acquisitionId={id} />
						</Card>
						<Card titleText='Acres'>
							<AcquisitionAcres acquisitionId={id} />
						</Card>
						<Card titleText='Dates'>
							<AcquisitionDates acquisitionId={id} />
						</Card>
					</Cards>
					<Cards className='col'>
						<Card titleText='Financial'>
							<AcquisitionFinancialInfo acquisitionId={id} />
						</Card>
					</Cards>
					<Cards className='full-col'>
						{notes ? (
							<Card titleText='Notes'>
								<P asRT>{notes}</P>
							</Card>
						) : null}
					</Cards>
				</CardsSection>

				<AcquisitionRelatedResources acquisitionId={id} />
			</Async>

			<Modal
				show={!!Op && Op !== 'delete' && Op !== 'updateEstates'}
				closeHandler={() => setOp(null)}
			>
				{Op === 'edit' ? (
					<OpsAcquisitionEdit id={id} cb={opCb} />
				) : Op === 'cb' ? (
					<OpAcquisitionCB id={id} cb={opCb} />
				) : Op === 'close' ? (
					<OpCloseAcquisition acquisition={acquisition as Acquisition} cb={opCb} />
				) : Op === 'foreclose' ? (
					<OpForecloseAcquisition acquisition={acquisition as Acquisition} cb={opCb} />
				) : Op === 'cancel' ? (
					<OpCancelAcquisition acquisition={acquisition as Acquisition} cb={opCb} />
				) : Op === 'exhibit' ? (
					<ExhibitForm
						resource={{ acquisition: acquisition as Acquisition }}
						cb={() => setOp(null)}
					/>
				) : Op === 'open' ? (
					<OpReopenAcquisition acquisition={acquisition as Acquisition} cb={opCb} />
				) : Op === 'doc' ? (
					<AddDocToResourceOp parentId={id} parentType='acquisition' cb={opCb} />
				) : null}
			</Modal>
			{Op === 'updateEstates' ? (
				<Alert
					msg={
						<div>
							<H5 mBot>Update conveyence status on all connected parcel estates?</H5>
							<P>
								(This will look at the sales and acquisitions history for each parcel estate on this
								acquisition to calculate conveyence for that estate)
							</P>
						</div>
					}
					cb={update => {
						if (update) updateEstates()
						setOp(null)
					}}
				/>
			) : null}
			{Op === 'delete' ? (
				<Alert
					msg='Are you sure you want to delete this acquisition? (Cannot be undone)'
					cb={deleteOp}
					okBtn='Cancel'
					cancelBtn={
						<Colorize color='err' asBlock>
							Delete Permanently
						</Colorize>
					}
					useCancelAsOp
				/>
			) : null}
		</Page>
	)
}
export default AcquisitionPage
