import React from 'react'
import styled from 'styled-components'
import { TabsNavItemProps } from './Tabs.nav.item'

export const TabView = styled.div``

export type TabProps = TabsNavItemProps & React.HtmlHTMLAttributes<HTMLDivElement> & {}
export const Tab: React.FC<TabProps> = ({ className, children, ...divAttrs }) => {
	return (
		<TabView className={`tab${className ? ` ${className}` : ''}`} {...divAttrs}>
			{children}
		</TabView>
	)
}
