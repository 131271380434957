import React from 'react'
import styled, { css } from 'styled-components/macro'
import { AgreementDetail } from '../../../Components/common/Agreement/Agreement.detail'

export const relatedDocAgreementModalStyles = css``

const RelatedDocAgreementModalView = styled.div`
	${relatedDocAgreementModalStyles}
`

export type RelatedDocAgreementModalProps = {
	agreementId: number
}
export const RelatedDocAgreementModal: React.FC<RelatedDocAgreementModalProps> = ({
	agreementId,
}) => {
	return (
		<RelatedDocAgreementModalView>
			<AgreementDetail agreementId={agreementId} />
		</RelatedDocAgreementModalView>
	)
}
