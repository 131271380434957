import { useField } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { useLeaseTypeListQuery } from '../../../gql_generated/graphql'
import { Async } from '../Async'
import ErrorBlock from '../Error.block'
import { FormFieldWrapProps } from './Form.field.wrap'
import Select, { SelectOptions } from './Select'

export const SelectLeaseTypeView = styled.div``

export type SelectLeaseTypeProps = FormFieldWrapProps & {}
const SelectLeaseType: React.FC<SelectLeaseTypeProps> = ({ label, ...props }) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [{ name }, { touched, error }, { setValue, setTouched }] = useField(props)
	const [results] = useLeaseTypeListQuery()
	const options: SelectOptions[] | undefined = results.data?.leaseTypes?.map(({ name }) => ({
		txt: name,
		val: name,
	}))

	return (
		<SelectLeaseTypeView>
			<Async fetchResults={results}>
				{options?.length ? (
					<Select
						label={label}
						name={name}
						onChange={e => {
							if (!touched) setTouched(true)
							if (e.currentTarget.value) setValue(e.currentTarget.value)
						}}
						options={[
							{
								txt: 'Select type...',
								val: '',
							},
							...options,
						]}
					/>
				) : null}
			</Async>
			{error ? <ErrorBlock>{error}</ErrorBlock> : null}
		</SelectLeaseTypeView>
	)
}
export default SelectLeaseType
