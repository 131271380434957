import React, { createContext, ReactNode, useContext } from 'react'
import get from 'lodash/get'
// import isPlainObject from 'lodash/isPlainObject'
import { useState } from '../../../utils'
import Alert, { AlertProps } from './Alert'

const AlertContext = createContext<Array<(options: AlertProps | ReactNode) => void>>([])

// type Continue = () => void
// type CB = (selection: boolean, setWaiting: Continue) => void

type AlertProviderProps = {}

export const useAlert = () => useContext(AlertContext)
// export const useAlert = (alert?: AlertProps | ReactNode) => {
// 	const [isWaiting, setWaiting] = useState(!!alert, 'isWaiting')
// 	const [showAlert] = useContext(AlertContext)

// 	if (alert) {
// 		showAlert({
// 			...(isPlainObject(alert) ? alert : {}),
// 			msg: get(alert, 'msg', alert),
// 			cb: selection => {
// 				const cb: CB | null = get(alert, 'cb', null)
// 				if (cb) cb(selection, () => setWaiting(false))
// 				else setWaiting(false)
// 			},
// 		})
// 	}

// 	return [showAlert, isWaiting]
// }

const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
	const [alertOpts, setAlert] = useState<AlertProps | ReactNode | null>(null, 'alertOpts')
	const Provider = AlertContext.Provider

	const msg = get(alertOpts, 'msg', alertOpts)
	const cb = get(alertOpts, 'cb')
	const okBtn = get(alertOpts, 'okBtn')
	const cancelBtn = get(alertOpts, 'cancelBtn')

	const handler = (selection: boolean) => {
		setAlert(null)
		if (cb) cb(selection)
	}

	return (
		<Provider value={[setAlert]}>
			{children}
			{alertOpts ? <Alert msg={msg} cb={handler} okBtn={okBtn} cancelBtn={cancelBtn} /> : null}
		</Provider>
	)
}
export default AlertProvider
