import React from 'react'
import styled, { css } from 'styled-components/macro'
import { EstateActivity, useParcelForActivityEditQuery } from '../../../gql_generated/graphql'
import { EstateTypeEnum, ParcelsParentType } from '../../../Types'
import { useState } from '../../../utils'
import { uniq } from '../../../utils/lodash.utils'
import { Async } from '../../UI'
import { EditEstateActivitySelectEstateType } from './Activity.edit.estateType.select'
import { EditEstateActivityForm } from './Activity.edit.form'

export const editEstateActivityStyles = css`
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.msg {
		padding: 2em 0;
	}
	.btns {
		width: 100%;
		justify-content: center;
		margin-top: 3em;
		.btn {
			&:last-child {
				margin-left: 1em;
			}
		}
	}
`

const EditEstateActivityView = styled.div`
	${editEstateActivityStyles}
`

export type EditEstateActivityProps = {
	parcelId: number
	parentId: number
	parentType: ParcelsParentType
	cb?: () => void
}
export const EditEstateActivity: React.FC<EditEstateActivityProps> = ({
	parcelId,
	parentId,
	parentType,
	cb,
}) => {
	const [results] = useParcelForActivityEditQuery({ variables: { id: parcelId } })

	const [selectedEstateType, setEstateType] = useState<EstateTypeEnum | null>(
		null,
		'selectedEstateType'
	)

	const estateActivity = results.data?.parcel?.activity?.filter(a => {
		const id = a[`${parentType}Id` as 'saleId' | 'leaseId' | 'easementId' | 'agreementId']
		return id === parentId
	})

	const estateTypes = uniq(estateActivity?.map(({ estateType }) => estateType))

	const formData =
		estateTypes?.length === 1 && estateActivity?.length
			? estateActivity[0]
			: estateActivity?.find(a => a.estateType === selectedEstateType)

	return (
		<EditEstateActivityView>
			<Async fetchResults={results}>
				{formData ? (
					<EditEstateActivityForm
						parentType={parentType}
						initialData={formData as EstateActivity}
						parcelId={parcelId}
						cb={cb}
					/>
				) : estateTypes?.length > 1 ? (
					<EditEstateActivitySelectEstateType
						estateTypes={estateTypes as EstateTypeEnum[]}
						onSelect={setEstateType}
					/>
				) : null}
			</Async>
		</EditEstateActivityView>
	)
}
