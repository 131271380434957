import styled, { css } from 'styled-components'
import { LeaseTermsExpirationFilterPeriodOption } from '../../../../Types'
import { LeaseFilter, usePaginatedLeasesQuery } from '../../../../gql_generated/graphql'
import { expiredLeaseWidgetColsConfig } from '../../../UI/Table.resources/Table.resource.col.configs/Lease.col.configs'
import { ResourcesTable } from '../../../UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../UI/Table.resources/usePaginationParams'
import { GenLeaseModal } from '../../../common/Lease/Lease.gen.modal'

const ExpiredLeaseDashWidgetWithContClauseStyles = css``

const ExpiredLeaseDashWidgetWithContClauseView = styled.div`
	${ExpiredLeaseDashWidgetWithContClauseStyles}
`

type ExpiredLeaseDashWidgetWithContClauseProps = {
	className?: string
	period: LeaseTermsExpirationFilterPeriodOption
}
export const ExpiredLeaseDashWidgetWithContClause = ({
	className,
	period,
}: ExpiredLeaseDashWidgetWithContClauseProps): JSX.Element => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedLeasesQuery({
		variables: {
			params: {
				...paginationStore.params,
				filterOptions: [
					{
						filter: LeaseFilter.TermExpiration,
						vals: [period, 'WITH CONT CLAUSE'],
					},
				],
			},
		},
	})
	const data = fetchResults.data?.page.leases
	return (
		<ExpiredLeaseDashWidgetWithContClauseView className={`${className ? ` className` : ''}`}>
			<ResourcesTable
				dataset={data}
				colsConfig={expiredLeaseWidgetColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={({ id }) => <GenLeaseModal leaseId={id} />}
				resourceType='lease'
				displayOptions={{
					hideTotalQty: true,
					hidePagination: true,
					suppressSorting: true,
				}}
				limitSelectConfig={{
					hideLimitSelect: true,
				}}
			/>
		</ExpiredLeaseDashWidgetWithContClauseView>
	)
}
