import React from 'react'
import styled, { css } from 'styled-components/macro'
import Protect from '../../../Components/common/Protect'
import { AddContactToOrgBtn } from '../../../Components/Ops/Ops.Orgs/Ops.Org.contact.add.btn'
import { EditContact } from '../../../Components/Ops/Ops.Orgs/Ops.Org.contact.edit'
import { PrimaryContactToggle } from '../../../Components/Ops/Ops.Orgs/Ops.Org.contact.togglePrimary'
import { LeftBar, Modal, useModalCtrl } from '../../../Components/UI'
import {
	contactEmailColConfig,
	contactFullNameColConfig,
	contactPhoneMobileColConfig,
} from '../../../Components/UI/Table.resources/Table.resource.col.configs/Contact.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { UserRole } from '../../../gql_generated/document_types'
import { usePaginatedContactsQuery } from '../../../gql_generated/graphql'
import { nnrMgrs, useProtect } from '../../../utils'
import { RelatedOrgContactModal } from '../Related.Modals/Org.related.contact.modal'

export const relatedOrgContactStyles = css``

const RelatedOrgContactsView = styled.div`
	${relatedOrgContactStyles}
`

export type RelatedOrgContactsProps = {
	orgId: number
}

export const RelatedOrgContacts: React.FC<RelatedOrgContactsProps> = ({ orgId }) => {
	const paginationStore = usePaginationParams()

	const [results] = usePaginatedContactsQuery({
		variables: {
			params: {
				...paginationStore.params,
				orgId,
			},
		},
	})

	const dataset = results.data?.page?.contacts

	const [[showEditModal, openEditModal, closeEditModal, contactId]] = useModalCtrl<number>()

	const [canEdit] = useProtect({ allowed: [...nnrMgrs, UserRole.Accountant, UserRole.Officer] })

	return (
		<RelatedOrgContactsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={[
					contactFullNameColConfig,
					contactEmailColConfig,
					contactPhoneMobileColConfig,
					{
						label: 'Make Primary?',
						isRowTrigger: false,
						Component: ({ id }) => <PrimaryContactToggle contactId={id} orgId={orgId} />,
					},
				]}
				fetchResults={results}
				paginationStore={paginationStore}
				includeSearch
				modalRenderFunc={data => <RelatedOrgContactModal contactId={data.id} />}
				rowOps={
					canEdit
						? [
								{
									type: 'edit',
									op: contact => openEditModal(contact.id),
								},
								// TODO: add contact deletion
						  ]
						: undefined
				}
			>
				<Protect allowed={[...nnrMgrs, UserRole.Accountant, UserRole.Officer]}>
					<LeftBar>
						<AddContactToOrgBtn orgId={orgId} />
					</LeftBar>
				</Protect>
			</ResourcesTable>
			<Modal show={showEditModal} closeHandler={closeEditModal} size='med' titleText='Edit Contact'>
				{contactId ? <EditContact orgId={orgId} contactId={contactId} cb={closeEditModal} /> : null}
			</Modal>
		</RelatedOrgContactsView>
	)
}
