import React from 'react'
import styled, { css } from 'styled-components/macro'
import { ErrorBlockErr } from '../Error.block/Error.block'
import { TableBodyRow } from './Table.body.row/Table.body.row'
import { TableBodyErrorRow } from './Table.body.row/Table.body.row.Error'
import { TableBodyLoaderRow } from './Table.body.row/Table.body.row.Loader'
import { TableRowConfig, TableRowHandlers, TableRowOpConfig } from './Table.types'

export const tableBodyStyles = css``

const TableBodyView = styled.tbody`
	${tableBodyStyles}
`

export type TableBodyProps<T> = TableRowHandlers<T> &
	Pick<TableRowConfig<T>, 'setLastActiveIdx' | 'selectType'> & {
		colsQty: number
		rows?: TableRowConfig<T>[]
		error?: ErrorBlockErr
		loading?: boolean
		rowsQty: number
		rowOps?: TableRowOpConfig<T>[]
		lastActiveIdx?: number
		suppressRowRender?: boolean
	}
export const TableBody: React.FC<TableBodyProps<any>> = ({
	rows,
	error,
	colsQty,
	loading,
	rowsQty,
	lastActiveIdx,
	selectType,
	suppressRowRender,
	...rowHandlers
}) => {
	return (
		<TableBodyView>
			{suppressRowRender ? null : error ? (
				<TableBodyErrorRow error={error} colsQty={colsQty} />
			) : loading ? (
				Array(rowsQty)
					.fill('')
					.map((_, i) => <TableBodyLoaderRow key={i} colsQty={colsQty} />)
			) : rows?.length ? (
				rows.map((row, idx) => (
					<TableBodyRow
						key={row.id}
						selectType={selectType}
						{...row}
						{...rowHandlers}
						isLastActive={lastActiveIdx === idx}
					/>
				))
			) : null}
		</TableBodyView>
	)
}
