import React from 'react'
import styled, { css } from 'styled-components/macro'
import { DocDetail } from '../../../Components/common/Doc/Doc.detail'

export const relatedParcelDocModalStyles = css``

const RelatedParcelDocModalView = styled.div`
	${relatedParcelDocModalStyles}
`

export type RelatedParcelDocModalProps = {
	docId: number
}
export const RelatedParcelDocModal: React.FC<RelatedParcelDocModalProps> = ({ docId }) => {
	return (
		<RelatedParcelDocModalView>
			<DocDetail docId={docId} />
		</RelatedParcelDocModalView>
	)
}
