/**
 *  Uses https://github.com/cchanxzy/react-currency-input-field
 * */
import React from 'react'
import CurrencyInput from 'react-currency-input-field'

import { useField } from 'formik'
import styled from 'styled-components'
import { toNumber } from '../../../utils/lodash.utils'
import FormFieldWrap, { FormFieldWrapProps } from './Form.field.wrap'
import { InputProps } from './Input'

const View = styled.div``

type PriceFieldProps = InputProps &
	FormFieldWrapProps & {
		decimals?: number
	}

const PriceField: React.FC<PriceFieldProps> = ({
	label,
	name,
	isReq,
	helperTxt,
	decimals,
	...props
}) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [{ value }, { touched }, { setValue, setTouched }] = useField({ name, ...props })
	return (
		<FormFieldWrap type='price' label={label} name={name} isReq={isReq} helperTxt={helperTxt}>
			<View>
				<CurrencyInput
					prefix='$'
					name={name}
					decimalScale={decimals ? undefined : 2}
					decimalsLimit={decimals || undefined}
					allowDecimals
					defaultValue={value}
					onBlur={() => {
						if (!touched) setTouched(true)
					}}
					onValueChange={value => {
						setValue(toNumber(value) || 0)
					}}
				/>
			</View>
		</FormFieldWrap>
	)
}
export default PriceField
