import React, { useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import {
	useContactForInfoQuery,
	useTogglePrimaryContactMutation,
} from '../../../gql_generated/graphql'
import { useStoreState } from '../../../store'
import { useState } from '../../../utils'
import { ToggleRaw } from '../../UI/Form.Fields/Toggle.field'

export const primaryContactToggleStyles = css``

const PrimaryContactToggleView = styled.div`
	${primaryContactToggleStyles}
`

export type PrimaryContactToggleProps = {
	orgId: number
	contactId: number
}
export const PrimaryContactToggle: React.FC<PrimaryContactToggleProps> = ({ orgId, contactId }) => {
	const [isOn, setOn] = useState(false)
	const [_, togglePrimary] = useTogglePrimaryContactMutation()
	const me = useStoreState(store => store.user.me)

	const [results] = useContactForInfoQuery({ variables: { id: contactId } })

	const { primary } = results.data?.contact || {}

	useEffect(() => {
		if (me && primary) {
			if (primary?.includes(me.id)) setOn(true)
			else setOn(false)
		}
	}, [primary, me])

	return (
		<PrimaryContactToggleView>
			<ToggleRaw
				name={`toggle-primary-${orgId}-${contactId}`}
				onClick={() => togglePrimary({ contactId, orgId })}
				isOn={isOn}
				isSmall
				isSimple
			/>
		</PrimaryContactToggleView>
	)
}
