import React from 'react'
import styled from 'styled-components'
import { useEstateFullQuery } from '../../../gql_generated/graphql'
import { Async, Card, Icon, KeyVals } from '../../UI'
import { IconType } from '../../UI/Icon/fa.defaults'

const EstateDetailView = styled.div`
	.cols {
		min-height: 350px;
		grid-template-columns: 350px 1fr;
	}
	.estate-ico {
		position: absolute;
		height: 100%;
		width: 100%;
		color: ${props => props.theme.colors.grey.light(43).val};
		object-fit: cover;
		object-position: center;
	}
`

type EstateDetailProps = {
	estateId: number
}

export const EstateDetail: React.FC<EstateDetailProps> = ({ estateId }) => {
	const [results] = useEstateFullQuery({ variables: { id: estateId } })
	const { acres, aliquot, type, typeFull, owner, status } = results.data?.estate || {}

	const ownerName = status !== 'Available' ? status : owner?.name || 'Unknown'

	return (
		<EstateDetailView>
			<Async fetchResults={results}>
				<div className='cols'>
					<div className='col'>
						<Card noShadow className='info' mBot>
							<KeyVals
								items={[
									['Estate', typeFull, 'upper'],
									['Status', status, 'status'],
									['Acres', acres, 'acres'],
									['Owner', ownerName],
								]}
							/>
						</Card>
						<Card noShadow titleText='Aliquot'>
							{aliquot}
						</Card>
					</div>
					<div className='col'>
						<Icon className='estate-ico' type={type as IconType} />
					</div>
				</div>
			</Async>
		</EstateDetailView>
	)
}
