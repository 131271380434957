import { useTheme } from 'styled-components'
import { ThemeColors } from '../theme/colors'

export type ColorOption = ('light' | 'dark' | ThemeColors) | string
export const useColor = (color?: ColorOption): string => {
	const { colors } = useTheme()
	const ecloColor =
		color === 'light' ? colors.white : color === 'dark' ? colors.black : colors[color || 'black']

	return color ? ecloColor?.val || color : 'inherit'
}
