import styled from 'styled-components'

const AddEditRoyaltyOnResourceView = styled.div`
	.tabs {
		${props => props.theme.media.sdesk} {
			width: 80vw;
			min-width: 700px;
			max-width: 95vw;
		}
	}
	.cols {
		grid-gap: 1em;
		${props => props.theme.media.tablet} {
			grid-template-columns: 1fr;
		}
		${props => props.theme.media.sdesk} {
			grid-template-columns: repeat(2, 1fr);
		}
	}
	.select-parcels {
		${props => props.theme.media.tablet} {
			min-width: 600px;
		}
	}
	.block {
		padding: 1em;
	}
	.preselected-estate-type {
		border: 1px solid ${props => props.theme.colors.grey.val};
		padding: 0.5em 1em;
		border-radius: 5px;
		margin-bottom: 0.5em;
		label {
			display: block;
			margin-bottom: 0.5em;
		}
	}
`
export default AddEditRoyaltyOnResourceView
