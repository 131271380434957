import { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { PropertyUpdateTaxInput } from '../../../gql_generated/document_types'
import { usePropertyTaxQuery, useUpdatePropertyTaxMutation } from '../../../gql_generated/graphql'
import { priceToNumber } from '../../../utils'
import { Async, Form, SubmitBtn, toastit } from '../../UI'
import { PropertyTaxFields, propertyTaxYupSchema } from './Ops.Parcel.propertyTax.fields'

export const editParcelPropertyTaxOpStyles = css``

const EditPropertyTaxOpView = styled.div`
	${editParcelPropertyTaxOpStyles}
`

export type EditPropertyTaxOpProps = {
	propertyTaxId: number
	cb?: () => void
}
export const EditPropertyTaxOp = ({ propertyTaxId, cb }: EditPropertyTaxOpProps): JSX.Element => {
	const [results] = usePropertyTaxQuery({ variables: { id: propertyTaxId } })

	const data = results.data?.tax

	const initialVals = {
		id: data?.id,
		parcelId: data?.parcelId,
		amount: `${data?.amount}`,
		year: `${data?.year}`,
	}

	const [mutRes, mutate] = useUpdatePropertyTaxMutation()
	const handleSubmit = useCallback(
		async (vals: typeof initialVals) => {
			try {
				const res = await mutate({
					data: {
						...vals,
						amount: priceToNumber(vals.amount),
						year: parseInt(vals.year),
					} as PropertyUpdateTaxInput,
				})
				if (res.error) {
					toastit.err('Error updating tax')
				} else {
					toastit.ok('Saved!')
					cb && cb()
				}
			} catch (err) {}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[results.data]
	)

	return (
		<EditPropertyTaxOpView>
			<Async fetchResults={results}>
				{data ? (
					<Form
						initialValues={initialVals}
						onSubmit={handleSubmit}
						submitErr={mutRes.error}
						validationSchema={propertyTaxYupSchema(true)}
					>
						{() => (
							<>
								<PropertyTaxFields isUpdate />
								<SubmitBtn />
							</>
						)}
					</Form>
				) : null}
			</Async>
		</EditPropertyTaxOpView>
	)
}
