import React from 'react'
import styled from 'styled-components'
import { parseStatus } from '../../utils'
import { Icon } from '../UI'
import { IconType } from '../UI/Icon/fa.defaults'

const StatusView = styled.div`
	display: flex;
	align-items: center;
	color: ${props => props.theme.colors.grey.val};
	.icon {
		height: 1.6em;
	}
`

type StatusProps = { status?: string; hideIcon?: boolean; className?: string }

const Status: React.FC<StatusProps> = ({ status, className, hideIcon }) => {
	const { txt, icon } = parseStatus(status)

	return status ? (
		<StatusView
			className={`status status-${status}${className ? ` ${className}` : ''}`}
			title={txt}
		>
			{hideIcon || !icon ? null : <Icon type={icon as IconType} padRight />}
			{txt}
		</StatusView>
	) : null
}
export default Status
