import React from 'react'
import styled, { css } from 'styled-components/macro'
import { useOrgFullQuery } from '../../../gql_generated/graphql'
import { AddressType } from '../../../Types'
import { Address, Card, HR, P } from '../../UI'
import { OrgContactsList } from './Org.contacts'
import OrgInfo from './Org.info'

export const orgDetailStyles = css`
	.cols {
		display: block;
		${props => props.theme.media.tablet} {
			display: flex;
			& > .col {
				flex-grow: 1;
				width: 50%;
			}
		}

		.notes {
			margin-top: 1em;
			${props => props.theme.media.tablet} {
				margin: 0;
			}
		}
	}
`

const OrgDetailView = styled.div`
	${orgDetailStyles}
`

export type OrgDetailProps = {
	orgId: number
}
export const OrgDetail: React.FC<OrgDetailProps> = ({ orgId }) => {
	const [results] = useOrgFullQuery({ variables: { id: orgId } })
	const org = results.data?.org

	const { notes, contacts: _, name, ...address } = org || {}
	return (
		<OrgDetailView>
			<section className='cols'>
				<div className='col'>
					<Card mBot noShadow titleText={name}>
						<div className='org-info'>
							<OrgInfo orgId={orgId} />
						</div>
					</Card>
					<Card noShadow titleText='Address'>
						<Address address={address as AddressType} />
					</Card>
				</div>
				<div className='col notes'>
					<Card noShadow titleText='Notes'>
						<P asRT>{notes}</P>
					</Card>
				</div>
			</section>
			<HR />
			<section className='contacts'>
				<OrgContactsList orgId={orgId} />
			</section>
		</OrgDetailView>
	)
}
