import React from 'react'
import styled, { css } from 'styled-components/macro'
import { DocDetail } from '../../../Components/common/Doc/Doc.detail'

export const relatedSaleDocModalStyles = css``

const RelatedSaleDocModalView = styled.div`
	${relatedSaleDocModalStyles}
`

export type RelatedSaleDocModalProps = {
	docId: number
}
export const RelatedSaleDocModal: React.FC<RelatedSaleDocModalProps> = ({ docId }) => {
	return (
		<RelatedSaleDocModalView>
			<DocDetail docId={docId} />
		</RelatedSaleDocModalView>
	)
}
