import React from 'react'
import styled, { css } from 'styled-components/macro'
import { LogDetail } from '../../../Components/common/Log/Log.detail'

export const relatedParcelLogModalStyles = css``

const RelatedParcelLogModalView = styled.div`
	${relatedParcelLogModalStyles}
`

export type RelatedParcelLogModalProps = {
	logId: number
}
export const RelatedParcelLogModal: React.FC<RelatedParcelLogModalProps> = ({ logId }) => {
	return (
		<RelatedParcelLogModalView>
			<LogDetail logId={logId} />
		</RelatedParcelLogModalView>
	)
}
