import React from 'react'
import { useSaleForFinancialInfoQuery } from '../../../gql_generated/graphql'
import { round, sumBy } from '../../../utils/lodash.utils'
import { Colorize, KeyVals } from '../../UI'

type SaleFinancialInfoProps = {
	saleId: number
}

// const surfaceEstates = ['srf', 'ind']
// const subSurfaceEstates = ['min', 'oil', 'geo']

// const matches = (estateType: string, type: 'srf' | 'sub') =>
// 	(type === 'srf' && surfaceEstates.includes(estateType)) ||
// 	(type === 'sub' && subSurfaceEstates.includes(estateType))

const SaleFinancialInfo: React.FC<SaleFinancialInfoProps> = ({ saleId }) => {
	const [results] = useSaleForFinancialInfoQuery({ variables: { id: saleId } })
	const sale = results.data?.sale
	const {
		price,
		brokerRate,
		deposit,
		isDepositTowardPurchase,
		cbNote,
		cbInterest,
		cbYears,
		activity,
	} = sale || {}

	// const estateTypes = uniq(activity?.map(({ estateType }) => estateType))
	// const srfEstates = intersection(estateTypes, surfaceEstates)
	// const subSrfEstates = intersection(estateTypes, subSurfaceEstates)
	// const isSrf = srfEstates.length && !subSrfEstates.length
	// const isSubSrf = !srfEstates.length && subSrfEstates.length

	// console.log('calculating activity: ', activity)
	// console.log('estateTypes: ', estateTypes)
	// console.log('srfEstates: ', srfEstates)
	// console.log('subEstates: ', subSrfEstates)
	// console.log('isSrf: ', isSrf)
	// console.log('isSubSrf: ', isSubSrf)

	const acres = sumBy(activity, ({ acres, estateType, estate }) => {
		// if (
		// 	(!isSrf && !isSubSrf) ||
		// 	(isSrf && matches(estateType, 'srf')) ||
		// 	(isSubSrf && matches(estateType, 'sub'))
		// )
		return estateType === 'srf' ? acres || estate.acres || 0 : 0
	})

	const pricePerAcre = acres && price ? price / acres : null

	let downPaymentTotal = price && cbNote ? price - cbNote : 0

	// if (downPaymentTotal && isDepositTowardPurchase && deposit) downPaymentTotal -= deposit

	return (
		<KeyVals
			items={[
				['Sale Price', price, 'currency'],
				[
					'Price per Acre',
					pricePerAcre ? (
						round(pricePerAcre, 2)
					) : !price ? (
						<Colorize color='err'>Add sale price to calculate...</Colorize>
					) : (
						<Colorize color='err'>Unable to calculate price...</Colorize>
					),
					pricePerAcre ? 'currency' : undefined,
				],
				['Broker Rate', `${brokerRate || 0}%`],
				['Deposit', deposit, 'currency'],
				deposit ? ['Towards Purchase?', isDepositTowardPurchase, 'boolean'] : null,
				['CB Note', cbNote, 'currency'],
				cbNote ? ['CB Interest', `${cbInterest}%`] : null,
				cbNote ? ['CB Years', cbYears] : null,
				cbNote ? ['Down Payment', downPaymentTotal, 'currency'] : null,
			]}
		/>
	)
}
export default SaleFinancialInfo
