import React from 'react'
import styled, { css } from 'styled-components/macro'
import { useParcelFullQuery } from '../../../gql_generated/graphql'
import { Async, Card, HR, KeyVals, P } from '../../UI'
import ParcelEstates from './Parcel.estates'
import ParcelInfo from './Parcel.info'
import ParcelOGData from './Parcel.og'

export const parcelDetailStyles = css`
	section.cols {
		display: block;

		& > .col {
			margin-bottom: 1em;
		}

		${props => props.theme.media.tablet} {
			display: flex;
			& > .col {
				flex-grow: 1;
				width: 50%;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
`

const ParcelDetailView = styled.div`
	${parcelDetailStyles}
`

export type ParcelDetailProps = {
	parcelId: number
}
export const ParcelDetail: React.FC<ParcelDetailProps> = ({ parcelId }) => {
	const [results] = useParcelFullQuery({
		variables: { id: parcelId },
	})

	const parcel = results.data?.parcel
	const { aliquotNotes, notes, srf } = parcel || {}

	return (
		<ParcelDetailView>
			<Async fetchResults={results}>
				{parcel ? (
					<>
						<section className='cols'>
							<div className='col'>
								<Card mBot noShadow titleText='Parcel Info'>
									<ParcelInfo parcelId={parcelId} />
								</Card>
								<Card mBot noShadow titleText='Estates on this parcel'>
									<ParcelEstates parcelId={parcelId} />
								</Card>
								<Card noShadow titleText='Financial'>
									<KeyVals items={[['Annual Lease Revenue', 'TODO']]} />
								</Card>
							</div>
							<div className='col'>
								<Card mBot noShadow titleText='Surface Aliquot'>
									<P asRT>{srf?.aliquot}</P>
								</Card>
								<Card mBot noShadow titleText='Aliquot Notes'>
									<P asRT>{aliquotNotes}</P>
								</Card>
								<Card noShadow titleText='Notes'>
									<P asRT>{notes}</P>
								</Card>
							</div>
						</section>
						<HR />
						<section>
							<Card noShadow titleText='Original (OG) data from Xogenous'>
								<ParcelOGData parcelId={parcelId} />
							</Card>
						</section>
					</>
				) : null}
			</Async>
		</ParcelDetailView>
	)
}
