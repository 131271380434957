import { useEffect, useRef, useState } from 'react'
import { isNumber, random, round } from '../../../../utils/lodash.utils'

export type UseProgressOptions = {
	speed?: 'short' | 'med' | 'long'
	percentOverride?: number
}

export const useProgress = (options?: UseProgressOptions) => {
	const { speed = 'long', percentOverride } = options || {}

	const [percent, setPercent] = useState(10)
	const cancel = useRef(false)

	useEffect(() => {
		if (isNumber(percentOverride) && percentOverride > percent)
			// progress bar increments are being handled externally
			setPercent(round(percentOverride))
	}, [percentOverride, percent])

	useEffect(() => {
		const increment = {
			min: speed === 'short' ? 20 : speed === 'med' ? 75 : 200,
			max: speed === 'short' ? 200 : speed === 'med' ? 500 : 2000,
		}
		if (!isNumber(percentOverride) && percent < 98) {
			setTimeout(() => {
				// increment bar by amount between current percentage and current percentage + 5
				if (!cancel.current)
					setPercent(state => {
						const max = state < 98 ? state + (100 - state) * 0.25 : 99
						const min = state < 98 ? state + 0.2 : state + 0.01
						const newPercent = random(min, max)
						// console.log('min: ', state + 0.2, ' | max: ', max)
						// console.log('new percent: ', newPercent)
						return newPercent
					})

				// perform increments at random time intervals between min and max values
			}, random(increment.min, increment.max, false))
		}
	}, [percent, speed, percentOverride])

	useEffect(() => {
		return () => {
			cancel.current = true
		}
	}, [])

	return percent
}
