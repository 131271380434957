import { Role, UserRole } from '../gql_generated/document_types'

const userRoles: Pick<Role, 'id' | 'name' | 'description'>[] = [
	{
		name: UserRole.Anon,
		description: 'Anonymous User',
		id: 1,
	},
	{
		name: UserRole.SysAdmin,
		description: 'System Administrator',
		id: 2,
	},
	{
		name: UserRole.Officer,
		description: 'Officer',
		id: 3,
	},
	{
		name: UserRole.Landman,
		description: 'Land Manager',
		id: 4,
	},
	{
		name: UserRole.Accountant,
		description: 'Accountant',
		id: 5,
	},
	{
		name: UserRole.Shareholder,
		description: 'Shareholder',
		id: 6,
	},
]
export default userRoles
