import React from 'react'
import styled from 'styled-components'
import { useUnAssignEstateActivitiesMutation } from '../../../../gql_generated/graphql'
import { useState } from '../../../../utils'
import { Btn, EM, ErrorBlock, P } from '../../../UI'
import { ResourceTableGlobalOpComponentOptions } from '../Table.resources'

const UnassignRelatedParcelsOpView = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.msg {
		padding: 2em 0;
	}
	.btns {
		width: 100%;
		justify-content: center;
		margin-top: 3em;
		.btn {
			&:last-child {
				margin-left: 1em;
			}
		}
	}
`

type UnassignRelatedParcelsOpProps = ResourceTableGlobalOpComponentOptions

export const UnassignRelatedParcelsOp: React.FC<UnassignRelatedParcelsOpProps> = ({
	parentId,
	parentType,
	isTotalQtySelected,
	selectedRowIds,
	deselectedRowIds,
	closeModal,
}) => {
	const [error, setError] = useState<any | null>(null, 'error')
	const [_, unAssignActivity] = useUnAssignEstateActivitiesMutation()

	const unassignHandler = async () => {
		try {
			const res = await unAssignActivity({
				parentId,
				parentType,
				parcelIds: selectedRowIds,
				deselectedParcelIds: deselectedRowIds,
				isTotalQtySelected,
			})

			if (res?.error) throw res.error

			closeModal()
		} catch (err: any) {
			console.error(err)
			setError(err)
		}
	}

	return (
		<UnassignRelatedParcelsOpView>
			<div className='msg'>
				<P>Are you sure you want to un-assign these parcel estates?</P>
				<EM>NOTE: Ownership of this {parentType} on these estates will transfer back to NNR/NNL</EM>
			</div>
			{error ? (
				<ErrorBlock error={error} />
			) : (
				<div className='flex btns'>
					<Btn className='cta' onClick={unassignHandler}>
						Unassign Parcels
					</Btn>
					<Btn onClick={closeModal}>Cancel</Btn>
				</div>
			)}
		</UnassignRelatedParcelsOpView>
	)
}
