import React from 'react'
import styled, { css } from 'styled-components/macro'
import { EasementDetail } from '../../../Components/common/Easement/Easement.detail'

export const relatedOrgEasementModalStyles = css``

const RelatedOrgEasementModalView = styled.div`
	${relatedOrgEasementModalStyles}
`

export type RelatedOrgEasementModalProps = {
	easementId: number
}
export const RelatedOrgEasementModal: React.FC<RelatedOrgEasementModalProps> = ({ easementId }) => {
	return (
		<RelatedOrgEasementModalView>
			<EasementDetail easementId={easementId} />
		</RelatedOrgEasementModalView>
	)
}
