import React from 'react'
import styled, { css } from 'styled-components/macro'
import { AddBtn, Modal } from '../../../../../Components/UI'
import { useModalCtrl } from '../../../../../Components/UI/Modal'
import { AddParcelsToDoc } from './Doc.add.parcels'

export const addParcelsToDocBtnStyles = css``

const AddParcelsToDocBtnView = styled.div`
	${addParcelsToDocBtnStyles}
`

export type AddParcelsToDocBtnProps = {
	docId: number
}
export const AddParcelsToDocBtn: React.FC<AddParcelsToDocBtnProps> = ({ docId }) => {
	const [[showModal, openModal, closeModal]] = useModalCtrl()
	return (
		<AddParcelsToDocBtnView>
			<AddBtn className='cta' onClick={openModal}>
				Add Parcels
			</AddBtn>
			<Modal show={showModal} closeHandler={closeModal} size='large'>
				<AddParcelsToDoc docId={docId} cb={closeModal} />
			</Modal>
		</AddParcelsToDocBtnView>
	)
}
