import styled, { css } from 'styled-components'
import { round } from '../../../utils/lodash.utils'
import { ProgressBar } from '../Loaders'
import { ProgressBarProps } from '../Loaders/ProgressBar/ProgressBar'
import { useProgress } from '../Loaders/ProgressBar/useProgress'

export const uploaderProgressStyles = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	height: 300px;
	width: 300px;
	border-radius: 50%;
	background: ${props => props.theme.colors.grey.tint(10).val};

	.digits {
		font-size: 80px;
		text-align: center;
		margin-bottom: 10px;
		color: ${props => props.theme.colors.grey.val};
	}

	.progress-bar {
		max-width: 200px;
	}
`

const UploaderProgressView = styled.div`
	${uploaderProgressStyles}
`

export const UploaderProgress = ({ isDone }: Pick<ProgressBarProps, 'isDone'>): JSX.Element => {
	const percent = useProgress()

	return (
		<UploaderProgressView>
			<div className='digits'>{round(percent)}%</div>
			<ProgressBar percentOverride={percent} isDone={isDone} />
		</UploaderProgressView>
	)
}
