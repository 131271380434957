import Bugsnag from '@bugsnag/js'
import { useState } from '.'
import { ErrorBlock } from '../Components/UI'
import { useToaster } from '../Components/UI/Toaster'
import { parseErrMsg } from './utils'

type ErrHandlerOptions = {
	showToast?: boolean
	printLog?: boolean
	trackErr?: boolean
	context?: string
}

type ErrorType = Error | any | string

type ErrHandler = (err: ErrorType, opts?: ErrHandlerOptions) => void

const useErrHandler = (options?: ErrHandlerOptions): Array<ErrHandler> => {
	const toast = useToaster()
	const [err, setErr] = useState<null | Error | any>(null, 'err')

	const errHandler = (error: ErrorType, opts?: ErrHandlerOptions) => {
		const { showToast = true, printLog = true, context = 'Unknown', trackErr } = {
			...(options || {}),
			...(opts || {}),
		}

		const err = typeof error === 'string' ? new Error(error) : error

		const msg = parseErrMsg(err) || ''

		if (showToast)
			toast(msg, {
				type: 'err',
			})

		if (printLog)
			console.log(
				`%cCONTEXT: ${context}`,
				`color:#FFF; background-color:#c51515; padding: 4px;`,
				' | ERROR: ',
				err
			)

		if (trackErr)
			Bugsnag.notify({
				...err,
				context,
			})

		setErr(err)
	}

	return [errHandler, () => <ErrorBlock error={err} />]
}
export default useErrHandler
