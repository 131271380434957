import React from 'react'
import { useAcquisitionForAcresQuery } from '../../../gql_generated/graphql'
import { KeyVals } from '../../UI'

type AcquisitionAcresProps = {
	acquisitionId: number
}
const AcquisitionAcres: React.FC<AcquisitionAcresProps> = ({ acquisitionId }) => {
	const [results] = useAcquisitionForAcresQuery({ variables: { id: acquisitionId } })
	const acquisition = results.data?.acquisition
	const { activity } = acquisition || {}

	const { srf, ind, min, oil, geo } =
		activity?.reduce(
			(obj, { estateType, acres, estate }) => {
				obj[estateType as 'srf' | 'ind' | 'min' | 'oil' | 'geo'] += acres || estate?.acres || 0
				return obj
			},
			{
				srf: 0,
				ind: 0,
				min: 0,
				oil: 0,
				geo: 0,
			}
		) || {}

	return (
		<KeyVals
			items={[
				activity ? ['Surface', srf, 'acres'] : null,
				activity ? ['Industrial Min.', ind, 'acres'] : null,
				activity ? ['Mineral', min, 'acres'] : null,
				activity ? ['Oil & Gas', oil, 'acres'] : null,
				activity ? ['Geothermal', geo, 'acres'] : null,
			]}
		/>
	)
}
export default AcquisitionAcres
