/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation } from '@reach/router'
import startCase from 'lodash/startCase'
import { useCallback, useEffect } from 'react'
import { IconType } from '../Components/UI/Icon/fa.defaults'
import { useStoreActions } from '../store'
import { parsePlural, parseSingle } from './resource.utils'

// const parseBreadcrumbs = (path, crumbTitle) => {
// 	const crumbs = compact(path.split('/'))
// 	return crumbs.map((slug, idx) => ({
// 		title: idx + 1 === crumbs.length && crumbTitle ? crumbTitle : capitalize(slug),
// 		slug,
// 	}))
// }

type UsePageDataProps = {
	pageTitle?: string
	pageType?: string
	icon?: IconType
	status?: string | null
}

const usePageData = (args?: UsePageDataProps) => {
	const { pageType: pType, pageTitle: title, icon: ico, status } = args || {}
	const { setPageType, setIcon, setPageTitle, setStatus } = useStoreActions(
		(actions: any) => actions.page
	)

	const { pathname } = useLocation()

	const pageType = pType || parseSingle(pathname)

	const pluralPageType: any = parsePlural(pageType)

	const icon = ico || pageType

	const pageTitle = title || startCase(pluralPageType)

	useEffect(() => {
		setPageType(pageType)
	}, [pageType])

	useEffect(() => {
		setIcon(icon)
	}, [icon])

	useEffect(() => {
		setPageTitle(pageTitle)
	}, [pageTitle])

	useEffect(() => {
		setStatus(status)
	}, [status])

	const cb = useCallback(
		({ pageType, pageTitle, icon, status }: UsePageDataProps) => {
			if (pageTitle) setPageTitle(pageTitle)

			if (pageType) {
				setPageType(pageType)
			}

			if (icon) setIcon(icon)
			else if (pageType) setIcon(pageType)

			if (status) setStatus(status)
		},
		//eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	return [cb]
}
export default usePageData
