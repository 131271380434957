import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useState } from '../../../utils'
import { Icon } from '../Icon'
import { Btn } from '../Typography'

type SearchProps = {
	hasBorder?: boolean
}
const Search = styled.div<SearchProps>`
	padding-right: 25px;
	display: inline-flex;
	.search-input {
		background: ${props => props.theme.colors.white.val};
		border: ${props =>
			props.hasBorder ? `solid 1px ${props.theme.colors.grey.light().val}` : 'none'};
		border-radius: 5px;
	}
	input {
		border: none;
		outline: none;
		box-shadow: none;
		flex-grow: 1;
		padding: 0.4em 1em;
		min-width: 15vw;
	}
	.btn {
		padding: 0;
		.icon {
			padding: 0;
		}
	}
	.clear-btn {
		color: ${props => props.theme.colors.yellow.val};
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
`
type SearchBarProps = SearchProps & {
	searchHandler: (val: string) => void
	clearHandler: () => void
	className?: string
	placeholder?: string | null
	isProgressive?: boolean
	min?: number
}

const SearchBar: React.FC<SearchBarProps> = ({
	searchHandler,
	clearHandler,
	className = null,
	placeholder = 'search...',
	hasBorder = false,
	isProgressive = false,
	min = 2,
}) => {
	const [str, setStr] = useState('', 'str')

	const handleSearch = useCallback(() => {
		const searchStr = str.length < min ? null : str
		if (searchStr) searchHandler(searchStr)
		else clearHandler()
	}, [str, searchHandler, clearHandler, min])

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = e.target?.value
		setStr(val)
	}

	const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault()
			handleSearch()
		}
	}

	const handleBlur = () => (str.length ? null : clearHandler())

	const handleClear = () => {
		setStr('')
		clearHandler()
	}

	useEffect(() => {
		if (isProgressive && str.length) {
			handleSearch()
		}
	}, [isProgressive, str, handleSearch])

	return (
		<Search className={`search-bar${className ? ` ${className}` : ''}`} hasBorder={hasBorder}>
			<div className='search-input flex'>
				<input
					type='text'
					placeholder={placeholder || 'Search...'}
					onChange={handleChange}
					onBlur={handleBlur}
					value={str}
					onKeyPress={handleEnter}
				/>
				<Btn onClick={handleSearch}>
					<Icon type='search' />
				</Btn>
			</div>
			{str.length ? (
				<Btn className='clear-btn' onClick={handleClear}>
					<Icon type='x' />
				</Btn>
			) : null}
		</Search>
	)
}
export default SearchBar
