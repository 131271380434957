import get from 'lodash/get'
import React from 'react'
import styled from 'styled-components'
import { useStoreActions, useStoreState } from '../../../store'
import { Btn, Icon } from '../../UI'

const Bar = styled.div`
	${props => props.theme.media.sdesk} {
		display: flex;
		justify-content: flex-end;
		height: ${props => get(props.theme.sizes, 'alertbar.val')};
		align-items: center;
		padding: 0;
	}
	button {
		padding: 0.5em;
		${props => props.theme.media.sdesk} {
			padding: 0;
			&.open {
				opacity: 0;
			}
		}
		.icon {
			margin: 0;
		}
	}
`

const ContentBar: React.FC<{ className?: string }> = ({ className }) => {
	const { toggleCardsBar } = useStoreActions(actions => actions.view)
	const { isCardsOpen } = useStoreState(state => state.view)
	return (
		<Bar className={`resource-ctrl-bar${className ? ` ${className}` : ''}`}>
			<div className='cards-ctrl flex'>
				<Btn onClick={() => toggleCardsBar(null)} className={isCardsOpen ? 'open' : 'closed'}>
					<Icon type='caretBoxDown' isFlipped={isCardsOpen} />
				</Btn>
			</div>
		</Bar>
	)
}
export default ContentBar
