import React from 'react'
import styled, { css } from 'styled-components/macro'
import { relatedOrgColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Org.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { OrgParent } from '../../../gql_generated/document_types'
import { usePaginatedOrgsQuery } from '../../../gql_generated/graphql'
import { RelatedLeaseOrgModal } from '../Related.Modals/Lease.related.org.modal'

export const relatedLeaseOrgStyles = css``

const RelatedLeaseOrgsView = styled.div`
	${relatedLeaseOrgStyles}
`

export type RelatedLeaseOrgsProps = {
	leaseId: number
}
export const RelatedLeaseOrgs: React.FC<RelatedLeaseOrgsProps> = ({ leaseId }) => {
	const paginationStore = usePaginationParams()

	const [results] = usePaginatedOrgsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: OrgParent.Lease,
						parentIds: [leaseId],
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.orgs

	return (
		<RelatedLeaseOrgsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={relatedOrgColsConfig}
				fetchResults={results}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedLeaseOrgModal orgId={data.id} />}
				resourceType='org'
			/>
		</RelatedLeaseOrgsView>
	)
}
