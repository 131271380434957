import React from 'react'
import styled from 'styled-components'
import { GenSaleModal } from '../../Components/common/Sale/Sale.gen.modal'
import { Modal, useModalCtrl } from '../../Components/UI'
import { CsvDownload } from '../../Components/UI/CSV.download'
import { salesPageOpenColsConfig } from '../../Components/UI/Table.resources/Table.resource.col.configs/Sale.col.configs'
import { ResourcesTable } from '../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../Components/UI/Table.resources/usePaginationParams'
import { SaleFilter } from '../../gql_generated/document_types'
import { usePaginatedSalesCsvMutation, usePaginatedSalesQuery } from '../../gql_generated/graphql'

const SalesPageOpenView = styled.div``

type SalesPageOpenProps = {}
const SalesPageOpen: React.FC<SalesPageOpenProps> = () => {
	const paginationStore = usePaginationParams()
	const params = {
		...paginationStore.params,
		filterOptions: [
			{
				filter: SaleFilter.Status,
				vals: ['Open Escrow'],
			},
		],
	}
	const [fetchResults] = usePaginatedSalesQuery({
		variables: {
			params,
		},
	})
	const { data } = fetchResults || {}

	const [[showCsv, openCsv, closeCsv]] = useModalCtrl()
	const [csvMutResults, csvMut] = usePaginatedSalesCsvMutation()

	const sales = data?.page?.sales

	return (
		<SalesPageOpenView>
			<ResourcesTable
				colsConfig={salesPageOpenColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				dataset={sales}
				modalRenderFunc={sale => <GenSaleModal saleId={sale.id} />}
				includeSearch
				resourceType='sale'
				miscOps={[
					{
						icon: 'download',
						title: 'CSV',
						op: openCsv,
					},
				]}
			/>
			<Modal show={showCsv} closeHandler={closeCsv}>
				<CsvDownload
					fetchData={() =>
						csvMut({
							params,
						})
					}
					fetchState={csvMutResults}
					csvData={csvMutResults.data?.csv}
					onAfterDownload={closeCsv}
					options={{
						filename: 'sales',
					}}
				/>
			</Modal>
		</SalesPageOpenView>
	)
}
export default SalesPageOpen
