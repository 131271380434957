import React from 'react'
import { useAcquisitionForFinancialQuery } from '../../../gql_generated/graphql'
import { round, sumBy } from '../../../utils/lodash.utils'
import { Colorize, KeyVals } from '../../UI'

type AcquisitionFinancialInfoProps = {
	acquisitionId: number
}

const AcquisitionFinancialInfo: React.FC<AcquisitionFinancialInfoProps> = ({ acquisitionId }) => {
	const [results] = useAcquisitionForFinancialQuery({ variables: { id: acquisitionId } })
	const acquisition = results.data?.acquisition
	const {
		price,
		brokerRate,
		deposit,
		isDepositTowardPurchase,
		cbNote,
		cbInterest,
		cbYears,
		activity,
	} = acquisition || {}

	const acres = sumBy(activity, ({ acres, estateType, estate }) => {
		return estateType === 'srf' ? acres || estate.acres || 0 : 0
	})

	const pricePerAcre = acres && price ? price / acres : null

	let downPaymentTotal = price && cbNote ? price - cbNote : 0

	return (
		<KeyVals
			items={[
				['Acquisition Price', price, 'currency'],
				[
					'Price per Acre',
					pricePerAcre ? (
						round(pricePerAcre, 2)
					) : !price ? (
						<Colorize color='err'>Add acquisition price to calculate...</Colorize>
					) : (
						<Colorize color='err'>Unable to calculate price...</Colorize>
					),
					pricePerAcre ? 'currency' : undefined,
				],
				['Broker Rate', `${brokerRate || 0}%`],
				['Deposit', deposit, 'currency'],
				deposit ? ['Towards Purchase?', isDepositTowardPurchase, 'boolean'] : null,
				['CB Note', cbNote, 'currency'],
				cbNote ? ['CB Interest', `${cbInterest}%`] : null,
				cbNote ? ['CB Years', cbYears] : null,
				cbNote ? ['Down Payment', downPaymentTotal, 'currency'] : null,
			]}
		/>
	)
}
export default AcquisitionFinancialInfo
