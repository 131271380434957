import {
	useRoyaltyForAcresColQuery,
	useRoyaltyForLeaseIdColQuery,
	useRoyaltyForLesseeColQuery,
	useRoyaltyForObligorColQuery,
	useRoyaltyForRateColQuery,
} from '../../../../gql_generated/graphql'
import { asAcres } from '../../../../utils'
import { sumBy } from '../../../../utils/lodash.utils'
import { TableBodyCellComponent } from '../../Table/Table.types'
import { ColConfig } from './Col.config.types'
import { ResourceTableCellAsync } from './Table.resource.cell.Async'

const Acres: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useRoyaltyForAcresColQuery({
		variables: { id },
	})
	const { royaltyActivity } = fetchResults.data?.royalty || {}

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{asAcres(
				sumBy(royaltyActivity, ({ estateActivity, acres, status }) =>
					status === 'Terminated' || estateActivity.isTerminated || estateActivity.assigneeId
						? 0
						: acres || estateActivity.acres || estateActivity.estate.acres || 0
				)
			)}
		</ResourceTableCellAsync>
	)
}

const Rate: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useRoyaltyForRateColQuery({
		variables: { id },
	})
	const { rate, rateType } = fetchResults.data?.royalty || {}

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{rateType === 'percentage' ? `${rate}%` : `$${rate}`}
		</ResourceTableCellAsync>
	)
}

const Obligor: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useRoyaltyForObligorColQuery({
		variables: { id },
	})
	const { sale } = fetchResults.data?.royalty || {}

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{sale?.grantee?.name || sale?.og?.grantee}
		</ResourceTableCellAsync>
	)
}

const Lessee: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useRoyaltyForLesseeColQuery({
		variables: { id },
	})
	const { lease } = fetchResults.data?.royalty || {}

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>
			{lease?.lessee?.name || lease?.og?.lessee}
		</ResourceTableCellAsync>
	)
}

const LeaseId: TableBodyCellComponent = ({ id }) => {
	const [fetchResults] = useRoyaltyForLeaseIdColQuery({
		variables: { id },
	})
	const { lease } = fetchResults.data?.royalty || {}

	return (
		<ResourceTableCellAsync fetchResults={fetchResults}>{lease?.leaseId}</ResourceTableCellAsync>
	)
}

export const royaltyTypeColConfig: ColConfig = {
	field: 'parentType',
	label: 'Type',
}

export const royaltyEstateTypeColConfig: ColConfig = {
	field: 'estateType',
	format: 'icon',
	label: 'EstateType',
}

export const royaltyRateColConfig: ColConfig = {
	label: 'Rate',
	Component: Rate,
}

export const royaltyDescriptionColConfig: ColConfig = {
	field: 'description',
	format: 'trunc',
}

export const royaltyAcresColConfig: ColConfig = {
	format: 'round',
	label: 'Acres',
	Component: Acres,
}

export const royaltyObligorColConfig: ColConfig = {
	label: 'Obligor',
	sortField: 'grantee.name',
	Component: Obligor,
}

export const royaltyLesseeColConfig: ColConfig = {
	label: 'Lessee',
	sortField: 'lessee.name',
	Component: Lessee,
}

export const royaltyLeaseId: ColConfig = {
	label: 'Lease ID',
	sortField: 'lease.leaseId',
	Component: LeaseId,
}

export const royaltiesPageSalesColsConfig: ColConfig[] = [
	royaltyObligorColConfig,
	royaltyEstateTypeColConfig,
	royaltyRateColConfig,
	royaltyDescriptionColConfig,
	royaltyAcresColConfig,
]

export const royaltiesPageLeasesColsConfig: ColConfig[] = [
	royaltyLesseeColConfig,
	royaltyLeaseId,
	royaltyEstateTypeColConfig,
	royaltyRateColConfig,
	royaltyDescriptionColConfig,
	royaltyAcresColConfig,
]

export const parcelRelatedRoyaltiesColsConfig: ColConfig[] = [
	royaltyTypeColConfig,
	royaltyEstateTypeColConfig,
	royaltyRateColConfig,
	royaltyDescriptionColConfig,
	royaltyAcresColConfig,
]

export const saleRelatedRoyaltiesColsConfig: ColConfig[] = [
	royaltyEstateTypeColConfig,
	royaltyRateColConfig,
	royaltyDescriptionColConfig,
	royaltyAcresColConfig,
]

export const leaseRelatedRoyaltiesColsConfig: ColConfig[] = [
	royaltyEstateTypeColConfig,
	royaltyRateColConfig,
	royaltyDescriptionColConfig,
	royaltyAcresColConfig,
]
