import includes from 'lodash/includes'
import pull from 'lodash/pull'
import React from 'react'
import styled from 'styled-components'
import { Btn, ErrorBlock, P, Spinner } from '../..'
import {
	useActivityEstateTypesByParentQuery,
	useRemoveActivityEstateTypesMutation,
} from '../../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../../Types'
import { estateTypes, useState } from '../../../../utils'
import EstateTypeBtn from '../../../common/Estate/Estate.type.btn'
import { ResourceTableGlobalOpComponentOptions } from '../Table.resources'

const RemoveParcelEstatesFromSaleAcquisitionOpView = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.estate-type-btns {
		margin: 2em 0;
		justify-content: center;
		.icon {
			height: 1.5em;
		}
	}
	.error-block {
		justify-content: center;
	}
`

type RemoveParcelEstatesFromSaleAcquisitionOpProps = ResourceTableGlobalOpComponentOptions

export const RemoveParcelEstatesFromSaleAcquisitionOp: React.FC<
	RemoveParcelEstatesFromSaleAcquisitionOpProps
> = ({
	parentId,
	parentType,
	isTotalQtySelected,
	selectedRowIds,
	deselectedRowIds,
	closeModal,
}) => {
	const [error, setError] = useState<any | null>(null, 'error')
	const [selectedEstateTypes, setSelected] = useState<string[]>([], 'selectedEstateTypes')
	const [_, removeEstateTypes] = useRemoveActivityEstateTypesMutation()
	const [{ data, fetching }] = useActivityEstateTypesByParentQuery({
		variables: {
			parentId,
			parentType,
			parcelIds: isTotalQtySelected ? null : selectedRowIds,
			returnUsedEstateTypes: true,
		},
	})

	const { activityEstateTypes } = data || {}

	const typesForRender = estateTypes.filter(({ type }) => includes(activityEstateTypes || [], type))

	const removeHandler = async () => {
		try {
			await removeEstateTypes({
				estateTypes: selectedEstateTypes,
				parentId,
				parentType,
				parcelIds: selectedRowIds,
				deselectedParcelIds: deselectedRowIds,
				isTotalQtySelected,
			})
			closeModal()
		} catch (err) {
			console.log(err)
			setError(err)
		}
	}

	const addType = (type: string) =>
		setSelected(state => {
			const results = [...state]
			if (includes(state, type)) {
				pull(results, type)
			} else {
				results.push(type as string)
			}
			return results
		})

	const isAllSelected =
		selectedEstateTypes?.length &&
		typesForRender?.length &&
		selectedEstateTypes.length === typesForRender.length

	return (
		<RemoveParcelEstatesFromSaleAcquisitionOpView>
			<P>Select estate types to remove from the selected parcels.</P>
			{fetching ? (
				<Spinner />
			) : typesForRender?.length > 1 ? (
				<div className='estate-type-btns flex'>
					{typesForRender?.map(({ type }) => (
						<EstateTypeBtn
							key={type}
							type={type as EstateTypeEnum}
							onClick={addType}
							selected={selectedEstateTypes as EstateTypeEnum[]}
						/>
					))}
				</div>
			) : null}
			{error ? <ErrorBlock error={error} /> : null}
			{isAllSelected || typesForRender?.length < 2 ? (
				<ErrorBlock isMultiLine>
					Each parcel on this {parentType} must have at least 1 estate type.
				</ErrorBlock>
			) : null}
			<Btn
				className='cta'
				disabled={!selectedEstateTypes?.length || isAllSelected}
				onClick={removeHandler}
			>
				Remove Estate Types
			</Btn>
		</RemoveParcelEstatesFromSaleAcquisitionOpView>
	)
}
