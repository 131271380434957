import React from 'react'
import styled from 'styled-components'
import { HR, SelectStateField, Text, Textarea } from '../../UI'

const View = styled.section`
	.update-aliquot-btn {
		margin-bottom: 1.5em;
	}
`

type ParcelFieldsProps = {}
const ParcelFields: React.FC<ParcelFieldsProps> = () => {
	return (
		<View className='one cols cols-2'>
			<div className='col'>
				<div className='flex-tablet'>
					<Text name='country' label='Country' isReq />
					<Text name='meridian' label='Meridian' isReq />
				</div>
				<div className='flex-tablet'>
					<SelectStateField name='state' label='State' isReq />
					<Text name='county' label='County' isReq />
					<Text name='apn' label='APN' isReq />
				</div>

				<div className='flex'>
					<Text name='town' label='Township' isReq />
					<Text name='range' label='Range' isReq />
					<Text name='section' label='Section' isReq />
				</div>
				<HR />
				<div className='flex'>
					<Textarea name='notes' label='Notes' />
				</div>
			</div>
			<div className='col'>
				<Textarea name='aliquotNotes' label='Aliquot Notes' />
			</div>
		</View>
	)
}
export default ParcelFields
