import { useField } from 'formik'
import toNumber from 'lodash/toNumber'
import React, { HTMLProps, useEffect } from 'react'
import styled from 'styled-components'
import { EM, ErrorBlock, Spinner } from '..'
import { useOrgDbasQuery } from '../../../gql_generated/graphql'

export const SelectDbaView = styled.div`
	.dbas-select {
		padding-left: 4px;
		margin-top: 0.5em;

		em {
			color: ${props => props.theme.colors.grey.val};
			display: block;
		}
		select {
			color: ${props => props.theme.colors.btn.val};
			padding: 0.4em 0;
		}
	}
`

export type SelectDbaProps = HTMLProps<HTMLSelectElement> & {
	name: string
	orgId: number
}
export const SelectDba: React.FC<SelectDbaProps> = ({ orgId, name, ...selectProps }) => {
	const [{ data, error, fetching }, refetch] = useOrgDbasQuery({ variables: { orgId } })
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [{ value }, _, { setValue }] = useField({ name })

	useEffect(() => {
		if (orgId !== value && !fetching) refetch({ variables: { orgId } })

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orgId, value, fetching])

	useEffect(() => {
		if (data && !data?.dbasByOrg?.length) setValue('')

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	return (
		<SelectDbaView>
			{error ? <ErrorBlock error={error} /> : null}
			{fetching ? <Spinner /> : null}
			{data?.dbasByOrg?.length ? (
				<div className='dbas-select'>
					<EM>Use DBA: </EM>
					<select
						{...selectProps}
						name={name}
						value={value}
						onChange={e => setValue(e.target.value ? toNumber(e.target.value) : '')}
					>
						<option value=''>Select DBA...</option>
						{data.dbasByOrg.map(({ id, name }) => (
							<option key={id} value={id}>
								{name}
							</option>
						))}
					</select>
				</div>
			) : null}
		</SelectDbaView>
	)
}
