import React from 'react'
import styled, { css } from 'styled-components/macro'
import { orgRelatedAcquisitionsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Acquisition.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { AcquisitionParent } from '../../../gql_generated/document_types'
import { usePaginatedAcquisitionsQuery } from '../../../gql_generated/graphql'
import { RelatedOrgAcquisitionModal } from '../Related.Modals/Org.related.acquisition.modal'

export const relatedOrgAcquisitionsStyles = css``

const RelatedOrgAcquisitionsView = styled.div`
	${relatedOrgAcquisitionsStyles}
`

export type RelatedOrgAcquisitionsProps = {
	orgId: number
}

export const RelatedOrgAcquisitions: React.FC<RelatedOrgAcquisitionsProps> = ({ orgId }) => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedAcquisitionsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: AcquisitionParent.Org,
						parentIds: [orgId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.acquisitions
	return (
		<RelatedOrgAcquisitionsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={orgRelatedAcquisitionsColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedOrgAcquisitionModal acquisitionId={data.id} />}
				resourceType='acquisition'
			/>
		</RelatedOrgAcquisitionsView>
	)
}
