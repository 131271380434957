import React from 'react'
import styled from 'styled-components'
import { useUnTerminateEstateActivitiesMutation } from '../../../../gql_generated/graphql'
import { useState } from '../../../../utils'
import { Btn, ErrorBlock, P } from '../../../UI'
import { ResourceTableGlobalOpComponentOptions } from '../Table.resources'

const UnterminateRelatedParcelsOpView = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.msg {
		padding: 2em 0;
	}
	.btns {
		width: 100%;
		justify-content: center;
		margin-top: 3em;
		.btn {
			&:last-child {
				margin-left: 1em;
			}
		}
	}
`

type UnterminateRelatedParcelsOpProps = ResourceTableGlobalOpComponentOptions

export const UnterminateRelatedParcelsOp: React.FC<UnterminateRelatedParcelsOpProps> = ({
	parentId,
	parentType,
	isTotalQtySelected,
	selectedRowIds,
	deselectedRowIds,
	closeModal,
}) => {
	const [error, setError] = useState<any | null>(null, 'error')
	const [_, unTerminateActivity] = useUnTerminateEstateActivitiesMutation()

	const removeHandler = async () => {
		try {
			const res = await unTerminateActivity({
				parentId,
				parentType,
				parcelIds: selectedRowIds,
				deselectedParcelIds: deselectedRowIds,
				isTotalQtySelected,
			})

			if (res?.error) throw res.error

			closeModal()
		} catch (err: any) {
			console.error(err)
			setError(err)
		}
	}

	return (
		<UnterminateRelatedParcelsOpView>
			<div className='msg'>
				<P>
					Are you sure you want to reverse termination of{' '}
					{isTotalQtySelected ? 'all' : selectedRowIds.length} parcel
					{selectedRowIds.length > 1 ? 's' : ''} from this {parentType}?
				</P>
			</div>
			{error ? (
				<ErrorBlock error={error} />
			) : (
				<div className='flex btns'>
					<Btn className='cta' onClick={removeHandler}>
						Un-Terminate Parcels
					</Btn>
					<Btn onClick={closeModal}>Cancel</Btn>
				</div>
			)}
		</UnterminateRelatedParcelsOpView>
	)
}
