import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Tab, Tabs } from '../../../../Components/UI'
import { OrgParcelSubParent } from '../../../../gql_generated/document_types'
import { ParcelRelatedOrgsTable } from './Parce.related.orgs.table'

export const relatedParcelOrgsStyles = css``

const RelatedParcelOrgsView = styled.div`
	${relatedParcelOrgsStyles}
`

export type RelatedParcelOrgsProps = {
	parcelId: number
}

export const RelatedParcelOrgs: React.FC<RelatedParcelOrgsProps> = ({ parcelId }) => {
	return (
		<RelatedParcelOrgsView>
			<Tabs renderOnSelect navSize='sm'>
				<Tab nav='Sale Orgs'>
					<ParcelRelatedOrgsTable parcelId={parcelId} subType={OrgParcelSubParent.Sale} />
				</Tab>
				<Tab nav='Acquisition Orgs'>
					<ParcelRelatedOrgsTable parcelId={parcelId} subType={OrgParcelSubParent.Acquisition} />
				</Tab>
				<Tab nav='Lease Orgs'>
					<ParcelRelatedOrgsTable parcelId={parcelId} subType={OrgParcelSubParent.Lease} />
				</Tab>
				<Tab nav='Easement Orgs'>
					<ParcelRelatedOrgsTable parcelId={parcelId} subType={OrgParcelSubParent.Easement} />
				</Tab>
				<Tab nav='Agreement Orgs'>
					<ParcelRelatedOrgsTable parcelId={parcelId} subType={OrgParcelSubParent.Agreement} />
				</Tab>
			</Tabs>
		</RelatedParcelOrgsView>
	)
}
