import styled, { css } from 'styled-components'
import { MgrsDash } from '../Dash.mgrs'

export const landmanDashboardStyles = css``

const LandmanDashboardView = styled.div`
	${landmanDashboardStyles}
`

export type LandmanDashboardProps = {}
export const LandmanDashboard = (): JSX.Element => {
	return (
		<LandmanDashboardView>
			<MgrsDash />
		</LandmanDashboardView>
	)
}
