import React from 'react'
import styled, { css } from 'styled-components/macro'
import { DocDetail } from './Doc.detail'

export const genDocModalStyles = css``

const GenDocModalView = styled.div`
	${genDocModalStyles}
`

export type GenDocModalProps = {
	docId: number
}
export const GenDocModal: React.FC<GenDocModalProps> = ({ docId }) => {
	return (
		<GenDocModalView>
			<DocDetail docId={docId} />
		</GenDocModalView>
	)
}
