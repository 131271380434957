import React from 'react'
import styled, { css } from 'styled-components/macro'
import { useSaleFullQuery } from '../../../gql_generated/graphql'
import { Async, Card, HR, P } from '../../UI'
import SaleAcres from './Sale.acres'
import SaleDates from './Sale.dates'
import SaleFinancialInfo from './Sale.finances'
import SaleInfo from './Sale.info'
import SaleOg from './Sale.og'

export const genSaleModalStyles = css`
	section.cols {
		display: block;

		${props => props.theme.media.tablet} {
			display: flex;

			& > .col {
				flex-grow: 1;
				width: 50%;
			}
		}
	}
`

const SaleDetailView = styled.div`
	${genSaleModalStyles}
`

export type SaleDetailProps = {
	saleId: number
}
export const SaleDetail: React.FC<SaleDetailProps> = ({ saleId }) => {
	const [results] = useSaleFullQuery({ variables: { id: saleId } })
	const { notes } = results.data?.sale || {}

	return (
		<SaleDetailView>
			<Async fetchResults={results}>
				<section className='cols'>
					<div className='col'>
						<Card mBot noShadow>
							<SaleInfo saleId={saleId} />
						</Card>
						<Card mBot noShadow titleText='Acres'>
							<SaleAcres saleId={saleId} />
						</Card>
						<Card noShadow titleText='Dates'>
							<SaleDates saleId={saleId} />
						</Card>
					</div>
					<div className='col'>
						<Card mBot noShadow titleText='Financial'>
							<SaleFinancialInfo saleId={saleId} />
						</Card>
						<Card noShadow titleText='Notes'>
							<P asRT>{notes || null}</P>
						</Card>
					</div>
				</section>
				<HR />
				<section>
					<Card noShadow titleText='Original (OG) data from Xogenous'>
						<SaleOg saleId={saleId} />
					</Card>
				</section>
			</Async>
		</SaleDetailView>
	)
}
