import React from 'react'
import styled, { css } from 'styled-components/macro'
import Protect from '../../../Components/common/Protect'
import CreateLeaseTerms from '../../../Components/Ops/Ops.Leases/Ops.Lease.terms.create'
import UpdateLeaseTerms from '../../../Components/Ops/Ops.Leases/Ops.Lease.terms.update'
import { AddBtn, LeftBar, Modal, useModalCtrl } from '../../../Components/UI'
import { leaseReleatedTermsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Lease.terms.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { LeaseTermParent, UserRole } from '../../../gql_generated/document_types'
import { usePaginatedLeaseTermsQuery } from '../../../gql_generated/graphql'
import { nnrMgrs } from '../../../utils'
import { RelatedLeaseTermModal } from '../Related.Modals/Lease.related.term.modal'

export const relatedLeaseTermStyles = css``

const RelatedLeaseTermsView = styled.div`
	${relatedLeaseTermStyles}
`

export type RelatedLeaseTermsProps = {
	leaseId: number
}

export const RelatedLeaseTerms: React.FC<RelatedLeaseTermsProps> = ({ leaseId }) => {
	const paginationStore = usePaginationParams()
	const [results] = usePaginatedLeaseTermsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: LeaseTermParent.Lease,
						parentIds: [leaseId],
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.terms

	const [[showEditModal, openEditModal, closeEditModal, currentTermId]] = useModalCtrl<number>()
	const [[showCreateModal, openCreateModal, closeCreateModal]] = useModalCtrl<number>()

	return (
		<RelatedLeaseTermsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={leaseReleatedTermsColsConfig}
				fetchResults={results}
				modalRenderFunc={({ id }) => <RelatedLeaseTermModal termId={id} />}
				paginationStore={paginationStore}
				resourceType='leaseTerms'
				suppressGoTo
				rowOps={[
					{
						type: 'edit',
						icon: 'calEdit',
						op: royalty => openEditModal(royalty.id),
						allowed: [...nnrMgrs, UserRole.Accountant],
					},
				]}
			>
				<Protect allowed={[...nnrMgrs, UserRole.Accountant]}>
					<LeftBar>
						<AddBtn title='Create new terms' onClick={() => openCreateModal()} />
					</LeftBar>
				</Protect>
			</ResourcesTable>

			<Modal show={showEditModal} closeHandler={closeEditModal}>
				{currentTermId ? (
					<UpdateLeaseTerms leaseId={leaseId} termId={currentTermId} cb={closeEditModal} />
				) : null}
			</Modal>

			<Modal show={showCreateModal} closeHandler={closeCreateModal}>
				<CreateLeaseTerms leaseId={leaseId} cb={closeCreateModal} />
			</Modal>
		</RelatedLeaseTermsView>
	)
}
