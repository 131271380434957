import React from 'react'
import styled, { css } from 'styled-components/macro'
import AgreementInfo from '../../../Components/common/Agreement/Agreement.info'
import AgreementOg from '../../../Components/common/Agreement/Agreement.og'
import { Async, Card, P } from '../../../Components/UI'
import { useAgreementFullQuery } from '../../../gql_generated/graphql'

export const agreementDetailStyles = css``

const AgreementDetailView = styled.div`
	${agreementDetailStyles}
`

export type AgreementDetailProps = {
	agreementId: number
}
export const AgreementDetail: React.FC<AgreementDetailProps> = ({ agreementId }) => {
	const [results] = useAgreementFullQuery({ variables: { id: agreementId } })
	const agreement = results.data?.agreement
	const { notes } = agreement || {}
	return (
		<AgreementDetailView>
			<Async fetchResults={results}>
				{agreement ? (
					<>
						<Card mBot noShadow>
							<AgreementInfo agreementId={agreementId} />
						</Card>
						<Card mBot noShadow titleText='Notes'>
							<P asRT>{notes}</P>
						</Card>
						<Card noShadow titleText='Orignal (OG) data from Xogenous'>
							<AgreementOg agreementId={agreementId} />
						</Card>
					</>
				) : null}
			</Async>
		</AgreementDetailView>
	)
}
