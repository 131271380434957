/* @ts-ignore */
import toastStyles from 'react-toastify/dist/ReactToastify.css'
import { createGlobalStyle } from 'styled-components'
import fonts from './fonts'

const Styles = createGlobalStyle`
	${fonts}
	${toastStyles}

	legend {
		box-sizing: border-box;
	}
	
	body {
		letter-spacing: 0.5px;
		&.__no-scroll {
			height: 100%;
			position: relative;
			overflow: hidden;
		}
		&.ReactModal__Body--open {
			overflow: hidden;
		}
	}
	p {
		margin: 0;
		margin-bottom: 1em;
	}
	.grid {
		display: grid;
		grid-gap: 1em;
	}

	.flex {
		display: flex;
	}
	.flex-tablet {
		${props => props.theme.media.sdesk} {
			display: flex;
		}
	}
	.flex-desk {
		${props => props.theme.media.sdesk} {
			display: flex;
		}
	}

	 /* Column layout classes */
	 .cols {
    display: flex;
    width: 100%;
    align-items: stretch;
    &.cols-tablet {
      display: block;
      width: auto;
      ${props => props.theme.media.tablet} {
        display: flex;
        width: 100%;
      }
    }
    &.cols-sdesk {
      display: block;
      width: auto;
      ${props => props.theme.media.sdesk} {
        display: flex;
        width: 100%;
      }
    }
    & > *{
      flex-grow: 1;
    }
  }

	.cols {
		display: grid;
		${props => props.theme.media.tablet} {
			grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
		}
		&.cols-2{
			${props => props.theme.media.tablet} {
				grid-template-columns: repeat(2, 1fr);
			}
		}
		.col {
			${props => props.theme.media.tablet} {
				padding:0 16px;
				border-right: solid 1px ${props => props.theme.colors.grey.val};
				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					border: none;
					padding-right: 0;
				}
			}
			${props => props.theme.media.sdesk} {
				padding: 0 32px;
			}
		}
	}

	.modal-overlay {
		z-index: 100;
		position:fixed !important;
		background: ${props => props.theme.colors.black.tint(0).val};
    transition: all ${props => props.theme.times.tranM};
		width: 100vw;
		height: 100vh;
		&.pic {
			background: ${props => props.theme.colors.black.tint(80).val};
		}
		&.ReactModal__Overlay--after-open{
			background: ${props => props.theme.colors.black.tint(60).val};
			.modal-wrap {
				opacity: 1;
				transform: translate3d(0,0,0);
			}
		}
		&.ReactModal__Overlay--before-close{
			background: ${props => props.theme.colors.black.tint(0).val};
			.modal-wrap {
				opacity: 0;
				transform: translate3d(0,50px,0);
			}
		}
		.modal {
			border: none;
			outline: none;
			box-shadow: none;
			width: auto;
			max-width: 90%;
		}
	}
	.__hide-mobile-only {
		${props => props.theme.media.mobile} {
			display: none !important;
		}
	}
	.__hide-mobile {
		@media screen and (max-width: ${props => props.theme.breaks.sdesk.num}px) {
			display: none !important;
		}
	}
	.__hide-tablet {
		@media only screen and (min-width: ${props =>
			props.theme.breaks.tablet.num}px) and (max-width: ${props =>
	props.theme.breaks.sdesk.num}px) {
			display: none;
		}
	}
	.__hide-desk {
		${props => props.theme.media.sdesk} {
			display: none;
		}
	}
	a {
		color: ${props => props.theme.colors.btn.val};
	}
`

export default Styles
