import React, { useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { EstateActivity } from '../../../gql_generated/document_types'
import { useEstateActivityByParcelIdQuery } from '../../../gql_generated/graphql'
import { EstateTypeEnum, ParcelsParentType } from '../../../Types'
import { parseResourceType, useState } from '../../../utils'
import { Async, H5, H6, HR, Icon, P } from '../../UI'
import EstateTypeBtn from '../Estate/Estate.type.btn'
import { EstateActivityInfo } from './Activity.Info'

export const estateActivityPreviewStyles = css`
	.estate-types {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.titles {
		${props => props.theme.media.sdesk} {
			padding-left: 80px;
		}
	}
	.estate-type-icon {
		display: none;

		${props => props.theme.media.sdesk} {
			display: block;
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			transform: translateY(-25%);
			color: ${props => props.theme.colors.grey.light(6).val};
			height: 90px;
		}
	}
`

const EstateActivityPreviewView = styled.div`
	${estateActivityPreviewStyles}
`

export type EstateActivityPreviewProps = {
	parcelId: number
	parentId: number
	parentType: ParcelsParentType
}
export const EstateActivityPreview: React.FC<EstateActivityPreviewProps> = ({
	parcelId,
	parentId,
	parentType,
}) => {
	const [results] = useEstateActivityByParcelIdQuery({
		variables: {
			parcelId,
			parentId,
			parentType,
		},
	})

	const activity = results.data?.activity

	const [selectedEstateType, setEstateType] = useState<EstateTypeEnum | null>(
		null,
		'selectedEstateType'
	)

	useEffect(() => {
		if (activity?.length === 1) setEstateType(activity[0].estateType as EstateTypeEnum)
	}, [activity])

	const availableEstateTypes = activity?.reduce((arry, { estateType }) => {
		const type = estateType as EstateTypeEnum
		if (arry.includes(type)) return arry
		else return [...arry, type]
	}, [] as EstateTypeEnum[])

	const currentActivity = activity?.find(
		({ estateType }) => estateType === selectedEstateType
	) as EstateActivity

	const { titleFull } = parseResourceType(selectedEstateType)
	return (
		<EstateActivityPreviewView>
			<Async fetchResults={results}>
				<header className='preview-estate-activity-header'>
					<div className='col'>
						{(activity?.length || 0) > 1 ? (
							<>
								<H6>Choose an estate type</H6>
								<div className='estate-types'>
									{availableEstateTypes?.map(type => (
										<EstateTypeBtn
											key={type}
											type={type}
											selected={[selectedEstateType as EstateTypeEnum]}
											onClick={() => setEstateType(type)}
										/>
									))}
								</div>
							</>
						) : null}
					</div>
				</header>
				<HR />
				<div className='details'>
					{selectedEstateType ? (
						<Icon className='estate-type-icon' type={selectedEstateType} />
					) : null}
					<div className='titles'>
						<H5>{titleFull} Estate Connection Details</H5>
						<P>
							This describes the relationship between this {parentType} and the {titleFull} estate
							on this parcel
						</P>
					</div>
					<HR />

					{currentActivity ? <EstateActivityInfo estateActivityId={currentActivity.id} /> : null}
				</div>
			</Async>
		</EstateActivityPreviewView>
	)
}
