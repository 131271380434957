import { ReactNode, useCallback, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useState } from '../../../utils'
import { Btn } from '../Typography'

export const dropdownStyles = css<{ zindex: number }>`
	position: relative;
	z-index: ${p => p.zindex};
	.dropdown-ctrl-group {
		position: absolute;
		bottom: 0;
		transform: translate3d(0, 100%, 0);
		transition: all ${props => props.theme.times.tranS};
		overflow: hidden;
		box-shadow: 0px 2px 4px ${props => props.theme.colors.black.tint(40).val};
	}

	.ctrl-group-content {
		padding: 1em 1.5em;
		background: ${props => props.theme.colors.white.val};
	}

	&.__position-right {
		.dropdown-ctrl-group {
			right: 0;
		}
	}
	&.__position-left {
		.dropdown-ctrl-group {
			left: 0;
		}
	}

	&.__full-width {
		.dropdown-ctrl-group {
			width: 100%;
		}
	}
`

const DropdownView = styled.div`
	${dropdownStyles}
`
export type DropdownRenderFuncOptions = {
	isOpen: boolean
	toggle: (override?: boolean) => void
}
export type DropdownRenderFunc = (options: DropdownRenderFuncOptions) => ReactNode

export type DropdownCtrlRenderFunc = (options: DropdownRenderFuncOptions) => ReactNode

export type DropdownProps = {
	ctrl: ReactNode | DropdownCtrlRenderFunc
	children: ReactNode | DropdownRenderFunc
	onOpen?: () => void
	onClose?: () => void

	position?: 'right' | 'left'
	isOpenInitially?: boolean
	fullWidth?: boolean
	closeOnContentClick?: boolean

	className?: string
	zIndex?: number
}
export const Dropdown = ({
	ctrl,
	onOpen,
	onClose,

	position = 'right',
	isOpenInitially = false,
	fullWidth = false,
	closeOnContentClick = false,

	className,
	zIndex,
	children,
}: DropdownProps) => {
	const contentEl = useRef<HTMLDivElement>(null)
	const [isOpen, setOpen] = useState(!!isOpenInitially, 'isOpen')

	useEffect(() => {
		const closeDropDown = (e: any) => {
			const target = e.target
			const content = contentEl.current
			if (!closeOnContentClick && content?.contains(target)) return

			setOpen(false)

			if (onClose) onClose()

			document.removeEventListener('click', closeDropDown)
		}

		if (isOpen) document.addEventListener('click', closeDropDown)

		return () => {
			document.removeEventListener('click', closeDropDown)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	const toggle = useCallback((override?: boolean) => {
		if (typeof override === 'boolean') setOpen(override)
		else setOpen(state => !state)
	}, [])

	return (
		<DropdownView
			className={`dropdown${className ? ` ${className}` : ''}${
				fullWidth ? ' __full-width' : ''
			} __position-${position}`}
			zindex={zIndex || 100}
		>
			<div className='dropdown-container'>
				{typeof ctrl === 'function' ? (
					ctrl({ toggle, isOpen })
				) : (
					<Btn
						className='dropdown-ctrl'
						onClick={() => {
							setOpen(true)
							if (onOpen) onOpen()
						}}
					>
						{ctrl}
					</Btn>
				)}
				<div
					className='dropdown-ctrl-group'
					style={{
						maxHeight: isOpen ? `${contentEl.current?.offsetHeight}px` : 0,
					}}
				>
					<div className='ctrl-group-content' ref={contentEl}>
						{typeof children === 'function' ? children({ toggle, isOpen }) : children}
					</div>
				</div>
			</div>
		</DropdownView>
	)
}
