import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Tab, Tabs } from '../../../Components/UI'
import { RelatedParcelLeasesActive } from './Parcel.related.leases.active'
import { RelatedParcelLeasesExpired } from './Parcel.related.leases.expired'
import { RelatedParcelLeasesTerminated } from './Parcel.related.leases.terminated'

export const relatedParcelLeasesStyles = css``

const RelatedParcelLeasesView = styled.div`
	${relatedParcelLeasesStyles}
`

export type RelatedParcelLeasesProps = {
	parcelId: number
}

export const RelatedParcelLeases: React.FC<RelatedParcelLeasesProps> = ({ parcelId }) => {
	return (
		<RelatedParcelLeasesView>
			<Tabs>
				<Tab nav='Active'>
					<RelatedParcelLeasesActive parcelId={parcelId} />
				</Tab>
				<Tab nav='Expired'>
					<RelatedParcelLeasesExpired parcelId={parcelId} />
				</Tab>
				<Tab nav='Terminated'>
					<RelatedParcelLeasesTerminated parcelId={parcelId} />
				</Tab>
			</Tabs>
		</RelatedParcelLeasesView>
	)
}
