import React from 'react'
import styled, { css } from 'styled-components/macro'
import { RoyaltyDetail } from '../../../Components/common/Royalty/Royalty.detail'
import RoyaltyParcels from '../../../Components/common/Royalty/Royalty.parcels'
import { Card } from '../../../Components/UI'

export const relatedLeaseRoyaltyModalStyles = css``

const RelatedLeaseRoyaltyModalView = styled.div`
	${relatedLeaseRoyaltyModalStyles}
`

export type RelatedLeaseRoyaltyModalProps = {
	royaltyId: number
}
export const RelatedLeaseRoyaltyModal: React.FC<RelatedLeaseRoyaltyModalProps> = ({
	royaltyId,
}) => {
	return (
		<RelatedLeaseRoyaltyModalView>
			<RoyaltyDetail royaltyId={royaltyId} />
			<Card noShadow mTop titleText='Parcels'>
				<RoyaltyParcels royaltyId={royaltyId} />
			</Card>
		</RelatedLeaseRoyaltyModalView>
	)
}
