import styled from 'styled-components'

type PProps = {
	mTop?: string
	mBot?: string
	padTop?: string
	padBot?: string
	pad?: string
	margin?: string
	asRT?: boolean
}
const P = styled.p<PProps>`
	letter-spacing: 0.8px;

	line-height: 1.2em;

	white-space: ${props => (props.asRT ? 'pre-line' : 'normal')};

	${props => (props.pad ? `padding: ${props.pad} 0;` : '')}
	${props => (props.margin ? `margin: ${props.margin} 0;` : '')}

	${props => (props.mTop ? `margin-top: ${props.mTop};` : '')}
	${props => (props.mBot ? `margin-bottom: ${props.mBot};` : '')}
	${props => (props.padTop ? `padding-top: ${props.padTop};` : '')}
	${props => (props.padBot ? `padding-bottom: ${props.padBot};` : '')}
`

export default P
