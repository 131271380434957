import includes from 'lodash/includes'
import React from 'react'
import styled from 'styled-components'
import { EstateTypeEnum } from '../../../Types'
import { Btn, Icon } from '../../UI'

const EstateTypeBtnView = styled.div`
	padding: 4px;

	button {
		border-radius: 50%;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: ${props => props.theme.colors.softSlate.val};
		background: ${props => props.theme.colors.btn.light(0).val};
		/* background: linear-gradient(
			to right,
			${props => props.theme.colors.btn.val} 0%,
			${props => props.theme.colors.btn.light(0).val} 100%
		); */
		padding: 0;
		box-shadow: 0 1px 2.5px ${props => props.theme.colors.grey.val};

		&:hover {
			background: ${props => props.theme.colors.btn.light(1).sat().val};
			color: ${props => props.theme.colors.softSlate.val};
		}

		.icon {
			margin: 0;
		}
	}

	&.__selected,
	&:focus {
		button {
			color: ${props => props.theme.colors.teal.val};
			background: ${props => props.theme.colors.btn.light(2).sat().val};
		}
	}

	&.__flat {
		button {
			box-shadow: none;
		}
	}
`

type EstateTypeBtnProps = {
	type: EstateTypeEnum
	selected?: Array<EstateTypeEnum>
	onClick?: (type: EstateTypeEnum) => void
	isFlat?: boolean
}
const EstateTypeBtn: React.FC<EstateTypeBtnProps> = ({
	type,
	selected,
	onClick,
	isFlat,
	...props
}) => {
	return (
		<EstateTypeBtnView
			className={`estate-type-btn${includes(selected, type) ? ' __selected' : ''}${
				isFlat ? ' __flat' : ''
			}`}
		>
			<Btn onClick={() => (onClick ? onClick(type) : null)} {...props}>
				<Icon type={type} />
			</Btn>
		</EstateTypeBtnView>
	)
}
export default EstateTypeBtn
