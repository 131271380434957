/**
 *  Provides state and handlers for managing table select all functionality
 * */

import { useReducer } from 'react'
import { difference } from '../../../utils/lodash.utils'

export type TableSelectRowsState = State & {
	updateSelectRowState: TableSelectRowsUpdate
}

type State = {
	isTotalQtySelected: boolean
	selectedRowIds: number[]
	deselectedRowIds: number[] // if isTotalQtySelected but individual rows are deselected
}

export type TableSelectRowsArg = boolean | number[]

export type TableSelectRowsUpdate = (arg: TableSelectRowsArg) => void

const reducer = (state: State, arg: TableSelectRowsArg) => {
	const newState = { ...state }
	if (typeof arg === 'boolean') {
		// This is either selecting all across multiple pages
		// Or this is deselecting all
		newState.isTotalQtySelected = arg
		newState.selectedRowIds = []
		newState.deselectedRowIds = []
	} else if (state.isTotalQtySelected) {
		// We are deselecting rows when all are selected across multiple pages

		// These are all current ids with ids for removal filtered out
		const filteredIds = difference(state.deselectedRowIds, arg)

		// These are all ids to add to current ids
		const idsToAdd = difference(arg, state.deselectedRowIds)

		newState.deselectedRowIds = [...filteredIds, ...idsToAdd]

		// This must be a total selected situation
		newState.isTotalQtySelected = true

		// We are not selecting individual ids
		newState.selectedRowIds = []
	} else {
		// This should act as a toggle for any ids passed in:

		// - If they already exist then we should remove them
		const filteredIds = difference(state.selectedRowIds, arg)

		// - If they don't exist then we add them
		const newIds = difference(arg, state.selectedRowIds)

		newState.selectedRowIds = [...filteredIds, ...newIds]

		// We are only concerned with individually selected ids
		newState.isTotalQtySelected = false
		newState.deselectedRowIds = []
	}

	return newState
}

type UseTableSelectRowsOptions = {}

export const useTableSelectRows = (_?: UseTableSelectRowsOptions): TableSelectRowsState => {
	const [{ isTotalQtySelected, selectedRowIds, deselectedRowIds }, dispatch] = useReducer(reducer, {
		isTotalQtySelected: false,
		selectedRowIds: [],
		deselectedRowIds: [],
	})

	return {
		isTotalQtySelected,
		selectedRowIds,
		deselectedRowIds,
		updateSelectRowState: dispatch,
	}
}
