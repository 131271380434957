import React from 'react'
import { RouteComponentProps, Router } from '@reach/router'

import Protect from '../Components/common/Protect'
import Page404 from '../Pages/404.page'

import Agreements from '../Pages/Agreements'
import Agreement from '../Pages/Agreement'

const AgreementRoutes: React.FC<RouteComponentProps> = () => {
	return (
		<Protect>
			<Router>
				<Agreements path='/' />
				<Agreement path='/:id' />
				<Page404 default />
			</Router>
		</Protect>
	)
}
AgreementRoutes.propTypes = {}
export default AgreementRoutes
