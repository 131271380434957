import React from 'react'
import { Router, Redirect } from '@reach/router'

import PublicLayout from '../Layouts/Layout.public'

/**
 *  IMPORT PAGES
 * */

// AUTH PAGES
import Login from '../Pages/Auth/Login'
import Logout from '../Pages/Auth/Logout'
import CreatePass from '../Pages/Auth/CreatePass'
import ForgotPass from '../Pages/Auth/ForgotPass'
import ResetPass from '../Pages/Auth/ResetPass'

import Mgr from './Mgr.routes'

import Page404 from '../Pages/404.page'

const Routing: React.FC = () => {
	return (
		<Router>
			<Redirect from='/' to='login' noThrow />
			<PublicLayout path='login'>
				<Login path='/' />
			</PublicLayout>
			<PublicLayout path='logout'>
				<Logout path='/' />
			</PublicLayout>
			<PublicLayout path='create-pass'>
				<CreatePass path='/' />
			</PublicLayout>
			<PublicLayout path='forgot-pass'>
				<ForgotPass path='/' />
			</PublicLayout>
			<PublicLayout path='reset-pass'>
				<ResetPass path='/' />
			</PublicLayout>

			<Mgr path='mgr/*' />
			<Page404 default />
		</Router>
	)
}
Routing.propTypes = {}
export default Routing
