import React from 'react'
import styled from 'styled-components'
import { Icon } from '../Icon'
import { SelectOptions, SelectProps } from './Select'

const View = styled.div`
	position: relative;
	width: auto;
	display: inline-flex;
	align-items: center;
	border: solid 2px ${props => props.theme.colors.grey.light().val};
	padding: 0.5em;
	color: ${props => props.theme.colors.grey.val};

	&:hover {
		cursor: pointer;
		color: ${props => props.theme.colors.btn.val};
	}
	select {
		border: none;
		outline: none;
		color: inherit;
		-webkit-appearance: none;
		-moz-appearance: none;
		flex-grow: 1;
		z-index: 1;
		position: relative;
		background: none;
		padding-right: 1em;
		&:hover {
			cursor: pointer;
		}
	}
	.icon {
		width: auto;
		color: inherit;
		display: grid;
		align-items: center;
		position: absolute;
		margin: 0;
		right: 0.5em;
		top: 50%;
		transform: translateY(-50%);
		z-index: 0;
		svg {
			height: 1em;
		}
	}
`

export type SelectRawProps = SelectProps & { options?: SelectOptions[]; disabled?: boolean }
const SelectRaw: React.FC<SelectRawProps> = ({ options = [], placeholder, children, ...props }) => {
	const optionsArry: SelectOptions[] = placeholder
		? [
				{
					txt: placeholder,
					val: '',
				},
				...options,
		  ]
		: options

	return (
		<View>
			<select {...props}>
				{optionsArry.length
					? optionsArry.map(option => (
							<option key={option.val} value={option.val}>
								{option.txt ? option.txt : option.val}
							</option>
					  ))
					: children}
			</select>
			<Icon type='caretDown' />
		</View>
	)
}
export default SelectRaw
