import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useState } from '../../utils'
import { UserCard } from '../common/User.card'
import { Btn, Icon, Link } from '../UI'
import { Dropdown } from '../UI/Dropdown'

export const MgrHeaderLoggedInMenuView = styled.div`
	position: relative;
	.btn {
		color: ${props => props.theme.colors.yellow.light(1).val};
		.icon {
			height: 20px;
		}
	}
	.notifications-ctrl {
		display: none;
	}
	.dropdown-ctrl {
		.icon {
			height: 28px;
		}
	}
	.user-menu {
		.link {
			display: flex;
			align-items: center;
		}
	}
`

const parseIcon = (notifications: any[]) => {
	if (!notifications.length) return 'bellSlash'
	if (!!notifications.find(({ isRead }) => isRead !== true)) return 'bellAlert'
	else return 'bellEmpty'
}

export type MgrHeaderLoggedInMenuProps = {
	className?: string
}
export const MgrHeaderLoggedInMenu: React.FC<MgrHeaderLoggedInMenuProps> = ({ className }) => {
	const notifications: any[] = []

	const [showUserMenu, setUserMenu] = useState(false, 'showUserMenu')

	useEffect(() => {
		const hideMenu = () => {
			setUserMenu(false)
			document.removeEventListener('click', hideMenu)
		}
		if (showUserMenu) document.addEventListener('click', hideMenu)

		return () => {
			document.removeEventListener('click', hideMenu)
		}
	}, [showUserMenu])

	return (
		<MgrHeaderLoggedInMenuView className={`logged-in-menu${className ? ` ${className}` : ''}`}>
			<Btn className='notifications-ctrl'>
				<Icon type={parseIcon(notifications)} />
			</Btn>
			<Dropdown ctrl={<Icon type='user' />} zIndex={101}>
				<div className='ctrl-group user-menu'>
					<UserCard includeBorder />
					<ul>
						<li>
							<Link to='/logout'>
								<Icon type='logout' padRight />
								Logout
							</Link>
						</li>
					</ul>
				</div>
			</Dropdown>
		</MgrHeaderLoggedInMenuView>
	)
}
