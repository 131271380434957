import React, { ReactElement, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { useState } from '../../../utils'
import { TabProps } from './Tab'
import { TabContainer } from './Tab.container'
import { TabsNav } from './Tabs.nav'

const TabsView = styled.div`
	transition: all 500ms ease-in-out;

	.tab-container {
		min-height: 150px;
		width: 100%;
	}

	.tab-content {
		padding-top: 1em;
	}
	.tab {
		width: 100%;
	}
`

type TabType = ReactElement<TabProps>

type TabsProps = {
	children: TabType[] | TabType
	className?: string
	initialTabIdx?: number
	renderOnSelect?: boolean
	navSize?: 'sm' | 'lg'
}

export const Tabs: React.FC<TabsProps> = React.memo(
	({ children, className, initialTabIdx = 0, renderOnSelect, navSize = 'lg' }) => {
		const isDone = useRef(false)

		const tabs = useMemo(() => (children instanceof Array ? children : [children]), [
			children,
		]) as TabType[]

		const [currentIdx, setCurrentIdx] = useState(initialTabIdx, 'currentIdx')

		const [CurrentTab, setCurrentTab] = useState(tabs[currentIdx], 'CurrentTab')

		useEffect(() => {
			setCurrentTab(tabs[currentIdx])

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [currentIdx, tabs])

		useEffect(() => {
			return () => {
				isDone.current = true
			}
		}, [])

		return isDone.current ? null : (
			<TabsView
				className={`${renderOnSelect ? `tabs` : `tabs pre-render`}${
					className ? ` ${className}` : ''
				}`}
			>
				<TabsNav
					size={navSize}
					tabs={tabs.map((T, idx) => ({
						nav: T.props.nav || idx + 1,
						icon: T.props.icon,
						navVerbose: T.props.navVerbose,
					}))}
					currentIdx={currentIdx}
					selectHandler={setCurrentIdx}
				/>
				<div className='tabs-wrap'>
					{renderOnSelect ? (
						<TabContainer isActive>{[CurrentTab]}</TabContainer>
					) : tabs.length ? (
						tabs.map((Tab, idx) => (
							<TabContainer
								key={Tab.props.nav || `tab-${idx}`}
								isActive={idx === currentIdx}
								preRender={!renderOnSelect}
							>
								{[Tab]}
							</TabContainer>
						))
					) : null}
				</div>
			</TabsView>
		)
	}
)
