import copy from 'copy-to-clipboard'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useState } from '../../../utils'
import { Icon } from '../Icon'
import { Btn } from '../Typography'

const CopyBtnView = styled(Btn)`
	position: relative;
	vertical-align: center;
	color: ${props => props.theme.colors.btn.val};
	width: auto;

	.text {
		margin-right: 0.5em;
	}

	.success-wrap {
		position: relative;
		padding-right: 0.5em;
		.success {
			color: ${props => props.theme.colors.grey.val};
			position: absolute;
			right: 1em;
			top: 50%;
			font-size: 0.8em;
			background: ${props => props.theme.colors.white.val};
			padding: 0.5em;
			transform: translate(100%, -50%);
			white-space: nowrap;
		}
	}
`

export type CopyBtnProps = {
	content?: string | number
	children?: string | number
	showPrompt?: boolean
	showMsg?: boolean
	forTime?: number
}

export const CopyBtn: React.FC<CopyBtnProps> = ({
	content,
	children,
	showPrompt,
	showMsg = true,
	forTime = 1000,
}) => {
	const [done, setDone] = useState(false, 'done')

	const text = content || children || ''

	const handler = () => {
		if (!text) return
		// See: https://www.npmjs.com/package/copy-to-clipboard
		copy(`${text}`)
		if (showMsg) setDone(true)
	}

	useEffect(() => {
		if (done && showMsg)
			setTimeout(() => {
				setDone(false)
			}, forTime)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [done])

	return (
		<CopyBtnView className='copy-clip-btn' onClick={handler}>
			{children ? <span className='text'>{children}</span> : null}
			<div className='success-wrap'>
				<Icon type='copy' title='Copy to clipboard' />
				{showPrompt ? <span className='prompt'>Copy to clipboard</span> : null}
				{done ? (
					<span className='success'>{showPrompt ? 'Copied!' : `Copied to clipboard!`}</span>
				) : null}
			</div>
		</CopyBtnView>
	)
}
