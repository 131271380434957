import React from 'react'
import styled, { css } from 'styled-components/macro'
import AcquisitionAcres from '../../../Components/common/Acquisition/Acquisition.acres'
import AcquisitionDates from '../../../Components/common/Acquisition/Acquisition.dates'
import AcquisitionFinancialInfo from '../../../Components/common/Acquisition/Acquisition.finances'
import AcquisitionInfo from '../../../Components/common/Acquisition/Acquisition.info'
import { Async, Card, P } from '../../../Components/UI'
import { useAcquisitionFullQuery } from '../../../gql_generated/graphql'

export const acquisitionDetailStyles = css``

const AcquisitionDetailView = styled.div`
	${acquisitionDetailStyles}
`

export type AcquisitionDetailProps = {
	acquisitionId: number
}
export const AcquisitionDetail: React.FC<AcquisitionDetailProps> = ({ acquisitionId }) => {
	const [results] = useAcquisitionFullQuery({ variables: { id: acquisitionId } })
	const acquisition = results.data?.acquisition

	return (
		<AcquisitionDetailView>
			<Async fetchResults={results}>
				{acquisition ? (
					<>
						<Card noShadow mBot>
							<AcquisitionInfo acquisitionId={acquisitionId} />
						</Card>
						<Card mBot noShadow titleText='Acres'>
							<AcquisitionAcres acquisitionId={acquisitionId} />
						</Card>
						<Card mBot noShadow titleText='Dates'>
							<AcquisitionDates acquisitionId={acquisitionId} />
						</Card>
						<Card mBot noShadow titleText='Financial'>
							<AcquisitionFinancialInfo acquisitionId={acquisitionId} />
						</Card>
						<Card noShadow titleText='Notes'>
							<P asRT>{acquisition.notes || null}</P>
						</Card>
					</>
				) : null}
			</Async>
		</AcquisitionDetailView>
	)
}
