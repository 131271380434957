import React from 'react'
import styled from 'styled-components'
import {
	EstateActivityUpdateInput,
	useEditEstateActivityMutation,
	useEstateActivityByIdQuery,
} from '../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../Types'
import { nnrMgrs, parseResourceType } from '../../../utils'
import {
	Btn,
	Card,
	ErrorBlock,
	Form,
	Icon,
	KeyVal,
	KeyVals,
	P,
	Spinner,
	SubmitBtn,
	toastit,
} from '../../UI'
import ParcelInfo from '../Parcel/Parcel.info'
import Protect from '../Protect'
import { CustomEstateActivityAcresAliquotFields } from './Activity.edit.customAcresAliquot'

export const EstateActivityInfoView = styled.div`
	min-width: 50vw;
	padding-top: 1em;

	${props => props.theme.media.tablet} {
		padding: 3em;
	}

	.info,
	.comparison {
		${props => props.theme.media.tablet} {
			display: flex;
		}
	}
	.custom-data {
		button {
			margin-top: 1em;
			padding: 0.5em 1em;
			border: solid 1px;
			display: flex;
			align-items: center;
			color: ${props => props.theme.colors.err.val};
		}
	}
`

export type EstateActivityInfoProps = {
	estateActivityId: number
}
export const EstateActivityInfo: React.FC<EstateActivityInfoProps> = ({ estateActivityId }) => {
	const [results] = useEstateActivityByIdQuery({ variables: { id: estateActivityId } })
	const activity = results.data?.estateActivity

	const [mutResults, editActivity] = useEditEstateActivityMutation()

	const {
		acres,
		aliquot,
		estateType,
		estate,
		assignee,
		isTerminated,
		id,
		estateId,
		parcelId,
		reservedEasement,
	} = activity || {}

	const { titleFull } = parseResourceType(estateType)

	const deleteCustomHandler = async () => {
		try {
			const res = await editActivity({
				data: {
					id,
					estateId,
					parcelId,
					acres: null,
					aliquot: null,
				} as EstateActivityUpdateInput,
			})
			if (res.error) toastit.err('Error removing custom acres/aliquot')
		} catch (err) {
			toastit.err('Error adding custom acres/aliquot')
		}
	}

	const addCustomHandler = async (vals: any) => {
		try {
			const res = await editActivity({
				data: {
					id,
					estateId,
					parcelId,
					acres: vals.acres,
					aliquot: vals.aliquot,
				} as EstateActivityUpdateInput,
			})
			if (res.error) toastit.err('Error adding custom acres/aliquot')
		} catch (err) {
			toastit.err('Error adding custom acres/aliquot')
		}
	}

	return (
		<EstateActivityInfoView>
			<section className='info'>
				<Card noShadow titleText='Parcel' mRight mBot>
					{parcelId ? <ParcelInfo parcelId={parcelId} /> : null}
				</Card>
				<Card titleText='Additional Info' noShadow mBot>
					<KeyVals
						items={[
							['Assignee', assignee, 'org'],
							['Terminated?', isTerminated, 'boolean'],
						]}
					/>
					{reservedEasement ? (
						<KeyVal label='Reserved Easement'>
							<P>{reservedEasement}</P>
						</KeyVal>
					) : null}
				</Card>
			</section>

			<section className='comparison'>
				<Card noShadow titleText={`${titleFull} estate`} mRight mBot>
					<KeyVals
						items={[
							['Acres', estate?.acres, 'acres'],
							['Aliquot', estate?.aliquot],
						]}
					/>
				</Card>
				<Card noShadow titleText='Custom Acres/Aliquot' mBot>
					{mutResults.error ? <ErrorBlock error={mutResults.error} /> : null}
					{(acres || aliquot) && !mutResults.data ? (
						<div className='custom-data'>
							<KeyVals
								items={[
									['Acres', acres, 'acres'],
									['Aliquot', aliquot],
								]}
							/>
							<>
								<P mTop='1em'>
									NOTE: This is using a custom acres/aliquot - if the estate acres/aliquot changes
									it will not update here.
								</P>
								<Protect allowed={nnrMgrs}>
									{estate?.acres === acres ? (
										<P>If this is unintentional you can delete the custom acres/aliquot:</P>
									) : null}
									<Btn onClick={deleteCustomHandler} disabled={mutResults.fetching}>
										{mutResults.fetching ? <Spinner /> : null}
										<Icon type='x' />
										Delete custom acres/aliquot
									</Btn>
								</Protect>
							</>
						</div>
					) : (
						<>
							<P>Using estate acres/aliquot</P>
							{activity ? (
								<Form
									initialValues={{
										acres: estate?.acres || '',
										aliquot: estate?.aliquot || '',
										useCustom: false,
									}}
									onSubmit={addCustomHandler}
								>
									{({ values }) => (
										<>
											<CustomEstateActivityAcresAliquotFields
												estateAcres={estate?.acres as number}
												estateAliquot={estate?.aliquot as string}
												useCustom={values.useCustom}
												estateType={estateType as EstateTypeEnum}
											/>
											{values.useCustom ? <SubmitBtn /> : null}
										</>
									)}
								</Form>
							) : null}
						</>
					)}
				</Card>
			</section>
		</EstateActivityInfoView>
	)
}
