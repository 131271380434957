import { Field } from 'formik'
import React from 'react'
import styled from 'styled-components'
import FormFieldWrap, { FormFieldWrapProps } from './Form.field.wrap'
import { InputProps } from './Input'

const I = styled.input``

const EmailField: React.FC<InputProps & FormFieldWrapProps> = ({
	label,
	name,
	helperTxt,
	isReq,
	...props
}) => {
	return (
		<FormFieldWrap type={'email'} label={label} name={name} helperTxt={helperTxt} isReq={isReq}>
			<Field as={I} name={name} type='email' title='Email input' {...props} />
		</FormFieldWrap>
	)
}
export default EmailField
