import { Field, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { EmailField, FormFieldWrap, HR, PhoneField, Text, Textarea } from '../../UI'
import SelectState from '../../UI/Form.Fields/Select.state'

export const orgSchema = Yup.object().shape({
	name: Yup.string().required('Please enter a name for this org.'),
})

export const contactSchema = Yup.object().shape({
	firstName: Yup.string().required('Please enter a first name'),
})

const StreetFields: React.FC<{}> = () => {
	const { values } = useFormikContext<{ address?: { street?: string } }>()
	const { street } = values?.address || {}
	return (
		<FormFieldWrap type='streets-field' name='street' label='Street'>
			<Field name='address.street' placeholder='Street line 1' />
			{street ? <Field name='address.street2' placeholder='Street line 2' /> : null}
		</FormFieldWrap>
	)
}

const AddressFields: React.FC<{ namePrefix?: string }> = ({ namePrefix }) => {
	const { values } = useFormikContext<any>()
	const { address } = values
	return (
		<section className='address-fields'>
			<legend>Address</legend>
			<Text
				name={namePrefix ? `${namePrefix}address.country` : 'address.country'}
				label='Country'
			/>
			<StreetFields />
			<Text name={namePrefix ? `${namePrefix}address.city` : 'address.city'} label='City' />
			{address?.country === 'USA' ? (
				<SelectState
					name={namePrefix ? `${namePrefix}address.state` : 'address.state'}
					label='State'
				/>
			) : (
				<Text
					name={namePrefix ? `${namePrefix}address.state` : 'address.state'}
					label='State/Province'
				/>
			)}
			<Text
				name={namePrefix ? `${namePrefix}address.zip` : 'address.zip'}
				label='Zip/Postal Code'
			/>
		</section>
	)
}

export const OrgFields: React.FC<{}> = () => {
	return (
		<>
			<Text name='name' placeholder='Org Name' isReq />
			<EmailField name='email' placeholder='Email' />
			<PhoneField name='phone' label='Phone' />
			<AddressFields />
			<Textarea name='notes' label='Notes' />
		</>
	)
}

export const ContactFields: React.FC<{ namePrefix?: string; isSimple?: boolean }> = ({
	namePrefix,
	isSimple,
}) => {
	const { values } = useFormikContext<{ phoneWork?: string }>()
	const { phoneWork } = values || {}
	return (
		<>
			<Text
				name={namePrefix ? `${namePrefix}firstName` : 'firstName'}
				placeholder='First Name'
				isReq
			/>
			<Text name={namePrefix ? `${namePrefix}lastName` : 'lastName'} placeholder='Last Name' />
			<Text name={namePrefix ? `${namePrefix}title` : 'title'} placeholder='Title' />
			<EmailField name={namePrefix ? `${namePrefix}email` : 'email'} placeholder='Email' />
			<PhoneField name={namePrefix ? `${namePrefix}phoneMobile` : 'phoneMobile'} label='Mobile' />
			<div className='flex'>
				<PhoneField name={namePrefix ? `${namePrefix}phoneWork` : 'phoneWork'} label='Work' />
				{phoneWork ? (
					<Text name={namePrefix ? `${namePrefix}phoneExt` : 'phoneExt'} placeholder='Ext' />
				) : null}
			</div>
			{!isSimple ? <AddressFields namePrefix={namePrefix} /> : null}
			{!isSimple ? <HR /> : null}
			{!isSimple ? (
				<Textarea name={namePrefix ? `${namePrefix}notes` : 'notes'} label='Notes' />
			) : null}
		</>
	)
}
