import React, { useRef } from 'react'
import styled from 'styled-components'
import {
	ContactInput,
	ContactUpdateInput,
	useContactQuery,
	useUpdateContactMutation,
} from '../../../gql_generated/graphql'
import { sanitizeDataForForm } from '../../../utils'
import { ErrorBlock, Form, Spinner, SubmitBtn, toastit } from '../../UI'
import { ContactFields, contactSchema } from './Ops.Org.fields'

const EditContactView = styled.div``

type EditContactProps = {
	contactId: number
	orgId: number
	cb?: () => void
}
export const EditContact: React.FC<EditContactProps> = ({ cb, contactId, orgId }) => {
	const [{ data, fetching, error }] = useContactQuery({ variables: { id: contactId } })
	const isDone = useRef(false)
	const { contact } = data || {}

	const [_, updateContact] = useUpdateContactMutation()
	return isDone.current ? null : fetching ? (
		<Spinner />
	) : error ? (
		<ErrorBlock error={error} />
	) : (
		<EditContactView>
			<Form<ContactUpdateInput>
				legend={`Edit ${contact ? contact.firstName : 'Contact'}`}
				initialValues={sanitizeDataForForm<ContactInput>(
					{ orgId, ...contact },
					{ stripKeys: ['fullName'] }
				)}
				onSubmit={async data => {
					try {
						const res = await updateContact({
							data: {
								...data,
								primary: data.primary?.length ? data.primary : null,
							},
						})
						if (res.error) toastit.err('Error updating contact')
						else if (cb) {
							isDone.current = true
							cb()
						}
					} catch (err) {
						throw err
					}
				}}
				validationSchema={contactSchema}
			>
				{() => (
					<>
						<ContactFields />
						<SubmitBtn />
					</>
				)}
			</Form>
		</EditContactView>
	)
}
