import React from 'react'
import styled, { css } from 'styled-components/macro'
import { parcelsPageColsConfig } from '../../../../Components/UI/Table.resources/Table.resource.col.configs/Parcel.col.configs'
import { ResourcesTable } from '../../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../../Components/UI/Table.resources/usePaginationParams'
import { ParcelFilter, ParcelParent } from '../../../../gql_generated/document_types'
import { usePaginatedParcelsQuery } from '../../../../gql_generated/graphql'
import { EstateTypeEnum } from '../../../../Types'
import { RelatedParcelAcquisitions } from '../../../Parcel/Related/Parcel.related.acquisitions'
import { RelatedResearchModal } from '../Related.Modals/Research.related.modal'

export const relatedResearchAcquisitionsStyles = css``

const RelatedResearchAcquisitionsView = styled.div`
	${relatedResearchAcquisitionsStyles}
`

export type RelatedResearchAcquisitionsProps = {
	parcelIds: number[]
	estateTypes: EstateTypeEnum[]
}

export const RelatedResearchAcquisitions: React.FC<RelatedResearchAcquisitionsProps> = ({
	parcelIds,
}) => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedParcelsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: ParcelParent.Acquisition,
					},
				],
				filterOptions: [
					{
						filter: ParcelFilter.ParcelIds,
						vals: parcelIds,
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.parcels
	return (
		<RelatedResearchAcquisitionsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={parcelsPageColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={({ id }) => (
					<RelatedResearchModal parcelId={id} parcelParent={ParcelParent.Acquisition}>
						<RelatedParcelAcquisitions parcelId={id} />
					</RelatedResearchModal>
				)}
				suppressGoTo
				resourceType='acquisition'
			/>
		</RelatedResearchAcquisitionsView>
	)
}
