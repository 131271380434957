import { useField } from 'formik'
import includes from 'lodash/includes'
import React from 'react'
import styled from 'styled-components'
import { estateTypes, useDropdown } from '../../../utils'
import { Icon } from '../Icon'
import { IconType } from '../Icon/fa.defaults'
import { Btn } from '../Typography'
import FormFieldWrap, { FormFieldWrapProps } from './Form.field.wrap'

const View = styled.div`
	position: relative;
	.options {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		background: ${props => props.theme.colors.white.val};
		padding: 1em;
		z-index: 1;
		box-shadow: 0 1px 3px ${props => props.theme.colors.black.tint().val};
		li {
			margin-bottom: 0.5em;
		}
		.btn {
			display: flex;
			align-items: center;
			text-transform: capitalize;
		}
	}
`

type SelectEstateTypeProps = FormFieldWrapProps & {
	placeholder?: string
	estateTypes?: string[]
}
const SelectEstateType: React.FC<SelectEstateTypeProps> = ({
	name,
	label,
	isReq,
	helperTxt,
	estateTypes: limitToEstateTypes,
	...props
}) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [{ value }, { touched }, { setValue, setTouched }] = useField({ name, ...props })
	const [show, setShow] = useDropdown()

	const estateTypesForRender = limitToEstateTypes?.length
		? estateTypes.filter(({ type }) => includes(limitToEstateTypes, type))
		: estateTypes

	return (
		<FormFieldWrap
			name={name}
			label={label}
			type='select-estate-type'
			isReq={isReq}
			helperTxt={helperTxt}
		>
			<View className='input'>
				<Btn
					onClick={() => {
						if (!touched) setTouched(true)
						setShow(true)
					}}
				>
					{value ? <Icon type={value} /> : 'Select estate type...'}
				</Btn>
				{show ? (
					<ul className='options'>
						{estateTypesForRender.map(({ type, typeFull }) => (
							<li key={type}>
								<Btn
									onClick={() => {
										setValue(type)
										setShow(false)
									}}
								>
									<Icon type={type as IconType} />
									{typeFull}
								</Btn>
							</li>
						))}
					</ul>
				) : null}
			</View>
		</FormFieldWrap>
	)
}
export default SelectEstateType
