import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import {
	Sale,
	SaleUpdateStatusInput,
	useUpdateSalesStatusMutation,
} from '../../../gql_generated/graphql'
import { formatDate, parseDateForServer } from '../../../utils'
import { Form, SubmitBtn, Text, Textarea } from '../../UI'

const OpCancelSaleView = styled.div``

const schema = Yup.object().shape({
	dateCancelled: Yup.string().required('Please enter a date for cancellation'),
	cancelReason: Yup.string().required('Please enter a reason for this cancellation'),
})

type OpCancelSaleProps = {
	sale: Sale
	cb?: () => void
}
const OpCancelSale: React.FC<OpCancelSaleProps> = ({ sale, cb }) => {
	const { id } = sale
	const [_, updateStatus] = useUpdateSalesStatusMutation()

	return (
		<OpCancelSaleView>
			<Form
				legend='Cancel Sale'
				initialValues={{
					dateCancelled: formatDate(new Date(), 'input'),
					cancelReason: '',
				}}
				onSubmit={async (data: SaleUpdateStatusInput) => {
					try {
						await updateStatus({
							data: {
								...data,
								id,
								dateCancelled: parseDateForServer(data.dateCancelled),
							},
						})
						if (cb) cb()
					} catch (err) {}
				}}
				validationSchema={schema}
			>
				{() => (
					<>
						<Text type='date' name='dateCancelled' label='Date Cancelled' isReq />
						<Textarea name='cancelReason' label='Cancellation Reason' isReq />
						<SubmitBtn>Cancel Sale</SubmitBtn>
					</>
				)}
			</Form>
		</OpCancelSaleView>
	)
}
export default OpCancelSale
