import React from 'react'
import styled from 'styled-components'
import { H1, H4, P } from '../../UI'
import { ExhibitPdfConfig } from './Exhibit.pdf'

const ExhibitPdfTitlesView = styled.div`
	display: grid;
	grid-template-columns: 15% 1fr 15%;
	.page-titles {
		text-align: center;
		& > * {
			margin-bottom: 0.5em;
		}
		h1 {
			font-size: 18px;
		}
		h4 {
			font-size: 14px;
		}
		p {
			font-size: 12px;
			font-weight: 700;
		}
	}
	.date {
		text-align: right;
	}
`

type ExhibitPdfTitlesProps = {
	config: ExhibitPdfConfig
}
const ExhibitPdfTitles: React.FC<ExhibitPdfTitlesProps> = ({ config }) => {
	const { letter, title, subTitle, hideDate, date } = config
	return (
		<ExhibitPdfTitlesView className='pdf-titles'>
			<div className='left-col'></div>
			<div className='page-titles'>
				<H1>Exhibit {letter.toUpperCase()}</H1>
				{title ? <H4>{title}</H4> : null}
				{subTitle ? <P>{subTitle}</P> : null}
			</div>
			{hideDate ? null : (
				<div className='right-col'>
					<div className='date'>{date}</div>
				</div>
			)}
		</ExhibitPdfTitlesView>
	)
}
export default ExhibitPdfTitles
