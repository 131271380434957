import React from 'react'
import styled from 'styled-components'
import { Acquisition, useUpdateAcquisitionsStatusMutation } from '../../../gql_generated/graphql'
import { Form, P, SubmitBtn } from '../../UI'

const OpReopenAcquisitionView = styled.div``

type OpReopenAcquisitionProps = {
	acquisition: Acquisition
	cb?: () => void
}
const OpReopenAcquisition: React.FC<OpReopenAcquisitionProps> = ({ acquisition, cb }) => {
	const { id } = acquisition
	const [_, updateStatus] = useUpdateAcquisitionsStatusMutation()

	return (
		<OpReopenAcquisitionView>
			<Form
				legend='Reopen Acquisition'
				initialValues={{
					dateOpened: '',
				}}
				onSubmit={async () => {
					try {
						await updateStatus({
							data: {
								dateCancelled: null,
								cancelReason: null,
								dateClosed: null,
								dateForeclosed: null,
								foreclosureReason: null,
								id,
							},
						})
						if (cb) cb()
					} catch (err) {}
				}}
			>
				{() => (
					<>
						<P>Are you sure you want to reopen this acquisition?</P>
						<SubmitBtn>Reopen</SubmitBtn>
					</>
				)}
			</Form>
		</OpReopenAcquisitionView>
	)
}
export default OpReopenAcquisition
