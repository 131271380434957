import { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useCreatePropertyTaxMutation } from '../../../gql_generated/graphql'
import { priceToNumber } from '../../../utils'
import { Form, SubmitBtn, toastit } from '../../UI'
import { PropertyTaxFields, propertyTaxYupSchema } from './Ops.Parcel.propertyTax.fields'

export const createPropertyTaxOpStyles = css``

const CreatePropertyTaxOpView = styled.div`
	${createPropertyTaxOpStyles}
`

export type CreatePropertyTaxOpProps = {
	parcelId: number
	cb?: () => void
}
export const CreatePropertyTaxOp = ({ parcelId, cb }: CreatePropertyTaxOpProps): JSX.Element => {
	const initialVals = {
		amount: '',
		year: new Date().getFullYear() + 1,
	}

	const [mutRes, mutate] = useCreatePropertyTaxMutation()
	const handleSubmit = useCallback(
		async (vals: typeof initialVals) => {
			try {
				const res = await mutate({
					data: {
						...vals,
						parcelId,
						amount: priceToNumber(vals.amount),
						year: vals.year ? parseInt(`${vals.year}`) : vals.year,
					},
				})
				if (res.error) {
					toastit.err('Error creating tax')
				} else {
					toastit.ok('Saved!')
					cb && cb()
				}
			} catch (err) {}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	return (
		<CreatePropertyTaxOpView>
			<Form
				initialValues={initialVals}
				onSubmit={handleSubmit}
				submitErr={mutRes.error}
				validationSchema={propertyTaxYupSchema()}
			>
				{() => (
					<>
						<PropertyTaxFields />
						<SubmitBtn />
					</>
				)}
			</Form>
		</CreatePropertyTaxOpView>
	)
}
