import React from 'react'
import styled, { css } from 'styled-components/macro'
import { DocDetail } from '../../../Components/common/Doc/Doc.detail'

export const relatedEasementDocModalStyles = css``

const RelatedEasementDocModalView = styled.div`
	${relatedEasementDocModalStyles}
`

export type RelatedEasementDocModalProps = {
	docId: number
}
export const RelatedEasementDocModal: React.FC<RelatedEasementDocModalProps> = ({ docId }) => {
	return (
		<RelatedEasementDocModalView>
			<DocDetail docId={docId} />
		</RelatedEasementDocModalView>
	)
}
