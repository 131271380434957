import { Redirect, RouteComponentProps } from '@reach/router'
import React from 'react'
import CardsSection from '../../Components/common/CardsSection'
import { GenEasementModal } from '../../Components/common/Easement/Easement.gen.modal'
import { PageOperation } from '../../Components/common/Operations'
import Page from '../../Components/common/Resources/Resource.page'
import CreateEasement from '../../Components/Ops/Ops.Easements/Ops.Easement.create'
import { Card, Modal } from '../../Components/UI'
import { easementsPageColsConfig } from '../../Components/UI/Table.resources/Table.resource.col.configs/Easement.col.configs'
import { ResourcesTable } from '../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../Components/UI/Table.resources/usePaginationParams'
import { usePaginatedEasementsQuery } from '../../gql_generated/graphql'
import { usePageData, useState } from '../../utils'

const Easements: React.FC<RouteComponentProps> = () => {
	usePageData({ pageType: 'easements', pageTitle: 'Easements' })
	const [Op, setOp] = useState<string | null>(null, 'Op')
	const [redirect, setRedirect] = useState<string | null>(null, 'redirect')

	const paginationStore = usePaginationParams()

	const [fetchResults] = usePaginatedEasementsQuery({
		variables: {
			params: {
				...paginationStore.params,
			},
		},
	})
	const { data } = fetchResults || {}
	const easements = data?.page?.easements

	const operations: PageOperation[] = [
		{
			txt: 'New Easement',
			icon: 'easement',
			op: () => setOp('create'),
		},
	]

	return redirect ? (
		<Redirect to={redirect} noThrow />
	) : (
		<Page isLoaded operations={operations}>
			<CardsSection>
				<Card>
					<ResourcesTable
						dataset={easements}
						colsConfig={easementsPageColsConfig}
						fetchResults={fetchResults}
						paginationStore={paginationStore}
						modalRenderFunc={easement => <GenEasementModal easementId={easement.id} />}
						includeSearch
						resourceType='easement'
					/>
				</Card>
			</CardsSection>
			<Modal show={!!Op} closeHandler={() => setOp(null)}>
				{Op === 'create' ? (
					<CreateEasement
						cb={(easement: any) => {
							const id = easement?.data?.createEasement.id
							if (id) setRedirect(`/mgr/easements/${id}`)
							else {
								setOp(null)
							}
						}}
					/>
				) : null}
			</Modal>
		</Page>
	)
}
export default Easements
