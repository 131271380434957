import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useAssignEstateActivitiesMutation } from '../../../../gql_generated/graphql'
import { useState } from '../../../../utils'
import { Btn, ErrorBlock, Form, SelectOrgField, SubmitBtn, toastit } from '../../../UI'
import { ResourceTableGlobalOpComponentOptions } from '../Table.resources'

const validationSchema = Yup.object().shape({
	assigneeId: Yup.number().required('Please select an assignee...'),
})

const AssignRelatedParcelsOpView = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.btns {
		align-items: center;
		button {
			margin: 0;
		}
	}
`

type AssignRelatedParcelsOpProps = ResourceTableGlobalOpComponentOptions

export const AssignRelatedParcelsOp: React.FC<AssignRelatedParcelsOpProps> = ({
	parentId,
	parentType,
	isTotalQtySelected,
	selectedRowIds,
	deselectedRowIds,
	closeModal,
}) => {
	const [error, setError] = useState<any | null>(null, 'error')
	const [_, assignActivity] = useAssignEstateActivitiesMutation()

	const submitHandler = async (vals: any) => {
		try {
			const res = await assignActivity({
				assigneeId: vals.assigneeId,
				parentId,
				parentType,
				parcelIds: selectedRowIds,
				deselectedParcelIds: deselectedRowIds,
				isTotalQtySelected,
			})

			if (res.error) throw res.error

			closeModal()
		} catch (err: any) {
			setError(err)
			toastit.err('Error assigning parcel(s)')
		}
	}

	return (
		<AssignRelatedParcelsOpView>
			{error ? <ErrorBlock error={error} /> : null}
			<Form
				onSubmit={submitHandler}
				validationSchema={validationSchema}
				legend='Assign parcels'
				initialValues={{
					assigneeId: '',
				}}
			>
				{() => (
					<>
						<SelectOrgField name='assigneeId' label='Select Assignee' isReq allowDba={false} />
						<div className='flex btns'>
							<SubmitBtn>Assign Parcels</SubmitBtn>
							<Btn onClick={closeModal}>Cancel</Btn>
						</div>
					</>
				)}
			</Form>
		</AssignRelatedParcelsOpView>
	)
}
