import React from 'react'
import styled, { css } from 'styled-components/macro'
import { OrgDetail } from '../../../Components/common/Org/Org.detail'

export const relatedAgreementOrgModalStyles = css``

const RelatedAgreementOrgModalView = styled.div`
	${relatedAgreementOrgModalStyles}
`

export type RelatedAgreementOrgModalProps = {
	orgId: number
}
export const RelatedAgreementOrgModal: React.FC<RelatedAgreementOrgModalProps> = ({ orgId }) => {
	return (
		<RelatedAgreementOrgModalView>
			<OrgDetail orgId={orgId} />
		</RelatedAgreementOrgModalView>
	)
}
