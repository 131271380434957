import { navigate, RouteComponentProps } from '@reach/router'
// import PropTypes from 'prop-types'
import toNumber from 'lodash/toNumber'
import React, { useEffect } from 'react'
import Cards from '../../Components/common/CardsColumns'
import CardsSection from '../../Components/common/CardsSection'
import ExhibitForm from '../../Components/common/Exhibit'
import { PageOperation } from '../../Components/common/Operations'
import Page from '../../Components/common/Resources/Resource.page'
import SaleAcres from '../../Components/common/Sale/Sale.acres'
import SaleDates from '../../Components/common/Sale/Sale.dates'
import SaleFinancialInfo from '../../Components/common/Sale/Sale.finances'
import SaleInfo from '../../Components/common/Sale/Sale.info'
import SaleOg from '../../Components/common/Sale/Sale.og'
import { AddDocToResourceOp } from '../../Components/Ops/Ops.AddDocToResource'
import OpCancelSale from '../../Components/Ops/Ops.Sales/Ops.Sale.cancel'
import OpSaleCB from '../../Components/Ops/Ops.Sales/Ops.Sale.cb'
import OpCloseSale from '../../Components/Ops/Ops.Sales/Ops.Sale.close'
import OpsSaleEdit from '../../Components/Ops/Ops.Sales/Ops.Sale.edit'
import OpForecloseSale from '../../Components/Ops/Ops.Sales/Ops.Sale.foreclose'
import OpReopenSale from '../../Components/Ops/Ops.Sales/Ops.Sale.reopenSale'
import { Alert, Async, Card, Colorize, H5, Modal, P, useToaster } from '../../Components/UI'
import { SaleStatus, UserRole } from '../../gql_generated/document_types'
import {
	Sale,
	useDeleteSaleMutation,
	useSaleFullQuery,
	useUpdateEstateConveyenceMutation,
} from '../../gql_generated/graphql'
import { usePageData, useState } from '../../utils'
import SaleRelatedResources from './Related/Sale.related'

interface salePageProps extends RouteComponentProps {
	id?: number
}

const SalePage: React.FC<salePageProps> = props => {
	const id = toNumber(props.id)
	const [fetchResults, refetch] = useSaleFullQuery({ variables: { id } })
	const { data, fetching } = fetchResults

	const [_, updateEstateConveyence] = useUpdateEstateConveyenceMutation()
	const [__, deleteSale] = useDeleteSaleMutation()

	const toast = useToaster()

	const [Op, setOp] = useState<string | null>(null, 'Op')

	const { sale } = data || {}

	const { status, escrowId, notes } = sale || {}

	const [updatePageInfo] = usePageData({ pageType: 'sale' })

	useEffect(() => {
		updatePageInfo({ pageTitle: `Escrow ID: ${escrowId}`, status })
	}, [id, escrowId, updatePageInfo, status])

	const opCb = async () => {
		await refetch()
		setOp(null)
	}

	const operations: PageOperation[] = [
		{
			txt: 'Close Sale',
			icon: 'convey',
			op: () => setOp('close'),
			hide: sale?.status === SaleStatus.Closed || (sale?.activity?.length || 0) === 0,
		},
		{
			txt: 'Edit',
			icon: 'edit',
			op: () => setOp('edit'),
			hide: sale?.status === SaleStatus.Closed,
		},
		{
			txt: 'Carryback',
			icon: 'cb',
			op: () => setOp('cb'),
			hide: sale?.status === SaleStatus.Closed,
		},
		{
			txt: 'Cancel Sale',
			icon: 'cancel',
			op: () => setOp('cancel'),
			hide: sale?.status !== SaleStatus.Open,
		},
		{
			txt: 'Foreclose',
			icon: 'foreclose',
			op: () => setOp('foreclose'),
			hide: sale?.status !== SaleStatus.Closed,
		},
		{
			txt: 'Reopen Sale',
			icon: 'open',
			op: () => setOp('open'),
			hide: sale?.status === SaleStatus.Open || !sale?.activity?.length,
		},
		{
			txt: 'Generate Exhibit',
			icon: 'exhibit',
			op: () => setOp('exhibit'),
			hide: !sale?.activity?.length,
			allowed: [UserRole.Accountant, UserRole.Officer],
		},
		// {
		// 	txt: 'Update Estates',
		// 	icon: 'update',
		// 	op: () => setOp('updateEstates'),
		// 	hide: sale?.status !== SaleStatus.Closed,
		// },
		{
			txt: 'Add Doc',
			icon: 'doc',
			op: () => setOp('doc'),
		},
		{
			txt: 'Delete',
			icon: 'x',
			op: () => setOp('delete'),
		},
	]

	const deleteOp = async (shouldDelete: boolean) => {
		if (shouldDelete) {
			try {
				await deleteSale({ id: toNumber(id) })
				navigate('/mgr/sales')
			} catch (err) {
				toast('Error deleting sale', { type: 'err' })
			}
		} else setOp(null)
	}

	const updateEstates = async () => {
		try {
			await updateEstateConveyence({
				parentId: toNumber(id),
				parentType: 'sale',
			})
			toast('Estates updated', { type: 'ok' })
		} catch (err) {
			toast('Error updating estates', { type: 'err' })
		}
	}

	return (
		<Page isLoaded={!fetching} operations={operations}>
			<Async fetchResults={fetchResults}>
				<CardsSection isCollapsable>
					<Cards className='col'>
						<Card>
							<SaleInfo saleId={id} />
						</Card>
						<Card titleText='Acres'>
							<SaleAcres saleId={id} />
						</Card>
						<Card titleText='Dates'>
							<SaleDates saleId={id} />
						</Card>
					</Cards>
					<Cards className='col'>
						<Card titleText='Financial'>
							<SaleFinancialInfo saleId={id} />
						</Card>
						<Card titleText='Original (OG) data from Xogenous'>
							<SaleOg saleId={id} />
						</Card>
					</Cards>
					<Cards className='full-col'>
						{notes ? (
							<Card titleText='Notes'>
								<P asRT>{notes}</P>
							</Card>
						) : null}
					</Cards>
				</CardsSection>
				<SaleRelatedResources saleId={id} />
			</Async>

			<Modal
				show={!!Op && Op !== 'delete' && Op !== 'updateEstates'}
				closeHandler={() => setOp(null)}
			>
				{Op === 'close' ? (
					<OpCloseSale sale={sale as Sale} cb={opCb} />
				) : Op === 'edit' ? (
					<OpsSaleEdit id={id} cb={opCb} />
				) : Op === 'cb' ? (
					<OpSaleCB id={id} cb={opCb} />
				) : Op === 'foreclose' ? (
					<OpForecloseSale sale={sale as Sale} cb={opCb} />
				) : Op === 'open' ? (
					<OpReopenSale sale={sale as Sale} cb={opCb} />
				) : Op === 'cancel' ? (
					<OpCancelSale sale={sale as Sale} cb={opCb} />
				) : Op === 'exhibit' ? (
					<ExhibitForm resource={{ sale: sale as Sale }} cb={() => setOp(null)} />
				) : Op === 'doc' ? (
					<AddDocToResourceOp parentId={id} parentType='sale' cb={opCb} />
				) : null}
			</Modal>
			{Op === 'updateEstates' ? (
				<Alert
					msg={
						<div>
							<H5 mBot>Update conveyence status on all connected parcel estates?</H5>
							<P>
								(This will look at the sales and acquisitions history for each parcel estate on this
								sale to calculate conveyence for that estate)
							</P>
						</div>
					}
					cb={update => {
						if (update) updateEstates()
						setOp(null)
					}}
				/>
			) : null}
			{Op === 'delete' ? (
				<Alert
					msg='Are you sure you want to delete this sale? (Cannot be undone)'
					cb={deleteOp}
					okBtn='Cancel'
					cancelBtn={
						<Colorize color='err' asBlock>
							Delete Permanently
						</Colorize>
					}
					useCancelAsOp
				/>
			) : null}
		</Page>
	)
}
export default SalePage
