import React from 'react'
import styled, { css } from 'styled-components/macro'
import { EstateDetail } from './Estate.detail'

export const genEstateModalStyles = css``

const GenEstateModalView = styled.div`
	${genEstateModalStyles}
`

export type GenEstateModalProps = {
	estateId: number
}
export const GenEstateModal: React.FC<GenEstateModalProps> = ({ estateId }) => {
	return (
		<GenEstateModalView>
			<EstateDetail estateId={estateId} />
		</GenEstateModalView>
	)
}
