import { useFormikContext } from 'formik'
import styled, { css } from 'styled-components'
import * as Yup from 'yup'
import { propertyTaxYearOptions } from '../../../utils'
import { toNumber } from '../../../utils/lodash.utils'
import { PriceField, Text } from '../../UI'
import Select from '../../UI/Form.Fields/Select'

export const propertyTaxYupSchema = (isUpdate?: boolean) =>
	Yup.object().shape({
		amount: Yup.string().required('Please enter an amount'),
		year: isUpdate ? Yup.string().required('Please enter a year') : Yup.string(),
	})

export const propertyTaxFieldsStyles = css``

const PropertyTaxFieldsView = styled.div`
	${propertyTaxFieldsStyles}
`
const nextYear = new Date().getFullYear() + 1

export type PropertyTaxFieldsProps = {
	isUpdate?: boolean
}
export const PropertyTaxFields = ({ isUpdate }: PropertyTaxFieldsProps): JSX.Element => {
	const { values } = useFormikContext<{ year: string }>()
	const year = toNumber(values?.year)
	const yearProps = {
		name: 'year',
		label: 'Expiration Year',
		helperTxt: `Tax year: ${year - 1} - ${year}`,
		isReq: isUpdate,
	}
	const canEditYear = year > nextYear - propertyTaxYearOptions.length
	return (
		<PropertyTaxFieldsView>
			<PriceField name='amount' decimals={2} label='Amount' isReq />
			{canEditYear ? (
				<Select {...yearProps} options={propertyTaxYearOptions} />
			) : (
				<Text {...yearProps} disabled />
			)}
		</PropertyTaxFieldsView>
	)
}
