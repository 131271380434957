import React from 'react'
import styled, { css } from 'styled-components/macro'
import { AcquisitionDetail } from '../../../Components/common/Acquisition/Acquisition.detail'

export const relatedParcelAcquisitionModalStyles = css``

const RelatedParcelAcquisitionModalView = styled.div`
	${relatedParcelAcquisitionModalStyles}
`

export type RelatedParcelAcquisitionModalProps = {
	acquisitionId: number
}
export const RelatedParcelAcquisitionModal: React.FC<RelatedParcelAcquisitionModalProps> = ({
	acquisitionId,
}) => {
	return (
		<RelatedParcelAcquisitionModalView>
			<AcquisitionDetail acquisitionId={acquisitionId} />
		</RelatedParcelAcquisitionModalView>
	)
}
