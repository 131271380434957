import React from 'react'
import styled, { css } from 'styled-components/macro'
import {
	salesEscrowIdColConfig,
	salesGranteeColConfig,
	salesStatusColConfig,
} from '../../../Components/UI/Table.resources/Table.resource.col.configs/Sale.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { SaleParent } from '../../../gql_generated/document_types'
import { usePaginatedSalesQuery } from '../../../gql_generated/graphql'
import { RelatedDocSaleModal } from '../Related.Modals/Doc.related.sale.modal'

export const relatedDocSalesStyles = css``

const RelatedDocSalesView = styled.div`
	${relatedDocSalesStyles}
`

export type RelatedDocSalesProps = {
	docId: number
}

export const RelatedDocSales: React.FC<RelatedDocSalesProps> = ({ docId }) => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedSalesQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: SaleParent.Doc,
						parentIds: [docId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const sales = data?.page?.sales
	return (
		<RelatedDocSalesView>
			<ResourcesTable
				dataset={sales}
				colsConfig={[salesEscrowIdColConfig, salesGranteeColConfig, salesStatusColConfig]}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={sale => <RelatedDocSaleModal saleId={sale.id} />}
				resourceType='sale'
			/>
		</RelatedDocSalesView>
	)
}
