import React from 'react'
import styled, { css } from 'styled-components/macro'
import { LogDetail } from '../../../Components/common/Log/Log.detail'

export const relatedAcquisitionLogModalStyles = css``

const RelatedAcquisitionLogModalView = styled.div`
	${relatedAcquisitionLogModalStyles}
`

export type RelatedAcquisitionLogModalProps = {
	logId: number
}
export const RelatedAcquisitionLogModal: React.FC<RelatedAcquisitionLogModalProps> = ({
	logId,
}) => {
	return (
		<RelatedAcquisitionLogModalView>
			<LogDetail logId={logId} />
		</RelatedAcquisitionLogModalView>
	)
}
