import { useField } from 'formik'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { FormFieldWrap, FormFieldWrapProps } from '.'
import { useState } from '../../../utils'

export const MultiTextFieldView = styled.div`
	input {
		display: block;
		width: 100%;
	}
`

export type MultiTextFieldItem = {
	id?: number
	name: string
}

const Input: React.FC<{
	idx: number
	value: MultiTextFieldItem
	values: MultiTextFieldItem[]
	setValues: (value: MultiTextFieldItem[], shouldValidate?: boolean | undefined) => void
	setFocus: (idx: number) => void
	hasFocus: boolean
}> = ({ idx, value, values, setValues, hasFocus, setFocus }) => {
	const element = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (element.current && hasFocus) element.current.focus()
	}, [hasFocus])

	return (
		<input
			ref={element}
			value={value.name}
			onKeyDown={(e: any) => {
				const key = e.key || e.keyCode
				const val = e.target.value
				if (key === 'Backspace' && !val?.length && values.length > 1) {
					const newVals = [...values]
					newVals.splice(idx, 1)
					setValues(newVals)
					setFocus(idx - 1)
				} else if (key === 'Enter') {
					e.preventDefault()
					setValues([...values, { name: '' }])
					setFocus(idx + 1)
				}
			}}
			onChange={(e: any) => {
				const name = e.target.value
				const { id } = value
				const newVals = [...values]
				const newVal: MultiTextFieldItem = { name }
				if (id) newVal.id = id
				newVals[idx] = newVal
				setValues(newVals)
				e.preventDefault()
			}}
		/>
	)
}

export type MultiTextFieldProps = FormFieldWrapProps & {}
export const MultiTextField: React.FC<MultiTextFieldProps> = ({ name, ...props }) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [{ value }, _, { setValue }] = useField<MultiTextFieldItem[]>({ name })
	// const lastItemIdx = value.length ? value.length - 1 : 0
	const [focusIdx, setFocusIdx] = useState(0, 'focusIdx')

	const fieldWrapProps = {
		...props,
		name,
		type: 'text',
	}
	return (
		<MultiTextFieldView>
			<FormFieldWrap {...fieldWrapProps}>
				{value.map((item, idx) => (
					<Input
						value={item}
						values={value}
						setValues={setValue}
						idx={idx}
						key={idx}
						setFocus={setFocusIdx}
						hasFocus={focusIdx ? focusIdx === idx : false}
					/>
				))}
			</FormFieldWrap>
		</MultiTextFieldView>
	)
}
