import styled from 'styled-components'
const UL = styled.ul`
	margin: 0.5em 1em 0.5em 2em;
	list-style-type: disc;
	color: ${props => props.theme.colors.grey.dark().val};
	li {
		display: list-item;
		padding-bottom: 0.5em;
	}
`
export default UL
