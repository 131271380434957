import { useField } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { Btn } from '../Typography'
import FormFieldWrap, { FormFieldRawWrap, FormFieldWrapProps } from './Form.field.wrap'
import { InputProps } from './Input'

type ToggleStyleProps = {
	isSmall?: boolean
}
export const ToggleView = styled.div<ToggleStyleProps>`
	input {
		display: none;
	}
	.btn {
		border: solid 1px;
		border-radius: 40px;
		display: flex;
		padding: 0;
		width: ${props => (props.isSmall ? '23px' : '40px')};
		color: ${props => props.theme.colors.btn.val};
		background: ${props => props.theme.colors.grey.light().val};
		transition: all ${props => props.theme.times.tranS};
		&:before {
			content: '';
			display: block;
			height: ${props => (props.isSmall ? '10px' : '20px')};
			width: ${props => (props.isSmall ? '10px' : '20px')};
			border: solid 1px;
			background: ${props => props.theme.colors.white.val};
			border-radius: 20px;
		}
		&.__true {
			background: ${props => props.theme.colors.ok.val};
			flex-direction: row-reverse;
		}
	}
`
type ToggleRawProps = ToggleStyleProps &
	FormFieldWrapProps & {
		onClick: (state: boolean) => void
		isOn: boolean
		label?: string
		name?: string
		className?: string
	}

export const ToggleRaw: React.FC<ToggleRawProps> = ({
	onClick,
	isOn,
	name,
	className,
	isSmall,
	...wrapProps
}) => {
	return (
		<FormFieldRawWrap
			name={name || 'toggle-raw-wrap'}
			{...wrapProps}
			className={`toggle-field${className ? ` ${className}` : ''}`}
		>
			<ToggleView isSmall={isSmall}>
				<Btn className={isOn ? '__true' : '__false'} onClick={() => onClick(!isOn)} />
			</ToggleView>
		</FormFieldRawWrap>
	)
}

type ToggleFieldProps = InputProps & FormFieldWrapProps & ToggleStyleProps & {}
const ToggleField: React.FC<ToggleFieldProps> = ({
	name,
	label,
	isReq,
	helperTxt,
	isSimple,
	...props
}) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [{ value }, _, { setValue }] = useField({ name, ...props })

	return (
		<FormFieldWrap
			name={name}
			label={label}
			isReq={isReq}
			type='toggle'
			isSimple={isSimple}
			helperTxt={helperTxt}
		>
			<ToggleView>
				<Btn className={value ? '__true' : '__false'} onClick={() => setValue(!value)} />
				<input name={name} value={value} type='checkbox' />
			</ToggleView>
		</FormFieldWrap>
	)
}
export default ToggleField
