import { useField } from 'formik'
import styled, { css } from 'styled-components'
import { EstateTypeEnum } from '../../../Types'
import { estateTypes } from '../../../utils'
import { Icon } from '../Icon'
import { FormFieldWrapProps } from './Form.field.wrap'

export const selectEstateTypesStyles = css`
	padding: 0.5em 1em;
	border: solid 1px ${props => props.theme.colors.grey.val};
	border-radius: 5px;
	background: ${props => props.theme.colors.paleGrey.val};

	.label {
		color: ${props => props.theme.colors.grey.val};
		margin-bottom: 0.5em;
	}
	.input-group {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-gap: 0.5em;
		height: 34px;
	}
	.estate-type-input {
		.icon {
			margin-left: 3px;
		}
	}
`

const SelectEstateTypesView = styled.div`
	${selectEstateTypesStyles}
`

export type SelectEstateTypesProps = FormFieldWrapProps & { className?: string }
export const SelectEstateTypes = ({
	name,
	label,
	className,
}: SelectEstateTypesProps): JSX.Element => {
	const [{ value }, { touched }, { setValue, setTouched }] = useField({ name })
	return (
		<SelectEstateTypesView className={`select-estate-types${className ? ` ${className}` : ''}`}>
			<div className='label'>{label}</div>
			<div className='input-group'>
				{estateTypes.map(({ type }) => (
					<label className='estate-type-input' key={type}>
						<input
							type='checkbox'
							value={type}
							checked={value.includes(type)}
							onChange={e => {
								if (!touched) setTouched(true)
								const val = e.currentTarget.value
								const newVals = [...value]
								const idx = newVals.findIndex(v => v === val)
								if (idx >= 0) newVals.splice(idx, 1)
								else newVals.push(val)
								setValue(newVals)
							}}
						/>

						<Icon type={type as EstateTypeEnum} />
					</label>
				))}
			</div>
		</SelectEstateTypesView>
	)
}
