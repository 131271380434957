import React from 'react'
import { useSaleForInfoQuery } from '../../../gql_generated/graphql'
import { formatStatus } from '../../../utils'
import { KeyVals } from '../../UI'

type SaleInfoProps = {
	saleId: number
}

const SaleInfo: React.FC<SaleInfoProps> = ({ saleId }) => {
	const [results] = useSaleForInfoQuery({ variables: { id: saleId } })
	const sale = results.data?.sale
	const {
		id,
		escrowId,
		grantee,
		granteeDba,
		broker,
		brokerDba,
		titleCompany,
		titleCompanyDba,
		status,
	} = sale || {}

	return (
		<KeyVals
			items={[
				['DB ID', id],
				['Escrow ID', escrowId],
				['Grantee', granteeDba || grantee, 'org'],
				['Broker', brokerDba || broker, 'org'],
				['Title Company', titleCompanyDba || titleCompany, 'org'],
				['Status', formatStatus(status)],
			]}
		/>
	)
}
export default SaleInfo
