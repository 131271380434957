import React from 'react'
import { StepWizardChildProps } from 'react-step-wizard'
import styled from 'styled-components'
import { Btn, H5, Icon } from '../../../UI'
import { useModalCtrl } from '../../../UI/Modal'
import { OpsDocCreate } from '../../Ops.Docs/Ops.Doc.create'
import { AddDocToResourceOpProps } from '../Ops.AddDocToResource'
import { AddDocToResourcePreview } from '../Ops.AddDocToResource.Preview'
import { AddDocToResourceSelectDocSelect } from './Ops.AddDocToResource.SelectDoc.select'

export const AddDocToResourceSelectDocView = styled.div`
	${props => props.theme.media.sdesk} {
		min-width: 80vw;
	}

	& > header {
		display: flex;
		padding-bottom: 1em;

		& > button {
			margin-left: auto;
		}
	}

	.select-doc-panes {
		${props => props.theme.media.sdesk} {
			display: flex;

			& > * {
				flex-grow: 1;
				min-width: 50%;
			}
		}
	}
	.create-doc-screen {
		${props => props.theme.media.sdesk} {
			padding: 0 3vw;
		}

		legend {
			border: none;
			text-align: center;
		}
	}
	.doc-preview {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		${props => props.theme.media.sdesk} {
			padding: 0 5vw;
		}
	}
`

export type AddDocToResourceSelectDocProps = Pick<
	AddDocToResourceOpProps,
	'parentId' | 'parentType'
> & {
	docId: number | null
	setDocId: React.Dispatch<React.SetStateAction<number | null>>
}
export const AddDocToResourceSelectDoc: React.FC<AddDocToResourceSelectDocProps> = ({
	parentId,
	parentType,
	docId,
	setDocId,
	...stepProps
}) => {
	const { nextStep } = stepProps as StepWizardChildProps

	const [[showModal, openModal, closeModal]] = useModalCtrl()

	return (
		<AddDocToResourceSelectDocView>
			<header>
				<H5>Select Doc</H5>
				<Btn
					className='cta'
					onClick={() => {
						if (docId) nextStep()
						else openModal()
					}}
				>
					{docId ? (
						<>
							Next
							<Icon type='arrowRight' padLeft />
						</>
					) : (
						<>
							<Icon type='doc' padRight />
							New Doc
						</>
					)}
				</Btn>
			</header>
			<div className='select-doc-panes'>
				<AddDocToResourceSelectDocSelect
					docId={docId}
					setDocId={setDocId}
					showPreview={closeModal}
				/>
				{showModal ? (
					<div className='create-doc-screen'>
						<OpsDocCreate
							parentId={parentId}
							parentType={parentType}
							cb={docId => {
								setDocId(docId)
								nextStep()
							}}
							submitBtnTxt='Add doc'
						/>
					</div>
				) : (
					<div className='doc-preview'>
						<AddDocToResourcePreview docId={docId} />
					</div>
				)}
			</div>
		</AddDocToResourceSelectDocView>
	)
}
