import React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from '@reach/router'
import Header from '../Components/Header/Header.public'
import Main from '../Components/common/Main'
import MainFooter from '../Components/Footer'

const Layout = styled.div``

const PublicLayout: React.FC<RouteComponentProps> = ({ children }) => {
	return (
		<Layout className='layout-public'>
			<Header />
			<Main className='page-content'>
				<div className='content'>{children}</div>
			</Main>
			<MainFooter className='main-footer' />
		</Layout>
	)
}
PublicLayout.propTypes = {}
export default PublicLayout
