import React from 'react'
import styled, { css } from 'styled-components/macro'
import { LeaseDetail } from '../../../Components/common/Lease/Lease.detail'

export const relatedDocLeaseModalStyles = css``

const RelatedDocLeaseModalView = styled.div`
	${relatedDocLeaseModalStyles}
`

export type RelatedDocLeaseModalProps = {
	leaseId: number
}
export const RelatedDocLeaseModal: React.FC<RelatedDocLeaseModalProps> = ({ leaseId }) => {
	return (
		<RelatedDocLeaseModalView>
			<LeaseDetail leaseId={leaseId} />
		</RelatedDocLeaseModalView>
	)
}
