import get from 'lodash/get'
import React from 'react'
import styled from 'styled-components'
import { useStoreState } from '../../store'
import CtrlBar from './Resources/Resource.ctrlBar'

const CardsView = styled.section<{ isCollapsable?: boolean }>`
	${props => props.theme.media.sdesk} {
		padding-top: ${props => (props.isCollapsable ? 0 : get(props.theme.sizes, 'alertbar.val'))};
	}
	.content-cards {
		transition: all ${props => props.theme.times.tranM};
		overflow: hidden;
	}
	.inner-grid {
		${props => props.theme.media.sdesk} {
			grid-gap: 16px;
			width: 100%;
			display: flex;
			align-items: stretch;
			flex-wrap: wrap;
			padding-bottom: 2em;
		}

		& > .col {
			border: none;

			${props => props.theme.media.sdesk} {
				display: grid;
				flex-grow: 1;
				grid-gap: 16px;
			}

			& > * {
				flex-grow: 1;
			}

			& > .card {
			}
		}
		.full-col {
			min-width: 100%;
			max-width: 100%;
		}
	}
`

type CardsSectionProps = {
	className?: string
	isCollapsable?: boolean
}

const CardsSection: React.FC<CardsSectionProps> = ({
	children,
	className,
	isCollapsable = false,
}) => {
	const { isCardsOpen } = useStoreState(state => state.view)
	return (
		<CardsView className={`cards${className ? ` ${className}` : ''}`} isCollapsable={isCollapsable}>
			{isCollapsable && <CtrlBar />}
			<div
				className='content-cards'
				style={isCollapsable ? { maxHeight: isCardsOpen ? '300vh' : '0' } : {}}
			>
				<div className='inner-grid grid'>{children}</div>
			</div>
		</CardsView>
	)
}
export default CardsSection
