import React from 'react'
import { ErrorBlock, Spinner } from '..'

export type AsyncProps = {
	fetchResults: {
		loading?: boolean
		fetching?: boolean
		error?: any
		[x: string]: any
	}
}
export const Async: React.FC<AsyncProps> = ({ fetchResults, children }) => {
	const { loading, fetching, error } = fetchResults
	return <>{loading || fetching ? <Spinner /> : error ? <ErrorBlock error={error} /> : children}</>
}
