import { Link } from '@reach/router'
import React from 'react'
import styled, { css } from 'styled-components/macro'
import RoyaltyInfo from '../../../Components/common/Royalty/Royalty.info'
import { Async, Card, Icon, P } from '../../../Components/UI'
import { useRoyaltyFullQuery } from '../../../gql_generated/graphql'
import { parseResourceType } from '../../../utils'
import { IconType } from '../../UI/Icon/fa.defaults'

export const royaltyDetailStyles = css`
	section.cols {
		display: block;

		${props => props.theme.media.tablet} {
			display: flex;

			& > .col {
				flex-grow: 1;
				width: 50%;
			}
		}
	}
	.goto-section {
		display: flex;
		padding: 1em 0;
		flex-direction: row-reverse;
	}
	.goto-parent-link {
		display: flex;
		align-items: center;
		padding: 1em 0;
		text-transform: uppercase;
	}
`

const RoyaltyDetailView = styled.div`
	${royaltyDetailStyles}
`

export type RoyaltyDetailProps = {
	royaltyId: number
	showGoTo?: boolean
}
export const RoyaltyDetail: React.FC<RoyaltyDetailProps> = ({ royaltyId, showGoTo }) => {
	const [results] = useRoyaltyFullQuery({ variables: { id: royaltyId } })
	const royalty = results.data?.royalty

	const { leaseId, saleId, description } = royalty || {}

	const parentId = saleId || leaseId
	const parentType = saleId ? 'sale' : leaseId ? 'lease' : null

	const { plural } = parseResourceType(parentType)

	return (
		<RoyaltyDetailView>
			<Async fetchResults={results}>
				{royalty ? (
					<>
						<section className='cols'>
							<div className='col'>
								<Card mBot noShadow titleText='Royalty Info'>
									<RoyaltyInfo royaltyId={royaltyId} />
								</Card>
							</div>
							<div className='col'>
								<Card noShadow titleText='Description'>
									<P asRT>{description}</P>
								</Card>
							</div>
						</section>
						{showGoTo ? (
							<section className='goto-section'>
								{parentId ? (
									<Link className='goto-parent-link' to={`/mgr/${plural}/${parentId}`}>
										Go to {parentType}
										<Icon type={parentType as IconType} padLeft />
										<Icon type='arrowRight' padLeft />
									</Link>
								) : null}
							</section>
						) : null}
					</>
				) : null}
			</Async>
		</RoyaltyDetailView>
	)
}
