import sumBy from 'lodash/sumBy'
import React from 'react'
import { useRoyaltyForGenInfoQuery } from '../../../gql_generated/graphql'
import { parseResourceType } from '../../../utils'
import { EM, Icon, KeyVals } from '../../UI'
import { IconType } from '../../UI/Icon/fa.defaults'

type RoyaltyInfoProps = {
	royaltyId: number
}
const RoyaltyInfo: React.FC<RoyaltyInfoProps> = ({ royaltyId }) => {
	const [results] = useRoyaltyForGenInfoQuery({ variables: { id: royaltyId } })
	const royalty = results.data?.royalty

	const { id, estateType, parentType, rate, rateType, status, royaltyActivity } = royalty || {}

	const { titleFull } = parseResourceType(estateType)

	return royalty ? (
		<>
			<KeyVals
				items={[
					['DB ID', id],
					[
						'Estate Type',
						<>
							<Icon type={estateType as IconType} />
							<EM padLeft='1em'>{titleFull}</EM>
						</>,
					],
					['Royalty Type', parentType, 'proper'],
					rateType === 'percentage' ? ['Rate', `${rate}%`] : ['Rate', rate, 'currency'],
					['Status', status],
					royaltyActivity?.length
						? [
								'Total Acres',
								sumBy(royaltyActivity, ({ acres, estateActivity, status }) =>
									status === 'Terminated' ||
									estateActivity.isTerminated ||
									estateActivity.assigneeId
										? 0
										: acres || estateActivity?.acres || estateActivity?.estate?.acres || 0
								),
								'round',
						  ]
						: null,
				]}
			/>
		</>
	) : null
}
export default RoyaltyInfo
