import React from 'react'
import styled from 'styled-components'

export const RelatedResourcesCardView = styled.div`
	background: ${props => props.theme.colors.white.val};
	box-shadow: 0 2px 4px ${props => props.theme.colors.black.tint().val};
	.tabs-wrap {
		padding: 1em;
	}
`

export type RelatedResourcesCardProps = {}
export const RelatedResourcesCard: React.FC<RelatedResourcesCardProps> = ({ children }) => {
	return <RelatedResourcesCardView>{children}</RelatedResourcesCardView>
}
