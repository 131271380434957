import React from 'react'
import styled, { css } from 'styled-components/macro'
import { parcelRelatedAgreementsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Agreement.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import {
	AgreementFilter,
	AgreementParent,
	AgreementStatus,
} from '../../../gql_generated/document_types'
import { usePaginatedAgreementsQuery } from '../../../gql_generated/graphql'
import { RelatedParcelAgreementModal } from '../RelatedModals/Parcel.related.agreement.modal'

export const relatedParcelAgreementsStyles = css``

const RelatedParcelAgreementsView = styled.div`
	${relatedParcelAgreementsStyles}
`

export type RelatedParcelAgreementsProps = {
	parcelId: number
}

export const RelatedParcelAgreements: React.FC<RelatedParcelAgreementsProps> = ({ parcelId }) => {
	const paginationStore = usePaginationParams()

	const [fetchResults] = usePaginatedAgreementsQuery({
		variables: {
			params: {
				...paginationStore.params,
				filterOptions: [{ filter: AgreementFilter.Statuses, vals: [AgreementStatus.Active] }],
				parentOptions: [
					{
						parentType: AgreementParent.Parcel,
						parentIds: [parcelId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.agreements
	return (
		<RelatedParcelAgreementsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={parcelRelatedAgreementsColsConfig(parcelId)}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={agreement => <RelatedParcelAgreementModal agreementId={agreement.id} />}
				resourceType='agreement'
			/>
		</RelatedParcelAgreementsView>
	)
}
