import { ColConfig, ColConfigNoField } from './Col.config.types'

export const contactFullNameColConfig: ColConfig = {
	field: 'fullName',
	label: ' ',
}

export const contactEmailColConfig: ColConfigNoField = {
	label: 'Email',
	field: 'email',
	format: {
		format: 'email',
		withCopyPaste: true,
	},
	isRowTrigger: false,
}

export const contactPhoneMobileColConfig: ColConfig = {
	field: 'phoneMobile',
	label: 'Mobile',
	format: {
		format: 'phone',
		withCopyPaste: true,
	},
	isRowTrigger: false,
}

export const contactPhoneWorkColConfig: ColConfig = {
	field: 'phoneWork',
	label: 'Work',
	format: {
		format: 'phone',
		withCopyPaste: true,
	},
	isRowTrigger: false,
}

export const contactMakePrimaryColConfig: ColConfigNoField = {
	label: 'Is Primary?',
	isRowTrigger: false,
}
