import styled from 'styled-components'
export type FormWrapProps = {
	maxWidth?: number | string
	hasLegend?: boolean
	center?: boolean
	leftBorder?: boolean
	legendSize?: string | null
	noPad?: boolean
}
const FormWrap = styled.div<FormWrapProps>`
	width: 100%;
	display: block;
	max-width: ${props => (props.maxWidth ? props.maxWidth : 'initial')};
	margin: 0 auto;
	padding: 0.5em;
	padding-top: ${props => (props.hasLegend || props.noPad ? 0 : '1em')};
	text-align: left;

	${props => props.theme.media.tablet} {
		width: auto;
	}
	legend {
		font-size: ${props => (props.legendSize ? props.legendSize : '1.5em')};
		text-transform: uppercase;
		color: ${props => props.theme.colors.grey.val};
		padding: 0.3em;
		padding-left: 0.8em;
		width: 100%;
		margin-bottom: 1em;
		border-left: 3px solid ${props => props.theme.colors.grey.light().val};
	}
	section {
		padding: 1em 0;
		legend {
			border: none;
			font-size: 1.3em;
			margin-bottom: 0.5em;
			padding: 0;
			text-transform: none;
		}
	}
	& > .flex {
		width: 100%;
		& > * {
			margin: 0;
			margin-right: 0.5em;
			min-width: 50px;
			&:last-child {
				margin: 0;
			}
			${props => props.theme.media.tablet} {
				flex-grow: 1;
			}
			input {
				max-width: 100%;
			}
		}
	}
	& > .flex-tablet {
		${props => props.theme.media.tablet} {
			& > * {
				margin: 0;
				margin-right: 0.5em;
				flex-grow: 1;
				&:last-child {
					margin: 0;
				}
			}
		}
	}
	.field-wrap {
		margin-left: ${props => (props.leftBorder ? '0.5em' : '0')};
		padding-left: ${props => (props.leftBorder ? '1em' : '0')};
		padding-bottom: ${props => (props.leftBorder ? '1em' : '0')};
		border-left: ${props => (props.leftBorder ? 'solid 3px' : 'none')};
		border-color: ${props => props.theme.colors.secondary.val};
		${props => (props.leftBorder ? 'margin-bottom: 0' : '')};
		&:first-child {
			padding-top: ${props => (props.leftBorder ? '1em' : '0')};
		}
		${props => props.theme.media.tablet} {
			/* margin-left: 0; */
			padding-left: ${props => (props.leftBorder ? '2em' : '0')};
		}
	}
	.submit-btn {
		width: 100%;
	}
	.submit-btn,
	.ok-cancel-btns {
		margin-top: 1em;
	}
	.ok-cancel-btns {
		display: flex;
		align-items: center;
		.submit-btn {
			margin-top: 0;
		}
		.btn:last-child {
			margin-left: 1em;
		}
	}
`
export default FormWrap
