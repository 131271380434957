import React from 'react'
import { RouteComponentProps, Router } from '@reach/router'

import Protect from '../Components/common/Protect'
import Page404 from '../Pages/404.page'

import Easements from '../Pages/Easements'
import Easement from '../Pages/Easement'

const EasementRoutes: React.FC<RouteComponentProps> = () => {
	return (
		<Protect>
			<Router>
				<Easements path='/' />
				<Easement path='/:id' />
				<Page404 default />
			</Router>
		</Protect>
	)
}
EasementRoutes.propTypes = {}
export default EasementRoutes
