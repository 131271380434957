import React from 'react'
import { Redirect, RouteComponentProps } from '@reach/router'
import { useLogout } from '../../../auth'
import { Spinner } from '../../../Components/UI'

import { useStoreState } from '../../../store'

const LogoutPage: React.FC<RouteComponentProps> = () => {
	const logout = useLogout()
	const { isAuthed } = useStoreState(state => state.user)

	if (isAuthed) logout()

	return isAuthed ? <Spinner /> : <Redirect to='/' noThrow />
}
export default LogoutPage
