import React, { useRef } from 'react'
import styled from 'styled-components'
import { ContactInput, useCreateContactMutation } from '../../../gql_generated/graphql'
import { defaultContactVals } from '../../../utils'
import { Form, SubmitBtn } from '../../UI'
import { ContactFields, contactSchema } from './Ops.Org.fields'

const CreateContactView = styled.div``

type CreateContactProps = {
	orgId: number
	cb?: (org: any) => void
}
export const CreateContact: React.FC<CreateContactProps> = ({ cb, orgId }) => {
	const isDone = useRef(false)
	const [_, createContact] = useCreateContactMutation()
	return isDone.current ? null : (
		<CreateContactView>
			<Form<ContactInput>
				legend='Create Contact'
				initialValues={{
					orgId,
					...defaultContactVals,
				}}
				onSubmit={async data => {
					try {
						const res = await createContact({
							data,
						})
						if (cb) {
							isDone.current = true
							console.log('results: ', res)
							cb(res)
						}
					} catch (err) {
						throw err
					}
				}}
				validationSchema={contactSchema}
			>
				{() => (
					<>
						<ContactFields />
						<SubmitBtn />
					</>
				)}
			</Form>
		</CreateContactView>
	)
}
