import pick from 'lodash/pick'
import React from 'react'
import styled from 'styled-components'
import { UserRole } from '../../../gql_generated/document_types'
import { User } from '../../../gql_generated/graphql'
import { useStoreState } from '../../../store'
import { formatDate } from '../../../utils'
import { Form, SubmitBtn } from '../../UI'
import { Heading } from '../../UI/Heading'
import UserFields, { userSchema } from './Ops.User.fields'

const EditUserView = styled.div`
	.date-last-login {
		font-size: 1rem;
		color: ${props => props.theme.colors.grey.val};
		font-style: italic;
		font-weight: normal;
	}
`

type EditUserFormProps = {
	user: User
}
const EditUserForm: React.FC<EditUserFormProps> = ({ user }) => {
	const { role, me } = useStoreState(state => state.user)
	const initialVals = pick(user, ['email', 'firstName', 'lastName', 'preferences', 'roleId'])

	const isAllowed = role === UserRole.Landman || role === UserRole.SysAdmin || me?.id === user.id

	return (
		<EditUserView>
			<Form
				legend='Update Profile'
				initialValues={initialVals}
				onSubmit={() => {}}
				validationSchema={userSchema}
			>
				{() => (
					<>
						{isAllowed ? (
							<Heading className='date-last-login' mBot displayType='p-heading'>
								Last logged in:{' '}
								{user.dateLastLogin
									? formatDate(user.dateLastLogin, 'MM/dd/yyyy hh:mma')
									: `(hasn't logged in yet)`}
							</Heading>
						) : null}
						<UserFields isUpdate />
						<SubmitBtn />
					</>
				)}
			</Form>
		</EditUserView>
	)
}
export default EditUserForm
