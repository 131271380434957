import React from 'react'
import styled, { css } from 'styled-components/macro'
import { SelectRaw } from '../Form.Fields'
import { PaginationStore } from './usePaginationParams'

export const resourcesTableLimitDropdownStyles = css``

const ResourcesTableLimitDropdownView = styled.div`
	${resourcesTableLimitDropdownStyles}
`

export type ResourcesTableLimitDropdownProps = {
	paginationStore: PaginationStore
	totalQty?: number
	maxAllowed?: number | false
}
export const ResourcesTableLimitDropdown: React.FC<ResourcesTableLimitDropdownProps> = ({
	paginationStore,
	totalQty = 0,
	maxAllowed = 500,
}) => {
	const { params, updateParams } = paginationStore
	const { limit } = params
	const { setLimit } = updateParams
	const options = [15, 30, 50, 100, totalQty]
	return (
		<ResourcesTableLimitDropdownView>
			<SelectRaw
				name='resource-table-limit'
				value={limit}
				onChange={e => {
					const val = parseInt(e.target.value)
					if (val !== limit) setLimit(val)
				}}
				options={options
					.filter((amount, idx) => {
						const isLastOption = idx === options.length - 1
						const isLastOptionMoreThanMax = isLastOption && maxAllowed && amount > maxAllowed

						return isLastOptionMoreThanMax ? false : isLastOption ? true : amount < totalQty
					})
					.map(amount => ({
						val: amount,
						txt: amount === totalQty ? 'Show All' : `Show ${amount} results`,
					}))}
			/>
		</ResourcesTableLimitDropdownView>
	)
}
