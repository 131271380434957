import React from 'react'
import { useLeaseForDatesInfoQuery } from '../../../gql_generated/graphql'
import { calcPastExpiration, formatDate, getCurrentLeaseTerms } from '../../../utils'
import { Colorize, KeyVals } from '../../UI'

type LeaseDateProps = {
	leaseId: number
}
const LeaseDates: React.FC<LeaseDateProps> = ({ leaseId }) => {
	const [results] = useLeaseForDatesInfoQuery({ variables: { id: leaseId } })
	const lease = results.data?.lease
	const {
		dateEffective,
		dateShouldExpire,
		dateExpired,
		dateTerminated,
		canRenew,
		dateInsurExpires,
	} = lease || {}
	const term = getCurrentLeaseTerms(lease)
	const { dateFrom, dateTo } = term || {}
	const isPastExpiration = calcPastExpiration(lease)
	return (
		<KeyVals
			items={[
				['Primary Effective', dateEffective, 'date'],
				dateShouldExpire ? ['Primary Expiration', dateShouldExpire, 'date'] : null,
				['Current Effective', dateFrom, 'date'],

				isPastExpiration
					? ['Current Expiration', <Colorize color='err'>{formatDate(dateTo)}</Colorize>]
					: ['Current Expiration', dateTo, 'date'],

				dateExpired ? ['Actual Expiration', dateExpired, 'date'] : null,
				dateTerminated ? ['Termination Date', dateTerminated, 'date'] : null,
				['Has Renewal Option?', canRenew, 'boolean'],
				dateInsurExpires ? ['Insurance Expiration', dateInsurExpires, 'date'] : null,
			]}
		/>
	)
}
export default LeaseDates
