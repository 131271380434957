import React from 'react'
import styled, { css } from 'styled-components/macro'
import Protect from '../../../Components/common/Protect'
import { AddEditRoyaltyOnResource } from '../../../Components/Ops/Ops.Royalties/Ops.Royalty.add.edit'
import { AddRoyaltyToResourceBtn } from '../../../Components/Ops/Ops.Royalties/Ops.Royalty.add.edit/Ops.Royalty.addToResource.btn'
import { LeftBar, Modal, P, useModalCtrl } from '../../../Components/UI'
import { RemoveRoyaltiesFromResourceOp } from '../../../Components/UI/Table.resources/Table.global.ops/RemoveRoyaltiesFromResource.op'
import { saleRelatedRoyaltiesColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Royalty.col.configs'
import {
	ResourcesTable,
	ResourceTableGlobalOpConfig,
} from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { RoyaltyParent, SaleStatus } from '../../../gql_generated/document_types'
import { usePaginatedRoyaltiesQuery, useSaleFullQuery } from '../../../gql_generated/graphql'
import { PrimaryResourceType } from '../../../Types'
import { nnrMgrs } from '../../../utils'
import { RelatedSaleRoyaltyModal } from '../Related.Modals/Sale.related.royalty.modal'

export const relatedSaleRoyaltyStyles = css``

const RelatedSaleRoyaltiesView = styled.div`
	${relatedSaleRoyaltyStyles}
`

export type RelatedSaleRoyaltiesProps = {
	saleId: number
}

export const RelatedSaleRoyalties: React.FC<RelatedSaleRoyaltiesProps> = ({ saleId }) => {
	const [saleResults] = useSaleFullQuery({ variables: { id: saleId } })

	const saleActivity = saleResults.data?.sale?.activity
	const status = saleResults.data?.sale?.status

	const canEdit = !!status && status !== SaleStatus.Closed

	const paginationStore = usePaginationParams()

	const [results] = usePaginatedRoyaltiesQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: RoyaltyParent.Sale,
						parentIds: [saleId],
					},
				],
			},
		},
	})

	const dataset = results.data?.page?.royalties

	const parentProps = {
		parentId: saleId,
		parentType: 'sale' as PrimaryResourceType,
	}
	const tableOps: ResourceTableGlobalOpConfig[] = [
		{
			txt: 'Remove',
			op: props => <RemoveRoyaltiesFromResourceOp {...props} {...parentProps} />,
		},
	]

	const [[showEditModal, openEditModal, closeEditModal, currentRoyaltyId]] = useModalCtrl<number>()

	const hasParcels = saleActivity?.length

	return (
		<RelatedSaleRoyaltiesView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={saleRelatedRoyaltiesColsConfig}
				fetchResults={results}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedSaleRoyaltyModal royaltyId={data.id} />}
				tableOps={canEdit ? tableOps : undefined}
				resourceType='royalty'
				suppressGoTo
				rowOps={[
					{
						type: 'edit',
						op: royalty => openEditModal(royalty.id),
					},
				]}
			>
				<Protect allowed={nnrMgrs}>
					<LeftBar>
						{canEdit ? (
							hasParcels ? (
								<AddRoyaltyToResourceBtn parentId={saleId} parentType='sale' />
							) : (
								<P>(Please add parcels to this sale before adding royalties)</P>
							)
						) : (
							<P>(Sale must be open to add royalties)</P>
						)}
					</LeftBar>
				</Protect>
			</ResourcesTable>
			<Modal
				show={showEditModal}
				closeHandler={closeEditModal}
				size='large'
				titleText='Edit Royalty'
			>
				{currentRoyaltyId ? (
					<AddEditRoyaltyOnResource
						parentId={saleId}
						parentType='sale'
						royaltyId={currentRoyaltyId}
						cb={closeEditModal}
					/>
				) : null}
			</Modal>
		</RelatedSaleRoyaltiesView>
	)
}
