/*
 =================================================
  Container node for restricting access to components
 =================================================
* */

import { Redirect } from '@reach/router'
import React, { ReactNode } from 'react'
import { UserRole } from '../../gql_generated/document_types'
import { useProtect } from '../../utils'
import { Block, LoaderHOC } from '../UI'

export type ProtectProps = {
	enforce?: boolean
	allowed?: UserRole[]
	Denied?: ReactNode
	showDenied?: boolean
	Loader?: ReactNode
	redirect?: string
}

const Protect: React.FC<ProtectProps> = ({
	children,
	enforce = true,
	allowed,
	Denied,
	showDenied,
	Loader,
	redirect,
}) => {
	const [hasPermission, hasAuthed] = useProtect({ allowed })

	// console.log('Protect - hasAuthed:', hasAuthed, ' | hasPermission: ', hasPermission)

	const DenyAccess = Denied || (
		<Block usage='overlay' type='err'>
			You do not have access
		</Block>
	)

	const NoAccess: React.FC<any> = () =>
		redirect ? <Redirect to={redirect} noThrow /> : <>{showDenied ? DenyAccess : null}</>

	const shouldRenderRoute = hasPermission || enforce === false

	return (
		<LoaderHOC
			isDone={hasAuthed}
			Loader={Loader}
			useProgressBar={true}
			children={shouldRenderRoute ? children : <NoAccess />}
		/>
	)
}
export default Protect
