import { useCallback } from 'react'
import styled, { css } from 'styled-components'
import * as Yup from 'yup'
import { EstateInput } from '../../../gql_generated/document_types'
import { useEstateForEditQuery, useUpdateEstateMutation } from '../../../gql_generated/graphql'
import { sanitizeDataForForm } from '../../../utils'
import { Async, Form, SubmitBtn, toastit } from '../../UI'
import { EstateFields } from './Ops.Estate.fields'

const yupSchema = Yup.object().shape({
	acres: Yup.number().required('Estate must have some acreage'),
	aliquot: Yup.string().required('Estates must have an aliquot'),
})

export const editEstateOpStyles = css`
	width: 100%;

	${props => props.theme.media.tablet} {
		padding: 2em 3vw;

		form {
			min-width: 300px;
		}
	}
`

const EditEstateOpView = styled.div`
	${editEstateOpStyles}
`

export type EditEstateOpProps = {
	estateId: number
	cb?: () => void
}
export const EditEstateOp = ({ estateId, cb }: EditEstateOpProps): JSX.Element => {
	const [results] = useEstateForEditQuery({ variables: { id: estateId } })
	const estateData = results.data?.estate
	const initialVals = (estateData &&
		sanitizeDataForForm(estateData, { stripKeys: ['parcelId', 'id'] })) as EstateInput

	const [{ error }, mutate] = useUpdateEstateMutation()
	const handleSubmit = useCallback(
		async ({ ownerId, ...vals }: typeof initialVals) => {
			try {
				const res = await mutate({
					id: estateId,
					data: {
						...vals,
						ownerId: ownerId ? parseInt(`${ownerId}`) : undefined,
					},
				})
				if (res.error) toastit.err('Error saving estate')
				else {
					toastit.ok('Estate Saved!')
					cb && cb()
				}
			} catch (err) {}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	return (
		<EditEstateOpView>
			<Async fetchResults={results}>
				{initialVals ? (
					<Form
						legend={`Edit ${initialVals.typeFull}`}
						submitErr={error}
						initialValues={initialVals}
						onSubmit={handleSubmit}
						validationSchema={yupSchema}
					>
						{() => (
							<>
								<EstateFields />
								<SubmitBtn />
							</>
						)}
					</Form>
				) : null}
			</Async>
		</EditEstateOpView>
	)
}
