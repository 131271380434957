import { useState } from 'react'
import { useTheme } from 'styled-components'

type UseModalCtrl<T> = {
	initialContent?: T | null
	showOnload?: boolean
}
type ShowModal<T> = (content?: T | null) => void
type CloseModal = () => void
type UseModalCtrlReturn<T> = [
	[boolean, ShowModal<T>, CloseModal, T | null],
	{
		showModal: boolean
		setShowModal: React.Dispatch<React.SetStateAction<boolean>>
		modalContent: T | null
		setModalContent: React.Dispatch<React.SetStateAction<T | null>>
	}
]

export const useModalCtrl = <T>(options?: UseModalCtrl<T>): UseModalCtrlReturn<T> => {
	const { initialContent = null, showOnload = false } = options || {}
	const [modalContent, setModalContent] = useState<T | null>(initialContent)
	const [showModal, setShowModal] = useState(showOnload)
	const { times } = useTheme()

	const openModal: ShowModal<T> = content => {
		if (typeof content === 'undefined') {
			if (modalContent !== null) setModalContent(null)
		} else {
			setModalContent(content)
		}
		setShowModal(true)
	}

	const closeModal: CloseModal = () => {
		setShowModal(false)
		setTimeout(() => {
			setModalContent(null)
		}, times.long)
	}

	return [
		[showModal, openModal, closeModal, modalContent],
		{
			showModal,
			setShowModal,
			modalContent,
			setModalContent,
		},
	]
}
