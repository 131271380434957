import styled, { css } from 'styled-components'
import { Card } from '../../../UI'
import { AcresOwnedByEstateTypeEstate } from './AcresOwnedByEstateType.dash.widget.estate'

const AcresOwnedByEstateTypeDashWidgetStyles = css`
	.acres-items {
		display: grid;
		grid-gap: 0.5rem;
	}
`

const AcresOwnedByEstateTypeDashWidgetView = styled.div`
	${AcresOwnedByEstateTypeDashWidgetStyles}
`

const estateTypes = ['srf', 'min']

type AcresOwnedByEstateTypeDashWidgetProps = {
	className?: string
}
export const AcresOwnedByEstateTypeDashWidget = ({
	className,
}: AcresOwnedByEstateTypeDashWidgetProps): JSX.Element => {
	return (
		<AcresOwnedByEstateTypeDashWidgetView
			className={`acres-owned-by-estate-type-widget${className ? ` className` : ''}`}
		>
			<Card noShadow titleText='OWNED'>
				<div className='acres-items'>
					{estateTypes.map(estateType => (
						<AcresOwnedByEstateTypeEstate key={estateType} estateType={estateType} />
					))}
				</div>
			</Card>
		</AcresOwnedByEstateTypeDashWidgetView>
	)
}
