import { RouteComponentProps } from '@reach/router'
import React from 'react'
import CardsSection from '../Components/common/CardsSection'
import Page from '../Components/common/Resources/Resource.page'
import { Card } from '../Components/UI'
import { Heading } from '../Components/UI/Heading'
import { usePageData } from '../utils'

const Page404: React.FC<RouteComponentProps> = () => {
	usePageData({ pageTitle: '404 Not Found', icon: 'question' })
	return (
		<Page isLoaded>
			<CardsSection>
				<Card>
					<Heading>404 Page Not Found</Heading>
				</Card>
			</CardsSection>
		</Page>
	)
}
export default Page404
