import React from 'react'
import StepWizard from 'react-step-wizard'
import styled from 'styled-components'
import { useState } from '../../../utils'
import { Btn } from '../../UI'
import CreateLeaseTerms from './Ops.Lease.terms.create'
import UpdateLeaseTerms from './Ops.Lease.terms.update'

const LeaseTermsSelectionView = styled.div`
	display: flex;
`

export type LeaseTermsProps = {
	leaseId: number
	cb?: () => void
}

const Selection = ({ nextStep, cb }: { nextStep?: () => void; cb: (state: boolean) => void }) => {
	return (
		<LeaseTermsSelectionView className='selection'>
			<Btn isCta onClick={() => (nextStep ? nextStep() : null)}>
				Edit Current Terms
			</Btn>
			<Btn
				isCta
				onClick={() => {
					cb(true)
					if (nextStep) nextStep()
				}}
			>
				Create New Terms
			</Btn>
		</LeaseTermsSelectionView>
	)
}

const LeaseTerm: React.FC<LeaseTermsProps> = ({ leaseId, cb }) => {
	const [isNew, setNew] = useState(false, 'isNew')
	return (
		<StepWizard isLazyMount>
			<Selection cb={setNew} />

			{isNew ? (
				<CreateLeaseTerms leaseId={leaseId} cb={cb} />
			) : (
				<UpdateLeaseTerms leaseId={leaseId} cb={cb} />
			)}
		</StepWizard>
	)
}
export default LeaseTerm
