import React from 'react'
import styled, { css } from 'styled-components/macro'
import { LogDetail } from '../../../Components/common/Log/Log.detail'

export const relatedLeaseLogModalStyles = css``

const RelatedLeaseLogModalView = styled.div`
	${relatedLeaseLogModalStyles}
`

export type RelatedLeaseLogModalProps = {
	logId: number
}
export const RelatedLeaseLogModal: React.FC<RelatedLeaseLogModalProps> = ({ logId }) => {
	return (
		<RelatedLeaseLogModalView>
			<LogDetail logId={logId} />
		</RelatedLeaseLogModalView>
	)
}
