import React from 'react'
import styled, { css } from 'styled-components/macro'
import { docRelatedAcquisitionsColsConfig } from '../../../Components/UI/Table.resources/Table.resource.col.configs/Acquisition.col.configs'
import { ResourcesTable } from '../../../Components/UI/Table.resources/Table.resources'
import { usePaginationParams } from '../../../Components/UI/Table.resources/usePaginationParams'
import { AcquisitionParent } from '../../../gql_generated/document_types'
import { usePaginatedAcquisitionsQuery } from '../../../gql_generated/graphql'
import { RelatedDocAcquisitionModal } from '../Related.Modals/Doc.related.acquisition.modal'

export const relatedDocAcquisitionsStyles = css``

const RelatedDocAcquisitionsView = styled.div`
	${relatedDocAcquisitionsStyles}
`

export type RelatedDocAcquisitionsProps = {
	docId: number
}

export const RelatedDocAcquisitions: React.FC<RelatedDocAcquisitionsProps> = ({ docId }) => {
	const paginationStore = usePaginationParams()
	const [fetchResults] = usePaginatedAcquisitionsQuery({
		variables: {
			params: {
				...paginationStore.params,
				parentOptions: [
					{
						parentType: AcquisitionParent.Doc,
						parentIds: [docId],
					},
				],
			},
		},
	})

	const { data } = fetchResults || {}

	const dataset = data?.page?.acquisitions
	return (
		<RelatedDocAcquisitionsView>
			<ResourcesTable
				dataset={dataset}
				colsConfig={docRelatedAcquisitionsColsConfig}
				fetchResults={fetchResults}
				paginationStore={paginationStore}
				modalRenderFunc={data => <RelatedDocAcquisitionModal acquisitionId={data.id} />}
				resourceType='acquisition'
			/>
		</RelatedDocAcquisitionsView>
	)
}
