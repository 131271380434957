import { useLocation, useNavigate } from '@reach/router'
import React, { HtmlHTMLAttributes } from 'react'
import styled from 'styled-components'
import { Btn, Icon } from '../UI'
import { MgrHeaderLoggedInMenu } from './Header.mgr.menu.loggedIn'
import { MgrHeaderPageInfo } from './Header.mgr.pageInfo'
import NavBtn from './Mobile-nav-ico.btn'

const MgrHeaderView = styled.header`
	background: ${props => props.theme.colors.softSlate.val};
	color: ${props => props.theme.colors.white.val};
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	display: flex;
	align-items: center;
	padding: 8px 0 4px 4px;

	${props => props.theme.media.tablet} {
		padding: 0 1em;
		padding-left: 0;
		height: ${props => props.theme.sizes.header.tablet.val};
	}

	${props => props.theme.media.sdesk} {
		position: relative;
		height: ${props => props.theme.sizes.header.sdesk.val};
		box-shadow: 0 2px 4px ${props => props.theme.colors.black.tint().val};
		color: ${props => props.theme.colors.ivory.val};
	}

	& > .page-info {
		height: 100%;
		display: flex;
		align-items: center;

		& > .nav-back-btn {
			${props => props.theme.media.tablet} {
				display: none;
			}
		}
	}

	& > .menu {
		margin-left: auto;
	}
`

type MgrHeaderProps = HtmlHTMLAttributes<HTMLDivElement> & {
	toggleNav: () => void
}

export const MgrHeader: React.FC<MgrHeaderProps> = ({ toggleNav, className }) => {
	const goTo = useNavigate()
	const { pathname } = useLocation()

	const isDash = pathname.includes('dash')
	return (
		<MgrHeaderView className={`global-header${className ? ` ${className}` : ''}`}>
			<section className='page-info'>
				{isDash ? null : (
					<Btn onClick={() => goTo(-1)} className='nav-back-btn'>
						<Icon type='arrowLeft' />
					</Btn>
				)}
				<MgrHeaderPageInfo />
			</section>
			<section className='menu'>
				<NavBtn className='mobile-nav-ico __hide-desk' onClick={() => toggleNav()} />
				<MgrHeaderLoggedInMenu className='__hide-mobile' />
			</section>
		</MgrHeaderView>
	)
}
export default MgrHeader
