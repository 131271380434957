import isFunction from 'lodash/isFunction'
import React, { useRef } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useCreateParcelMutation } from '../../../gql_generated/graphql'
import { defaultParcelVals, useState } from '../../../utils'
import { Btn, Form, H5, HR, Number, SubmitBtn, Textarea, toastit } from '../../UI'
import ParcelEstateFields from './Ops.Parcel.estateFields'
import ParcelFields from './Ops.Parcel.fields'

const View = styled.div`
	.estates-section {
		margin-bottom: 2em;
	}
	.estates-title {
		margin-bottom: 1em;
	}
`

const schema = Yup.object({
	apn: Yup.string().required(),
	town: Yup.string().required(),
	range: Yup.string().required(),
	section: Yup.string().required(),
})

type CreateParcelProps = {
	cb?: (data: any) => void
}

const CreateParcel: React.FC<CreateParcelProps> = ({ cb }) => {
	const [_, createParcel] = useCreateParcelMutation()
	const stopRender = useRef(false) // component should no longer render markup
	const [showEstates, setShowEstates] = useState(false, 'showEstates')

	return stopRender.current ? null : (
		<Form
			initialValues={defaultParcelVals}
			onSubmit={async (vals: any) => {
				const { srf, ind, min, oil, geo, acres, aliquot, ...data } = vals
				try {
					// console.log('data: ', data)

					const results = await createParcel({
						data: {
							...data,
							estates: [srf, ind, min, oil, geo].map(estate => ({
								acres,
								aliquot,
								...estate,
							})),
						},
					})

					if (results.error) {
						toastit.err('Error saving parcel')
					} else if (isFunction(cb)) {
						stopRender.current = true
						cb(results)
					}
				} catch (err) {
					console.error(err)
				}
			}}
			validationSchema={schema}
		>
			{() => {
				return (
					<View>
						<ParcelFields />
						<HR />
						<section className='estates-section'>
							<H5 className='estates-title'>Estates</H5>
							<ParcelEstateFields show={showEstates} />
							{!showEstates ? (
								<>
									<Number
										name='acres'
										label='Parcel Acres'
										helperTxt='NOTE: Will set the acres for all estates on this parcel'
										width='sm'
										isReq
									/>
									<Textarea
										name='aliquot'
										label='Aliquot'
										helperTxt='NOTE: Will set aliquot for all estates on this parcel'
										width='sm'
										isReq
									/>
									<Btn isCta onClick={() => setShowEstates(true)}>
										Edit Individual Estates
									</Btn>
								</>
							) : null}
						</section>
						<SubmitBtn>Save</SubmitBtn>
					</View>
				)
			}}
		</Form>
	)
}
export default CreateParcel
