import React from 'react'
import { stateList } from '../../../utils/stateList'
import { FormFieldWrapProps } from './Form.field.wrap'
import Select from './Select'

type SelectStateProps = FormFieldWrapProps
const SelectState: React.FC<SelectStateProps> = ({ name, label }) => {
	return (
		<Select
			name={name}
			label={label}
			options={stateList.map(({ full, abrev }) => ({
				val: abrev,
				txt: full,
			}))}
		/>
	)
}
export default SelectState
