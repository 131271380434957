import React from 'react'
import { useLeaseForInfoQuery } from '../../../gql_generated/graphql'
import { sumBy } from '../../../utils/lodash.utils'
import { KeyVals } from '../../UI'

type LeaseInfoProps = {
	leaseId: number
}
const LeaseInfo: React.FC<LeaseInfoProps> = ({ leaseId }) => {
	const [results] = useLeaseForInfoQuery({ variables: { id: leaseId } })
	const lease = results.data?.lease
	const { lessee, lesseeDba, leaseType, estateType, activity, initialFee, status } = lease || {}
	return lease ? (
		<KeyVals
			items={[
				['DB ID', leaseId],
				['Lease ID', lease.leaseId],
				['Lessee', lesseeDba || lessee, 'org'],
				leaseType ? ['Lease Type', leaseType.name, 'proper'] : null,
				['Estate Type', estateType, 'icon'],
				['Initial Fee', initialFee, 'currency'],
				[
					'Total',
					sumBy(activity, ({ acres, estate, assigneeId, isTerminated }) =>
						assigneeId || isTerminated ? 0 : acres || estate.acres
					),
					'acres',
				],
				['Status', status],
			]}
		/>
	) : null
}
export default LeaseInfo
