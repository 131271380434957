import React from 'react'
import styled from 'styled-components'
import { useTerminateEstateActivitiesMutation } from '../../../../gql_generated/graphql'
import { useState } from '../../../../utils'
import { Btn, ErrorBlock, P } from '../../../UI'
import { ResourceTableGlobalOpComponentOptions } from '../Table.resources'

const TerminateRelatedParcelsOpView = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.msg {
		padding: 2em 0;
	}
	.btns {
		width: 100%;
		justify-content: center;
		margin-top: 3em;
		.btn {
			&:last-child {
				margin-left: 1em;
			}
		}
	}
`

type TerminateRelatedParcelsOpProps = ResourceTableGlobalOpComponentOptions

export const TerminateRelatedParcelsOp: React.FC<TerminateRelatedParcelsOpProps> = ({
	parentId,
	parentType,
	isTotalQtySelected,
	selectedRowIds,
	deselectedRowIds,
	closeModal,
}) => {
	const [error, setError] = useState<any | null>(null, 'error')
	const [_, terminateActivity] = useTerminateEstateActivitiesMutation()

	const removeHandler = async () => {
		try {
			await terminateActivity({
				parentId,
				parentType,
				parcelIds: selectedRowIds,
				deselectedParcelIds: deselectedRowIds,
				isTotalQtySelected,
			})

			closeModal()
		} catch (err) {
			setError(err)
		}
	}

	return (
		<TerminateRelatedParcelsOpView>
			<div className='msg'>
				<P>
					Are you sure you want to terminate {isTotalQtySelected ? 'all' : selectedRowIds.length}{' '}
					parcel
					{selectedRowIds.length > 1 ? 's' : ''} from this {parentType}?
				</P>
			</div>
			{error ? <ErrorBlock error={error} /> : null}
			<div className='flex btns'>
				<Btn className='cta' onClick={removeHandler}>
					Terminate Parcels
				</Btn>
				<Btn onClick={closeModal}>Cancel</Btn>
			</div>
		</TerminateRelatedParcelsOpView>
	)
}
