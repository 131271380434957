import { RouteComponentProps } from '@reach/router'
import { useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'
import {
	Btn,
	Card,
	Icon,
	Modal,
	P,
	PrintWindow,
	SelectCountyRaw,
	Spinner,
	useModalCtrl,
} from '../../Components/UI'
import { CsvDownload } from '../../Components/UI/CSV.download'
import { ToggleRaw } from '../../Components/UI/Form.Fields/Toggle.field'
import CardsSection from '../../Components/common/CardsSection'
import { PropertyTaxesPDF } from '../../Components/common/PropertyTaxes.pdf'
import Page from '../../Components/common/Resources/Resource.page'
import { CsvPayload } from '../../gql_generated/document_types'
import { useParcelsForPropertyTaxReportQuery } from '../../gql_generated/graphql'
import { usePageData, useState } from '../../utils'

export const propertyTaxReportPageStyles = css`
	.config-panel {
		display: flex;
		flex-direction: column;
		row-gap: 1rem;
		max-width: 350px;
		padding: 1rem;
		border: solid 1px ${props => props.theme.colors.grey.val};
		border-radius: 5px;

		.btns {
			display: flex;
			column-gap: 1rem;
			.btn {
				display: flex;
				padding: 0.5em 1em;
				border: solid 1px;
				color: ${props => props.theme.colors.btn.val};

				&:disabled {
					color: ${props => props.theme.colors.disabled.val};
				}
			}
		}
	}
`

const PropertyTaxesReportPageView = styled.div`
	${propertyTaxReportPageStyles}
`

export const PropertyTaxesReportPage = (_: RouteComponentProps): JSX.Element => {
	const [county, setCounty] = useState('')

	usePageData({ pageTitle: 'Property Tax Report', icon: 'receipt' })

	const [[showModal, openModal, closeModal]] = useModalCtrl()
	const [[showCsv, openCsv, closeCsv]] = useModalCtrl()

	const [includeUnowned, setIncludeUnowned] = useState(false)

	const [{ data }] = useParcelsForPropertyTaxReportQuery({
		variables: { county, onlyOwnedSrf: !includeUnowned },
		pause: !county,
	})
	const canRender = !!data?.parcels
	const year = new Date().getFullYear()

	const [csvData, setCsvData] = useState<CsvPayload | undefined>(undefined)

	const filteredParcels = useMemo(
		() =>
			!includeUnowned
				? data?.parcels
				: data?.parcels?.filter(({ currentPropertyTax }) => !!currentPropertyTax?.amount),
		[includeUnowned, data]
	)

	const csvHandler = useCallback(() => {
		if (!filteredParcels) return

		setCsvData({
			headerStr: `APN,Amount\n`,
			dataStr: filteredParcels
				.map(({ apn, currentPropertyTax }) => `${apn},${currentPropertyTax?.amount || '?'}`)
				.join('\n'),
		})
	}, [filteredParcels])

	return (
		<PropertyTaxesReportPageView>
			<Page isLoaded>
				<CardsSection>
					<Card>
						<div className='config-panel'>
							<SelectCountyRaw
								defaultTxt='Select County...'
								name='county'
								onChange={e => setCounty(e.currentTarget.value)}
								value={county}
							/>
							<ToggleRaw
								label='Show all that have tax data?'
								helperTxt='NOTE: This includes unowned surface estate parcels'
								name='include'
								isOn={includeUnowned}
								onClick={setIncludeUnowned}
							/>
							{data ? (
								<div className='totals'>
									<P>{filteredParcels?.length} Parcels Found</P>
								</div>
							) : null}
							<div className='btns'>
								<Btn className='create-report-btn' disabled={!county} onClick={() => openModal()}>
									<Icon type='receipt' />
									Create Report
								</Btn>
								<Btn className='create-csv-btn' disabled={!county} onClick={() => openCsv()}>
									<Icon type='download' />
									Download CSV
								</Btn>
							</div>
						</div>
					</Card>
				</CardsSection>
			</Page>
			<Modal show={showModal} closeHandler={closeModal}>
				{canRender ? (
					<PrintWindow
						PDF={<PropertyTaxesPDF county={county} includeUnowned={includeUnowned} />}
						cb={closeModal}
						fileName={`${year}-${year + 1}-${county}-property-taxes`}
					/>
				) : (
					<Spinner />
				)}
			</Modal>
			<Modal show={showCsv} closeHandler={closeCsv}>
				<CsvDownload
					fetchData={csvHandler}
					fetchState={{
						fetching: false,
					}}
					csvData={csvData}
					onAfterDownload={closeCsv}
					options={{
						filename: `${county}-county-property-taxes`,
					}}
				/>
			</Modal>
		</PropertyTaxesReportPageView>
	)
}
